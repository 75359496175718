import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Label, P } from 'components'

const MinMaxDisplay = (props) => {
  const { label, min_data, max_data } = props
  return (
    <Grid item xs={12}>
      <Grid container mt={2} spacing={2}>
        <Grid item xs={12} sm={5.75} md={5} lg={4}>
          <Label color="gray">{label}</Label>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Label color="gray" sx={{ display: 'inline-grid' }}>
            Min:&nbsp;
          </Label>
          <Label>{min_data}</Label>
        </Grid>
        <Grid item xs={12} sm={1.25} md={1.5} alignSelf="center" sx={{ marginTop: { xs: 0, sm: 3 } }}>
          <P>~</P>
        </Grid>
        <Grid item xs={12} sm={2} md={3}>
          <Label color="gray" sx={{ display: 'inline-grid' }}>
            Max:&nbsp;
          </Label>
          <Label>{max_data}</Label>
        </Grid>
      </Grid>
    </Grid>
  )
}

MinMaxDisplay.displayName = 'Content Display'

MinMaxDisplay.propTypes = {
  label: PropTypes.string,
  min_data: PropTypes.any,
  max_data: PropTypes.any,
}

export default MinMaxDisplay
