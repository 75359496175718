import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import InnerHTML from 'dangerously-set-html-content'
import { createOffer } from 'services/jobs'
import { setConfirming, setFormErrors } from 'store/jobs/actionCreators'
import { hideNotification } from 'store/notification/actionCreators'
import { useHistory } from 'react-router-dom'
import SuccessIcon from 'assets/svg/success.svg'
//utils
import { formatAddress } from 'utils/helper'
//componets
import {
  Label,
  Heading,
  SubHeading,
  LargeButton,
  Icon,
  Link,
  ContentDisplay,
  LabelDisplay,
  OptionsDisplay,
  RecipientsDisplay,
} from 'components'

import PropTypes from 'prop-types'

const StyledJobConfirmForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& .LabelInfo': {
    fontSize: '12px',
    padding: '5px 15px 5px 15px',
    margin: '0px 2px 15px 2px',
    backgroundColor: 'oldlace',
    borderRadius: '5px',
  },
  '& .labelAlign': {
    textAlign: 'right',
    paddingRight: '10px',
    fontWeight: '200',
  },
  '& .invoiceGenerated': {
    paddingTop: 0,
  },
}))

const StyledLabel = styled(Label)(() => {
  return {
    position: 'inherit',
    overflowWrap: 'break-word',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
    [`& .iconButton`]: {
      position: 'absolute',
      marginTop: -8,
    },
  }
})

const OfferConfirmForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const formData = useSelector((state) => state.jobs.formData)
  const loader = useSelector((state) => state.loader)
  const isConfirming = useSelector((state) => state.jobs.is_confirming)
  const [confirmed, setConfirmed] = useState(false)
  const handleConfirm = async () => {
    let newData = {
      ...formData.current,
      goods_supplied_chk: formData.formatted?.provided_documents,
      goods_sent_to_worker_chk: formData.formatted?.provided_items,
      goods_worker_bring_chk: formData.formatted?.worker_tools,
      invoice_generated: formData.formatted?.invoice_generated,
    }
    await dispatch(createOffer(newData))
      .then(() => {
        dispatch(setConfirming(false))
        dispatch(setFormErrors(null))
        setConfirmed(true)
      })
      .catch((e) => {
        const { code, error } = e.response.data
        const allFields = formData.current ? Object.keys(formData.current) : []
        const allErrors = error ? Object.keys(error) : []
        const hasFieldError = allFields.some((item) => allErrors.includes(item))
        const jobDatesErrors = Object.keys(error).filter((key) => key.includes('job_dates'))
        if (code === 422 && (hasFieldError || jobDatesErrors?.length > 0)) {
          dispatch(hideNotification())
          dispatch(setFormErrors(error))
        }
        history.goBack()
      })
  }

  //set Page Title
  useEffect(() => {
    if (Object.keys(formData)) props.setTitle(formData.formatted?.name)
    return
  }, [dispatch, formData, isConfirming])

  useEffect(() => {
    if (!confirmed && (!isConfirming || !formData.formatted)) {
      history.goBack()
    }
    return () => {
      dispatch(setConfirming(false))
    }
  }, [dispatch])

  return (
    <StyledJobConfirmForm container className={confirmed ? 'Confirmation' : ''}>
      {confirmed && (
        <Grid container justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} sm={10} mt={2}>
            <Icon source={SuccessIcon} />
          </Grid>
          <Grid item xs={12} sm={10} pt={1} pb={3} gap={4}>
            <SubHeading color="secondary">オファーが完了しました。</SubHeading>
          </Grid>
          <Grid item xs={12} sm={10} pb={'28px'}>
            <Link
              to={`/offer/create?project=${formData.current?.project_id}&job_group=${formData.current?.job_group_id}&client=${formData.current?.client_id}&worker=${formData.current?.worker_id}`}
            >
              <LargeButton id="CopyJobBtn" color="primary" mode="light">
                このオファーをコピーして新しいオファーを作成する
              </LargeButton>
            </Link>
          </Grid>
          <Grid item xs={12} pb={1}>
            <Link id="ConfirmedBackBtn" to={'/job-group/list'}>
              <u>作業グループ一覧</u>
            </Link>
          </Grid>
          <Grid item xs={12} pb={6}>
            <Link id="ConfirmedBackBtn" to={'/application/list'}>
              <u>応募一覧</u>
            </Link>
          </Grid>
        </Grid>
      )}
      {!confirmed && (
        <Grid container>
          <Grid item xs={12}>
            <Heading>オファー確認</Heading>
          </Grid>
          <Grid item xs={12} pb={3} pt={4}>
            <SubHeading>以下の内容でオファーしますか？</SubHeading>
          </Grid>
          <ContentDisplay label="案件:" data={formData.formatted?.project_name} />
          <ContentDisplay label="作業グループ:" data={formData.formatted?.job_group_name} />
          <ContentDisplay label="応募者表示名:" data={formData.formatted?.worker_name} />
          <ContentDisplay label="作業名:" data={formData.formatted?.name} />
          <ContentDisplay label="作業担当者:" data={formData.formatted?.pic_name} />
          <ContentDisplay label="作業日時:" data={formData.formatted?.job_dates?.join(', ')} />
          <ContentDisplay label="作業時間:" data={formData.formatted?.working_hours} />
          <ContentDisplay label="休憩時間:" data={formData.formatted?.break_time} />
          <ContentDisplay label="作業場所:" data={formatAddress(formData.formatted)} />
          <ContentDisplay
            label="作業内容:"
            data={<InnerHTML className="InnerHtml" html={formData.formatted?.description} />}
          />
          <ContentDisplay label="作業費(税抜き):" data={formData.formatted?.salary_format} />
          <ContentDisplay label="手当:" data={formData.formatted?.allowance} />
          <ContentDisplay label="交通費:" data={formData.formatted?.toll_fee} />
          <ContentDisplay label="高速代:" data={formData.formatted?.highway_fee} />
          <ContentDisplay label="駐車場代:" data={formData.formatted?.parking_fee} />
          <ContentDisplay label="宿泊費:" data={formData.formatted?.overnight_stay_fee} />
          <ContentDisplay label="その他経費:" data={formData.formatted?.other_fee} />
          <OptionsDisplay
            label="事前支給品:"
            textOption="その他"
            text={formData.formatted?.provided_to_worker_other}
            options={formData.formatted?.provided_documents}
          />
          <OptionsDisplay
            label="事前送付品:"
            textOption="作業に使用する部材"
            text={formData.formatted?.items_provided_description}
            options={formData.formatted?.provided_items}
          />
          <OptionsDisplay
            label="ワーカー持参物:"
            text={formData.formatted?.worker_tool_notes}
            options={formData.formatted?.worker_tools}
          />
          <ContentDisplay label="服装:" data={formData.formatted?.attire} />
          <ContentDisplay label="エスカレーション先:" data={formData.formatted?.escalation_contact} />
          <Grid item container mt={2} spacing={2}>
            <Grid item xs={12} sm={5.75} md={5} lg={4}>
              <Label color="gray">{'キャンセル費:'}</Label>
            </Grid>
            <Grid item xs={12} sm={6.25} md={7} lg={8}>
              <StyledLabel>
                <pre>
                  <LabelDisplay md={-17} type="info" className="LabelInfo">
                    {'・1週間前キャンセル、作業費20%支払い'} <br />
                    {'・1〜3日前キャンセル、作業費50%支払い'} <br />
                    {'・当日キャンセル、作業費100%支払い'}
                  </LabelDisplay>
                </pre>
              </StyledLabel>
            </Grid>
          </Grid>
          <Grid item container pt={0} spacing={2}>
            <Grid item xs={12} sm={5.75} md={5} lg={4}></Grid>
            <Grid item xs={12} sm={6.25} md={7} lg={8}>
              <StyledLabel>
                <pre>{formData.formatted?.invoice_generated ? '✓ 上記キャンセル費を承諾する' : ''}</pre>
              </StyledLabel>
            </Grid>
          </Grid>
          <ContentDisplay label="お客様現地ご担当者様連絡先:" data={formData.formatted?.onsite_client_contact} />
          <ContentDisplay label="名乗り:" data={formData.formatted?.onsite_name} />
          <ContentDisplay label="当日同行者:" data={formData.formatted?.fellow} />
          <ContentDisplay label="その他連絡事項:" data={formData.formatted?.remarks} />
          <RecipientsDisplay label="必要な連絡と、担当者以外に追加で必要な宛先:" data={formData.formatted} />
          <ContentDisplay label="オファーコメント:" data={formData.formatted?.offer_comment} />
          <Grid item container xs={12} pt={6} justifyContent={{ xs: 'center', sm: 'end' }} gap={2}>
            <LargeButton id="ConfirmedBackBtn" color="gray" mode="btn1" onClick={history.goBack}>
              前の画面に戻って編集
            </LargeButton>
            <LargeButton id="JobConfirmBtn" color="primary" onClick={handleConfirm}>
              {loader && <CircularProgress size="1.5rem" color="white" />}
              {!loader && '確定'}
            </LargeButton>
          </Grid>
        </Grid>
      )}
    </StyledJobConfirmForm>
  )
}

OfferConfirmForm.displayName = 'Job Confirmation Form'

OfferConfirmForm.propTypes = {
  setTitle: PropTypes.func,
}

export default OfferConfirmForm
