export const status = {
  user: {
    active: 1,
    blocked: 2,
    pending: 3,
    locked: 4,
  },
  project: {
    locked: 4,
    open: 5,
    close: 6,
    suspended: 69,
  },
  actual_report: {
    accepted: 27,
    declined: 28,
    need_revision: 29,
    not_submitted: 30,
  },
  expense: {
    accepted: 31,
    declined: 32,
    need_revision: 33,
    not_submitted: 34,
  },
  job: {
    offer: 11,
    before_job_start: 12,
    working: 13,
    wait_work_report_submit: 14,
    wait_work_report_approve: 15,
    wait_expense_report_submit: 16,
    wait_expense_report_approve: 17,
    wait_invoice: 18,
    completed: 19,
    offered_declined: 20,
    declined: 21,
    canceled: 22,
    deleted: 23,
    offer_cancel: 72,
  },
  job_group: {
    under_edition: 7,
    can_apply: 10,
    close_application: 8,
    completed: 9,
    cancel: 70,
    suspended: 71,
  },
  application: {
    unprocessed: 73,
    offered: 74,
    rejected: 75,
  },
  client: {
    active: 24,
    accepted: 25,
    declined: 26,
  },
  name: {
    job_group: {
      under_edition: '編集中',
      close_application: '募集終了',
      completed: '完了',
      can_apply: '募集中',
    },
    offer: {
      before_job_start: 'オファー回答済み',
    },
  },
}

const {
  offer,
  before_job_start: jobStart,
  working,
  wait_work_report_submit: workReportSubmit,
  wait_expense_report_submit: expenseReportSubmit,
  wait_expense_report_approve: expenseReportApprove,
  wait_work_report_approve: waitWorkReportApprove,
  wait_invoice: invoice,
  completed,
  offered_declined,
  declined,
  canceled,
  offer_cancel,
} = status.job

export const jobListRequiredStatusId = [
  jobStart,
  working,
  workReportSubmit,
  expenseReportSubmit,
  expenseReportApprove,
  waitWorkReportApprove,
  invoice,
  completed,
  canceled,
  declined,
]

export const clientJobListRequiredStatusId = [
  offer,
  jobStart,
  working,
  workReportSubmit,
  expenseReportSubmit,
  expenseReportApprove,
  waitWorkReportApprove,
  invoice,
  completed,
  offered_declined,
  declined,
  canceled,
  offer_cancel,
]

export const applicationListRequiredStatusId = [
  status.job_group.under_edition,
  status.job_group.can_apply,
  status.job_group.close_application,
  status.job_group.completed,
  status.job_group.suspended,
]

export const offerListRequiredStatusId = [status.job.offer, status.job.before_job_start, status.job.offer_cancel]
export const jobListDefaultStatusId = jobListRequiredStatusId.slice(0, jobListRequiredStatusId.length - 2)
export const applicationListDefaultStatusId = applicationListRequiredStatusId.slice(
  0,
  applicationListRequiredStatusId.length - 2
)

export const clientJobListDefaultStatusId = clientJobListRequiredStatusId.slice(0, 9)

export const settings = {
  public: '公開',
  limited_public: '限定公開',
  under_edition: '編集中',
}

export const member_type = {
  member: '会員',
  worker: 'ワーカー',
}

export const OPCApplicationListDefaultStatusId = [
  status.application.unprocessed,
  status.application.offered,
  status.application.rejected,
]

export const OPCApplicationListJobGroupDefaultStatusId = [
  status.job_group.under_edition,
  status.job_group.can_apply,
  status.job_group.close_application,
  status.job_group.completed,
  status.job_group.cancel,
  status.job_group.suspended,
]
