// named routes
export const routes = {
  jobList: '/job/list',
  offerConfirmation: '/offer/confirmation',
  jobDetail: '/job/show/:id',
  jobGroupDetail: '/job-group/show/:id',
  jobConfirmation: '/job/confirmation',
  jobGroupList: '/job-group/list',
  projectList: '/project/list',
}

export default [
  {
    path: '/application/list',
    component: 'views/client/Application/ApplicationList',
    auth: true,
  },
  {
    path: '/application/show/:id',
    component: 'views/client/Application/ApplicationDetails',
    auth: true,
  },
  {
    path: '/offer/create',
    component: 'views/client/Offer/OfferCreateUpdate',
    exact: false,
    auth: true,
  },
  {
    path: routes.offerConfirmation,
    component: 'views/client/Offer/OfferConfirmation',
    auth: true,
  },
  {
    path: routes.jobList,
    component: 'views/client/JobList',
    auth: true,
  },
  {
    path: '/job/update/:id',
    component: 'views/client/Jobs/JobCreateUpdate',
    auth: true,
  },
  {
    path: routes.jobConfirmation,
    component: 'views/client/Jobs/JobConfirm',
    auth: true,
  },
  {
    path: routes.jobDetail,
    component: 'views/client/Jobs/JobDetails',
    auth: true,
  },
  {
    path: '/worker/show/:id',
    component: 'views/client/Worker/WorkerDetail',
    auth: true,
  },
  {
    path: '/password/change',
    component: 'views/common/ChangePassword',
    auth: true,
  },
  {
    path: '/register',
    component: 'views/client/Registration/RequestRegistration',
  },
  {
    path: '/register/client',
    component: 'views/client/Registration/ClientRegistration',
  },
  {
    path: '/register/verify',
    component: 'views/client/Registration/VerifyRegistration',
  },
  {
    path: routes.projectList,
    component: 'views/operation-center/ProjectList',
    auth: true,
  },
  {
    path: routes.jobGroupList,
    component: 'views/client/JobGroup/JobGroupList',
    auth: true,
  },
  {
    path: '/job-group/create',
    component: 'views/operation-center/JobGroup/JobGroupCreateUpdate',
    auth: true,
  },
  {
    path: '/job-group/create/:id',
    component: 'views/operation-center/JobGroup/JobGroupCreateUpdate',
    auth: true,
  },
  {
    path: '/job-group/update/:id',
    component: 'views/operation-center/JobGroup/JobGroupCreateUpdate',
    auth: true,
  },
  {
    path: '/job-group/confirmation',
    component: 'views/operation-center/JobGroup/JobGroupConfirm',
    auth: true,
  },
  {
    path: routes.jobGroupDetail,
    component: 'views/operation-center/JobGroup/JobGroupShow',
    auth: true,
  },
  {
    path: '/application/list',
    component: 'views/client/Application/ApplicationList',
    auth: true,
  },
  {
    path: '/payment/list',
    component: 'views/client/Payment/PaymentList',
    auth: true,
  },
  {
    path: '/system-usage-fee/list',
    component: 'views/client/SystemUsageList',
    auth: true,
    mode: 'client',
  },
]
