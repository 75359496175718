import Http from 'utils/Http'
import { downloadFile as commonDownload } from 'utils/common'
import {
  getPrefecturesListRequest,
  getPrefecturesListSuccess,
  getPrefecturesListFailure,
  getAllActiveClientListRequest,
  getAllActiveClientListSuccess,
  getAllActiveClientListFailure,
  getAllStatusRequest,
  getAllStatusSuccess,
  getAllStatusFailure,
  getAllWorkerSkillsRequest,
  getAllWorkerSkillsSuccess,
  getAllWorkerSkillsFailure,
  getAllJobFilterOptionsRequest,
  getAllJobFilterOptionsSuccess,
  getAllJobFilterOptionsFailure,
  getAllExpenseCategoriesRequest,
  getAllExpenseCategoriesSuccess,
  getAllExpenseCategoriesFailure,
  setDownloadFileFailure,
} from 'store/common/actionCreators'

const SUCCESS = 200

export function getPrefecturesList() {
  return (dispatch) => {
    dispatch(getPrefecturesListRequest())
    return Http.get('prefectures/all')
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(getPrefecturesListSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(getPrefecturesListFailure(error.response.data.error))
      })
  }
}

export function getAllActiveClientList() {
  return (dispatch) => {
    dispatch(getAllActiveClientListRequest())
    return Http.get('clients/all')
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(getAllActiveClientListSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(getAllActiveClientListFailure(error.response.data.error))
      })
  }
}

export function getAllStatusList() {
  return (dispatch) => {
    dispatch(getAllStatusRequest())
    return Http.get('status')
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(getAllStatusSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(getAllStatusFailure(error.response.data.error))
      })
  }
}

export function getWorkerSkills() {
  return (dispatch) => {
    dispatch(getAllWorkerSkillsRequest())
    return Http.get('op-center/skills/')
      .then((response) => {
        return dispatch(getAllWorkerSkillsSuccess(response.data))
      })
      .catch((error) => {
        return dispatch(getAllWorkerSkillsFailure(error.response.data.error))
      })
  }
}

export function getPublicWorkerSkills() {
  return (dispatch) => {
    dispatch(getAllWorkerSkillsRequest())
    return Http.get('register/worker/skills')
      .then((response) => {
        return dispatch(getAllWorkerSkillsSuccess(response.data))
      })
      .catch((error) => {
        return dispatch(getAllWorkerSkillsFailure(error.response.data.error))
      })
  }
}

export function getAllJobOptionsList() {
  return (dispatch) => {
    dispatch(getAllJobFilterOptionsRequest())
    return Http.get('op-center/jobs/options')
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(getAllJobFilterOptionsSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(getAllJobFilterOptionsFailure(error.response.data.error))
      })
  }
}

export function getAddress(zipcode) {
  return Http.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zipcode}`, {
    transformRequest: (data, headers) => {
      delete headers.common['Authorization']
      return data
    },
  })
}

export function getAllExpenseCategories() {
  return (dispatch) => {
    dispatch(getAllExpenseCategoriesRequest())
    return Http.get('expense-categories')
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(getAllExpenseCategoriesSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(getAllExpenseCategoriesFailure(error))
      })
  }
}

export function downloadFile(fileUrl, filename) {
  return (dispatch) => {
    return Http.post(`download-file?`, fileUrl, {
      responseType: 'blob',
    })
      .then((response) => {
        commonDownload(filename, response.data)
      })
      .catch((error) => {
        return dispatch(setDownloadFileFailure(error))
      })
  }
}
