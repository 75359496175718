import { Button as MUIButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import standards from 'theme/standards'

const Button = styled(MUIButton, {
  shouldForwardProp: (prop) => !['color', 'borderRadius'].includes(prop),
})(
  ({
    color,
    height,
    mode,
    width,
    maxwidth,
    minwidth,
    padding,
    fontSize,
    variant,
    bold,
    theme,
    mt,
    mb,
    ml,
    mr,
    borderRadius,
  }) => ({
    backgroundColor: variant == 'outlined' ? '#FFFFFF' : `${theme.palette[color]?.[mode]}`,
    borderRadius: borderRadius || height / 2,
    borderColor: `${theme.palette[color]?.[mode]}`,
    boxShadow: 'none',
    opacity: 1,
    minWidth: minwidth ? minwidth : 145,
    maxWidth: maxwidth ?? 'fit-content',
    width: width,
    height: height ?? '',
    fontSize: fontSize,
    padding: padding ?? theme.standards.padding.button,
    fontWeight: bold ? 'bold' : 'normal',
    color: variant == 'outlined' ? `${theme.palette[color]?.[mode]}` : `${theme.palette[color]?.contrastText}`,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
      maxWidth: 'unset',
      width: '100% !important',
      height: 'auto',
      minHeight: height ?? '',
      paddingTop: 6,
      paddingBottom: 6,
    },
    '&:hover': {
      color: variant == 'outlined' ? `${theme.palette[color]?.[mode]}` : `${theme.palette[color]?.contrastText}`,
      backgroundColor: variant == 'outlined' ? '#FFFFFF' : `${theme.palette[color]?.[mode]}`,
      borderColor: `${theme.palette[color]?.[mode]}`,
      cursor: 'pointer',
    },
    '&.Mui-disabled': {
      color: variant == 'outlined' ? `${theme.palette[color]?.[mode]}` : `${theme.palette[color]?.contrastText}`,
      opacity: 0.5,
    },
    marginTop: mt,
    marginBottom: mb,
    marginLeft: ml,
    marginRight: mr,
  })
)

Button.defaultProps = {
  height: 66,
  color: 'primary',
  mode: 'main',
  width: '100%',
  fontSize: standards.fontSize.default,
}

export default Button
