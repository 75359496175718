import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Label } from 'components'
import { styled } from '@mui/material/styles'

const StyledLabel = styled(Label)(() => {
  return {
    position: 'inherit',
    overflowWrap: 'break-word',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
  }
})

const OptionsDisplay = (props) => {
  const { label, blockStyle, labelAlign, options, text, textOption } = props

  return (
    <Grid item container mt={2} spacing={2}>
      <Grid item textAlign={labelAlign || 'left'} xs={12} {...(!blockStyle && { sm: 5.75, md: 5, lg: 4 })}>
        <Label color="gray">{label}</Label>
      </Grid>
      <Grid item xs={12} {...(!blockStyle && { xs: 12, sm: 5.25, md: 7 })}>
        <StyledLabel>
          <pre>
            {options &&
              options.map((value) => {
                return (
                  <Label key={value} className="checkLabels">
                    {'✓ ' + value}
                    {value === textOption ? (
                      <Grid display="flex" alignItems="baseline">
                        <Label color="gray">{'・'}</Label>
                        <Label color="gray" className="checkLabelsChild">
                          {text}
                        </Label>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Label>
                )
              })}
          </pre>
          {!textOption && text && (
            <pre>
              <Grid display="flex" alignItems="baseline">
                <Label color="gray">{'・'}</Label>
                <Label color="gray" className="checkLabelsChild">
                  {text}
                </Label>
              </Grid>
            </pre>
          )}
        </StyledLabel>
      </Grid>
    </Grid>
  )
}

OptionsDisplay.displayName = 'Options Display'

OptionsDisplay.propTypes = {
  label: PropTypes.string,
  data: PropTypes.any,
  blockStyle: PropTypes.bool,
  labelAlign: PropTypes.any,
  options: PropTypes.array,
  text: PropTypes.any,
  textOption: PropTypes.string,
}

export default OptionsDisplay
