import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

//componets
import { Label, RadioGroup } from 'components'

const StyledInputRadioGroup = styled(Box)(({ theme }) => ({
  padding: `${theme.standards.padding.input.default}`,
  '& .InputLabel': {
    paddingBottom: `${theme.standards.padding.label.inputLabel}`,
    fontSize: `${theme.standards.fontSize['input_label']}px !important`,
  },
  '& .RequiredText': {
    display: 'inline-flex',
  },
}))

const InputRadioGroup = React.forwardRef((props, ref) => {
  const excludedProps = ['label', 'labelProps', 'required']
  let defaultProps = omit(props, excludedProps)
  const { label, labelProps, onChange, fontSize } = props

  return (
    <StyledInputRadioGroup fontSize={fontSize}>
      <Label {...labelProps} className="InputLabel">
        {label}
        {props.required && (
          <Label color="error" className="RequiredText">
            *
          </Label>
        )}
      </Label>
      <RadioGroup {...defaultProps} ref={ref} onChange={onChange} />
    </StyledInputRadioGroup>
  )
})

InputRadioGroup.displayName = 'Input Radio Group'

InputRadioGroup.propTypes = {
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  required: PropTypes.bool,
  items: PropTypes.array,
  fontSize: PropTypes.number,
  onChange: PropTypes.func,
}

export default InputRadioGroup
