import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
//componets
import { Label, InputLabel } from 'components'

const StyledInputMinMax = styled(Grid)(() => ({
  '& .RequiredText': {
    display: 'inline',
  },
}))

const InputMinMax = (props) => {
  const excludedProps = ['label', 'type', 'labelProps', 'required', 'sideLabel', 'tag']
  let defaultMinProps = omit(props.minProps, excludedProps)
  let defaultMaxProps = omit(props.maxProps, excludedProps)

  const { label, labelProps, minProps, maxProps } = props

  const labelDisplay = (
    <Label {...labelProps} className="InputLabel">
      {label}
      {props.required && (
        <Label color="error" className="RequiredText">
          *
        </Label>
      )}
    </Label>
  )

  return (
    <StyledInputMinMax container alignItems="start" columnSpacing={1} mt={2} mb={1}>
      <Grid item xs={12} md={2.3} mb={1} sx={{ paddingTop: { xs: 0, md: 5 } }}>
        {labelDisplay}
      </Grid>
      <Grid item xs={12} md={4.35}>
        <Grid container columnSpacing={2}>
          <Grid item xs={1} sx={{ paddingTop: { xs: 5 } }}>
            <Label fontSize={labelProps?.fontSize}>{minProps?.tag}</Label>
          </Grid>
          <Grid item xs={11}>
            <InputLabel {...defaultMinProps} label={minProps?.label} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={1} textAlign="center" sx={{ paddingTop: { xs: 0, md: 5 } }}>
        {` 〜 `}
      </Grid>
      <Grid item xs={12} md={4.35}>
        <Grid container columnSpacing={2}>
          <Grid item xs={1} sx={{ paddingTop: { xs: 5 } }}>
            <Label fontSize={labelProps?.fontSize}>{minProps?.tag}</Label>
          </Grid>
          <Grid item xs={11}>
            <InputLabel {...defaultMaxProps} label={maxProps?.label} />
          </Grid>
        </Grid>
      </Grid>
    </StyledInputMinMax>
  )
}

InputMinMax.displayName = 'Input Min Max'

InputMinMax.defaultProps = {
  color: 'secondary',
}

InputMinMax.propTypes = {
  label: PropTypes.string.isRequired,
  errorMsg: PropTypes.string,
  required: PropTypes.bool,
  labelProps: PropTypes.object,
  minProps: PropTypes.object,
  maxProps: PropTypes.object,
}

export default InputMinMax
