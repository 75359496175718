import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/ja'
import Router from './router/Router'
import theme from './theme'
import 'assets/scss/index.scss'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider adapterLocale="ja" dateAdapter={AdapterDayjs} dateFormats={{ monthAndYear: 'YYYY年MM月' }}>
        <Router />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
