import React from 'react'
import PropTypes from 'prop-types'
import { Grid, CircularProgress as CProgress } from '@mui/material'
import omit from 'lodash/omit'

function CircularProgress(props) {
  const excludedProps = ['containerProps']
  let defaultProps = omit(props, excludedProps)

  return (
    <Grid container {...props.containerProps}>
      <CProgress {...defaultProps} />
    </Grid>
  )
}

CircularProgress.displayName = 'Circular Progress'

CircularProgress.defaultProps = {
  containerProps: {
    justifyContent: 'center',
  },
  size: '1.5rem',
  color: 'primary',
}

CircularProgress.propTypes = {
  containerProps: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
}

export default CircularProgress
