import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Link, Label } from 'components'
import { ArrowBack } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const StyledLabel = styled(Label, {
  shouldForwardProp: (prop) => prop,
})(({ color, bold, display, theme }) => ({
  opacity: 1,
  lineHeight: 'unset',
  letterSpacing: 0,
  whiteSpace: 'unset',
  wordBreak: 'break-word',
  display: display ?? '',
  color: color ? `${theme.palette?.text?.[color]}` : '',
  fontWeight: bold ? 'bold' : '',
  cursor: 'pointer',
}))

function BackArrow(props) {
  const { label, path } = props

  // Styled component named StyledButton
  return (
    <Link to={path} bold="true" color="primary">
      <Grid item justifyContent="center" alignItems="center" display="inline-flex">
        <ArrowBack fontSize="small" className="Arrow-Sign" />
        <StyledLabel className="cursor-pointer">{label}</StyledLabel>
      </Grid>
    </Link>
  )
}

BackArrow.defaultProps = {
  fontSize: 15,
  color: 'black',
}

BackArrow.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
}

export default BackArrow
