import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  Search as SearchIcon,
} from '@mui/icons-material'

//components
import { Label, Input, FormInput, Caption } from 'components'

const StyledInputLabel = styled(Box)(({ theme }) => ({
  padding: `${theme.standards.padding.input.default}`,
  '&:has(.NoLabel)': {
    '& .InputLabel': {
      display: `none !important`,
    },
  },
  '&:has(.NoPad)': {
    padding: 'unset !important',
  },
  '& .InputLabel': {
    paddingBottom: `${theme.standards.padding.label.inputLabel}`,
  },
  '& .RequiredText': {
    display: 'inline-flex',
  },
}))

const InputLabel = React.forwardRef((props, ref) => {
  const excludedProps = [
    'label',
    'type',
    'onChange',
    'labelProps',
    'required',
    'sideLabel',
    'hasReset',
    'requiredText',
    'caption',
    'iconState',
  ]

  let defaultProps = omit(props, excludedProps)
  const { label, errorMsg, onChange, type, labelProps, sideLabel, hasReset, requiredText, caption, iconState } = props
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  let endAdorment = null
  //add text input icon for password type

  switch (type) {
    case 'password': {
      endAdorment = (
        <IconButton disabled={iconState} onClick={handleClickShowPassword}>
          {!showPassword ? (
            <VisibilityOffOutlinedIcon id={props.name + '-InputIcon'} fontSize="medium" color="lightGray" />
          ) : (
            <VisibilityOutlinedIcon id={props.name + '-InputIcon'} fontSize="medium" color="lightGray" />
          )}
        </IconButton>
      )
      break
    }
    case 'filter': {
      endAdorment = (
        <IconButton type="submit">
          <SearchIcon />
        </IconButton>
      )
      break
    }
  }

  const labelDisplay = (
    <Label {...labelProps} className="InputLabel">
      {!props.label ? <>&nbsp;</> : label}
      {props.required && props.label != null && (
        <Label color="error" className="RequiredText">
          {requiredText || '*'}
        </Label>
      )}
    </Label>
  )

  const topLabelDisplay = (
    <Box>
      {labelDisplay}
      {hasReset ? (
        <FormInput
          {...defaultProps}
          id={props.id || `${props.name}-input-label`}
          ref={ref}
          type={type == 'password' ? (showPassword ? 'text' : 'password') : type || 'text'}
          errorMsg={errorMsg || ''}
          onChange={onChange ? (e) => onChange(e) : () => {}}
          endAdorment={endAdorment}
          value={''}
        />
      ) : (
        <Input
          {...defaultProps}
          id={props.id || `${props.name}-input-label`}
          ref={ref}
          type={type == 'password' ? (showPassword ? 'text' : 'password') : type || 'text'}
          errorMsg={errorMsg || ''}
          onChange={onChange ? (e) => onChange(e) : () => {}}
          endAdorment={endAdorment}
        />
      )}
      <Caption>{caption}</Caption>
    </Box>
  )

  const sideLabelDisplay = (
    <Box alignItems="center">
      <Box display="inline-grid" width={{ xs: 1, sm: 5 / 12, md: 4 / 12 }}>
        {labelDisplay}
      </Box>
      <Box display="inline-grid" width={{ xs: 1, sm: 7 / 12, md: 8 / 12 }}>
        {hasReset ? (
          <FormInput
            {...defaultProps}
            id={props.id || `${props.name}-input-label`}
            ref={ref}
            type={type == 'password' ? (showPassword ? 'text' : 'password') : type || 'text'}
            errorMsg={errorMsg || ''}
            endAdorment={endAdorment}
            value={''}
          />
        ) : (
          <Input
            {...defaultProps}
            id={props.id || `${props.name}-input-label`}
            ref={ref}
            type={type == 'password' ? (showPassword ? 'text' : 'password') : type || 'text'}
            errorMsg={errorMsg || ''}
            onChange={onChange ? (e) => onChange(e) : () => {}}
            endAdorment={endAdorment}
          />
        )}
        <Caption>{caption}</Caption>
      </Box>
    </Box>
  )

  return <StyledInputLabel>{sideLabel ? sideLabelDisplay : topLabelDisplay}</StyledInputLabel>
})

InputLabel.displayName = 'Input Label'

InputLabel.defaultProps = {
  color: 'secondary',
}

InputLabel.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  variant: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  labelProps: PropTypes.object,
  bold: PropTypes.bool,
  sideLabel: PropTypes.bool,
  id: PropTypes.string,
  hasReset: PropTypes.bool,
  caption: PropTypes.string,
  requiredText: PropTypes.any,
  iconState: PropTypes.bool,
}

export default InputLabel
