import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import BtnDownloadIcon from 'assets/svg/btn_download.svg'
import { useForm } from 'react-hook-form'
import * as dayjs from 'dayjs'
import { hideNotification } from 'store/notification/actionCreators'
//components
import { Grid, ButtonGroup, CircularProgress } from '@mui/material'
import {
  Heading,
  Button,
  Icon,
  InputDatePicker,
  InputTextArea,
  Checkbox,
  TableList,
  BackArrow,
  LargeButton,
} from 'components'
//services
import { downloadInvoice } from 'services/invoice'
//utils
import { dateFormat, downloadFile } from 'utils/common'
import { status } from 'utils/config/status'
//styled component
const StyledInvoiceDetailsForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .InvoiceDetails-form': {
    width: '100%',
  },
  '& .MuiBox-root.InputTextArea': {
    padding: 0,
  },
  '& #DownloadInvoiceBtn': {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  '& .InputAmount': {
    pointerEvents: 'none',
    borderRadius: 16,
    '& .MuiButton-root': {
      borderRadius: 16,
      '&.AmountLabel': {
        width: '40% !important',
        [theme.breakpoints.down('sm')]: {
          wordBreak: 'keep-all',
          width: '55% !important',
          fontSize: 14,
        },
      },
      '&.AmountText': {
        backgroundColor: theme.palette.disabled,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
          '& .MuiTypography-title': {
            wordBreak: 'keep-all',
            fontSize: 20,
          },
        },
      },
    },
  },
  '& .MuiTableBody-root td': {
    backgroundColor: theme.palette.disabled,
    borderColor: `${theme.palette.gray.light} !important`,
  },
}))

const InvoiceDetailsForm = () => {
  const dispatch = useDispatch()
  const formData = useSelector((state) => state.invoice.formData)
  const isLoading = useSelector((state) => state.loader)
  const displayData = formData?.original
  //useState
  const [isDownload, setIsDownload] = useState(false)
  //useform hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm()
  //format job data
  const jobReportsData = displayData?.jobs.map((item) => {
    return {
      ...item,
      name: item.status_id === status.job.canceled ? `キャンセル料 - ${item.name}` : item.name,
    }
  })
  // Set Job table headers
  const headerCells = [
    { id: 'date', label: '作業日', minWidth: 150, align: 'center' },
    { id: 'name', label: '作業', minWidth: 250, align: 'center' },
    { id: 'total_fees_format', label: ' 作業費・手当（税抜き）', minWidth: 120, align: 'center', colAlign: 'right' },
    { id: 'total_expenses_format', label: ' 経費（税抜き）', minWidth: 120, align: 'center', colAlign: 'right' },
    { id: 'grand_total_format', label: '小計(税抜き)', minWidth: 120, align: 'center', colAlign: 'right' },
  ]
  //Initial table rows
  const initialRows = 5
  let initialData = []
  if (displayData?.jobs.length < initialRows) {
    for (let i = 0; i < initialRows - displayData?.jobs.length; i++) {
      initialData.push({ id: `data-${i}` })
    }
  }
  //handle download invoice
  const handleDownloadInvoice = (data) => {
    data['id'] = displayData?.id
    setIsDownload(true)
    downloadInvoice(data)
      .then((response) => {
        setIsDownload(false)
        downloadFile(`Invoice_${dayjs(displayData?.invoice_month).format('YYYY_MM')}`, response.data)
      })
      .catch(async (e) => {
        let statusCode = e.response.status
        if (statusCode === 422) {
          setIsDownload(false)
          dispatch(hideNotification())
          let responseObj = await e.response.data.text()
          const { error } = JSON.parse(responseObj)
          const allFields = Object.keys(data)
          const allErrors = error ? Object.keys(error) : []
          const hasFieldError = allFields.some((item) => allErrors.includes(item))
          if (hasFieldError) {
            allErrors.map((key) => {
              setError(key, { type: 'custom', message: error[key][0] }, { shouldFocus: true })
            })
          }
        }
      })
  }
  //form validation
  const validationRules = {
    invoice_date: {
      required: {
        value: String,
        message: '請求日を入力してください。',
      },
      validate: (value) => {
        const isValid = dayjs(value, dateFormat, true).isValid()
        if (!isValid) {
          return 'YYYY/MM/DDの形式で入力してください。'
        } else {
          return true
        }
      },
    },
    due_date: {
      required: {
        value: String,
        message: 'お支払い期限を入力してください。',
      },
      validate: (value) => {
        const isValid = dayjs(value, dateFormat, true).isValid()
        if (!isValid) {
          return 'YYYY/MM/DDの形式で入力してください。'
        } else {
          return true
        }
      },
    },
    bank_details: {
      required: {
        value: String,
        message: '振込先を入力してください。',
      },
      validate: (value) => {
        if (value.length > 500) {
          return '500文字以内で入力してください。'
        } else if (value?.trim()?.length <= 0) {
          return '振込先を入力してください。'
        }
        return true
      },
    },
    biller: {
      required: {
        value: String,
        message: '請求元を入力してください。',
      },
      validate: (value) => {
        const newLineCount = value.split('\n')
        if (value.length > 500) {
          return '500文字以内で入力してください。'
        } else if (newLineCount.length > 6) {
          return ' 6行以内で入力してください。'
        } else if (value?.trim()?.length <= 0) {
          return '請求元を入力してください。'
        }
        return true
      },
    },
    remarks: {
      validate: (value) => {
        return value.length <= 500 || '500文字以内で入力してください。'
      },
    },
  }

  let defaultInputProps = {
    required: false,
  }
  //get InputTextArea Field
  const getInputTextAreaField = (label, name, placeholder, height, disabled = false, deafultValue = '') => {
    defaultInputProps['height'] = height
    return (
      <InputTextArea
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        isDisabled={disabled}
        defaultValue={deafultValue}
        {...defaultInputProps}
      />
    )
  }
  //get DatePicker Field
  const getInputDatePickerField = (label, name, defaultValue) => {
    return (
      <InputDatePicker
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        value={defaultValue}
        name={name}
        label={label}
        color="primary"
        inputFormat={dateFormat}
        {...defaultInputProps}
      />
    )
  }
  //get Checkbox Input
  const getInputCheckBox = (label, name) => {
    return <Checkbox id={name + '-ID'} name={name} label={label} ref={register(name, validationRules[name])} />
  }

  return (
    <StyledInvoiceDetailsForm container>
      <BackArrow label="請求書一覧に戻る" path="/invoice_list" />
      <form onSubmit={handleSubmit(handleDownloadInvoice)} className="InvoiceDetails-form">
        {isLoading && (
          <Grid xs={12} item textAlign="center" py={32}>
            <CircularProgress />
          </Grid>
        )}
        {!isLoading && (
          <Grid container pt={5} px={2} columnSpacing={{ xs: 0, md: 4 }}>
            <Grid item xs={12}>
              <Heading>請求書ダウンロード</Heading>
            </Grid>
            <Grid item container xs={12} justifyContent={{ md: 'right' }} pt={1}>
              <Grid item xs={12} sm={4} md={3} lg={2} textAlign="right">
                <LargeButton
                  id="DownloadInvoiceBtn"
                  type="submit"
                  startIcon={
                    isDownload ? <CircularProgress color="white" size="1.5rem" /> : <Icon source={BtnDownloadIcon} />
                  }
                >
                  ダウンロード
                </LargeButton>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} pt={1}>
              {getInputDatePickerField(
                '請求日',
                'invoice_date',
                dayjs(displayData?.invoice_month).endOf('month').format(dateFormat)
              )}
              {getInputDatePickerField(
                'お支払い期限',
                'due_date',
                dayjs(displayData?.invoice_month).add(1, 'month').endOf('month').format(dateFormat)
              )}
            </Grid>
            <Grid item xs={12} md={7} pt={1}>
              {getInputTextAreaField('請求書送付先', 'bill_to', '', 140, true, displayData?.bill_to)}
            </Grid>
            <Grid item xs={12} md={5} pt={1}>
              {getInputTextAreaField(
                '振り込み先',
                'bank_details',
                '例:\n◯◯銀行□□支店\n普通123456\nササツウタロウ',
                102,
                false,
                displayData?.bank_details?.trim()
              )}
              {getInputCheckBox('この振り込み先情報を次も利用する', 'remember_bank_info')}
            </Grid>
            <Grid item xs={12} md={7} pt={1}>
              {getInputTextAreaField(
                '請求元',
                'biller',
                '',
                150,
                false,
                displayData?.worker_biller ?? displayData?.bill_by?.trim()
              )}
              {getInputCheckBox('この請求元情報を次も利用する', 'remember_biller')}
            </Grid>
            <Grid item xs={12} md={5} pt={{ xs: 1 }} mt={{ xs: 0, md: -3 }}>
              {getInputTextAreaField(
                '備考欄',
                'remarks',
                '',
                110,
                false,
                '振り込み手数料は御社負担でお願いいたします。\n登録番号：\n※送付後一定期間内に連絡がない場合確認済とします。'
              )}
            </Grid>
            <Grid item container xs={12} md={7} pt={0} justifyContent="end">
              <Grid item xs={12} md={10} pt={1} alignItems="end" display="inline-flex">
                <ButtonGroup fullWidth variant="contained" className="InputAmount" aria-label="input amount">
                  <Button className="AmountLabel" maxwidth="inherit">
                    合計金額
                  </Button>
                  <Button className="AmountText" fontSize={45} bold="true" maxwidth="inherit">
                    <Heading>{displayData?.total_amount_format}</Heading>
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid item xs={12} pt={4}>
              <TableList
                headCells={headerCells}
                data={jobReportsData ? [...jobReportsData, ...initialData] : []}
                noSort
                noPaginate
              />
            </Grid>
          </Grid>
        )}
      </form>
    </StyledInvoiceDetailsForm>
  )
}

InvoiceDetailsForm.displayName = 'Invoice Details Form'

export default InvoiceDetailsForm
