import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  authRequestSignIn,
  authSignIn,
  authFailSignIn,
  setUser,
  signOut,
  getMemberType,
  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFailure,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  setPasswordData,
  setError,
} from 'store/auth/actionCreators'
import { hideNotification } from 'store/notification/actionCreators'

import { setRedirectRoute } from 'store/common/actionCreators'
export function signInUser(credentials) {
  const mode = localStorage.getItem('mode')

  return (dispatch) => {
    /**
     * The app state is updated to inform
     * that the API call is starting.
     */
    dispatch(authRequestSignIn())

    dispatch(showLoader())

    const config = {
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    }

    const data = { ...credentials, ...config }

    // actual api call
    return Http.post('oauth/token', data)
      .then((response) => {
        dispatch(checkLogin(response, mode))
      })
      .catch((error) => {
        // if there are any errors, update the store
        dispatch(authFailSignIn(error.response.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

function checkLogin(access_data, mode) {
  const accessData = access_data.data
  const token = accessData.access_token

  const validateRoleType = (id) => {
    switch (id) {
      case 1:
      case 2:
        return 'opc'
      case 3:
      case 4:
        return 'client'
      case 5:
        return 'wkr'
      default:
        return ''
    }
  }

  const handleWorkerLogin = (dispatch, workerId, memberType, accessData) => {
    localStorage.setItem('workerId', workerId)
    dispatch(authSignIn(accessData))
    dispatch(getMemberType(memberType))
  }

  return (dispatch) => {
    //check account group type
    return Http.get('/profile', { headers: { Authorization: `Bearer ${token}` } })
      .then((resUser) => {
        const resUserData = resUser.data.data
        const {
          role,
          member_type: memberType,
          worker_id: workerId,
          worker_account_status,
          status_id,
          client_user,
        } = resUserData
        const roleType = resUserData ? role.id : null

        // if super admin or regular operator
        if (mode === 'opc' && validateRoleType(roleType) === 'opc') {
          dispatch(setUser(resUserData))
          dispatch(authSignIn(accessData))
        } else if (mode === 'wkr' && validateRoleType(roleType) === 'wkr') {
          if (status_id === 1 && worker_account_status) {
            dispatch(setUser(resUserData))
            handleWorkerLogin(dispatch, workerId, memberType, accessData)
          } else {
            dispatch(
              authFailSignIn({
                error: 'invalid_account',
              })
            )
          }
        } else if (mode === 'client' && validateRoleType(roleType) === 'client') {
          if (status_id === 1 && client_user?.account_status) {
            dispatch(setUser(resUserData))
            dispatch(authSignIn(accessData))
          } else {
            dispatch(
              authFailSignIn({
                error: 'invalid_account',
              })
            )
          }
        } else {
          dispatch(
            authFailSignIn({
              error: 'invalid_user_credentials',
            })
          )
        }
      })
      .catch((error) => {
        // if there are any errors, update the store
        dispatch(authFailSignIn(error.response.data))
      })
  }
}

export function signUpUser(formData) {
  return () => {
    return Http.post('register', { ...formData })
  }
}

export function activateUser(token) {
  return () => {
    return Http.post('activate', { token })
  }
}

export function sendForgotPasswordEmail(formData) {
  formData['mode'] = localStorage.getItem('mode')
  return () => {
    return Http.post('password/forgot', { ...formData })
  }
}

export function resetPassword(formData) {
  return () => {
    return Http.post('password/reset', { ...formData })
  }
}

export function changePassword(formData) {
  return (dispatch) => {
    dispatch(showLoader())
    dispatch(changePasswordRequest())
    return Http.put('password/update', formData)
      .then(() => {
        dispatch(changePasswordSuccess())
      })
      .catch(({ response }) => {
        dispatch(hideNotification())
        dispatch(setPasswordData(formData))
        dispatch(changePasswordFailure())
        dispatch(setError(response.data.error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function getUser() {
  return (dispatch) => {
    return Http.get('profile').then((response) => {
      dispatch(setUser(response.data.data))
    })
  }
}

export function signOutUser() {
  return (dispatch) => {
    dispatch(showLoader())

    return Http.delete('oauth/token')
      .then(() => {
        dispatch(signOut())
        dispatch(setRedirectRoute(null))
        location.href = '/login'
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function validateTokenService(token, type) {
  return () => {
    return Http.post('validate-token', {
      token,
      validationType: type,
    })
  }
}

export function updatePassword(data) {
  return (dispatch) => {
    dispatch(updatePasswordRequest())
    return Http.post(`register/worker/update/password`, data)
      .then((response) => {
        dispatch(updatePasswordSuccess(response.data.data))
        dispatch(getUser())
      })
      .catch((error) => {
        dispatch(updatePasswordFailure(error.response.data.error))
      })
  }
}
