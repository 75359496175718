import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Snackbar, SnackbarContent } from '@mui/material'
import { green } from '@mui/material/colors'

const PREFIX = 'Notification'

const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
}

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  [`& .${classes.success}`]: {
    backgroundColor: green[500],
    justifyContent: 'center',
  },

  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.error.dark,
  },
}))

function Notification(props) {
  const { message, open, onClose, success } = props

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
    >
      <SnackbarContent className={success ? classes.success : classes.error} message={message} />
    </StyledSnackbar>
  )
}

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
}

export default Notification
