import React from 'react'
import PropTypes from 'prop-types'
import { Select as MUISelect, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import omit from 'lodash/omit'
import standards from 'theme/standards'

const StyledMultiSelect = styled(MUISelect, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ width, height, minHeight, fontSize, theme }) => ({
  width: width,
  height: height,
  minHeight: minHeight,
  boxSizing: 'border-box',
  fontSize: fontSize,
  '& fieldset': {
    border: '1px solid ',
    borderRadius: 5,
    borderColor: '#D9D9D9 !important',
  },
  '&:hover fieldset': {
    borderColor: '#D9D9D9 !important',
  },
  '& .Mui-error': {
    marginLeft: 0,
    '& fieldset': {
      borderColor: `${theme.palette['error'].main}`,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette['error'].main}`,
    },
  },
}))

const MultiSelect = React.forwardRef((props, ref) => {
  const excludedProps = ['menuItems', 'placehoder', 'width', 'height', 'fontSize', 'onChange', 'enableEmpty']
  let defaultProps = omit(props, excludedProps)
  const { menuItems, placehoder, width, height, fontSize, onChange } = props
  return (
    <StyledMultiSelect
      multiple
      fullWidth
      width={width}
      height={height}
      fontSize={fontSize}
      {...defaultProps}
      {...ref}
      onChange={(e) => {
        ref.onChange(e)
        onChange ? onChange(e) : () => {}
      }}
    >
      {placehoder && (
        <MenuItem disabled value="">
          {placehoder}
        </MenuItem>
      )}
      {!props.enableEmpty && (
        <MenuItem id={'menuItem-all-' + defaultProps.name + '-ID'} value={0}>
          すべて
        </MenuItem>
      )}
      {menuItems.map((item, index) => (
        <MenuItem
          id={'menuItem' + index + '-' + defaultProps.name + '-ID'}
          className="SelectItem"
          key={index}
          value={item.id}
        >
          {item.name}
        </MenuItem>
      ))}
    </StyledMultiSelect>
  )
})

MultiSelect.displayName = 'Input Multi Select'

MultiSelect.defaultProps = {
  height: standards.inputHeight.md,
  width: '100%',
  fontSize: standards.fontSize.default,
}

MultiSelect.propTypes = {
  onChange: PropTypes.func,
  renderValue: PropTypes.func,
  placehoder: PropTypes.string,
  menuItems: PropTypes.array,
  width: PropTypes.any,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  enableEmpty: PropTypes.bool,
}

export default MultiSelect
