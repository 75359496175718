import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { InputLabel } from 'components'
import { Label, LargeButton, Heading, Link } from 'components/atoms'
import { Divider, Grid, CircularProgress } from '@mui/material'
import standards from 'theme/standards'
import { useForm } from 'react-hook-form'
import { ForgotPasswordSuccess } from 'components'
import { useDispatch } from 'react-redux'
import { hideNotification } from 'store/notification/actionCreators'
import Http from 'utils/Http'
import { emailAddressValidation } from 'utils/helper'
import { getMessages } from 'validation'

function RequestRegistrationForm() {
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [companyNameError, setCompanyNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const dispatch = useDispatch()
  const rulesMsg = getMessages('ja')

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm()

  useEffect(() => {
    companyNameError && trigger('name')
    emailError && trigger('email_address')
  }, [companyNameError, emailError])

  const validationRules = {
    name: {
      required: {
        value: String,
        message: rulesMsg.name_input,
      },
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
      validate: () => {
        if (companyNameError) {
          return rulesMsg.name_exists
        }
      },
    },
    email_address: {
      required: {
        value: String,
        message: rulesMsg.email_credential,
      },
      validate: (value) => {
        return emailAddressValidation(value, emailError)
      },
      maxLength: {
        value: 300,
        message: rulesMsg.max(300),
      },
    },
  }

  const handleMemberRegistration = async (requestData) => {
    setLoading(true)
    try {
      await Http.post('/clients/create/client-email', requestData)
      setEmail(requestData.email_address)
      setIsEmailSent(true)
    } catch ({ response }) {
      const error = response.data.error ?? {}

      if (error?.name) {
        setCompanyNameError(true)
      }
      if (error?.email_address) {
        setEmailError(true)
      }

      dispatch(hideNotification())
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = (name) => {
    switch (name) {
      case 'name':
        {
          clearErrors('name')
          setCompanyNameError(false)
        }
        break
      case 'email_address':
        {
          clearErrors('email_address')
          setEmailError(false)
        }
        break
    }

    errors[name] && trigger(name)
  }

  return (
    <Grid container py={'35px'} justifyContent="center" alignItems="center" textAlign="center">
      {!isEmailSent ? (
        <Fragment>
          <Grid item xs={12} sx={{ px: { xs: 2, sm: 4 }, textAlign: 'start' }}>
            <Heading bold="true" variant="title">
              {'会員登録フォーム'}
            </Heading>
          </Grid>
          <form className="form" onSubmit={handleSubmit(handleMemberRegistration)}>
            <Grid container justifyContent="center" sx={{ px: { xs: 2 } }} rowSpacing={1}>
              <Grid item xs={12} sm={10} md={8} lg={8}>
                <InputLabel
                  id="ClientNameInput"
                  name="name"
                  ref={register('name', validationRules.name)}
                  error={errors && errors?.name ? true : false}
                  errorMsg={errors ? errors?.name?.message : null}
                  type="text"
                  height={standards.inputHeight['lg']}
                  placeholder={'法人名'}
                  onChange={() => handleInputChange('name')}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={8} lg={8} mt={-5}>
                <InputLabel
                  id="PICEmailInput"
                  name="email_address"
                  ref={register('email_address', validationRules.email_address)}
                  error={errors && errors?.email_address ? true : false}
                  errorMsg={errors ? errors?.email_address?.message : null}
                  type="text"
                  height={standards.inputHeight['lg']}
                  placeholder={'担当者メールアドレス'}
                  onChange={() => handleInputChange('email_address')}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={8} lg={8}>
                <LargeButton id="RequestRegistrationId" type="submit" color="primary" maxwidth="100%">
                  {loading ? <CircularProgress size={14} color="white" /> : '仮登録メールを送信'}
                </LargeButton>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12} py={6}>
            <Label align="center">
              {'会員登録に伴い、 ワークオンサイト'}
              <Link id="TermsOfUseLink" to="/terms_of_use" target={'_blank'}>
                {'利用規約、'}
              </Link>
              <br />
              <Link id="PrivacyPolicyLink" to="/privacy_policy" target={'_blank'}>
                {'プライバシーポリシー'}
              </Link>
              {'に同意したものとみなされます。'}
            </Label>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ borderBottomWidth: 1, borderColor: 'rgb(179 178 178)' }} />
            <Label align="center" pt={4}>
              {'既にアカウントをお持ちですか？'}
              <Link id="LoginLink" to="/login" color={'secondary'}>
                {'ログイン'}
              </Link>
            </Label>
          </Grid>
        </Fragment>
      ) : (
        <ForgotPasswordSuccess emailAddress={email} />
      )}
    </Grid>
  )
}

RequestRegistrationForm.propTypes = {
  mode: PropTypes.string,
}

export default RequestRegistrationForm
