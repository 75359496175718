import React from 'react'
import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import standards from 'theme/standards'
export const Banner = styled(Chip, {
  shouldForwardProp: (prop) => !['color', 'borderRadius', 'boxShadow'].includes(prop),
})(({ color, mode, height, width, fontSize, bold, variant, borderRadius, boxShadow, theme }) => ({
  backgroundColor: variant == 'outlined' ? '#FFFFFF' : theme.palette[color]?.light,
  opacity: 1,
  borderRadius: borderRadius,
  border: 0,
  borderLeft: `6px solid ${theme.palette[color]?.[mode]}`,
  width: width,
  height: height,
  fontSize: fontSize,
  color: variant == 'filled' ? `${theme.palette.text.primary}` : `${theme.palette[color]?.[mode]}`,
  fontWeight: bold ? 'bold' : 'normal',
  whiteSpace: 'unset',
  justifyContent: 'start',
  boxShadow: boxShadow ?? `2px 1px 10px ${theme.palette.lightGray.light}`,
  '&.SmallBanner': {
    borderLeft: `3px solid ${theme.palette[color]?.[mode]}`,
  },
}))

Banner.defaultProps = {
  width: '100%',
  height: 53,
  color: 'success',
  mode: 'main',
  fontSize: standards.fontSize.default,
  borderRadius: 6,
  variant: 'filled',
}

export const SmallBanner = (props) => {
  return <Banner {...props} height={24} borderRadius={0} className="SmallBanner" boxShadow="unset" />
}
