import React, { useState, useEffect } from 'react'
import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Heading, LargeButton, TableList, InputSelect, InputLabel, InputDatePicker, InputMultiSelect } from 'components'
import { useForm } from 'react-hook-form'
import { getPrefecturesList, getAllStatusList, getAllJobOptionsList } from 'services/common'
import { resetCurrentForm } from 'store/jobs/actionCreators'
import { getStatusByType } from 'utils/helper'
import { jobListRequiredStatusId, jobListDefaultStatusId } from 'utils/config/status'
import { getMessages } from 'validation'

import {
  datePresets,
  thisDay,
  thisMonthStart,
  thisMonthEnd,
  prevMonthStart,
  prevMonthEnd,
  nextMonthStart,
  nextMonthEnd,
} from 'utils/common'

const JobListForm = (props) => {
  const [datePresetValue, setdatePresetValue] = useState(1)
  const [dateFromValue, setDateFromValue] = useState(thisMonthStart)
  const [dateToValue, setDateToValue] = useState(thisMonthEnd)
  const [selectedRow, setSelectedRow] = useState({})
  const [disableInput1, setDisableInput1] = useState(true)
  const [disableInput2, setDisableInput2] = useState(true)
  const [filter1, setFilter1] = useState(0)
  const [filter2, setFilter2] = useState(0)
  const [filterText, setFilterText] = useState({ filterByText1: '', filterByText2: '' })
  const jobFilterList = useSelector((state) => state.common.jobFilterList) ?? []
  const prefecturesList = useSelector((state) => state.common.prefectureslist) ?? []
  const rawStatusOptions = useSelector((state) => state.common.statusList) ?? []
  const dispatch = useDispatch()
  const history = useHistory()
  const rulesMsg = getMessages('ja')
  const dateFormat = 'YYYY/MM/DD'
  const statusList = getStatusByType(rawStatusOptions, 'Job', jobListRequiredStatusId)

  useEffect(() => {
    dispatch(getAllJobOptionsList())
    dispatch(getPrefecturesList())
    dispatch(getAllStatusList())
    dispatch(resetCurrentForm())
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm()

  const validationRules = {
    filterByText1: {
      validate: (value) => {
        if (parseInt(getValues().filterBy1) !== 0) {
          return value?.trim()?.length > 0 || filterMessage(filter1)
        }
        return
      },
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    filterByText2: {
      validate: (value) => {
        if (parseInt(getValues().filterBy2) !== 0) {
          return value?.trim()?.length > 0 || filterMessage(filter2)
        }
        return
      },
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    start_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
    end_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
  }

  const filterMessage = (filter) => {
    return `${jobFilterList.find(({ id }) => id === filter).name}は必須項目です。`
  }

  const handleSelectedRow = (rowData) => {
    setSelectedRow(rowData)
  }

  const handleDatePreset = (item) => {
    switch (item) {
      case 2: //prev month
        setDateFromValue(prevMonthStart)
        setDateToValue(prevMonthEnd)
        break
      case 3: //next month
        setDateFromValue(nextMonthStart)
        setDateToValue(nextMonthEnd)
        break
      case 4: //this day
        setDateFromValue(thisDay)
        setDateToValue(thisDay)
        break
      case 1: //this month
        setDateFromValue(thisMonthStart)
        setDateToValue(thisMonthEnd)
        break
      default:
        break
    }

    setdatePresetValue(item)
  }

  const onDateChange = (newVal, type) => {
    setdatePresetValue(5)
    if (type == 'start_date') {
      setDateFromValue(newVal)
      if (dayjs(newVal).diff(dayjs(dateToValue)) >= 1) {
        setDateToValue(newVal)
      }
    } else {
      setDateToValue(newVal)
      if (dayjs(dateFromValue).diff(dayjs(newVal)) >= 1) {
        setDateFromValue(newVal)
      }
    }
  }

  const onFilterBy1Change = (value) => {
    setDisableInput1(value === 0 || false)
    setFilterText({
      ...filterText,
      filterByText1: '',
    })
    reset({ ...getValues(), filterByText1: null })
    setFilter1(value)
  }

  const onFilterBy2Change = (value) => {
    setDisableInput2(value === 0 || false)
    setFilterText({
      ...filterText,
      filterByText2: '',
    })
    reset({ ...getValues(), filterByText2: null })
    setFilter2(value)
  }

  const onChangeFilterText = (e) => {
    setFilterText({
      ...filterText,
      [e.target.name]: e.target.value,
    })
  }
  const handleSearch = (data) => {
    props.handlePageSearch(data)
  }

  const handleDetail = () => {
    history.push('/job/show/' + selectedRow?.id)
  }
  const handleEdit = () => {
    history.push('/job/update/' + selectedRow?.id)
  }
  const handleCopy = () => {
    history.push('/job/create/' + selectedRow?.id)
  }
  const handleCreate = () => {
    dispatch(resetCurrentForm())
    history.push('/job/create/')
  }

  const actionList = [
    {
      handler: (handler) => handleDetail(handler),
      name: '閲覧',
    },
    {
      handler: (handler) => handleEdit(handler),
      name: '編集',
    },
    {
      handler: (handler) => handleCopy(handler),
      name: 'コピー',
    },
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Heading>作業一覧</Heading>
      </Grid>
      <Grid item container justifyContent="end" pt={{ xs: 2, sm: 0 }}>
        <Grid item xs={12} sm={5} md={4} lg={3} textAlign="end">
          <LargeButton startIcon={<AddIcon size={'sm'} color={'white'} />} onClick={handleCreate}>
            作業新規登録
          </LargeButton>
        </Grid>
      </Grid>
      <Grid item xs={12} pt={4}>
        <form onSubmit={handleSubmit(handleSearch)} className="JobList-form">
          <Grid container columnSpacing={1}>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <InputSelect
                name="filterBy1"
                id="filterSelect1-ID"
                ref={register('filterBy1')}
                label="検索フィルター1"
                menuItems={[{ id: 0, name: '' }, ...jobFilterList]}
                defaultValue={0}
                onChange={onFilterBy1Change}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={9} lg={10} mt={{ xs: -5, sm: 0 }}>
              <InputLabel
                name="filterByText1"
                id="filterByText1-ID"
                ref={register('filterByText1', validationRules.filterByText1)}
                error={errors && errors.filterByText1 ? true : false}
                errorMsg={errors ? errors?.filterByText1?.message : null}
                value={filterText?.filterByText1}
                isDisabled={disableInput1}
                onChange={onChangeFilterText}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <InputSelect
                name="filterBy2"
                id="filterSelect2-ID"
                ref={register('filterBy2')}
                label="検索フィルター2"
                menuItems={[{ id: 0, name: '' }, ...jobFilterList]}
                defaultValue={0}
                onChange={onFilterBy2Change}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={9} lg={10} mt={{ xs: -5, sm: 0 }}>
              <InputLabel
                name="filterByText2"
                id="filterByText2-ID"
                ref={register('filterByText2', validationRules.filterByText2)}
                error={errors && errors.filterByText2 ? true : false}
                errorMsg={errors ? errors?.filterByText2?.message : null}
                labelProps={{ bold: 'true' }}
                value={filterText?.filterByText2}
                isDisabled={disableInput2}
                onChange={onChangeFilterText}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={1}>
            <Grid item xs={12} sm={4} md={4} lg={1.5} alignItems={'center'}>
              <InputSelect
                name="prefecture"
                ref={register('prefecture')}
                label="都道府県"
                menuItems={[{ id: 0, name: 'すべて' }, ...prefecturesList] ?? []}
                defaultValue={0}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={1.5} alignItems={'center'}>
              <InputSelect
                name="date_preset"
                label="作業日"
                menuItems={datePresets}
                value={datePresetValue}
                onChange={handleDatePreset}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2} alignItems={'center'}>
              <InputDatePicker
                id="jobList-start_date-ID"
                ref={register('start_date', validationRules.start_date)}
                error={errors && errors.start_date ? true : false}
                errorMsg={errors ? errors?.start_date?.message : null}
                name="start_date"
                label={'開始日'}
                color="primary"
                inputFormat={dateFormat}
                value={dayjs(dateFromValue)}
                onChange={(newVal) => onDateChange(newVal, 'start_date')}
                placeholder={thisMonthStart}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2} alignItems={'center'}>
              <InputDatePicker
                id="jobList-end_date-ID"
                ref={register('end_date', validationRules.end_date)}
                error={errors && errors.end_date ? true : false}
                errorMsg={errors ? errors?.end_date?.message : null}
                name="end_date"
                label={'終了日'}
                color="primary"
                inputFormat={dateFormat}
                value={dayjs(dateToValue)}
                onChange={(newVal) => onDateChange(newVal, 'end_date')}
                placeholder={thisMonthEnd}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={3.5} alignItems={'center'}>
              <InputMultiSelect
                ref={register('status_id')}
                name="status_id"
                label={'ステータス'}
                menuItems={statusList ?? []}
                defaultValue={jobListDefaultStatusId ?? []}
                enableEmpty
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={1.5} pt={{ md: 3.2 }}>
              <LargeButton
                type="submit"
                id="SearchBtn-ID"
                bold="true"
                color="warning"
                startIcon={
                  <SearchIcon
                    size={'sm'}
                    disabled={props.isLoading ? true : false}
                    style={{ color: 'rgb(255 107 9 / 55%)' }}
                  />
                }
              >
                検索
              </LargeButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}>
        <TableList
          title={`Job List`}
          headCells={props.headerCells}
          data={props.data}
          totalPage={props.totalPage}
          totalCount={props.totalCount}
          handlePageChange={props.handlePageChange}
          handleSelectedRow={handleSelectedRow}
          isLoading={props.isLoading}
          handleSort={props.handleSort}
          sort={props.sort}
          sortBy={props.sortBy}
          currentPage={props.currentPage}
          action={true}
          actionList={actionList}
          pageFrom={props.pageFrom}
          pageTo={props.pageTo}
        />
      </Grid>
    </Grid>
  )
}

JobListForm.displayName = 'Job List Form'

JobListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

export default JobListForm
