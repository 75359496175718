import * as types from './actionTypes'

export function actionSearchJobGroups(jobGroup) {
  return {
    type: types.SEARCH_JOB_GROUPS,
    payload: jobGroup,
  }
}

export function actionSearchClientJobGroups(jobGroup) {
  return {
    type: types.SEARCH_CLIENT_JOB_GROUPS,
    payload: jobGroup,
  }
}

export function actionSetSearchCriteria(searchData) {
  return {
    type: types.SET_SEARCH_CRITERIA,
    payload: { searchData },
  }
}

export function setJobGroupFormData(formData) {
  return {
    type: types.SET_JOB_GROUP_FORM_DATA,
    payload: formData,
  }
}

export function setConfirming(confirming) {
  return {
    type: types.SET_CONFIRMING_DATA,
    payload: confirming,
  }
}

export function setFormMode(isUpdate) {
  return {
    type: types.SET_FORM_MODE,
    payload: isUpdate,
  }
}

export function resetCurrentForm() {
  return {
    type: types.RESET_CURRENT_FORM,
  }
}
