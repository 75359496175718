import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  fetchingDataFail,
  actionSearchProject,
  actionSetSearchCriteria,
  actionGetAllClients,
  actionGetAllClientUsers,
  actionCreateProject,
  actionUpdateProject,
  actionValidateProjectStatus,
  actionClearProjectDetails,
} from 'store/projects/actionCreators'
import { hideNotification } from 'store/notification/actionCreators'

export function searchProjects(params) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('op-center/projects', { params })
      .then((response) => {
        dispatch(actionSearchProject(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function changeSearchCriteria(project, client, status, page, limit, sort, sortBy) {
  return (dispatch) => {
    dispatch(actionSetSearchCriteria(project, client, status, page, limit, sort, sortBy))
  }
}

export function getAllActiveUsers() {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('clients/all', {})
      .then((response) => {
        dispatch(actionGetAllClients(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function getAllClientUsers(id) {
  return (dispatch) => {
    return Http.get(`clients/${id}`, {})
      .then((response) => {
        dispatch(actionGetAllClientUsers(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
  }
}

export function createOrCopyProject(projectDetails) {
  return (dispatch) => {
    dispatch(actionClearProjectDetails())
    dispatch(showLoader())
    return Http.post('op-center/projects', projectDetails)
      .then((response) => {
        dispatch(actionCreateProject(response.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function updateProject(projectDetails, projectId) {
  return (dispatch) => {
    dispatch(actionClearProjectDetails())
    dispatch(showLoader())
    return Http.put('op-center/projects/' + projectId, projectDetails)
      .then((response) => {
        dispatch(actionUpdateProject(response.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function abortProject(projectId) {
  return (dispatch) => {
    return Http.get(`client/projects/suspend/${projectId}`, {})
      .then((response) => {
        dispatch(actionValidateProjectStatus(response.data))
      })
      .catch((error) => {
        dispatch(hideNotification())
        dispatch(fetchingDataFail(error))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
  }
}
