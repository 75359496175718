import React, { useState, useEffect } from 'react'
import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { Search as SearchIcon } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { Grid, Stack, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getMessages } from 'validation'

import {
  Heading,
  LargeButton,
  TableList,
  InputLabel,
  InputDatePicker,
  InputMultiSelect,
  Label,
  Link,
  SmallOutlinedTag,
} from 'components'
import { useForm } from 'react-hook-form'
import { getPrefecturesList, getAllStatusList, getAllJobOptionsList } from 'services/common'
import { resetCurrentForm } from 'store/application/actionCreators'
import { getStatusByType } from 'utils/helper'
import { routes } from 'router/routes/opCenterRoutes'
import { useControlledMultiSelectFormField, useMatchLastLocation } from 'utils/hooks'
import { OPCApplicationListDefaultStatusId, OPCApplicationListJobGroupDefaultStatusId } from 'utils/config/status'

import { dateFormat } from 'utils/common'

const StyledSearchIcon = styled(SearchIcon)({ color: 'rgb(255 107 9 / 55%)' })

const ApplicationListForm = (props) => {
  const [dateFromValue, setDateFromValue] = useState()
  const [dateToValue, setDateToValue] = useState()
  const rawStatusOptions = useSelector((state) => state.common.statusList) ?? []
  const dispatch = useDispatch()
  const applicationStatusList = getStatusByType(rawStatusOptions, 'Application', OPCApplicationListDefaultStatusId)
  const jobGroupStatusList = getStatusByType(rawStatusOptions, 'Job Group', OPCApplicationListJobGroupDefaultStatusId)
  const rulesMsg = getMessages('ja')
  const searchValues = useSelector((state) => state.applications.OPCSearch)
  const isFromApplicationDetails = useMatchLastLocation(routes.applicationDetails)
  const isFromJobGroupList = useMatchLastLocation(routes.jobGroupStatusList)

  useEffect(() => {
    if (!isFromApplicationDetails && !isFromJobGroupList) {
      dispatch(resetCurrentForm())
    }

    dispatch(getAllJobOptionsList())
    dispatch(getPrefecturesList())
    dispatch(getAllStatusList())
  }, [])

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      job_group_status: OPCApplicationListJobGroupDefaultStatusId,
      application_status: OPCApplicationListDefaultStatusId,
    },
  })

  const validationRules = {
    worker_display_name: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    job_group_name: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    project_name: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    client_name: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    start_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
    end_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        const startDateVal = getValues('start_date')
        if (!isValid) {
          return rulesMsg.invalid_date()
        } else if (dayjs(value).isBefore(startDateVal)) {
          return rulesMsg.is_date_before()
        } else {
          return true
        }
      },
    },
  }

  const onDateChange = (newVal, type) => {
    if (type == 'start_date') {
      setDateFromValue(newVal)
      if (dayjs(newVal).diff(dayjs(dateToValue)) >= 1) {
        setDateToValue(newVal)
      }
    } else {
      setDateToValue(newVal)
      if (dayjs(dateFromValue).diff(dayjs(newVal)) >= 1) {
        setDateFromValue(newVal)
      }
    }
  }

  const handleSearch = (data) => {
    props.handlePageSearch(data)
  }

  const actionList = [
    {
      name: '/application/show',
      color: 'view',
      label: '閲覧',
    },
  ]

  const formatData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        client_name: (
          <Link to={'/client/show/' + item?.client_id} sx={{ textDecoration: 'underline' }}>
            {item?.client_name}
          </Link>
        ),
        worker_display_name: (
          <Box display="inline-flex" key={item.id}>
            <Box component="span" width="max-content" textAlign="left">
              <Label pb={0.5}>
                <Link to={'/application/show/' + item?.id} maxwidth="max-content" sx={{ textDecoration: 'underline' }}>
                  {item?.worker_display_name}
                </Link>
              </Label>
              <Stack direction="row" spacing={1}>
                {!!item?.verified_user && <SmallOutlinedTag label="本人確認済" color="success" />}
                {!!item?.tekikaku_flag && <SmallOutlinedTag label="適格請求書発行事業者" color="primary" />}
              </Stack>
            </Box>
          </Box>
        ),
        job_group_name: (
          <Link to={'/job_group/show/' + item?.job_group_id} sx={{ textDecoration: 'underline' }}>
            {item?.job_group_name}
          </Link>
        ),
      }
    })
  }

  useEffect(() => {
    reset(searchValues)
  }, [searchValues])

  const jobGroupStatusRef = useControlledMultiSelectFormField({ register, watch, name: 'job_group_status' })
  const applicationStatusRef = useControlledMultiSelectFormField({ register, watch, name: 'application_status' })

  return (
    <Grid container>
      <Grid item xs={12}>
        <Heading>応募一覧</Heading>
      </Grid>
      <Grid item xs={12} mt={{ xs: 0, sm: 4 }}>
        <form onSubmit={handleSubmit(handleSearch)} className="JobList-form">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={3}>
              <InputLabel
                name="worker_display_name"
                id="worker_display_name-ID"
                ref={register('worker_display_name', validationRules.worker_display_name)}
                label="応募者表示名・ID"
                error={!!(errors && errors.worker_display_name)}
                errorMsg={errors ? errors?.worker_display_name?.message : null}
                {...(searchValues && { value: searchValues?.worker })}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel
                name="job_group_name"
                id="job_group_name-ID"
                ref={register('job_group_name', validationRules.job_group_name)}
                label="作業グループ名・ID"
                error={!!(errors && errors.job_group_name)}
                errorMsg={errors ? errors?.job_group_name?.message : null}
                {...(searchValues && { value: searchValues?.job_group })}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <InputLabel
                name="project_name"
                id="project_name-ID"
                ref={register('project_name', validationRules.project_name)}
                label="案件名・ID"
                error={!!(errors && errors.project_name)}
                errorMsg={errors ? errors?.project_name?.message : null}
                {...(searchValues && { value: searchValues?.project })}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <InputLabel
                name="client_name"
                id="client_name-ID"
                ref={register('client_name', validationRules.client_name)}
                label="クライアント名・ID"
                error={!!(errors && errors.client_name)}
                errorMsg={errors ? errors?.client_name?.message : null}
                {...(searchValues && { value: searchValues?.client })}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6} md={2.5} alignItems={'center'}>
              <InputMultiSelect
                ref={jobGroupStatusRef}
                name="job_group_status"
                label={'作業グループステータス'}
                menuItems={jobGroupStatusList ?? []}
                enableEmpty
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5} alignItems={'center'}>
              <InputMultiSelect
                ref={applicationStatusRef}
                name="application_status"
                label={'応募ステータス'}
                menuItems={applicationStatusList ?? []}
                enableEmpty
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5} alignItems={'center'}>
              <InputDatePicker
                id="jobList-start_date-ID"
                ref={register('start_date', validationRules.start_date)}
                error={!!(errors && errors.start_date)}
                errorMsg={errors ? errors?.start_date?.message : null}
                name="start_date"
                label={'応募日'}
                color="primary"
                inputFormat={dateFormat}
                value={dateFromValue && dateFromValue !== ' ' ? dayjs(dateFromValue) : ''}
                onChange={(newVal) => onDateChange(newVal, 'start_date')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5} alignItems={'center'}>
              <InputDatePicker
                id="jobList-end_date-ID"
                ref={register('end_date', validationRules.end_date)}
                error={!!(errors && errors.end_date)}
                errorMsg={errors ? errors?.end_date?.message : null}
                name="end_date"
                color="primary"
                inputFormat={dateFormat}
                value={dateToValue && dateToValue !== ' ' ? dayjs(dateToValue) : ''}
                onChange={(newVal) => onDateChange(newVal, 'end_date')}
                minDate={dayjs(dateFromValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1} pt={{ md: 3.5 }}>
              <LargeButton
                type="submit"
                id="SearchBtn-ID"
                bold="true"
                color="warning"
                startIcon={<StyledSearchIcon size={'sm'} disabled={props.isLoading ? true : false} />}
              >
                検索
              </LargeButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}>
        <TableList
          title={`Application List`}
          headCells={props.headerCells}
          data={formatData(props.data)}
          totalPage={props.totalPage}
          totalCount={props.totalCount}
          handlePageChange={props.handlePageChange}
          isLoading={props.isLoading}
          handleSort={props.handleSort}
          sort={props.sort}
          sortBy={props.sortBy}
          currentPage={props.currentPage}
          action={true}
          actionButton={true}
          actionList={actionList}
          pageFrom={props.pageFrom}
          pageTo={props.pageTo}
        />
      </Grid>
    </Grid>
  )
}

ApplicationListForm.displayName = 'Application List Form'

ApplicationListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

export default ApplicationListForm
