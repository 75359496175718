import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  actionSearchInvoice,
  actionSetSearchCriteria,
  actionGetWorkerInvoiceMonth,
  setInvoiceFormData,
} from 'store/invoice/actionCreators'

export function searchInvoice(worker_id, invoice_month, page, limit, sort, sortBy) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('worker/invoices', {
      params: {
        worker_id,
        invoice_month,
        page,
        limit,
        sort,
        sortBy,
      },
    })
      .then((response) => {
        dispatch(actionSearchInvoice(response.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function changeSearchCriteria(invoiceMonth, page, limit, sort, sortBy) {
  return (dispatch) => {
    dispatch(actionSetSearchCriteria(invoiceMonth, page, limit, sort, sortBy))
  }
}

export function getInvoiceMonths(workerId) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get(`worker/invoices/months/${workerId}`)
      .then((response) => {
        dispatch(actionGetWorkerInvoiceMonth(response.data))
      })
      .catch(() => {
        dispatch(hideLoader())
      })
  }
}

export function getInvoiceDetails(workerId) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('worker/invoices/' + workerId)
      .then((response) => {
        let response_data = {}
        response_data['original'] = response.data.data
        dispatch(setInvoiceFormData(response_data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function downloadInvoice(formData) {
  return Http.post('worker/invoices/download', formData, {
    responseType: 'blob',
  })
}
