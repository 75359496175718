import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import omit from 'lodash/omit'
import { styled } from '@mui/material/styles'
//componets
import { Label, RichTextEditor } from 'components'

const StyledInputRichTextEditor = styled(Box)(({ theme }) => ({
  padding: `${theme.standards.padding.input.default}`,
  '& .InputLabel': {
    paddingBottom: `${theme.standards.padding.label.inputLabel}`,
  },
  '& .RequiredText': {
    display: 'inline-flex',
  },
}))

const InputRichTextEditor = React.forwardRef((props, ref) => {
  const excludedProps = ['label', 'type', 'labelProps', 'required', 'sideLabel']
  let defaultProps = omit(props, excludedProps)
  const { label, labelProps, sideLabel } = props

  const labelDisplay = (
    <Label {...labelProps} className="InputLabel">
      {label}
      {props.required && (
        <Label color="error" className="RequiredText">
          *
        </Label>
      )}
    </Label>
  )

  const topLabelDisplay = (
    <Box>
      {labelDisplay}
      <RichTextEditor {...defaultProps} ref={ref} />
    </Box>
  )
  const sideLabelDisplay = (
    <Box>
      <Box display="inline-grid" width={{ xs: 1, sm: 5 / 12, md: 4 / 12 }}>
        {labelDisplay}
      </Box>
      <Box display="inline-grid" width={{ xs: 1, sm: 7 / 12, md: 8 / 12 }}>
        <RichTextEditor {...defaultProps} ref={ref} />
      </Box>
    </Box>
  )

  return <StyledInputRichTextEditor>{sideLabel ? sideLabelDisplay : topLabelDisplay}</StyledInputRichTextEditor>
})

InputRichTextEditor.displayName = 'Input Rich Text Editor'

InputRichTextEditor.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  labelProps: PropTypes.object,
  sideLabel: PropTypes.bool,
}

export default InputRichTextEditor
