import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid, Box } from '@mui/material'
import { Label, LargeButton, P } from 'components'

function ProjectModalVerify(props) {
  const isClient = localStorage.getItem('mode').includes('client')
  const { action, title, onClose, projectDetails } = props
  const isRegister = action.includes('register')
  const isEdit = action.includes('edit')
  const totalJobGroups = Object.keys(projectDetails).length > 0 ? projectDetails?.job_groups.length : 0

  const xsGridItemLabel = isClient ? 4.5 : 5
  const xsGridItemValue = isClient ? 7.5 : 5.5
  const smGridItemLabel = isClient ? 5.25 : 6
  const smGridItemValue = isClient ? 6.75 : 6
  const xsBoxWidth = isClient ? 200 : 250
  const smBoxWidth = isClient ? 400 : 450

  const clientLabelContent = (
    <Fragment>
      <Grid item xs={xsGridItemLabel} sm={smGridItemLabel} textAlign="end">
        <Label color="gray">{'クライアント:'}</Label>
      </Grid>
      <Grid item xs={xsGridItemValue} sm={smGridItemValue} pl={1} textAlign="start">
        <P>{projectDetails?.client.name}</P>
      </Grid>
    </Fragment>
  )

  const totalJobGroupContent = (
    <Grid item pb={3} pl={2} xs={12} md={12} lg={12} textAlign="center">
      <Label>{`作業グループ(${isRegister ? 0 : totalJobGroups}件)のコピーも作成しました。`}</Label>
    </Grid>
  )

  const handleCloseVerification = () => {
    onClose()
  }

  return (
    <Grid container rowSpacing={3} justifyContent="center">
      <Grid item pl={2} xs={12} md={12} lg={12} textAlign="center">
        <Label>{title}</Label>
      </Grid>
      <Grid pb={2} item xs={12} textAlign="center" sx={{ display: 'flex', placeContent: 'center' }}>
        <Box sx={{ width: { xs: xsBoxWidth, sm: smBoxWidth } }}>
          <Grid container justifyContent="center" className="PojectListVerify-form">
            {!isClient && clientLabelContent}
            <Grid item xs={xsGridItemLabel} sm={smGridItemLabel} textAlign="end">
              <Label color="gray">{'担当者:'}</Label>
            </Grid>
            <Grid item xs={xsGridItemValue} sm={smGridItemValue} pl={1} textAlign="start">
              <P>{projectDetails?.pic_name}</P>
            </Grid>
            <Grid item xs={xsGridItemLabel} sm={smGridItemLabel} textAlign="end">
              <Label color="gray">{'案件名:'}</Label>
            </Grid>
            <Grid item xs={xsGridItemValue} sm={smGridItemValue} pl={1} textAlign="start">
              <P>{projectDetails?.name}</P>
            </Grid>
            {!isClient && (
              <Fragment>
                <Grid item xs={xsGridItemLabel} sm={smGridItemLabel} textAlign="end">
                  {action === 'edit' && (
                    <Label align={'right'} color={'light_gray'}>
                      {'ステータス:'}
                    </Label>
                  )}
                </Grid>

                <Grid item xs={xsGridItemValue} sm={smGridItemValue} pl={1} textAlign="start">
                  {action === 'edit' && <Label>{projectDetails?.status.name}</Label>}
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Box>
      </Grid>
      {isClient && !isRegister && !isEdit && totalJobGroupContent}
      <Grid textAlign="center" item xs={7} sm={6} md={5}>
        <LargeButton onClick={handleCloseVerification} variant="contained">
          {'OK'}
        </LargeButton>
      </Grid>
    </Grid>
  )
}

ProjectModalVerify.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  project: PropTypes.object,
  onClose: PropTypes.func,
  projectDetails: PropTypes.object,
}

export default ProjectModalVerify
