import React from 'react'
import PropTypes from 'prop-types'
import { Popper as MUIPopper } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledPopper = styled(MUIPopper)(() => ({
  borderRadius: 4,
  display: 'inherit',
  '&#tooltip[data-show]': {
    display: 'block',
  },
  '&#tooltip[data-popper-placement^="top"] > #arrow': {
    bottom: -5,
  },
  '&#tooltip[data-popper-placement^="bottom"] > #arrow': {
    top: -5,
  },
  '&#tooltip[data-popper-placement^="left"] > #arrow': {
    right: -5,
  },
  '&#tooltip[data-popper-placement^="right"] > #arrow': {
    left: -5,
  },
}))

function Popper(props) {
  const { open, anchorEl, arrowRef, hasArrow, padding, children } = props

  return (
    <StyledPopper
      id={'tooltip'}
      open={open}
      anchorEl={anchorEl}
      placement="right"
      disablePortal={true}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            boundary: 'document',
            fallbackPlacements: ['bottom-start', 'top-start'],
            padding: { padding },
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
        {
          name: 'arrow',
          enabled: hasArrow,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      {children}
    </StyledPopper>
  )
}

Popper.defaultProps = {
  padding: 8,
  hasArrow: false,
}

Popper.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  arrowRef: PropTypes.object,
  hasArrow: PropTypes.bool,
  padding: PropTypes.number,
  children: PropTypes.node,
}

export default Popper
