import * as types from './actionTypes'

export function authRequestSignIn() {
  return {
    type: types.AUTH_SIGNIN_PENDING,
  }
}

export function authSignIn(authToken) {
  return {
    type: types.AUTH_SIGNIN,
    payload: authToken,
  }
}

export function signOut() {
  return {
    type: types.AUTH_SIGNOUT,
  }
}

export function authFailSignIn(error) {
  return {
    type: types.AUTH_SIGNIN_FAILED,
    payload: error,
  }
}

export function authCheck() {
  return {
    type: types.AUTH_CHECK,
  }
}

export function setUser(user) {
  return {
    type: types.AUTH_SET_USER,
    payload: user,
  }
}

export function getMemberType(type) {
  return {
    type: types.MEMBER_TYPE,
    payload: type,
  }
}

export function updatePasswordRequest() {
  return {
    type: types.UPDATE_PASSWORD_REQUEST,
  }
}

export function updatePasswordSuccess(data) {
  return {
    type: types.UPDATE_PASSWORD_SUCCESS,
    payload: data,
  }
}

export function updatePasswordFailure(error) {
  return {
    type: types.UPDATE_PASSWORD_FAILURE,
    payload: error,
  }
}

export function changePasswordRequest() {
  return {
    type: types.CHANGE_PASSWORD_REQUEST,
  }
}

export function changePasswordSuccess() {
  return {
    type: types.CHANGE_PASSWORD_SUCCESS,
  }
}

export function changePasswordFailure() {
  return {
    type: types.CHANGE_PASSWORD_FAILURE,
  }
}

export function setError(error) {
  return {
    type: types.COMMON_ERROR,
    payload: error,
  }
}

export function setPasswordData(data) {
  return {
    type: types.SET_PASSWORD_DATA,
    payload: data,
  }
}
