import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableRow, TableCell, TableBody, TableContainer } from '@mui/material'
import { styled } from '@mui/material/styles'
import InnerHTML from 'dangerously-set-html-content'
import { Label } from 'components'

const StyledTable = styled(Table, {
  shouldForwardProp: (prop) => prop !== 'titleWidth',
})(({ titleWidth, theme }) => ({
  padding: 0,
  '& td.MuiTableCell-root': {
    padding: '8px 16px 8px 16px',
    fontWeight: 'normal',
    borderBottom: `1px solid ${theme.palette.background.gray}`,
    '&:first-of-type': {
      borderRight: `1px solid ${theme.palette.background.gray}`,
      color: `${theme.palette.text.main}`,
      width: titleWidth,
      [theme.breakpoints.down('sm')]: {
        width: '50%',
        whiteSpace: 'break-spaces',
        '& pre': {
          whiteSpace: 'break-spaces',
        },
      },
    },
  },
  '& tr.MuiTableRow-root': {
    '&:last-child td': {
      borderBottom: 0,
    },
  },
  '& .content': {
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
  },
  '& .InnerHtml': {
    display: 'grid',
    lineHeight: 'normal',
    '& p': {
      minHeight: `${theme.standards.fontSize.default}px !important`,
    },
  },
}))

const TabTableDisplay = (props) => {
  return (
    <TableContainer>
      <StyledTable titleWidth={props.titleWidth}>
        <TableBody>
          {props.items.map((item, index) => (
            <TableRow key={index}>
              <TableCell variant="head" align="right">
                <Label color="gray">
                  <pre>{item.label}</pre>
                </Label>
              </TableCell>
              <TableCell>
                <pre>
                  <Label className="content">
                    {item.label === '作業内容' && <InnerHTML className="InnerHtml" html={item.data} />}
                    {item.label !== '作業内容' && item.data}
                  </Label>
                </pre>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

TabTableDisplay.displayName = 'Tab Table Display'

TabTableDisplay.defaultProps = {
  titleWidth: 180,
}

TabTableDisplay.propTypes = {
  items: PropTypes.array,
  titleWidth: PropTypes.any,
}

export default TabTableDisplay
