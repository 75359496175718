import React, { useEffect, useState } from 'react'

import { Grid, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { LabelDisplay, Link, Label, WorkerMemberRows, LargeTabs, Heading } from 'components'
import { getWorkerJobList } from 'services/workers'
import { useSelector, useDispatch } from 'react-redux'
import { tabWorkerJobsIndex } from 'utils/common'
import { changeSearchCriteria } from 'services/jobs'
import { actionSetJobStatusDefault, actionSetOfferSearchCriteria } from 'store/jobs/actionCreators'
import { status } from 'utils/config/status'

const StyledHeaders = styled(Grid)(() => {
  return {
    backgroundColor: '#D8D7D7',
    boxShadow: '5px 5px 15px #00000012',
    borderRadius: '15px',
    opacity: '1',
    minWidth: 'fit-content',
    whiteSpace: 'nowwarp',
    wordBreak: 'keep-all',
  }
})

const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    paddingLeft: 15,
    marginRight: 21,
    gap: 5,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start',
    },
    '& button': {
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'start',
      },
      textAlign: 'center',
      '&:nth-of-type(4)': {
        pointerEvents: 'none',
      },
    },
  },
}))

function WorkerMemberMyPageForm() {
  const dispatch = useDispatch()
  const workerList = useSelector((state) => state.workers.workerList)
  const searchParameters = useSelector((state) => state.jobs.search)
  const user = useSelector((state) => state.auth.user)
  const isLoading = useSelector((state) => state.loader)
  const [tabs, setTabs] = useState([])
  const [tabContent, setTabContent] = useState()
  const [detailTab, setDetailTab] = useState()
  const [isFirstTab, setFirstTab] = useState(true)
  const [btnLink, setbtnLink] = useState('')
  const [seeAllLink, setSeeAllLink] = useState('')

  // Get Worker List
  useEffect(() => {
    if (user?.worker_id) {
      dispatch(getWorkerJobList(user?.worker_id))
    }
  }, [user?.worker_id])

  useEffect(() => {
    setTabs([
      `オファー未回答：${workerList?.jobs_offer?.count ?? 0}件`,
      `実績未報告：${workerList?.jobs_waiting_actual_report?.count ?? 0}件`,
      `経費未報告：${workerList?.jobs_waiting_expense_report?.count ?? 0}件`,
    ])
    if (workerList?.jobs_offer?.data) {
      setTabContent(workerList?.jobs_offer?.data)
    }
  }, [workerList?.jobs_offer?.data])

  const onTabChange = (tab) => {
    switch (tab) {
      case tabWorkerJobsIndex.jobs_waiting_actual_report:
        setTabContent(workerList?.jobs_waiting_actual_report?.data)
        setFirstTab(false)
        setDetailTab(1)
        dispatch(
          changeSearchCriteria({
            ...searchParameters,
            status_id: status.job.wait_work_report_submit,
            sortBy: 'date',
            start_date: null,
            end_date: null,
          })
        )
        break
      case tabWorkerJobsIndex.jobs_waiting_expense_report:
        setTabContent(workerList?.jobs_waiting_expense_report?.data)
        setFirstTab(false)
        setDetailTab(2)
        dispatch(
          changeSearchCriteria({
            ...searchParameters,
            status_id: status.job.wait_expense_report_submit,
            sortBy: 'date',
            start_date: null,
            end_date: null,
          })
        )
        break
      default:
        setTabContent(workerList?.jobs_offer?.data)
        setFirstTab(true)
        break
    }
  }

  useEffect(() => {
    if (isFirstTab && user?.worker_id) {
      dispatch(
        actionSetOfferSearchCriteria({
          include_past: false,
          status: Object.values([status.job.offer]).join() ?? '',
          start_date: null,
          end_date: null,
          date_preset: 5,
        })
      )
      setbtnLink('/offer/show/')
      setSeeAllLink('/offer/list')
    } else {
      setbtnLink('/job/show/')
      dispatch(actionSetJobStatusDefault(true))
      setSeeAllLink('/job/list')
    }
  }, [isFirstTab, user?.worker_id])

  return (
    <>
      {isLoading && (
        <Grid xs={12} item textAlign="center" py={32}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <Grid container justifyContent={'center'} alignItems={'center'}>
          <Grid item container justifyContent={'flex-start'} alignItems={'center'} mb={2} xs={12}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Heading>会員マイページ</Heading>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" mt={4}>
              <Grid mr={4}>
                <LabelDisplay width={350} type={'info'}>
                  {`今月の作業費合計: ${workerList?.sum_total_fees}（税込み）`}
                </LabelDisplay>
              </Grid>
              <Grid>
                <Link to="/job/list">
                  <u>{'今月の作業を確認する >'}</u>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <StyledGrid item container xs={12} mr={2} mb={2}>
            <LargeTabs tabs={tabs} onChange={onTabChange}>
              <StyledHeaders
                item
                container
                direction="row"
                justifyContent={'space-around'}
                alignItems={'center'}
                textAlign={{ xs: 'center', sm: 'start' }}
                xs={12}
                mb={2}
                ml={2}
                pl={2}
                height={{ xs: 70, md: 48, lg: 48 }}
              >
                <Grid item xs={3}>
                  <Label>{isFirstTab ? 'オファー作業名' : '作業名'}</Label>
                </Grid>
                <Grid item xs={2.5}>
                  <Label>{'クライアント'}</Label>
                </Grid>
                <Grid item xs={3}>
                  <Label>{'作業日'}</Label>
                </Grid>
                <Grid item xs={2} />
              </StyledHeaders>
              {tabContent &&
                tabContent.map((value, index) => {
                  return (
                    <WorkerMemberRows
                      data={value}
                      key={index}
                      btnLink={btnLink}
                      isFirstTab={isFirstTab}
                      detailTab={detailTab}
                    />
                  )
                })}
            </LargeTabs>
          </StyledGrid>
          <Grid item container justifyContent={'flex-end'} alignItems={'center'} mb={2} xs={12}>
            <Grid item xs={4} md={2} lg={2}>
              <Link to={seeAllLink}>
                <u>{'全てを確認する >'}</u>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}
export default WorkerMemberMyPageForm
