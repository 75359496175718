export default {
  padding: {
    label: {
      default: '0px',
      inputLabel: '8px',
    },
    input: {
      default: '0px 0px 28px 0px',
    },
    title: {
      default: '0px 0px 40px 0px',
    },
    content: {
      default: '35px 45px 35px 45px',
      mobile: '35px 15px 35px 15px !important',
    },
    page: {
      default: '30px 0px 30px 0px',
      sm_container: '80px 0px 80px 0px',
    },
    info: {
      default: '50px',
    },
    modal: {
      body: '30px 22px 30px 22px',
    },
    button: '0px 18px 0px 18px',
  },
  border: {
    box: '15px',
    color: '#D9D9D9 !important',
  },

  container: {
    sm: '555px !important',
    md: '680px !important',
    lg: '1024px !important',
    xl: '1400px !important',
    sm_modal: '383px !important',
    md_modal: '480px !important',
    lg_modal: '754px !important',
  },

  fontSize: {
    default: 14,
    input_label: 14,
  },

  buttonHeight: {
    sm: 38,
    md: 42,
    lg: 48,
  },

  inputHeight: {
    sm: 38,
    md: 42,
    lg: 48,
    checkbox: 24,
    radio: 24,
  },

  modal: {
    header: {
      height: 71,
    },
  },

  footer: {
    height: 75,
  },

  header: {
    height: 75,
  },
}
