import axios from 'axios'
import store from 'store/config'

import { showNotification } from 'store/notification/actionCreators'
import { actionSet404Page } from 'store/common/actionCreators'
import { signOut } from 'store/auth/actionCreators'

// create new instance
const Http = axios.create()

// set default config
Http.defaults.baseURL = process.env.REACT_APP_API_URL
Http.defaults.headers.common.Accept = 'application/json'

/**
 * intercept the response so we can handle the
 * expected exceptions from the API
 */
Http.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    /**
     * This could be a CORS issue or
     * a dropped internet connection.
     */
    if (typeof error.response.status === 'undefined' || error.response.status === 0) {
      store.dispatch(showNotification('大変申し訳ありませんが、時間をおいて再度お試しください。', false))
    }

    switch (error.response.status) {
      // The following case clauses are wrapped into blocks using brackets to avoid eslint no-case-declarations
      case 401: {
        const state = store.getState()

        if (state.auth.isAuthenticated) {
          store.dispatch(signOut())
        }

        break
      }
      case 404:
        store.dispatch(actionSet404Page(true))
        break
      case 500:
      case 562:
      case 563:
      case 567:
      case 568:
      case 570:
        store.dispatch(showNotification('大変申し訳ありませんが、時間をおいて再度お試しください。', false))
        break
      default:
        store.dispatch(actionSet404Page(false))
        break
    }

    return Promise.reject(error)
  }
)

export default Http
