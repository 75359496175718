import React, { useEffect, useState } from 'react'
import { Grid, Box, Stack, Divider, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { getCancellationFee, readJob } from 'services/jobs'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined'
//utils
import { formatJobDetails, getCurrentDate } from 'utils/helper'
import { tabs, tabIndex } from 'utils/common'
import { status } from 'utils/config/status'

//components
import {
  Label,
  Heading,
  SubHeading,
  Link,
  LargeButton,
  Banner,
  Tabs,
  TabTableDisplay,
  ClientReportFileTab as ReportFileTab,
  ClientExpenseReportTab as ExpensesTab,
  Modal,
  ClientJobProcessConfirmation,
  ClientJobCompletion,
  BackArrow,
} from 'components'

import isEmpty from 'lodash/isEmpty'

const StyledJobDetails = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .JobOutline label': {
    [theme.breakpoints.down('sm')]: {
      display: 'inline-grid',
    },
  },
  '& .DateTimeBanner  .MuiChip-label': {
    height: 'inherit',
    width: '100%',
  },
}))

const JobDetails = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  const reports = Number(params.get('reports'))
  const formData = useSelector((state) => state.jobs.formData)
  const isLoading = useSelector((state) => state.loader)
  const displayData = formData?.formatted

  const [isOpen, setIsOpen] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [action, setAction] = useState('')

  const jobDetailsData = formatJobDetails(displayData, true)

  //Tab Contents
  const JobDetailsTab = <TabTableDisplay items={jobDetailsData} />
  const ReportsFileTab = <ReportFileTab data={displayData} />
  const ExpenseReportTab = <ExpensesTab data={displayData} />

  //set tab state
  const [tabContent, setTabContent] = useState(JobDetailsTab)
  const [activeTab, setActiveTab] = useState(tabIndex.details)

  useEffect(() => {
    let jobId = props?.match?.params?.id
    if (jobId) {
      dispatch(readJob(jobId))
    }
  }, [dispatch])

  useEffect(() => {
    if (reports) {
      setActiveTab(reports)
    }
  }, [reports])

  useEffect(() => {
    if (activeTab === tabIndex.details) {
      setTabContent(JobDetailsTab)
    } else if (activeTab === tabIndex.reports) {
      setTabContent(ReportsFileTab)
    } else {
      setTabContent(ExpenseReportTab)
    }
    if (!isEmpty(displayData)) {
      props.handleData(displayData, activeTab)
    }
  }, [displayData, activeTab])

  const onTabChange = (tab) => {
    setActiveTab(tab)
    switch (tab) {
      case tabIndex.reports:
        setTabContent(ReportsFileTab)
        props.handleData(displayData, 1)
        break
      case tabIndex.expense:
        setTabContent(ExpenseReportTab)
        props.handleData(displayData, 2)
        break
      default:
        setTabContent(JobDetailsTab)
        props.handleData([displayData, 0])
        break
    }
  }

  const getDateTimeComponent = (date, time) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem color="gray" sx={{ borderColor: '#f7f0f0a1' }} />}
        height="inherit"
      >
        <Box width={7 / 12} alignItems="center" display="inline-flex">
          <CalendarMonthOutlinedIcon />
          <Label>&nbsp;{date}</Label>
        </Box>
        <Box width={5 / 12} alignItems="center" display="inline-flex" p={1}>
          <ScheduleOutlinedIcon />
          <Label>&nbsp;{time}</Label>
        </Box>
      </Stack>
    )
  }

  const handleCancel = () => {
    setAction('cancel')
    setIsOpen(true)
    setIsComplete(false)
    dispatch(getCancellationFee(displayData.id, getCurrentDate()))
  }

  const handleCloseCancelModal = () => {
    setIsComplete(false)
    setIsOpen(false)
  }

  const handleCompletion = (completed) => {
    setIsComplete(completed)
    setIsOpen(true)
  }

  const modalTitle = isComplete ? '辞退' : '作業辞退'
  const modalBodyMsg = action === 'cancel' ? '作業をキャンセルしました。' : '作業を辞退しました。'

  const modalContent = isComplete ? (
    <ClientJobCompletion modalBodyMsg={modalBodyMsg} data={displayData} onClose={handleCloseCancelModal} />
  ) : (
    <ClientJobProcessConfirmation
      data={displayData}
      onClose={handleCloseCancelModal}
      completed={handleCompletion}
      action={action}
    />
  )

  function createCopy() {
    const application = displayData?.application
    history.push(
      queryString.stringifyUrl({
        url: '/offer/create',
        query: {
          project: displayData?.project_id,
          job_group: displayData?.job_group_id,
          client: displayData?.client_id,
          worker: displayData?.worker_id,
          job: displayData?.id,
          with_applicants: application,
        },
      })
    )
  }

  return (
    <StyledJobDetails item xs={12}>
      {isLoading && (
        <Grid xs={12} item textAlign="center" py={32}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <React.Fragment>
          {location.state?.fromJobList && <BackArrow label="作業一覧に戻る" path="/job/list" />}
          <Grid item xs={12} pt={5} pb={4} py={3}>
            <Heading>作業詳細</Heading>
          </Grid>
          <Grid container py={3} direction="row-reverse">
            <Grid item container xs={12} sm={6} md={6} justifyContent={{ xs: 'center', sm: 'end' }} pb={{ xs: 2 }}>
              <Grid item xs={12} md={4}>
                {parseInt(displayData?.status_id) === status.job.before_job_start && (
                  <Grid item xs={12} pb={1} textAlign="end">
                    <Link to={'/job/update/' + displayData?.id}>
                      <LargeButton id="JobUpdateBtn" color="primary" mode="light" minwidth={166}>
                        編集
                      </LargeButton>
                    </Link>
                  </Grid>
                )}
                <Grid item xs={12} textAlign="end">
                  <LargeButton id="JobUpdateBtn" color="primary" mode="light" minwidth={166} onClick={createCopy}>
                    コピーしてオファー
                  </LargeButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="JobOutline">
              <Grid item xs={12}>
                <SubHeading color="secondary" bold="true">
                  {displayData?.job_name}
                </SubHeading>
              </Grid>
              <Grid item xs={12} md={6}>
                <Label pb={0.5}>
                  {displayData?.date} {displayData?.start_time}~{displayData?.end_time}
                </Label>
              </Grid>
              <Grid item xs={12} md={6}>
                <Label pb={0.5}>
                  案件: <span>{displayData?.project_alias}</span>
                </Label>
              </Grid>
              <Grid item xs={12} md={6}>
                <Label pb={0.5}>
                  作業グループ:{' '}
                  <Link to={'/job-group/show/' + displayData?.job_group_id} sx={{ textDecoration: 'underline' }}>
                    {displayData?.job_group_alias}
                  </Link>
                </Label>
              </Grid>
              <Grid item xs={12} md={6}>
                <Label pb={0.5}>
                  作業担当者: <span>{displayData?.pic_alias}</span>
                </Label>
              </Grid>
              <Grid item xs={12}>
                <Label pb={0.5}>
                  ワーカー:{' '}
                  <Link to={'/worker/show/' + displayData?.id} sx={{ textDecoration: 'underline' }}>
                    {displayData?.has_before_job_start_log === true
                      ? displayData?.worker_name
                      : displayData?.worker_screen_name}
                  </Link>
                </Label>
              </Grid>
            </Grid>
          </Grid>
          <Grid container py={3} spacing={4}>
            <Grid item xs={12} sm={5} md={4}>
              <Label pb={1}>ステータス</Label>
              <Banner label={displayData?.status_name} variant="outlined" color="success" bold="true" />
            </Grid>
            <Grid item xs={12} sm={8} md={4}>
              <Label pb={1}>ステータス更新日</Label>
              <Banner
                label={getDateTimeComponent(displayData?.status_updated_date, displayData?.status_updated_time)}
                variant="outlined"
                color="gray"
                bold="true"
                mode="dark"
                className="DateTimeBanner"
              />
            </Grid>
            <Grid item xs={12}>
              <Tabs tabs={tabs} value={activeTab} onChange={onTabChange}>
                {tabContent}
              </Tabs>
            </Grid>
            {(parseInt(displayData?.status_id) === status.job.before_job_start ||
              parseInt(displayData?.status_id) === status.job.offer ||
              parseInt(displayData?.status_id) === status.job.working) && (
              <Grid item container xs={12} gap={2} justifyContent="center" textAlign="center">
                <LargeButton id="JobCancelBtn" color="gray" mode="btn1" onClick={handleCancel}>
                  キャンセル
                </LargeButton>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
      <Modal
        open={isOpen}
        title={action === 'cancel' ? '作業キャンセル' : modalTitle}
        onClose={handleCloseCancelModal}
        size={isComplete ? 'sm' : 'md'}
      >
        {modalContent}
      </Modal>
    </StyledJobDetails>
  )
}

JobDetails.displayName = 'Job Details'

JobDetails.propTypes = {
  match: PropTypes.any,
  handleData: PropTypes.func,
}

export default JobDetails
