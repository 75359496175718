import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid } from '@mui/material'
import { LargeButton, Checkbox } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { resignJobWorker, readWorkerJob } from 'services/jobs'
import { showNotification } from 'store/notification/actionCreators'

function JobProcessConfirmation(props) {
  const { data, onClose, completed, action } = props
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.jobs.is_modal_loading)
  const [isChecked, setIsChecked] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const isResignJob = action === 'resign'

  const handleClick = () => {
    setIsChecked(!isChecked)
    setIsDisabled(isChecked)
  }

  const handleConfirm = () => {
    if (isResignJob) {
      dispatch(resignJobWorker(data.id))
        .then(() => {
          dispatch(readWorkerJob(data.id, false))
          onClose()
          completed(true)
        })
        .catch((error) => {
          if (error?.response.data && error?.response.data.code === 500) {
            dispatch(showNotification(error?.response.data.error, false))
          }
        })
    }
  }

  return (
    <div>
      {isLoading && (
        <Grid xs={12} item textAlign="center" py={6}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <Grid container rowSpacing={3} justifyContent="center">
          <Grid item xs={12} md={12} lg={12}>
            {isResignJob && (
              <Grid container columnSpacing={1} justifyContent="center">
                <Grid item xs="auto">
                  <Checkbox onChange={handleClick} label="事前に担当者の承諾を得ている" checked={isChecked} />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid textAlign="center" item xs={6}>
            <LargeButton disabled={isDisabled} type="submit" variant="contained" onClick={handleConfirm}>
              OK
            </LargeButton>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

JobProcessConfirmation.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  completed: PropTypes.func,
  action: PropTypes.string,
}

export default JobProcessConfirmation
