import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper } from '@mui/material'
import { Label } from 'components'

const CategoryDisplay = (props) => {
  const { items } = props

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 2,
        p: 2,
      }}
    >
      <Grid container className="CategoryWrapper">
        {items &&
          items.map((item, index) => {
            return items.length === 1 ? (
              <Grid item textAlign="center" xs={12} pb={3} pl={{ xs: 0, sm: 3 }} key={index}>
                <Label>
                  {props.bullets && '• '}
                  {item.skill_name}
                </Label>
              </Grid>
            ) : (
              <Grid item pl={{ xs: 0, sm: 3 }} xs={12} sm={6} key={index}>
                <Label>
                  {props.bullets && '• '}
                  {item.skill_name}
                </Label>
              </Grid>
            )
          })}
      </Grid>
    </Paper>
  )
}

CategoryDisplay.displayName = 'Category Display'

CategoryDisplay.propTypes = {
  items: PropTypes.any,
  bullets: PropTypes.bool,
}

export default CategoryDisplay
