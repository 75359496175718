import React, { useEffect, useState } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import omit from 'lodash/omit'
import standards from 'theme/standards'

const StyledCheckbox = styled(Checkbox, { shouldForwardProp: (prop) => prop !== 'color' })(
  ({ color, height, width, mode, theme }) => ({
    width: width ?? '',
    height: height ?? '',
    color: `${theme.standards.border.color}`,
    marginRight: 6,
    '& .MuiSvgIcon-root': {
      height: height ?? '',
      width: width ?? '',
    },
    '&.Mui-checked': {
      color: `${theme.palette[color]?.[mode]} !important`,
    },
    '&.Mui-disabled': {
      color: `${theme.palette?.lightGray?.dark} !important`,
    },
  })
)
const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  alignItems: 'start',
  '& .MuiFormControlLabel-label.Mui-disabled': {
    color: `${theme.palette?.text?.primary} !important`,
  },
}))

const CustomCheckbox = React.forwardRef((props, ref) => {
  const [checkedValue, setValue] = useState(props.checked || false)
  const excludedProps = ['onChange', 'InputProps', 'errorMsg', 'required']
  let defaultProps = omit(props, excludedProps)
  const { onChange, checked, label } = props

  useEffect(() => {
    setValue(checked ? checked : false)
    ref?.onChange({ target: { name: props.name, value: checked ? checked : false } })
  }, [checked])

  const handleChange = (e) => {
    onChange(e)
    setValue(e.target.checked)
  }
  return (
    <StyledFormControlLabel
      sx={{ margin: 0 }}
      label={label}
      control={
        <StyledCheckbox
          {...defaultProps}
          {...ref}
          checked={checkedValue || false}
          onChange={(e) => {
            ref?.onChange(e)
            onChange ? handleChange(e) : setValue(e.target.checked)
          }}
        />
      }
    />
  )
})

CustomCheckbox.displayName = 'Checkbox'

CustomCheckbox.defaultProps = {
  color: 'primary',
  mode: 'main',
  height: standards.inputHeight.checkbox,
  width: standards.inputHeight.checkbox,
}

CustomCheckbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  fontSize: PropTypes.number,
}

export default CustomCheckbox
