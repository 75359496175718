import React from 'react'
import PropTypes from 'prop-types'
import { Select as MUISelect, MenuItem, FormHelperText, FormControl as MUIFormControl } from '@mui/material'
import { styled } from '@mui/material/styles'
import omit from 'lodash/omit'
import standards from 'theme/standards'

const FormControl = styled(MUIFormControl, {
  shouldForwardProp: (prop) => prop,
})(({ width, height, fontSize, theme, align }) => ({
  width: width,
  height: height,
  boxSizing: 'border-box',
  fontSize: fontSize,
  textAlign: align,
  '& .Mui-Select': {
    height: height,
  },
  '& fieldset': {
    border: '1px solid ',
    borderRadius: 5,
    borderColor: '#D9D9D9 !important',
  },
  '&:hover fieldset': {
    borderColor: '#D9D9D9 !important',
  },
  '& .Mui-error': {
    marginLeft: 0,
    '& fieldset': {
      borderColor: `${theme.palette['error'].main}`,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette['error'].main}`,
    },
  },
}))

const Select = React.forwardRef((props, ref) => {
  const excludedProps = ['menuItems', 'placeholder', 'width', 'height', 'fontSize', 'onChange']
  let defaultProps = omit(props, excludedProps)
  const { menuItems, placeholder, width, height, fontSize, errormsg, align } = props
  return (
    <FormControl width={width} height={height} fontSize={fontSize} align={align}>
      <MUISelect
        className="Mui-Select"
        {...defaultProps}
        {...ref}
        MenuProps={{
          style: {
            maxHeight: 400,
          },
        }}
        onChange={props.onChange && ((e) => props.onChange(e.target.value))}
      >
        {placeholder && (
          <MenuItem disabled value={-1} selected id={'menuSelectItem-Placeholder-ID'}>
            {placeholder}
          </MenuItem>
        )}
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            value={item.id}
            id={'menuItem' + index + '-' + defaultProps.name + '-ID'}
            disabled={item.disabled || false}
          >
            {item.name}
          </MenuItem>
        ))}
      </MUISelect>
      {errormsg && <FormHelperText error={props.error}>{errormsg}</FormHelperText>}
    </FormControl>
  )
})

Select.displayName = 'Input Select'

Select.defaultProps = {
  height: standards.inputHeight.md,
  width: '100%',
  fontSize: standards.fontSize.default,
  align: 'start',
}

Select.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  menuItems: PropTypes.array,
  width: PropTypes.any,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  errormsg: PropTypes.string,
  error: PropTypes.bool,
  align: PropTypes.string,
}

export default Select
