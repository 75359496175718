import React, { useState, Fragment } from 'react'
import { Grid, Box } from '@mui/material'
import PropTypes from 'prop-types'
import searchIcon from 'assets/svg/search.svg'
import AddIcon from '@mui/icons-material/Add'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getStatusByType, linkGenerator } from 'utils/helper'
import * as dayjs from 'dayjs'
import {
  datePresets,
  thisDay,
  thisMonthStart,
  thisMonthEnd,
  prevMonthStart,
  prevMonthEnd,
  nextMonthStart,
  nextMonthEnd,
  dateFormat,
} from 'utils/common'
import {
  LargeButton,
  InputLabel,
  InputSelect,
  InputMultiSelect,
  TableList,
  Heading,
  InputDatePicker,
  Icon,
  Caption,
} from 'components'
import { resetCurrentForm, setJobGroupFormData } from 'store/job-groups/actionCreators'
import { useHistory } from 'react-router-dom'
import { getMessages } from 'validation'
import { status } from 'utils/config/status'
import { changeSearchCriteria } from 'services/application'
import { changeSearchClientCriteria } from 'services/jobs'
import { useMatchLastLocation } from 'utils/hooks'
import { routes } from 'router/routes/clientRoutes'

function JobGroupListForm(props) {
  const isFromProjectList = useMatchLastLocation(routes.projectList)
  const prefectureslist = useSelector((state) => state.common.prefectureslist)
  const [datePresetValue, setdatePresetValue] = useState(isFromProjectList ? 5 : 1)
  const [dateFromValue, setDateFromValue] = useState(thisMonthStart)
  const [dateToValue, setDateToValue] = useState(thisMonthEnd)
  const [selectedRow, setSelectedRow] = useState({})
  const [dateFromChanged, setDateFromChanged] = useState(false)
  const [dateToChanged, setDateToChanged] = useState(false)
  const { search: searchParameters } = useSelector((state) => state.applications)
  const allStatus = useSelector((state) => state.common.statusList)
  const jobGroupStatus = getStatusByType(allStatus, 'Job Group')
  const isClient = localStorage.getItem('mode').includes('client')

  const { formData, search } = useSelector((state) => state.jobGroups)

  const dispatch = useDispatch()
  const history = useHistory()
  const rulesMsg = getMessages('ja')

  const { under_edition, can_apply, close_application, completed } = status.job_group
  const defaultJobGroupStatus = [under_edition, can_apply, close_application, completed]
  jobGroupStatus.splice(-1) //removes abort('中断') status on list

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm()

  const hasErrors = Object.keys(errors).length > 0

  const validationRules = {
    name: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    project_name: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    start_date: {
      validate: (value) => {
        const isValid = dayjs(value, dateFormat, true).isValid()
        return isValid || value === '' || rulesMsg.invalid_date()
      },
    },
    end_date: {
      validate: (value) => {
        const isValid = dayjs(value, dateFormat, true).isValid()
        const startDateVal = getValues('start_date')
        if (!isValid && value !== '') {
          return rulesMsg.invalid_date()
        } else if (dayjs(value).isBefore(startDateVal)) {
          return rulesMsg.end_date_invalid
        }
        return true
      },
    },
  }

  const handleSearch = (data) => {
    props.handlePageSearch({
      ...data,
      prefecture: data.prefecture.toString() !== '0' ? data.prefecture : '',
      status_id: data.status_id.toString(),
    })
  }

  const handleDatePreset = (preset) => {
    switch (preset) {
      case 2: //prev month
        setDateFromValue(prevMonthStart)
        setDateToValue(prevMonthEnd)
        break
      case 3: //next month
        setDateFromValue(nextMonthStart)
        setDateToValue(nextMonthEnd)
        break
      case 4: //this day
        setDateFromValue(thisDay)
        setDateToValue(thisDay)
        break
      case 1: //this month
        setDateFromValue(thisMonthStart)
        setDateToValue(thisMonthEnd)
        break
      default:
        break
    }

    setdatePresetValue(preset)
  }

  const onDateChange = (newVal, type) => {
    setdatePresetValue(5)
    if (type == 'start_date') {
      setDateFromValue(newVal)
      setDateFromChanged(true)
      //if date from is set greater than date to, then adjust date to
      if (dayjs(newVal).diff(dayjs(dateToValue)) >= 1) {
        setDateToValue(newVal)
      }
    } else {
      setDateToChanged(true)
      setDateToValue(newVal)
    }
  }

  const handleSelectedRow = (rowData) => {
    setSelectedRow(rowData)
  }

  const handleCreate = () => {
    dispatch(resetCurrentForm())
    history.push(`/${isClient ? 'job-group' : 'job_group'}/create`)
  }

  const handleShowDetails = () => {
    history.push({
      pathname: `/${isClient ? 'job-group' : 'job_group'}/show/${selectedRow.id}`,
      state: { fromJobGroupList: true },
    })
  }

  const handleEdit = () => {
    handleClearCurrentFormData()
    history.push(`/${isClient ? 'job-group' : 'job_group'}/update/${selectedRow.id}`)
  }

  const handleCopy = () => {
    handleClearCurrentFormData()
    history.push(`/${isClient ? 'job-group' : 'job_group'}/create/${selectedRow.id}`)
  }

  const handleClearCurrentFormData = () => {
    dispatch(setJobGroupFormData({ ...formData, current: null }))
  }

  const actionList = [
    {
      handler: handleShowDetails,
      name: '閲覧',
    },
    {
      handler: handleEdit,
      name: '編集',
    },
    {
      handler: handleCopy,
      name: 'コピー',
    },
  ]

  const handleSearchApplication = (name, status) => {
    dispatch(
      changeSearchCriteria({ ...searchParameters, job_group: name, application_status: status, job_group_status: [] })
    )
  }

  const handleSearchJob = (name, status, startDate, endDate) => {
    dispatch(
      changeSearchClientCriteria({
        ...searchParameters,
        job_group_name: name,
        status_id: status,
        start_date: startDate,
        end_date: endDate,
        date_preset: 5,
      })
    )
  }

  const formatListData = (list) => {
    return list.map((item) => ({
      ...item,
      name: (
        <Grid container direction="row" justifyContent="center" columnSpacing={0.5}>
          <Grid item>
            {linkGenerator({
              path: `/job-group/show/${item.id}`,
              details: item.name,
              methodHandler: handleClearCurrentFormData,
            })}
          </Grid>
          <Grid item>{<Caption sx={{ color: 'black' }}>{`(${item.registered_date}登録)`}</Caption>}</Grid>
        </Grid>
      ),
      project_name: (
        <Grid container direction="row" justifyContent="center" columnSpacing={0.5}>
          <Grid item>{item.project_name.split(' (ID: ')[0]}</Grid>
          <Grid item>
            {<Caption sx={{ color: 'black' }}>{`(ID: ${item.project_name.split(' (ID: ')[1]}`}</Caption>}
          </Grid>
        </Grid>
      ),
      start_date: item.start_date || item.date_range.split('～')[0],
      end_date: item.end_date || item.date_range.split('～')[1],
      applications: (
        <Fragment>
          {linkGenerator({
            path: `/application/list`,
            details:
              'applications' in item
                ? item.applications?.filter(({ status_id }) => status_id == status.application.unprocessed).length
                : 0,
            methodHandler: handleSearchApplication,
            name: `"${item?.id}"`,
            status: status.application.unprocessed.toString(),
          })}
          {' / '}
          {linkGenerator({
            path: `/application/list`,
            details: 'applications' in item ? item.applications.length : 0,
            methodHandler: handleSearchApplication,
            name: `"${item?.id}"`,
            status: [],
          })}
        </Fragment>
      ),
      jobs: (
        <Fragment>
          {linkGenerator({
            path: `/job/list`,
            details:
              'jobs' in item
                ? item.jobs?.filter(
                    ({ status_id }) =>
                      status_id >= status.job.before_job_start && status_id <= status.job.wait_expense_report_approve
                  ).length
                : 0,
            methodHandler: handleSearchJob,
            name: `"${item?.id}"`,
            status: Object.entries(status.job)
              .filter(([, val]) => val >= status.job.before_job_start && val <= status.job.wait_expense_report_approve)
              .map(([, val]) => val)
              .toString(),
            startDate: ' ',
            endDate: ' ',
          })}
          {' / '}
          {linkGenerator({
            path: `/job/list`,
            details: 'jobs' in item ? item.jobs.length : 0,
            methodHandler: handleSearchJob,
            name: `"${item?.id}"`,
            status: '',
            startDate: ' ',
            endDate: ' ',
          })}
        </Fragment>
      ),
    }))
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={7} md={8} lg={9}>
        <Heading>{'作業グループ一覧'}</Heading>
      </Grid>
      <Grid item xs={12} sm={5} md={4} lg={3} textAlign="end" sx={{ marginTop: { xs: 2, sm: 0 } }}>
        <LargeButton
          id="jobGroupCreateBtn-ID"
          startIcon={<AddIcon size={'sm'} style={{ color: '#ffffff' }} />}
          onClick={handleCreate}
        >
          {'作業グループ新規登録'}
        </LargeButton>
      </Grid>
      <Box component="form" onSubmit={handleSubmit(handleSearch)} className="form" pt={6}>
        <Grid container columnSpacing={2} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <InputLabel
              id="jobGroup-name-ID"
              ref={register('name', validationRules.name)}
              error={errors && errors.name ? true : false}
              errorMsg={errors ? errors?.name?.message : null}
              name="name"
              label={'作業グループ名・ID'}
              value={search?.name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <InputLabel
              id="jobGroup-project-ID"
              ref={register('project_name', validationRules.project_name)}
              error={errors && errors.project_name ? true : false}
              errorMsg={errors ? errors?.project_name?.message : null}
              name="project_name"
              label={'案件名・ID'}
              value={search?.project_id || search?.project_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <InputSelect
              id="jobGroup-prefecture-ID"
              ref={register('prefecture')}
              name="prefecture"
              label={'都道府県'}
              menuItems={[{ id: '0', name: 'すべて' }, ...prefectureslist]}
              defaultValue={parseInt(search?.prefecture) || 0}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={1.5}>
            <InputSelect
              id="jobGroup-date_preset-ID"
              name="date_preset"
              label={'作業開始日'}
              menuItems={datePresets}
              value={datePresetValue}
              onChange={(e) => handleDatePreset(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3.5} lg={3} xl={2.5}>
            <InputDatePicker
              id="jobGroup-start_date-ID"
              ref={register('start_date', validationRules.start_date)}
              error={errors && errors.start_date ? true : false}
              errorMsg={errors ? errors?.start_date?.message : null}
              name="start_date"
              label={'開始日'}
              color="primary"
              inputFormat={dateFormat}
              value={dayjs(dateFromChanged ? dateFromValue : isFromProjectList ? search.start_date : dateFromValue)}
              onChange={(newVal) => onDateChange(newVal, 'start_date')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3.5} lg={3} xl={2.5}>
            <InputDatePicker
              id="jobGroup-end_date-ID"
              ref={register('end_date', validationRules.end_date)}
              error={errors && errors.end_date ? true : false}
              errorMsg={errors ? errors?.end_date?.message : null}
              name="end_date"
              label={'終了日'}
              color="primary"
              inputFormat={dateFormat}
              value={dayjs(dateToChanged ? dateToValue : isFromProjectList ? search.end_date : dateToValue)}
              onChange={(newVal) => onDateChange(newVal, 'end_date')}
              minDate={dayjs(dateFromValue)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2.5}>
            <InputMultiSelect
              id="jobGroup-status-ID"
              ref={register('status_id')}
              name="status_id"
              label={'ステータス'}
              menuItems={jobGroupStatus}
              defaultValue={isFromProjectList ? [] : defaultJobGroupStatus}
              enableEmpty
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={12}
            lg={2}
            sx={{
              paddingTop: { xs: 3.5 },
              textAlign: { md: 'right', xl: 'left' },
              marginTop: { md: hasErrors ? -4.85 : -2, lg: 0 },
              marginBottom: { md: hasErrors ? 1.85 : -3.5, lg: 0 },
            }}
          >
            <LargeButton
              id="jobGroup-submitBtn-ID"
              type="submit"
              bold="true"
              color="warning"
              startIcon={<Icon size={'sm'} disabled={props.isLoading ? true : false} source={searchIcon} />}
            >
              {'検索'}
            </LargeButton>
          </Grid>
        </Grid>
      </Box>
      <Box width={1}>
        <Grid item xs={12} sx={{ marginTop: { md: hasErrors ? 0.65 : 6, lg: hasErrors ? -2.95 : 0 } }}>
          <TableList
            title={`JobGroup List`}
            headCells={props.headerCells}
            data={formatListData(props.data)}
            totalPage={props.totalPage}
            totalCount={props.totalCount}
            handlePageChange={props.handlePageChange}
            isLoading={props.isLoading}
            handleSort={props.handleSort}
            handleSelectedRow={handleSelectedRow}
            sort={props.sort}
            sortBy={props.sortBy}
            currentPage={props.currentPage}
            action={true}
            actionList={actionList}
            pageFrom={props.pageFrom}
            pageTo={props.pageTo}
          />
        </Grid>
      </Box>
    </Grid>
  )
}

JobGroupListForm.displayName = 'JobGroup List Form'

JobGroupListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

export default JobGroupListForm
