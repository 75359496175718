import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { Heading, LargeButton, ContentDisplay, Label, Icon, SubHeading } from 'components'
import { setConfirming } from 'store/client/actionCreators'
import { createUpdateClient } from 'services/client'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SuccessIcon from 'assets/svg/success.svg'
import { formatAddress } from 'utils/helper'
import PropTypes from 'prop-types'

const ClientConfirmForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const displayData = useSelector((state) => state.client.formData)
  const isUpdate = useSelector((state) => state.client.is_update)
  const isConfirming = useSelector((state) => state.client.isConfirming)
  const loader = useSelector((state) => state.loader)
  const [isSubmit, setIsSubmit] = useState(false)
  const [confirmTitle, setConfirmTitle] = useState()
  const [subConfirmTitle, setSubConfirmTitle] = useState()
  const [successMainTitle, setSuccessMainTitle] = useState()
  const [successSubTitle, setSuccessSubTitle] = useState()
  const { handleUpdateTitle } = props
  const handleConfirm = () => {
    const formData = displayData.current
    const formId = isUpdate ? formData?.id : null

    dispatch(createUpdateClient(formData, formId)).then(() => {
      setIsSubmit(true)
    })
  }

  useEffect(() => {
    if (isSubmit) {
      handleUpdateTitle(isSubmit)
    }
  }, [isSubmit])

  useEffect(() => {
    if (!isSubmit && (!isConfirming || !displayData.current)) {
      history.push('/client/create')
    }
    return () => {
      dispatch(setConfirming(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (isUpdate) {
      setConfirmTitle('クライアント編集確認')
      setSubConfirmTitle('以下の内容で更新しますか？')
      setSuccessMainTitle('クライアント編集完了')
      setSuccessSubTitle('編集完了しました。')
    } else {
      setConfirmTitle('クライアント登録確認')
      setSubConfirmTitle('以下の内容で登録しますか？')
      setSuccessMainTitle('クライアント登録完了')
      setSuccessSubTitle('登録完了しました。')
    }
  }, [isUpdate])

  return (
    <>
      {!isSubmit ? (
        <Grid container>
          <Grid item xs={12}>
            <Grid item container xs={12} alignItems="center" mb={3}>
              <Grid item container xs={12} mb={4} justifyContent="flex-start" alignItems="flex-start">
                <Heading>{confirmTitle}</Heading>
              </Grid>
              <Grid item container xs={12} justifyContent="flex-start" alignItems="flex-start">
                <Label>{subConfirmTitle}</Label>
              </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-start" mb={2}>
              <ContentDisplay label="法人名:" data={displayData?.current?.name} />
              <ContentDisplay label="法人住所:" data={formatAddress(displayData.current)} />
              <ContentDisplay label="WEBサイト:" data={displayData?.current?.website} />
              <ContentDisplay label="法人代表電話番号:" data={displayData?.current?.phone_number} />
              <ContentDisplay label="法人メールアドレス:" data={displayData?.current?.email} />
              <ContentDisplay label="内部メモ:" data={displayData?.current?.internal_memo} />
            </Grid>

            <Grid container pt={4} justifyContent="flex-end" alignItems="flex-end">
              <Grid item mr={2}>
                <LargeButton id="WorkerSubmitBtn" color="gray" onClick={history.goBack}>
                  {'戻る'}
                </LargeButton>
              </Grid>
              <Grid item>
                <LargeButton id="WorkerSubmitBtn" onClick={handleConfirm} type="submit" color="primary">
                  {loader && <CircularProgress size="1.5rem" color="white" />}
                  {!loader && '確定'}
                </LargeButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="Confirmation" justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} pt={{ xs: 1, sm: 4 }}>
            <Icon source={SuccessIcon} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: { xs: 2 }, pt: { xs: 4 } }}>
            <Heading>{successMainTitle}</Heading>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: { xs: 2 }, pt: { xs: 1 } }}>
            <SubHeading>{successSubTitle}</SubHeading>
          </Grid>
          <Grid item xs={12} pt={{ xs: 4 }}>
            <Link to="/client-user/create">
              <LargeButton
                id="ChangePasswordConfirmBtn"
                color="primary"
                mode="light"
                width={240}
                maxwidth="unset"
                maxheight="unset"
              >
                {'クライアント担当者を登録'}
              </LargeButton>
            </Link>
          </Grid>
          <Grid item xs={12} pt={{ xs: 1 }}>
            <Link to="/client/list">
              <LargeButton id="ChangePasswordConfirmBtn" color="primary" mode="light" width={240} maxwidth="unset">
                {'クライアント一覧へ'}
              </LargeButton>
            </Link>
          </Grid>
        </Grid>
      )}
    </>
  )
}

ClientConfirmForm.propTypes = {
  handleUpdateTitle: PropTypes.func,
}

export default ClientConfirmForm
