import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Label, TableList, LargeButton, Modal, ReportsModalContent } from 'components'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { actualReportStatus, downloadActualReport, readJob } from 'services/jobs'
import { downloadFile } from 'utils/common'
import { status } from 'utils/config/status'

const ReportFileTab = (props) => {
  const dispatch = useDispatch()
  const { data } = props
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const [modalState, setModalState] = useState({ modalTitle: '', modalBodyMsg: '' })
  const [isRequesting, setIsRequesting] = useState(false)
  const reportData = data?.report_files.map((item) => {
    return {
      ...item,
      updated_at: item?.updated_date + ' ' + item?.updated_time,
    }
  })

  const headerCells = [
    { id: 'filename', label: 'ファイル名', minWidth: 400 },
    { id: 'updated_at', label: 'アップロード日時' },
  ]

  const handleDownload = (e, rowData) => {
    downloadActualReport(data.id, rowData?.id).then((response) => {
      downloadFile(rowData?.filename, response.data)
    })
  }

  //approve reports
  const handleApprove = () => {
    setModalType('approve')
    setIsOpenModal(true)
    setConfirmed(false)
  }
  const handleApproveConfirm = () => {
    setIsRequesting(true)
    actualReportStatus('accepted', data.id, {}).then((response) => {
      if (response.data.code == 200) {
        setIsRequesting(false)
        setConfirmed(true)
      }
    })
  }

  //reject reports
  const handleReject = () => {
    setModalType('reject')
    setIsOpenModal(true)
    setConfirmed(false)
  }
  const handleRejectConfirm = (formData) => {
    setIsRequesting(true)
    actualReportStatus('rejected', data.id, formData).then((response) => {
      if (response.data.code == 200) {
        setIsRequesting(false)
        setConfirmed(true)
      }
    })
  }

  //close modal
  const handleModalClose = () => {
    if (confirmed) {
      dispatch(readJob(data.id, false))
    }
    setIsOpenModal(false)
  }

  useEffect(() => {
    const approveModalTitle = modalType === 'approve' ? (!confirmed ? '実績報告承認' : '作業実績報告') : ''
    const rejectModalTitle = modalType === 'reject' ? (!confirmed ? '実績報告差し戻し' : '実績報告差し戻し') : ''
    const approveModalBodyMsg =
      modalType === 'approve' ? (!confirmed ? '以下の作業実績報告を承認しますか？' : '実績報告を承認しました。') : ''
    const rejectModalBodyMsg =
      modalType === 'reject'
        ? !confirmed
          ? '以下の作業実績報告を差し戻しますか？'
          : '作業実績報告を差し戻しました。'
        : ''
    setModalState({
      ...modalState,
      modalTitle: modalType === 'approve' ? approveModalTitle : rejectModalTitle,
      modalBodyMsg: modalType === 'approve' ? approveModalBodyMsg : rejectModalBodyMsg,
    })
  }, [isOpenModal, confirmed, modalType])

  return (
    <Grid container p={4}>
      <Label bold="true" pb={1}>
        実績報告
      </Label>
      <Grid item xs={12} pb={2}>
        <pre className="ReportDescriptionWrapper">{data?.actual_report_description}</pre>
      </Grid>
      <Grid item xs={12}>
        <TableList
          title={`Job Report List`}
          headCells={headerCells}
          data={reportData}
          handleDownload={handleDownload}
          noSort
          noPaginate
          isReportsActions={{ download: true }}
          no_data_msg="レポートは提出されていません"
        />
      </Grid>

      <Grid item container justifyContent={{ xs: 'center', md: 'end' }} gap={3} pt={2} xs={12}>
        <LargeButton
          id="RejectReportsBtn"
          color="gray"
          mode="btn1"
          onClick={handleReject}
          disabled={
            isRequesting ||
            data?.actual_report_status_id !== status.job.wait_work_report_approve ||
            data?.actual_report_status_id == status.actual_report.accepted ||
            data?.actual_report_status_id == status.actual_report.declined
              ? true
              : false
          }
        >
          差し戻し
        </LargeButton>
        <LargeButton
          id="ApproveReportsBtn"
          onClick={() => {
            handleApprove()
          }}
          disabled={
            isRequesting ||
            data?.actual_report_status_id !== status.job.wait_work_report_approve ||
            data?.actual_report_status_id == status.actual_report.accepted
              ? true
              : false
          }
        >
          承認
        </LargeButton>
      </Grid>
      <Modal
        open={isOpenModal}
        title={modalState?.modalTitle}
        lg={4}
        xl={3}
        onClose={handleModalClose}
        size={confirmed ? 'sm' : 'md'}
      >
        <ReportsModalContent
          data={data}
          confirmed={confirmed}
          modalType={modalType}
          modalState={modalState}
          handleApproveConfirm={() => handleApproveConfirm()}
          handleRejectConfirm={handleRejectConfirm}
          handleModalClose={handleModalClose}
          isRequesting={isRequesting}
        />
      </Modal>
    </Grid>
  )
}

ReportFileTab.propTypes = {
  data: PropTypes.object,
}

export default ReportFileTab
