export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const SEARCH_USER = 'SEARCH_USER'
export const SET_SEARCH_CRITERIA = 'SET_USER_SEARCH_CRITERIA'
export const CLEAR_MODAL_VALUES = 'CLEAR_MODAL_VALUES'
export const SET_MODAL_VALUES = 'SET_MODAL_VALUES'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
export const SET_TOKEN_RESPONSE = 'SET_TOKEN_RESPONSE'
export const SET_ERROR = 'SET_ERROR'
export const PENDING = 'PENDING'
export const CONFIRMED = 'CONFIRMED'
export const SUCCESSFUL = 'SUCCESSFUL'
export const FAILED = 'FAILED'
