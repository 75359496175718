import { combineReducers } from 'redux'
import auth from './auth/reducer'
import loader from './loader/reducer'
import notification from './notification/reducer'
import users from './users/reducer'
import projects from './projects/reducer'
import workers from './workers/reducer'
import jobGroups from './job-groups/reducer'
import invoice from './invoice/reducer'
import results from './results/reducer'
import clientUsers from './client-user/reducer'
import applications from './application/reducer'
import jobSeeker from './job-seeker/reducer'
import client from './client/reducer'
import systemUsage from './system-usage/reducer'

//common reducer
import common from './common/reducer'
import jobs from './jobs/reducer'
const reducers = combineReducers({
  auth,
  loader,
  common,
  notification,
  users,
  projects,
  jobs,
  workers,
  jobGroups,
  invoice,
  results,
  clientUsers,
  applications,
  jobSeeker,
  client,
  systemUsage,
})

export const rootReducer = (state, action) => {
  const { type } = action
  switch (type) {
    case 'RESET': {
      return reducers(undefined, action)
    }
  }
  return reducers(state, action)
}

export default reducers
