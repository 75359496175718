import React, { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { setRedirectRoute, actionSet404Page } from 'store/common/actionCreators'

import Main from 'layouts/main'
import { Loader } from 'components'
import NotFound from '../views/NotFound'

function Private(props) {
  const dispatch = useDispatch()
  const { component, layout, mode, ...rest } = props
  const authUser = JSON.parse(localStorage.getItem('authUser'))
  const history = useHistory()
  const isNotFound = useSelector((state) => state.common.notFound)

  useEffect(() => {
    if (authUser) {
      switch (mode) {
        case 'wkr':
          switch (authUser?.member_type) {
            case '会員': //member
              return <Redirect to={'/job/search'} />
            case 'ワーカー': //worker
              return <Redirect to={'/my-page'} />
            default:
              return <Redirect to={'/login'} />
          }
        default:
          return <Redirect to={'/login'} />
      }
    } else {
      dispatch(setRedirectRoute(props?.location?.pathname + (props?.location?.search || '') ?? null))
      history.push('/login')
    }
  }, [authUser])

  useEffect(() => {
    dispatch(actionSet404Page(false))
  }, [location.pathname])

  const Layout = layout ? lazy(() => import(`../layouts/${layout}`)) : Main
  const Component = lazy(() => import(`../${component}`))
  const renderLoader = Loader

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={renderLoader()}>
          {isNotFound && <NotFound />}
          {!isNotFound && (
            <Layout mode={mode}>
              <Component {...props} />
            </Layout>
          )}
        </Suspense>
      )}
    />
  )
}

Private.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  location: PropTypes.object,
  mode: PropTypes.string,
}

export default Private
