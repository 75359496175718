import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import omit from 'lodash/omit'

const StyledInput = styled(TextField, {
  shouldForwardProp: (prop) => prop != 'borderColor' && prop != 'borderRadius',
})(({ width, height, fontSize, borderRadius, borderColor, disabled, theme }) => ({
  padding: 0,
  '& input': {
    width: width,
    height: height,
    boxSizing: 'border-box',
    fontSize: fontSize,
    '&.Mui-disabled': {
      background: `${disabled ? theme.palette.disabled : ''}`,
    },
  },
  '& textarea': {
    width: width,
    height: `${height}px !important`,
    boxSizing: 'border-box',
    fontSize: fontSize,
    overflow: 'auto !important',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: `${theme.palette['lightGray'].light} !important`,
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${theme.palette['lightGray'].main} !important`,
      borderRadius: 3,
    },
  },
  '& fieldset': {
    border: '1px solid ',
    borderRadius: borderRadius,
    borderColor: borderColor ? `${borderColor} !important` : `${theme.standards.border.color}`,
  },
  '&:hover fieldset': {
    borderColor: borderColor ? `${borderColor} !important` : `${theme.standards.border.color}`,
  },
  '& .Mui-error': {
    '& fieldset': {
      borderColor: `${theme.palette['error'].main}`,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette['error'].main}`,
    },
    marginLeft: 0,
  },
  '& .MuiFormHelperText-contained': {
    marginLeft: 0,
    color: `${theme.palette.black}`,
  },
  '& .MuiInputBase-multiline.Mui-disabled': {
    background: `${disabled ? theme.palette.disabled : ''}`,
  },
}))

const FormInput = React.forwardRef((props, ref) => {
  const [inputValue, setValue] = useState(props.value || props.defaultValue)
  const excludedProps = [
    'onChange',
    'InputProps',
    'errorMsg',
    'endAdorment',
    'required',
    'value',
    'defaultValue',
    'isDisabled',
    'borderColor',
    'borderRadius',
  ]
  let defaultProps = omit(props, excludedProps)
  const { errorMsg, onChange, endAdorment, width, height, fontSize, isDisabled, borderColor, borderRadius } = props

  const handleChange = (e) => {
    onChange(e)
  }
  return (
    <StyledInput
      {...defaultProps}
      {...ref}
      disabled={isDisabled}
      fullWidth
      hiddenLabel
      width={width}
      height={height}
      fontSize={fontSize}
      borderColor={borderColor}
      borderRadius={borderRadius}
      helperText={errorMsg || ''}
      defaultValue={inputValue || ''}
      InputProps={{
        endAdornment: endAdorment ? endAdorment : null,
      }}
      onChange={(e) => {
        onChange ? handleChange(e) : setValue(e.target.value)
      }}
    />
  )
})

FormInput.displayName = 'FormInput'

FormInput.defaultProps = {
  height: 67,
  width: '100%',
  fontSize: 14,
  borderRadius: 5,
}

FormInput.propTypes = {
  onChange: PropTypes.func,
  errorMsg: PropTypes.string,
  endAdorment: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  isDisabled: PropTypes.bool,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.number,
}

export default FormInput
