export default [
  {
    path: '/',
    component: 'views/common/Home',
  },
  {
    path: '/login',
    component: 'views/common/Login',
  },
  {
    path: '/forgot_password',
    component: 'views/common/ForgotPassword',
  },
  {
    path: '/password/reset',
    component: 'views/common/ResetPassword',
  },
  {
    path: '/terms_of_use',
    component: 'views/common/TermsCondition',
  },
  {
    path: '/privacy_policy',
    component: 'views/common/PrivacyPolicy',
  },
]
