import React from 'react'
import { Tabs, Tab } from '@mui/material'
import { a11yProps } from './Tabs'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  marginBottom: 20,
  color: `${theme.palette.text['primary']}`,
  flex: '1 1 auto',
  '& .MuiTab-root': {
    color: `${theme.palette.text['primary']}`,
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
    '&.Mui-selected': {
      color: `${theme.palette.text['primary']}`,
    },
  },
  '& .MuiTabs-scroller': {
    height: 75,
  },
}))

const LargeTabs = (props) => {
  const [value, setValue] = React.useState(props.value || 0)
  const handleChange = (e, newValue) => {
    setValue(newValue)
    props.onChange(newValue)
  }
  return (
    <>
      <StyledTabs value={value} onChange={handleChange}>
        {props.tabs.map((item, index) => (
          <Tab key={index} label={item} {...a11yProps(index)} />
        ))}
      </StyledTabs>
      {props.children}
    </>
  )
}

LargeTabs.propTypes = {
  tabs: PropTypes.array,
  children: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.number,
}

export default LargeTabs
