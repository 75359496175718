import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
//components
import { LargeButton, Label } from 'components'
//services
import { cancelApplication } from 'services/application'

function RejectApplicationContent(props) {
  const dispatch = useDispatch()
  const [confirmed, setConfirmed] = useState(false)
  const isLoading = useSelector((state) => state.loader)
  const { data, onClose } = props

  //on cancel application
  const handleCancelApplication = async () => {
    await dispatch(cancelApplication(data?.id)).then(() => {
      setConfirmed(true)
    })
  }

  return (
    <Grid container justifyContent="center" pt={2}>
      <Grid item xs={12} textAlign="center">
        <Label>{confirmed ? '以下の応募者の見送りが完了しました。' : '以下の応募者を見送りますか？'}</Label>
      </Grid>
      <Grid item xs={12} justifyContent="center" display="inline-flex" pt={4}>
        <Label color="gray">応募者表示名:</Label>
        <Label>{data?.screen_name}</Label>
      </Grid>
      <Grid item container xs={12} justifyContent="center" textAlign="center" gap={2} pt={4}>
        {!confirmed && (
          <React.Fragment>
            <LargeButton id="CloseBtn" color="gray" mode="btn1" disabled={isLoading} onClick={onClose}>
              いいえ
            </LargeButton>
            <LargeButton id="ConfirmBtn" type="submit" disabled={isLoading} onClick={() => handleCancelApplication()}>
              はい
            </LargeButton>
          </React.Fragment>
        )}
        {confirmed && (
          <LargeButton id="ConfirmBtn" type="submit" onClick={onClose}>
            OK
          </LargeButton>
        )}
      </Grid>
    </Grid>
  )
}

RejectApplicationContent.propTypes = {
  data: PropTypes.any,
  onClose: PropTypes.func,
}

export default RejectApplicationContent
