import React from 'react'
import * as types from './actionTypes'
import { formulateCurrentPage, formulatePageResult, formatJobOutline } from '../../utils/helper'
import { jobListDefaultStatusId, status, clientJobListRequiredStatusId } from 'utils/config/status'
import { Link } from 'components'
import { thisMonthStart, thisMonthEnd } from 'utils/common'
const initialState = {
  list: [],
  offerList: [],
  offerForm: {},
  offerAction: '',
  is_status_filtered: false,
  is_confirming: false,
  is_update: false,
  is_modal_loading: false,
  cancellationFee: null,
  cancellationFeeFormatted: null,
  formData: {
    current: null,
    formatted: null,
    original: null,
  },
  formErrors: {},
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 20,
    prevPageUrl: null,
    nextPageUrl: null,
    pageFrom: 0,
    pageTo: 0,
  },
  search: {
    mode: localStorage.getItem('mode'),
    project_id: '',
    name: '',
    project_name: '',
    client_name: '',
    filter_by1: 0,
    filter_txt1: '',
    filter_by2: 0,
    filter_txt2: '',
    start_date: thisMonthStart,
    end_date: thisMonthEnd,
    status_id: jobListDefaultStatusId.toString(),
    keyword: '',
    area: '',
    prefecture: '',
    page: 1,
    limit: 20,
    sortBy: 'registered_date',
    sort: 'desc',
  },
  offerSearch: {
    mode: localStorage.getItem('mode'),
    keyword: '',
    area: '',
    start_date: thisMonthStart,
    end_date: thisMonthEnd,
    status: Object.values([status.job.offer]).join() ?? '',
    include_past: false,
    page: 1,
    limit: 20,
    sortBy: 'offer_date',
    sort: 'desc',
    date_preset: '',
  },
  searchClient: {
    name: '',
    worker_name: '',
    job_group_name: '',
    project_name: '',
    start_date: '',
    end_date: '',
    prefecture: '',
    status_id: clientJobListRequiredStatusId.slice(0, 9).toString(),
    page: 1,
    limit: 20,
    sortBy: 'date',
    sort: 'asc',
    date_preset: '',
  },
  totalFeeTax: '',
  jobOutline: {},
  checkClockOut: false,
  isDeleted: false,
  error: {},
  reportStatus: false,
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SEARCH_JOB: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pageSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }
    case types.SET_JOB_FORM_DATA:
      return {
        ...state,
        formData: {
          current: {
            ...state.formData?.current,
            ...payload?.current,
          },
          formatted: {
            ...state.formData?.formatted,
            ...payload?.formatted,
          },
          original: {
            ...state.formData?.original,
            ...payload?.original,
          },
        },
      }
    case types.GET_JOB_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload.data,
        success: true,
      }
    case types.GET_JOB_DETAILS_FAILED:
      return {
        ...state,
        details: null,
        success: false,
        error: payload.error,
      }
    case types.GET_WORKER_JOB_LIST: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )
      const list = payload.data.map((item) => ({
        ...item,
        job_name: (
          <Link to={`/job/show/${item.id}`} sx={{ textDecoration: 'underline' }}>
            {item.job_name}
          </Link>
        ),
      }))

      return {
        ...state,
        list: list,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
        totalFeeTax: payload.meta.worker_total_fee_tax.formatted,
      }
    }
    case types.SET_JOB_SEARCH_CRITERIA: {
      const {
        filter_by1,
        filter_txt1,
        filter_by2,
        filter_txt2,
        prefecture,
        page,
        sort,
        sortBy,
        keyword,
        area,
        start_date,
        end_date,
        status_id,
      } = payload
      let statusId = typeof status_id === 'object' ? status_id.join() : status_id
      return {
        ...state,
        search: {
          ...state.search,
          filter_by1,
          filter_txt1,
          filter_by2,
          filter_txt2,
          prefecture,
          page,
          keyword,
          area,
          start_date,
          end_date,
          status_id: statusId,
          sort,
          sortBy,
        },
      }
    }
    case types.SET_JOB_STATUS_DEFAULT: {
      return {
        ...state,
        is_status_filtered: payload,
      }
    }
    case types.SET_SEARCH_OFFER_CRITERIA: {
      const { status: offerStatus } = payload
      return {
        ...state,
        offerSearch: {
          ...state.offerSearch,
          ...payload,
          status: typeof offerStatus === 'object' ? offerStatus.join() : offerStatus,
        },
      }
    }
    case types.SEARCH_OFFER: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      return {
        ...state,
        offerList: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pageSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }
    case types.FETCHING_DATA_FAILED:
      return {
        ...state,
        error: payload,
      }
    case types.SET_CANCELLATION_FEE:
      return {
        ...state,
        cancellationFee: payload.cancellation_fee,
        cancellationFeeFormatted: payload.cancellation_fee_formatted,
      }
    case types.SHOW_MODAL_LOADER:
      return {
        ...state,
        is_modal_loading: true,
      }
    case types.HIDE_MODAL_LOADER:
      return {
        ...state,
        is_modal_loading: false,
      }
    case types.SET_CONFIRMING_DATA:
      return {
        ...state,
        is_confirming: payload,
      }
    case types.SET_FORM_MODE:
      return {
        ...state,
        is_update: payload,
      }
    case types.RESET_CURRENT_FORM:
      return {
        ...state,
        formData: {
          ...initialState.formData,
        },
        formErrors: {},
      }
    case types.RESET_CLIENT_SEARCH_PARAMS: {
      return {
        ...state,
        searchClient: {
          ...initialState.searchClient,
        },
      }
    }
    case types.SET_JOB_OUTLINE_DETAILS:
      return {
        ...state,
        jobOutline: payload.data,
      }
    case types.POPULATE_JOB_OUTLINE: {
      const {
        one_day_before_recipients,
        departure_recipients,
        arrival_recipients,
        clock_in_recipients,
        clock_out_recipients,
      } = payload
      return {
        ...state,
        jobOutline: {
          ...formatJobOutline(payload).data,
          one_day_before_recipients,
          departure_recipients,
          arrival_recipients,
          clock_in_recipients,
          clock_out_recipients,
        },
      }
    }
    case types.CHECK_CLOCKOUT: {
      return {
        ...state,
        checkClockOut: payload,
      }
    }
    case types.SET_REPORT_STATUS: {
      return {
        ...state,
        reportStatus: payload,
      }
    }
    case types.SET_ACCEPT_REJECT_OFFER: {
      return {
        ...state,
        offerForm: payload,
      }
    }
    case types.SET_OFFER_ACTION: {
      return {
        ...state,
        offerAction: payload,
      }
    }
    case types.SEARCH_CLIENT_JOB: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )
      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pageSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        searchClient: {
          ...state.searchClient,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }
    case types.SET_SEARCH_CLIENT_CRITERIA: {
      const {
        name,
        worker_name,
        job_group_name,
        project_name,
        start_date,
        end_date,
        prefecture,
        status_id,
        limit,
        page,
        sort,
        sortBy,
        date_preset,
      } = payload
      let statusId = typeof status_id === 'object' ? status_id.join() : status_id
      return {
        ...state,
        searchClient: {
          ...state.searchClient,
          name,
          worker_name,
          job_group_name,
          project_name,
          start_date,
          end_date,
          prefecture,
          status_id: statusId,
          limit,
          page,
          sort,
          sortBy,
          date_preset,
        },
      }
    }
    case types.SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: {
          ...payload,
        },
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default reducer
