import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { LargeButton, P, Label } from 'components'

const StyledForm = styled('form')(() => ({
  display: 'contents',
}))

function JobCompletion(props) {
  const { modalBodyMsg, data, onClose } = props

  return (
    <Grid container rowSpacing={6} justifyContent="center">
      <Grid item xs={12} textAlign="center" pb={2}>
        <P>{modalBodyMsg}</P>
      </Grid>
      <StyledForm>
        <Grid item xs={4} textAlign="end">
          <Label color="gray">作業名:</Label>
        </Grid>
        <Grid item xs={6} pl={1}>
          <Label>{data?.job_name}</Label>
        </Grid>
        <Grid item xs={4} textAlign="end">
          <Label color="gray">作業日:</Label>
        </Grid>
        <Grid item xs={6} pl={1}>
          <Label>
            {data?.date} {data?.start_time}~{data?.end_time}
          </Label>
        </Grid>
        <Grid item xs={4} textAlign="end">
          <Label color="gray">ワーカー:</Label>
        </Grid>
        <Grid item xs={6} pl={1}>
          <Label>{data?.worker_name}</Label>
        </Grid>
      </StyledForm>
      <Grid textAlign="center" item xs={6}>
        <LargeButton id="ConfirmBtn-ID" onClick={onClose}>
          OK
        </LargeButton>
      </Grid>
    </Grid>
  )
}

JobCompletion.propTypes = {
  modalBodyMsg: PropTypes.string,
  data: PropTypes.obj,
  onClose: PropTypes.func,
}

export default JobCompletion
