import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Menu as MenuIcon } from '@mui/icons-material'
import { Link } from 'components'
import { IconButton, Typography, Menu, MenuItem } from '@mui/material'
import { filterMenuItems } from 'utils/helper'
import { useSelector } from 'react-redux'

function NavigatorMenu(props) {
  const { onNavOpen, onNavClose, menuItems, navAnchor } = props
  const { user } = useSelector((state) => state.auth)

  let links = filterMenuItems(localStorage.getItem('mode'), menuItems, user)

  return (
    <Fragment>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(e) => onNavOpen(e)}
        color="inherit"
        sx={{
          padding: 0,
        }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={navAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(navAnchor)}
        onClose={() => onNavClose()}
        sx={{
          display: { xs: 'block' },
        }}
      >
        {links.map(({ name, link }, index) => (
          <Link to={link} key={`${name}-${index}`}>
            <MenuItem id={`${name}-${index}`} onClick={() => onNavClose()}>
              <Typography textAlign="center">{name}</Typography>
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </Fragment>
  )
}

NavigatorMenu.propTypes = {
  onNavOpen: PropTypes.func,
  onNavClose: PropTypes.func,
  menuItems: PropTypes.array,
  navAnchor: PropTypes.any,
}

export default NavigatorMenu
