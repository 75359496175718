const en = {
  translation: {
    // define translations below
    auth: {
      required: 'This field is required.',
      email: 'The email format is invalid.',
      password: {
        minLength: 'Password must be at least 8 characters.',
        confirm: 'Password confirmation does not match.',
        strong: 'Password must contain the following: 1 uppercase, 1 special character and a minimum of 8 characters.',
      },
    },
  },
}

export default en
