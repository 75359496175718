import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Label } from 'components'
import { styled } from '@mui/material/styles'

const StyledLabel = styled(Label)(() => {
  return {
    position: 'inherit',
    overflowWrap: 'break-word',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
  }
})

const RecipientsDisplay = (props) => {
  const { label, blockStyle, labelAlign, data } = props

  return (
    <Grid item container mt={2} spacing={2}>
      <Grid item textAlign={labelAlign || 'left'} xs={12} {...(!blockStyle && { sm: 5.75, md: 5, lg: 4 })}>
        <StyledLabel color="gray">
          <pre>{label}</pre>
        </StyledLabel>
      </Grid>
      <Grid item xs={12} {...(!blockStyle && { xs: 12, sm: 5.25, md: 7 })}>
        <StyledLabel>
          <pre>{data?.one_day_before_recipients && '前日連絡 (' + data?.one_day_before_recipients + ')'}</pre>
          <pre>{data?.departure_recipients && '出発連絡 (' + data?.departure_recipients + ')'}</pre>
          <pre>{data?.arrival_recipients && '現着連絡 (' + data?.arrival_recipients + ')'}</pre>
          <pre>{'入館連絡 (' + data?.clock_in_recipients + ')'}</pre>
          <pre>{'退館連絡 (' + data?.clock_out_recipients + ')'}</pre>
        </StyledLabel>
      </Grid>
    </Grid>
  )
}

RecipientsDisplay.displayName = 'Recipients Display'

RecipientsDisplay.propTypes = {
  label: PropTypes.any,
  data: PropTypes.any,
  blockStyle: PropTypes.bool,
  labelAlign: PropTypes.any,
}

export default RecipientsDisplay
