import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import { setClientUserFormData } from 'store/client-user/actionCreators'
import {
  actionSearchClient,
  actionSetSearchCriteria,
  setClientFormData,
  fetchingDataFail,
} from 'store/client/actionCreators'

export function createUpdateClient(data, id = null) {
  const url = id == null ? 'op-center/clients' : `op-center/clients/${id}`
  return (dispatch) => {
    dispatch(showLoader())
    const request = id == null ? Http.post(url, data) : Http.put(url, data)
    return request
      .then((response) => {
        const clientUserData = {
          ...response?.data?.data,
          client: { id: response?.data?.data?.id, name: response?.data?.data?.name },
          name: '',
          phone_number: '',
          email: '',
        }
        dispatch(setClientUserFormData({ current: clientUserData }))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function confirmClient(data) {
  return async (dispatch) => {
    let response_data = {}
    response_data['current'] = data
    response_data['original'] = null
    dispatch(setClientFormData(response_data))
  }
}

export function searchClients(searchParameters) {
  return async (dispatch) => {
    dispatch(showLoader())
    return await Http.get('op-center/clients', {
      params: searchParameters,
    })
      .then((response) => {
        dispatch(actionSearchClient(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function changeSearchCriteria(searchParameters) {
  return (dispatch) => {
    dispatch(actionSetSearchCriteria(searchParameters))
  }
}

export function getClient(id) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get(`op-center/clients/${id}`)
      .then((response) => {
        let formData = []
        formData['current'] = {}
        formData['original'] = response.data.data
        dispatch(setClientFormData(formData))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}
