import React from 'react'
import { Tabs as MuiTabs, Tab, Box } from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const StyledTabs = styled(MuiTabs, {
  shouldForwardProp: (prop) => prop,
})(({ mode, color, theme }) => ({
  padding: 0,
  color: `${theme.palette.black}`,
  '& .MuiTab-root': {
    borderRadius: '8px 8px 0px 0px',
    minWidth: 176,
    backgroundColor: `${theme.palette.background.gray}`,
    marginRight: 4,
    color: `${theme.palette.black}`,
    '&.Mui-selected': {
      backgroundColor: `${theme.palette[color][mode]}`,
      color: `${theme.palette.white}`,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: `${theme.palette[color][mode]}`,
  },
}))

const StyledTabPannel = styled(Box, {
  shouldForwardProp: (prop) => prop,
})(({ mode, color, theme }) => ({
  padding: 0,
  borderRadius: '0px 8px 0px 0px',
  borderTop: `11px solid ${theme.palette[color][mode]}`,
  '& .TabPannelContainer': {
    border: `1px solid ${theme.palette.background.gray}`,
    borderRadius: '0px 0px 8px 8px',
  },
}))

export function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

const Tabs = (props) => {
  const [value, setValue] = React.useState(props.value || 0)

  const handleChange = (e, newValue) => {
    setValue(newValue)
    props.onChange(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs">
          {props.tabs.map((item, index) => (
            <Tab key={index} label={item} {...a11yProps(index)} />
          ))}
        </StyledTabs>
      </Box>
      <StyledTabPannel role="tabpanel" id={`tabpanel-${value}`} aria-labelledby={`tab-${value}`}>
        <Box className="TabPannelContainer">{props.children}</Box>
      </StyledTabPannel>
    </Box>
  )
}
Tabs.displayName = 'Tabs'

StyledTabs.defaultProps = {
  color: 'gray',
  mode: 'dark',
}

StyledTabPannel.defaultProps = {
  color: 'gray',
  mode: 'dark',
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  children: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.number,
}

export default Tabs
