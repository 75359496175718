import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Box } from '@mui/material'
import { Label, Heading, SubHeading, P } from 'components'
import { privacyPolicySectionDetails } from '../../../../utils/config/staticContents'

const StyledContent = styled('ol', {
  shouldForwardProp: (prop) => !['isSubcontent'].includes(prop),
})(() => ({
  marginLeft: 15,
  ['li']: {
    listStyleType: 'none',
  },
  ['li::marker']: {
    content: `"・ "`,
  },
}))

const StyledSubContent = styled('ol', {
  shouldForwardProp: (prop) => !['isSubcontent'].includes(prop),
})(() => ({
  fontSize: 14,
  marginTop: 5,
  marginLeft: -15,
  ['li']: {
    listStyleType: 'none',
  },
  ['li::marker']: {
    content: `""`,
  },
}))

function PrivacyPolicyContent() {
  const noMarginIds = [
    'privacy-section-2-1',
    'privacy-section-2-2',
    'privacy-section-5-1-3',
    'privacy-section-5-2',
    'privacy-section-6-1',
    'privacy-section-6-2',
    'privacy-section-10-1',
    'privacy-section-10-2',
    'privacy-section-11-1',
    'privacy-section-11-2',
  ]

  return (
    <Fragment>
      {privacyPolicySectionDetails.map(({ id, chapter, info, title, isSubcontent, contentDetails }) => (
        <Fragment key={`${id}-${chapter}`}>
          {chapter && (
            <Grid item xs={12} mt={2}>
              <Heading id={chapter} color="secendary" bold="true">
                {chapter}
              </Heading>
            </Grid>
          )}
          {title && (
            <Grid item xs={12} mt={2}>
              <SubHeading id={id} color="secondary" bold="true">
                {title}
              </SubHeading>
            </Grid>
          )}
          {info.includes('WEB') && (
            <Grid item xs={12} mt={noMarginIds.includes(id) ? 0 : 2}>
              <Label display={'contents'}>
                弊社は、サービス会員登録の際に、あるいは弊社への
                <Box sx={{ fontWeight: 'bold', display: 'inline' }}>WEB</Box>
                サイト問い合わせフォーム・電話・メールにてご連絡をいただく際に、ユーザーの個人情報をご提供いただいております。
              </Label>
            </Grid>
          )}
          {info && !info.includes('WEB') && (
            <Grid item xs={12} mt={noMarginIds.includes(id) ? 0 : 2}>
              <Label bold={info.includes('e-mail')}>{info}</Label>
            </Grid>
          )}
          {contentDetails.length > 0 && (
            <Grid item xs={12} mt={2}>
              <StyledContent isSubcontent={isSubcontent}>
                {contentDetails.map(({ content, subContent }) => (
                  <li key={`${content}-${id}`}>
                    <P id={id}>{content}</P>
                    {subContent && (
                      <StyledSubContent isSubcontent={isSubcontent}>
                        {subContent.map((item) => (
                          <li key={`${item}-${id}`}>
                            <P>{item}</P>
                          </li>
                        ))}
                      </StyledSubContent>
                    )}
                  </li>
                ))}
              </StyledContent>
            </Grid>
          )}
        </Fragment>
      ))}
    </Fragment>
  )
}

export default PrivacyPolicyContent
