import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import LocationIcon from 'assets/svg/Location.svg'
import ClientIcon from 'assets/svg/Client.svg'
import { useSelector } from 'react-redux'
//components
import { SubHeading, Label, SmallBanner, IconLabel, LabelDisplay, Icon, Card } from 'components'
//common
import { member_type } from 'utils/config/status'

const StyledJobGroupCard = styled(Card)(({ theme }) => ({
  boxShadow: `0 0 5px ${theme.palette.lightGray.light} !important`,
  height: '100%',
  cursor: 'pointer',
  '& div, .MuiGrid-root, .MuiTypography-root': {
    cursor: 'pointer',
  },
  '&:hover': {
    boxShadow: `0 0 5px ${theme.palette.primary.main} !important`,
  },
  '& .JobGroupCard-LabelDisplay': {
    padding: 8,
    borderRadius: '6px !important',
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: theme.standards.fontSize.default,
      fontWeight: 'normal !important',
    },
  },
  '& .JobGroupCard-ClientDisplay': {
    alignItems: 'flex-start',
    '& img': {
      paddingRight: '2px',
      paddingTop: '3px',
    },
  },
}))

const JobGroupCard = (props) => {
  const auth = useSelector((state) => state?.auth?.user)
  return (
    <StyledJobGroupCard borderRadius={6}>
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <SubHeading bold="true">{props?.data?.name}</SubHeading>
          <Label>{props?.data?.date_range}</Label>
        </Grid>
        <Grid item>
          <SmallBanner label={props?.data?.status} color={props?.data?.status == '募集中' ? 'error' : 'lightGray'} />
        </Grid>
        <Grid item xs={12}>
          <IconLabel label={props?.data?.prefecture} icon={<Icon source={LocationIcon} />} pt={1} />
          <IconLabel
            className="JobGroupCard-ClientDisplay"
            label={
              auth?.member_type === member_type.worker
                ? props?.data?.client_name
                : 'クライアント名はワーカー登録後に表示されます'
            }
            icon={<Icon source={ClientIcon} />}
            pb={1}
          />
          <Label pb={0.5}>基本作業費（税抜き)</Label>
          <LabelDisplay type="" width={235} className="JobGroupCard-LabelDisplay" justifyContent="left">
            {props?.data?.fee_range}
          </LabelDisplay>
        </Grid>
      </Grid>
    </StyledJobGroupCard>
  )
}

JobGroupCard.propTypes = {
  data: PropTypes.any,
}

export default JobGroupCard
