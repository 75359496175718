import React from 'react'
import { Card as MuiCard, CardContent } from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

export const StyledCard = styled(MuiCard, {
  shouldForwardProp: (prop) => !['color', 'borderRadius'].includes(prop),
})(({ color, borderRadius, theme }) => ({
  backgroundColor: color ? `${theme.palette.background[color]}` : 'unset',
  borderRadius: borderRadius ?? 0,
  boxShadow: 'none',
  height: '100%',
}))

export const Card = (props) => {
  return (
    <StyledCard {...props}>
      <CardContent>{props.children}</CardContent>
    </StyledCard>
  )
}
Card.propTypes = {
  children: PropTypes.node,
}

export const CardGray = (props) => {
  return <Card {...props} color="light_gray" borderRadius={26} className="TestCard" />
}
