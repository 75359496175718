import React from 'react'
import PropTypes from 'prop-types'
import { Clear as ClearIcon } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { Grid, IconButton } from '@mui/material'

//components
import { DatePicker } from 'components'

const StyledInputDatePicker = styled(Grid)(() => ({
  '& .MuiIconButton-root.RemoveDateIcon': {
    border: '1.5px dashed #808080',
    opacity: 1,
    borderRadius: 0,
    padding: 1,
  },
}))

const DatepickerControl = React.forwardRef((props, ref) => {
  return (
    <StyledInputDatePicker item container spacing={1} justifyContent="space-between">
      <Grid item xs={10.2} sm={10} md={10.9} lg={11}>
        <DatePicker {...props} ref={ref} />
      </Grid>
      <Grid item mt={0.7}>
        <IconButton onClick={() => props.onRemove(props.dataID)} disableFocusRipple={true} className="RemoveDateIcon">
          <ClearIcon />
        </IconButton>
      </Grid>
    </StyledInputDatePicker>
  )
})

DatepickerControl.displayName = 'Date Picker Control'

DatepickerControl.propTypes = {
  onRemove: PropTypes.func,
  dataID: PropTypes.any,
}

export default DatepickerControl
