import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InputLabel } from 'components'
import { Icon, Label, LargeButton, Heading, Link, SubHeading } from 'components/atoms'
import { Divider, Grid, CircularProgress } from '@mui/material'
import standards from 'theme/standards'
import { useForm } from 'react-hook-form'
import { registerWorkerEmail } from 'services/workers'
import { useDispatch } from 'react-redux'
import { hideNotification } from 'store/notification/actionCreators'
import { authFailSignIn } from 'store/auth/actionCreators'
import EmailPasswordResetIcon from 'assets/svg/EmailPasswordResetIcon.svg'

function WorkerRegistrationForm(props) {
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm()

  const validationRules = {
    email_address: {
      required: {
        value: String,
        message: 'メールアドレスを入力してください。',
      },
      pattern: {
        value: /\S+@\S+\.\S+/, // Regex Email Validation
        message: 'メールアドレスの書式が正しくありません。',
      },
    },
  }

  const handleInputChange = () => {
    //reset auth error on input change
    dispatch(authFailSignIn({}))
  }

  const handleSubmitEmail = (data) => {
    setLoading(true)
    dispatch(registerWorkerEmail(data))
      .then(() => {
        setEmail(data.email_address)
        setIsEmailSent(true)
        props.isSuccess(true)
      })
      .catch((e) => {
        const { code, error } = e.response.data
        // handle API validation error
        if (code === 422 && error) {
          dispatch(hideNotification())
          setIsEmailSent(false)
          setError('email_address', {
            message: 'このメールアドレスはすでに登録されています。ログインしてください。',
          })
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <Grid
      container
      py={'35px'}
      justifyContent="center"
      alignItems="center"
      className={isEmailSent ? 'ConfirmationMd' : ''}
    >
      {!isEmailSent ? (
        <React.Fragment>
          <Grid item xs={12} sx={{ px: { xs: 2, sm: 4 } }}>
            <Heading bold="true" variant="title">
              新規会員登録
            </Heading>
          </Grid>
          <Grid item xs={12} sm={10} md={8} lg={8} sx={{ px: { xs: 2 }, pt: { xs: 1, sm: 4 } }}>
            <form className="form" onSubmit={handleSubmit(handleSubmitEmail)}>
              <InputLabel
                id="LoginEmailInput"
                name="email_address"
                ref={register('email_address', validationRules.email_address)}
                error={errors && errors?.email_address ? true : false}
                errorMsg={errors ? errors?.email_address?.message : null}
                type="text"
                height={standards.inputHeight['lg']}
                placeholder={'メールアドレスを入力ください'}
                onChange={(e) => handleInputChange(e)}
              />
              <LargeButton id="LoginBtn" type="submit" color="primary" maxwidth="inherit">
                {loading ? <CircularProgress size="1.5rem" color="white" /> : '仮登録メールを送信'}
              </LargeButton>
            </form>
          </Grid>
          <Grid item xs={12} py={6}>
            <Label align="center">
              会員登録に伴い、 ワークオンサイト
              <Link id="TermsOfUseLink" to="/terms_of_use" target={'_blank'}>
                利用規約、
              </Link>
              <br />
              <Link id="PrivacyPolicyLink" to="/privacy_policy" target={'_blank'}>
                プライバシーポリシー
              </Link>
              に同意したものとみなされます。
            </Label>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ borderBottomWidth: 1, borderColor: 'rgb(179 178 178)' }} />
            <Label align="center" pt={4}>
              既にアカウントをお持ちですか？
              <Link id="LoginLink" to="/login" color={'secondary'}>
                ログイン
              </Link>
            </Label>
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid container direction="column-reverse" justifyContent="center" alignItems="center" pb={3}>
            <Icon source={EmailPasswordResetIcon}></Icon>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
            <form className="form" onSubmit={handleSubmitEmail}>
              <SubHeading color="secondary" display="flex" justifyContent="center" sx={{ px: { xs: 4 } }}>
                メールアドレス登録および承認をお願いします
              </SubHeading>
              <Grid
                container
                direction="column-reverse"
                justifyContent="center"
                alignItems="center"
                sx={{ px: { xs: 4 }, pt: { xs: 3, sm: 3 } }}
              >
                <Label align="center">
                  {email}承認用メールを送信しました。
                  <br />
                  メールをご確認いただき、記載されたURLをクリックして承認をお願いいたします。
                  <br /> メールが届かない場合、
                  <Link id="RegisterLink" to="/register">
                    こちら
                  </Link>
                  から再度メールアドレスを登録ください。
                </Label>
              </Grid>
            </form>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}

WorkerRegistrationForm.propTypes = {
  mode: PropTypes.string,
  isSuccess: PropTypes.func,
}

export default WorkerRegistrationForm
