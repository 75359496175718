import React from 'react'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Button, Label } from 'components'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

const StyledRows = styled(Grid)(() => {
  return {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D6D6D6',
    borderRadius: '10px',
    opacity: '1',
  }
})

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  padding: 0,
  '& .MuiButtonBase-root-MuiButton-root': {
    maxWidth: 'max-content',
    wordBreak: 'keep-all',
  },
}))

function WorkerMemberRows(props) {
  const history = useHistory()
  const { data, btnLink, isFirstTab, detailTab } = props
  const btnLinkUrl = isFirstTab ? btnLink + data?.offer_id : btnLink + data?.id

  const handleDetail = (btnLinkUrl) => {
    if (isFirstTab) {
      history.push(btnLinkUrl)
    } else {
      history.push(`${btnLinkUrl}?reports=${detailTab}`)
    }
  }

  return (
    <StyledRows
      item
      container
      direction="row"
      justifyContent={'space-around'}
      alignItems={'center'}
      textAlign={{ xs: 'center', sm: 'start' }}
      xs={12}
      mb={2}
      ml={2}
      pl={2}
      minHeight={{ xs: 100, md: 70, lg: 70 }}
      py={1}
    >
      <Grid item xs={3}>
        <Label>{data?.job_name}</Label>
      </Grid>
      <Grid item xs={2.5}>
        <Label>{data?.client_name}</Label>
      </Grid>
      <Grid item xs={3}>
        <Label>{isFirstTab ? data?.offer_date_formatted : data?.job_date}</Label>
      </Grid>
      <Grid item xs={2}>
        <StyledButton
          color={'primary'}
          mode={'main'}
          height={30}
          minwidth={'unset'}
          maxwidth={140}
          onClick={() => handleDetail(btnLinkUrl)}
        >
          {'詳細'}
        </StyledButton>
      </Grid>
    </StyledRows>
  )
}

WorkerMemberRows.propTypes = {
  data: PropTypes.any,
  btnLink: PropTypes.string,
  isFirstTab: PropTypes.bool,
  detailTab: PropTypes.number,
}

export default WorkerMemberRows
