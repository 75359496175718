import React, { useState, useEffect } from 'react'
import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'
import searchIcon from 'assets/svg/search.svg'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
//validation msg
import { getMessages } from 'validation'
//components
import {
  Heading,
  LargeButton,
  TableList,
  InputLabel,
  InputDatePicker,
  InputMultiSelect,
  Icon,
  Link,
  InputSelect,
  Checkbox,
  SmallBanner,
} from 'components'
//store
import { resetCurrentForm } from 'store/jobs/actionCreators'
//utils
import {
  datePresets,
  thisDay,
  thisMonthStart,
  thisMonthEnd,
  prevMonthStart,
  prevMonthEnd,
  nextMonthStart,
  nextMonthEnd,
  dateFormat,
} from 'utils/common'
import { getStatusByType, formatAmount, formatStringToArray } from 'utils/helper'
import { offerListRequiredStatusId, status, settings } from 'utils/config/status'
//services
import { getAllStatusList } from 'services/common'
const OfferListForm = (props) => {
  const rulesMsg = getMessages('ja')
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm()
  //State
  const rawStatusOptions = useSelector((state) => state.common.statusList) ?? []
  const dispatch = useDispatch()
  const offerStatusList = getStatusByType(rawStatusOptions, 'Job', offerListRequiredStatusId)
  const searchValues = useSelector((state) => state.jobs.offerSearch) ?? {}
  const [datePresetValue, setDatePresetValue] = useState(searchValues?.date_preset || 1)
  const [dateFromValue, setDateFromValue] = useState(thisMonthStart)
  const [dateToValue, setDateToValue] = useState(thisMonthEnd)
  const formattedOfferStatusList = offerStatusList.map((item) => {
    return {
      ...item,
      name: item.id === status.job.before_job_start ? status.name.offer.before_job_start : item.name,
    }
  })

  //Use Effects
  useEffect(() => {
    dispatch(getAllStatusList())
    dispatch(resetCurrentForm())
  }, [])

  useEffect(() => {
    setDateFromValue(searchValues?.start_date)
    setDateToValue(searchValues?.end_date)
  }, [searchValues?.start_date, searchValues?.end_date])

  //Form Validation
  const validationRules = {
    keyword: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    area: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    start_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
    end_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        const startDateVal = getValues('start_date')
        if (!isValid) {
          return rulesMsg.invalid_date()
        } else if (dayjs(value).isBefore(startDateVal)) {
          return rulesMsg.is_date_before()
        } else {
          return true
        }
      },
    },
  }

  //Click search button
  const handleSearch = (data) => {
    data['date_preset'] = datePresetValue
    props.handlePageSearch(data)
  }

  //date Preset onchange
  const handleDatePreset = (preset) => {
    switch (preset) {
      case 1: //this month
        setDateFromValue(thisMonthStart)
        setDateToValue(thisMonthEnd)
        break
      case 2: //prev month
        setDateFromValue(prevMonthStart)
        setDateToValue(prevMonthEnd)
        break
      case 3: //next month
        setDateFromValue(nextMonthStart)
        setDateToValue(nextMonthEnd)
        break
      case 4: //this day
        setDateFromValue(thisDay)
        setDateToValue(thisDay)
        break
      default:
        break
    }

    setDatePresetValue(preset)
  }

  //on date change
  const onDateChange = (newVal, type) => {
    setDatePresetValue(5)
    if (type == 'start_date') {
      setDateFromValue(newVal)
      //if date from is set greater than date to, then adjust date to
      if (dayjs(newVal).diff(dayjs(dateToValue)) >= 1) {
        setDateToValue(newVal)
      }
    } else {
      setDateToValue(newVal)
    }
  }

  const getOfferTitle = (item, isCancelled = false) => {
    return (
      <React.Fragment>
        {isCancelled ? item?.job_name : <u>{item?.job_name}</u>}
        {item?.job_group_visibility == settings.limited_public && (
          <>
            &emsp;
            <SmallBanner label={item?.job_group_visibility} color="warning" width="fit-content" />
          </>
        )}
      </React.Fragment>
    )
  }

  //Format table list data
  const formatData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        job_name:
          parseInt(item?.status_id) === status.job.offer_cancel ? (
            getOfferTitle(item, true)
          ) : (
            <Link to={'/offer/show/' + item?.id}>{getOfferTitle(item, false)}</Link>
          ),
        earliest_offer_date:
          parseInt(item?.status_id) === status.job.offer_cancel
            ? ''
            : `${item?.earliest_offer_date}~ (計${item?.num_dates}日間)`,
        fee: formatAmount(item?.fee),
        hideAction: parseInt(item?.status_id) === status.job.offer_cancel ? true : false,
      }
    })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Heading>作業オファー一覧</Heading>
      </Grid>
      <Grid item xs={12} pt={4}>
        <form onSubmit={handleSubmit(handleSearch)} className="JobList-form">
          <Grid item container columnSpacing={1}>
            <Grid item xs={12} sm={6} md={7} lg={8} xl={8}>
              <InputLabel
                ref={register('keyword', validationRules.keyword)}
                id="InputLabel-keyword-ID"
                error={errors && errors.keyword ? true : false}
                errorMsg={errors ? errors?.keyword?.message : null}
                name="keyword"
                label={'キーワード'}
                placeholder=" 作業名、クライアント名を検索"
                value={searchValues?.keyword}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4} xl={4}>
              <InputLabel
                ref={register('area', validationRules.area)}
                id="InputLabel-area-ID"
                error={errors && errors.area ? true : false}
                errorMsg={errors ? errors?.area?.message : null}
                name="area"
                label={'エリア'}
                placeholder="例：”東京都”　”渋谷区”"
                value={searchValues?.area}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={1}>
            <Grid item xs={12} sm={3} md={2.2} lg={1.4} xl={1.5}>
              <InputSelect
                id="InputSelect-date_preset-ID"
                name="date_preset"
                label={'作業日'}
                menuItems={datePresets}
                value={datePresetValue}
                onChange={(e) => handleDatePreset(e)}
              />
            </Grid>
            <Grid item xs={12} sm={4.5} md={2.8} lg={1.8} xl={2}>
              <InputDatePicker
                id="InputDatePicker-start_date-ID"
                ref={register('start_date', validationRules.start_date)}
                error={errors && errors.start_date ? true : false}
                errorMsg={errors ? errors?.start_date?.message : null}
                name="start_date"
                color="primary"
                inputFormat={dateFormat}
                value={dateFromValue ? dayjs(dateFromValue) : ''}
                label="開始日"
                onChange={(newVal) => onDateChange(newVal, 'start_date')}
              />
            </Grid>
            <Grid item xs={12} sm={4.5} md={2.8} lg={1.8} xl={2}>
              <InputDatePicker
                id="InputDatePicker-end_date-ID"
                ref={register('end_date', validationRules.end_date)}
                error={errors && errors.end_date ? true : false}
                errorMsg={errors ? errors?.end_date?.message : null}
                name="end_date"
                color="primary"
                inputFormat={dateFormat}
                value={dateToValue ? dayjs(dateToValue) : ''}
                onChange={(newVal) => onDateChange(newVal, 'end_date')}
                label="終了日"
                minDate={dayjs(dateFromValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4.2} lg={2.5} xl={2.5}>
              <InputMultiSelect
                id="InputMultiSelect-status-ID"
                ref={register('status')}
                name="status"
                label={'応募ステータス'}
                menuItems={formattedOfferStatusList ?? []}
                enableEmpty={true}
                defaultValue={formatStringToArray(searchValues?.status) ?? []}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={2.5} lg={2} xl={2} pt={{ xs: 0, sm: 4.2, md: 1, lg: 4.2 }}>
              <Checkbox
                id="Checkbox-include_past-ID"
                name="include_past"
                ref={register('include_past')}
                checked={searchValues?.include_past}
                label="過去のオファーも含める"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={1.5} pt={{ xs: 3, lg: 3.5 }}>
              <LargeButton
                type="submit"
                id="SearchBtn-ID"
                bold="true"
                color="warning"
                startIcon={<Icon size={'sm'} disabled={props.isLoading ? true : false} source={searchIcon} />}
              >
                検索
              </LargeButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12} pt={{ xs: 4, sm: 0 }}>
        <TableList
          title={`Application List`}
          headCells={props.headerCells}
          data={formatData(props.data)}
          totalPage={props.totalPage}
          totalCount={props.totalCount}
          handlePageChange={props.handlePageChange}
          isLoading={props.isLoading}
          handleSort={props.handleSort}
          sort={props.sort}
          sortBy={props.sortBy}
          currentPage={props.currentPage}
          action={true}
          actionButton={true}
          actionList={props.actionList}
          pageFrom={props.pageFrom}
          pageTo={props.pageTo}
        />
      </Grid>
    </Grid>
  )
}

OfferListForm.displayName = 'Application List Form'

OfferListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
  actionList: PropTypes.any,
}

export default OfferListForm
