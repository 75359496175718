import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { DesktopDatePicker as MUIDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { styled } from '@mui/material/styles'
import omit from 'lodash/omit'
import standards from 'theme/standards'
import { dateFormat } from 'utils/common'

const StyledDatePickerWrapper = styled('div')(({ width, height, fontSize, theme }) => ({
  padding: 0,
  '& input': {
    width: width,
    height: height,
    boxSizing: 'border-box',
    fontSize: fontSize,
    borderColor: '#D9D9D9 !important',
  },
  '& fieldset': {
    border: '1px solid ',
    borderRadius: 5,
    borderColor: '#D9D9D9 !important',
  },
  '&:hover fieldset': {
    borderColor: '#D9D9D9 !important',
  },
  '& .MuiTextField-root': {
    width: width,
  },
  '& .Mui-error': {
    '& fieldset': {
      borderColor: `${theme.palette['error'].main}`,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette['error'].main}`,
    },
    marginLeft: 0,
  },
}))

const DatePicker = React.forwardRef((props, ref) => {
  const excludedProps = ['width', 'height', 'fontSize', 'onChange', 'value']
  let defaultProps = omit(props, excludedProps)
  const { width, height, fontSize, color, value, onChange, placeholder, error, errorMsg } = props

  const [dateValue, setDateValue] = useState(value || null)

  useEffect(() => {
    setDateValue(value || null)
  }, [value])

  return (
    <StyledDatePickerWrapper width={width} height={height} fontSize={fontSize}>
      <MUIDatePicker
        id={`datePicker-${props.name}-ID`}
        fullWidth
        renderInput={(params) => {
          ref?.onChange({ target: { name: props.name, value: params?.inputProps?.value } })
          return (
            <TextField
              id={`inputDatePicker-${props.name}-ID`}
              helperText={errorMsg}
              {...params}
              {...ref}
              error={error}
              onChange={(e) => {
                ref?.onChange(e)
              }}
              inputProps={{
                ...params.inputProps,
                placeholder: placeholder ? placeholder : dateFormat,
              }}
            />
          )
        }}
        components={{
          OpenPickerIcon: CalendarMonthOutlinedIcon,
        }}
        OpenPickerButtonProps={{ color: color, id: 'datePickerIcon-' + props.name + '-ID' }}
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue)
          onChange ? onChange(newValue) : () => {}
        }}
        PaperProps={{ id: `datePickerPaper-${props.name}-ID`, className: 'datePickerPaper-wrapper' }}
        PopperProps={{
          id: `datePickerPopper-${props.name}-ID`,
          placement: 'bottom-end',
        }}
        desktopModeMediaQuery={'@media (pointer: fine)'}
        {...defaultProps}
      />
    </StyledDatePickerWrapper>
  )
})

DatePicker.displayName = 'Date Picker'

DatePicker.defaultProps = {
  height: standards.inputHeight.md,
  width: '100%',
  fontSize: standards.fontSize.default,
}

DatePicker.propTypes = {
  onChange: PropTypes.func,
  errorMsg: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  color: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
}

export default DatePicker
