import { Alert as MuiAlert } from '@mui/material'
import { styled } from '@mui/material/styles'
import standards from 'theme/standards'

const Alert = styled(MuiAlert, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color, mode, fontSize, bold, theme }) => ({
  fontSize: fontSize,
  fontWeight: bold ? 'bold' : 'normal',
  color: theme.palette[color]?.[mode],
  backgroundColor: theme.palette['notice'],
  '.MuiAlert-icon': {
    color: theme.palette[color]?.[mode],
    padding: 0,
    svg: {
      fontSize: '2.5rem',
    },
  },
}))

Alert.defaultProps = {
  color: 'primary',
  mode: 'main',
  fontSize: standards.fontSize.default,
}

export default Alert
