import React from 'react'
import { tableOfContents } from '../../../../utils/config/staticContents'
import { Label, Link } from 'components'
import { styled } from '@mui/material/styles'

const StyleContentHeader = styled('ul')(() => ({
  listStyleType: 'none',
}))

const StyledSection = styled('ul')(() => ({
  listStyleType: 'none',
  marginLeft: 20,
}))

function TermsTableContent() {
  return (
    <StyleContentHeader>
      {tableOfContents.map(({ chapter, section }) => (
        <li key={`${chapter}-content-head`}>
          <Label pt={4.38}>
            <Link
              to={`#${chapter}`}
              onClick={() =>
                document.querySelector(`#${chapter}`).scrollIntoView({
                  behavior: 'smooth',
                })
              }
            >
              {chapter}
            </Link>
          </Label>
          <StyledSection>
            {section.map(({ id, title }) => (
              <li key={`${id}-content-section`}>
                <Label>
                  <Link
                    to={`#section-${id}`}
                    onClick={() =>
                      document.querySelector(`#section-${id}`).scrollIntoView({
                        behavior: 'smooth',
                      })
                    }
                  >
                    {title}
                  </Link>
                </Label>
              </li>
            ))}
          </StyledSection>
        </li>
      ))}
    </StyleContentHeader>
  )
}

export default TermsTableContent
