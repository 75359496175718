import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { createContactLog } from 'services/jobs'
import { readWorkerJobReport } from 'services/jobs'
import { BackArrow, Heading, Label, InputTextArea, LargeButton, JobOutline } from 'components'
import { populateJobOutline } from 'store/jobs/actionCreators'
import standards from 'theme/standards'

function WorkerReportForm(props) {
  const [recipientList, setRecipientList] = useState([
    {
      id: -1,
      job_name: '',
      project_name: '',
      job_group_name: '',
      client_name: '',
      job_date_time: '',
      client_contact_name: '',
      client_contact_email: '',
      client_contact_phone_number: '',
      one_day_before_recipients: '',
      departure_recipients: '',
      arrival_recipients: '',
      clock_in_recipients: '',
      clock_out_recipients: '',
    },
  ])
  const [isPopulated, setIsPopulated] = useState(false)
  const [jobListIdIndex, setJobListIdIndex] = useState(0)
  const { list, jobOutline, formData } = useSelector((state) => state.jobs)
  const displayData = formData?.formatted
  const isLoading = useSelector((state) => state.loader)
  const jobId = parseInt(location.pathname.split('/')[2])
  const contactType = parseInt(location.pathname.split('/')[3])
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  useEffect(() => {
    dispatch(readWorkerJobReport(jobId))
  }, [dispatch])

  useEffect(() => {
    setRecipientList(removeDuplicates([...recipientList, ...list]))
    setIsPopulated(list?.some(({ id }) => id !== -1))
    isPopulated && updateJobOutline(jobId)
  }, [list, isPopulated])

  useEffect(() => {
    setValue('email', reportData[contactType]?.emailRecipient)
    setValue('type', reportData[contactType]?.type)
    setValue('job_id', jobId)
  }, [jobListIdIndex])

  const updateJobOutline = (jobId) => {
    let idIndex = 0

    idIndex = recipientList.map(({ id }) => id).indexOf(jobId)
    idIndex = idIndex < 0 ? 0 : idIndex

    dispatch(populateJobOutline(recipientList[idIndex]))
    return setJobListIdIndex(idIndex)
  }

  const emailPreviousDay = displayData?.one_day_before_recipients
  const emailDeparture = displayData?.departure_recipients
  const emailArrival = displayData?.arrival_recipients
  const emailClockIn = displayData?.clock_in_recipients
  const emailClockOut = displayData?.clock_out_recipients

  const validationRules = {
    comment: {
      maxLength: {
        value: 512,
        message: '512文字以内で入力してください。',
      },
    },
  }

  const removeDuplicates = (list) => {
    return [...new Map(list.map((item) => [item.id, item])).values()]
  }

  const handleForm = (data) => {
    dispatch(createContactLog(data)).then(() => {
      data.email && history.push(`/job/show/${jobId}`)
    })
  }

  const convertToArray = (data) => {
    return data?.replace(/ /g, '')?.split(',')
  }

  const listIsEmpty = (list) => {
    return Object.keys(list).length === 0
  }

  const reportData = {
    1: {
      title: '前日連絡',
      type: 'one_day_before',
      emailRecipient: emailPreviousDay,
      canSendReport: displayData?.is_previous_contact_enabled,
    },
    2: {
      title: '出発連絡',
      type: 'departure',
      emailRecipient: emailDeparture,
      canSendReport: displayData?.is_departure_contact_enabled,
    },
    3: {
      title: '現着連絡',
      type: 'arrival',
      emailRecipient: emailArrival,
      canSendReport: displayData?.is_arrival_contact_enabled,
    },
    4: {
      title: '入館連絡',
      type: 'clock_in',
      emailRecipient: emailClockIn,
      canSendReport: displayData?.is_clockin_contact_enabled,
    },
    5: {
      title: '退館連絡',
      type: 'clock_out',
      emailRecipient: emailClockOut,
      canSendReport: displayData?.is_clockout_contact_enabled,
    },
  }
  setValue('email', reportData[contactType]?.emailRecipient)

  const EmailRecipientContent = (
    <Fragment>
      {convertToArray(reportData[contactType]?.emailRecipient)?.map((recipient, i) => {
        return (
          <Label key={i}>
            {recipient === 'undefined' ? <CircularProgress size={24} color="primary" /> : recipient}
          </Label>
        )
      })}
    </Fragment>
  )

  const loadingContent = (
    <Grid container justifyContent="center">
      <Grid item>
        <CircularProgress color={'primary'} size={standards.fontSize['f-19']} />
      </Grid>
    </Grid>
  )

  const formContent = (
    <form onSubmit={handleSubmit(handleForm)}>
      <Grid container alignItems={'center'} justifyContent={'flex-start'}>
        <BackArrow label="作業詳細に戻る" path={'/job/show/' + jobId} />
        <Grid item xs={12} sm={12} md={12} lg={12} pt={2}>
          <Heading>{reportData[contactType]?.title}</Heading>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} pt={2}>
          <JobOutline />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} pt={5}>
          <Label bold="true">{'送信先メールアドレス: '}</Label>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} pt={2}>
          {EmailRecipientContent}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} pt={5}>
          <InputTextArea
            className="Job-Comment-InputLabel"
            name="comment"
            ref={register('comment', validationRules.comment)}
            error={errors && errors.comment ? true : false}
            errorMsg={errors ? errors?.comment?.message : null}
            label="コメント :"
            type="text"
            height={103}
            labelProps={{ bold: 'true' }}
            rows={3}
          />
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container alignItems={'center'} justifyContent={'center'}>
              <Grid item xs={4.5} sm={3.5} md={2} lg={1.5}>
                <LargeButton type="submit" bold="true" disabled={reportData[contactType]?.canSendReport === false}>
                  {isLoading ? <CircularProgress size={24} color="white" /> : '送信'}
                </LargeButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )

  useEffect(() => {
    props.handleData(reportData[contactType]?.title, jobOutline?.job_name)
  })

  return listIsEmpty(jobOutline) ? loadingContent : formContent
}

WorkerReportForm.propTypes = {
  id: PropTypes.number,
  contactType: PropTypes.number,
  handleData: PropTypes.func,
}

export default WorkerReportForm
