export const CREATE_CLIENT = 'CREATE_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const SET_FORM_MODE = 'SET_FORM_MODE'
export const SEARCH_CLIENT = 'SEARCH_APPLICATION'
export const SET_SEARCH_CRITERIA = 'SET_CLIENT_SEARCH_CRITERIA'
export const FETCHING_DATA_FAILED = 'FETCHING_DATA_FAILED'
export const SET_CLIENT_FORM_DATA = 'SET_CLIENT_FORM_DATA'
export const RESET_CURRENT_FORM = 'RESET_CURRENT_FORM'
export const SET_CONFIRMING_DATA = 'SET_CONFIRMING_DATA'
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS'
