import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Label, LargeButton, InputTextArea, P } from 'components'

const StyledForm = styled('form')(() => ({
  display: 'contents',
}))

const ReportsModalContent = (props) => {
  const {
    data,
    modalState,
    confirmed,
    modalType,
    handleApproveConfirm,
    handleRejectConfirm,
    handleModalClose,
    isRequesting,
  } = props
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  //form validation
  const validationRules = {
    comment: {
      required: {
        value: String,
        message: '差し戻しコメントを入力してください。',
      },
      validate: (value) => {
        if (!value.trim().length > 0) {
          return '差し戻しコメントを入力してください。'
        } else if (value.trim().length > 512) {
          return '512文字以内で入力してください。'
        } else {
          return
        }
      },
    },
  }

  return (
    <Grid container justifyContent="center">
      <Grid item pb={2} xs={12} textAlign="center" pt={confirmed ? 3 : 0}>
        <P>{modalState?.modalBodyMsg}</P>
      </Grid>
      {!confirmed && (
        <StyledForm
          onSubmit={handleSubmit(modalType === 'approve' ? handleApproveConfirm : handleRejectConfirm)}
          className="ProjectList-form"
        >
          {localStorage.getItem('mode') !== 'client' && (
            <React.Fragment>
              <Grid item xs={4} textAlign="end">
                <Label color="gray">クライアント名:</Label>
              </Grid>
              <Grid item xs={6} pl={1}>
                <Label>{data?.client_name}</Label>
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={4} textAlign="end">
            <Label color="gray">作業名:</Label>
          </Grid>
          <Grid item xs={6} pl={1}>
            <Label>{data?.job_name}</Label>
          </Grid>
          <Grid item xs={4} textAlign="end">
            <Label color="gray">作業日:</Label>
          </Grid>
          <Grid item xs={6} pl={1}>
            <Label>
              {data?.date} {data?.start_time}~{data?.end_time}
            </Label>
          </Grid>
          <Grid item xs={4} textAlign="end">
            <Label color="gray">ワーカー:</Label>
          </Grid>
          <Grid item xs={6} pl={1}>
            <Label>{data?.worker_name}</Label>
          </Grid>
          {modalType === 'reject' && (
            <Grid item xs={12} pt={2}>
              <InputTextArea
                name="comment"
                ref={register('comment', validationRules.comment)}
                error={errors && errors.comment ? true : false}
                errorMsg={errors ? errors?.comment?.message : null}
                label="差し戻しコメント"
                required
                borderRadius={0}
                height={100}
                borderColor="#707070"
              />
            </Grid>
          )}
          <Grid item container xs={12} justifyContent="center" textAlign="center" gap={2} pt={6}>
            <Grid item xs={4}>
              <LargeButton
                id="ApproveReportCloseBtn"
                color="gray"
                mode="btn1"
                onClick={handleModalClose}
                disabled={isRequesting}
              >
                いいえ
              </LargeButton>
            </Grid>
            <Grid item xs={4}>
              <LargeButton id="ApproveReportConfirmBtn" type="submit" disabled={isRequesting}>
                はい
              </LargeButton>
            </Grid>
          </Grid>
        </StyledForm>
      )}
      {confirmed && (
        <React.Fragment>
          <Grid item xs={6} pt={3} textAlign="center">
            <LargeButton id="ApproveReportCloseBtn" onClick={handleModalClose}>
              OK
            </LargeButton>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}

ReportsModalContent.propTypes = {
  data: PropTypes.object,
  handleApproveConfirm: PropTypes.func,
  handleRejectConfirm: PropTypes.func,
  handleModalClose: PropTypes.func,
  type: PropTypes.string,
  confirmed: PropTypes.bool,
  modalState: PropTypes.object,
  modalType: PropTypes.string,
  isRequesting: PropTypes.bool,
}

export default ReportsModalContent
