import React, { useState } from 'react'
import { Grid, FormHelperText, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateWorkerExpense, readJob } from 'services/jobs'
import { hideNotification } from 'store/notification/actionCreators'
import { Input, Select, Label, LargeButton, Container } from 'components'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { tabIndex } from 'utils/common'

const StyledExpenseFormModal = styled(Container, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  padding: 0,
  '& .MuiButton-root.UploadBtn': {
    borderRadius: 5,
    '& .MuiFormControl-root': {
      display: 'none',
    },
  },
  '& .form': {
    width: '100%',
  },
}))

const ExpenseFormModal = (props) => {
  const { data, onClose, navigateTo } = props
  const [fileLabel, setFileLabel] = useState('')
  const [newFile, setNewFile] = useState()
  const [fileError, setFileError] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [expenseCategory, setExpenseCategory] = useState(0)
  const { expenseCategoryList } = useSelector((state) => state.common)
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    setError,
  } = useForm()
  const jobId = data.job_id
  const dispatch = useDispatch()

  const validationRules = {
    item: {
      required: {
        value: String,
        message: '品目を入力してください。',
      },
      validate: (value) => {
        if (value.trim().length <= 0) {
          return '品目を入力してください。'
        } else if (value.trim().length > 255) {
          return '255文字以内で入力してください。'
        } else {
          return
        }
      },
    },
    price: {
      required: {
        value: Number,
        message: '金額を入力してください。',
      },
      pattern: {
        value: /^[0-9,]*$/, // Regex numbers and commas Validation
        message: '1〜1,000,000の数値を入力してください。',
      },
      validate: (value) => {
        const regex = /^\d{1,3}(,\d{3})*(\.\d+)?$/
        if (value && regex?.test(value)) {
          value = value.toString()?.replaceAll(',', '')
        }

        if (!(value >= 1 && value <= 1000000)) {
          return '1〜1,000,000の数値を入力してください。'
        }
        return
      },
    },
    expense_category_id: {
      validate: (value) => {
        if (value == undefined || value == 0) {
          return '項目を選択してください。'
        }
      },
    },
    file_url: {
      validate: (value) => {
        if (value !== null && value.length > 0) {
          const filename = value[0].name
          const fileSize = value[0].size / 1024 / 1024 // in MB
          let allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.JPG|\.JPEG|\.png|\.PNG|\.gif|\.GIF)$/i

          if (process.env.REACT_APP_CLAMAV_TEST === 'true') {
            allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.JPG|\.JPEG|\.png|\.PNG|\.gif|\.GIF|\.james|\.JAMES)$/i
          }
          if (!allowedExtensions.exec(filename)) {
            return 'JPG, JPEG, GIF, PNG, PDFいずれかのファイルを選択してください。'
          } else if (fileSize > 4) {
            return 'ファイルサイズは4MB以下にしてください。'
          }
        }
        return true
      },
    },
  }

  const handleUpdateExpense = (formData) => {
    setUploading(true)
    let newFormData = {}

    if (newFile) {
      newFormData = { ...formData, file_url: newFile }
    } else {
      delete formData.file_url
      newFormData = formData
    }

    dispatch(updateWorkerExpense(newFormData, jobId, data.id))
      .then(() => {
        dispatch(readJob(jobId, false))
        reset()
        setUploading(false)
        unregister('file_url')
        navigateTo(tabIndex.expense)
        onClose()
      })
      .catch((e) => {
        const { code, error } = e.response.data
        const allFields = Object.keys(getValues()) ?? []
        const allErrors = error ? Object.keys(error) : []
        const hasFieldError = allFields.some((item) => allErrors.includes(item))
        if (code === 422 && hasFieldError) {
          dispatch(hideNotification())
          allErrors.map((key) => {
            if (key == 'file_url') {
              setValue('file_url', '')
              setFileError(error[key][0] || null)
            }
            setError(key, { type: 'custom', message: error[key][0] }, { shouldFocus: true })
          })
        }
        setUploading(false)
      })
  }

  const handleFileUpload = ({ target }) => {
    setFileError(null)
    setNewFile(target.files[0])
    setFileLabel(target.files[0].name)
  }

  const handleExpenseCategory = (item) => {
    setExpenseCategory(item)
  }

  return (
    <StyledExpenseFormModal container pt={3}>
      <form onSubmit={handleSubmit(handleUpdateExpense)} className="form">
        <Grid item container spacing={2} xs={12}>
          <Grid item xs={12} sm={12} md={5} lg={2}>
            <Input
              id="ExpenseItemName"
              name="item"
              ref={register('item', validationRules.item)}
              error={errors && errors.item ? true : false}
              errorMsg={errors ? errors?.item?.message : null}
              type="text"
              placeholder={'例: 高速代○○インター〜○○インター'}
              required={true}
              value={data.item}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2.5}>
            <Select
              id="ModalExpenseCategory"
              name="expense_category_id"
              label=""
              ref={register('expense_category_id', validationRules.expense_category_id)}
              menuItems={[{ id: 0, name: '' }, ...expenseCategoryList]}
              value={expenseCategory || data.expense_category_id || 0}
              onChange={handleExpenseCategory}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <Input
              id="ExpensePrice"
              name="price"
              ref={register('price', validationRules.price)}
              error={errors && errors?.price ? true : false}
              errorMsg={errors ? errors?.price?.message : null}
              type="text"
              placeholder={'例: 2,100'}
              value={data.price}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.5} lg={2.5}>
            <LargeButton id="uploadBtn" className="UploadBtn" color="lightGray" mode="dark" component="label">
              {'ファイルを選択'}
              {!uploading && (
                <Input
                  id="ExpenseFile"
                  name="file_url"
                  ref={register('file_url', validationRules.file_url)}
                  type="file"
                  onChange={(e) => handleFileUpload(e)}
                />
              )}
            </LargeButton>
            {(errors?.file_url || fileError) && (
              <FormHelperText id={'FormHelperText-file_url-ID'} error>
                {errors?.file_url?.message || fileError}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={3}>
            <Label id="fileUploadLabel" pt={1}>
              {fileLabel || data.file_name || '選択されてません'}
            </Label>
          </Grid>
        </Grid>
        <Grid container ptt={2} justifyContent={'center'} alignItems={'center'} pt={4}>
          <LargeButton type="submit" id="updateExpenseBtn">
            {uploading ? <CircularProgress size="1.5rem" color="white" /> : '登録する'}
          </LargeButton>
        </Grid>
      </form>
    </StyledExpenseFormModal>
  )
}

ExpenseFormModal.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  onClose: PropTypes.func,
  navigateTo: PropTypes.func,
}

export default ExpenseFormModal
