import React from 'react'
import { Stepper as MuiStepper, StepLabel, Step } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

const StyledStepper = styled(MuiStepper, { shouldForwardProp: (prop) => prop !== 'color' })(() => ({
  '& .MuiStep-horizontal': {
    padding: 0,
    width: '100%',
    '& .MuiStepLabel-iconContainer': {
      padding: 0,
    },
    '& .MuiStepLabel-labelContainer': {
      padding: 0,
      marginBottom: 15,
    },
    '& .MuiStepLabel-label': {
      color: '#FFCCAB',
      marginLeft: 5,
    },
    '& .Mui-active, .Mui-completed': {
      color: '#ff6b09 !important',
    },
  },
}))

function ColorlibStepIcon(props) {
  const { active, completed, className } = props

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  }

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const ColorlibStepIconRoot = styled('div')(({ ownerState, theme }) => ({
  backgroundColor: '#ffffff',
  zIndex: 1,
  color: '#FFCCAB',
  border: '2px solid #FFCCAB',
  width: 48,
  height: 48,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#ff6b09',
    border: '2px solid #ff6b09',
    color: '#ffffff',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#ff6b09',
    border: '2px solid #ff6b09',
    color: '#ffffff',
  }),
  [theme.breakpoints.down('sm')]: {
    width: 32,
    height: 32,
  },
}))

const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#ff6b09',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#ff6b09',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#FFCCAB',
    borderRadius: 1,
    marginLeft: -5,
  },
}))

function Stepper(props) {
  const { activeStep, steps } = props
  return (
    <StyledStepper activeStep={activeStep} steps={steps}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel color="inherit" StepIconComponent={ColorlibStepIcon}>
            {label}
            <ColorlibConnector />
          </StepLabel>
        </Step>
      ))}
    </StyledStepper>
  )
}

Stepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array,
}

export default Stepper
