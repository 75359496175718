import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { FormHelperText } from '@mui/material'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import BoldIcon from 'assets/img/bold.png'
import UnderlineIcon from 'assets/img/underline.png'
import standards from 'theme/standards'

const StyledRichTextEditor = styled('div', {
  shouldForwardProp: (prop) => prop,
})(({ height, width, error, fontSize, theme }) => ({
  padding: 0,
  width: width,
  height: height,
  fontSize: fontSize,
  borderRadius: 7,
  border: `1px solid ${error ? theme.palette.error.main : theme.palette.lightGray.light}`,
  '& .DraftEditor-editorContainer': {
    minHeight: `calc(${height}px - 60px)`,
  },
  '& .public-DraftEditor-content': {
    minHeight: `calc(${height}px - 60px)`,
  },
  '& .public-DraftStyleDefault-block': {
    margin: '0px !important',
  },
  '& .DraftEditor-root': {
    maxHeight: `calc(${height}px - 60px)`,
    backgroundColor: theme.palette.white,
    padding: '0px 10px',
    '& .public-DraftEditorPlaceholder-root': {
      width: 'auto',
    },
  },
  '& .rdw-editor-toolbar': {
    height: 36,
    borderRadius: '7px 7px 0px 0px',
    boxShadow: `0px 1px 4px 0px ${theme.palette.lightGray.light}`,
    padding: 0,
  },
  '& .rdw-option-wrapper': {
    height: 34,
    boxShadow: 'none',
    border: 'none',
    borderRight: `1px solid ${theme.palette.lightGray.light}`,
    borderRadius: 0,
    backgroundColor: theme.palette.white,
    color: theme.palette.gray.main,
    fontSize: 18,
    margin: 0,
    padding: '0px 20px',
    '&.rdw-option-active': {
      backgroundColor: `${theme.palette.lightGray.light}`,
    },
    '&.mui-rte-bold::after': {
      content: '"太字"',
    },
    '&.mui-rte-underline::after': {
      content: '"下線"',
    },
    '& img': {
      filter: 'opacity(0.5)',
      marginTop: 4,
      marginRight: 6,
    },
    '&.mui-rte-bold img': {
      width: 10,
      height: 12,
    },
    '&.mui-rte-underline img': {
      width: 12,
      height: 14,
    },
  },
  '& .SeleniumOverrideInput': {
    width: 2,
    border: 'none',
    height: 1,
    zIndex: '-1000 !important',
    position: 'absolute',
  },
}))

const RichTextEditor = React.forwardRef((props, ref) => {
  const excludedProps = ['onChange', 'errorMsg', 'required', 'height', 'width', 'fontSize', 'error', 'defaultValue']
  let defaultProps = omit(props, excludedProps)
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
  const [seleniumTestValue, setSeleniumTestValue] = React.useState('')

  const getHtmlData = (editorState) => {
    let htmlData = ''
    if (editorState.getCurrentContent().hasText()) {
      htmlData = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    }
    return htmlData
  }

  useEffect(() => {
    if (props.defaultValue || seleniumTestValue) {
      const contentBlock = htmlToDraft(props.defaultValue || seleniumTestValue)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        setEditorState(EditorState.createWithContent(contentState))
        ref.onChange({ target: { name: props.name, value: props.defaultValue || seleniumTestValue } })
      }
    }
  }, [props.defaultValue, seleniumTestValue])

  //for selenium testing purpose only (due to Selenium RTE onchange Bug)
  //related issue: https://groups.google.com/g/selenium-users/c/C23-BsZAtVg?pli=1
  const setSeleniumOverideValue = (e) => {
    setSeleniumTestValue(e.target.value)
  }

  return (
    <>
      <StyledRichTextEditor
        id={'editorWrapper-' + defaultProps?.id}
        height={props.height}
        width={props.width}
        fontSize={props.fontSize}
        error={props.error ? 'error' : null}
        {...ref}
      >
        <Editor
          {...defaultProps}
          editorState={editorState}
          toolbar={{
            options: ['inline'],
            inline: {
              component: undefined,
              className: 'mui-rte-options',
              options: ['bold', 'underline'],
              bold: { className: 'mui-rte-bold', icon: BoldIcon },
              underline: { className: 'mui-rte-underline', icon: UnderlineIcon },
            },
          }}
          onEditorStateChange={(state) => {
            setEditorState(state)
            ref.onChange({ target: { name: props.name, value: getHtmlData(state) } })
          }}
        />
        <input
          onChange={setSeleniumOverideValue}
          id={`SeleniumRTE-${defaultProps?.name}`}
          className="SeleniumOverrideInput"
        ></input>
      </StyledRichTextEditor>
      <FormHelperText id={'FormHelperText-' + defaultProps?.id} error={props.error}>
        {props.errorMsg}
      </FormHelperText>
    </>
  )
})

RichTextEditor.displayName = 'Input Rich Text Editor'

RichTextEditor.defaultProps = {
  height: 200,
  width: '100%',
  fontSize: standards.fontSize.default,
  inlineToolbar: false,
  controls: ['bold', 'underline'],
}

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  errorMsg: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  id: PropTypes.any,
  name: PropTypes.string,
  error: PropTypes.bool,
  defaultValue: PropTypes.string,
}

export default RichTextEditor
