import React, { useState, useEffect } from 'react'
import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import searchIcon from 'assets/svg/search.svg'
import { Grid, Stack, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
//validation msg
import { getMessages } from 'validation'
//components
import {
  Heading,
  LargeButton,
  TableList,
  InputLabel,
  InputDatePicker,
  InputMultiSelect,
  Icon,
  Label,
  Link,
  SmallOutlinedTag,
  RejectApplicationContent,
  Modal,
} from 'components'
//store
import { resetCurrentForm } from 'store/jobs/actionCreators'
//services
import { getAllStatusList } from 'services/common'
//utils
import { getStatusByType, formatStringToArray } from 'utils/helper'
import { applicationListRequiredStatusId, status } from 'utils/config/status'
import { dateFormat } from 'utils/common'

const ApplicationListForm = (props) => {
  const rulesMsg = getMessages('ja')
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  //State
  const [dateFromValue, setDateFromValue] = useState()
  const [dateToValue, setDateToValue] = useState()
  const [selectedRow, setSelectedRow] = useState({})
  const rawStatusOptions = useSelector((state) => state.common.statusList) ?? []
  const searchValues = useSelector((state) => state.applications.search) ?? []
  const dispatch = useDispatch()
  const history = useHistory()
  const statusList = getStatusByType(rawStatusOptions, 'Job Group', applicationListRequiredStatusId)
  const applicationStatusList = getStatusByType(rawStatusOptions, 'Application')
  const [openCancelModal, setOpenCancelModal] = useState(false)

  //Use Effects
  useEffect(() => {
    dispatch(getAllStatusList())
    dispatch(resetCurrentForm())
  }, [])

  //Form Validation
  const validationRules = {
    worker: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    job_group: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    project: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    start_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
    end_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
  }

  //Handle select table row
  const handleSelectedRow = (rowData) => {
    setSelectedRow(rowData)
  }

  //Handle datepicker change
  const onDateChange = (newVal, type) => {
    if (type == 'start_date') {
      setDateFromValue(newVal)
      if (dayjs(newVal).diff(dayjs(dateToValue)) >= 1) {
        setDateToValue(newVal)
      }
    } else {
      setDateToValue(newVal)
      if (dayjs(dateFromValue).diff(dayjs(newVal)) >= 1) {
        setDateFromValue(newVal)
      }
    }
  }

  //Click searach button
  const handleSearch = (data) => {
    props.handlePageSearch(data)
  }

  //Table action - show details
  const handleDetail = () => {
    history.push('/application/show/' + selectedRow?.id)
  }

  //Table action - application offer
  const handleOffer = () => {
    history.push(
      `/offer/create?application=${selectedRow?.id}&project=${selectedRow?.project_id}&job_group=${selectedRow?.job_group_id}&client=${selectedRow?.client_id}&worker=${selectedRow?.worker_id}`
    )
  }

  //Table action - application reject
  const handleReject = () => {
    setOpenCancelModal(true)
  }

  //Close cancel application modal
  const onCloseCancelModal = () => {
    setOpenCancelModal(false)
  }

  //Get table action list
  const getActionList = () => {
    return [
      {
        handler: (handler) => handleDetail(handler),
        name: '閲覧',
      },
      {
        handler: (handler) => handleOffer(handler),
        name: 'オファー',
      },
      selectedRow?.application_status_id == status.application.unprocessed
        ? {
            handler: (handler) => handleReject(handler),
            name: '見送り',
          }
        : null,
    ].filter((val) => val != null)
  }

  //Format table list data
  const formatData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        worker_display_name: (
          <Box display="inline-flex">
            <Box component="span" width="max-content" textAlign="left">
              <Label pb={0.5}>
                <Link to={'/application/show/' + item?.id} maxwidth="max-content">
                  <u>{item?.worker_display_name}</u>
                </Link>
              </Label>
              <Stack direction="row" spacing={1}>
                {item?.verified_user ? <SmallOutlinedTag label="本人確認済" color="success" /> : ''}
                {item?.tekikaku_flag ? <SmallOutlinedTag label="適格請求書発行事業者" color="primary" /> : ''}
              </Stack>
            </Box>
          </Box>
        ),
        job_group_name: (
          <Link to={'/job-group/show/' + item?.job_group_id}>
            <u>{item?.job_group_name}</u>
          </Link>
        ),
      }
    })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Heading>応募一覧</Heading>
      </Grid>
      <Grid item xs={12} pt={4}>
        <form onSubmit={handleSubmit(handleSearch)} className="JobList-form">
          <Grid item container columnSpacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <InputLabel
                ref={register('worker', validationRules.worker)}
                id="InputLabel-worker_id-ID"
                error={errors && errors.worker ? true : false}
                errorMsg={errors ? errors?.worker?.message : null}
                name="worker_id"
                label={'応募者表示名・ID'}
                value={searchValues?.worker}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <InputLabel
                ref={register('job_group', validationRules.job_group)}
                id="InputLabel-job_group-ID"
                error={errors && errors.job_group ? true : false}
                errorMsg={errors ? errors?.job_group?.message : null}
                name="job_group"
                label={'作業グループ名・ID'}
                value={searchValues?.job_group}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2.5} xl={2.5}>
              <InputLabel
                ref={register('project', validationRules.project)}
                id="InputLabel-project-ID"
                error={errors && errors.project ? true : false}
                errorMsg={errors ? errors?.project?.message : null}
                name="project"
                label={'案件名・ID'}
                value={searchValues?.project}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2.5} xl={2.5}>
              <InputMultiSelect
                id="InputMultiSelect-job_group_status-ID"
                ref={register('job_group_status')}
                name="job_group_status"
                label={'作業グループステータス'}
                menuItems={statusList ?? []}
                defaultValue={formatStringToArray(searchValues?.job_group_status) ?? []}
                enableEmpty
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={2.5} xl={2.5}>
              <InputMultiSelect
                id="InputMultiSelect-application_status-ID"
                ref={register('application_status')}
                name="application_status"
                label={'応募ステータス'}
                menuItems={applicationStatusList ?? []}
                defaultValue={formatStringToArray(searchValues?.application_status) ?? []}
                enableEmpty
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <InputDatePicker
                id="InputDatePicker-start_date-ID"
                ref={register('start_date', validationRules.start_date)}
                error={!!(errors && errors.start_date)}
                errorMsg={errors ? errors?.start_date?.message : null}
                name="start_date"
                label={'応募日'}
                color="primary"
                inputFormat={dateFormat}
                value={dateFromValue && dateFromValue !== ' ' ? dayjs(dateFromValue) : ''}
                onChange={(newVal) => onDateChange(newVal, 'start_date')}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <InputDatePicker
                id="InputDatePicker-end_date-ID"
                ref={register('end_date', validationRules.end_date)}
                error={!!(errors && errors.end_date)}
                errorMsg={errors ? errors?.end_date?.message : null}
                name="end_date"
                color="primary"
                inputFormat={dateFormat}
                value={dateToValue && dateToValue !== ' ' ? dayjs(dateToValue) : ''}
                onChange={(newVal) => onDateChange(newVal, 'end_date')}
                minDate={dayjs(dateFromValue)}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={1.5} pt={{ xs: 0, lg: 3.5 }}>
              <LargeButton
                type="submit"
                id="SearchBtn-ID"
                bold="true"
                color="warning"
                startIcon={<Icon size={'sm'} disabled={props.isLoading ? true : false} source={searchIcon} />}
              >
                検索
              </LargeButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12} pt={{ xs: 4, sm: 0 }}>
        <TableList
          title={`Application List`}
          headCells={props.headerCells}
          data={formatData(props.data)}
          totalPage={props.totalPage}
          totalCount={props.totalCount}
          handlePageChange={props.handlePageChange}
          handleSelectedRow={handleSelectedRow}
          isLoading={props.isLoading}
          handleSort={props.handleSort}
          sort={props.sort}
          sortBy={props.sortBy}
          currentPage={props.currentPage}
          action={true}
          actionList={getActionList()}
          pageFrom={props.pageFrom}
          pageTo={props.pageTo}
        />
      </Grid>
      <Modal open={openCancelModal} title={'見送り'} onClose={() => onCloseCancelModal(false)} size={'sm'}>
        <RejectApplicationContent onClose={() => onCloseCancelModal()} data={selectedRow} />
      </Modal>
    </Grid>
  )
}

ApplicationListForm.displayName = 'Application List Form'

ApplicationListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

export default ApplicationListForm
