// import Http from 'utils/Http'
import * as types from './actionTypes'

const initialState = {
  pending: false,
  success: false,
  error: null,
  prefectureslist: [],
  clientList: [],
  statusList: [],
  workerSkillList: [],
  jobFilterList: [],
  token: [],
  expenseCategoryList: [],
  redirect: null,
  locations: [],
  privateRedirect: null,
  notFound: false,
  offerDropdown: {
    projects: [],
    job_groups: [],
    client_users: [],
    workers: [],
  },
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    //prefectures list
    case types.GET_PREFECTURES_LIST_REQUEST:
      return {
        ...state,
        prefectureslist: [],
        pending: true,
        success: false,
      }
    case types.GET_PREFECTURES_LIST_SUCCESS:
      return {
        ...state,
        prefectureslist: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_PREFECTURES_LIST_FAILURE:
      return {
        ...state,
        prefectureslist: null,
        pending: false,
        success: false,
        error: payload.error,
      }
    //job group list
    case types.GET_ALL_CLIENT_LIST_REQUEST:
      return {
        ...state,
        clientList: [],
        pending: true,
        success: false,
      }
    case types.GET_ALL_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clientList: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_ALL_CLIENT_LIST_FAILURE:
      return {
        ...state,
        clientList: null,
        pending: false,
        success: false,
        error: payload.error,
      }
    //Status List
    case types.GET_ALL_STATUS_LIST_REQUEST:
      return {
        ...state,
        statusList: [],
        pending: true,
        success: false,
      }
    //Worker Skills
    case types.GET_ALL_STATUS_LIST_SUCCESS:
      return {
        ...state,
        statusList: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_ALL_STATUS_LIST_FAILURE:
      return {
        ...state,
        statusList: null,
        pending: false,
        success: false,
        error: payload.error,
      }
    //Job Filter
    case types.GET_ALL_JOB_FILTER_OPTIONS_LIST_REQUEST:
      return {
        ...state,
        jobFilterList: [],
        pending: true,
        success: false,
      }
    case types.GET_ALL_JOB_FILTER_OPTIONS_LIST_SUCCESS:
      return {
        ...state,
        jobFilterList: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_ALL_JOB_FILTER_OPTIONS_LIST_FAILURE:
      return {
        ...state,
        jobFilterList: null,
        pending: false,
        success: false,
        error: payload.error,
      }
    case types.GET_ALL_WORKER_SKILLS_REQUEST:
      return {
        ...state,
        workerSkillList: [],
        pending: true,
        success: false,
      }
    case types.GET_ALL_WORKER_SKILLS_SUCCESS:
      return {
        ...state,
        workerSkillList: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_ALL_WORKER_SKILLS_FAILURE:
      return {
        ...state,
        workerSkillList: [],
        pending: false,
        success: false,
        error: payload.error,
      }
    //expense categories
    case types.GET_ALL_EXEPENSE_CATEGORIES_REQUEST:
      return {
        ...state,
        expenseCategoryList: [],
        pending: true,
        success: false,
      }
    case types.GET_ALL_EXEPENSE_CATEGORIES_SUCCESS:
      return {
        ...state,
        expenseCategoryList: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_ALL_EXEPENSE_CATEGORIES_FAILURE:
      return {
        ...state,
        expenseCategoryList: null,
        pending: false,
        success: false,
        error: payload.error,
      }
    case types.SET_DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        success: false,
        error: payload.error,
      }
    case types.SET_REDIRECT_ROUTE:
      return {
        ...state,
        redirect: payload,
      }
    case types.ADD_LOCATION:
      return {
        ...state,
        locations: [payload].concat(state.locations.slice(0, 1)),
      }
    case types.SET_PRIVATE_REDIRECT_ROUTE:
      return {
        ...state,
        privateRedirect: payload,
      }
    case types.SET_OFFER_DROPDOWN_DATA:
      return {
        ...state,
        offerDropdown: {
          ...state.offerDropdown,
          ...payload,
        },
      }
    case types.SET_404_PAGE:
      return {
        ...state,
        notFound: payload,
      }
    default:
      return state
  }
}
