import React from 'react'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import standards from 'theme/standards'

export const Container = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ maxWidth, theme, padding }) => ({
  backgroundColor: `${theme.palette['background'].paper}`,
  height: 'auto',
  outline: 'none',
  borderRadius: `${theme.standards.border.box}`,
  padding: padding,
  opacity: 1,
  maxWidth: `${theme.standards.container[maxWidth]}` || maxWidth,
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.standards.padding.content.mobile}`,
  },
  '&.LargeContainer': {
    [theme.breakpoints.down('lg')]: {
      maxWidth: `${theme.standards.container.lg}`,
    },
  },
  '& form': {
    width: '100%',
  },
  '&:has(.Confirmation)': {
    maxWidth: `${theme.standards.container.sm}`,
  },
  '&.NoPadding': {
    padding: 'unset !important',
  },
  '&:has(.ConfirmationMd)': {
    maxWidth: `${theme.standards.container.md}`,
  },
}))

Container.defaultProps = {
  maxWidth: '100%',
  padding: standards.padding.content.default,
}

export const LargeContainer = (props) => {
  return (
    <Container
      {...props}
      item
      container
      xs={12}
      lg={9.7216}
      xl={12}
      maxWidth={standards.container.xl}
      className="LargeContainer"
    />
  )
}
