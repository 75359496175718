import React from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { Label } from 'components'

Toolbar.propTypes = {
  isLoading: PropTypes.bool,
  totalCount: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

function Toolbar(props) {
  const { totalCount, isLoading, pageFrom, pageTo } = props
  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }} pb={1} pr={1}>
      {!isLoading && (
        <Label id="tableTolbar-ID">
          表示中：{pageFrom}-{pageTo}件 / {totalCount}件中
        </Label>
      )}
    </Grid>
  )
}

export default Toolbar
