import * as types from './actionTypes'

export function actionSearchInvoice(invoiceList) {
  return {
    type: types.SEARCH_INVOICE,
    payload: invoiceList,
  }
}

export function actionSetSearchCriteria(invoiceMonth, page, limit, sort, sortBy) {
  return {
    type: types.SET_SEARCH_CRITERIA,
    payload: { invoiceMonth, page, limit, sort, sortBy },
  }
}

export function actionGetWorkerInvoiceMonth(invoiceMonths) {
  return {
    type: types.GET_WORKER_MONTHS,
    payload: invoiceMonths,
  }
}

export function actionSetDefaultInvoiceMonth(defaultInvoiceMonth) {
  return {
    type: types.SET_DEFAULT_INVOICE_MONTH,
    payload: defaultInvoiceMonth,
  }
}

export function setInvoiceFormData(formData) {
  return {
    type: types.SET_INVOICE_FORM_DATA,
    payload: formData,
  }
}
