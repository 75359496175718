import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid, Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as dayjs from 'dayjs'
import { useSelector, useDispatch } from 'react-redux'
import { createJobGroup, getProject, readJobGroup } from 'services/jobGroups'
import { setConfirming, setFormMode, setJobGroupFormData } from 'store/job-groups/actionCreators'
import { useHistory } from 'react-router-dom'
import { hideNotification } from 'store/notification/actionCreators'
//utils
import { emailAddressValidation, formatDropdownData } from 'utils/helper'
import { settings as clientSettings } from 'utils/common'
import { thisDay, dateFormat } from 'utils/common'
//componets
import {
  Label,
  Heading,
  InputAutoComplete,
  InputLabel,
  InputDatePicker,
  InputMinMax,
  InputRichTextEditor,
  LargeButton,
  Checkbox,
  P,
} from 'components'
import { getMessages } from 'validation'
import { toolTipTitle } from 'utils/config/staticContents'

const JobGroupCreateUpdate = (props) => {
  const [jobGroupId, setJobGroupId] = useState(props.match.params.id ?? 0)
  const isClient = localStorage.getItem('mode').includes('client')
  const dispatch = useDispatch()
  const history = useHistory()
  const rulesMsg = getMessages('ja')
  const dateTomorrow = dayjs().add(1, 'day').format(dateFormat)
  const minimumSalary = 1
  const maximumSalary = 1000000

  //useform hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    setValue,
  } = useForm()

  const dayAfter = dayjs().add(1, 'day').format(dateFormat)
  const hasError = Object.keys(errors).length > 0

  const clientList = useSelector((state) => state.common.clientList)
  const prefectureslist = useSelector((state) => state.common.prefectureslist)
  const { user } = useSelector((state) => state.auth)
  const { is_update: didUpdate } = useSelector((state) => state.jobGroups)

  //dropown data
  const [prefectures, setPrefectures] = useState([])
  const [activeClientList, setactiveClientList] = useState([])
  const [clientUserList, setClientUserList] = useState([])
  const [projectList, setProjectList] = useState([])
  const settings = clientSettings

  //form data
  const currentFormData = useSelector((state) => state.jobGroups.formData)
  let defaultFormData =
    currentFormData.current && Object.keys(currentFormData.current).length > 0
      ? currentFormData.current
      : currentFormData.original

  const [formData, setFormData] = useState(null)

  //dropdown state
  const [projectDisabled, setProjectDisabled] = useState(defaultFormData?.project_id ? false : true)
  const [clientUserDisabled, setClientUserDisabled] = useState(defaultFormData?.client_user_id ? false : true)

  //update state
  const [isUpdate, setIsUpdate] = useState(false)

  //date values
  const [dateFromValue, setDateFromValue] = useState(formData ? dayjs(formData['start_date']) : null)
  const [dateToValue, setDateToValue] = useState(formData ? dayjs(formData['end_date']) : null)
  const [{ defaultMaxStartDate, defaultMinEndDate }, setDefaultMinMaxDate] = useState({
    defaultMaxStartDate: '',
    defaultMinEndDate: dayAfter,
  })

  //Regex Patterns
  const currencyPattern = /^[0-9]{1,3}(,[0-9]{3})+$/ // numbers and commas Validation
  const numbersOnlyPattern = /^[0-9]+$/ // numbers only Validation

  //set form data base on action type
  useEffect(() => {
    setJobGroupId(jobGroupId || 0)
    const projectId = props.location.state?.projectId

    // from projectList
    if (projectId) {
      dispatch(getProject(projectId)).then((response) => {
        let project = response?.data?.data
        project['current'] = {
          ...defaultFormData,
          client_id: {
            id: project?.client?.id,
            name: project?.client?.name,
          },
          project_id: {
            id: project?.id,
            name: project?.alias,
          },
          client_user_id: {
            id: project?.client_user_id,
            name: project?.pic_name,
          },
        }
        dispatch(setJobGroupFormData(project))
      })
      return
    }

    // for create new jobgroup
    if (isClient && !jobGroupId && !didUpdate) {
      currentFormData.current = {
        client_id: { id: user?.client_user?.client_id, name: user?.client_user?.client_name },
      }
      return
    }

    //for update
    if (jobGroupId > 0 && !currentFormData.current) {
      currentFormData.current === null && dispatch(readJobGroup(jobGroupId))
    }

    if (props?.location?.pathname.includes('update')) {
      setIsUpdate(true)
      dispatch(setFormMode(true))
      return
    } else {
      setIsUpdate(false)
      dispatch(setFormMode(false))
      return
    }
  }, [])

  //set form data state
  useEffect(() => {
    setFormData(defaultFormData)
  }, [defaultFormData])

  //set prefecture state
  useEffect(() => {
    setPrefectures(formatDropdownData(prefectureslist))
  }, [prefectureslist])

  //set client list stat
  useEffect(() => {
    setactiveClientList(formatDropdownData(clientList))
  }, [clientList])

  //render when client dropdown change from form data
  useEffect(() => {
    if (formData?.client_id) {
      let clientID = formData?.client_id?.id
      let selectedClient = clientID ? clientList.find(({ id }) => id === clientID) : null
      let clientUsers = selectedClient ? selectedClient?.client_user : []
      let clientProjects = selectedClient ? selectedClient?.projects : []

      setClientUserList(clientID ? formatDropdownData(clientUsers, 'name') : [])
      setProjectList(clientID ? formatDropdownData(clientProjects, 'name') : [])
      setProjectDisabled(false)
      setClientUserDisabled(false)
    }
  }, [formData?.client_id, clientList])

  //render when start and end date change from form data
  useEffect(() => {
    let formDataStartDate = formData ? dayjs(formData['start_date']) : null
    let formDataEndDate = formData ? dayjs(formData['end_date']) : null
    setDateFromValue(formDataStartDate)
    setDateToValue(formDataEndDate)
    if (formData) {
      setDefaultMinMaxDate((prevState) => ({
        ...prevState,
        defaultMaxStartDate: formDataEndDate,
        defaultMinEndDate: !isUpdate && dayjs(formDataStartDate).isBefore(dayAfter) ? dayAfter : formDataStartDate,
      }))
    }
  }, [formData?.start_date, formData?.end_date])

  // handle Submit Confirmation
  const handleSubmitJobGroup = async (data) => {
    let newData = {
      ...data,
      min_salary: parseFloat(data.min_salary.replace(/,/g, '')),
      max_salary: parseFloat(data.max_salary.replace(/,/g, '')),
      client_id: isClient ? user?.client_user?.client_id : data.client_id.id,
      client_user_id: data.client_user_id.id,
      location: data.location.name,
      project_id: data.project_id.id,
      setting: data.setting.id,
      close_application_flag: data?.close_application_flag ? 1 : 0,
    }

    dispatch(createJobGroup(newData, data))
      .then(() => {
        dispatch(setConfirming(true))
        dispatch(setFormMode(isUpdate))
        history.push(`/${isClient ? 'job-group' : 'job_group'}/confirmation`)
      })
      .catch((e) => {
        const { code, error } = e.response.data
        const allFields = Object.keys(newData)
        const allErrors = error ? Object.keys(error) : []
        const hasFieldError = allFields.some((item) => allErrors.includes(item))
        if (code === 422 && hasFieldError) {
          dispatch(hideNotification())
          allErrors.map((key) => {
            setError(key, { type: 'custom', message: error[key][0] }, { shouldFocus: true })
          })
        }
      })
  }

  //handle change autocomplete dropdown
  const handleAutoCompleteChange = (data, name) => {
    if (name == 'client_id') {
      let selectedClient = data?.id ? clientList.find(({ id }) => id === data.id) : null
      let clientUsers = selectedClient ? selectedClient?.client_user : []
      let clientProjects = selectedClient ? selectedClient?.projects : []
      setClientUserList(data?.id ? formatDropdownData(clientUsers, 'name') : [])
      setProjectList(data?.id ? formatDropdownData(clientProjects, 'name') : [])
      setProjectDisabled(data?.id ? false : true)
      setClientUserDisabled(data?.id ? false : true)
      setFormData((prevState) => ({
        ...prevState,
        project_id: null,
        client_user_id: null,
      }))
      setValue('project_id', '')
      setValue('client_user_id', '')
    }
  }

  //handle date picker change
  const onDateChange = (newVal, type) => {
    if (type == 'start_date') {
      setDateFromValue(newVal)
      setDefaultMinMaxDate((prevState) => ({
        ...prevState,
        defaultMinEndDate: !isUpdate && dayjs(newVal).isBefore(dayAfter) ? dayAfter : newVal,
      }))
    } else {
      setDateToValue(newVal)
      setDefaultMinMaxDate((prevState) => ({
        ...prevState,
        defaultMaxStartDate: newVal,
      }))
    }
  }

  //form validation
  const validationRules = {
    client_id: {
      required: {
        value: Object,
        message: rulesMsg.client_input,
      },
    },
    project_id: {
      required: {
        value: Object,
        message: rulesMsg.project_input,
      },
    },
    client_user_id: {
      required: {
        value: Object,
        message: isClient ? rulesMsg.client_jobGroup_input : rulesMsg.contact_person_input,
      },
    },
    setting: {
      required: {
        value: Object,
        message: rulesMsg.setting_input,
      },
    },
    name: {
      required: {
        value: String,
        message: rulesMsg.jobGroup_name_input,
      },
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    start_date: {
      required: {
        value: Date,
        message: isClient ? rulesMsg.client_start_date_input : rulesMsg.start_date_input,
      },
      validate: (value) => {
        const isValid = dayjs(value, dateFormat, true).isValid()
        const endDateVal = getValues('end_date')
        if (!isValid) {
          return rulesMsg.invalid_date()
        } else if (dayjs(value).isAfter(endDateVal)) {
          return rulesMsg.dates_mismatch
        } else if (isClient && !isUpdate && !dayjs(value).isAfter(dayjs(thisDay))) {
          return rulesMsg.date_invalid
        } else {
          return true
        }
      },
    },
    end_date: {
      required: {
        value: Date,
        message: isClient ? rulesMsg.client_end_date_input : rulesMsg.end_date_input,
      },
      validate: (value) => {
        const isValid = dayjs(value, dateFormat, true).isValid()
        const startDateVal = getValues('start_date')
        if (!isValid) {
          return rulesMsg.invalid_date()
        } else if (!isUpdate && !dayjs(value).isAfter(dayjs(thisDay))) {
          return rulesMsg.date_invalid
        } else if (dayjs(value).isBefore(startDateVal)) {
          return isClient ? rulesMsg.client_jobGroup_date_error : rulesMsg.jobGroup_date_error
        } else {
          return true
        }
      },
    },
    location: {
      required: {
        value: Object,
        message: rulesMsg.location_input,
      },
    },
    min_salary: {
      required: {
        value: Number,
        message: rulesMsg.min_salary_input,
      },
      validate: (value) => {
        const intMinValue = value.replaceAll(',', '')
        const inRange = checkRange(intMinValue)
        let message = rulesMsg.salary_length_error

        if (value.includes(',')) {
          return (inRange && currencyPattern.test(value)) || message
        } else {
          return (inRange && numbersOnlyPattern.test(value)) || message
        }
      },
    },
    max_salary: {
      required: {
        value: Number,
        message: rulesMsg.max_salary_invalid,
      },
      validate: (value) => {
        const minSalary = getValues('min_salary')
        const intMinValue = minSalary.replaceAll(',', '')
        const intMaxValue = value.replaceAll(',', '')
        const inRange = checkRange(intMaxValue)

        if (value.includes(',')) {
          return validateSalary(value, intMinValue, intMaxValue, currencyPattern, inRange)
        } else {
          return validateSalary(value, intMinValue, intMaxValue, numbersOnlyPattern, inRange)
        }
      },
    },
    no_of_workers: {
      required: {
        value: Number,
        message: rulesMsg.worker_input,
      },
      validate: (value) => {
        return (parseInt(value) >= 1 && parseInt(value) <= 100) || rulesMsg.worker_capacity_error
      },
      pattern: {
        value: numbersOnlyPattern,
        message: rulesMsg.worker_capacity_error,
      },
    },
    details: {
      required: {
        value: String,
        message: rulesMsg.details_input,
      },
      validate: (value) => {
        const regex = /(<([^>]+)>)/gi
        return value.replaceAll(regex, '').length <= 1000 || rulesMsg.max(1000)
      },
    },
    fees: {
      required: {
        value: String,
        message: rulesMsg.fees_input,
      },
      validate: (value) => {
        const regex = /(<([^>]+)>)/gi
        return value.replaceAll(regex, '').length <= 1000 || rulesMsg.max(1000)
      },
    },
    allowances: {
      required: {
        value: String,
        message: rulesMsg.allowances_input,
      },
      validate: (value) => {
        const regex = /(<([^>]+)>)/gi
        return value.replaceAll(regex, '').length <= 1000 || rulesMsg.max(1000)
      },
    },
    email_address: {
      required: {
        value: String,
        message: rulesMsg.jobGroup_email_input,
      },
      maxLength: {
        value: 300,
        message: rulesMsg.max(300),
      },
      validate: (value) => {
        return emailAddressValidation(value)
      },
    },
  }

  const validateSalary = (value, min, max, pattern, inRange) => {
    if (parseFloat(max) < parseFloat(min)) {
      return rulesMsg.max_salary_less_error
    } else {
      return (inRange && pattern.test(value)) || rulesMsg.salary_length_error
    }
  }

  const checkRange = (value) => {
    return parseFloat(value) >= minimumSalary && parseFloat(value) <= maximumSalary
  }

  let defaultInputProps = {
    required: true,
  }

  const getAutoCompleteField = (label, name, placeholder, options, disabled, noPad, toolTip) => {
    let defaultValue = formData ? formData[name] : null

    if (name === 'client_user_id' && isClient && location.pathname.includes('create')) {
      const clientUserId = user?.client_user?.id
      defaultValue = (defaultValue || options.filter(({ id }) => id === parseInt(clientUserId))[0]) ?? null
    }

    if (name == 'location' && formData && formData[name]) {
      if (typeof formData[name] === 'string') {
        defaultValue = prefectures.find((location) => location.name === (formData[name] || formData[name]?.name))
      }
    }

    if (name === 'setting' && jobGroupId === 0) {
      defaultValue = clientSettings.find((setting) => setting.id === '編集中')
    }

    return (
      <InputAutoComplete
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        options={options}
        {...(defaultValue && { defaultinput: defaultValue })}
        itemSelect={(data) => handleAutoCompleteChange(data, name)}
        {...defaultInputProps}
        disabled={disabled}
        noPad={noPad}
        toolTip={isClient ? toolTip : ''}
      />
    )
  }

  const getInputLabelField = (label, name, placeholder = '', defaultValue) => {
    let inputValue = formData ? formData[name] : null
    return (
      <InputLabel
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        value={inputValue ? inputValue : defaultValue}
        {...defaultInputProps}
      />
    )
  }

  const getInputDatePickerField = (label, name, placeholder, value, minDate, maxDate) => {
    return (
      <InputDatePicker
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        name={name}
        label={label}
        placeholder={placeholder}
        color="primary"
        inputFormat={dateFormat}
        value={value ? value : null}
        {...(minDate && { minDate: dayjs(minDate) })}
        {...(maxDate && { maxDate: dayjs(maxDate) })}
        required
        onChange={(newVal) => onDateChange(newVal, name)}
      />
    )
  }

  const getInputRichTextEditor = (label, name, placeholder, height) => {
    defaultInputProps['sideLabel'] = false
    return (
      <InputRichTextEditor
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        defaultValue={formData ? formData[name] : null}
        label={label}
        height={height}
        required
      />
    )
  }

  const getInputMinMaxProps = (name, label, placeholder, tag) => {
    return {
      id: name + '-ID',
      ref: register(name, validationRules[name]),
      error: errors && errors[name] ? true : false,
      errorMsg: errors ? errors[name]?.message : null,
      name: name,
      label: label,
      defaultValue: formData ? formData[name] : null,
      placeholder: placeholder,
      tag: tag,
      ...defaultInputProps,
    }
  }

  const detailsPlaceHolder =
    '例:\n・現行ルータ撤去および新規ルータ設置、試験(機器のログ取得)\n・翌営業日立ち会い、トラブルの場合は旧ルータへの切り戻し対応\n・成果物の作成（簡単なラック図、フロア図）\nログ取得作業においてteratermを使用します。\nteratermでの作業実績がある方希望。\n翌営業日立ち会いはお客様先付近での待機、\nお客様より対応依頼あり次第の入館となります。\n担当頂く拠点によっては、翌営業日立ち会いの無い拠点もあります。\n'

  const feesPlaceHolder = '例:\n作業費17,000円（税別）\n翌営業日立会　作業費　12,000円（税別）'

  const allowancePlaceHolder =
    '例:\n・トラブル等で18：00以降の延長作業となった場合は、30分あたり1,000円の延長手当\n・往復60km以上1kmあたり20円の交通費\n・高速代実費、駐車場代実費'

  return (
    <Grid>
      <Fragment>
        <Grid container pb={6}>
          <Heading>{!isUpdate ? '作業グループ登録' : '作業グループ編集'}</Heading>
        </Grid>
        <form onSubmit={handleSubmit(handleSubmitJobGroup)} className="form">
          <Grid container>
            <Grid item xs={12}>
              {!isClient &&
                getAutoCompleteField('クライアント', 'client_id', 'クライアント', activeClientList, false, false)}
              {getAutoCompleteField(
                '案件',
                'project_id',
                isClient ? '選択してください' : '案件名',
                projectList,
                projectDisabled
              )}
              {getAutoCompleteField(
                '作業グループ担当者',
                'client_user_id',
                '担当者',
                clientUserList,
                clientUserDisabled
              )}
              {getAutoCompleteField('公開設定', 'setting', '公開設定', settings, false, true, toolTipTitle)}
              <Box ml="auto" pb={'28px'}>
                {isUpdate && (
                  <Checkbox
                    id="close_application_flag-ID"
                    name="close_application_flag"
                    ref={register('close_application_flag')}
                    checked={formData ? (formData?.close_application_flag == 1 ? true : false) : false}
                    label="募集を締め切る"
                  />
                )}
              </Box>
              {getInputLabelField('作業グループ名', 'name', '例: 無線LAN設定')}
              <Box>
                <Box display="inline-grid" width={{ xs: 1, sm: 1, md: 5.8 / 12 }} sx={{ marginInlineEnd: '3%' }}>
                  {getInputDatePickerField(
                    '作業グループ開始日',
                    'start_date',
                    `例: 2022/03/25`,
                    dateFromValue,
                    !isUpdate ? dateTomorrow : false,
                    defaultMaxStartDate
                  )}
                </Box>
                <Box display="inline-grid" width={{ xs: 1, sm: 1, md: 5.8 / 12 }}>
                  {getInputDatePickerField(
                    '作業グループ終了日',
                    'end_date',
                    `例: 2022/03/25`,
                    dateToValue,
                    defaultMinEndDate
                  )}
                </Box>
              </Box>
              {getAutoCompleteField('作業実施都道府県', 'location', '例: 宮城県', prefectures)}
            </Grid>
            <InputMinMax
              minProps={getInputMinMaxProps('min_salary', 'Min', '例: 10,000', '¥')}
              maxProps={getInputMinMaxProps('max_salary', 'Max', '例: 15,000', '¥')}
              label="基本作業費(1日あたり)"
              required
            />
            <Grid item xs={11.6} sx={{ marginTop: hasError ? -3 : 0 }}>
              {getInputLabelField('募集人数', 'no_of_workers', '', isClient && '1')}
            </Grid>
            <Grid item xs={0.4} pt={{ xs: 5, sm: 5 }} pl={1} sx={{ marginTop: hasError ? -3 : 0 }}>
              <Label>人</Label>
            </Grid>
            <Grid item xs={12}>
              {getInputLabelField(
                '応募問い合わせ用メールアドレス',
                'email_address',
                '例: inquiries@example.com',
                isClient && user?.email_address
              )}
            </Grid>
            <Grid item xs={12}>
              <Box pb={'28px'}>
                <P mt={-3}>
                  {'募集要項に開示されます。不都合がある場合は公開可能な メールアドレスを設定してください。'}
                </P>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {getInputRichTextEditor('作業内容', 'details', detailsPlaceHolder, 383)}
              {getInputRichTextEditor('作業費', 'fees', feesPlaceHolder, 192)}
              {getInputRichTextEditor('その他手当', 'allowances', allowancePlaceHolder, 192)}
            </Grid>
            <Grid container pt={4} justifyContent="end">
              <Grid item xs={12} sm={4} md={3} lg={2} textAlign="end">
                <LargeButton id="JobGroupSubmitBtn" type="submit">
                  {'確認'}
                </LargeButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Fragment>
    </Grid>
  )
}

JobGroupCreateUpdate.displayName = 'Job Group Create/Update Form'

JobGroupCreateUpdate.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
}

export default JobGroupCreateUpdate
