import * as types from './actionTypes'

export function actionSearchWorkJobGroups(jobGroup) {
  return {
    type: types.SEARCH_WORK_JOB_GROUPS,
    payload: jobGroup,
  }
}

export function actionSetSearchCriteria(searchData) {
  return {
    type: types.SET_SEARCH_CRITERIA,
    payload: { searchData },
  }
}

export function resetCurrentForm() {
  return {
    type: types.RESET_CURRENT_FORM,
  }
}

export function resetSearch() {
  return {
    type: types.SEARCH_RESET,
  }
}

export function setAdvanceSearch(advanceSearch) {
  return {
    type: types.SET_ADVANCE_SEARCH,
    payload: advanceSearch,
  }
}

export function resetLoadedPage() {
  return {
    type: types.RESET_PAGE_LOADED,
  }
}
