import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

//componets
import { Label, Checkbox, Caption } from 'components'

const StyledInputMultiCheckbox = styled(Box)(({ fontSize, theme }) => ({
  padding: `${theme.standards.padding.input.default}`,
  '& .InputLabel': {
    paddingBottom: `${theme.standards.padding.label.inputLabel}`,
    fontSize: `${theme.standards.fontSize['input_label']}px !important`,
  },
  '& .InputLabel-subLabel': {
    paddingBottom: `${theme.standards.padding.label.inputLabel}`,
  },
  '& .RequiredText': {
    display: 'inline-flex',
  },
  '& .MuiFormControlLabel-label': {
    fontSize: `${fontSize}px !important` ?? '',
  },
}))

const InputMultiCheckbox = React.forwardRef((props, ref) => {
  const excludedProps = ['label', 'labelProps', 'required', 'items', 'selected', 'subLabel']
  let defaultProps = omit(props, excludedProps)
  const { label, labelProps, items, selected, fontSize, name } = props

  return (
    <StyledInputMultiCheckbox fontSize={fontSize}>
      <Label {...labelProps} className="InputLabel">
        {label}
        {props.required && (
          <Label color="error" className="RequiredText">
            *
          </Label>
        )}
      </Label>
      {props?.subLabel && (
        <Caption color="primary" className="InputLabel-subLabel">
          {props?.subLabel}
        </Caption>
      )}
      <Box width={1} className="CheckboxWrapper">
        {items &&
          items.map((value, index) => {
            return (
              <Box key={index}>
                <Checkbox
                  ref={ref}
                  id={`${name}-${value.id}`}
                  value={value.id}
                  checked={selected.includes(value.id) ? true : false}
                  label={value?.name}
                  disabled={value?.isDisabled}
                  {...defaultProps}
                />
              </Box>
            )
          })}
      </Box>
    </StyledInputMultiCheckbox>
  )
})

InputMultiCheckbox.displayName = 'Input Multi Checkbox'

InputMultiCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
  defaultValue: PropTypes.any,
  required: PropTypes.bool,
  items: PropTypes.array,
  selected: PropTypes.array,
  fontSize: PropTypes.number,
  subLabel: PropTypes.string,
  name: PropTypes.string,
}

export default InputMultiCheckbox
