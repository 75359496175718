import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { LargeButton, InputTextArea, Label } from 'components'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { submitWorkerExpense, readWorkerJob } from 'services/jobs'

const StyledForm = styled('form')(() => ({
  display: 'contents',
}))

function ExpenseCompletion(props) {
  const { onClose, jobId, reportStatus, setIsEdit, setModalType } = props
  const dispatch = useDispatch()
  const [isRequesting, setIsRequesting] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const validationRules = {
    comment: {
      required: {
        value: String,
        message: '修正コメントを入力してください。',
      },
      maxLength: {
        value: 512,
        message: '512文字以内で入力してください。',
      },
    },
  }
  const handleModalClose = () => {
    onClose()
  }

  const handleExpenseSubmit = (data) => {
    setIsRequesting(true)
    dispatch(submitWorkerExpense(jobId, data)).finally(() => {
      dispatch(readWorkerJob(jobId, false)).finally(() => {
        setIsRequesting(false)
        setModalType('confirm')
        setIsEdit(false)
      })
    })
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} pt={3}>
        <Label>{'この内容で経費報告しますか？'}</Label>
      </Grid>
      <StyledForm onSubmit={handleSubmit(handleExpenseSubmit)} className="ExpenseCompletionForm">
        <Grid item xs={12} pt={4}>
          <InputTextArea
            name="comment"
            ref={register('comment', validationRules.comment)}
            error={errors && errors.comment ? true : false}
            errorMsg={errors ? errors?.comment?.message : null}
            label="修正コメント"
            required
            borderRadius={0}
            borderColor="#707070"
            height={100}
          />
        </Grid>
        <Grid item container xs={12} justifyContent="center" textAlign="center" gap={2} rowSpacing={2}>
          <Grid item xs={4}>
            <LargeButton
              id="cancelExpenseReport"
              color="gray"
              mode="btn1"
              onClick={handleModalClose}
              disabled={isRequesting}
            >
              {'キャンセル'}
            </LargeButton>
          </Grid>
          <Grid item xs={4}>
            <LargeButton id="confirmExpenseReport" type="submit" disabled={!reportStatus || isRequesting}>
              {'はい'}
            </LargeButton>
          </Grid>
        </Grid>
      </StyledForm>
    </Grid>
  )
}

ExpenseCompletion.propTypes = {
  onClose: PropTypes.func,
  jobId: PropTypes.string,
  reportStatus: PropTypes.bool,
  setIsEdit: PropTypes.func,
  setModalType: PropTypes.func,
}

export default ExpenseCompletion
