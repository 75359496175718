import React, { useState, useEffect } from 'react'
import { Grid, Divider, Box } from '@mui/material'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import searchIcon from 'assets/svg/search.svg'
import * as dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
//validation msg
import { getMessages, currencyPattern } from 'validation'
//store
import { useDispatch, useSelector } from 'react-redux'
import { setAdvanceSearch, actionSetSearchCriteria } from 'store/job-seeker/actionCreators'
//utils
import {
  datePresets,
  thisDay,
  thisMonthStart,
  thisMonthEnd,
  prevMonthStart,
  prevMonthEnd,
  nextMonthStart,
  nextMonthEnd,
  dateFormat,
} from 'utils/common'
//components
import {
  Heading,
  InputLabel,
  InputSelect,
  Accordion,
  InputDatePicker,
  Checkbox,
  Label,
  LargeButton,
  Icon,
} from 'components'

const StyledJobSearchForm = styled(Grid)(({ theme }) => ({
  marginBottom: '-35px !important',
  '& .MuiAccordion-root': {
    border: 'none',
    boxShadow: 'none',
    '& .MuiAccordionSummary-expandIconWrapper': {
      background: theme.palette.disabled,
      borderRadius: 4,
    },
    '& .MuiAccordion-Tile': {
      pointerEvents: 'none',
      padding: 0,
      '& .MuiAccordionSummary-content': {
        '& .MuiTypography-root': {
          pointerEvents: 'auto',
          cursor: 'pointer',
        },
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        pointerEvents: 'auto',
      },
    },
  },
  '& .MuiDivider-root': {
    borderBottomWidth: 1,
    borderColor: '#ACACAC',
    opacity: 0.35,
    marginLeft: -45,
    marginRight: -45,
    [theme.breakpoints.down('sm')]: {
      marginLeft: -15,
      marginRight: -15,
    },
  },
}))

const JobSearchForm = (props) => {
  const dispatch = useDispatch()
  const rulesMsg = getMessages('ja')
  const prefecturesList = useSelector((state) => state.common.prefectureslist)
  const searchData = useSelector((state) => state.jobSeeker.search)
  const advanceSearch = useSelector((state) => state.jobSeeker.is_advance_search)
  const authUser = JSON.parse(localStorage.getItem('authUser'))

  const [prefectureValue, setPrefectureValue] = useState(searchData?.prefecture || 0)
  const [datePresetValue, setdatePresetValue] = useState(searchData?.date_preset || 1)
  const [dateFromValue, setDateFromValue] = useState(thisMonthStart)
  const [dateToValue, setDateToValue] = useState(thisMonthEnd)

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm()

  //form validation
  const validationRules = {
    keyword: {
      validate: (value) => {
        return value.trim().length <= 255 || rulesMsg.max(255)
      },
    },
    start_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
    end_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        const startDateVal = getValues('start_date')
        if (!isValid) {
          return rulesMsg.invalid_date()
        } else if (dayjs(value).isBefore(startDateVal)) {
          return rulesMsg.is_date_before()
        } else {
          return true
        }
      },
    },
    min_fee: {
      validate: (value) => {
        if (!value) return true
        const intMinValue = value.replaceAll(',', '')
        return (
          (parseFloat(intMinValue) >= 1 && parseFloat(intMinValue) <= 1000000) || rulesMsg.between(['1', '1,000,000'])
        )
      },
      pattern: {
        value: currencyPattern, // Regex numbers and commas Validation
        message: rulesMsg.between(['1', '1,000,000']),
      },
    },
    max_fee: {
      validate: (value) => {
        if (!value) return true
        const min_fee = getValues('min_fee')
        const intMinValue = min_fee.replaceAll(',', '')
        const intMaxValue = value.replaceAll(',', '')
        if (parseFloat(intMaxValue) < parseFloat(intMinValue)) {
          return rulesMsg.is_equal_greater()
        } else if (!(parseFloat(intMaxValue) >= 1 && parseFloat(intMaxValue) <= 1000000)) {
          return rulesMsg.between(['1', '1,000,000'])
        } else {
          return true
        }
      },
      pattern: {
        value: currencyPattern, // Regex numbers and commas Validation
        message: rulesMsg.between(['1', '1,000,000']),
      },
    },
  }

  //set default form data
  useEffect(() => {
    let formDataStartDate = !isEmpty(searchData?.start_date) ? dayjs(searchData['start_date']) : null
    let formDataEndDate = !isEmpty(searchData?.end_date) ? dayjs(searchData['end_date']) : null
    setDateFromValue(formDataStartDate)
    setDateToValue(formDataEndDate)
  }, [searchData?.start_date, searchData?.end_date])

  //set prefecture value
  useEffect(() => {
    setValue('prefecture', prefectureValue)
  }, [prefectureValue])

  //set default prefecture value
  useEffect(() => {
    if (searchData?.prefecture === '') {
      setPrefectureValue(authUser?.worker_prefecture?.id || 0)
      dispatch(actionSetSearchCriteria({ prefecture: authUser?.worker_prefecture?.id || 0 }))
    }
  }, [authUser?.worker_prefecture?.id])

  //handle change prefecture
  const onChangePrefecture = (value) => {
    setPrefectureValue(value)
  }

  //on Search
  const handleSearch = (data) => {
    if (!advanceSearch) {
      let emptyAdvanceSearch = {
        start_date: '',
        end_date: '',
        min_fee: '',
        max_fee: '',
        is_include: '',
      }
      data = {
        ...data,
        ...emptyAdvanceSearch,
      }
    }
    props.handlePageSearch(data)
  }

  //date Preset onchange
  const handleDatePreset = (preset) => {
    switch (preset) {
      case 1: //this month
        setDateFromValue(thisMonthStart)
        setDateToValue(thisMonthEnd)
        break
      case 2: //prev month
        setDateFromValue(prevMonthStart)
        setDateToValue(prevMonthEnd)
        break
      case 3: //next month
        setDateFromValue(nextMonthStart)
        setDateToValue(nextMonthEnd)
        break
      case 4: //this day
        setDateFromValue(thisDay)
        setDateToValue(thisDay)
        break
      default:
        break
    }
    dispatch(actionSetSearchCriteria({ date_preset: preset }))
    setdatePresetValue(preset)
  }

  //on date change
  const onDateChange = (newVal, type) => {
    setdatePresetValue(5)
    dispatch(actionSetSearchCriteria({ date_preset: 5 }))
    if (type == 'start_date') {
      setDateFromValue(newVal)
      //if date from is set greater than date to, then adjust date to
      if (dayjs(newVal).diff(dayjs(dateToValue)) >= 1) {
        setDateToValue(newVal)
      }
    } else {
      setDateToValue(newVal)
    }
  }

  const handleAccordion = (e, expand) => {
    dispatch(setAdvanceSearch(expand))
  }

  return (
    <StyledJobSearchForm container>
      <Grid>
        <Heading>作業検索</Heading>
      </Grid>
      <Box component="form" onSubmit={handleSubmit(handleSearch)} className="form">
        <Grid item container xs={12} pt={4} columnSpacing={2}>
          <Grid item xs={12} sm={6} md={8}>
            <InputLabel
              id="jobSearch-keyword-ID"
              name="keyword"
              ref={register('keyword', validationRules.keyword)}
              error={errors && errors.keyword ? true : false}
              errorMsg={errors ? errors?.keyword?.message : null}
              label="キーワード"
              placeholder="作業名、作業内容を検索"
              type="text"
              value={searchData?.keyword || null}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <InputSelect
              id="jobSearch-prefecture-ID"
              ref={register('prefecture')}
              name="prefecture"
              label={'都道府県'}
              menuItems={[{ id: '0', name: 'すべて' }, ...prefecturesList]}
              value={prefecturesList?.length > 0 ? parseInt(prefectureValue) : 0}
              onChange={onChangePrefecture}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2} pt={{ xs: 0, sm: 3.5 }}>
            <LargeButton
              id="JobSearch-submit-ID"
              type="submit"
              bold="true"
              color="warning"
              maxwidth="unset"
              startIcon={<Icon size={'sm'} source={searchIcon} />}
            >
              {'検索'}
            </LargeButton>
          </Grid>
          <Grid item xs={12} pt={2}>
            <Divider />
          </Grid>
          <Grid item container xs={12} display="block" py={2}>
            <Accordion
              label="その他のオプション"
              labelAlign="right"
              expanded={advanceSearch}
              onChange={handleAccordion}
            >
              <Grid item container xs={12} columnSpacing={2}>
                <Grid item xs={12} sm={3} md={2.2} lg={1.4} xl={1.5}>
                  <InputSelect
                    id="jobGroup-date_preset-ID"
                    name="date_preset"
                    label={'作業日'}
                    menuItems={datePresets}
                    value={datePresetValue}
                    onChange={(e) => handleDatePreset(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={4.5} md={2.8} lg={1.8} xl={2}>
                  <InputDatePicker
                    id="jobGroup-start_date-ID"
                    ref={register('start_date', validationRules.start_date)}
                    error={errors && errors.start_date ? true : false}
                    errorMsg={errors ? errors?.start_date?.message : null}
                    name="start_date"
                    color="primary"
                    inputFormat={dateFormat}
                    value={dayjs(dateFromValue)}
                    onChange={(newVal) => onDateChange(newVal, 'start_date')}
                  />
                </Grid>
                <Grid item xs={12} sm={4.5} md={2.8} lg={1.8} xl={2}>
                  <InputDatePicker
                    id="jobGroup-end_date-ID"
                    ref={register('end_date', validationRules.end_date)}
                    error={errors && errors.end_date ? true : false}
                    errorMsg={errors ? errors?.end_date?.message : null}
                    name="end_date"
                    color="primary"
                    inputFormat={dateFormat}
                    value={dayjs(dateToValue)}
                    onChange={(newVal) => onDateChange(newVal, 'end_date')}
                    minDate={dayjs(dateFromValue)}
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={8} lg={5} xl={4} p={0} justifyContent="space-around">
                  <Grid item xs={5} sm={5} md={5} lg={4} xl={5} display="inline-flex">
                    <Label pt={5}>¥</Label>&nbsp;
                    <InputLabel
                      id="jobSearch-min_fee-ID"
                      name="min_fee"
                      ref={register('min_fee', validationRules.min_fee)}
                      error={errors && errors.min_fee ? true : false}
                      errorMsg={errors ? errors?.min_fee?.message : null}
                      label="基本作業費"
                      type="text"
                      labelProps={{ ml: '-12px' }}
                      value={searchData?.min_fee || null}
                    />
                  </Grid>
                  <Grid item px={1}>
                    <Label pt={5}>〜</Label>
                  </Grid>
                  <Grid item xs={5} display="inline-flex" textAlign="end" justifyContent="end">
                    <Label pt={5}>¥</Label>&nbsp;
                    <InputLabel
                      id="jobSearch-max_fee-ID"
                      name="max_fee"
                      ref={register('max_fee', validationRules.max_fee)}
                      error={errors && errors.max_fee ? true : false}
                      errorMsg={errors ? errors?.max_fee?.message : null}
                      type="text"
                      value={searchData?.max_fee || null}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={5} md={2.5} lg={2} xl={2} pt={{ xs: 0, sm: 0, md: 4.2, lg: 4.2 }}>
                  <Checkbox
                    id="jobSearch-include_closed-ID"
                    name="is_include"
                    ref={register('is_include', validationRules[name])}
                    error={errors && errors?.include_closed ? 1 : 0}
                    errorMsg={errors ? errors?.include_closed?.message : null}
                    checked={searchData?.is_include === 1}
                    label="募集終了も含める"
                  />
                </Grid>
              </Grid>
            </Accordion>
          </Grid>
        </Grid>
      </Box>
    </StyledJobSearchForm>
  )
}

JobSearchForm.propTypes = {
  handlePageSearch: PropTypes.func,
}

export default JobSearchForm
