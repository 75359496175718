import React, { lazy, Suspense, useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import NotFound from '../views/NotFound'
import Main from 'layouts/main'
import { Loader } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { setRedirectRoute, actionSet404Page } from 'store/common/actionCreators'

function Public(props) {
  const { component, layout, mode, ...rest } = props
  const auth = useSelector((state) => state?.auth?.user)
  const forceRedirect = useSelector((state) => state?.common?.redirect)
  const Layout = layout ? lazy(() => import(`../layouts/${layout}`)) : Main
  const Component = lazy(() => import(`../${component}`))
  const renderLoader = Loader
  const dispatch = useDispatch()
  const history = useHistory()
  const nonAuthRouter = ['/privacy_policy', '/terms_of_use', '/password-settings']
  const isFromRegistration = location.pathname.includes('/register/verify')
  const isNotFound = useSelector((state) => state.common.notFound)

  useEffect(() => {
    dispatch(actionSet404Page(false))
  }, [location.pathname])

  useEffect(() => {
    if (!nonAuthRouter.includes(props?.path)) {
      if (auth) {
        switch (mode) {
          case 'opc':
            history.push(forceRedirect ? forceRedirect : '/project_list')
            break
          case 'wkr':
            switch (auth?.member_type) {
              case '会員': //member
                history.push('/job/search')
                break
              case 'ワーカー': //worker
                history.push(forceRedirect ? forceRedirect : '/my-page')
                break
              default:
                history.push('/login')
                break
            }
            break
          case 'client':
            history.push(
              !forceRedirect
                ? isFromRegistration
                  ? { pathname: '/project/list', state: { fromRegistration: true } }
                  : { pathname: '/job/list', state: { fromLogin: true } }
                : forceRedirect
            )
            break
          default:
            break
        }
        dispatch(setRedirectRoute(null))
      }
    }
  }, [auth])

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={renderLoader()}>
          {isNotFound && <NotFound />}
          {!isNotFound && (
            <Layout mode={mode}>
              <Component {...props} />
            </Layout>
          )}
        </Suspense>
      )}
    />
  )
}

Public.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  location: PropTypes.object,
  mode: PropTypes.string,
  path: PropTypes.string,
}

export default Public
