import Http from 'utils/Http'
import { formatOfferDropdownData } from 'utils/helper'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  actionSearchApplication,
  actionSetSearchCriteria,
  fetchingDataFail,
  setApplicationFormData,
  OPCActionSearchApplication,
  OPCActionSetSearchCriteria,
} from 'store/application/actionCreators'
import { actionSetOfferDropdownData } from 'store/common/actionCreators'

export function OPCSearchApplications(params) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('client/applications', { params })
      .then((response) => {
        dispatch(OPCActionSearchApplication(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function searchApplications(params) {
  return async (dispatch) => {
    dispatch(showLoader())
    return await Http.get('client/applications', {
      params,
    })
      .then((response) => {
        dispatch(actionSearchApplication(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function OPCChangeSearchCriteria(searchParameters) {
  return (dispatch) => {
    dispatch(OPCActionSetSearchCriteria(searchParameters))
  }
}
export function changeSearchCriteria(searchParameters) {
  return (dispatch) => {
    dispatch(actionSetSearchCriteria(searchParameters))
  }
}

export function getApplication(id) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get(`client/applications/${id}`)
      .then((response) => {
        let formData = []
        formData['original'] = response.data.data
        dispatch(setApplicationFormData(formData))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function cancelApplication(id) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('client/applications/reject', { id: id })
      .then((response) => {
        let formData = []
        formData['original'] = response.data.data
        dispatch(setApplicationFormData(formData))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function opcGetApplication(id) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get(`client/applications/${id}`)
      .then((response) => {
        let formData = []
        formData['original'] = response.data
        dispatch(setApplicationFormData(formData))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function getDropdownData(mode, id = null) {
  let url = ''
  switch (mode) {
    case 'projects':
      url = 'client/applications/projects'
      break
    case 'job_groups':
      url = `client/applications/job-groups?project_id=${id}`
      break
    case 'workers':
      url = `client/applications/workers?job_group_id=${id}`
      break
    case 'client_users':
      url = 'client/users'
      break
    default:
      break
  }
  return (dispatch) => {
    return Http.get(url)
      .then((response) => {
        let options = {}
        options[mode] = formatOfferDropdownData(response.data.data)
        dispatch(actionSetOfferDropdownData(options))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
  }
}
