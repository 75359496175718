import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, FormHelperText, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { setFormMode, setConfirming, setJobFormData, setFormErrors, resetCurrentForm } from 'store/jobs/actionCreators'
import { getDropdownData } from 'services/application'
import { getAddress } from 'services/common'
import { readJob } from 'services/jobs'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as dayjs from 'dayjs'
//validation msg
import { getMessages } from 'validation'
//utils
import { formatWorkerDocuments, formatTimeString, formatAmount } from 'utils/helper'
import { dateFormat, jobCheckboxOptions, timeFormat, thisDay } from 'utils/common'
//componets
import {
  Label,
  Heading,
  InputAutoComplete,
  InputRichTextEditor,
  InputLabel,
  InputTextArea,
  InputMultiCheckbox,
  LargeButton,
  Checkbox,
  LabelDisplay,
  Caption,
  OfferDateRange,
} from 'components'

const StyledOfferCreateUpdateForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .ProjectList-form': {
    width: '100%',
  },
  '& .CheckboxWrapper': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, auto)',
    },
  },
  '& .DisplayNameWrapper .MuiBox-root': {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  '& .MuiButton-root.UploadBtn': {
    borderRadius: 5,
    '& .MuiFormControl-root': {
      display: 'none',
    },
  },
  '& input#InputUploadFile-ID': {
    display: 'none',
  },
  '& .WorkExperience-Wrapper': {
    '& .MuiBox-root:first-of-type': {
      padding: 0,
    },
  },
  '& .BankAccount-Wrapper': {
    '& .MuiBox-root': {
      padding: 0,
    },
  },
  '& .LabelInfo': {
    fontSize: '12px',
    padding: '5px 15px 5px 15px',
    margin: '0px 2px 15px 2px',
    backgroundColor: 'oldlace',
    borderRadius: '5px',
  },
  '& .Destination-Wrapper': {
    paddingTop: '30px',
  },
  '& .LabelInfoText': {
    '& span': {
      color: 'red',
    },
  },
  '& .Cancellation-Wrapper': {
    '& .MuiBox-root:first-of-type': {
      padding: 14,
    },
    '& p.MuiFormHelperText-root.Cancellation-Note': {
      color: `${theme.palette.text.error} !important`,
      fontSize: 12,
    },
  },
  '& .AddressWrapper .MuiTypography-root': {
    display: 'none',
  },
  '& .JobCheckbox-Wrapper .MuiBox-root': {
    paddingBottom: 0,
  },
}))

const OfferCreateUpdateForm = (props) => {
  const rulesMsg = getMessages('ja')
  const dispatch = useDispatch()
  const history = useHistory()
  const isFromJobList = props?.isFromJobList
  //useform hook
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors, isSubmitted },
    watch,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
  } = useForm()
  const emailRegex =
    /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/i
  let providedToWorker = 'その他'
  let itemProvidedDesc = '作業に使用する部材'
  const start_time = useRef({})
  start_time.current = watch('start_time', '')
  const auth = JSON.parse(localStorage.getItem('authUser'))
  const formErrors = useSelector((state) => state.jobs.formErrors)
  const projectList = useSelector((state) => state.common.offerDropdown?.projects) || []
  const jobGroupList = useSelector((state) => state.common.offerDropdown?.job_groups) || []
  const workerList = useSelector((state) => state.common.offerDropdown?.workers) || []
  const clientUserList = useSelector((state) => state.common.offerDropdown?.client_users) || []

  //dropown data
  const urlParams = new URLSearchParams(window.location.search)
  const [activeProject, setActiveProject] = useState(urlParams.get('project'))
  const [activeWorker, setActiveWorker] = useState(urlParams.get('worker'))
  const [activeJobGroup, setActiveJobGroup] = useState(urlParams.get('job_group'))
  const [activeClientUser, setActiveClientUser] = useState(auth?.client_user?.id)
  const activeJob = urlParams.get('job')
  const withApplication = !!parseInt(urlParams.get('with_applicants'))
  const [isProvidedToWorkerOther, setIsProvidedToWorkerOther] = useState(false)
  const [isItemsProvidedDescription, setIsItemsProvidedDescription] = useState(false)
  const [isCancelationFee, setIsCancelationFee] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [prefecture, setPrefecture] = useState('')
  const [municipality, setMunicipality] = useState('')
  const [picEmail, setPicEmail] = useState('')
  const [isDepartureRecipients, setIsDepartureRecipients] = useState(false)
  const [departureRecipients, setDepartureRecipients] = useState('')
  const [isArrivalRecipients, setIsArrivalRecipients] = useState(false)
  const [arrivalRecipients, setArrivalRecipients] = useState('')
  const [isOneDayBeforeRecipients, setIsOneDayBeforeRecipients] = useState(false)
  const [oneDayBeforeRecipients, setOneDayBeforeRecipients] = useState('')
  const [clockInRecipients, setClockInRecipients] = useState('')
  const [clockOutRecipients, setClockOutRecipients] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [jobGroupDates, setJobGroupDates] = useState({ start_date: null, end_date: null })
  const [providedToWorkerInput, setProvidedToWorkerInput] = useState('')
  const [providedDescriptionInput, setItemProvidedDescriptionInput] = useState('')
  //form data
  const currentformData = useSelector((state) => state.jobs.formData)

  let deafultFormData =
    currentformData.current && Object.keys(currentformData.current).length > 0
      ? currentformData.current
      : currentformData.original

  const [formData, setFormData] = useState(null)
  //dropdown state
  const [jobGroupDisabled, setJobGroupDisabled] = useState(deafultFormData?.job_group ? false : true)
  const [workerDisabled, setWorkerDisabled] = useState(deafultFormData?.job_group ? false : true)
  //state
  const [isUpdate, setIsUpdate] = useState(false)
  const [requestAddress, setRequestAddress] = useState(false)
  const [selectedCheckboxOptions, setSelectedCheckboxOptions] = useState(['手順書', '筆記用具'])

  //Reset selected dates when page go back on other page
  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        const currentPath = history.location.pathname
        if (!(currentPath.includes('/offer/create') || currentPath.includes('/offer/confirmation'))) {
          dispatch(resetCurrentForm())
        }
      }
    }
  }, [history])

  useEffect(() => {
    if (isFromJobList && activeJob && withApplication) {
      dispatch(readJob(activeJob))
    }

    if (isFromJobList && !withApplication) {
      dispatch(resetCurrentForm())
      setActiveProject(null)
      setActiveJobGroup(null)
      setActiveClientUser(null)
    }
  }, [isFromJobList, activeJob, withApplication])

  //dispatch hooks
  useEffect(() => {
    if (props?.location?.pathname.includes('update')) {
      setIsUpdate(true)
      dispatch(setFormMode(true))
    } else {
      setIsUpdate(false)
      dispatch(setFormMode(false))
    }
  }, [dispatch])

  //formErrors hooks
  useEffect(() => {
    Object.keys(formErrors)
      .filter((key) => !key.includes('job_dates'))
      .map((key) => {
        setError(key, { type: 'custom', message: formErrors[key][0] }, { shouldFocus: true })
      })
    //job dates error
    Object.keys(formErrors)
      .filter((key) => key.includes('job_dates'))
      .map((key) => {
        var index = key.replace(/[^0-9]/g, '')
        setError(
          `job_dates[${index}]`,
          { type: 'custom', message: formErrors[`job_dates.${index}`][0] },
          { shouldFocus: true }
        )
      })
  }, [formErrors])

  useEffect(() => {
    if (deafultFormData) {
      const clientUserID =
        isFromJobList && activeJob && withApplication ? auth?.client_user?.id : deafultFormData?.client_user_id
      const clientUser = clientUserID ? clientUserList.find(({ id }) => id === parseInt(clientUserID)) : null
      const project = deafultFormData?.project_id
        ? projectList.find(({ id }) => id === parseInt(deafultFormData?.project_id))
        : null
      const jobGroup = deafultFormData?.job_group_id
        ? jobGroupList.find(({ id }) => id === parseInt(deafultFormData?.job_group_id))
        : null
      const worker = deafultFormData?.worker_id
        ? workerList.find(({ id }) => id === parseInt(deafultFormData?.worker_id))
        : null

      setFormData({
        ...deafultFormData,
        project_id: project,
        job_group: jobGroup,
        worker: worker,
        client_user_id: clientUser,
        invoice_generated: isUpdate ? true : false,
      })
      setActiveProject(deafultFormData?.project_id)
      setActiveJobGroup(deafultFormData?.job_group_id)
      setActiveWorker(deafultFormData?.worker_id)
      setActiveClientUser(clientUserID)
      setIsOneDayBeforeRecipients(deafultFormData?.one_day_before_recipients?.length > 0 ? true : false)
      setIsDepartureRecipients(deafultFormData?.departure_recipients?.length > 0 ? true : false)
      setIsArrivalRecipients(deafultFormData?.arrival_recipients?.length > 0 ? true : false)
      setOneDayBeforeRecipients(deafultFormData?.one_day_before_recipients || '')
      setDepartureRecipients(deafultFormData?.departure_recipients || '')
      setArrivalRecipients(deafultFormData?.arrival_recipients || '')
      setClockInRecipients(deafultFormData?.clock_in_recipients || deafultFormData?.client_user?.email_address || '')
      setClockOutRecipients(deafultFormData?.clock_out_recipients || deafultFormData?.client_user?.email_address || '')
      setPrefecture(deafultFormData?.prefecture ?? '')
      setMunicipality(deafultFormData?.municipality ?? '')
      setAddressLine1(deafultFormData?.address_line_1 ?? '')
      setProvidedToWorkerInput(deafultFormData?.provided_to_worker_other)
      setItemProvidedDescriptionInput(deafultFormData?.items_provided_description)
    }
  }, [dispatch, deafultFormData])

  //formData hooks
  useEffect(() => {
    let selectedOptions = ['手順書', '筆記用具']
    let providedDocuments = deafultFormData?.provided_documents || []
    let providedItems = deafultFormData?.provided_items || []
    let workerTools = deafultFormData?.worker_tools || []
    selectedOptions = providedDocuments.concat(providedItems, workerTools)

    if (deafultFormData?.client_id) {
      jobCheckboxOptions.map((type) =>
        type.options.map((option) => {
          if (deafultFormData?.provided_documents) {
            deafultFormData?.provided_documents.map((value) => {
              if (value.name == option.name) {
                if (!selectedOptions.includes(option.name)) {
                  selectedOptions.push(option.name)
                  if (option.id == providedToWorker) {
                    setIsProvidedToWorkerOther(true)
                  }
                }
              }
            })
            deafultFormData?.provided_items.map((value) => {
              if (value.name == option.name) {
                if (!selectedOptions.includes(option.name)) {
                  selectedOptions.push(option.name)
                  if (option.id == itemProvidedDesc) {
                    setIsItemsProvidedDescription(true)
                  }
                }
              }
            })
            deafultFormData?.worker_tools.map((value) => {
              if (value.name == option.name) {
                if (!selectedOptions.includes(option.name)) {
                  selectedOptions.push(option.name)
                }
              }
            })
          } else {
            if (deafultFormData?.goods_supplied_chk) {
              deafultFormData?.goods_supplied_chk.map((value) => {
                if (!selectedOptions.includes(value)) {
                  selectedOptions.push(value)
                  if (value === providedToWorker) {
                    setIsProvidedToWorkerOther(true)
                  }
                }
              })
            }
            if (deafultFormData?.goods_sent_to_worker_chk) {
              deafultFormData?.goods_sent_to_worker_chk.map((value) => {
                if (!selectedOptions.includes(value)) {
                  selectedOptions.push(value)
                  if (value === itemProvidedDesc) {
                    setIsItemsProvidedDescription(true)
                  }
                }
              })
            }
            if (deafultFormData?.goods_worker_bring_chk) {
              deafultFormData?.goods_worker_bring_chk.map((value) => {
                if (!selectedOptions.includes(value)) {
                  selectedOptions.push(value)
                }
              })
            }
          }
        })
      )
      setSelectedCheckboxOptions(selectedOptions)
      setValue('goods_supplied_chk', ['手順書'])
      setValue('goods_worker_bring_chk', ['筆記用具'])
    } else {
      setValue('goods_supplied_chk', ['手順書'])
      setValue('goods_worker_bring_chk', ['筆記用具'])
      setSelectedCheckboxOptions(['手順書', '筆記用具'])
    }

    if (deafultFormData?.provided_to_worker_other) {
      setIsProvidedToWorkerOther(true)
    }
    if (deafultFormData?.items_provided_description) {
      setIsItemsProvidedDescription(true)
    }
  }, [formData, deafultFormData])

  //project dropdown hooks
  useEffect(() => {
    const selectedProject = activeProject ? projectList.find(({ id }) => id === parseInt(activeProject)) : null
    setFormData((prevState) => ({
      ...prevState,
      project_id: selectedProject,
    }))
    setJobGroupDisabled(activeProject ? false : true)
  }, [activeProject, projectList])

  //job group dropdown hooks
  useEffect(() => {
    const selectedJobGroup = activeJobGroup ? jobGroupList.find(({ id }) => id === parseInt(activeJobGroup)) : null
    setFormData((prevState) => ({
      ...prevState,
      job_group: selectedJobGroup,
    }))
    setJobGroupDates({
      start_date: selectedJobGroup?.start_date,
      end_date: selectedJobGroup?.end_date,
    })
    setWorkerDisabled(activeJobGroup ? false : true)
  }, [activeJobGroup, jobGroupList])

  //worker dropdown hooks
  useEffect(() => {
    const selectedWorker = activeWorker ? workerList.find(({ id }) => id === parseInt(activeWorker)) : null
    setFormData((prevState) => ({
      ...prevState,
      worker: selectedWorker,
      application_id: selectedWorker?.application_id || null,
    }))
  }, [activeWorker, workerList])

  //client user dropdown hooks
  useEffect(() => {
    const selectedClientUser = activeClientUser
      ? clientUserList.find(({ id }) => id === parseInt(activeClientUser))
      : null
    setFormData((prevState) => ({
      ...prevState,
      client_user_id: selectedClientUser,
    }))
    const newPIC = selectedClientUser?.user?.email_address ?? ''
    handleReminderEmails(picEmail, newPIC)
    setPicEmail(selectedClientUser?.user?.email_address || '')
  }, [activeClientUser, clientUserList])

  //check input name type
  const isReminders = (name) => {
    return (
      name === 'one_day_before_recipients' ||
      name === 'departure_recipients' ||
      name === 'arrival_recipients' ||
      name === 'clock_in_recipients' ||
      name === 'clock_out_recipients'
    )
  }

  //triger validations if form already submitted and checkbox isOneDayBeforeRecipients value changed
  useEffect(() => {
    isSubmitted && trigger('one_day_before_recipients')
  }, [isOneDayBeforeRecipients])

  //triger validations if form already submitted and checkbox isDepartureRecipients value changed
  useEffect(() => {
    isSubmitted && trigger('departure_recipients')
  }, [isDepartureRecipients])

  //triger validations if form already submitted and checkbox isArrivalRecipients value changed
  useEffect(() => {
    isSubmitted && trigger('arrival_recipients')
  }, [isArrivalRecipients])

  //triger validations if form already submitted and checkbox isProvidedToWorkerOther value changed
  useEffect(() => {
    isSubmitted && trigger('provided_to_worker_other')
  }, [isProvidedToWorkerOther])

  //triger validations if form already submitted and checkbox isItemsProvidedDescription value changed
  useEffect(() => {
    isSubmitted && trigger('items_provided_description')
  }, [isItemsProvidedDescription])

  const handleClick = (e) => {
    if (!e.target.checked) {
      const index = selectedCheckboxOptions.indexOf(e.target.value)
      if (index > -1) {
        selectedCheckboxOptions.splice(index, 1)
      }
    }

    if (e.target.checked && !selectedCheckboxOptions.includes(e.target.value)) {
      selectedCheckboxOptions.push(e.target.value)
    }
    if (e.target.value == providedToWorker) {
      setIsProvidedToWorkerOther(!isProvidedToWorkerOther)
      setProvidedToWorkerInput('')
      clearErrors('provided_to_worker_other')
    }

    if (e.target.value == itemProvidedDesc) {
      setIsItemsProvidedDescription(!isItemsProvidedDescription)
      setItemProvidedDescriptionInput('')
      clearErrors('items_provided_description')
    }
  }
  //handle replace strings
  const handleReplaceString = (original, toReplace, replacement) => {
    return original.includes(toReplace + ',')
      ? original.replace(toReplace + ',', replacement?.length > 0 ? replacement + ',' : replacement)
      : original.replace(toReplace, replacement)
  }
  //handle change PIC emails for remiders
  const handleReminderEmails = (oldPIC, newPIC) => {
    const hasOldPIC = oldPIC?.length > 0 ?? false

    if (isOneDayBeforeRecipients && !oneDayBeforeRecipients && oneDayBeforeRecipients.length <= 0) {
      setOneDayBeforeRecipients(newPIC)
    } else if (hasOldPIC && oneDayBeforeRecipients.includes(oldPIC)) {
      setOneDayBeforeRecipients(handleReplaceString(oneDayBeforeRecipients, oldPIC, newPIC))
    }

    if (isDepartureRecipients && (!departureRecipients || departureRecipients.length <= 0)) {
      setDepartureRecipients(newPIC)
    } else if (hasOldPIC && departureRecipients.includes(oldPIC)) {
      setDepartureRecipients(handleReplaceString(departureRecipients, oldPIC, newPIC))
    }

    if (isArrivalRecipients && (!arrivalRecipients || arrivalRecipients.length <= 0)) {
      setArrivalRecipients(newPIC)
    } else if (hasOldPIC && arrivalRecipients.includes(oldPIC)) {
      setArrivalRecipients(handleReplaceString(arrivalRecipients, oldPIC, newPIC))
    }

    if (!deafultFormData?.clock_in_recipients && (!clockInRecipients || clockInRecipients.length <= 0)) {
      setClockInRecipients(newPIC)
    } else if (hasOldPIC && clockInRecipients.includes(oldPIC)) {
      setClockInRecipients(handleReplaceString(clockInRecipients, oldPIC, newPIC))
    }

    if (!deafultFormData?.clock_out_recipients && (!clockOutRecipients || clockOutRecipients.length <= 0)) {
      setClockOutRecipients(newPIC)
    } else if (hasOldPIC && clockOutRecipients.includes(oldPIC)) {
      setClockOutRecipients(handleReplaceString(clockOutRecipients, oldPIC, newPIC))
    }
  }

  const handleCheckboxChange = (e) => {
    switch (e.target.name) {
      case 'invoice_generated':
        setIsCancelationFee(e.target.checked)
        break

      case 'is_previous_contact_enabled':
        setIsOneDayBeforeRecipients(e.target.checked)
        if (!e.target.checked) {
          setOneDayBeforeRecipients('')
          setValue('one_day_before_recipients', '')
          clearErrors('one_day_before_recipients')
        } else {
          if (!oneDayBeforeRecipients || oneDayBeforeRecipients?.length <= 0) {
            setOneDayBeforeRecipients(picEmail)
          }
        }
        break

      case 'is_departure_contact_enabled':
        setIsDepartureRecipients(e.target.checked)
        if (!e.target.checked) {
          setDepartureRecipients('')
          setValue('departure_recipients', '')
          clearErrors('departure_recipients')
        } else {
          if (!departureRecipients || departureRecipients?.length <= 0) {
            setDepartureRecipients(picEmail)
          }
        }
        break

      case 'is_arrival_contact_enabled':
        setIsArrivalRecipients(e.target.checked)
        if (!e.target.checked) {
          setArrivalRecipients('')
          setValue('arrival_recipients', '')
          clearErrors('arrival_recipients')
        } else {
          if (!arrivalRecipients || arrivalRecipients?.length <= 0) {
            setArrivalRecipients(picEmail)
          }
        }
        break
    }
  }

  //handle change autocomplete dropdown
  const handleAutoCompleteChange = (data, name) => {
    if (name == 'project_id') {
      if (data?.id) dispatch(getDropdownData('job_groups', data?.id))
      setActiveProject(data?.id)
      setJobGroupDisabled(data?.id ? false : true)
      setActiveJobGroup(null)
      setActiveWorker(null)
      setActiveProject(data?.id)
      setWorkerDisabled(true)
      setFormData((prevState) => ({
        ...prevState,
        job_group: null,
        worker: null,
      }))
      setValue('job_group', null)
      setValue('worker', null)
    }

    if (name == 'job_group') {
      let selectedJobGroup = data?.id ? jobGroupList.find(({ id }) => id === data.id) : null
      if (data?.id) dispatch(getDropdownData('workers', data?.id))
      setActiveJobGroup(data?.id)
      setActiveWorker(null)
      setWorkerDisabled(data?.id ? false : true)
      setFormData((prevState) => ({
        ...prevState,
        worker: null,
      }))
      setValue('worker', null)
      setJobGroupDates({
        start_date: selectedJobGroup?.start_date,
        end_date: selectedJobGroup?.end_date,
      })
    }

    if (name == 'worker') {
      setActiveWorker(data?.id)
    }

    if (name == 'client_user_id') {
      setActiveClientUser(data?.id)
      let selectedUserClient = data?.id ? clientUserList.find(({ id }) => id === data.id) : null
      const newPIC = selectedUserClient?.user?.email_address ?? ''
      handleReminderEmails(picEmail, newPIC)
      setPicEmail(newPIC)
    }
  }
  //check custom validation before form submit
  const triggerConfirm = (e) => {
    if (errors?.job_dates?.length > 0) {
      e.preventDefault()
    } else {
      dispatch(setFormErrors(null))
    }
  }
  // handle Submit
  const handleSubmitJobForm = (data) => {
    setIsSubmit(true)
    const selectedWorker = activeWorker ? workerList.find(({ id }) => id === parseInt(activeWorker)) : null
    data['application_id'] = !formData?.application_id ? urlParams.get('application') : selectedWorker?.application_id
    data['provided_documents'] = formatWorkerDocuments(
      'goods_supplied_chk',
      jobCheckboxOptions,
      selectedCheckboxOptions
    )
    data['provided_items'] = formatWorkerDocuments(
      'goods_sent_to_worker_chk',
      jobCheckboxOptions,
      selectedCheckboxOptions
    )
    data['worker_tools'] = formatWorkerDocuments('goods_worker_bring_chk', jobCheckboxOptions, selectedCheckboxOptions)
    data['project_name'] = data?.project_id?.original_name
    data['job_group_name'] = data?.job_group?.original_name
    data['worker_name'] = data?.worker?.original_name
    data['pic_name'] = data?.client_user_id?.original_name
    data['start_time'] = formatTimeString(data?.start_time)
    data['end_time'] = formatTimeString(data?.end_time)
    data['working_hours'] = data?.start_time + ' ~ ' + data?.end_time
    data['salary_format'] = formatAmount(data?.salary)
    data['job_dates'] = data?.job_dates?.filter((el) => el != null)
    let newData = {
      ...data,
      mode: localStorage.getItem('mode'),
      client_id: urlParams.get('client'),
      client_user_id: data?.client_user_id?.id,
      project_id: data?.project_id?.id,
      job_group_id: data?.job_group?.id,
      worker_id: data?.worker?.id,
    }
    dispatch(setConfirming(true))
    dispatch(setFormMode(isUpdate))
    const newFormData = {}
    newFormData['current'] = newData
    newFormData['formatted'] = data
    dispatch(setJobFormData(newFormData))
    history.push('/offer/confirmation')
  }

  // onClick get address by postal code
  const handleGetAddress = async () => {
    const validPostal = await trigger('zip_code')
    let zip_code = getValues('zip_code')
    if (validPostal) {
      setRequestAddress(true)
      await getAddress(zip_code).then((response) => {
        setRequestAddress(false)
        if (response.data.results == null) {
          setError('zip_code', { type: 'custom', message: rulesMsg.postal_invalid })
        } else {
          clearErrors('zip_code')
          clearErrors('prefecture')
          clearErrors('municipality')
          setPrefecture(response.data.results[0]?.address1)
          setMunicipality(response.data.results[0]?.address2)
          setAddressLine1(response.data.results[0]?.address3)
        }
      })
    }
  }
  //onChange postal input value
  const handleChangePostal = () => {
    if (errors.zip_code) {
      trigger('zip_code')
    }
    clearErrors('prefecture')
    clearErrors('municipality')
    setPrefecture('')
    setMunicipality('')
  }
  //onChange inputs reset errors
  const handleInputChange = (event, name) => {
    const value = event.target.value
    const hasValue = value.length > 0
    switch (name) {
      case 'one_day_before_recipients':
        hasValue && setIsOneDayBeforeRecipients(true)
        setOneDayBeforeRecipients(value)
        break
      case 'departure_recipients':
        hasValue && setIsDepartureRecipients(true)
        setDepartureRecipients(value)
        break
      case 'arrival_recipients':
        hasValue && setIsArrivalRecipients(true)
        setArrivalRecipients(value)
        break
      case 'clock_in_recipients':
        setClockInRecipients(value)
        break
      case 'clock_out_recipients':
        setClockOutRecipients(value)
        break
      case 'provided_to_worker_other':
        setProvidedToWorkerInput(value)
        break
      case 'items_provided_description':
        setItemProvidedDescriptionInput(value)
        break
    }
    if (errors[name]) {
      trigger(name)
    }
  }

  //form validation
  const validationRules = {
    project_id: {
      required: {
        value: Object,
        message: rulesMsg.required_select('案件を'),
      },
    },
    job_group: {
      required: {
        value: Object,
        message: rulesMsg.required_select('作業グループを'),
      },
    },
    worker: {
      required: {
        value: Object,
        message: rulesMsg.required_select('応募者表示名'),
      },
    },
    client_user_id: {
      required: {
        value: Object,
        message: rulesMsg.required_select('作業担当者を'),
      },
    },
    name: {
      required: {
        value: String,
        message: rulesMsg.required('作業名を'),
      },
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    job_dates_count: {
      validate: (value) => {
        if (value <= 0) {
          return rulesMsg.required('作業日を')
        } else if (value > 31) {
          return rulesMsg.date_range()
        } else {
          return true
        }
      },
    },
    job_dates: {
      validate: (value) => {
        let allValues = getValues('job_dates')
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        let notUnique = false
        if (isValid) {
          const filterValues = allValues.filter((item) => item === value)
          notUnique = filterValues?.length > 1 ? true : false
        }
        if (!isValid) {
          return isValid || rulesMsg.invalid_date()
        } else if (dayjs(value).isBefore(dayjs(thisDay))) {
          return rulesMsg.after_today()
        } else if (isValid && notUnique) {
          return rulesMsg.offer_unique_dates
        } else {
          return true
        }
      },
    },
    start_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        if (!isValid) {
          return rulesMsg.invalid_date()
        } else if (!isUpdate && dayjs(value).isBefore(dayjs(thisDay))) {
          return rulesMsg.after_today()
        } else {
          return true
        }
      },
    },
    start_time: {
      required: {
        value: String,
        message: rulesMsg.required('作業開始時刻を'),
      },
      pattern: {
        value: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
        message: rulesMsg.time_format(),
      },
    },
    end_time: {
      required: {
        value: String,
        message: rulesMsg.required('作業終了時刻を'),
      },
      pattern: {
        value: /^([0-1]?[0-9]|(2[0-9]|3[0-9]|4[0-7]))[:]?([0-5]\d)$/i,
        message: rulesMsg.time_format(),
      },
      validate: (value) => {
        const jobDate = getValues('date') || thisDay
        const starTime = dayjs(jobDate + getValues('start_time')).format(dateFormat + ' ' + timeFormat)
        const endTime = dayjs(jobDate + value).format(dateFormat + ' ' + timeFormat)
        const endTimeLimit = dayjs(starTime)
          .add(1, 'day')
          .format(dateFormat + ' ' + timeFormat)
        if (dayjs(endTime).isBefore(dayjs(starTime)) || dayjs(endTime).isSame(dayjs(starTime))) {
          return rulesMsg.job_time
        } else if (dayjs(endTime).isAfter(dayjs(endTimeLimit))) {
          return rulesMsg.is_24hours
        } else {
          return
        }
      },
    },
    break_time: {
      required: {
        value: String,
        message: rulesMsg.required('休憩時間を'),
      },
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    zip_code: {
      required: {
        value: String,
        message: rulesMsg.required('郵便番号を'),
      },
      validate: (value) => {
        if (value.length !== 7) {
          return rulesMsg.min_numbers(7)
        } else if (!/^[0-9]*$/.test(value)) {
          return rulesMsg.min_numbers(7)
        } else {
          return true
        }
      },
    },
    prefecture: {
      required: {
        value: String,
        message: rulesMsg.required('作業場所住所を'),
      },
    },
    municipality: {
      required: {
        value: String,
        message: rulesMsg.required('作業場所住所を'),
      },
    },
    address_line_1: {
      required: {
        value: String,
        message: rulesMsg.required('町名番地を'),
      },
      validate: (value) => {
        return value.length <= 255 || rulesMsg.max(255)
      },
    },
    address_line_2: {
      validate: (value) => {
        return value.length <= 255 || rulesMsg.max(255)
      },
    },
    description: {
      required: {
        value: String,
        message: rulesMsg.required('作業内容を'),
      },
      validate: (value) => {
        const regex = /(<([^>]+)>)/gi
        return value.replaceAll(regex, '').length <= 1000 || rulesMsg.max(1000)
      },
    },
    salary: {
      required: {
        value: String,
        message: rulesMsg.required('作業費(税抜き)を'),
      },
      validate: (value) => {
        const intMinValue = value.toString().trim().includes(',') ? value.replaceAll(',', '') : value
        return (
          (parseFloat(intMinValue) >= 1 && parseFloat(intMinValue) <= 1000000) || rulesMsg.between(['1', '1,000,000'])
        )
      },
      pattern: {
        value: /^[0-9]{1,3}(,?[0-9]{3})*$/,
        message: rulesMsg.between(['1', '1,000,000']),
      },
    },
    allowance: {
      required: {
        value: Number,
        message: rulesMsg.required('手当を'),
      },
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    toll_fee: {
      required: {
        value: Number,
        message: rulesMsg.required('交通費を'),
      },
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    highway_fee: {
      required: {
        value: Number,
        message: rulesMsg.required('高速代を'),
      },
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    parking_fee: {
      required: {
        value: Number,
        message: rulesMsg.required('駐車場代を'),
      },
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    overnight_stay_fee: {
      required: {
        value: Number,
        message: rulesMsg.required('宿泊費を'),
      },
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    other_fee: {
      required: {
        value: Number,
        message: rulesMsg.required('その他経費を'),
      },
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    provided_to_worker_other: {
      validate: {
        required: (value) => {
          if (!value && isProvidedToWorkerOther) return rulesMsg.required('その他の事前支給品を')
          return true
        },
        max: (value) => {
          return value.length <= 100 || rulesMsg.max(100)
        },
      },
    },
    items_provided_description: {
      validate: {
        required: (value) => {
          if (!value && isItemsProvidedDescription) return rulesMsg.required('作業に使用する部材を')
          return true
        },
        max: (value) => {
          return value.length <= 100 || rulesMsg.max(100)
        },
      },
    },
    worker_tool_notes: {
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    attire: {
      required: {
        value: String,
        message: rulesMsg.required('服装を'),
      },
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    escalation_contact: {
      required: {
        value: String,
        message: rulesMsg.required('エスカレーション先を'),
      },
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    invoice_generated: {
      required: {
        value: Boolean,
        message: rulesMsg.cancellation_required,
      },
    },
    onsite_client_contact: {
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    onsite_name: {
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    fellow: {
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    remarks: {
      validate: (value) => {
        return value.length <= 100 || rulesMsg.max(100)
      },
    },
    one_day_before_recipients: {
      validate: {
        required: (value) => {
          if (!value && getValues('is_previous_contact_enabled')) return rulesMsg.required(' 前日連絡先を')
          return true
        },
        max: (value) => {
          return value.length <= 255 || rulesMsg.max(255)
        },
      },
      pattern: {
        value: emailRegex,
        message: rulesMsg.invalid_email(),
      },
    },
    departure_recipients: {
      validate: {
        required: (value) => {
          if (!value && getValues('is_departure_contact_enabled')) return rulesMsg.required('出発連絡先を')
          return true
        },
        max: (value) => {
          return value.length <= 255 || rulesMsg.max(255)
        },
      },
      pattern: {
        value: emailRegex,
        message: rulesMsg.invalid_email(),
      },
    },
    arrival_recipients: {
      validate: {
        required: (value) => {
          if (!value && getValues('is_arrival_contact_enabled')) return rulesMsg.required('現着連絡先を')
          return true
        },
        max: (value) => {
          return value.length <= 255 || rulesMsg.max(255)
        },
      },
      pattern: {
        value: emailRegex,
        message: rulesMsg.invalid_email(),
      },
    },
    clock_in_recipients: {
      required: {
        value: String,
        message: rulesMsg.required('入館連絡先を'),
      },
      validate: (value) => {
        return value.length <= 255 || rulesMsg.max(255)
      },
      pattern: {
        value: emailRegex,
        message: rulesMsg.invalid_email(),
      },
    },
    clock_out_recipients: {
      required: {
        value: String,
        message: rulesMsg.required('退館連絡先を'),
      },
      validate: (value) => {
        return value.length <= 255 || rulesMsg.max(255)
      },
      pattern: {
        value: emailRegex,
        message: rulesMsg.invalid_email(),
      },
    },
    offer_comment: {
      required: {
        value: String,
        message: rulesMsg.required('オファーコメントを'),
      },
      validate: (value) => {
        return value.length <= 1000 || rulesMsg.max(1000)
      },
    },
  }

  let defaultInputProps = {
    required: true,
  }

  const getAutoCompleteField = (label, name, placeholder, options, disabled, noPad) => {
    let defaultValue = formData ? formData?.[name] ?? null : null
    return (
      <InputAutoComplete
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        options={options}
        defaultinput={defaultValue}
        itemSelect={(data) => handleAutoCompleteChange(data, name)}
        {...defaultInputProps}
        disabled={disabled}
        noPad={noPad}
      />
    )
  }

  const getInputLabelField = (label, name, placeholder, requiredField, disabled) => {
    let inputValue = formData ? formData[name] : null
    if (name === 'prefecture') {
      inputValue = prefecture
    }
    if (name === 'municipality') {
      inputValue = municipality
    }
    if (name === 'address_line_1') {
      inputValue = addressLine1
    }

    if (name === 'one_day_before_recipients') {
      inputValue = oneDayBeforeRecipients
    }

    if (name === 'departure_recipients') {
      inputValue = departureRecipients
    }

    if (name === 'arrival_recipients') {
      inputValue = arrivalRecipients
    }

    if (name === 'clock_in_recipients') {
      inputValue = clockInRecipients
    }

    if (name === 'clock_out_recipients') {
      inputValue = clockOutRecipients
    }

    if (name === 'provided_to_worker_other') {
      inputValue = providedToWorkerInput
    }

    if (name === 'items_provided_description') {
      inputValue = providedDescriptionInput
    }

    if (name === 'attire') {
      inputValue = inputValue || 'スーツ'
    }

    return (
      <InputLabel
        id={name + '-ID'}
        className={name === 'salary' || isReminders(name) ? 'NoLabel NoPad' : ''}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        isDisabled={disabled || false}
        {...(inputValue && { value: inputValue })}
        {...defaultInputProps}
        required={requiredField}
        onChange={name == 'zip_code' ? () => handleChangePostal() : (e) => handleInputChange(e, name)}
      />
    )
  }

  const getInputTextAreaField = (label, subLabel, name, placeholder, height, required) => {
    let inputValue = formData ? formData[name] : null
    return (
      <InputTextArea
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        subLabel={subLabel}
        {...(inputValue && { value: inputValue })}
        height={height}
        required={required}
      />
    )
  }

  const getInputRichTextEditor = (label, name, placeholder, height) => {
    defaultInputProps['sideLabel'] = false
    return (
      <InputRichTextEditor
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        defaultValue={formData ? formData[name] : null}
        label={label}
        height={height}
        required
      />
    )
  }

  const getInputMultiCheckbox = (label, name, items, subLabel, requiredField) => {
    return (
      <InputMultiCheckbox
        ref={register(name + '[]')}
        name={name + '[]'}
        label={label}
        subLabel={subLabel}
        items={items}
        onChange={handleClick}
        required={requiredField}
        selected={selectedCheckboxOptions}
      />
    )
  }

  const getInputCheckbox = (label, name, defaultChecked, defaultDisabled, requiredField) => {
    return (
      <Checkbox
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? 1 : 0}
        errorMsg={errors ? errors[name]?.message : null}
        name={name}
        label={label}
        required={requiredField}
        disabled={defaultDisabled}
        onChange={handleCheckboxChange}
        checked={defaultChecked}
      />
    )
  }
  const detailsPlaceHolder =
    '例:\n' +
    'こちらは仙台支店での作業内容です。\n' +
    '同日同時刻の盛岡営業所、郡山営業所の作業内容はそれぞれの記載を確認してください。\n' +
    '・現行ルータ撤去および新規ルータ設置、試験(機器のログ取得) \n' +
    '・翌営業日立ち会い、トラブルの場合は旧ルータへの切り戻し対応 \n' +
    '・成果物の作成（簡単なラック図、フロア図）\n' +
    'ログ取得作業においてteratermを使用します。\n' +
    'teratermでの作業実績がある方希望。\n' +
    '翌営業日立ち会いはお客様先付近での待機、\n' +
    'お客様より対応依頼あり次第の入館となります。'

  return (
    <StyledOfferCreateUpdateForm item container xs={12}>
      <Grid container pb={6}>
        <Heading>オファーフォーム</Heading>
      </Grid>
      <form onSubmit={handleSubmit(handleSubmitJobForm)} className="form">
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {getAutoCompleteField('案件', 'project_id', '選択ください', projectList, false, false)}
            {getAutoCompleteField('作業グループ', 'job_group', '作業グループ', jobGroupList, jobGroupDisabled, false)}
            {getAutoCompleteField('応募者表示名', 'worker', '応募者表示名', workerList, workerDisabled, false)}
            {getInputLabelField('作業名', 'name', '例: 無線LAN設定', true)}
            {getAutoCompleteField('作業担当者', 'client_user_id', '選択ください', clientUserList, false, false)}
          </Grid>
        </Grid>
        <Grid item container xs={12} pb={4}>
          <OfferDateRange
            range={jobGroupDates}
            ref={{ register, errors, clearErrors, setError, setValue, validationRules, trigger, unregister }}
            label="作業日"
            defaultDates={formData?.job_dates || []}
            required
          />
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} md={6}>
            {getInputLabelField('作業開始時刻', 'start_time', '例: 9:00', true)}
          </Grid>
          <Grid item xs={12} md={6}>
            {getInputLabelField('作業終了時刻', 'end_time', '例: 28:00', true)}
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} md={6}>
            {getInputLabelField('休憩時間', 'break_time', '例: 12：00～13：00', true)}
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }} alignItems="start">
          <Grid item xs={12} md={6}>
            {getInputLabelField('作業場所住所', 'zip_code', '例: 9820011', true)}
          </Grid>
          <Grid item container xs={12} md={6} alignItems={'center'} mt={{ xs: 0, md: 3.5 }} pb={{ xs: 3.6, md: 0 }}>
            <LargeButton
              variant="outlined"
              color="primary"
              mode="light"
              onClick={handleGetAddress}
              id="getAddressBtn-ID"
            >
              {requestAddress && <CircularProgress size="1.5rem" color="inherit" />}
              {!requestAddress && '郵便番号から住所を検索'}
            </LargeButton>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }} className="AddressWrapper">
          <Grid item xs={12} md={6}>
            {getInputLabelField(null, 'prefecture', '都道府県', true, true)}
          </Grid>
          <Grid item xs={12} md={6}>
            {getInputLabelField(null, 'municipality', '市町村区', true, true)}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className="AddressWrapper">
            {getInputLabelField(null, 'address_line_1', '例: 長町６−１３−２', true)}
          </Grid>
          <Grid item xs={12} className="AddressWrapper">
            {getInputLabelField(null, 'address_line_2', '例: フリシャー仙台ビル１０４')}
          </Grid>
          <Grid item xs={12}>
            {getInputRichTextEditor('作業内容', 'description', detailsPlaceHolder, 265)}
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} md={6} pb={1}>
            <Label fontSize={15} color="black" className="LabelInfoText">
              作業費(税抜き) <span>*</span>
            </Label>
          </Grid>
        </Grid>
        <Grid container columnSpacing={{ xs: 0, md: 4 }}>
          <Grid item container xs={12} md={6} alignItems="start" pb={4}>
            <Grid item xs={0.8} md={0.4} mt={1.5}>
              <Label>¥ &nbsp;</Label>
            </Grid>
            <Grid item xs={11.2} md={11.6}>
              {getInputLabelField(null, 'salary', '例: 15,000', true)}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {getInputLabelField(
              '手当',
              'allowance',
              '例: 現地拘束8時間以上、30分あたり¥1,000（税別）、リーダー手当など',
              true
            )}
            {getInputLabelField('交通費', 'toll_fee', '例: 往復60km以上1kmあたり¥20（税込み）', true)}
            {getInputLabelField('高速代', 'highway_fee', '例: 領収書に基づいて実費精算（税込み）', true)}
            {getInputLabelField('駐車場代', 'parking_fee', '例: 領収書に基づいて実費精算（税込み）', true)}
            {getInputLabelField('宿泊費', 'overnight_stay_fee', '例: 領収書に基づいて実費精算（税込み）', true)}
            {getInputLabelField('その他経費', 'other_fee', '例: 領収書に基づいて実費精算（税込み）', true)}
          </Grid>
        </Grid>
        <Grid container>
          {jobCheckboxOptions &&
            jobCheckboxOptions.map((value, index) => (
              <Grid item xs={12} key={index}>
                <Grid item xs={12} key={index} className="JobCheckbox-Wrapper">
                  {getInputMultiCheckbox(
                    value?.name,
                    value.type,
                    value?.options,
                    '',
                    value?.type === 'goods_sent_to_worker_chk' ? false : true
                  )}
                </Grid>
                {value?.type === 'goods_supplied_chk' ? (
                  <Grid pt={0} item xs={12}>
                    {getInputLabelField(
                      null,
                      'provided_to_worker_other',
                      'その他の事前支給品がある場合はここに入力してください',
                      isProvidedToWorkerOther,
                      !isProvidedToWorkerOther
                    )}
                  </Grid>
                ) : (
                  ''
                )}
                {value?.type === 'goods_sent_to_worker_chk' ? (
                  <Grid pt={0} item xs={12}>
                    {getInputLabelField(
                      null,
                      'items_provided_description',
                      '作業に使用する部材がある場合はここに入力してください',
                      isItemsProvidedDescription,
                      !isItemsProvidedDescription
                    )}
                  </Grid>
                ) : (
                  ''
                )}
                {value?.type === 'goods_worker_bring_chk' ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {getInputTextAreaField('', '', 'worker_tool_notes', '備考', 126)}
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            ))}
        </Grid>
        <Grid container columnSpacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} className="BankAccount-Wrapper">
            {getInputLabelField('服装', 'attire', 'スーツ', true)}
            <Caption>例: 上下スーツ、作業着、スニーカー可、クールビズ可、マスク着用</Caption>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className="Destination-Wrapper">
            {getInputLabelField(
              'エスカレーション先 ',
              'escalation_contact',
              '例: ○○株式会社の担当●●、電話番号は090-1111-1111',
              true
            )}
          </Grid>
          <Grid item xs={12} className="Cancellation-Wrapper">
            <Label fontSize={15} color="black" className="LabelInfoText">
              キャンセル費 <span>*</span>
            </Label>
            <LabelDisplay md={-17} type="info" className="LabelInfo">
              {'・1週間前キャンセル、作業費20%支払い'} <br />
              {'・1〜3日前キャンセル、作業費50%支払い'} <br />
              {'・当日キャンセル、作業費100%支払い'}
            </LabelDisplay>
            {getInputCheckbox(
              '上記キャンセル費を承諾する',
              'invoice_generated',
              formData ? Boolean(formData?.invoice_generated) : false,
              false,
              true
            )}
            {!isCancelationFee && errors?.invoice_generated && (
              <FormHelperText className="Cancellation-Note">
                上記キャンセル費を承諾する、にチェックを入れてください。
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} className="Destination-Wrapper">
            {getInputLabelField(
              'お客様現地ご担当者様連絡先',
              'onsite_client_contact',
              '例: ○○株式会社の担当●●、電話番号は090-1111-1111',
              false
            )}
            {getInputLabelField('名乗り', 'onsite_name', '例: 「○○株式会社の●●です」', false)}
            {getInputLabelField('当日同行者', 'fellow', '例: 弊社より作業リーダーが同行します', false)}
            {getInputTextAreaField(
              'その他連絡事項',
              '',
              'remarks',
              '例: 作業1週間前からの検温結果、事前に作成する身分証明書'
            )}
            <Label>各種連絡</Label>
            <Caption>
              必要な連絡にチェックを入れ、 <br />
              担当者以外に追加で必要な宛先があれば <br />
              それぞれ記入してください <br />
              ＊本作業の担当者には自動で連絡がいきます。
            </Caption>
            <Grid container pt={2} spacing={{ xs: 0, md: 4 }}>
              <Grid item xs={6} md={3}>
                {getInputCheckbox('前日連絡', 'is_previous_contact_enabled', isOneDayBeforeRecipients, false)}
              </Grid>
              <Grid item xs={12} md={9}>
                {getInputLabelField(
                  null,
                  'one_day_before_recipients',
                  '例: tanaka@example.com, yamada@example.com',
                  isOneDayBeforeRecipients
                )}
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, md: 4 }} pt={1}>
              <Grid item xs={6} md={3}>
                {getInputCheckbox('出発連絡', 'is_departure_contact_enabled', isDepartureRecipients, false)}
              </Grid>
              <Grid item xs={12} md={9}>
                {getInputLabelField(
                  null,
                  'departure_recipients',
                  '例: tanaka@example.com, yamada@example.com',
                  isDepartureRecipients
                )}
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, md: 4 }} pt={1}>
              <Grid item xs={6} md={3}>
                {getInputCheckbox('現着連絡', 'is_arrival_contact_enabled', isArrivalRecipients, false)}
              </Grid>
              <Grid item xs={12} md={9}>
                {getInputLabelField(
                  null,
                  'arrival_recipients',
                  '例: tanaka@example.com, yamada@example.com',
                  isArrivalRecipients
                )}
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, md: 4 }} pt={1}>
              <Grid item xs={6} md={3}>
                {getInputCheckbox('入館連絡', 'is_clockin_contact_enabled', true, true)}
              </Grid>
              <Grid item xs={12} md={9}>
                {getInputLabelField(null, 'clock_in_recipients', '例: tanaka@example.com, yamada@example.com', true)}
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, md: 4 }} pt={1}>
              <Grid item xs={6} md={3}>
                {getInputCheckbox('退館連絡', 'is_clockout_contact_enabled', true, true)}
              </Grid>
              <Grid item xs={12} md={9}>
                {getInputLabelField(null, 'clock_out_recipients', '例: tanaka@example.com, yamada@example.com', true)}
              </Grid>
            </Grid>
            <Grid item xs={12} pt={2}>
              {getInputTextAreaField(
                'オファーコメント',
                '',
                'offer_comment',
                '例: 作業者を至急募集しています。〇〇月〇〇日までに回答いただけると助かります。',
                null,
                true
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container pt={4} justifyContent="end">
          <Grid item xs={12} sm={4} md={3} lg={2} textAlign="end">
            <LargeButton
              id="WorkerSubmitBtn"
              type="submit"
              onClick={(e) => {
                triggerConfirm(e)
              }}
            >
              {isSubmit && <CircularProgress size="1.5rem" color="white" />}
              {!isSubmit && '確認'}
            </LargeButton>
          </Grid>
        </Grid>
      </form>
    </StyledOfferCreateUpdateForm>
  )
}

OfferCreateUpdateForm.displayName = 'Offer Form'

OfferCreateUpdateForm.defaultProps = {
  isFromJobList: false,
}

OfferCreateUpdateForm.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
  isFromJobList: PropTypes.bool,
}

export default OfferCreateUpdateForm
