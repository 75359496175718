import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip as MUITooltip, Fade, ClickAwayListener } from '@mui/material'
import palette from 'theme/palette'
import { styled } from '@mui/material/styles'

const StyleToolTipTitle = styled('span')(() => {
  return {
    whiteSpace: 'pre-line',
  }
})

function Tooltip(props) {
  const { title, width, placement, backgroundcolor, children } = props
  const [open, setOpen] = useState(false)

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <MUITooltip
        open={open}
        disableFocusListener
        title={<StyleToolTipTitle>{title}</StyleToolTipTitle>}
        arrow
        placement={placement}
        TransitionComponent={Fade}
        onClick={() => setOpen(true)}
        cursor={'pointer'}
        componentsProps={{
          tooltip: {
            sx: {
              margin: { xs: '0px 7px', md: '0px 8px' },
              bgcolor: backgroundcolor,
              maxWidth: width,
              '& .MuiTooltip-arrow': {
                color: backgroundcolor,
              },
            },
          },
        }}
      >
        {children}
      </MUITooltip>
    </ClickAwayListener>
  )
}

Tooltip.displayName = 'Tooltip'

Tooltip.defaultProps = {
  width: 250,
  placement: 'top',
  backgroundcolor: palette.text.primary,
}

Tooltip.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
  placement: PropTypes.string,
  backgroundcolor: PropTypes.string,
  children: PropTypes.node,
}

palette.text.primary
export default Tooltip
