import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Label, TableList, LargeButton, Modal, ReportsModalContent } from 'components'
import { Grid } from '@mui/material'
import { approveExpenseReport, rejectExpenseReport, readJob, downloadExpenseReport } from 'services/jobs'
import { downloadFile } from 'utils/common'
import { status } from 'utils/config/status'

const ExpenseReportTab = (props) => {
  const dispatch = useDispatch()
  const { data } = props
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const [modalState, setModalState] = useState({ modalTitle: '', modalBodyMsg: '' })
  const [isRequesting, setIsRequesting] = useState(false)
  const reportExpenses = data?.expenses.map((item) => {
    return {
      ...item,
      updated_at: item?.updated_date + ' ' + item?.updated_time,
    }
  })

  const headerCells = [
    { id: 'item', label: '品目' },
    { id: 'expense_category_name', label: '項目' },
    { id: 'price_formatted', label: '金額' },
    { id: 'file_name', label: 'ファイル名' },
    { id: 'updated_at', label: 'アップロード日時' },
  ]

  const handleDownload = (e, rowData) => {
    downloadExpenseReport(data.id, rowData?.id).then((response) => {
      downloadFile(rowData?.file_name, response.data)
    })
  }

  //approve reports
  const handleApprove = () => {
    setModalType('approve')
    setIsOpenModal(true)
    setConfirmed(false)
  }
  const handleApproveConfirm = () => {
    setIsRequesting(true)
    approveExpenseReport(data.id).then((response) => {
      if (response.data.code == 200) {
        setIsRequesting(false)
        setConfirmed(true)
      }
    })
  }

  //reject reports
  const handleReject = () => {
    setModalType('reject')
    setIsOpenModal(true)
    setConfirmed(false)
  }
  const handleRejectConfirm = (formData) => {
    let newFormData = {
      job_id: data.id,
      comment: formData?.comment,
    }
    setIsRequesting(true)
    rejectExpenseReport(newFormData).then((response) => {
      if (response.data.code == 200) {
        setIsRequesting(false)
        setConfirmed(true)
      }
    })
  }

  //close modal
  const handleModalClose = () => {
    if (confirmed) {
      dispatch(readJob(data.id, false))
    }
    setIsOpenModal(false)
  }

  useEffect(() => {
    const approveModalTitle = modalType === 'approve' ? (!confirmed ? '経費報告承認' : '経費報告承認') : ''
    const rejectModalTitle = modalType === 'reject' ? (!confirmed ? '経費報告差し戻し' : '経費報告差し戻し') : ''
    const approveModalBodyMsg =
      modalType === 'approve' ? (!confirmed ? '以下作業の経費報告を承認しますか？' : '経費報告を承認しました。') : ''
    const rejectModalBodyMsg =
      modalType === 'reject' ? (!confirmed ? '以下作業の経費報告を差し戻しますか？' : '経費報告を差し戻しました。') : ''
    setModalState({
      ...modalState,
      modalTitle: modalType === 'approve' ? approveModalTitle : rejectModalTitle,
      modalBodyMsg: modalType === 'approve' ? approveModalBodyMsg : rejectModalBodyMsg,
    })
  }, [isOpenModal, confirmed, modalType])

  return (
    <Grid container p={4}>
      <Label bold="true" pb={1}>
        経費報告
      </Label>
      <Grid item xs={12}>
        <TableList
          title={`Job Report List`}
          headCells={headerCells}
          data={reportExpenses}
          handleDownload={handleDownload}
          noSort
          noPaginate
          isReportsActions={{ download: true }}
          no_data_msg="まだ経費報告がありません"
        />
      </Grid>
      <Grid item container justifyContent={{ xs: 'center', md: 'end' }} gap={3} pt={2} xs={12}>
        <LargeButton
          id="RejectReportsBtn"
          color="gray"
          mode="btn1"
          onClick={handleReject}
          disabled={
            isRequesting ||
            data?.expense_status_id !== status.job.wait_expense_report_approve ||
            data?.expense_status_id == status.expense.accepted ||
            data?.expense_status_id == status.expense.declined
              ? true
              : false
          }
        >
          差し戻し
        </LargeButton>
        <LargeButton
          id="ApproveReportsBtn"
          onClick={() => {
            handleApprove()
          }}
          disabled={
            isRequesting ||
            data?.expense_status_id !== status.job.wait_expense_report_approve ||
            data?.expense_status_id == status.expense.accepted
              ? true
              : false
          }
        >
          承認
        </LargeButton>
      </Grid>
      <Modal open={isOpenModal} title={modalState?.modalTitle} lg={4} xl={3} onClose={handleModalClose}>
        <ReportsModalContent
          data={data}
          confirmed={confirmed}
          modalType={modalType}
          modalState={modalState}
          handleApproveConfirm={() => handleApproveConfirm()}
          handleRejectConfirm={handleRejectConfirm}
          handleModalClose={handleModalClose}
          isRequesting={isRequesting}
        />
      </Modal>
    </Grid>
  )
}

ExpenseReportTab.propTypes = {
  data: PropTypes.object,
}

export default ExpenseReportTab
