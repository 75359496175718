import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Label, Autocomplete, Tooltip } from 'components'
import { Help as HelpIcon } from '@mui/icons-material'
import palette from 'theme/palette'

const StyledInputAutoCompleteLabel = styled(Box)(({ noPad, theme }) => ({
  padding: noPad ? 0 : `${theme.standards.padding.input.default}`,
  '& .InputLabel': {
    paddingBottom: `${theme.standards.padding.label.inputLabel}`,
  },
  '& .RequiredText': {
    display: 'inline-flex',
  },
}))

const InputAutoCompleteLabel = React.forwardRef((props, ref) => {
  const excludedProps = [
    'label',
    'labelProps',
    'required',
    'sideLabel',
    'autocompleteProps',
    'itemselect',
    'noPad',
    'toolTip',
  ]
  let defaultProps = omit(props, excludedProps)
  const { label, labelProps, sideLabel, itemSelect, noPad, toolTip } = props

  const labelDisplay = (
    <Box display="flex">
      <Label {...labelProps} className="InputLabel" sx={{ paddingRight: 1 }}>
        {label}
        {props.required && (
          <Label color="error" className="RequiredText">
            *
          </Label>
        )}
      </Label>
      {toolTip && (
        <Tooltip title={toolTip} width={900}>
          <HelpIcon fontSize="small" sx={{ color: palette.text.primary }} />
        </Tooltip>
      )}
    </Box>
  )
  const topLabelDisplay = (
    <Box>
      {labelDisplay}
      <Autocomplete {...defaultProps} ref={ref} />
    </Box>
  )
  const sideLabelDisplay = (
    <Box alignItems="center">
      <Box display="inline-grid" width={{ xs: 1, sm: 5 / 12, md: 4 / 12 }}>
        {labelDisplay}
      </Box>
      <Box display="inline-grid" width={{ xs: 1, sm: 7 / 12, md: 8 / 12 }}>
        <Autocomplete {...defaultProps} ref={ref} itemSelect={itemSelect} />
      </Box>
    </Box>
  )

  return (
    <StyledInputAutoCompleteLabel noPad={noPad}>
      {sideLabel ? sideLabelDisplay : topLabelDisplay}
    </StyledInputAutoCompleteLabel>
  )
})

InputAutoCompleteLabel.displayName = 'Input AutoComplete'

InputAutoCompleteLabel.defaultProps = {
  color: 'secondary',
}

InputAutoCompleteLabel.propTypes = {
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
  sideLabel: PropTypes.bool,
  required: PropTypes.bool,
  itemSelect: PropTypes.func,
  noPad: PropTypes.bool,
  toolTip: PropTypes.string,
}

export default InputAutoCompleteLabel
