import palette from '../palette'
export default {
  styleOverrides: {
    option: {
      backgroundColor: palette.white,
      '&.Mui-focused': {
        backgroundColor: palette.background.gray,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: palette.white,
        '&.Mui-focused': {
          backgroundColor: palette.background.gray,
        },
      },
    },
  },
}
