import * as types from './actionTypes'
import { formulateCurrentPage, formulatePageResult } from '../../utils/helper'

const initialState = {
  list: [],
  workerList: [],
  isConfirming: false,
  isUpdate: false,
  formData: {
    current: null,
    formatted: null,
    original: null,
  },
  formErrors: {},
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 10,
    prevPageUrl: null,
    nextPageUrl: null,
    pageFrom: 0,
    pageTo: 0,
  },
  search: {
    name: '',
    screen_name: '',
    id: '',
    email_address: '',
    phone_number: '',
    member_type: '',
    limit: 20,
    page: 1,
    sort: 'desc',
    sortBy: 'registered_date',
  },
  pending: false,
  success: false,
  error: null,
  details: null,
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SEARCH_WORKERS: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )
      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }
    case types.SET_SEARCH_CRITERIA:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      }
    // worker details
    case types.GET_WORKER_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload.data,
        success: true,
      }
    case types.GET_WORKER_DETAILS_FAILED:
      return {
        ...state,
        details: null,
        success: false,
        error: payload.error,
      }
    case types.SET_WORKERS_FORM_DATA:
      return {
        ...state,
        formData: {
          current: {
            ...state.formData?.current,
            ...payload?.current,
          },
          original: {
            ...state.formData?.original,
            ...payload?.original,
          },
        },
      }
    case types.SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: {
          ...payload,
        },
      }
    case types.SET_CONFIRMING_DATA:
      return {
        ...state,
        isConfirming: payload,
      }
    case types.SET_FORM_MODE:
      return {
        ...state,
        isUpdate: payload,
      }
    case types.RESET_CURRENT_FORM:
      return {
        ...state,
        formData: {
          ...initialState.formData,
        },
        formErrors: {},
      }
    // save worker information
    case types.SAVE_WORKER_INFORMATION_SUCCESS:
      return {
        ...state,
        details: payload.data,
        success: true,
      }
    case types.SAVE_WORKER_INFORMATION_FAILED:
      return {
        ...state,
        details: null,
        success: false,
        error: payload.error,
      }
    //get worker job list
    case types.GET_WORKER_JOB_LIST_REQUEST:
      return {
        ...state,
        workerList: [],
        pending: true,
        success: false,
      }
    case types.GET_WORKER_JOB_LIST_SUCCESS:
      return {
        ...state,
        workerList: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_WORKER_JOB_LIST_FAILURE:
      return {
        ...state,
        workerList: null,
        pending: false,
        success: false,
        error: payload.error,
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default reducer
