import * as ActionTypes from './actionTypes'

export function getPrefecturesListRequest() {
  return {
    type: ActionTypes.GET_PREFECTURES_LIST_REQUEST,
  }
}

export function getPrefecturesListSuccess(data) {
  return {
    type: ActionTypes.GET_PREFECTURES_LIST_SUCCESS,
    payload: data,
  }
}

export function getPrefecturesListFailure(error) {
  return {
    type: ActionTypes.GET_PREFECTURES_LIST_FAILURE,
    payload: error,
  }
}

export function getAllActiveClientListRequest() {
  return {
    type: ActionTypes.GET_ALL_CLIENT_LIST_REQUEST,
  }
}

export function getAllActiveClientListSuccess(data) {
  return {
    type: ActionTypes.GET_ALL_CLIENT_LIST_SUCCESS,
    payload: data,
  }
}

export function getAllActiveClientListFailure(error) {
  return {
    type: ActionTypes.GET_ALL_CLIENT_LIST_FAILURE,
    payload: error,
  }
}

export function getAllStatusRequest() {
  return {
    type: ActionTypes.GET_ALL_STATUS_LIST_REQUEST,
  }
}

export function getAllStatusSuccess(data) {
  return {
    type: ActionTypes.GET_ALL_STATUS_LIST_SUCCESS,
    payload: data,
  }
}

export function getAllStatusFailure(error) {
  return {
    type: ActionTypes.GET_ALL_STATUS_LIST_FAILURE,
    payload: error,
  }
}

export function getAllJobFilterOptionsRequest() {
  return {
    type: ActionTypes.GET_ALL_JOB_FILTER_OPTIONS_LIST_REQUEST,
  }
}

export function getAllJobFilterOptionsSuccess(data) {
  return {
    type: ActionTypes.GET_ALL_JOB_FILTER_OPTIONS_LIST_SUCCESS,
    payload: data,
  }
}

export function getAllJobFilterOptionsFailure(error) {
  return {
    type: ActionTypes.GET_ALL_JOB_FILTER_OPTIONS_LIST_FAILURE,
    payload: error,
  }
}

export function getAllWorkerSkillsRequest() {
  return {
    type: ActionTypes.GET_ALL_WORKER_SKILLS_REQUEST,
  }
}

export function getAllWorkerSkillsSuccess(data) {
  return {
    type: ActionTypes.GET_ALL_WORKER_SKILLS_SUCCESS,
    payload: data,
  }
}

export function getAllWorkerSkillsFailure(error) {
  return {
    type: ActionTypes.GET_ALL_WORKER_SKILLS_FAILURE,
    payload: error,
  }
}

export function getAllExpenseCategoriesRequest() {
  return {
    type: ActionTypes.GET_ALL_EXEPENSE_CATEGORIES_REQUEST,
  }
}

export function getAllExpenseCategoriesSuccess(data) {
  return {
    type: ActionTypes.GET_ALL_EXEPENSE_CATEGORIES_SUCCESS,
    payload: data,
  }
}

export function getAllExpenseCategoriesFailure(error) {
  return {
    type: ActionTypes.GET_ALL_EXEPENSE_CATEGORIES_FAILURE,
    payload: error,
  }
}

export function setDownloadFileFailure(error) {
  return {
    type: ActionTypes.SET_DOWNLOAD_FILE_FAILURE,
    payload: error,
  }
}

export function resetReducers() {
  return {
    type: ActionTypes.RESET,
  }
}

export function setRedirectRoute(path) {
  return {
    type: ActionTypes.SET_REDIRECT_ROUTE,
    payload: path,
  }
}

export function actionAddLocation(payload) {
  return {
    type: ActionTypes.ADD_LOCATION,
    payload,
  }
}

export function setPrivateRedirectRoute(path) {
  return {
    type: ActionTypes.SET_PRIVATE_REDIRECT_ROUTE,
    payload: path,
  }
}

export function actionSetOfferDropdownData(options) {
  return {
    type: ActionTypes.SET_OFFER_DROPDOWN_DATA,
    payload: options,
  }
}

export function actionSet404Page(status) {
  return {
    type: ActionTypes.SET_404_PAGE,
    payload: status,
  }
}
