import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  actionSearchClient,
  actionSetSearchCriteria,
  fetchingDataFail,
  setClientUserFormData,
  resetCurrentForm,
} from 'store/client-user/actionCreators'

export function searchClientUsers(searchParameters) {
  return async (dispatch) => {
    dispatch(showLoader())
    return await Http.get('op-center/client-users', {
      params: searchParameters,
    })
      .then((response) => {
        dispatch(actionSearchClient(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function changeSearchCriteria(searchParameters) {
  return (dispatch) => {
    dispatch(actionSetSearchCriteria(searchParameters))
  }
}

export function getClientUser(id) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get(`/op-center/client-users/${id}`)
      .then((response) => {
        let response_data = {}
        response_data['current'] = {}
        response_data['original'] = response.data.data
        dispatch(setClientUserFormData(response_data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function createUpdateClientUser(data, id = null) {
  let url = id == null ? 'op-center/client-users' : `op-center/client-users/${id}`
  return (dispatch) => {
    dispatch(showLoader())
    const request = id == null ? Http.post(url, data) : Http.put(url, data)
    return request
      .then(() => {
        dispatch(resetCurrentForm())
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function confirmClientUser(data, id) {
  let url = id == null ? 'op-center/client-users/confirm' : `op-center/client-users/${id}/confirm`
  return (dispatch) => {
    dispatch(showLoader())
    const request = id == null ? Http.post(url, data) : Http.put(url, data)
    return request
      .then((response) => {
        dispatch(setClientUserFormData(response.data.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}
