import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import searchIcon from 'assets/svg/search.svg'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetCurrentForm } from 'store/workers/actionCreators'
import { LargeButton, InputLabel, InputSelect, TableList, Heading, Icon } from 'components'
import AddIcon from '@mui/icons-material/Add'

const WorkerListForm = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const handleSearch = (data) => {
    props.handlePageSearch(data)
  }

  useEffect(() => {
    dispatch(resetCurrentForm())
  }, [])

  const validationRules = {
    name: {
      validate: (value) => {
        return value.length <= 255 || '255文字以内で入力してください。'
      },
    },
    screenName: {
      validate: (value) => {
        return value.length <= 255 || '255文字以内で入力してください。'
      },
    },
    id: {
      validate: (value) => {
        return !value || value >= 1 || '1以上の数字を入力してください。'
      },
    },
    phoneNumber: {
      pattern: {
        value: /^[0-9]+$/,
        message: '数字を入力してください。',
      },
    },
  }

  // Table Actions
  const actionList = [
    {
      name: 'worker',
      color: 'view',
      label: '閲覧',
    },
    {
      name: 'worker/update',
      color: 'edit',
      label: '編集',
    },
  ]

  const memberTypes = [
    {
      id: 0,
      name: 'すべて',
    },
    {
      id: 1,
      name: '会員',
    },
    {
      id: 2,
      name: 'ワーカー',
    },
  ]

  const handleCreate = () => {
    dispatch(resetCurrentForm())
    history.push('/worker/create')
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Heading>ワーカー一覧</Heading>
      </Grid>

      <Grid item container justifyContent="end" pt={{ xs: 2, sm: 0 }}>
        <Grid item xs={12} sm={5} md={4} lg={3} textAlign="end">
          <LargeButton startIcon={<AddIcon size={'sm'} />} id="workerCreateBtn-ID" onClick={handleCreate}>
            ワーカー新規登録
          </LargeButton>
        </Grid>
      </Grid>
      <Grid item container pt={6} xs={12}>
        <form onSubmit={handleSubmit(handleSearch)} className="WorkerList-form">
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputLabel
                name="name"
                ref={register('name', validationRules.name)}
                error={!!(errors && errors.name)}
                errorMsg={errors ? errors?.name?.message : null}
                label="ワーカー名"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputLabel
                name="screenName"
                ref={register('screenName', validationRules.screenName)}
                error={!!(errors && errors.screenName)}
                errorMsg={errors ? errors?.screenName?.message : null}
                label="表示名"
                type="text"
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} alignItems={'center'}>
            <Grid item xs={12} sm={6} md={2.4} xl={2.4}>
              <InputLabel
                name="id"
                ref={register('id', validationRules.id)}
                error={!!(errors && errors.id)}
                errorMsg={errors ? errors?.id?.message : null}
                label="会員ID"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4} xl={2.4}>
              <InputLabel
                className="emailAddress"
                name="emailAddress"
                ref={register('emailAddress')}
                label="メールアドレス"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4} xl={2.4}>
              <InputLabel
                className="phoneNumber"
                name="phoneNumber"
                ref={register('phoneNumber', validationRules.phoneNumber)}
                error={!!(errors && errors.phoneNumber)}
                errorMsg={errors ? errors?.phoneNumber?.message : null}
                label="電話番号"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4} xl={2.4}>
              <InputSelect
                name="memberType"
                ref={register('memberType')}
                label="会員種別"
                menuItems={memberTypes}
                defaultValue={0}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <LargeButton
                type="submit"
                bold="true"
                color="warning"
                startIcon={<Icon id="searchBtn-ID" size={'sm'} disabled={props.isLoading} source={searchIcon} />}
              >
                検索
              </LargeButton>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} pt={2}>
          <TableList
            title={`Worker List`}
            headCells={props.headerCells}
            data={props.data}
            totalPage={props.totalPage}
            totalCount={props.totalCount}
            handlePageChange={props.handlePageChange}
            isLoading={props.isLoading}
            handleSort={props.handleSort}
            sort={props.sort}
            sortBy={props.sortBy}
            currentPage={props.currentPage}
            action={true}
            actionButton={true}
            actionList={actionList}
            pageFrom={props.pageFrom}
            pageTo={props.pageTo}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

WorkerListForm.displayName = 'Worker List Form'

WorkerListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

export default WorkerListForm
