import React, { useEffect, useState } from 'react'
import { RadioGroup as MuiRadioGroup, Radio, FormControlLabel } from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import omit from 'lodash/omit'
import standards from 'theme/standards'

const StyledRadioGroup = styled(MuiRadioGroup, { shouldForwardProp: (prop) => prop !== 'color' })(
  ({ gap, size, fontSize, color, mode, theme }) => ({
    gap: gap,
    '& .MuiFormControlLabel-label': {
      fontSize: fontSize ?? '',
    },
    '& .MuiRadio-root': {
      color: `${theme.standards.border.color}`,
      '& .MuiSvgIcon-root': {
        fontSize: size ?? '',
        '&:first-of-type': {
          color: `${theme.standards.border.color}`,
        },
      },
      '&.Mui-checked': {
        color: `${theme.palette[color]?.[mode]} !important`,
      },
    },
  })
)

const RadioGroup = React.forwardRef((props, ref) => {
  const [radioValue, setValue] = useState(props.value || props.defaultValue)
  const excludedProps = ['onChange', 'InputProps', 'errorMsg', 'required', 'value', 'defaultValue', 'items']
  let defaultProps = omit(props, excludedProps)
  const { onChange, name, value, defaultValue, items } = props
  let newValue = value ?? defaultValue ?? ''

  useEffect(() => {
    setValue(newValue || '')
    ref?.onChange({ target: { name: name, value: newValue ?? '' } })
  }, [value, defaultValue])

  const handleChange = (e) => {
    onChange(e)
    setValue(e.target.value)
  }
  return (
    <StyledRadioGroup
      {...defaultProps}
      row
      value={radioValue || ''}
      onChange={(e) => {
        ref?.onChange(e)
        onChange ? handleChange(e) : setValue(e.target.value)
      }}
    >
      {items &&
        items.map((value, index) => {
          return (
            <FormControlLabel
              id={`${name}'-'${index}`}
              key={index}
              sx={{ margin: 0 }}
              label={value}
              value={value}
              control={<Radio />}
            />
          )
        })}
    </StyledRadioGroup>
  )
})
RadioGroup.displayName = 'Radio Group'

RadioGroup.defaultProps = {
  color: 'primary',
  mode: 'main',
  fontSize: standards.fontSize.default,
  size: 24,
  gap: 10,
}

RadioGroup.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  fontSize: PropTypes.number,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  items: PropTypes.array,
}

export default RadioGroup
