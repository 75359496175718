import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { SubHeading, Heading, LargeButton, ContentDisplay, Label, Icon } from 'components'
import { createUpdateClientUser } from 'services/client-user'
import { setConfirming } from 'store/workers/actionCreators'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SuccessIcon from 'assets/svg/success.svg'
import PropTypes from 'prop-types'

const ClientUserConfirmForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const displayData = useSelector((state) => state.clientUsers.formData)
  const isUpdate = useSelector((state) => state.clientUsers.is_update)
  const isConfirming = useSelector((state) => state.clientUsers.isConfirming)
  const loader = useSelector((state) => state.loader)
  const [isSubmit, setIsSubmit] = useState(false)
  const [confirmTitle, setConfirmTitle] = useState()
  const [subConfirmTitle, setSubConfirmTitle] = useState()
  const [successMainTitle, setSuccessMainTitle] = useState()
  const [successSubTitle, setSuccessSubTitle] = useState()

  const handleConfirm = () => {
    let formData = displayData.original
    let formId = isUpdate ? formData?.id : null
    formData['account_status'] = displayData?.current?.account_status ? 1 : 0
    dispatch(createUpdateClientUser(formData, formId)).then(() => {
      setIsSubmit(true)
      props.isConfirm(true)
    })
  }

  useEffect(() => {
    if (!isSubmit && (!isConfirming || !displayData.current)) {
      history.push('/client-user/create')
    }
    return () => {
      dispatch(setConfirming(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (isUpdate) {
      setConfirmTitle('クライアント担当者編集確認')
      setSubConfirmTitle('以下の内容で更新しますか？')
      setSuccessMainTitle('クライアント担当者編集完了')
      setSuccessSubTitle('編集完了しました。')
    } else {
      setConfirmTitle('クライアント担当者登録確認')
      setSubConfirmTitle('以下の内容で登録しますか？')
      setSuccessMainTitle('クライアント担当者登録完了')
      setSuccessSubTitle('登録完了しました。')
    }
  }, [isUpdate])

  return (
    <>
      {!isSubmit ? (
        <Grid container>
          <Grid item xs={12}>
            <Grid item container xs={12} alignItems="center" mb={3}>
              <Grid item container xs={12} mb={4} justifyContent="flex-start" alignItems="flex-start">
                <Heading>{confirmTitle}</Heading>
              </Grid>
              <Grid item container xs={12} justifyContent="flex-start" alignItems="flex-start">
                <SubHeading>{subConfirmTitle}</SubHeading>
              </Grid>
            </Grid>
            <Grid item container xs={8} justifyContent="flex-start" mb={2}>
              <ContentDisplay label="クライアント:" data={displayData?.formatted?.client} />
              <ContentDisplay label="担当者名:" data={displayData?.formatted?.name} />
              <ContentDisplay label="担当者電話番号:" data={displayData?.formatted?.phone_number} />
              <ContentDisplay label="担当者メールアドレス:" data={displayData?.formatted?.email} />
              <ContentDisplay label="アカウント状態:" data={displayData?.formatted?.account_status} />
            </Grid>
            <Grid item container xs={12} justifyContent="center" alignItems="center" mr={4} pt={4}>
              {!isUpdate && <Label>{'担当者にパスワード設定メールが送信されます。'}</Label>}
            </Grid>
            <Grid container pt={4} justifyContent="flex-end" alignItems="flex-end">
              <Grid item mr={2}>
                <LargeButton id="WorkerSubmitBtn" color="gray" onClick={history.goBack}>
                  {'戻る'}
                </LargeButton>
              </Grid>
              <Grid item>
                <LargeButton id="WorkerSubmitBtn" onClick={handleConfirm} type="submit" color="primary">
                  {loader && <CircularProgress size="1.5rem" color="white" />}
                  {!loader && '確定'}
                </LargeButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="Confirmation" justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} pt={{ xs: 1, sm: 4 }}>
            <Icon source={SuccessIcon} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: { xs: 2 }, pt: { xs: 4 } }}>
            <Heading>{successMainTitle}</Heading>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: { xs: 2 }, pt: { xs: 1 } }}>
            <Label>{successSubTitle}</Label>
            <Label fontSize={13}>
              {!isUpdate && 'クライアント担当者にパスワード設定の依頼メールが送信されました。'}
            </Label>
          </Grid>
          <Grid item xs={12} pt={{ xs: 4 }}>
            <Link to="/client-user/list">
              <LargeButton
                id="ChangePasswordConfirmBtn"
                color="primary"
                mode="light"
                width={240}
                maxwidth="unset"
                maxheight="unset"
              >
                {'クライアント担当者一覧へ'}
              </LargeButton>
            </Link>
          </Grid>
        </Grid>
      )}
    </>
  )
}

ClientUserConfirmForm.propTypes = {
  isConfirm: PropTypes.func,
}

export default ClientUserConfirmForm
