import Http from 'utils/Http'
import { formatJobForm, formatJobFormDisplay } from 'utils/helper'
import { status } from 'utils/config/status'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  actionSearchJob,
  actionSetSearchCriteria,
  actionSetOfferSearchCriteria,
  fetchingDataFail,
  getWorkerJobList,
  setJobFormData,
  setOfferFormData,
  setCancellationFee,
  showModalLoader,
  hideModalLoader,
  setJobOutlineDetails,
  actionCheckClockout,
  actionSearchOffer,
  actionSearchClientJob,
  actionSetSearchClientCriteria,
} from 'store/jobs/actionCreators'
import { formatJobOutline } from 'utils/helper'
export function searchJobs(searchParameters) {
  const {
    filter_by1,
    filter_txt1,
    filter_by2,
    filter_txt2,
    start_date,
    end_date,
    prefecture,
    municipality,
    status_id,
    limit,
    page,
    sort,
    sortBy,
  } = searchParameters
  return async (dispatch) => {
    dispatch(showLoader())
    return await Http.get('op-center/jobs', {
      params: {
        filter_by1,
        filter_by2,
        filter_txt1,
        filter_txt2,
        start_date,
        end_date,
        prefecture,
        municipality,
        status_id,
        sortBy,
        sort,
        page,
        limit,
      },
    })
      .then((response) => {
        dispatch(actionSearchJob(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function changeSearchCriteria(searchParameters, offer = false) {
  return (dispatch) => {
    if (!offer) {
      dispatch(actionSetSearchCriteria(searchParameters))
    } else {
      dispatch(actionSetOfferSearchCriteria(searchParameters))
    }
  }
}

export function readJob(jobId, loader = true) {
  return (dispatch) => {
    if (loader) dispatch(showLoader())
    return Http.get('op-center/jobs/' + jobId)
      .then((response) => {
        let response_data = {}
        const jobOutline = formatJobOutline(response.data.data)
        response_data['original'] = response.data.data
        response_data['formatted'] = response.data.data
        dispatch(setJobFormData(response_data))
        dispatch(setJobOutlineDetails(jobOutline))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function fetchJobs(searchParameters) {
  const { filter_by1, filter_txt1, keyword, area, mode, start_date, end_date, status_id, page, sortBy, sort, limit } =
    searchParameters
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('op-center/jobs', {
      params: {
        mode,
        filter_by1,
        filter_txt1,
        keyword,
        area,
        start_date,
        end_date,
        status_id,
        page,
        sortBy,
        sort,
        limit,
      },
    })
      .then((response) => {
        dispatch(getWorkerJobList(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function getCancellationFee(id, cancelDate) {
  return (dispatch) => {
    dispatch(showModalLoader())
    return Http.get(`op-center/jobs/cancel/${id}?cancel_date=${cancelDate}`)
      .then((response) => {
        dispatch(setCancellationFee(response.data))
      })
      .finally(() => {
        dispatch(hideModalLoader())
      })
  }
}

export function cancelJob(id, cancellationFee) {
  return (dispatch) => {
    dispatch(showModalLoader())
    return Http.put(`op-center/jobs/confirm-cancel/${id}?cancellation_fee=${cancellationFee}`)
      .then((response) => {
        dispatch(setJobFormData(response.data))
      })
      .finally(() => {
        dispatch(hideModalLoader())
      })
  }
}

export function resignJob(id) {
  return (dispatch) => {
    dispatch(showModalLoader())
    return Http.put(`op-center/jobs/confirm-resignation/${id}`)
      .then((response) => {
        dispatch(setJobFormData(response.data))
      })
      .finally(() => {
        dispatch(hideModalLoader())
      })
  }
}

export function resignJobWorker(id) {
  return (dispatch) => {
    dispatch(showModalLoader())
    return Http.put(`worker/jobs/confirm-resignation/${id}`)
      .then((response) => {
        dispatch(setJobFormData(response.data))
      })
      .finally(() => {
        dispatch(hideModalLoader())
      })
  }
}

export function actualReportStatus($status_type, job_id, $params = {}) {
  return Http.post(`op-center/jobs/update/status/${$status_type}/${job_id}`, $params)
}

export function downloadActualReport(job_id, report_id) {
  return Http.get(`op-center/jobs/download/report/${job_id}/${report_id}`, {
    responseType: 'blob',
  })
}

export function createJob(data, currentData) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('op-center/jobs/confirm', data)
      .then((response) => {
        let response_data = response.data.data
        response_data['current'] = currentData
        response_data['formatted'] = response_data
        dispatch(setJobFormData(response_data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function confirmJob(data) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('op-center/jobs', formatJobForm(data))
      .then((response) => {
        let response_data = {}
        response_data['current'] = {}
        response_data['original'] = formatJobFormDisplay(response.data.data)
        dispatch(setJobFormData(response_data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function updateConfirmJob(data, id) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.put(`op-center/jobs/${id}`, formatJobForm(data))
      .then((response) => {
        let response_data = {}
        response_data['current'] = {}
        response_data['original'] = formatJobFormDisplay(response.data.data)
        dispatch(setJobFormData(response_data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function approveExpenseReport(job_id) {
  return Http.post('op-center/expenses/approve', { job_id })
}

export function rejectExpenseReport(params) {
  return Http.post('op-center/expenses/reject', params)
}

export function downloadExpenseReport(job_id, report_id) {
  return Http.get(`op-center/expenses/download/report/${report_id}/${job_id}`, {
    responseType: 'blob',
  })
}

export function getJobOutlineDetails(id) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get(`op-center/workers/show/expense-list-details/${id}`)
      .then((response) => {
        dispatch(setJobOutlineDetails(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function createContactLog(formData) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('worker/jobs/contact', { ...formData })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function saveReportDescription(id, description) {
  return Http.post(`worker/reports/save/${id}`, {
    report_text: description,
  })
}

export function uploadActualReport(job_id, file) {
  let formData = new FormData()
  formData.append('id_card_url', file)
  return Http.post(`worker/jobs/upload/report/${job_id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function deleteActualReport(job_id, report_id) {
  return Http.delete(`worker/jobs/delete/report/${job_id}/${report_id}`)
}

export function submitPerformanceReport(id, formData) {
  return (dispatch) => {
    return Http.post(`worker/jobs/upsave/report/${id}`, formData).then((response) => {
      let response_data = {}
      const jobOutline = formatJobOutline(response.data.data)
      response_data['original'] = response.data.data
      response_data['formatted'] = response.data.data
      dispatch(setJobFormData(response_data))
      dispatch(setJobOutlineDetails(jobOutline))
    })
  }
}

export function readWorkerJob(jobId, loader = true) {
  return (dispatch) => {
    if (loader) dispatch(showLoader())
    return Http.post('worker/jobs/show/performance-report/' + jobId)
      .then((response) => {
        let response_data = {}
        const jobOutline = formatJobOutline(response.data.data)
        response_data['original'] = response.data.data
        response_data['formatted'] = response.data.data
        dispatch(setJobFormData(response_data))
        dispatch(setJobOutlineDetails(jobOutline))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function readWorkerJobReport(jobId, loader = true) {
  return (dispatch) => {
    if (loader) dispatch(showLoader())
    return Http.post('worker/jobs/show/contact/' + jobId)
      .then((response) => {
        let response_data = {}
        const jobOutline = formatJobOutline(response.data.data)
        response_data['original'] = response.data.data
        response_data['formatted'] = response.data.data
        dispatch(setJobFormData(response_data))
        dispatch(setJobOutlineDetails(jobOutline))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function createExpense(expenseDetails, jobId) {
  let data = new FormData()
  Object.entries(expenseDetails).forEach(([key, value]) => data.append(key, value))
  return () => {
    return Http.post(`worker/expense/save/${jobId}`, expenseDetails, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}

export function updateWorkerExpense(expenseDetails, jobId, expenseId) {
  let data = new FormData()
  Object.entries(expenseDetails).forEach(([key, value]) => data.append(key, value))

  return () => {
    return Http.post(`worker/expense/update/${jobId}/${expenseId}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}

export function checkJobClockOut(jobId) {
  return (dispatch) => {
    return Http.get(`worker/expense/check/clock-out/${jobId}`)
      .then((response) => {
        dispatch(actionCheckClockout(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
  }
}

export function submitWorkerExpense(jobId, data = {}) {
  return (dispatch) => {
    return Http.post(`worker/expense/update/job-status/${jobId}`, data).catch((error) => {
      dispatch(fetchingDataFail(error))
    })
  }
}

export function removeWorkerExpense(jobId, expenseId) {
  return (dispatch) => {
    return Http.delete(`worker/expense/delete/${jobId}/${expenseId}`)
      .then(() => {
        dispatch(readJob(jobId, false))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
  }
}

export function searchClientJobs(searchParameters) {
  const {
    name,
    worker_name,
    job_group_name,
    project_name,
    start_date,
    end_date,
    prefecture,
    status_id,
    limit,
    page,
    sort,
    sortBy,
  } = searchParameters
  return async (dispatch) => {
    dispatch(showLoader())
    return await Http.get('client/jobs', {
      params: {
        name,
        worker_name,
        job_group_name,
        project_name,
        start_date,
        end_date,
        prefecture,
        status_id,
        limit,
        page,
        sort,
        sortBy,
      },
    })
      .then((response) => {
        dispatch(actionSearchClientJob(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function createOffer(data) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('client/offers', data)
      .then((response) => {
        let response_data = {}
        response_data['original'] = response.data.data
        dispatch(setJobFormData(response_data))
        dispatch(setOfferFormData({}))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function changeSearchClientCriteria(searchParameters) {
  return (dispatch) => {
    dispatch(actionSetSearchClientCriteria(searchParameters))
  }
}

export function confirmOffer(data) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('worker/job-offers/accept', data)
      .then(() => {
        let response_data = {}
        response_data['current'] = null
        response_data['original'] = null
        dispatch(setJobFormData(response_data))
        dispatch(setOfferFormData({}))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function rejectAllOffer(id) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get(`worker/job-offers/reject/${id}`)
      .then(() => {
        let response_data = {}
        response_data['current'] = null
        response_data['original'] = null
        dispatch(setJobFormData(response_data))
        dispatch(setOfferFormData({}))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function searchOffers(searchParameters) {
  return async (dispatch) => {
    dispatch(showLoader())
    const { status: offerStatus } = searchParameters
    if (offerStatus?.includes(status.job.before_job_start) && !offerStatus.includes(status.job.offered_declined)) {
      searchParameters = {
        ...searchParameters,
        status: status.job.offered_declined + ',' + offerStatus,
      }
    }
    return await Http.get('worker/job-offers', {
      params: searchParameters,
    })
      .then((response) => {
        dispatch(actionSearchOffer(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function readOffer(OfferId) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('worker/job-offers/' + OfferId)
      .then((response) => {
        let response_data = {}
        response_data['original'] = response.data.data
        response_data['formatted'] = response.data.data
        dispatch(setJobFormData(response_data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}
