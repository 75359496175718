import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
// import TimePicker from 'react-time-picker'
import { Grid, FormHelperText, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { setFormMode, setConfirming } from 'store/jobs/actionCreators'
import { hideNotification } from 'store/notification/actionCreators'
import { createJob, readJob } from 'services/jobs'
import { readJobGroup } from 'services/jobGroups'
import { getAddress } from 'services/common'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as dayjs from 'dayjs'
//utils
import { formatDropdownData } from 'utils/helper'
import { dateFormat, jobCheckboxOptions, timeFormat, thisDay } from 'utils/common'
//componets
import {
  Label,
  Heading,
  InputAutoComplete,
  InputRichTextEditor,
  InputLabel,
  InputDatePicker,
  InputTextArea,
  InputMultiCheckbox,
  LargeButton,
  Checkbox,
  LabelDisplay,
  Caption,
} from 'components'

const StyledJobCreateUpdateForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .ProjectList-form': {
    width: '100%',
  },
  '& .CheckboxWrapper': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
  },
  '& input.Mui-disabled': {
    backgroundColor: `${theme.palette.lightGray.dark}`,
    borderRadius: 5,
  },
  '& .JobCheckbox-Wrapper .MuiBox-root': {
    paddingBottom: 0,
  },
  '& .DisplayNameWrapper .MuiBox-root': {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  '& .MuiButton-root.UploadBtn': {
    borderRadius: 5,
    '& .MuiFormControl-root': {
      display: 'none',
    },
  },
  '& input#InputUploadFile-ID': {
    display: 'none',
  },
  '& .WorkExperience-Wrapper': {
    '& .MuiBox-root:first-of-type': {
      padding: 0,
    },
  },
  '& .BankAccount-Wrapper': {
    '& .MuiBox-root': {
      padding: 0,
    },
  },
  '& .LabelInfo': {
    fontSize: '12px',
    padding: '5px 15px 5px 15px',
    margin: '0px 2px 15px 2px',
    backgroundColor: 'oldlace',
    borderRadius: '5px',
  },
  '& .Destination-Wrapper': {
    paddingTop: '30px',
  },
  '& .LabelInfoText': {
    '& span': {
      color: 'red',
    },
  },
  '& .Cancellation-Wrapper': {
    '& .MuiBox-root:first-of-type': {
      padding: 14,
    },
    '& p.MuiFormHelperText-root.Cancellation-Note': {
      color: `${theme.palette.text.error} !important`,
      fontSize: 12,
    },
  },
}))

const JobCreateUpdateForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  //useform hook
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    watch,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
  } = useForm()

  let providedToWorker = 'その他'
  let itemProvidedDesc = '作業に使用する部材'
  const start_time = useRef({})
  start_time.current = watch('start_time', '')
  const clientList = useSelector((state) => state.common.clientList)
  //dropown data
  const [clientUserList, setClientUserList] = useState([])
  const [workerList, setWorkerList] = useState([])
  const [jobGroupList, setJobGroupList] = useState([])
  const [projectList, setProjectList] = useState([])
  const [activeProjectList, setActiveProjectList] = useState([])
  const [activeClientUserList, setActiveClientUserList] = useState([])
  const [isProvidedToWorkerOther, setIsProvidedToWorkerOther] = useState(false)
  const [isItemsProvidedDescription, setIsItemsProvidedDescription] = useState(false)
  const [isCancelationFee, setIsCancelationFee] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [prefecture, setPrefecture] = useState('')
  const [municipality, setMunicipality] = useState('')
  const [picEmail, setPicEmail] = useState('')
  const [isDepartureRecipients, setIsDepartureRecipients] = useState(false)
  const [departureRecipients, setDepartureRecipients] = useState('')
  const [isArrivalRecipients, setIsArrivalRecipients] = useState(false)
  const [arrivalRecipients, setArrivalRecipients] = useState('')
  const [isOneDayBeforeRecipients, setIsOneDayBeforeRecipients] = useState(false)
  const [oneDayBeforeRecipients, setOneDayBeforeRecipients] = useState('')
  const [clockInRecipients, setClockInRecipients] = useState('')
  const [clockOutRecipients, setClockOutRecipients] = useState('')
  const [addressLine1, setAddressLine1] = useState('')

  //form data
  const currentformData = useSelector((state) => state.jobs.formData)
  const jobGroupData = useSelector((state) => state.jobGroups.formData)
  let formDataJobGroup =
    jobGroupData.current && Object.keys(jobGroupData.current).length > 0 ? jobGroupData.current : jobGroupData.original

  let deafultFormData =
    currentformData.current && Object.keys(currentformData.current).length > 0
      ? currentformData.current
      : currentformData.original

  const [formData, setFormData] = useState(null)

  //dropdown state
  const [activeClientList, setactiveClientList] = useState([])
  const [projectDisabled, setProjectDisabled] = useState(deafultFormData?.project_id ? false : true)
  const [clientUserDisabled, setClientUserDisabled] = useState(deafultFormData?.client_user_id ? false : true)
  const [jobGroupDisabled, setJobGroupDisabled] = useState(deafultFormData?.job_group ? false : true)

  //state
  const [isUpdate, setIsUpdate] = useState(false)
  const [requestAddress, setRequestAddress] = useState(false)
  const [selectedCheckboxOptions, setSelectedCheckboxOptions] = useState([])

  //dispatch hooks
  useEffect(() => {
    let jobId = props?.match?.params?.id
    if (props?.location?.pathname.includes('update')) {
      setIsUpdate(true)
      dispatch(setFormMode(true))
    } else {
      setIsUpdate(false)
      dispatch(setFormMode(false))
    }
    if (jobId) {
      dispatch(readJob(jobId))
    }
  }, [dispatch])

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams) {
      const job_group = urlParams.get('job_group')
      if (job_group) {
        dispatch(readJobGroup(job_group))
        setProjectDisabled(false)
        setJobGroupDisabled(false)
      }
    }
  }, [dispatch])

  useEffect(() => {
    if (deafultFormData) {
      let invoiceGenerated = false
      if (isUpdate) {
        invoiceGenerated = true
      }
      setFormData({
        ...deafultFormData,
        client_id: {
          id: deafultFormData?.client_id?.id ? deafultFormData?.client_id?.id : deafultFormData?.client_id,
          name: deafultFormData?.client_id?.name ? deafultFormData?.client_id?.name : deafultFormData?.client?.name,
        },
        project_id: {
          id: deafultFormData?.project_id?.id ? deafultFormData?.project_id?.id : deafultFormData?.project_id,
          name: deafultFormData?.project_id?.name ? deafultFormData?.project_id?.name : deafultFormData?.project_name,
        },
        client_user_id: {
          id: deafultFormData?.client_user_id?.id
            ? deafultFormData?.client_user_id?.id
            : deafultFormData?.client_user_id,
          name: deafultFormData?.client_user_id?.name
            ? deafultFormData?.client_user_id?.name
            : deafultFormData?.client_user?.user?.name,
        },
        job_group: {
          ...deafultFormData?.job_group,
          id: deafultFormData?.job_group?.id ? deafultFormData?.job_group?.id : deafultFormData?.job_group_id,
          name: deafultFormData?.job_group?.name ? deafultFormData?.job_group?.name : deafultFormData?.job_group_name,
        },
        worker: {
          id: deafultFormData?.worker?.id ? deafultFormData?.worker?.id : deafultFormData?.worker_id,
          name: deafultFormData?.worker?.name ? deafultFormData?.worker?.name : deafultFormData?.worker_name,
        },
        invoice_generated: invoiceGenerated,
      })
      setIsOneDayBeforeRecipients(deafultFormData?.one_day_before_recipients?.length > 0 ? true : false)
      setIsDepartureRecipients(deafultFormData?.departure_recipients?.length > 0 ? true : false)
      setIsArrivalRecipients(deafultFormData?.arrival_recipients?.length > 0 ? true : false)
      setPicEmail(deafultFormData?.client_user?.user?.email_address || '')
      setOneDayBeforeRecipients(deafultFormData?.one_day_before_recipients || '')
      setDepartureRecipients(deafultFormData?.departure_recipients || '')
      setArrivalRecipients(deafultFormData?.arrival_recipients || '')
      setClockInRecipients(deafultFormData?.clock_in_recipients || '')
      setClockOutRecipients(deafultFormData?.clock_out_recipients || '')
    }
  }, [dispatch, deafultFormData])

  useEffect(() => {
    if (formDataJobGroup) {
      setFormData({
        ...deafultFormData,
        client_id: {
          id: formDataJobGroup?.client_id?.id,
          name: formDataJobGroup?.client_id?.name,
        },
        project_id: {
          id: formDataJobGroup?.project_id?.id,
          name: formDataJobGroup?.project_id?.name,
        },
        client_user_id: {
          id: formDataJobGroup?.client_user_id?.id,
          name: formDataJobGroup?.client_user_id?.alias,
        },
        job_group: {
          ...formDataJobGroup?.job_group,
          id: formDataJobGroup?.id,
          name: formDataJobGroup?.alias,
        },
      })
    }
  }, [dispatch, formDataJobGroup])

  //auto fill pic email when job created from job group
  useEffect(() => {
    if (formDataJobGroup) {
      const clienUserId = formDataJobGroup?.client_user_id?.id
      const selectedClientUser = activeClientUserList?.find((e) => e.id === clienUserId)
      setSelectedUserClientState(selectedClientUser)
    }
  }, [formDataJobGroup, activeClientUserList])

  useEffect(() => {
    setactiveClientList(formatDropdownData(clientList, 'alias'))
    setWorkerList(clientList?.[0]?.workers ? formatDropdownData(clientList?.[0]?.workers, 'name') : [])
  }, [dispatch, clientList])

  //formData hooks
  useEffect(() => {
    let selectedOptions = ['手順書', '筆記用具']

    if (formData?.client_id) {
      jobCheckboxOptions.map((type) =>
        type.options.map((option) => {
          if (formData?.provided_documents) {
            formData?.provided_documents.map((value) => {
              if (value.name == option.name) {
                if (!selectedOptions.includes(option.name)) {
                  selectedOptions.push(option.name)
                  if (option.id == providedToWorker) {
                    setIsProvidedToWorkerOther(true)
                  }
                }
              }
            })
            formData?.provided_items.map((value) => {
              if (value.name == option.name) {
                if (!selectedOptions.includes(option.name)) {
                  selectedOptions.push(option.name)
                  if (option.id == itemProvidedDesc) {
                    setIsItemsProvidedDescription(true)
                  }
                }
              }
            })
            formData?.worker_tools.map((value) => {
              if (value.name == option.name) {
                if (!selectedOptions.includes(option.name)) {
                  selectedOptions.push(option.name)
                }
              }
            })
          } else {
            if (formData?.goods_supplied_chk) {
              formData?.goods_supplied_chk.map((value) => {
                if (!selectedOptions.includes(value)) {
                  selectedOptions.push(value)
                  if (value === providedToWorker) {
                    setIsProvidedToWorkerOther(true)
                  }
                }
              })
            }
            if (formData?.goods_sent_to_worker_chk) {
              formData?.goods_sent_to_worker_chk.map((value) => {
                if (!selectedOptions.includes(value)) {
                  selectedOptions.push(value)
                  if (value === itemProvidedDesc) {
                    setIsItemsProvidedDescription(true)
                  }
                }
              })
            }
            if (formData?.goods_worker_bring_chk) {
              formData?.goods_worker_bring_chk.map((value) => {
                if (!selectedOptions.includes(value)) {
                  selectedOptions.push(value)
                }
              })
            }
          }
        })
      )
      setSelectedCheckboxOptions(selectedOptions)
      setValue('goods_supplied_chk', ['手順書'])
      setValue('goods_worker_bring_chk', ['筆記用具'])
    } else {
      setValue('goods_supplied_chk', ['手順書'])
      setValue('goods_worker_bring_chk', ['筆記用具'])
      setSelectedCheckboxOptions(['手順書', '筆記用具'])
    }
    setIsCancelationFee(formData?.invoice_generated ?? false)
    setPrefecture(formData?.prefecture ?? '')
    setMunicipality(formData?.municipality ?? '')
    setAddressLine1(formData?.address_line_1 ?? '')
  }, [formData, deafultFormData])

  //render when client dropdown change from form data
  useEffect(() => {
    if (formData?.client_id) {
      let clientID = formData?.client_id.id ? formData?.client_id.id : formData?.client_id
      let selectedClient = clientID ? clientList.find(({ id }) => id === clientID) : null
      let clientUsers = selectedClient ? selectedClient?.client_user : []
      let clientProjects = selectedClient ? selectedClient?.projects : []
      setActiveProjectList(clientProjects)
      setActiveClientUserList(clientUsers)
      setClientUserList(clientID ? formatDropdownData(clientUsers, 'user_name') : [])
      setProjectList(clientID ? formatDropdownData(clientProjects, 'name') : [])
      setProjectDisabled(false)
      setClientUserDisabled(false)
    }
  }, [formData?.client_id, clientList])

  useEffect(() => {
    if (formData?.project_id) {
      let projectID = formData?.project_id?.id ? formData?.project_id?.id : formData?.project_id
      let selectedProject = projectID ? activeProjectList.find(({ id }) => id === projectID) : null
      let projectJobGroup = selectedProject ? selectedProject?.job_groups : []
      setJobGroupList(projectID ? formatDropdownData(projectJobGroup, 'name') : [])
      setJobGroupDisabled(projectID ? false : true)
    }
  }, [formData?.project_id, activeProjectList])

  //check input name type
  const isReminders = (name) => {
    return (
      name === 'one_day_before_recipients' ||
      name === 'departure_recipients' ||
      name === 'arrival_recipients' ||
      name === 'clock_in_recipients' ||
      name === 'clock_out_recipients'
    )
  }

  //triger validations if form already submitted and checkbox isOneDayBeforeRecipients value changed
  useEffect(() => {
    isSubmitted && trigger('one_day_before_recipients')
  }, [isOneDayBeforeRecipients])

  //triger validations if form already submitted and checkbox isDepartureRecipients value changed
  useEffect(() => {
    isSubmitted && trigger('departure_recipients')
  }, [isDepartureRecipients])

  //triger validations if form already submitted and checkbox isArrivalRecipients value changed
  useEffect(() => {
    isSubmitted && trigger('arrival_recipients')
  }, [isArrivalRecipients])

  //triger validations if form already submitted and checkbox isProvidedToWorkerOther value changed
  useEffect(() => {
    isSubmitted && trigger('provided_to_worker_other')
  }, [isProvidedToWorkerOther])

  //triger validations if form already submitted and checkbox isItemsProvidedDescription value changed
  useEffect(() => {
    isSubmitted && trigger('items_provided_description')
  }, [isItemsProvidedDescription])

  const handleClick = (e) => {
    if (!e.target.checked) {
      const index = selectedCheckboxOptions.indexOf(e.target.value)
      if (index > -1) {
        selectedCheckboxOptions.splice(index, 1)
      }
    }

    if (e.target.checked && !selectedCheckboxOptions.includes(e.target.value)) {
      selectedCheckboxOptions.push(e.target.value)
    }
    if (e.target.value == providedToWorker) {
      setIsProvidedToWorkerOther(!isProvidedToWorkerOther)
      clearErrors('provided_to_worker_other')
    }

    if (e.target.value == itemProvidedDesc) {
      setIsItemsProvidedDescription(!isItemsProvidedDescription)
      clearErrors('items_provided_description')
    }
  }
  //handle replace strings
  const handleReplaceString = (original, toReplace, replacement) => {
    return original.includes(toReplace + ',')
      ? original.replace(toReplace + ',', replacement?.length > 0 ? replacement + ',' : replacement)
      : original.replace(toReplace, replacement)
  }
  //handle change PIC emails for remiders
  const handleReminderEmails = (oldPIC, newPIC) => {
    const hasOldPIC = oldPIC?.length > 0 ?? false

    if (isOneDayBeforeRecipients && !oneDayBeforeRecipients && oneDayBeforeRecipients.length <= 0) {
      setOneDayBeforeRecipients(newPIC)
    } else if (hasOldPIC && oneDayBeforeRecipients.includes(oldPIC)) {
      setOneDayBeforeRecipients(handleReplaceString(oneDayBeforeRecipients, oldPIC, newPIC))
    }

    if (isDepartureRecipients && (!departureRecipients || departureRecipients.length <= 0)) {
      setDepartureRecipients(newPIC)
    } else if (hasOldPIC && departureRecipients.includes(oldPIC)) {
      setDepartureRecipients(handleReplaceString(departureRecipients, oldPIC, newPIC))
    }

    if (isArrivalRecipients && (!arrivalRecipients || arrivalRecipients.length <= 0)) {
      setArrivalRecipients(newPIC)
    } else if (hasOldPIC && arrivalRecipients.includes(oldPIC)) {
      setArrivalRecipients(handleReplaceString(arrivalRecipients, oldPIC, newPIC))
    }

    if (!clockInRecipients || clockInRecipients.length <= 0) {
      setClockInRecipients(newPIC)
    } else if (hasOldPIC && clockInRecipients.includes(oldPIC)) {
      setClockInRecipients(handleReplaceString(clockInRecipients, oldPIC, newPIC))
    }

    if (!clockOutRecipients || clockOutRecipients.length <= 0) {
      setClockOutRecipients(newPIC)
    } else if (hasOldPIC && clockOutRecipients.includes(oldPIC)) {
      setClockOutRecipients(handleReplaceString(clockOutRecipients, oldPIC, newPIC))
    }
  }

  const handleCheckboxChange = (e) => {
    switch (e.target.name) {
      case 'invoice_generated':
        setIsCancelationFee(e.target.checked)
        break

      case 'is_previous_contact_enabled':
        setIsOneDayBeforeRecipients(e.target.checked)
        if (!e.target.checked) {
          setOneDayBeforeRecipients('')
          setValue('one_day_before_recipients', '')
          clearErrors('one_day_before_recipients')
        } else {
          if (!oneDayBeforeRecipients || oneDayBeforeRecipients?.length <= 0) {
            setOneDayBeforeRecipients(picEmail)
          }
        }
        break

      case 'is_departure_contact_enabled':
        setIsDepartureRecipients(e.target.checked)
        if (!e.target.checked) {
          setDepartureRecipients('')
          setValue('departure_recipients', '')
          clearErrors('departure_recipients')
        } else {
          if (!departureRecipients || departureRecipients?.length <= 0) {
            setDepartureRecipients(picEmail)
          }
        }
        break

      case 'is_arrival_contact_enabled':
        setIsArrivalRecipients(e.target.checked)
        if (!e.target.checked) {
          setArrivalRecipients('')
          setValue('arrival_recipients', '')
          clearErrors('arrival_recipients')
        } else {
          if (!arrivalRecipients || arrivalRecipients?.length <= 0) {
            setArrivalRecipients(picEmail)
          }
        }
        break
    }
  }

  //handle change autocomplete dropdown
  const handleAutoCompleteChange = (data, name) => {
    if (name == 'client_id') {
      let selectedClient = data?.id ? clientList.find(({ id }) => id === data.id) : null
      let clientUsers = selectedClient ? selectedClient?.client_user : []
      let clientProjects = selectedClient ? selectedClient?.projects : []
      setActiveProjectList(clientProjects)
      setActiveClientUserList(clientUsers)
      setClientUserList(data?.id ? formatDropdownData(clientUsers, 'user_name') : [])
      setProjectList(data?.id ? formatDropdownData(clientProjects, 'name') : [])
      setProjectDisabled(data?.id ? false : true)
      setClientUserDisabled(data?.id ? false : true)
      setJobGroupDisabled(true)
      setJobGroupList([])
      setFormData((prevState) => ({
        ...prevState,
        project_id: null,
        job_group: null,
        client_user_id: null,
        client_user: null,
      }))
      setValue('project_id', null)
      setValue('job_group', null)
      setValue('client_user_id', null)
      handleReminderEmails(picEmail, '')
      setPicEmail('')
    }

    if (name == 'project_id') {
      let selectedProject = data?.id ? activeProjectList.find(({ id }) => id === data.id) : null
      let projectJobGroup = selectedProject ? selectedProject?.job_groups : []
      setJobGroupList(data?.id ? formatDropdownData(projectJobGroup, 'name') : [])
      setJobGroupDisabled(data?.id ? false : true)
      setFormData((prevState) => ({
        ...prevState,
        job_group: null,
      }))
      setValue('job_group', null)
    }

    if (name == 'client_user_id') {
      let selectedUserClient = data?.id ? activeClientUserList.find(({ id }) => id === data.id) : null
      setSelectedUserClientState(selectedUserClient)
    }
  }

  // set selected client user state
  const setSelectedUserClientState = (selectedUserClient) => {
    const newPIC = selectedUserClient?.user?.email_address ?? ''
    handleReminderEmails(picEmail, newPIC)
    setPicEmail(newPIC)
    setFormData((prevState) => ({
      ...prevState,
      client_user: selectedUserClient,
    }))
  }

  // handle Submit
  const handleSubmitJobForm = (data) => {
    setIsSubmit(true)
    let goods_supplied_chk = []
    let goods_sent_to_worker_chk = []
    let goods_worker_bring_chk = []

    jobCheckboxOptions.map((type) =>
      type.options.map((option) => {
        if (type.type === 'goods_supplied_chk') {
          selectedCheckboxOptions.map((selected) => {
            if (selected === option.name) {
              if (!goods_supplied_chk.includes(option.name)) {
                goods_supplied_chk.push(option.name)
              }
            }
          })
        }
        if (type.type === 'goods_sent_to_worker_chk') {
          selectedCheckboxOptions.map((selected) => {
            if (selected === option.name) {
              if (!goods_sent_to_worker_chk.includes(option.name)) {
                goods_sent_to_worker_chk.push(option.name)
              }
            }
          })
        }
        if (type.type === 'goods_worker_bring_chk') {
          selectedCheckboxOptions.map((selected) => {
            if (selected === option.name) {
              if (!goods_worker_bring_chk.includes(option.name)) {
                goods_worker_bring_chk.push(option.name)
              }
            }
          })
        }
      })
    )
    if (!goods_supplied_chk.includes('その他')) {
      data['provided_to_worker_other'] = ''
    }

    if (!goods_sent_to_worker_chk.includes('作業に使用する部材')) {
      data['items_provided_description'] = ''
    }
    data['goods_supplied_chk'] = goods_supplied_chk
    data['goods_sent_to_worker_chk'] = goods_sent_to_worker_chk
    data['goods_worker_bring_chk'] = goods_worker_bring_chk
    let newData = {
      ...data,
      id: isUpdate ? props?.match?.params?.id : null,
      client_user_id: data?.client_user_id.id,
      project_id: data?.project_id.id,
      job_group_id: data?.job_group.id,
      worker_id: data?.worker.id,
      client_id: data?.client_id.id,
    }
    data['client_user'] = formData?.client_user
    data['worker_alias'] = formData?.worker_alias
    data['pic_alias'] = formData?.pic_alias
    data['project_alias'] = formData?.project_alias
    data['job_group_alias'] = formData?.job_group_alias

    dispatch(createJob(newData, data))
      .then(() => {
        dispatch(setConfirming(true))
        dispatch(setFormMode(isUpdate))
        history.push('/job/confirmation')
      })
      .catch((e) => {
        if (e.response) {
          const { code, error } = e.response.data
          let formattedErrors = {}
          Object.keys(error).map((key) => {
            if (key.split('.')[0] === 'one_day_before_recipients') {
              formattedErrors['one_day_before_recipients'] = error[key]
            } else if (key.split('.')[0] === 'departure_recipients') {
              formattedErrors['departure_recipients'] = error[key]
            } else if (key.split('.')[0] === 'arrival_recipients') {
              formattedErrors['arrival_recipients'] = error[key]
            } else if (key.split('.')[0] === 'clock_in_recipients') {
              formattedErrors['clock_in_recipients'] = error[key]
            } else if (key.split('.')[0] === 'clock_out_recipients') {
              formattedErrors['clock_out_recipients'] = error[key]
            } else {
              formattedErrors[key] = error[key]
            }
          })
          const allFields = Object.keys(getValues())
          const allErrors = formattedErrors ? Object.keys(formattedErrors) : []
          const hasFieldError = allFields.some((item) => allErrors.includes(item))
          if (code === 422 && hasFieldError) {
            dispatch(hideNotification())
            Object.keys(formattedErrors).map((key) => {
              setError(key, { type: 'custom', message: formattedErrors[key][0] }, { shouldFocus: true })
            })
          }
        }
      })
      .finally(() => {
        setIsSubmit(false)
      })
  }

  // onClick get address by postal code
  const handleGetAddress = async () => {
    const validPostal = await trigger('zip_code')
    let zip_code = getValues('zip_code')
    if (validPostal) {
      setRequestAddress(true)
      await getAddress(zip_code).then((response) => {
        setRequestAddress(false)
        if (response.data.results == null) {
          setError('zip_code', { type: 'custom', message: '該当する郵便番号が見つかりませんでした。' })
        } else {
          clearErrors('zip_code')
          clearErrors('prefecture')
          clearErrors('municipality')
          setPrefecture(response.data.results[0]?.address1)
          setMunicipality(response.data.results[0]?.address2)
          setAddressLine1(response.data.results[0]?.address3)
        }
      })
    }
  }
  //onChange postal input value
  const handleChangePostal = () => {
    if (errors.zip_code) {
      trigger('zip_code')
    }
    clearErrors('prefecture')
    clearErrors('municipality')
    setPrefecture('')
    setMunicipality('')
  }
  //onChange inputs reset errors
  const handleInputChange = (event, name) => {
    const value = event.target.value
    const hasValue = value.length > 0
    switch (name) {
      case 'one_day_before_recipients':
        hasValue && setIsOneDayBeforeRecipients(true)
        setOneDayBeforeRecipients(value)
        break
      case 'departure_recipients':
        hasValue && setIsDepartureRecipients(true)
        setDepartureRecipients(value)
        break
      case 'arrival_recipients':
        hasValue && setIsArrivalRecipients(true)
        setArrivalRecipients(value)
        break
      case 'clock_in_recipients':
        setClockInRecipients(value)
        break
      case 'clock_out_recipients':
        setClockOutRecipients(value)
        break
    }
    if (errors[name]) {
      trigger(name)
    }
  }

  //form validation
  const validationRules = {
    client_id: {
      required: {
        value: Object,
        message: 'クライアントを選択してください。',
      },
    },
    project_id: {
      required: {
        value: Object,
        message: '案件を選択してください。',
      },
    },
    job_group: {
      required: {
        value: Object,
        message: '作業グループを選択してください。',
      },
    },
    name: {
      required: {
        value: String,
        message: '案件を選択してください。',
      },
      validate: (value) => {
        return value.length <= 255 || '100文字以内で入力してください。'
      },
    },
    client_user_id: {
      required: {
        value: Object,
        message: '作業担当者を選択してください。',
      },
    },
    worker: {
      required: {
        value: Object,
        message: 'ワーカーを選択してください。',
      },
    },
    date: {
      required: {
        value: String,
        message: '作業日を入力してください。',
      },
      validate: (value) => {
        const isValid = dayjs(value, dateFormat, true).isValid()
        if (!isValid) {
          return 'YYYY/MM/DDの形式で入力してください。'
        } else {
          return true
        }
      },
    },
    start_time: {
      required: {
        value: String,
        message: '作業開始時刻を入力してください。',
      },
      pattern: {
        value: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
        message: 'HH:MMの形式で入力してください。',
      },
    },
    end_time: {
      required: {
        value: String,
        message: '作業終了時刻を入力してください。',
      },
      pattern: {
        value: /^([0-1]?[0-9]|(2[0-9]|3[0-9]|4[0-7]))[:]?([0-5]\d)$/i,
        message: 'HH:MMの形式で入力してください。',
      },
      validate: (value) => {
        const jobDate = getValues('date') || thisDay
        const starTime = dayjs(jobDate + getValues('start_time')).format(dateFormat + ' ' + timeFormat)
        const endTime = dayjs(jobDate + value).format(dateFormat + ' ' + timeFormat)
        const endTimeLimit = dayjs(starTime)
          .add(1, 'day')
          .format(dateFormat + ' ' + timeFormat)
        if (dayjs(endTime).isBefore(dayjs(starTime)) || dayjs(endTime).isSame(dayjs(starTime))) {
          return '作業開始時刻以降の時刻を入力してください。深夜作業で日をまたぐ場合は24:00にプラスする形で入力してください。例: 28:00'
        } else if (dayjs(endTime).isAfter(dayjs(endTimeLimit))) {
          return '作業開始時間から24時間いないの時刻を入力してください。'
        } else {
          return
        }
      },
    },
    break_time: {
      required: {
        value: String,
        message: '休憩時間を入力してください。',
      },
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    zip_code: {
      required: {
        value: String,
        message: '郵便番号を入力してください',
      },
      validate: (value) => {
        if (value.length > 7) {
          return '7桁の半角数字を入力してください。'
        } else if (!/^[0-9]*$/.test(value)) {
          return '7桁の半角数字を入力してください。'
        } else {
          return true
        }
      },
    },
    prefecture: {
      required: {
        value: String,
        message: 'は必須項目です。',
      },
    },
    municipality: {
      required: {
        value: String,
        message: 'は必須項目です。',
      },
    },
    address_line_1: {
      required: {
        value: String,
        message: '町名番地を入力してください。',
      },
      validate: (value) => {
        return value.length <= 255 || '255文字以内で入力してください。'
      },
    },
    address_line_2: {
      validate: (value) => {
        return value.length <= 255 || '255文字以内で入力してください。'
      },
    },
    description: {
      required: {
        value: String,
        message: '作業内容を入力してください。',
      },
      validate: (value) => {
        const regex = /(<([^>]+)>)/gi
        return value.replaceAll(regex, '').length <= 1000 || '1000文字以内で入力してください。'
      },
    },
    salary: {
      required: {
        value: String,
        message: '作業費(税抜き)を入力してください。',
      },
      validate: (value) => {
        const intMinValue = value.toString().trim().includes(',') ? value.replaceAll(',', '') : value
        return (
          (parseFloat(intMinValue) >= 1 && parseFloat(intMinValue) <= 1000000) ||
          '1〜1,000,000の数字を入力してください。'
        )
      },
      pattern: {
        value: /^[0-9,]*$/, // Regex numbers and commas Validation
        message: '1〜1,000,000の数字を入力してください。',
      },
    },
    allowance: {
      required: {
        value: Number,
        message: '手当を入力してください。',
      },
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    toll_fee: {
      required: {
        value: Number,
        message: '交通費入力してください。',
      },
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    highway_fee: {
      required: {
        value: Number,
        message: '高速代を入力してください。',
      },
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    parking_fee: {
      required: {
        value: Number,
        message: '駐車場代を入力してください。',
      },
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    overnight_stay_fee: {
      required: {
        value: Number,
        message: '宿泊費を入力してください。',
      },
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    other_fee: {
      required: {
        value: Number,
        message: 'その他経費を入力してください。',
      },
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    provided_to_worker_other: {
      validate: {
        required: (value) => {
          if (!value && getValues('手順書')) return 'その他の事前支給品を入力してください。'
          return true
        },
      },
    },
    items_provided_description: {
      validate: {
        required: (value) => {
          if (!value && getValues('作業に使用する部材')) return '作業に使用する部材を入力してください。'
          return true
        },
      },
    },
    worker_tool_notes: {
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    attire: {
      required: {
        value: String,
        message: '服装を入力してください。',
      },
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    escalation_contact: {
      required: {
        value: String,
        message: 'エスカレーション先を入力してください。',
      },
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    invoice_generated: {
      required: {
        value: Boolean,
        message: '上記キャンセル費を承諾する、にチェックを入れてください。',
      },
    },
    onsite_client_contact: {
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    onsite_name: {
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    fellow: {
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    remarks: {
      validate: (value) => {
        return value.length <= 100 || '100文字以内で入力してください。'
      },
    },
    one_day_before_recipients: {
      validate: {
        required: (value) => {
          if (!value && getValues('is_previous_contact_enabled')) return '連絡先を入力してください。'
          return true
        },
        max: (value) => {
          return value.length <= 255 || '255文字以内で入力してください。'
        },
      },
      pattern: {
        value: /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/i,
        message: 'メールアドレスの書式が正しくありません。',
      },
    },
    departure_recipients: {
      validate: {
        required: (value) => {
          if (!value && getValues('is_departure_contact_enabled')) return '連絡先を入力してください。'
          return true
        },
        max: (value) => {
          return value.length <= 255 || '255文字以内で入力してください。'
        },
      },
      pattern: {
        value: /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/i,
        message: 'メールアドレスの書式が正しくありません。',
      },
    },
    arrival_recipients: {
      validate: {
        required: (value) => {
          if (!value && getValues('is_arrival_contact_enabled')) return '連絡先を入力してください。'
          return true
        },
        max: (value) => {
          return value.length <= 255 || '255文字以内で入力してください。'
        },
      },
      pattern: {
        value: /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/i,
        message: 'メールアドレスの書式が正しくありません。',
      },
    },
    clock_in_recipients: {
      required: {
        value: String,
        message: 'エスカレーション先を入力してください。',
      },
      validate: (value) => {
        return value.length <= 255 || '255文字以内で入力してください。'
      },
      pattern: {
        value: /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/i,
        message: 'メールアドレスの書式が正しくありません。',
      },
    },
    clock_out_recipients: {
      required: {
        value: String,
        message: 'エスカレーション先を入力してください。',
      },
      validate: (value) => {
        return value.length <= 255 || '255文字以内で入力してください。'
      },
      pattern: {
        value: /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/i,
        message: 'メールアドレスの書式が正しくありません。',
      },
    },
  }

  let defaultInputProps = {
    required: true,
  }

  const getAutoCompleteField = (label, name, placeholder, options, disabled, noPad) => {
    let defaultValue = formData ? formData?.[name] ?? null : null
    return (
      <InputAutoComplete
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        options={options}
        defaultinput={defaultValue}
        itemSelect={(data) => handleAutoCompleteChange(data, name)}
        {...defaultInputProps}
        disabled={disabled}
        noPad={noPad}
      />
    )
  }

  const getInputLabelField = (label, name, placeholder, requiredField, disabled) => {
    let inputValue = formData ? formData[name] : null
    if (name === 'prefecture') {
      inputValue = prefecture
    }
    if (name === 'municipality') {
      inputValue = municipality
    }
    if (name === 'address_line_1') {
      inputValue = addressLine1
    }

    if (name === 'one_day_before_recipients') {
      inputValue = oneDayBeforeRecipients
    }

    if (name === 'departure_recipients') {
      inputValue = departureRecipients
    }

    if (name === 'arrival_recipients') {
      inputValue = arrivalRecipients
    }

    if (name === 'clock_in_recipients') {
      inputValue = clockInRecipients
    }

    if (name === 'clock_out_recipients') {
      inputValue = clockOutRecipients
    }
    return (
      <InputLabel
        id={name + '-ID'}
        className={name === 'salary' || isReminders(name) ? 'NoLabel NoPad' : ''}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        isDisabled={disabled || false}
        {...(inputValue && { value: inputValue })}
        {...defaultInputProps}
        required={requiredField}
        onChange={name == 'zip_code' ? () => handleChangePostal() : (e) => handleInputChange(e, name)}
      />
    )
  }

  const getInputTextAreaField = (label, subLabel, name, placeholder, height, required) => {
    let inputValue = formData ? formData[name] : null
    return (
      <InputTextArea
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        subLabel={subLabel}
        {...(inputValue && { value: inputValue })}
        height={height}
        required={required}
      />
    )
  }

  const getInputRichTextEditor = (label, name, placeholder, height) => {
    defaultInputProps['sideLabel'] = false
    return (
      <InputRichTextEditor
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        defaultValue={formData ? formData[name] : null}
        label={label}
        height={height}
        required
      />
    )
  }

  const getInputMultiCheckbox = (label, name, items, subLabel, requiredField) => {
    return (
      <InputMultiCheckbox
        ref={register(name + '[]')}
        name={name + '[]'}
        label={label}
        subLabel={subLabel}
        items={items}
        onChange={handleClick}
        required={requiredField}
        selected={selectedCheckboxOptions}
      />
    )
  }

  const getInputCheckbox = (label, name, defaultChecked, defaultDisabled, requiredField) => {
    return (
      <Checkbox
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? 1 : 0}
        errorMsg={errors ? errors[name]?.message : null}
        name={name}
        label={label}
        required={requiredField}
        disabled={defaultDisabled}
        onChange={handleCheckboxChange}
        checked={defaultChecked}
      />
    )
  }
  const detailsPlaceHolder =
    '例:\n' +
    'こちらは仙台支店での作業内容です。\n' +
    '同日同時刻の盛岡営業所、郡山営業所の作業内容はそれぞれの記載を確認してください。\n' +
    '・現行ルータ撤去および新規ルータ設置、試験(機器のログ取得) \n' +
    '・翌営業日立ち会い、トラブルの場合は旧ルータへの切り戻し対応 \n' +
    '・成果物の作成（簡単なラック図、フロア図）\n' +
    'ログ取得作業においてteratermを使用します。\n' +
    'teratermでの作業実績がある方希望。\n' +
    '翌営業日立ち会いはお客様先付近での待機、\n' +
    'お客様より対応依頼あり次第の入館となります。'

  return (
    <StyledJobCreateUpdateForm>
      <Grid container pb={6}>
        <Heading>{isUpdate ? '作業編集' : '作業登録'}</Heading>
      </Grid>
      <form onSubmit={handleSubmit(handleSubmitJobForm)} className="form">
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {getAutoCompleteField('クライアント', 'client_id', '選択してください', activeClientList, false, false)}
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {getAutoCompleteField('案件', 'project_id', '選択ください', projectList, projectDisabled, false)}
            {getAutoCompleteField('作業グループ', 'job_group', '作業グループ', jobGroupList, jobGroupDisabled, false)}
            {getInputLabelField('作業名', 'name', '例: 無線LAN設定', true)}
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {getAutoCompleteField(
              '作業担当者',
              'client_user_id',
              '選択してください',
              clientUserList,
              clientUserDisabled,
              false
            )}
            {getAutoCompleteField('ワーカー', 'worker', '選択してください', workerList, false, false)}
            <InputDatePicker
              id={'date-ID'}
              ref={register('date', validationRules.date)}
              error={errors && errors.date ? true : false}
              errorMsg={errors ? errors.date?.message : null}
              name="date"
              label="作業日"
              placeholder="例: 2022/03/25"
              inputFormat={dateFormat}
              required
              color="primary"
              {...(formData && formData['date'] && { value: formData['date'] })}
              {...defaultInputProps}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} md={6}>
            {getInputLabelField('作業開始時刻', 'start_time', '例: 9:00', true)}
          </Grid>
          <Grid item xs={12} md={6}>
            {getInputLabelField('作業終了時刻', 'end_time', '例: 28:00', true)}
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item pt="16px" xs={12} md={6}>
            {/* <TimePicker onChange={onChange} value={value} /> */}
            {getInputLabelField('休憩時間', 'break_time', '例: 12：00～13：00', true)}
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} md={6}>
            {getInputLabelField('郵便番号', 'zip_code', '例: 9820011', true)}
          </Grid>
          <Grid item container xs={12} md={6} pb={{ xs: 3, md: 0 }} alignItems={'center'}>
            <LargeButton
              variant="outlined"
              color="green"
              mode="light"
              onClick={handleGetAddress}
              mb={typeof errors.zip_code !== 'undefined' ? 25 : 0}
            >
              {requestAddress && <CircularProgress size="1.5rem" color="inherit" />}
              {!requestAddress && '郵便番号から住所を検索'}
            </LargeButton>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} md={6}>
            {getInputLabelField('都道府県', 'prefecture', '', true, true)}
          </Grid>
          <Grid item xs={12} md={6}>
            {getInputLabelField('市町村区', 'municipality', '', true, true)}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {getInputLabelField('町名番地', 'address_line_1', '例: 長町６−１３−２', true)}
          </Grid>
          <Grid item xs={12}>
            {getInputLabelField('以降の住所', 'address_line_2', '例: フリシャー仙台ビル１０４')}
          </Grid>
          <Grid item xs={12}>
            {getInputRichTextEditor('作業内容', 'description', detailsPlaceHolder, 265)}
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} md={6} pb={1}>
            <Label fontSize={15} color="black" className="LabelInfoText">
              作業費(税抜き) <span>*</span>
            </Label>
          </Grid>
        </Grid>
        <Grid container columnSpacing={{ xs: 0, md: 4 }}>
          <Grid item container xs={12} md={6} alignItems="center" pb={4}>
            <Grid item xs={0.8} md={0.4}>
              <Label>¥ &nbsp;</Label>
            </Grid>
            <Grid item xs={11.2} md={11.6}>
              {getInputLabelField(null, 'salary', '例: 15,000', true)}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {getInputLabelField(
              '手当',
              'allowance',
              '例: 現地拘束8時間以上、30分あたり1,000円（税抜き）、リーダー手当など',
              true
            )}
            {getInputLabelField('交通費', 'toll_fee', '例: 往復60km以上1kmあたり20円（税込み）', true)}
            {getInputLabelField('高速代', 'highway_fee', '例: 領収書に基づいて実費精算（税込み）', true)}
            {getInputLabelField('駐車場代', 'parking_fee', '例: 領収書に基づいて実費精算（税込み）', true)}
            {getInputLabelField('宿泊費', 'overnight_stay_fee', '例: 領収書に基づいて実費精算（税込み）', true)}
            {getInputLabelField('その他経費', 'other_fee', '例: 領収書に基づいて実費精算（税込み）', true)}
          </Grid>
        </Grid>
        <Grid container>
          {jobCheckboxOptions &&
            jobCheckboxOptions.map((value, index) => (
              <Grid item xs={12} key={index}>
                <Grid item xs={12} key={index} className="JobCheckbox-Wrapper">
                  {getInputMultiCheckbox(
                    value?.name,
                    value.type,
                    value?.options,
                    '',
                    value?.type === 'goods_sent_to_worker_chk' ? false : true
                  )}
                </Grid>
                {value?.type === 'goods_supplied_chk' ? (
                  <Grid pt={0} item xs={12}>
                    {getInputLabelField(
                      null,
                      'provided_to_worker_other',
                      'その他の事前支給品がある場合はここに入力してください',
                      isProvidedToWorkerOther,
                      !isProvidedToWorkerOther
                    )}
                  </Grid>
                ) : (
                  ''
                )}
                {value?.type === 'goods_sent_to_worker_chk' ? (
                  <Grid pt={0} item xs={12}>
                    {getInputLabelField(
                      null,
                      'items_provided_description',
                      '作業に使用する部材がある場合はここに入力してください',
                      isItemsProvidedDescription,
                      !isItemsProvidedDescription
                    )}
                  </Grid>
                ) : (
                  ''
                )}
                {value?.type === 'goods_worker_bring_chk' ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {getInputTextAreaField('', '', 'worker_tool_notes', '備考', 126)}
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            ))}
        </Grid>
        <Grid container columnSpacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} className="BankAccount-Wrapper">
            {getInputLabelField('服装', 'attire', 'スーツ', true)}
            <Caption>例: 上下スーツ、作業着、スニーカー可、クールビズ可、マスク着用</Caption>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className="Destination-Wrapper">
            {getInputLabelField(
              'エスカレーション先 ',
              'escalation_contact',
              '例: ○○株式会社の担当●●、電話番号は090-1111-1111',
              true
            )}
          </Grid>
          <Grid item xs={12} className="Cancellation-Wrapper">
            <Label fontSize={15} color="black" className="LabelInfoText">
              キャンセル費 <span>*</span>
            </Label>
            <LabelDisplay md={-17} type="info" className="LabelInfo">
              {'・1週間前キャンセル、作業費20%支払い'} <br />
              {'・1〜3日前キャンセル、作業費50%支払い'} <br />
              {'・当日キャンセル、作業費100%支払い'}
            </LabelDisplay>
            {getInputCheckbox(
              '上記キャンセル費を承諾する',
              'invoice_generated',
              formData ? Boolean(formData?.invoice_generated) : false,
              false,
              true
            )}
            {!isCancelationFee && Object.keys(errors).length > 0 && (
              <FormHelperText className="Cancellation-Note">
                上記キャンセル費を承諾する、にチェックを入れてください。
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} className="Destination-Wrapper">
            {getInputLabelField(
              'お客様現地ご担当者様連絡先',
              'onsite_client_contact',
              '例: ○○株式会社の担当●●、電話番号は090-1111-1111',
              false
            )}
            {getInputLabelField('名乗り', 'onsite_name', '例: 「○○株式会社の●●です」', false)}
            {getInputLabelField('当日同行者', 'fellow', '例: 弊社より作業リーダーが同行します', false)}
            {getInputTextAreaField(
              'その他連絡事項',
              '',
              'remarks',
              '例: 作業1週間前からの検温結果、事前に作成する身分証明書'
            )}
            <Label fontSize={15} color="black">
              各種連絡
            </Label>
            <Label fontSize={12}>
              必要な連絡にチェックを入れ、担当者以外に追加で必要な宛先があれば それぞれ入力してください。
              <br />
              複数の宛先を設定する場合は、メールアドレスをカンマ（,）で区切って入力してください。
              <br />
              ＊本作業の担当者には登録しなくてもメールが届きます。
            </Label>
            <Grid container pt={2} spacing={{ xs: 0, md: 4 }}>
              <Grid item xs={6} md={3}>
                {getInputCheckbox('前日連絡', 'is_previous_contact_enabled', isOneDayBeforeRecipients, false)}
              </Grid>
              <Grid item xs={12} md={9}>
                {getInputLabelField(
                  null,
                  'one_day_before_recipients',
                  '例: tanaka@example.com, yamada@example.com',
                  isOneDayBeforeRecipients
                )}
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, md: 4 }} pt={1}>
              <Grid item xs={6} md={3}>
                {getInputCheckbox('出発連絡', 'is_departure_contact_enabled', isDepartureRecipients, false)}
              </Grid>
              <Grid item xs={12} md={9}>
                {getInputLabelField(
                  null,
                  'departure_recipients',
                  '例: tanaka@example.com, yamada@example.com',
                  isDepartureRecipients
                )}
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, md: 4 }} pt={1}>
              <Grid item xs={6} md={3}>
                {getInputCheckbox('現着連絡', 'is_arrival_contact_enabled', isArrivalRecipients, false)}
              </Grid>
              <Grid item xs={12} md={9}>
                {getInputLabelField(
                  null,
                  'arrival_recipients',
                  '例: tanaka@example.com, yamada@example.com',
                  isArrivalRecipients
                )}
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, md: 4 }} pt={1}>
              <Grid item xs={6} md={3}>
                {getInputCheckbox('入館連絡', 'is_clockin_contact_enabled', true, true)}
              </Grid>
              <Grid item xs={12} md={9}>
                {getInputLabelField(null, 'clock_in_recipients', '例: tanaka@example.com, yamada@example.com', true)}
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, md: 4 }} pt={1}>
              <Grid item xs={6} md={3}>
                {getInputCheckbox('退館連絡', 'is_clockout_contact_enabled', true, true)}
              </Grid>
              <Grid item xs={12} md={9}>
                {getInputLabelField(null, 'clock_out_recipients', '例: tanaka@example.com, yamada@example.com', true)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container pt={4} justifyContent="end">
          <Grid item xs={12} sm={4} md={3} lg={2} textAlign="end">
            <LargeButton id="WorkerSubmitBtn" type="submit">
              {isSubmit && <CircularProgress size="1.5rem" color="white" />}
              {!isSubmit && '確認'}
            </LargeButton>
          </Grid>
        </Grid>
      </form>
    </StyledJobCreateUpdateForm>
  )
}

JobCreateUpdateForm.displayName = 'Job Create/Update Form'

JobCreateUpdateForm.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
}

export default JobCreateUpdateForm
