import React from 'react'
import { Accordion as MUIAccordion, AccordionSummary, AccordionDetails } from '@mui/material'
import PropTypes from 'prop-types'
import { Label } from 'components'
import { styled } from '@mui/material/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const StyledMUIAccordion = styled(MUIAccordion, {
  shouldForwardProp: (prop) => !['labelAlign', 'label'].includes(prop),
})(({ labelAlign }) => ({
  '& .MuiAccordion-Tile .MuiAccordionSummary-content': {
    justifyContent: labelAlign ?? 'unset',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0)',
    '& .MuiSvgIcon-root': {
      transform: 'rotate(90deg)',
    },
  },
}))

const Accordion = (props) => {
  return (
    <StyledMUIAccordion {...props}>
      <AccordionSummary expandIcon={<ChevronRightIcon id="AccordionExpandIcon-ID" />} className="MuiAccordion-Tile">
        <Label pr={1}>{props.label}</Label>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </StyledMUIAccordion>
  )
}

Accordion.defaultProps = {
  labelAlign: 'left',
}

Accordion.propTypes = {
  labelAlign: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
}

export default Accordion
