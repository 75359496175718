import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { LargeButton, Label } from 'components'

function ExpenseConfirmation(props) {
  const { onClose, onConfirm, action, isRequesting } = props
  const { is_modal_loading: isModalLoading } = useSelector((state) => state.jobs)
  const isSubmitExpense = action === 'submit'
  const isDeleteExpense = action === 'delete'

  return (
    <>
      {isModalLoading && (
        <Grid xs={12} item textAlign="center" py={6}>
          <CircularProgress />
        </Grid>
      )}
      {!isModalLoading && (
        <Grid container justifyContent="center">
          <Grid item xs={12} textAlign="center">
            <Label>
              {(isSubmitExpense && 'この内容で経費報告しますか？') ||
                (isDeleteExpense && '削除してもよろしいでしょうか？')}
            </Label>
          </Grid>
          <Grid item container xs={12} justifyContent="center" textAlign="center" gap={2} pt={4}>
            <LargeButton variant="contained" color="gray" onClick={onClose} disabled={isRequesting}>
              {'キャンセル'}
            </LargeButton>
            <LargeButton variant="contained" onClick={onConfirm} disabled={isRequesting}>
              {'はい'}
            </LargeButton>
          </Grid>
        </Grid>
      )}
    </>
  )
}

ExpenseConfirmation.propTypes = {
  onClose: PropTypes.func,
  completed: PropTypes.func,
  onConfirm: PropTypes.func,
  action: PropTypes.string,
  isRequesting: PropTypes.bool,
}

export default ExpenseConfirmation
