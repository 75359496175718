import * as dayjs from 'dayjs'

export const datePresets = [
  {
    id: 1,
    name: '今月', //This Month
  },
  {
    id: 2,
    name: '先月', //Prev Month
  },
  {
    id: 3,
    name: '来月', //Next Month
  },
  {
    id: 4,
    name: '今日', //Today
  },
  {
    id: 5,
    name: 'カスタム', //Custom
  },
]

export const settings = [
  {
    id: '公開',
    name: '公開',
  },
  {
    id: '限定公開',
    name: '限定公開',
  },
  {
    id: '編集中',
    name: '編集中',
  },
]
export const jobCheckboxOptions = [
  {
    id: 1,
    type: 'goods_supplied_chk',
    name: '事前支給品',
    options: [
      { id: '手順書', name: '手順書', isDisabled: true },
      { id: 'チェックシート', name: 'チェックシート', isDisabled: false },
      { id: '報告書', name: '報告書', isChecked: false, isDisabled: false },
      { id: 'その他', name: 'その他', isChecked: false, isDisabled: false },
    ],
  },
  {
    id: 2,
    type: 'goods_sent_to_worker_chk',
    name: '事前送付品',
    options: [
      { id: '作業対象機器', name: '作業対象機器' },
      { id: '紙媒体手順書', name: '紙媒体手順書' },
      { id: '紙媒体チェックシート', name: '紙媒体チェックシート' },
      { id: '紙媒体報告書', name: '紙媒体報告書' },
      { id: '作業に使用する部材', name: '作業に使用する部材' },
    ],
  },
  {
    id: 3,
    type: 'goods_worker_bring_chk',
    name: 'ワーカー持参物 ',
    options: [
      { id: '筆記用具', name: '筆記用具', isDisabled: true },
      { id: '持参支給品', name: '持参支給品' },
      { id: '事前送付品', name: '事前送付品' },
      { id: 'ドライバー・カッター等基本工具', name: 'ドライバー・カッター等基本工具' },
      { id: 'ビニールタイ', name: 'ビニールタイ' },
      { id: 'コンソールケーブル', name: 'コンソールケーブル' },
      { id: '作業用ノートPC', name: '作業用ノートPC' },
    ],
  },
]

export const dateFormat = 'YYYY/MM/DD'
export const timeFormat = 'HH:mm'

export const thisDay = dayjs().format(dateFormat)
export const thisMonthStart = dayjs().startOf('month').format(dateFormat)
export const thisMonthEnd = dayjs().endOf('month').format(dateFormat)
export const prevMonthStart = dayjs().subtract(1, 'month').startOf('month').format(dateFormat)
export const prevMonthEnd = dayjs().subtract(1, 'month').endOf('month').format(dateFormat)
export const nextMonthStart = dayjs().add(1, 'month').startOf('month').format(dateFormat)
export const nextMonthEnd = dayjs().add(1, 'month').endOf('month').format(dateFormat)
export const minBirthDate = dayjs(thisDay).subtract(18, 'year').format(dateFormat)

export function downloadFile(filename, data) {
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')
  link.download = filename
  link.href = url
  link.click()
}

export const tabs = ['作業詳細', '実績', '経費']
export const tabIndex = {
  details: 0,
  reports: 1,
  expense: 2,
}

export const clientUsersAcctStatus = [
  {
    id: 0,
    name: 'すべて',
  },
  {
    id: 1,
    name: '有効',
  },
  {
    id: 2,
    name: '無効',
  },
]

export const tabWorkerJobsIndex = {
  jobs_offer: 0,
  jobs_waiting_actual_report: 1,
  jobs_waiting_expense_report: 2,
}

export const systemInfo = {
  release_date: process.env.REACT_APP_PHASE2_RELEASE_DATE,
}
