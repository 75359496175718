import React, { Fragment } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { Heading, LargeButton, ContentDisplay } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import { formatAddress } from 'utils/helper'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { setClientUserFormData, resetCurrentForm } from 'store/client-user/actionCreators'
import AddIcon from '@mui/icons-material/Add'

const ClientDetailsForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector((state) => state.loader)
  const { displayData } = props

  const handleEdit = () => {
    const clientUpdatePage = '/client/update/' + displayData?.id
    dispatch(resetCurrentForm())
    history.push(clientUpdatePage)
  }

  const handleAddPic = () => {
    const clientUserCreatePage = '/client-user/create'
    dispatch(resetCurrentForm())
    const newData = {
      ...displayData,
      client: {
        id: displayData?.id,
        name: displayData?.name,
      },
      name: '',
      phone_number: '',
      email: '',
    }
    dispatch(setClientUserFormData({ current: newData }))
    history.push(clientUserCreatePage)
  }

  return (
    <Fragment>
      <Grid container>
        {isLoading && (
          <Grid xs={12} item textAlign="center" py={32}>
            <CircularProgress />
          </Grid>
        )}
        {!isLoading && (
          <Grid item xs={12}>
            <Grid item container xs={12} alignItems="center" mb={3}>
              <Grid item container xs={6} justifyContent="flex-start" alignItems="flex-start">
                <Heading>{'クライアント詳細'}</Heading>
              </Grid>
              <Grid item container xs={6} direction="column" justifyContent="center" alignItems="flex-end">
                <Grid item mt={1}>
                  <LargeButton
                    id="editBtn"
                    color="primary"
                    mode="light"
                    width={255}
                    maxwidth="unset"
                    onClick={handleEdit}
                  >
                    {'編集'}
                  </LargeButton>
                </Grid>
                <Grid item mt={1}>
                  <LargeButton
                    id="addPicBtn"
                    color="primary"
                    mode="light"
                    width={255}
                    maxwidth="unset"
                    startIcon={<AddIcon size={'sm'} />}
                    onClick={handleAddPic}
                  >
                    {'クライアント担当者新規登録'}
                  </LargeButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-start" mb={3}>
              <ContentDisplay label="法人名:" data={displayData?.name} />
              <ContentDisplay label="法人住所:" data={formatAddress(displayData)} />
              <ContentDisplay label="法人サイト:" data={displayData?.website} />
              <ContentDisplay label="法人代表電話番号:" data={displayData?.phone_number} />
              <ContentDisplay label="法人メールアドレス:" data={displayData?.email} />
              <ContentDisplay label="内部メモ:" data={displayData?.internal_memo} />
              <ContentDisplay label="登録日:" data={displayData?.create_date} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}

ClientDetailsForm.propTypes = {
  displayData: PropTypes.any,
}

export default ClientDetailsForm
