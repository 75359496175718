import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Search as SearchIcon } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { getAllStatusList } from 'services/common'
import { getStatusByType } from 'utils/helper'
import { useForm } from 'react-hook-form'
import * as dayjs from 'dayjs'
import { getMessages } from 'validation'
import { jobListRequiredStatusId, jobListDefaultStatusId } from 'utils/config/status'
import {
  datePresets,
  thisDay,
  thisMonthStart,
  thisMonthEnd,
  prevMonthStart,
  prevMonthEnd,
  nextMonthStart,
  nextMonthEnd,
} from 'utils/common'
import {
  TableList,
  Heading,
  InputLabel,
  InputSelect,
  InputDatePicker,
  InputMultiSelect,
  LargeButton,
  LabelDisplay,
  CircularProgress,
} from 'components'

const StyledJobListForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .totalFeeDisplay': {
    [theme.breakpoints.down(620)]: {
      marginBottom: 'unset !important',
    },
  },
}))

function JobListForm(props) {
  const rulesMsg = getMessages('ja')
  const { isLoading } = props
  const [datePresetValue, setdatePresetValue] = useState(1)
  const [dateFromValue, setDateFromValue] = useState(thisMonthStart)
  const [dateToValue, setDateToValue] = useState(thisMonthEnd)
  const totalFeeTax = useSelector((state) => state.jobs.totalFeeTax) ?? ''
  const rawStatusOptions = useSelector((state) => state.common.statusList) ?? []
  const searchParameters = useSelector((state) => state.jobs.search)
  const isStatusFiltered = useSelector((state) => state.jobs.is_status_filtered)
  const jobGroupStatusList = getStatusByType(rawStatusOptions, 'Job Group', jobListDefaultStatusId)
  const jobStatusList = getStatusByType(rawStatusOptions, 'Job', jobListRequiredStatusId)
  const dateFormat = 'YYYY/MM/DD'
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    dispatch(getAllStatusList())
  }, [])

  useEffect(() => {
    if (!searchParameters.start_date && !searchParameters.end_date) {
      setdatePresetValue(null)
      setDateFromValue(searchParameters.start_date)
      setDateToValue(searchParameters.end_date)
    }
  }, [!searchParameters.start_date, !searchParameters.end_date])

  const validationRules = {
    keyword: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    area: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    start_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
    end_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
  }

  const handleDatePreset = (item) => {
    switch (item) {
      case 2: //prev month
        setDateFromValue(prevMonthStart)
        setDateToValue(prevMonthEnd)
        break
      case 3: //next month
        setDateFromValue(nextMonthStart)
        setDateToValue(nextMonthEnd)
        break
      case 4: //this day
        setDateFromValue(thisDay)
        setDateToValue(thisDay)
        break
      case 1: //this month
        setDateFromValue(thisMonthStart)
        setDateToValue(thisMonthEnd)
        break
      default:
        break
    }

    setdatePresetValue(item)
  }

  const onDateChange = (newVal, type) => {
    setdatePresetValue(5)
    if (type == 'start_date') {
      setDateFromValue(newVal)
      if (dayjs(newVal).diff(dayjs(dateToValue)) >= 1) {
        setDateToValue(newVal)
      }
    } else {
      setDateToValue(newVal)
      if (dayjs(dateFromValue).diff(dayjs(newVal)) >= 1) {
        setDateFromValue(newVal)
      }
    }
  }

  const handleFilter = (data) => {
    props.handlePageSearch(data)
  }

  const totalFeeContent = props.isLoading ? <CircularProgress /> : `作業費合計: ${totalFeeTax}（税込み）`

  // Table Actions
  const actionList = [
    {
      name: '/job/show',
      color: 'success',
      mode: 'light',
      label: '詳細',
      height: 24,
    },
  ]
  return (
    <StyledJobListForm container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Heading>作業一覧</Heading>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} mt={{ xs: 2, sm: 4 }}>
        <form onSubmit={handleSubmit(handleFilter)}>
          <Grid item xs={12} sm={12} md={12} lg={12} />
          <Grid container columnSpacing={1}>
            <Grid item xs={12} sm={12} md={9} lg={7.5}>
              <InputLabel
                id="WorkSearchBox"
                name="keyword"
                ref={register('keyword', validationRules.keyword)}
                error={errors && errors.keyword ? true : false}
                errorMsg={errors ? errors?.keyword?.message : null}
                label="キーワード"
                placeholder="作業名、クライアント名を検索"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <InputLabel
                id="area-InputLabel-ID"
                name="area"
                ref={register('area', validationRules.area)}
                error={errors && errors.area ? true : false}
                errorMsg={errors ? errors?.area?.message : null}
                label="エリア"
                placeholder='例: "東京都" "渋谷区"'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid item container columnSpacing={1} rowSpacing={1} alignItems={'start'}>
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <InputSelect
                    name="date_preset"
                    label="作業日"
                    menuItems={datePresets}
                    value={datePresetValue}
                    onChange={handleDatePreset}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={1.5}>
                  <InputDatePicker
                    id="workList-start_date-ID"
                    ref={register('start_date', validationRules.start_date)}
                    error={errors && errors.start_date ? true : false}
                    errorMsg={errors ? errors?.start_date?.message : null}
                    name="start_date"
                    label={'開始日'}
                    color="primary"
                    inputFormat={dateFormat}
                    value={dateFromValue ? dayjs(dateFromValue) : ''}
                    onChange={(newVal) => onDateChange(newVal, 'start_date')}
                    placeholder={thisMonthStart}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={1.5}>
                  <InputDatePicker
                    id="workList-end_date-ID"
                    ref={register('end_date', validationRules.end_date)}
                    error={errors && errors.end_date ? true : false}
                    errorMsg={errors ? errors?.end_date?.message : null}
                    name="end_date"
                    label={'終了日'}
                    color="primary"
                    inputFormat={dateFormat}
                    value={dateToValue ? dayjs(dateToValue) : ''}
                    onChange={(newVal) => onDateChange(newVal, 'end_date')}
                    placeholder={thisMonthEnd}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={3}>
                  <InputMultiSelect
                    ref={register('status_id')}
                    name="status_id"
                    label={'ステータス'}
                    menuItems={[...jobGroupStatusList, ...jobStatusList] ?? []}
                    defaultValue={
                      isStatusFiltered ? [parseInt(searchParameters?.status_id)] : jobListDefaultStatusId ?? []
                    }
                    enableEmpty
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} mt={{ xs: 0, md: 3.5 }}>
                  <LargeButton
                    type="submit"
                    id="SearchBtn-ID"
                    bold="true"
                    color="warning"
                    startIcon={
                      <SearchIcon
                        size={'sm'}
                        disabled={props.isLoading ? true : false}
                        style={{ color: 'rgb(255 107 9 / 55%)' }}
                      />
                    }
                  >
                    検索
                  </LargeButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
        <LabelDisplay mb={-17} className={'totalFeeDisplay'} type={isLoading ? '' : 'info'}>
          {totalFeeContent}
        </LabelDisplay>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} mt={{ xs: 2, md: 0 }}>
        <TableList
          title={`Work List`}
          headCells={props.headerCells}
          data={props.data}
          totalPage={props.totalPage}
          totalCount={props.totalCount}
          handlePageChange={props.handlePageChange}
          isLoading={props.isLoading}
          handleSort={props.handleSort}
          sort={props.sort}
          sortBy={props.sortBy}
          currentPage={props.currentPage}
          pageFrom={props.pageFrom}
          pageTo={props.pageTo}
          action={true}
          actionButton={true}
          actionList={actionList}
        />
      </Grid>
    </StyledJobListForm>
  )
}

JobListForm.displayName = 'Work List Form'

JobListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

export default JobListForm
