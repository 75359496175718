import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import InformationIcon from 'assets/svg/InformationIcon.svg'
import { Icon, Label } from 'components'
import omit from 'lodash/omit'

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop,
})(({ theme, bgc, color, bold, display, height, alignItems, width, mt, mb, mr, ml }) => ({
  backgroundColor: `${theme.palette.background[bgc]}`,
  borderRadius: `${theme.standards.border.box}`,
  color: `${theme.palette.text[color]}`,
  fontWeight: bold ? 'bold' : 'normal',
  display,
  height,
  alignItems,
  padding: 15,
  width,
  marginTop: mt,
  marginBottom: mb,
  marginRight: mr,
  marginLeft: ml,
  '& .InfoText': {
    [theme.breakpoints.down('lg')]: {
      maxWidth: '90%',
    },
  },
}))

const handleIcon = (type) => {
  switch (type) {
    case 'info': {
      return InformationIcon
    }
    default: {
      return
    }
  }
}

function LabelDisplay(props) {
  const excludedProps = ['alignItems']
  const defaultProps = omit(props, excludedProps)
  const { alignItems, justifyContent, children, type } = props
  return (
    <StyledBox {...defaultProps}>
      <Grid container columnSpacing={2} alignItems={alignItems} justifyContent={justifyContent}>
        {type && (
          <Grid item>
            <Icon mt={4} source={handleIcon(type)} />
          </Grid>
        )}
        <Grid item className="InfoText">
          <Label color="secondary">{children}</Label>
        </Grid>
      </Grid>
    </StyledBox>
  )
}

LabelDisplay.displayName = 'Label Display'

LabelDisplay.defaultProps = {
  display: 'flex',
  bgc: 'primary',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'fit-content',
  color: 'secondary',
  bold: true,
  width: 'fit-content',
}

LabelDisplay.propTypes = {
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
}

export default LabelDisplay
