import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Label, Link } from 'components'
import { IconButton, Typography, Menu, MenuItem, Divider } from '@mui/material'
import { AccountCircle as AccountCircleIcon, ArrowRight as ArrowRightIcon } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import initial from 'lodash/initial'

const StyledHeader = styled('div')(() => ({
  '& .menuArrowDown': {
    transform: 'rotate(90deg)',
  },
  '& .menuArrowSide': {
    transform: 'unset',
  },
  '& :hover': {
    cursor: 'pointer',
  },
}))

function ProfileMenu(props) {
  const { onSignOut, onUserOpen, onUserClose, onNavClose, profileItems, userAnchor, menuOpen } = props
  const { user } = useSelector((state) => state.auth)
  const name = user?.name ? user.name.split(' ') : user?.email_address
  const profileName = user?.name ? `${name.at(-1)} ${initial(name).join(' ')} 様` : name
  const isOPC = localStorage.getItem('mode') === 'opc'

  let links = []

  switch (localStorage.getItem('mode')) {
    case 'wkr': {
      links = profileItems.some((obj) => 'type' in obj && obj.type === user?.member_type)
        ? profileItems
        : profileItems.filter(({ name }) => !['ワーカー情報編集'].includes(name))
      break
    }
    default: {
      links = profileItems
      break
    }
  }

  return (
    <StyledHeader>
      <IconButton onClick={(e) => onUserOpen(e)} sx={{ p: 0 }} disableRipple={true} disableFocusRipple={true}>
        <AccountCircleIcon sx={{ color: 'black' }} />
        <Label
          bold="true"
          pl={1}
          pb={0.2}
          name="header"
          id="userDisplayName-ID"
          sx={isOPC ? { display: { xs: 'inherit' } } : { display: { xs: 'none', md: 'inherit' } }}
        >
          {profileName}
        </Label>
        <ArrowRightIcon
          className={menuOpen ? 'menuArrowDown' : 'menuArrowSide'}
          sx={
            isOPC
              ? { color: 'black', display: { xs: 'inherit' } }
              : { color: 'black', display: { xs: 'none', md: 'inherit' } }
          }
        />
      </IconButton>
      <Menu
        disableScrollLock={true}
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={userAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(userAnchor)}
        onClose={() => onUserClose()}
      >
        {profileItems.length > 0 &&
          links.map(({ name, link }, index) => (
            <Link to={link} key={`${name}-${index}`}>
              <MenuItem id={`${name}-${index}`} onClick={() => onNavClose()}>
                <Typography textAlign="center">{name}</Typography>
              </MenuItem>
            </Link>
          ))}
        <Divider />
        <MenuItem id="LogOutBtn" onClick={() => onSignOut()}>
          {'ログアウト'}
        </MenuItem>
      </Menu>
    </StyledHeader>
  )
}

ProfileMenu.propTypes = {
  onSignOut: PropTypes.func,
  onUserOpen: PropTypes.func,
  onUserClose: PropTypes.func,
  onNavClose: PropTypes.func,
  profileItems: PropTypes.array,
  userAnchor: PropTypes.any,
  menuOpen: PropTypes.bool,
}

export default ProfileMenu
