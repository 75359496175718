export const toolTipTitle = `「編集中」： 基本の状態。 ワーカーからは閲覧・検索できない状態です。\n
「公開」： ワーカーから閲覧・検索できる状態です。ワーカーからの応募を受け付けます。 登録内容に修正が必要になった場合は、編集中に戻すこともできます。\n
「限定公開」： システム内の通常の方法では、ワーカーからは閲覧・検索できない状態です。 作業グループ詳細画面で、「応募urlをコピー」機能が使用できるようになります。 特 定のワーカーだけに応募して欲しい場合など、応募urlをメールなどで直接通知することで、 不特定多数のワーカーに知られずに作業依頼を相談することができます。\n
 登録内容に修正が必要になった場合は、編集中に戻すこともできます。 公開設定が「編集中」の場合、 作業グループのステータスは「編集中」となります。 公開設定が「公開」か 「限定公開」の場合、 作業グループのステータスは「募集中」となります。`

export const tableOfContents = [
  {
    chapter: '第1章　総則',
    section: [
      { id: 1, title: '第1条　総則' },
      { id: 2, title: '第2条　定義' },
      { id: 3, title: '第3条　本利用規約の変更' },
    ],
  },
  {
    chapter: '第2章　会員',
    section: [
      { id: 4, title: '第4条　会員登録' },
      { id: 5, title: '第5条　会員資格' },
      { id: 6, title: '第6条　会員登録の取消等・退会' },
    ],
  },
  {
    chapter: '第3章　本サービス',
    section: [
      { id: 7, title: '第7条　本サービスの内容について' },
      { id: 8, title: '第8条　本サービスの利用について' },
      { id: 9, title: '第9条　本サービス水準等の非保証等' },
      { id: 10, title: '第10条　本サービスの利用料' },
      { id: 11, title: '第11条　システム利用手数料の支払方法' },
      { id: 12, title: '第12条　銀行口座への入金に関する事項' },
    ],
  },
  {
    chapter: '第4章　会員間の取引',
    section: [
      { id: 13, title: '第13条　業務委託契約' },
      { id: 14, title: '第14条　取引における法令遵守' },
      { id: 15, title: '第15条　取引における秘密保持' },
      { id: 16, title: '第16条　会員間の連絡' },
    ],
  },
  {
    chapter: '第5章　ユーザーの責任',
    section: [
      { id: 17, title: '第17条　利用環境の整備' },
      { id: 18, title: '第18条　自己責任の原則' },
      { id: 19, title: '第19条　ID・パスワードの管理' },
      { id: 20, title: '第20条　地位等の譲渡禁止' },
      { id: 21, title: '第21条　禁止事項' },
    ],
  },
  {
    chapter: '第6章　本サービスの運営・免責等',
    section: [
      { id: 22, title: '第22条　個人情報の取り扱い' },
      { id: 23, title: '第23条　弊社提供サービスに関する知的財産権' },
      { id: 24, title: '第24条　監視業務' },
      { id: 25, title: '第25条　規約違反への対処及び違約金等' },
      { id: 26, title: '第26条　弊社からの連絡又は通知' },
      { id: 27, title: '第27条　サイトの中断・停止・終了' },
      { id: 28, title: '第28条　免責' },
      { id: 29, title: '第29条　本サービスの譲渡等' },
    ],
  },
  {
    chapter: '第7章　その他',
    section: [
      { id: 30, title: '第30条　反社会的勢力の排除' },
      { id: 31, title: '第31条　準拠法・分離可能性・管轄裁判所' },
    ],
  },
]

export const sectionDetails = [
  {
    id: 'section-1',
    chapter: '第1章　総則',
    title: '第1条　総則',
    isSubcontent: false,
    contentDetails: [
      {
        content:
          '本利用規約は、株式会社佐々通オンサイト（以下「弊社」といいます。）が提供するインターネットサイト「ワークオンサイト！」（以下「本サイト」といいます。）を利用する個人及び法人（以下「ユーザー」といいます。）が遵守すべき利用条件を定めるものです。',
        subContent: [],
      },
      {
        content:
          '本利用規約は、本サイトの利用に関して生ずるすべての関係に適用されるものとし、会員及びユーザーは、本規約を熟読し、本規約の内容を十分に理解した上でこれに同意して、本サイトを利用するものとします。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-2',
    title: '第2条　定義',
    info: '本規約の中で使用される以下の各用語は、次の通りとします。',
    contentDetails: [
      {
        content:
          '「会員」　本規約に同意し、本サイトを利用するために、所定の入会登録を行い、弊社から入会登録の承諾を受けた個人及び法人 ',
        subContent: [],
      },
      {
        content: '「ユーザー」　会員、会員登録の申込者及び本サイトの閲覧者など、本サイトを利用する個人及び法人。',
        subContent: [],
      },
      {
        content:
          '「本サービス」　本サイトにおいて、又は本サイトを使用して弊社が会員又はユーザーに対し提供する一切のサービス',
        subContent: [],
      },
      {
        content: '「クライアント」　依頼を希望する業務を本サイト上に登録して受託者を募集し、発注する会員',
        subContent: [],
      },
      {
        content: '「ワーカー」　本サイトに登録された業務に対して応募を行い、受託をする会員',
        subContent: [],
      },
      {
        content:
          '「会員連絡先情報」　会員の住所（法人の場合所在地）、氏名若しくは商号、連絡先（電話番号、電子メールアドレス）、又は連絡担当者の情報',
        subContent: [],
      },
      {
        content:
          '「本人確認登録」　本人確認・実在確認を目的に、希望するワーカー及び希望するクライアントに対して、本サイトへのデータアップロード等の手段により、「運転免許証」、各種「健康保険証」等を用いて本サイト内の登録情報に合致するか確認を行うことにより、本サービスの利用を行う者が本人であることの登録を行う機能',
        subContent: [],
      },
      {
        content:
          '「個人情報」　個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む）。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-3',
    title: '第3条　本利用規約の変更',
    contentDetails: [
      {
        content:
          '本規約は、弊社が提供する本サイトを利用するユーザーとの関係を定めるものとします。また、本規約は本サイトの利用に関して生ずる、ワーカーとクライアントの間、会員間等、すべての法律関係に適用されるものとします。また、弊社は、民法548条の4に基づき、弊社が合理的と判断した内容について、ユーザーの同意を得ることなく本規約を変更できるものとします。この場合には、変更後の規約の施行日から弊社と会員の間には変更後の規約に基づく権利義務関係が生じるものとします。規約の変更・追加によりユーザーに生じた一切の損害について、直接損害か間接損害か、予見できたか否かを問わず、弊社は一切の責任を負いません。変更後の規約は本サイト上に表示・告知した施行日より効力を生じるものとし、ユーザーは定期的に本規約の最新の内容を確認する義務を負うものとします。',
        subContent: [],
      },
      {
        content:
          '本規約中、ワーカーとクライアントの間の法律関係を定める規定については、同当事者間で別途合意がない限り、会員間の取引及び同当事者間の法律関係に適用されるものとします。',
        subContent: [],
      },
      {
        content:
          '本規約中、ワーカーとクライアントの間の法律関係を定める規定、及びワーカー又はクライアントと弊社、他の会員又は第三者との法律関係を定める規定については、当該ワーカー及びクライアントは、いずれも、消費者契約法第2条第2項にいう事業者の立場でこれに同意するものとします。 ',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-4',
    chapter: '第2章　会員',
    title: '第4条　会員登録',
    contentDetails: [
      {
        content:
          '会員登録手続を行うことができるのは、その会員となる本人に限るものとし、代理人による会員登録は認められないものとします。会員となろうとする者が法人の場合には、当該法人の従業員のうち、当該法人の社内規則及び決裁手続に基づき、当該法人を代表して対外的に契約を締結できる権限を付与された者に限るものとし、それら以外の代理人による会員登録は認められないものとします。',
        subContent: [],
      },
      {
        content:
          '弊社は、会員登録手続にあたり、会員となろうとする者及び特定の機能・サービス等を利用しようとする者に対して、身分証明書又は履歴事項全部証明書その他必要な書類の提出を求めることがあります。これらの書類をご提出いただけない場合又は弊社所定の審査の結果不適切と思われる場合には、会員登録や特定の機能・サービス等の利用をお断りすることがありますので、予めご了承ください。',
        subContent: [],
      },
      {
        content:
          '会員登録手続を行う者は、登録情報の入力にあたり、入力した情報は全て真実であることを保証するものとします。また、会員連絡先情報は必ず登録するものとします。',
        subContent: [],
      },
      {
        content:
          '登録した情報全てにつき、その内容の正確性・真実性・最新性等一切について、会員自らが責任を負うものとします',
        subContent: [],
      },
      {
        content:
          '登録内容の変更がある場合は、会員は直ちに登録内容を修正し、常に会員自身の正確な情報が登録されているよう、管理・修正する責任を負います。',
        subContent: [],
      },
      {
        content:
          '弊社は、会員の希望又は弊社の基準に基づき、別途弊社所定の本人確認を行う場合があります。この場合、会員は、以下各号の事項につき承諾し従うものとします。',
        subContent: [
          '本人確認が完了した会員については本人確認登録が行われるものとし、本人確認登録が完了しない会員は、本サービスの一部又は全部の利用について制約を受ける場合があること',
          '会員が本人確認を行う場合、弊社に対し、虚偽、偽造、変造又は誤認を与える資料を本人確認書類として提出しないこと',
          '本人確認登録後、本人確認時に会員が虚偽、偽造、変造又は誤解を与える資料を本人確認書類として提出した疑いがある場合、またはなりすましの疑いがある場合、その他弊社が必要と判断した場合は、再度、弊社が指定する証明書類の提出を求めること',
          '弊社が別途定める期日までに証明書類の再提出がなされない場合、会員に通知することなく、会員の本サービスの利用の停止又は会員資格の取消等を行うこと',
          '会員は、弊社が指定する証明書類の提出をするまで、本サービスの一部又は全部の利用について制約を受ける場合があること',
          '弊社が本条に基づき本サービスの一部又は全部の利用の制限、本サービスの利用を停止若しくは会員資格の取消等の措置を行ったことにより、会員が何らかの損害を被ったとしても、弊社は一切の責任を負わないこと ',
          '本人確認登録は、あくまで本人確認書類と本サイトへの登録情報との合致を確認するだけであり、弊社は、当該会員の存在、責任能力、業務遂行能力、連絡先情報の正確性、その他の能力の有無等を一切保証せず、何ら責任を負わないこと',
        ],
      },
    ],
  },
  {
    id: 'section-5',
    title: '第5条　会員資格 ',
    contentDetails: [
      {
        content:
          ' 会員として登録できる者の資格・条件は以下の通りです。但し、法人の場合、第1号の適用はなく、第2号から第5号までは、第4条第1項記載の従業員に適用されます。',
        subContent: [
          '満18才以上であること',
          '日本語を理解し、読み書き出来ること',
          '規約に同意すること',
          '日本において適法に就労するための要件を満たしていること',
          '己、または自己の役員、重要な地位の使用人これに準ずる顧問等、経営に実質的な影響力を有する株主等（以下「自己の役員等」という）が、暴力団、暴力団員、暴力団関係企業・ 団体又はその関係者、その他反社会的勢力（以下総称して「反社会的勢力」という）ではなく、過去５年以内に反社会的勢力に所属せず、これらのものと関係を有していないこと',
        ],
      },
      {
        content: '項のほか、以下に該当する場合、弊社は、会員登録の申込を承諾しないことができます。',
        subContent: [
          '第5条第1項各号に定める事由があると認められる場合',
          '本人以外の者による申込みの場合',
          '既に会員登録されている者による申込みの場合',
          '申込者が反社会的勢力等に該当する場合',
          '本サービスまたは弊社が運営する他のサービスにおいて、会員登録の拒絶または解約の措置等を受けたことがある場合',
          'その他弊社が会員登録を不適切と判断した場合',
        ],
      },
      {
        content:
          '会員は、2つ以上のアカウントを保有することができないものとします。但し、弊社が別途承認した場合はこの限りではありません。',
        subContent: [],
      },
      {
        content:
          '会員は、いかなる場合においても、アカウントを第三者に譲渡・販売・貸与・その他の処分をすることは出来ません。',
        subContent: [],
      },
      {
        content:
          '弊社は、会員として登録することを承諾しない場合、当該会員登録手続を行った者に対し、承諾しない理由を開示及び説明する義務を負わず、承諾しないことによってその者に生じる損害については一切責任を負いません。',
        subContent: [],
      },
      {
        content:
          '会員が本条の規定に違反したために被った損害について、直接損害か間接損害かを問わず、弊社は一切責任を負いません。会員は、会員が前各項の規定に違反したために第三者が被った損害がある場合、会員が当該第三者に責任を負うものとし、弊社は一切責任を負いません。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-6',
    title: '第6条　会員登録の取消等・退会 ',
    contentDetails: [
      {
        content:
          '第4条に基づく会員登録後であっても、会員について以下の各号の一に該当する事実が判明した場合には、会員登録の取り消し、本サービス利用の停止、その他会員としての権利の剥奪等、弊社が必要と判断する措置を行う場合があります。',
        subContent: [
          '入力された登録情報に虚偽の情報があることが判明した場合',
          '第4条に定める会員の資格・条件を満たしていないことが判明した場合、又は満たさなくなった場合',
          '法令又は本利用規約及び各種ガイドラインに違反する行為を行った場合、又は当該行為を行うおそれがあると認められる場合',
          '会員登録を行った当該個人又は法人が、弊社が提供する各種サービスにおいて、過去に弊社、他の会員又は第三者との間で何らかのトラブルを起こしていることが判明した場合',
          '他の会員や第三者との間で発生した争いが、弊社所定の水準を超えた場合 ',
          '他の会員や第三者から受ける苦情が、弊社所定の水準を超えた場合（当該会員について、他の会員や第三者から弊社が受ける苦情を含みます。）',
          '1年以内に1回以上のログインがなかった場合 ',
          '弊社から送付された電子メールを受領することができない場合、又は弊社からの連絡に対して30日以上応答が無い場合',
          'その他弊社が当該会員の登録が不適切であると判断した場合、又は弊社が本サイトの運営上支障があると判断した場合 ',
        ],
      },
      {
        content:
          '弊社は、前項に定める措置により会員又は第三者に損害が発生した場合であっても、一切責任を負わないものとします。なお、弊社は、前項に定める措置の対象となった会員が今後支払われる予定であった金銭について、弊社の判断により、別途弊社が指定する他の方法による精算等、必要な処置を行うことができるものとします。',
        subContent: [],
      },
      {
        content:
          '会員が退会を希望する場合には、弊社所定の手続を行うこととします。但し、当該会員が以下に定める状況にある間は退会できないものとします。',
        subContent: [
          '自らが当事者となった業務委託契約で定めた業務が終了していない場合（当事者同士の合意により終了した場合を除く）',
          '自らが当事者となった業務委託契約の請求及び支払に関する本サービス所定の手続（領収書の提出等）が完了していない場合',
        ],
      },
    ],
  },
  {
    id: 'section-7',
    chapter: '第3章　本サービス',
    title: '第7条　本サービスの内容について',
    contentDetails: [
      {
        content:
          '弊社は、本サービスを通じて、会員同士がクライアント及びワーカーとして　業務委託契約を締結し業務を遂行するためのプラットフォーム及びツールの提供を行います。 ',
        subContent: [],
      },
      {
        content:
          '本サービスは、クライアントとワーカーが直接業務委託契約を締結することを目的とするものであり、弊社は本サイト上で締結される各取引の当事者とはなりません。',
        subContent: [],
      },
      {
        content: '本サービスの内容は以下のとおりです。',
        subContent: [
          'クライアントは、委託を希望する業務を、本サイト上の登録フォームに入力して登録し、募集します。',
          'ワーカーは、募集されている業務について、本サイト上のプラットフォームから応募することができます。',
          'クライアントは、応募があった中から、依頼したいワーカーに対し、業務委託の詳細条件を提示します（オファー）。',
          'ワーカーがオファー記載の詳細条件を確認し、本サイト上で承諾することにより、クライアントとワーカーの間で、本サービスによって業務委託契約が成立することとなります。',
        ],
      },
    ],
  },
  {
    id: 'section-8',
    title: '第8条　本サービスの利用について',
    contentDetails: [
      {
        content:
          '会員は、依頼する業務内容の登録にあたり、業務の具体的内容を明らかにする必要があるものとし、会員間取引に伴う義務の履行の目的以外に、本サイト外へ誘導する記載又は行為を行ってはならないものとします。',
        subContent: [],
      },
      {
        content:
          '会員は、秘密保持義務、競業避止義務、その他自己が他者に対して負う義務に違反してはならないものとします。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-9',
    title: '第9条　本サービス水準等の非保証等',
    contentDetails: [
      {
        content:
          '弊社は、本取引を行うワーカーもしくはクライアントの選定及び本取引に基づく業務の遂行やその成果について 、それらの内容・品質・信憑性・適法性・正確性・有用性等の確認及び保証を行わないとともに、その瑕疵に関して一切の責任を負いません。',
        subContent: [],
      },
      {
        content:
          '弊社は本サイト内で会員が示した業務に関する一切の情報についてその内容・品質・正確性・適法性（以下、知的財産権や他人の権利非侵害を含む）・有用性・信憑性などは確認せず、確認の義務を負わず、かつ何ら保証しません。',
        subContent: [],
      },
      {
        content:
          '弊社は本サイト内において弊社が提供するコンテンツその他一切の情報の内容・品質・正確性・適法性・有用性・信憑性などについて、一切保証をせず、一切責任を負いません。 ',
        subContent: [],
      },
      {
        content:
          '本サイトの本人確認登録は、弊社があくまでも本人確認書類等と登録情報との合致を確認するだけであり、本人確認登録済み会員であっても、弊社は、その存在・責任能力・業務遂行能力・会員連絡先情報の正確性・その他の能力の有無等を一切保証せず、一切責任を負いません。',
        subContent: [],
      },
      {
        content:
          '本サイトの認定ワーカー検索では、弊社はあくまでもワーカー検索の機能を提供するだけであって、本人確認済みワーカーであっても、ワーカーとしてのスキル、業務遂行能力等の能力の有無等を一切保証せず、一切責任を負いません。',
        subContent: [],
      },
      {
        content:
          '前各項のほか、弊社は、サービスの品質向上及びサービスの安定的な提供に努めますが、サービスの利用に伴う結果などについては、一切保証しません。本サイト提供における、不正確・不適切・不明瞭な内容・表現・行為などにより、会員及び第三者に対して直接的又は間接的な損害が生じた場合であっても、弊社は、当該損害について一切責任を負いません。また、弊社は、ユーザーに対して、アドバイスや情報提供を行うことがありますが、アドバイスや情報提供の正確性、有用性、又はユーザーの望む結果の実現に対して責任を負うものではありません。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-10',
    title: '第10条　本サービスの利用料 ',
    contentDetails: [
      {
        content:
          '本サービスは、会員登録、仕事登録、仕事検索等の機能は、本サイト上で業務委託契約が成立した場合を除き無料でご利用いただけます。',
        subContent: [],
      },
      {
        content:
          'クライアントとワーカーの間で業務委託契約が成立した場合、クライアントから、弊社に対し、成立した契約１件当たり、3、300円（税込）のシステム利用手数料をお支払い頂きます。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-11',
    title: '第11条　システム利用手数料の支払方法 ',
    contentDetails: [
      {
        content:
          'システム利用料の請求は、弊社が、クライアントに対し、クライアントとワーカー間の業務委託契約の成立月の月末日を締め日として、翌月末日を支払日とする請求書を発行し、送付して行います。',
        subContent: [],
      },
      {
        content: 'システム利用手数料は、前項の請求書の記載内容に基づき、銀行口座振込にて期限までにお支払いください。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-12',
    title: '第12条　銀行口座への入金に関する事項',
    contentDetails: [
      {
        content:
          '何らかの理由により、会員が登録する銀行口座に対し、弊社から入金を行う場合は、日本国内の銀行に対してのみ行えるものとします。',
        subContent: [],
      },
      {
        content:
          '本規約において特に定める場合を除き、返金にかかる弊社所定の振込手数料はクライアントが負担するものとし、弊社はクライアントへの返金額から、振込手数料相当額を差し引くことができるものとします。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-13',
    chapter: '第4章　会員間の取引',
    title: '第13条　業務委託契約',
    contentDetails: [
      {
        content:
          'クライアントとワーカーは、本サービスを利用して成立した業務委託契約に従って、互いの義務を履行しなければなりません。同契約及び契約の成立過程で生じる各種作業・連絡・法的義務の履行・トラブル対処等については、会員同士で行うものとし、弊社は一切関与しません。',
        subContent: [],
      },
      {
        content: '弊社は、会員間の取引に関する一切の事項について、一切責任を負わないものとします。',
        subContent: [],
      },
      {
        content:
          'クライアントとワーカーは、本サービスを利用して成立した契約を、いかなる意味でも雇用契約又は類似の労働契約とはしないものとし、これがいかなる契約の形式による場合であっても、クライアントは業務内容及び遂行方法について業務委託に必要な限度を超えて指揮命令してはならず、これらに反する内容でのクライアントとワーカー間の定めは無効とします。本項の規定は、本規約第3条第2項、その他本規約のいかなる規定にもかかわらず、クライアントとワーカー間のいかなる合意にも優先して適用されます。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-14',
    title: '第14条　取引における法令遵守',
    info: '会員は、会員の間取引において、法令を遵守する義務を負います。その中には、以下のものが含まれますが、これに限られるものではありません。',
    isSubcontent: true,
    contentDetails: [
      {
        content:
          '会員間の取引が下請代金支払遅延等防止法の適用を受けるときは、親事業者たるクライアントは、同法を遵守するものとします。また、同法の適用の有無にかかわらず、クライアントは、独占禁止法及びこれに関連する公正取引委員会告示等に定める不公正な取引方法その他独占禁止法に違反する行為を行わないものとします。',
        subContent: [],
      },
      {
        content:
          '会員間の取引によってワーカーに支払われる報酬につきクライアントが源泉徴収をする義務があるとき、クライアントは、源泉徴収税の納付、支払調書の交付等の義務を履行するものとします。',
        subContent: [],
      },
      {
        content:
          '会員間取引が下請代金支払遅延等防止法の適用をうけるとき、下請事業者に該当するワーカーは、同法第3条第1項に定める書面を本サイト上からダウンロードすることにより、その他弊社又はクライアントが定める方法により親事業者たるクライアントから電磁的方法で提供を受けることを承諾するものとします。なお、ワーカーが同書面の電磁的方法による提供を承諾しない場合、クライアントはワーカーと直接連絡を取り、適宜の方法で交付するものとします。 ',
        subContent: [],
      },
      {
        content: '会員は、自ら又は第三者を利用して、以下の各号に掲げる行為を行ってはなりません。',
        subContent: [
          '暴力的な要求行為',
          '法的な責任を超えた不当な要求行為',
          '取引に関して、脅迫的な言動をし、又は暴力を用いる行為',
          '風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為',
          'その他これらに準ずる行為',
        ],
      },
      {
        content:
          '弊社は、会員間の取引における法令遵守につき、何らこれを保証するものではなく、何らの責任を負わないものとします。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-15',
    title: '第15条　取引における秘密保持',
    contentDetails: [
      {
        content:
          '会員は、会員間の取引又はその成立過程において、取引の相手方たる会員から秘密である旨示されて開示される秘密情報、依頼に関連する一切の情報、会員間取引遂行中に知り得た秘密情報、及び、取引の相手方たる会員が保持する個人情報を、すべて秘密として保持し、会員間取引の目的以外には一切使用せず、第三者に一切開示又は漏えいしないものとします。',
        subContent: [],
      },
      {
        content:
          '前項の規定に関わらず、以下のいずれかに該当することを会員が証明したものについては、秘密情報から除かれるものとします。ただし、個人情報については第6号に該当することを会員が証明した場合にのみ秘密情報から除かれるものとします。 ',
        subContent: [
          '既に公知、公用の情報',
          '秘密情報の開示後に、会員の責によらず公知となった情報',
          '開示を受けた時点で、被開示者が既に知得していた情報',
          '開示を受けた後、正当な権限を有する第三者から守秘義務を負うことなしに適法に入手した情報',
          '開示者が、第三者に開示することを文書により承諾した情報',
          '法令又は確定判決、訴訟上の認諾調書、仲裁判断、訴訟上の和解調書、調停調書、若しくはその他確定判決と同一の効力を有するもの等により義務付けられた情報',
        ],
      },
      {
        content:
          '会員が、秘密情報を利用するにあたっては、開示目的を達成するに最小限必要な者に限定して開示するものとします。この場合、会員はその者が秘密情報を漏洩もしくは開示目的以外に利用しないよう、監督その他の必要な措置を講ずる義務を負うものとします。',
        subContent: [],
      },
      {
        content:
          '会員は、秘密情報の管理について、全て合理的な安全管理体制及び漏洩防止手段を講じる義務を負うものとします。',
        subContent: [],
      },
      {
        content:
          '会員は、会員間で取引を開始する前に、必要に応じ、別途秘密保持契約を締結し、相互の秘密保持に努めるものとします。この別途秘密保持契約の締結の有無にかかわらず、本サイト上、本条に同意することを表示した会員間では、会員間の取引に関し、相互に本条に定める秘密保持義務を負うものとします。',
        subContent: [],
      },
      {
        content:
          '弊社は、会員間の取引における秘密保持につき、何らこれを保証するものではなく、何らの責任を負わないものとします。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-16',
    title: '第16条　会員間の連絡',
    contentDetails: [
      {
        content:
          '会員が、本サイトにおいて知り得た取引の相手方たる会員連絡先情報は、会員間の取引に伴う義務の履行のためにのみ使用し、本サイトを介さずに行う直接取引やそれを勧誘する行為又は他の目的には一切使用せず、いかなる第三者にも開示しないものとします。',
        subContent: [],
      },
      {
        content:
          '弊社は、会員からの申出が、裁判所、検察庁又は行政機関の命令等法令に基づく申出に伴う場合に限り、取引の相手方たる会員の会員連絡先情報を当該申出にかかる会員に開示することができるものとします。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-17',
    chapter: '第5章　ユーザーの責任',
    title: '第17条 利用環境の整備 ',
    info: 'ユーザーは、本サイトを利用するために必要な通信機器、ソフトウェア、その他これらに付随して必要となる全ての機器を、自己の費用と責任において準備し、本サイトが利用可能な状態に置くものとします。また、自己の費用と責任で、任意の電気通信サービスを経由して本サイトに接続するものとします。',
    contentDetails: [],
  },
  {
    id: 'section-18',
    title: '第18条 自己責任の原則',
    info: 'ユーザーは、ユーザーによる本サイトの利用とその本サイトを利用してなされた一切の行為（ユーザーによる利用又は行為とみなされる他者の利用や行為を含みます。以下同様とします。）と、その結果について一切の責任を負います。ユーザーは、本サイトの利用により弊社又は他者に対して損害を与えた場合（ユーザーが、本規約等における義務を履行しないことにより他者又は弊社が損害を被った場合を含みます。）、自己の責任と費用をもって損害を賠償するものとします。',
    contentDetails: [],
  },
  {
    id: 'section-19',
    title: '第19条　ID・パスワードの管理',
    contentDetails: [
      {
        content:
          '会員は、登録したID及びパスワードについて、自己の責任の下で適切に管理し、ID及びパスワードの盗用を防止する措置を自ら講じるものとします。',
        subContent: [],
      },
      {
        content:
          '会員は、登録したID及びパスワードについて、第三者による利用や第三者への貸与・譲渡等の行為を行ってはならないものとします。',
        subContent: [],
      },
      {
        content:
          'ID及びパスワードの管理不十分、使用上の過誤、第三者の使用等により被った損害は会員が責任を負うものとし、弊社はかかる会員の損害から一切免責されるものとします。また、会員が本来得られるはずだった利益等の一切を弊社に請求できないこととします。',
        subContent: [],
      },
      {
        content:
          '会員は、ID及びパスワードの盗用や第三者による使用が判明した場合、直ちにその旨を弊社に通知し、弊社からの指示に従うものとします。 ',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-20',
    title: '第20条　地位等の譲渡禁止',
    info: 'ユーザーは、本利用規約に基づく権利、義務及び本利用規約に基づき成立する契約上の地位の全部又は一部について、これを第三者に譲渡、質入れ、その他の方法により処分してはならないものとします。但し、弊社の書面による事前の承諾がある場合を除きます。',
    contentDetails: [],
  },
  {
    id: 'section-21',
    title: '第21条　禁止事項',
    info: '本サービスのユーザーが、以下に定める行為を行うことを禁止します。',
    isSubcontent: true,
    contentDetails: [
      {
        content:
          '弊社、他のユーザー又は第三者の著作権、商標権等の知的財産権を侵害する行為、又は侵害するおそれのある行為',
        subContent: [],
      },
      {
        content:
          '他のユーザー若しくは第三者の財産、プライバシー若しくは肖像権を侵害する行為、又は侵害するおそれのある行為',
        subContent: [],
      },
      {
        content:
          '特定個人の氏名・住所・電話番号・メールアドレスなど第三者が見て個人を特定できる情報を第三者に提供する行為',
        subContent: [],
      },
      {
        content: '弊社、他のユーザー又は第三者を差別若しくは誹謗中傷し、又は名誉若しくは信用を毀損する行為',
        subContent: [],
      },
      {
        content: '一人のユーザーが複数のメールアドレス等を登録して重複して会員登録を行う行為',
        subContent: [],
      },
      {
        content: '本サービスにおけるアカウントを第三者との間で売買する行為、又は売買を試みる行為',
        subContent: [],
      },
      {
        content: '会員資格を停止ないし無効にされた会員に代わり会員登録をする行為',
        subContent: [],
      },
      {
        content: 'アクセス可能な本サービス又は他者の情報を改ざん、消去する行為',
        subContent: [],
      },
      {
        content: '弊社又は他者になりすます行為（詐称するためにメールヘッダ等の部分に細工を行う行為を含みます。）',
        subContent: [],
      },
      {
        content: '有害なコンピュータプログラム等を送信し、又は他者が受信可能な状態におく行為',
        subContent: [],
      },
      {
        content:
          '他者に対し、無断で、広告・宣伝・勧誘等の電子メール又はメッセージ（以下「電子メール等」といいます。）若しくは嫌悪感を抱くおそれのある内容を含む電子メール等を送信する行為、他者の電子メール等の受信を妨害する行為、連鎖的な電子メール等の転送を依頼する行為及び当該依頼に応じて転送する行為',
        subContent: [],
      },
      {
        content:
          '他者の設備若しくは本サービス用設備（弊社が本サービスを提供するために用意する通信設備、電子計算機、その他の機器及びソフトウェアを言い、以下同様とします。）に無権限でアクセスし、又はポートスキャン、DOS攻撃若しくは大量のメール送信等により、その利用若しくは運営に支障を与える行為、又は支障を与えるおそれのある行為',
        subContent: [],
      },
      {
        content: 'サーバ等のアクセス制御機能を解除又は回避するための情報、機器、ソフトウェア等を流通させる行為',
        subContent: [],
      },
      {
        content:
          '本人の同意を得ることなく、又は詐欺的な手段（いわゆるフィッシング及びこれに類する手段を含みます。）により他者の会員登録情報を取得する行為',
        subContent: [],
      },
      {
        content:
          '弊社が事前に書面をもって承認した場合を除く、本サービスに基づく業務委託以外を目的とした本サービスを使用した営業活動、本サービスに基づく業務委託以外の営利活動を目的とした本サービスの利用にかかる行為、又はその準備を目的とした本サービスの利用にかかる行為',
        subContent: [],
      },
      {
        content:
          'ワーカーの承諾の有無にかかわらず、委託業務の内容に照らして報酬額が著しく低いと弊社が判断する金額で業務を依頼する行為、又は業務を開始する行為',
        subContent: [],
      },
      {
        content: '報酬確定前に商品・サービス等の購入が必要な業務を依頼する行為、又は業務を開始する行為',
        subContent: [],
      },
      {
        content: 'マルチ・ねずみ講・MLMなどの連鎖取引への勧誘が疑われる業務を依頼する行為、又は業務を開始する行為',
        subContent: [],
      },
      {
        content:
          'その他、外部サービスの規約違反などにより当該サービスの運営に影響を及ぼすおそれのある業務を依頼する行為、又は業務を開始する行為',
        subContent: [],
      },
      {
        content:
          '外部サービスの宣伝や登録、メールマガジンへの登録、アフィリエイトなど、本サービスの趣旨とは異なる目的の業務を依頼する行為、又は業務を開始する行為',
        subContent: [],
      },
      {
        content: '業務委託契約以外の契約形態で業務を依頼する行為、又は業務を開始する行為。',
        subContent: [],
      },
      {
        content: '依頼する業務の性質上必要がないにもかかわらず、勤務時間・勤務地を制限する業務を依頼する行為',
        subContent: [],
      },
      {
        content:
          '弊社を介さない業務の依頼、金銭の支払い、その他直接取引を想起させる行為（弊社が事前に承諾をした場合を除く）',
        subContent: [],
      },
      {
        content:
          '委託する業務の内容、手順、納入する成果物の仕様、数量、機能、業務の実施場所、業務の実施条件、免責条件など、業務の遂行に必要となる定めのない業務を依頼する行為、又は業務を開始する行為',
        subContent: [],
      },
      {
        content:
          '他のユーザー又は第三者が主導する情報の交換又は共有を妨害する行為、本サービスの運営を妨害する行為、その他弊社に不利益を与えるおそれのある行為',
        subContent: [],
      },
      {
        content:
          '長時間の架電、同様の問い合わせの過度な繰り返し、義務や理由のないことの強要、その他弊社の業務に著しく支障を来たす行為、又はそのおそれのある行為',
        subContent: [],
      },
      {
        content: '日本又は海外において適法に就労するための要件を満たしていないまま就労する行為、又はそれを助長する行為',
        subContent: [],
      },
      {
        content: '自己の所属する組織体の規則に違反する行為',
        subContent: [],
      },
      {
        content:
          '上記各号の他、法令又は本利用規約に抵触する行為、公序良俗に違反する行為（暴力を助長し、誘発するおそれのある情報又は残虐な映像を送信又は表示する行為や心中の仲間を募る行為等を含みます。）及びその他迷惑行為',
        subContent: [],
      },
      {
        content:
          '上記各号のいずれかに該当する行為（当該行為を他者が行っている場合を含みます。）を助長する目的で他のサイトにリンクを張る行為',
        subContent: [],
      },
      {
        content: 'その他弊社がユーザーとして不適当と判断した行為',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-22',
    chapter: '第6章　本サービスの運営・免責等',
    title: '第22条　個人情報の取り扱い',
    info: '弊社は、弊社が知り得た利用者の個人情報を、別途定める「プライバシーポリシー」に従い取り扱います。',
    contentDetails: [],
  },
  {
    id: 'section-23',
    title: '第23条　弊社提供サービスに関する知的財産権',
    contentDetails: [
      {
        content:
          '本サービスで弊社が作成・提供する画像、テキスト、プログラム等に関する著作権等の一切の知的財産権は、弊社に帰属します。',
        subContent: [],
      },
      {
        content:
          '本サービスで弊社が作成・提供・掲載する一切の画像、テキスト、プログラム等は、著作権法、商標法等の法律により保護されています。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-24',
    title: '第24条　監視業務',
    contentDetails: [
      {
        content:
          '弊社は、会員が本規約等に従った本サービスの利用をしているか、また、本規約等に反する行為や不正がないかを監視する業務を独自の裁量で行う権利を有するものとします。 ',
        subContent: [],
      },
      {
        content:
          '本サービスで提供する「メッセージ機能」は、メッセージの送信者と受信者及び弊社の三者のみが閲覧出来るようになっています。弊社は前項の監視業務を含め本サービスの提供上必要な範囲に限り、かつ必要に応じて、その内容を閲覧し、会員の本規約等の違反行為又は不正行為に対して弊社が適切と考える措置を行うことができるほか、本規約に照らして一部又は全てを削除することがあります。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-25',
    title: '第25条　規約違反への対処及び違約金等',
    contentDetails: [
      {
        content:
          '弊社は、ユーザーの行為が本利用規約及び各種ガイドラインの定めに抵触すると判断した場合、弊社の判断により、当該ユーザーに何ら通知することなくして、本サービスの一時停止、会員登録の解除、本サービスへのアクセスを拒否、本サイト上におけるプロフィール等の掲載情報や電子掲示板への投稿の全部若しくは一部の削除、変更又は公開範囲の制限、進行中案件の停止、掲載案件の削除、その他弊社が必要と判断する一切の措置を講ずることができるものとします。',
        subContent: [],
      },
      {
        content:
          '前項に基づく弊社の対処に関する質問、苦情は一切受け付けておりません。なお、ユーザーは、当該措置によって被った一切の損害について、弊社に対して賠償請求を行わないものとします。',
        subContent: [],
      },
      {
        content:
          '弊社は、ユーザーが本利用規約違反等の悪質な行為を行っていると判断した場合、当該ユーザーに対して法的措置を検討するものとします。',
        subContent: [],
      },
      {
        content:
          'ユーザーは、ユーザーが本利用規約違反等の行為を行ったことにより弊社に損害（第三者に損害が生じ、その損害について弊社が填補した場合を含みます。）が生じた場合、その一切の損害について、弊社に対して賠償する責任を負うものとします。',
        subContent: [],
      },
      {
        content:
          '弊社は、ユーザーが第8条第１項又は第22条に違反した場合、当該ユーザーの登録解除等、弊社が必要と判断する措置を講ずることができるものとします。また、弊社は、ユーザーに対し、違約金として、当該取引の報酬額に対するシステム利用料相当額か金100万円のいずれか大きい方の金額（当該取引の報酬額に対するシステム利用料相当額の算定が不可能な場合は、金100万円）の支払いを求めること及び一切の法的措置（金銭賠償請求を含むがこれに限りません。）を講ずることができるものとします。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-26',
    title: '第26条　弊社からの連絡又は通知',
    contentDetails: [
      {
        content:
          '弊社が会員への連絡又は通知の必要がある場合には、登録されたメールアドレスへのメール、登録された電話番号への電話、又は登録された住所への郵送によって、連絡又は通知を行います。',
        subContent: [],
      },
      {
        content:
          'ユーザーは、原則としてメール、お問い合わせフォーム、又は電話にて弊社への連絡を行うものとします。来訪は受け付けておりません。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-27',
    title: '第27条　サイトの中断・停止・終了',
    contentDetails: [
      {
        content:
          '弊社は、システム障害及び保守、地震等の天変地異や火災等の自然災害の発生、その他技術上・運営上の理由により、本サービスの提供が困難であると判断した場合、ユーザーへの事前通知を行わず、本サービスの中断を行う場合があります。',
        subContent: [],
      },
      {
        content:
          '弊社は2週間前までに、会員に電子メールでの通知及び本サイト上で告知を行うことにより、本サービスの停止及び終了を行うことができるものとします。',
        subContent: [],
      },
      {
        content: '弊社は、本条に基づき弊社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-28',
    title: '第28条　免責',
    contentDetails: [
      {
        content:
          '会員登録取消し、ユーザーからのID・パスワードの第三者に漏洩、ユーザーによる秘密漏示、本サービスのシステム不具合や障害・中断やデータの消失・漏洩等により生じた不利益・損害等、本サービスの利用によりユーザーに生じた一切の不利益・損害について弊社は一切の責任を負いません。',
        subContent: [],
      },
      {
        content:
          'ユーザーが、本サービスを利用することにより、他のユーザー又は第三者に対し不利益・損害を与えた場合、ユーザーは自己の費用と責任においてこれを賠償するものとし、これらの一切の不利益・損害について弊社は一切責任を負いません。',
        subContent: [],
      },
      {
        content:
          '弊社は本サービス上で行われる受発注者間の取引を管理するものではなく、取引によって生じた一切の不利益・損害について一切責任を負いません。',
        subContent: [],
      },
      {
        content:
          '本サービス上でやりとりされるメッセージや送受信されるファイルに個人情報等が含まれていた場合、それによって会員が被った不利益・損害について、弊社は一切責任を負いません。',
        subContent: [],
      },
      {
        content:
          '弊社は、会員の身元の保証をするものではなく、また、ワーカー又はクライアントが本サービス上で取引を完了することを保証するものでもありません。',
        subContent: [],
      },
      {
        content:
          '弊社は、本サービス上で会員が作成・登録・提供・掲載・投稿した一切の画像、テキスト、プログラム等について、本サイトの円滑な運営又は本サービスの継続的な提供のために必要な範囲内で、弊社の判断により、使用・公開等を行うことができるものとし、これらによる不利益・損害について弊社は一切責任を負いません。 ',
        subContent: [],
      },
    ],
  },
  {
    id: 'section-29',
    title: '第29条　本サービスの譲渡等',
    info: '弊社は、本サービスの事業を第三者に譲渡した場合、当該事業譲渡に伴い、本サービスの運営者たる地位、本利用規約上の地位、本利用規約に基づく権利及び義務並びに会員の登録情報及びその他情報を当該事業譲渡の譲受人に譲渡することができるものとし、本サービスの会員は、会員たる地位、本規約上の地位、本利用規約に基づく権利及び義務並びに会員の登録情報その他情報の譲渡につきあらかじめ同意するものとします。',
    contentDetails: [],
  },
  {
    id: 'section-30',
    chapter: '第7章　その他',
    title: '第30条　反社会的勢力の排除',
    contentDetails: [
      {
        content:
          '弊社及び利用者は、相手方に対し、当該利用者による本サービスの利用開始時点において、自己及び自己の取締役、執行役、執行役員等の経営に実質的に関与する重要な使用人、実質的に経営権を有する者が反社会的勢力に該当しないことを表明し、かつ当該利用者による本サービスの利用期間中該当しないことを保証するものとします。なお、本条において「反社会的勢力」とは、暴力団員による不当な行為の防止等に関する法律（以下「暴対法」といいます。）第2条第2号に定義される暴力団、暴対法第2条第6号に定義される暴力団員、暴力団員でなくなった日から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動標榜ゴロ、政治活動標榜ゴロ、特殊知能暴力集団、暴力団密接関係者及びその他の暴力的な要求行為若しくは法的な責任を超えた不当要求を行う集団又は個人をいうものとします。',
        subContent: [],
      },
      {
        content:
          '弊社及び利用者は、本サービスの利用に関連して自ら又は第三者を利用して以下の各号に該当する行為を行わないことを、相手方に対し、保証するものとします。',
        subContent: [
          '暴力的な要求行為',
          '法的な責任を超えた不当な要求行為',
          '脅迫的な言動をし、又は暴力を用いる行為',
          '風説を流布し、偽計又は威力を用いて他方当事者の信用を棄損し、又は他方当事者の業務を妨害する行為',
          'その他前各号に準ずる行為',
        ],
      },
    ],
  },
  {
    id: 'section-31',
    title: '第31条　準拠法・分離可能性・管轄裁判所',
    contentDetails: [
      {
        content: '本利用規約は、日本法に基づき解釈されるものとします。',
        subContent: [],
      },
      {
        content:
          '本利用規約の一部について裁判所やその他正当な権限を有する機関により違法、執行不能又は無効とされた場合、その違法性、執行不能性又は無効性は、本利用規約の他の条項の適法性、執行可能性又は有効性に一切影響を与えないものとします。',
        subContent: [],
      },
      {
        content:
          ' 本サービスに関連して訴訟等の必要が生じた場合には、仙台地方裁判所を第一審の専属的合意管轄裁判所とします。',
        subContent: [],
      },
    ],
  },
]

export const privacyPolicySectionDetails = [
  {
    id: 'privacy-section-1',
    title: '■事業者の名称 ',
    info: '株式会社佐々通オンサイト',
    contentDetails: [],
  },
  {
    id: 'privacy-section-2',
    title: '■個人情報管理責任者',
    info: '株式会社佐々通オンサイト オンサイトサービス部',
    contentDetails: [],
  },
  {
    id: 'privacy-section-2-1',
    title: '',
    info: '（仙台市太白区長町６－１３－２）',
    contentDetails: [],
  },
  {
    id: 'privacy-section-2-2',
    title: '',
    info: 'e-mail : info@sasatsu.co.jp',
    contentDetails: [],
  },
  {
    id: 'privacy-section-3',
    title: '■法令・規範の遵守',
    info: '弊社は、個人情報の取扱いに関する法令・国が定める指針その他の規範を遵守致します。',
    contentDetails: [],
  },
  {
    id: 'privacy-section-4',
    title: '■定義',
    info: 'このプライバシーポリシーにおいて、個人情報とは、生存する個人に関する情報であって、以下のいずれかに該当するものをいいます。',
    contentDetails: [
      {
        content:
          '当該情報に含まれる氏名、生年月日その他の記述等｛文書、図画もしくは電磁的記録（電子的方式、磁気的方式その他人の知覚によって認識することができない方式により作られた記録をいう）に記載され、もしくは記録され、又は音声、動作その他の方法を用いて表された一切の事項（個人識別符号を除く）をいう｝により、特定の個人を識別できるもの（他の情報と容易に照合でき、それにより特定の個人を識別できるものを含む）。',
        subContent: [],
      },
      {
        content: '個人識別符号が含まれるもの。',
        subContent: [],
      },
    ],
  },
  {
    id: 'privacy-section-5-1',
    title: '■個人情報の取得と利用について',
    info: `弊社は、サービス会員登録の際に、あるいは弊社へのWEBサイト問い合わせフォーム・電話・メールにてご連絡をいただく際に、ユーザーの個人情報をご提供いただいております。`,
    contentDetails: [],
  },
  {
    id: 'privacy-section-5-2',
    title: '',
    info: 'ご提供いただいたユーザーの個人情報については以下の目的以外には利用致しません。',
    contentDetails: [
      {
        content: 'お問い合わせ対応のため',
        subContent: [],
      },
      {
        content: 'サービスの会員登録、サービス提供、サポート、連絡のため',
        subContent: [],
      },
      {
        content: 'ご利用料金請求のため',
        subContent: [],
      },
      {
        content: '弊社内におけるマーケティング調査/統計/分析のため',
        subContent: [],
      },
      {
        content: '新サービスのご案内、お知らせご連絡のため',
        subContent: [],
      },
      {
        content: '商談、お打ち合わせ、契約履行のため',
        subContent: [],
      },
      {
        content: '与信判断及び与信管理のため',
        subContent: [],
      },
    ],
  },
  {
    id: 'privacy-section-5-3',
    title: '',
    info: 'なお弊社は、サービス開発、運用の委託先と個人情報を共有する場合がありますが、委託先とは守秘契約を取り交わし、弊社の監督において適切に管理しております。',
    contentDetails: [],
  },
  {
    id: 'privacy-section-6',
    title: '■個人情報保護のための取り組み',
    info: '弊社は、ユーザーの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス、紛失、破壊、改ざんおよび漏えい等防止のため、適切な措置を講じております。',
    contentDetails: [],
  },
  {
    id: 'privacy-section-6-1',
    title: '',
    info: 'また、利用目的に照らして不要となった個人情報については、速やかにかつ適正に削除、廃棄いたします。',
    contentDetails: [],
  },
  {
    id: 'privacy-section-6-2',
    title: '',
    info: '弊社社員につきましても、管理体制および個人情報保護のため教育を徹底しております。',
    contentDetails: [],
  },
  {
    id: 'privacy-section-7',
    title: '■個人情報開示に関する免責',
    info: '弊社は、以下の場合を除き、原則的にユーザーご本人の許可なく第三者に個人情報を開示致しません。',
    contentDetails: [
      {
        content:
          '人の生命、身体、又は財産の保護のために個人情報の第三者提供が必要である場合であって、本人の同意を得ることが困難である場合',
        subContent: [],
      },
      {
        content:
          '裁判所、警察、税務署、消費者センター、その他の法律や条例などで認められた権限を持つ機関から要請がある場合',
        subContent: [],
      },
      {
        content:
          'ご本人の同意がある場合や、ご本人自らが弊社サービスの機能あるいは別の手段を用いて第三者に個人情報を明らかにする場合',
        subContent: [],
      },
      {
        content: '弊社サービスの利用料金等に関する債権を、第三者に譲渡する必要が生じた場合',
        subContent: [],
      },
    ],
  },
  {
    id: 'privacy-section-8',
    title: '■個人情報の開示・訂正・利用停止又は消去',
    info: 'ご本人から個人情報の内容の開示（個人データの第三者提供記録の開示を含みます）・訂正・利用停止・消去又は第三者提供の停止の請求があった場合は、個人情報保護法に基づき適切・迅速に対応いたします。',
    contentDetails: [],
  },
  {
    id: 'privacy-section-9',
    title: '■個人情報保護管理の継続的改善 ',
    info: '弊社は、個人情報の取り扱いに関して、法令、規範、情報技術の変化を常に把握し、常に継続的改善に努めます。',
    contentDetails: [],
  },
  {
    id: 'privacy-section-10',
    title: '',
    info: '個人情報についてのお問い合わせ ',
    contentDetails: [],
  },
  {
    id: 'privacy-section-10-1',
    title: '',
    info: 'お客様からの個人情報に関するお問い合わせや苦情には適切かつ誠実に対応致します。',
    contentDetails: [],
  },
  {
    id: 'privacy-section-10-2',
    title: '',
    info: '保有している個人情報についてお客様ご本人から開示、訂正、削除、利用停止などの申請を受付け、公正かつ合理的に対応致します。',
    contentDetails: [],
  },
  {
    id: 'privacy-section-11',
    title: '',
    info: 'お問い合わせ先',
    contentDetails: [],
  },
  {
    id: 'privacy-section-11-1',
    title: '',
    info: '株式会社佐々通オンサイト',
    contentDetails: [],
  },
  {
    id: 'privacy-section-11-2',
    title: '',
    info: 'e-mail : info@sasatsu.co.jp',
    contentDetails: [],
  },
]
