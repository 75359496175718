import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  SubHeading,
  Heading,
  Label,
  ScrollToTop,
  LargeButton,
  TermsTableContent,
  TermsConditionContent,
} from 'components'

const StyledTermsCondition = styled(Grid)(() => ({
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
}))

function TermsCondition(props) {
  return (
    <Fragment>
      <StyledTermsCondition container id={'Top-Page-Terms-Conditions'}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Heading>利用規約</Heading>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={4.38}>
          <Divider sx={{ borderBottomWidth: 1, borderColor: 'gray' }} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={4.38}>
          <SubHeading color="secondary" bold="true">
            {'目次'}
          </SubHeading>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TermsTableContent />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={5} mb={2}>
          <Divider sx={{ borderBottomWidth: 1, borderColor: 'gray' }} />
        </Grid>
        <TermsConditionContent />
        <Grid item xs={12} sm={12} md={12} lg={12} mt={5} mb={5}>
          <Divider sx={{ borderBottomWidth: 1, borderColor: 'gray' }} />
        </Grid>
        <Grid item ml={2} mb={1}>
          <ul>
            <li>
              <Label>{'令和4年8月15日 制定・施行 '}</Label>
            </li>
          </ul>
        </Grid>
      </StyledTermsCondition>
      <ScrollToTop {...props} topId={'#Top-Page-Terms-Conditions'}>
        <LargeButton maxwidth={180}>
          <KeyboardArrowUpIcon />
          {'トップへ戻る'}
        </LargeButton>
      </ScrollToTop>
    </Fragment>
  )
}

TermsCondition.propTypes = {
  isSubcontent: PropTypes.bool,
}

export default TermsCondition
