import * as types from './actionTypes'

export function actionSearchResults(resultDataList) {
  return {
    type: types.SEARCH_RESULT,
    payload: resultDataList,
  }
}

export function fetchingDataFail(error) {
  return {
    type: types.FETCHING_DATA_FAILED,
    payload: error,
  }
}

export function actionSetSearchCriteria(searchParameters) {
  return {
    type: types.SET_SEARCH_CRITERIA,
    payload: searchParameters,
  }
}

export function actionSetResultOptions({ data }) {
  return {
    type: types.SET_RESULT_OPTIONS,
    payload: data,
  }
}
