import * as types from './actionTypes'

export function actionSearchClient(clientList) {
  return {
    type: types.SEARCH_CLIENT,
    payload: clientList,
  }
}

export function actionSetSearchCriteria(searchParameters) {
  return {
    type: types.SET_SEARCH_CRITERIA,
    payload: searchParameters,
  }
}

export function fetchingDataFail(error) {
  return {
    type: types.FETCHING_DATA_FAILED,
    payload: error,
  }
}

export function setClientUserFormData(formData) {
  return {
    type: types.SET_CLIENT_USER_FORM_DATA,
    payload: formData,
  }
}

export function setFormMode(isUpdate) {
  return {
    type: types.SET_FORM_MODE,
    payload: isUpdate,
  }
}

export function setConfirming(confirming) {
  return {
    type: types.SET_CONFIRMING_DATA,
    payload: confirming,
  }
}

export function setFormErrors(errors) {
  return {
    type: types.SET_FORM_ERRORS,
    payload: errors,
  }
}

export function resetCurrentForm() {
  return {
    type: types.RESET_CURRENT_FORM,
  }
}
