import React, { useState } from 'react'
import { Grid, Box, CircularProgress, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
//utils
import { groupBy, maskedData, formatMaskedNameId } from 'utils/helper'
import { status } from 'utils/config/status'
//components
import {
  SmallOutlinedTag,
  Banner,
  LargeButton,
  Link,
  ContentDisplay,
  Label,
  CategoryDisplay,
  BackArrow,
  Heading,
  SubHeading,
  P,
  Caption,
  RejectApplicationContent,
  Modal,
} from 'components'

const StyledApplicationDetailsForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .WorkExperience-Text': {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
  },
  '& .JobOutline label': {
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
    },
  },
  '& .UnderlineLink': {
    textDecoration: 'underline',
  },
}))

const ApplicationDetailsForm = (props) => {
  const isLoading = useSelector((state) => state.loader)
  const { displayData } = props
  const skills = displayData?.worker?.skills ?? []
  const certificates = skills.filter((skill) => skill.skill_category_type === 'Qualification')
  const canDos = skills.filter((skill) => skill.skill_category_type === 'Skills')
  const certificatesByCategory = groupBy(certificates, 'skill_category_name')
  const canDosByCategory = groupBy(canDos, 'skill_category_name')
  const [openCancelModal, setOpenCancelModal] = useState(false)

  //reject offer
  const rejectOffer = () => {
    setOpenCancelModal(true)
  }

  //Close cancel application modal
  const onCloseCancelModal = () => {
    setOpenCancelModal(false)
  }

  //get applicant name display
  const getWorkerDisplayName = (data) => {
    return (
      <React.Fragment>
        <Box display="inline-flex">
          <Label pr={2}>{formatMaskedNameId(data?.worker?.name, data?.worker?.id)}</Label>
          <Stack direction="row" spacing={1}>
            {data?.worker?.verified_user ? <SmallOutlinedTag label="本人確認済" color="success" /> : ''}
            {data?.worker?.tekikaku_flag ? <SmallOutlinedTag label="適格請求書発行事業者" color="primary" /> : ''}
          </Stack>
        </Box>
        <Caption>(マッチングが成立すると開示されます。）</Caption>
      </React.Fragment>
    )
  }
  //get phone number display
  const gePhoneNumberDisplay = (phone_number) => {
    return (
      <React.Fragment>
        {maskedData(phone_number)}
        <Caption>(マッチングが成立すると開示されます。）</Caption>
      </React.Fragment>
    )
  }

  return (
    <StyledApplicationDetailsForm container pb={4}>
      {isLoading && (
        <Grid xs={12} item textAlign="center" py={32}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <React.Fragment>
          <BackArrow label="応募一覧に戻る" path="/application/list" />
          <Grid item container>
            <Grid item xs={12} py={2}>
              <Heading>応募詳細</Heading>
            </Grid>
            <Grid item xs={12} className="JobOutline">
              <Label pb={0.5}>
                応募ID: <span>{displayData?.id}</span>
              </Label>
              <Label pb={0.5}>
                応募作業グループ:&nbsp;
                <Link className="UnderlineLink" to={'/job-group/show/' + displayData?.job_group_id}>
                  {displayData?.job_group_name_formatted}
                </Link>
              </Label>
              <Label pb={0.5}>
                応募案件名: <span>{displayData?.project_name}</span>
              </Label>
              <Label pb={0.5}>
                応募日: <span>{displayData?.application_date}</span>
              </Label>
            </Grid>
            <Grid item xs={12} sm={5} md={4} py={4}>
              <Label pb={1}>応募ステータス</Label>
              <Banner label={displayData?.application_status} variant="outlined" color="success" bold="true" />
            </Grid>
            <Grid item xs={12} py={2}>
              <SubHeading bold="true">応募者</SubHeading>
            </Grid>
          </Grid>
          {displayData && (
            <Grid item container>
              <ContentDisplay label="応募者名:" data={getWorkerDisplayName(displayData)} />
              <ContentDisplay label="表示名:" data={displayData?.worker?.display_name} />
              <ContentDisplay label="性別:" data={displayData?.worker?.gender} />
              <ContentDisplay label="年齢:" data={displayData?.worker?.age} />
              <ContentDisplay label="住所:" data={`${displayData?.worker?.prefecture} ${displayData?.worker?.city}`} />
              <ContentDisplay label="メールアドレス:" data={displayData?.worker?.email} />
              <ContentDisplay label="電話番号:" data={gePhoneNumberDisplay(displayData?.worker?.phone_number)} />
              <ContentDisplay
                label="適格請求書発行事業者:"
                data={displayData?.worker?.tekikaku_flag ? <CheckBoxIcon color="primary" /> : <CheckBoxBlankIcon />}
              />
              {certificates.length !== 0 && (
                <Grid item pt={10} xs={12} textAlign="center">
                  <SubHeading bold="true">所有資格</SubHeading>
                </Grid>
              )}
              {Object.entries(certificatesByCategory).map((items, i) => {
                return (
                  <Grid item xs={12} key={i} pt={4} textAlign="center">
                    <Label color="main" pb={2}>
                      {items[0]}:
                    </Label>
                    <CategoryDisplay items={items[1]} />
                  </Grid>
                )
              })}
              {canDos.length !== 0 && (
                <Grid item pt={10} xs={12} textAlign="center">
                  <SubHeading bold="true">対応可能作業</SubHeading>
                </Grid>
              )}
              {Object.entries(canDosByCategory).map((items, i) => {
                return (
                  <Grid item xs={12} key={i} pt={4} textAlign="center">
                    <CategoryDisplay items={items[1]} />
                  </Grid>
                )
              })}
              <Grid item pt={10}>
                <SubHeading>業務経験</SubHeading>
              </Grid>
              <Grid item pt={1} xs={12} sm={12} md={12} lg={12}>
                <pre>
                  <P className="WorkExperience-Text">{displayData?.worker?.work_experience}</P>
                </pre>
              </Grid>
              <Grid item container pt={8} pb={2} justifyContent="center" spacing={2}>
                {displayData.application_status_id === status.application.unprocessed && (
                  <Grid item>
                    <LargeButton id="ApplicationDeclineBtn" color="gray" mode="btn1" onClick={rejectOffer}>
                      見送り
                    </LargeButton>
                  </Grid>
                )}
                <Grid item>
                  <Link
                    to={`/offer/create?application=${displayData?.id}&project=${displayData?.project_id}&job_group=${displayData?.job_group_id}&client=${displayData?.client?.id}&worker=${displayData?.worker?.id}`}
                  >
                    <LargeButton id="ApplicationOfferBtn" color="primary">
                      オファー
                    </LargeButton>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      )}
      <Modal open={openCancelModal} title={'見送り'} onClose={() => onCloseCancelModal(false)} size={'sm'}>
        <RejectApplicationContent onClose={() => onCloseCancelModal()} data={displayData} />
      </Modal>
    </StyledApplicationDetailsForm>
  )
}

ApplicationDetailsForm.displayName = 'Application Details Info'

ApplicationDetailsForm.propTypes = {
  displayData: PropTypes.any,
}

export default ApplicationDetailsForm
