import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { actionClearModalValues } from 'store/projects/actionCreators'
import { getAllClientUsers, createOrCopyProject, updateProject } from 'services/projects'
import { InputAutoComplete, InputSelect, LargeButton, InputLabel, Label, Checkbox } from 'components'
import { status } from 'utils/config/status'
import { getMessages } from 'validation'

function ProjectModalForm(props) {
  const { action, onClose, selectedRow, statusList } = props
  const isClient = localStorage.getItem('mode').includes('client')
  const [isDisabled, setIsDisabled] = useState(selectedRow.client?.id || isClient ? false : true)
  const [newStatus, setNewStatus] = useState(selectedRow?.status_id)
  const [isEdited, setIsEdited] = useState(false)
  const { modalValues } = useSelector((state) => state.projects)
  const [hideClearButton, setHideClearButton] = useState(true)
  const [selectedClientUser, setSelectedClientUser] = useState({
    id: selectedRow?.client_user?.id,
    name: selectedRow?.client_user?.name,
  })
  const { user } = useSelector((state) => state.auth)
  const defaultClientId = user?.client_user?.client_id

  const rulesMsg = getMessages('ja')
  const dispatch = useDispatch()

  useEffect(() => {
    isClient && defaultClientId && dispatch(getAllClientUsers(defaultClientId))
  }, [])

  useEffect(() => {
    trigger('status')

    if (selectedRow.client?.id) {
      setHideClearButton(false)
    } else {
      setHideClearButton(true)
    }
  }, [newStatus])

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm()

  const validationRules = {
    project: {
      required: {
        value: String,
        message: rulesMsg.project_name_input,
      },
      maxLength: {
        value: 100,
        message: rulesMsg.max(100),
      },
    },
    client: {
      validate: (value) => {
        return (value !== null && Object.keys(value).length > 0) || rulesMsg.client_input
      },
    },
    clientUser: {
      validate: (value) => {
        return (value !== null && Object.keys(value).length > 0) || rulesMsg.contact_person_input
      },
    },
    status: {
      validate: () => {
        let hideError = true
        if (isEdited) {
          hideError = newStatus === status.project.open || selectedRow.can_close
        }
        return hideError || rulesMsg.project_status_error
      },
    },
  }

  const handleClient = (item) => {
    setIsDisabled(item?.id ? false : true)
    item?.id > 0 && dispatch(getAllClientUsers(item?.id))
    setSelectedClientUser({})

    if (item) {
      setHideClearButton(false)
    } else {
      setHideClearButton(true)
    }
  }

  const handleClientUser = (item) => {
    setSelectedClientUser(item)
  }

  const handleFormSubmit = (data) => {
    switch (action) {
      case 'register':
        handleRegisterProject(data)
        break
      case 'edit':
        handleUpdateProject(data)
        break
      case 'copy':
        handleCopyProject(data)
        break
      default:
        break
    }
  }

  const handleRegisterProject = (data) => {
    const { project, clientUser, client } = data
    const clientId = isClient ? defaultClientId : client.id
    const clientName = isClient ? user?.client_user?.client_name : client.name

    const formData = {
      name: project,
      client_id: clientId,
      client_name: clientName,
      client_user_id: clientUser.id,
    }

    dispatch(createOrCopyProject(formData)).then(() => {
      dispatch(actionClearModalValues())
    })
  }

  const handleUpdateProject = (data) => {
    const { project, clientUser, client, status } = data
    const clientId = isClient ? defaultClientId : client.id
    const clientName = isClient ? user?.client_user?.client_name : client.name

    const formData = {
      name: project,
      client_id: clientId,
      client_name: clientName,
      client_user_id: clientUser.id,
      status_id: status,
    }

    dispatch(updateProject(formData, selectedRow.id)).then(() => {
      dispatch(actionClearModalValues())
    })
  }

  const handleCopyProject = (data) => {
    const { project, clientUser, client, copy } = data
    const clientId = isClient ? defaultClientId : client.id
    const clientName = isClient ? user?.client_user?.client_name : client.name

    const formData = {
      name: project,
      client_id: clientId,
      client_name: clientName,
      client_user_id: clientUser.id,
      id: selectedRow.id,
      copy_jg: copy,
    }

    dispatch(createOrCopyProject(formData)).then(() => {
      dispatch(actionClearModalValues())
    })
  }

  const handleNewStatus = (item) => {
    setNewStatus(item)
    setIsEdited(true)
  }

  const copyContentLabel = action == 'copy' && (
    <Grid item xs={12} md={12} lg={12} pb={2}>
      <Label fontSize={19}>{`コピー元: ${selectedRow.name} (ID: ${selectedRow.id})`}</Label>
    </Grid>
  )

  const copyContentCheckbox = action == 'copy' && (
    <Grid item xs={12} md={12} lg={12} pb={4}>
      <Checkbox ref={register('copy')} name="copy" label="作業グループも一緒にコピーする" />
    </Grid>
  )

  const statusContentDropDown = action == 'edit' && (
    <Grid item xs={12} md={12} lg={12}>
      <InputSelect
        name="status"
        ref={register('status', validationRules.status)}
        error={errors && errors?.status ? true : false}
        errormsg={errors?.status?.message}
        label={'ステータス'}
        menuItems={statusList}
        value={action !== 'register' && (newStatus || parseInt(selectedRow.status_id))}
        placeholder={'ステータス'}
        onChange={handleNewStatus}
      />
    </Grid>
  )
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container>
        {copyContentLabel}
        {!isClient && (
          <Grid item xs={12} md={12} lg={12}>
            <InputAutoComplete
              ref={register('client', validationRules.client)}
              name="client"
              defaultinput={action !== 'register' ? { id: selectedRow.client?.id, name: selectedRow.client?.name } : {}}
              error={errors && errors?.client ? true : false}
              errorMsg={errors?.client?.message}
              label={'クライアント'}
              placeholder={'クライアント'}
              options={modalValues.clients}
              itemSelect={handleClient}
              isInit={true}
              isHideClearBtn={hideClearButton}
            />
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={12}>
          <InputAutoComplete
            ref={register('clientUser', validationRules.clientUser)}
            name="clientUser"
            defaultinput={selectedClientUser}
            error={errors && errors?.clientUser ? true : false}
            errorMsg={errors?.clientUser?.message}
            label={'担当者'}
            placeholder={'担当者'}
            options={modalValues.client_users}
            disabled={isDisabled}
            itemSelect={handleClientUser}
            isHideClearBtn={selectedClientUser ? false : true}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} pt={2}>
          <InputLabel
            ref={register('project', validationRules.project)}
            error={errors && errors?.project ? true : false}
            errorMsg={errors ? errors?.project?.message : null}
            defaultValue={action !== 'register' ? selectedRow.name : ''}
            name="project"
            label={'案件名'}
            placeholder={'例：PCリプレース'}
          />
        </Grid>
        {copyContentCheckbox || statusContentDropDown}
        <Grid item container xs={12} justifyContent="center" gap={2}>
          <Grid item xs={5} sm={4}>
            <LargeButton color={'gray'} onClick={onClose}>
              {'キャンセル'}
            </LargeButton>
          </Grid>
          <Grid item xs={5} sm={4}>
            <LargeButton type="submit">{action === 'register' ? '登録' : '保存'}</LargeButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

ProjectModalForm.propTypes = {
  action: PropTypes.string,
  onClose: PropTypes.func,
  projectDetails: PropTypes.object,
  selectedRow: PropTypes.object,
  statusList: PropTypes.array,
}

export default ProjectModalForm
