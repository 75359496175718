import React, { useState } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { sendForgotPasswordEmail } from 'services/auth'
import { hideNotification } from 'store/notification/actionCreators'
import ForgotPaswordIcon from 'assets/svg/ForgotPaswordIcon.svg'
import ForgotPasswordSuccessIcon from 'assets/svg/ForgotPasswordSuccessIcon.svg'
import EmailPasswordResetIcon from 'assets/svg/EmailPasswordResetIcon.svg'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { styled } from '@mui/material/styles'
import { getMessages } from 'validation'

//components
import { Label, Input, LargeButton, Icon, SubHeading, P, Link } from 'components'

import PropTypes from 'prop-types'

const StyledForgotPasswordForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& .ForgotPassword-backLabel': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}))

const ForgotPasswordForm = (props) => {
  const dispatch = useDispatch()
  const rulesMsg = getMessages('ja')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const mode = localStorage.getItem('mode')
  const isClient = mode === 'client'

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm()

  const validationRules = {
    email_address: {
      required: {
        value: String,
        message: rulesMsg.email_credential,
      },
      pattern: {
        value: /\S+@\S+\.\S+/, // Regex Email Validation
        message: rulesMsg.email_pattern,
      },
    },
  }

  const handleForgotPassword = (data) => {
    setLoading(true)
    setEmailAddress(data.email_address)
    dispatch(sendForgotPasswordEmail(data))
      .then(() => {
        setSuccess(true)

        props.isSuccess('success')
      })
      .catch((e) => {
        const { code } = e.response.data
        // handle API validation error
        if (code === 422) {
          dispatch(hideNotification())
          setError('email_address', {
            message: rulesMsg.invalid_input,
          })
        }
      })
      .finally(() => setLoading(false))
  }

  const clientPasswordSuccessContent = (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label>{`${emailAddress} へ承認用メールを送信しました。`}</Label>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label>{'メールをご確認いただき、記載されたURLをクリックして承認をお願いいたします。 '}</Label>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} display={'inline-flex'} justifyContent={'center'}>
        <Label>{`メールが届かない場合、`}</Label>
        <Link to="/register">{'こちら'}</Link>
        <Label>{`から再度メールアドレスを登録ください。`}</Label>
      </Grid>
    </Grid>
  )

  const setConfirmButton = (mode) => {
    switch (mode) {
      case 'opc':
        return (
          <Link to="/login">
            <LargeButton id="ForgotPasswordConfirmBtn">{'ログイン画面に戻る'}</LargeButton>
          </Link>
        )
      case 'wkr':
        return (
          <Link to="/login" pt={6}>
            <Label color="light_gray" pt={5} className="ForgotPassword-backLabel">
              <KeyboardBackspaceIcon fontSize="small" />
              {'ログインにもどる'}
            </Label>
          </Link>
        )
      default:
        return
    }
  }

  return (
    <StyledForgotPasswordForm container justifyContent="center" alignItems="center" textAlign="center" py={2}>
      <Grid item>
        <Icon
          source={success ? (mode === 'wkr' ? EmailPasswordResetIcon : ForgotPasswordSuccessIcon) : ForgotPaswordIcon}
        ></Icon>
        <SubHeading color="secondary" id={'ForgotPassword-Title'} display="block" bold="true" pt={success ? 2 : 1}>
          {success ? 'メールをご確認ください。' : 'パスワードをお忘れですか？'}
        </SubHeading>
        <Grid pt={success ? 2 : 1} pb={success ? 2 : 0}>
          {!success && (
            <>
              <P>
                ご利用中のメールアドレスを入力してください
                <br />
                パスワード再設定のためのURLをお送りいたします。
              </P>
            </>
          )}
          {success &&
            (mode === 'client' ? (
              clientPasswordSuccessContent
            ) : (
              <Label>{'入力されたメールアドレスに再設定リンクをお送りしました。'}</Label>
            ))}
        </Grid>
        {!success && (
          <form onSubmit={handleSubmit(handleForgotPassword)} className="form">
            <Grid py={2}>
              <Input
                id="ForgotPasswordEmailInput"
                name="email_address"
                ref={register('email_address', validationRules.email_address)}
                error={errors && errors.email_address ? true : false}
                errorMsg={errors ? errors?.email_address?.message : null}
                type="text"
                placeholder={isClient ? 'メールアドレス' : 'メールアドレスを入力してください'}
                required
              />
            </Grid>
            <LargeButton type="submit" id="ForgotPasswordBtn" maxwidth="inherit">
              {loading ? <CircularProgress size="1.5rem" color="white" /> : '再設定メールを送信'}
            </LargeButton>
          </form>
        )}
        {success ? (
          setConfirmButton(mode)
        ) : (
          <Link to="/login" pt={6}>
            <Label fontSize={18} color="light_gray" pt={5} className="ForgotPassword-backLabel">
              <KeyboardBackspaceIcon fontSize="small" />
              {'ログインにもどる'}
            </Label>
          </Link>
        )}
      </Grid>
    </StyledForgotPasswordForm>
  )
}

ForgotPasswordForm.propTypes = {
  isSuccess: PropTypes.func,
}

export default ForgotPasswordForm
