import React, { useState, useEffect } from 'react'
import { Grid, Box, Stack, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import InnerHTML from 'dangerously-set-html-content'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined'
import _without from 'lodash/without'
import * as dayjs from 'dayjs'
import 'dayjs/locale/ja'
import SuccesCheckIcon from 'assets/svg/SuccesCheckIcon.svg'
//store
import { setOfferFormData, setConfirming, setOfferAction } from 'store/jobs/actionCreators'
//services
import { readOffer, confirmOffer as apiConfirmOffer, rejectAllOffer } from 'services/jobs'
//componets
import {
  Label,
  Heading,
  SubHeading,
  LargeButton,
  Banner,
  ContentDisplay,
  CardGray,
  LabelDisplay,
  SmallBanner,
  InputMultiCheckbox,
  Checkbox,
  RecipientsDisplay,
  OptionsDisplay,
  Icon,
} from 'components'
//common
import { settings, status } from 'utils/config/status'
import { dateFormat } from 'utils/common'
import { formatAddress, formatTimeString, formatAmount, formatWorkerProvidedItems } from 'utils/helper'

const StyledOfferDetailsForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .DateTimeBanner  .MuiChip-label': {
    height: 'inherit',
    width: '100%',
  },
  '& .CheckboxWrapper, .DateWrapper': {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, auto)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, auto)',
    },
    [theme.breakpoints.down(310)]: {
      display: 'block',
    },
  },
  '& .OfferAcceptBtn': {
    marginBottom: 20,
  },
  '& .DateNotes': {
    marginTop: -20,
  },
}))

const StyledLabel = styled(Label)(() => {
  return {
    position: 'inherit',
    overflowWrap: 'break-word',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
    [`& .iconButton`]: {
      position: 'absolute',
      marginTop: -8,
    },
  }
})

const OfferDetailsForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const formData = useSelector((state) => state.jobs.formData)
  const offerFormData = useSelector((state) => state.jobs.offerForm)
  const offerAction = useSelector((state) => state.jobs.offerAction)
  const isConfirming = useSelector((state) => state.jobs.is_confirming)
  const displayData = formData?.formatted
  const [jobDates, setJobDates] = useState([])
  const [selectedDates, setSelectedDates] = useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [readMore, setReadMore] = useState(false)
  const [titleMode, setTitleMode] = useState('details')
  const [offerName, setOfferName] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const isAccept = offerAction === 'accept' ? true : false
  const isReject = offerAction === 'reject' ? true : false

  //Reset selected dates when page go back on other page
  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        const currentPath = history.location.pathname
        if (!(currentPath.includes('/offer/show') || currentPath.includes('/offer/confirmation'))) {
          setSelectedDates([])
          setAllSelected(false)
          dispatch(setOfferFormData({ ...offerFormData, selected_dates: [], unselected_dates: [] }))
        }
      }
    }
  }, [history])

  useEffect(() => {
    //for details show
    let id = props?.match?.params?.id
    if (id) {
      dispatch(readOffer(id))
    }

    let confirmation = props.location.pathname.includes('confirmation')
    //for confirmation
    if (confirmation) {
      setTitleMode('confirmation')
      if (!isConfirming || !displayData) {
        history.goBack()
      }
      return () => {
        dispatch(setConfirming(false))
      }
    } else {
      setReadMore(true)
    }
  }, [dispatch])

  //set browser title
  useEffect(() => {
    setOfferName(displayData?.job_data?.name)
  }, [displayData])

  useEffect(() => {
    if (!isEmpty(displayData)) props.setTitle(offerName, titleMode)
  }, [titleMode, offerName])

  //format job dates
  useEffect(() => {
    const formatedJobDates = displayData?.job_dates.map((value) => {
      return {
        id: value,
        name: dayjs(value)
          .locale('ja')
          .format(dateFormat + ' (dd)'),
      }
    })
    setJobDates(formatedJobDates)
  }, [displayData?.job_dates])

  //get offer confirmation data
  useEffect(() => {
    setSelectedDates(offerFormData?.selected_dates || [])
    if (Object.keys(offerFormData).length > 0) {
      setAllSelected(displayData?.job_dates?.length === offerFormData?.selected_dates?.length ? true : false)
    }
  }, [dispatch, offerFormData])

  //handle select all dates
  const handleSelectAll = (e) => {
    setSelectedDates(e.target.checked ? displayData?.job_dates : [])
    setAllSelected(e.target.checked)
  }

  //handle select dates
  const handleSelectDate = (e) => {
    let selected = selectedDates
    if (!e.target.checked) {
      selected = _without(selectedDates, e.target.value)
    } else {
      selected.push(e.target.value)
    }
    selected?.sort((a, b) => {
      return new Date(a) - new Date(b)
    })
    setSelectedDates([...selected])
    setAllSelected(selected?.length === displayData?.job_dates?.length ? true : false)
  }

  //read more or less
  const readMoreLess = () => {
    setReadMore(!readMore)
  }

  //offer actions
  const setAction = (mode) => {
    let formUnselectedDates = []
    let formSelectedDates = []
    if (mode === 'reject') {
      formUnselectedDates = _without(displayData?.job_dates)
      formSelectedDates = []
    } else {
      formUnselectedDates = _without(displayData?.job_dates, ...selectedDates)
      formSelectedDates = selectedDates
    }
    let formData = {
      id: displayData?.id,
      mode: localStorage.getItem('mode'),
      selected_dates: formSelectedDates,
      unselected_dates: formUnselectedDates,
    }
    dispatch(setOfferFormData(formData))
    dispatch(setOfferAction(mode))
    dispatch(setConfirming(true))
    history.push('/offer/confirmation')
  }

  //handle accept offer
  const confirmOffer = () => {
    setAction('accept')
  }

  //handle reject offer
  const rejectOffer = () => {
    setAction('reject')
  }

  //handle confirm offer actions
  const offerConfirmAction = async () => {
    let actionApi = apiConfirmOffer(offerFormData)
    if (isReject) {
      actionApi = rejectAllOffer(offerFormData?.id)
    }
    await dispatch(actionApi)
      .then(() => {
        setConfirmed(true)
        setTitleMode('confirmed')
      })
      .catch(() => {
        history.goBack()
      })
  }

  //get offer name format
  const getOfferName = (name, visibility) => {
    return (
      <React.Fragment>
        {name}
        {visibility == settings.limited_public && (
          <>
            &emsp;
            <SmallBanner label={visibility} color="warning" width="fit-content" />
          </>
        )}
      </React.Fragment>
    )
  }
  //get updated status date time
  const getDateTimeComponent = (date, time) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem color="gray" sx={{ borderColor: '#f7f0f0a1' }} />}
        height="inherit"
      >
        <Box width={7 / 12} alignItems="center" display="inline-flex">
          <CalendarMonthOutlinedIcon />
          <Label>&nbsp;{date}</Label>
        </Box>
        <Box width={5 / 12} alignItems="center" display="inline-flex" p={1}>
          <ScheduleOutlinedIcon />
          <Label>&nbsp;{time}</Label>
        </Box>
      </Stack>
    )
  }

  return (
    <StyledOfferDetailsForm container className={confirmed ? 'Confirmation' : ''}>
      {confirmed && (
        <Grid item container justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} sm={10} mt={4}>
            <Icon source={SuccesCheckIcon} />
          </Grid>
          <Grid item xs={12} sm={10} py={3}>
            <SubHeading color="secondary">オファー回答完了</SubHeading>
          </Grid>
          <Grid item xs={12} pb={3} textAlign="center">
            <Label>オファーに回答しました。</Label>
            <Label>My 作業から承諾した作業が確認できます。</Label>
          </Grid>
        </Grid>
      )}
      {!confirmed && (
        <Grid item container>
          <Grid item xs={12} mb={6}>
            {!isConfirming && <Heading>オファー詳細</Heading>}
            {isConfirming && <Heading>オファー回答確認</Heading>}
          </Grid>
          {isConfirming && (
            <Grid item xs={12} mb={7}>
              <SubHeading>下記の通りオファーに回答しますか？</SubHeading>
            </Grid>
          )}
          {!isConfirming && (
            <Grid item container xs={12} spacing={2} mb={6}>
              <Grid item xs={12} sm={4} md={4}>
                <Label pb={1}>ステータス</Label>
                <Banner
                  label={
                    displayData?.status_id === status.job.before_job_start
                      ? status?.name.offer.before_job_start
                      : displayData?.status
                  }
                  variant="outlined"
                  color="success"
                  bold="true"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Label pb={1}>ステータス更新日</Label>
                <Banner
                  label={getDateTimeComponent(displayData?.status_updated_date, displayData?.status_updated_time)}
                  variant="outlined"
                  color="gray"
                  bold="true"
                  mode="dark"
                  className="DateTimeBanner"
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <CardGray>
              <Grid container justifyContent="space-between" px={{ sm: 2, md: 6 }} pb={3}>
                <ContentDisplay
                  label="作業名:"
                  data={getOfferName(displayData?.job_data?.name, displayData?.job_data?.job_group_visibility)}
                />
                <ContentDisplay label="作業担当者:" data={displayData?.job_data?.pic_name} />
                <ContentDisplay label="作業日:" data={'下記【オファー作業日】を参照'} />
                <ContentDisplay
                  label="作業時間:"
                  data={`${formatTimeString(displayData?.job_data?.start_time)} ~ ${formatTimeString(
                    displayData?.job_data?.end_time
                  )}`}
                />
                <ContentDisplay label="休憩時間:" data={displayData?.job_data?.break_time} />
                <ContentDisplay label="作業場所:" data={formatAddress(displayData?.job_data)} />
                <ContentDisplay
                  label="作業内容:"
                  data={<InnerHTML className="InnerHtml" html={displayData?.job_data?.description} />}
                />
                <ContentDisplay label="作業費(税抜き):" data={formatAmount(displayData?.job_data?.fee)} />
                {readMore && (
                  <React.Fragment>
                    <ContentDisplay label="手当:" data={displayData?.job_data?.allowance} />
                    <ContentDisplay label="交通費:" data={displayData?.job_data?.toll_fee} />
                    <ContentDisplay label="高速代:" data={displayData?.job_data?.highway_fee} />
                    <ContentDisplay label="駐車場代:" data={displayData?.job_data?.parking_fee} />
                    <ContentDisplay label="宿泊費:" data={displayData?.job_data?.overnight_stay_fee} />
                    <ContentDisplay label="その他経費:" data={displayData?.job_data?.other_fee} />
                    <Grid item container mt={2} spacing={2}>
                      <Grid item xs={12} sm={5.75} md={5} lg={4}>
                        <Label color="gray">{'キャンセル費:'}</Label>
                      </Grid>
                      <Grid item xs={12} sm={6.25} md={7} lg={8}>
                        <StyledLabel>
                          <pre>
                            <LabelDisplay md={-17} type="info" className="LabelInfo">
                              {'クライアントが作業をキャンセルした場合は以下に則ってキャンセル費が支払われます。'}{' '}
                              <br />
                              {'・1週間前キャンセル、作業費20%支払い '} <br />
                              {'・1〜3日前キャンセル、作業費50%支払い'} <br />
                              {'・当日キャンセル、作業費100%支払い'}
                            </LabelDisplay>
                          </pre>
                        </StyledLabel>
                      </Grid>
                    </Grid>
                    <OptionsDisplay
                      label="事前支給品:"
                      textOption="その他"
                      text={displayData?.job_data?.provided_to_worker_other}
                      options={formatWorkerProvidedItems(displayData?.job_data?.provided_documents)}
                    />
                    <OptionsDisplay
                      label="事前送付品:"
                      textOption="作業に使用する部材"
                      text={displayData?.job_data?.items_provided_description}
                      options={formatWorkerProvidedItems(displayData?.job_data?.provided_items)}
                    />
                    <OptionsDisplay
                      label="ワーカー持参物:"
                      text={displayData?.job_data?.worker_tool_notes}
                      options={formatWorkerProvidedItems(displayData?.job_data?.worker_tools)}
                    />
                    <ContentDisplay label="服装:" data={displayData?.job_data?.attire} />
                    <ContentDisplay label="エスカレーション先:" data={displayData?.job_data?.escalation_contact} />
                    <Grid item container pt={0} spacing={2}>
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <Label color="gray">{''}</Label>
                      </Grid>
                      <Grid item xs={12} sm={9} md={9} lg={9}>
                        <StyledLabel>
                          <pre>{displayData?.job_data?.invoice_generated ? '✓ 上記キャンセル費を承諾する' : ''}</pre>
                        </StyledLabel>
                      </Grid>
                    </Grid>
                    <ContentDisplay
                      label="お客様現地ご担当者様連絡先:"
                      data={displayData?.job_data?.onsite_client_contact}
                    />
                    <ContentDisplay label="名乗り:" data={displayData?.job_data?.onsite_name} />
                    <ContentDisplay label="当日同行者:" data={displayData?.job_data?.fellow} />
                    <ContentDisplay label="その他連絡事項:" data={displayData?.job_data?.remarks} />
                    <RecipientsDisplay
                      label={'必要な連絡と、担当者以\n外に追加で必要な宛先:'}
                      data={displayData?.job_data}
                    />
                    <ContentDisplay label="オファーコメント:" data={displayData?.comment} />
                    <ContentDisplay label="オファー日:" data={displayData?.offer_date} />
                  </React.Fragment>
                )}
              </Grid>
            </CardGray>
          </Grid>

          {isConfirming && (
            <Grid item xs={12} textAlign="center" my={4}>
              <LargeButton
                id="OfferReadMoreLessBtn-ID"
                color="success"
                mode="light"
                variant="outlined"
                onClick={() => {
                  readMoreLess()
                }}
              >
                {readMore ? '閉じる' : 'もっと見る'}
              </LargeButton>
            </Grid>
          )}
          {!isConfirming && (
            <Grid item container xs={12} mt={4}>
              <Grid item xs={12} mb={2} mt={4}>
                <SubHeading color="secondary">オファー作業日</SubHeading>
              </Grid>
              {displayData?.status_id === status.job.offer && (
                <React.Fragment>
                  <Grid item xs={12} className="JobDatesWrapper">
                    <Checkbox
                      id="Checkbox-select-all-ID"
                      name="include_past"
                      checked={allSelected}
                      label="全ての日程を選択"
                      onChange={handleSelectAll}
                    />
                    <InputMultiCheckbox
                      className="jobDates"
                      name={'job_dates' + '[]'}
                      items={jobDates}
                      label=""
                      onChange={(e) => handleSelectDate(e)}
                      selected={selectedDates}
                    />
                    <Label className="DateNotes">※ チェックのない日付のオファーは自動で辞退となります。</Label>
                  </Grid>
                  <Grid
                    item
                    container
                    mt={2}
                    mb={2}
                    spacing={2}
                    justifyContent={{ xs: 'center', sm: 'end' }}
                    display={{ xs: 'block', sm: 'inhiret' }}
                  >
                    <Grid item display="grid">
                      <LargeButton
                        className="OfferAcceptBtn"
                        id="OfferAcceptBtn-ID"
                        color="primary"
                        onClick={() => {
                          confirmOffer()
                        }}
                        disabled={selectedDates?.length > 0 ? false : true}
                      >
                        チェックした日付のオファーを承諾する
                      </LargeButton>
                      <LargeButton
                        id="OfferRejectBtn-ID"
                        color="gray"
                        mode="btn1"
                        maxwidth="inhiret"
                        onClick={() => rejectOffer()}
                      >
                        全日程を辞退する
                      </LargeButton>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              {(displayData?.status_id === status.job.before_job_start ||
                displayData?.status_id === status.job.offered_declined) && (
                <Grid item container xs={12} my={2}>
                  <Grid item xs={12} mb={2}>
                    承諾した日程
                  </Grid>
                  <Grid item xs={12} mb={2} className="DateWrapper">
                    {displayData?.selected_job_dates
                      ?.filter((date) => date != '')
                      ?.map((item, i) => {
                        return (
                          <Label key={i}>
                            {dayjs(item)
                              .locale('ja')
                              .format(dateFormat + ' (dd)')}
                          </Label>
                        )
                      })}
                  </Grid>
                  <Grid item xs={12} mb={2} mt={4}>
                    辞退した日程
                  </Grid>
                  <Grid item xs={12} mb={2} className="DateWrapper">
                    {displayData?.unselected_job_dates
                      ?.filter((date) => date != '')
                      ?.map((item, i) => {
                        return (
                          <Label key={i}>
                            {dayjs(item)
                              .locale('ja')
                              .format(dateFormat + ' (dd)')}
                          </Label>
                        )
                      })}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {isConfirming && !confirmed && (
            <React.Fragment>
              <Grid item container xs={12} my={2}>
                <Grid item xs={12} mb={2}>
                  <Label bold="true">
                    {isAccept ? '以下の作業日程のオファーを承諾する' : '承諾する日程はありません。全て辞退となります。'}
                  </Label>
                </Grid>
                {isAccept && (
                  <React.Fragment>
                    <Grid item xs={12} mb={2} className="DateWrapper">
                      {offerFormData?.selected_dates?.map((item, i) => {
                        return (
                          <Label key={i}>
                            {dayjs(item)
                              .locale('ja')
                              .format(dateFormat + ' (dd)')}
                          </Label>
                        )
                      })}
                    </Grid>
                    <Grid item xs={12}>
                      <Label>※ 上記以外の日程のオファーは自動で辞退となります。</Label>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>

              <Grid
                item
                container
                my={2}
                spacing={2}
                justifyContent={{ xs: 'center', sm: 'end' }}
                display={{ xs: 'block', sm: 'inhiret' }}
              >
                <Grid item>
                  <LargeButton id="OfferBackBtn-ID" color="gray" mode="btn1" onClick={history.goBack}>
                    戻る
                  </LargeButton>
                </Grid>
                <Grid item>
                  <LargeButton id="OfferConfirmBtn-ID" color="primary" onClick={() => offerConfirmAction()}>
                    確定
                  </LargeButton>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      )}
    </StyledOfferDetailsForm>
  )
}

OfferDetailsForm.displayName = 'Job Group Details'

OfferDetailsForm.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
  setTitle: PropTypes.func,
}

export default OfferDetailsForm
