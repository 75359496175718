import React from 'react'
import PropTypes from 'prop-types'
import { Fade, Grid, useScrollTrigger } from '@mui/material'

function ScrollToTop(props) {
  const { children, window, topId, threshold } = props
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: threshold,
  })

  const handleClick = () => {
    const anchor = document.querySelector(topId)
    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }
  return (
    <Fade in={trigger}>
      <Grid
        item
        conatiner
        onClick={handleClick}
        role="presentation"
        position="fixed"
        bottom={'10%'}
        right={{ xs: 10, sm: 20, md: '15%' }}
      >
        {children}
      </Grid>
    </Fade>
  )
}

ScrollToTop.defaultProps = {
  threshold: 1000,
}

ScrollToTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
  topId: PropTypes.string,
  threshold: PropTypes.number,
}

export default ScrollToTop
