import React, { useState, useEffect } from 'react'
import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Search as SearchIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { formatNameID } from 'utils/helper'
import queryString from 'query-string'
import {
  Heading,
  LargeButton,
  TableList,
  InputSelect,
  InputLabel,
  InputDatePicker,
  InputMultiSelect,
  Link,
} from 'components'
import { useForm } from 'react-hook-form'
import { routes } from 'router/routes/clientRoutes'
import { getPrefecturesList, getAllStatusList, getAllJobOptionsList } from 'services/common'
import { resetCurrentClientSearchForm } from 'store/jobs/actionCreators'
import { getStatusByType } from 'utils/helper'
import { useControlledMultiSelectFormField, useMatchLastLocation } from 'utils/hooks'
import { clientJobListRequiredStatusId, clientJobListDefaultStatusId, status } from 'utils/config/status'
import { getMessages } from 'validation'

import {
  datePresets,
  thisDay,
  thisMonthStart,
  thisMonthEnd,
  prevMonthStart,
  prevMonthEnd,
  nextMonthStart,
  nextMonthEnd,
  dateFormat,
} from 'utils/common'

const JobListForm = (props) => {
  const [dateFromValue, setDateFromValue] = useState(thisMonthStart)
  const [dateToValue, setDateToValue] = useState(thisMonthEnd)
  const [selectedRow, setSelectedRow] = useState({})
  const prefecturesList = useSelector((state) => state.common.prefectureslist) ?? []
  const rawStatusOptions = useSelector((state) => state.common.statusList) ?? []
  const searchValues = useSelector((state) => state.jobs.searchClient)
  const dispatch = useDispatch()
  const history = useHistory()
  const rulesMsg = getMessages('ja')
  const statusList = getStatusByType(rawStatusOptions, 'Job', clientJobListRequiredStatusId)
  const isFromJobDetails = useMatchLastLocation(routes.jobDetail)
  const isFromJobGroupDetail = useMatchLastLocation(routes.jobGroupDetail)
  const isFromJobGroupList = useMatchLastLocation(routes.jobGroupList)
  const { state } = props.location
  const isFromLogin = state?.fromLogin ?? false
  const dateTodayPresetItem = 4
  const [datePresetValue, setdatePresetValue] = useState(searchValues?.date_preset || 1)
  const [prefectureValue, setPrefectureValue] = useState(searchValues?.prefecture || 0)

  useEffect(() => {
    if (!isFromJobDetails && !isFromJobGroupDetail && !isFromJobGroupList) {
      dispatch(resetCurrentClientSearchForm())
    }

    dispatch(getAllJobOptionsList())
    dispatch(getPrefecturesList())
    dispatch(getAllStatusList())
    isFromLogin && handleDatePreset(dateTodayPresetItem)
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      status_id: clientJobListDefaultStatusId,
    },
  })
  useEffect(() => {
    setPrefectureValue(searchValues?.prefecture || 0)

    reset({
      ...searchValues,
      status_id:
        typeof searchValues.status_id == 'string' ? searchValues.status_id.split(',').map((item) => Number(item)) : [],
      jobName: searchValues?.name ?? '',
      workerName: searchValues?.worker_name ?? '',
      jobGroupName: searchValues?.job_group_name ?? '',
      projectName: searchValues?.project_name ?? '',
    })
  }, [searchValues])

  useEffect(() => {
    setDateFromValue(searchValues?.start_date)
    setDateToValue(searchValues?.end_date)
  }, [searchValues?.start_date, searchValues?.end_date])

  const validationRules = {
    jobName: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    workerName: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    jobGroupName: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    projectName: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    start_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
    end_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
  }

  const handleSelectedRow = (rowData) => {
    setSelectedRow(rowData)
  }

  const handleDatePreset = (item) => {
    switch (item) {
      case 2: //prev month
        setDateFromValue(prevMonthStart)
        setDateToValue(prevMonthEnd)
        break
      case 3: //next month
        setDateFromValue(nextMonthStart)
        setDateToValue(nextMonthEnd)
        break
      case 4: //this day
        setDateFromValue(thisDay)
        setDateToValue(thisDay)
        break
      case 1: //this month
        setDateFromValue(thisMonthStart)
        setDateToValue(thisMonthEnd)
        break
      default:
        break
    }

    setdatePresetValue(item)
  }

  const onDateChange = (newVal, type) => {
    setdatePresetValue(5)
    if (type == 'start_date') {
      setDateFromValue(newVal)
      if (dayjs(newVal).diff(dayjs(dateToValue)) >= 1) {
        setDateToValue(newVal)
      }
    } else {
      setDateToValue(newVal)
      if (dayjs(dateFromValue).diff(dayjs(newVal)) >= 1) {
        setDateFromValue(newVal)
      }
    }
  }

  const handleSearch = (data) => {
    data['date_preset'] = datePresetValue
    props.handlePageSearch(data)
  }

  const handleDetail = () => {
    history.push({ pathname: '/job/show/' + selectedRow?.id, state: { fromJobList: true } })
  }
  const handleEdit = () => {
    history.push('/job/update/' + selectedRow?.id)
  }
  const handleCopy = () => {
    const application = selectedRow?.application
    history.push(
      queryString.stringifyUrl({
        url: '/offer/create',
        query: {
          project: selectedRow?.project_id,
          job_group: selectedRow?.job_group_id,
          client: selectedRow?.client_id,
          worker: selectedRow?.worker_id,
          job: selectedRow?.id,
          with_applicants: application,
        },
      })
    )
  }

  //Get table action list
  const getActionList = () => {
    return [
      {
        handler: handleDetail,
        name: '閲覧',
      },
      selectedRow?.status_id === status.job.before_job_start
        ? {
            handler: (handler) => handleEdit(handler),
            name: '編集',
          }
        : null,
      {
        handler: handleCopy,
        name: 'コピーしてオファー ',
      },
    ].filter((val) => val != null)
  }

  const formatData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        job_name: (
          <Link
            to={{ pathname: `/job/show/${item?.id}`, state: { fromJobList: true } }}
            sx={{ textDecoration: 'underline' }}
          >
            {item?.name}
          </Link>
        ),
        worker_name: (
          <Link to={`/worker/show/${item?.id}`} sx={{ textDecoration: 'underline' }}>
            {item?.has_before_job_start_log === true
              ? item?.worker_name
              : formatNameID(item?.worker_screen_name, item?.worker_id)}
          </Link>
        ),
        job_group_name: (
          <Link to={`/job-group/show/${item?.job_group_id}`} sx={{ textDecoration: 'underline' }}>
            {item?.job_group_name}
          </Link>
        ),
      }
    })
  }

  const handleSetPrefecture = (value) => {
    setPrefectureValue(value)
  }

  const statusIdRef = useControlledMultiSelectFormField({ register, watch, name: 'status_id' })

  return (
    <Grid container>
      <Grid item xs={12}>
        <Heading>作業一覧</Heading>
      </Grid>
      <Grid item xs={12} mt={{ xs: 0, sm: 4 }}>
        <form onSubmit={handleSubmit(handleSearch)} className="JobList-form">
          <Grid container columnSpacing={1}>
            <Grid item xs={12} md={3}>
              <InputLabel
                name="jobName"
                id="jobName-ID"
                ref={register('jobName', validationRules.jobName)}
                label="作業名・ID"
                error={errors && errors.jobName ? true : false}
                errorMsg={errors ? errors?.jobName?.message : null}
                value={searchValues?.name}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel
                name="workerName"
                id="workerName-ID"
                ref={register('workerName', validationRules.workerName)}
                label="ワーカー名・ID"
                error={errors && errors.workerName ? true : false}
                errorMsg={errors ? errors?.workerName?.message : null}
                value={searchValues?.worker_name}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel
                name="jobGroupName"
                id="jobGroupName-ID"
                ref={register('jobGroupName', validationRules.jobGroupName)}
                label="作業グループ名・ID"
                error={errors && errors.jobGroupName ? true : false}
                errorMsg={errors ? errors?.jobGroupName?.message : null}
                value={searchValues?.job_group_name}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel
                name="projectName"
                id="projectName-ID"
                ref={register('projectName', validationRules.projectName)}
                label="案件名・ID"
                error={errors && errors.projectName ? true : false}
                errorMsg={errors ? errors?.projectName?.message : null}
                value={searchValues?.project_name}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6} md={1.5} alignItems={'center'}>
              <InputSelect
                id="prefecture-ID"
                name="prefecture"
                ref={register('prefecture')}
                label="都道府県"
                menuItems={[{ id: 0, name: 'すべて' }, ...prefecturesList] ?? []}
                onChange={handleSetPrefecture}
                value={prefecturesList?.length > 0 ? parseInt(prefectureValue) : 0}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1.5} alignItems={'center'}>
              <InputSelect
                id="jobList-date_preset-ID"
                ref={register('date_preset')}
                name="date_preset"
                label="作業日"
                menuItems={datePresets}
                value={datePresetValue}
                onChange={handleDatePreset}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5} alignItems={'center'}>
              <InputDatePicker
                id="jobList-start_date-ID"
                ref={register('start_date', validationRules.start_date)}
                error={errors && errors.start_date ? true : false}
                errorMsg={errors ? errors?.start_date?.message : null}
                name="start_date"
                label={'開始日'}
                color="primary"
                inputFormat={dateFormat}
                value={dateFromValue && dateFromValue !== ' ' ? dayjs(dateFromValue) : ''}
                onChange={(newVal) => onDateChange(newVal, 'start_date')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5} alignItems={'center'}>
              <InputDatePicker
                id="jobList-end_date-ID"
                ref={register('end_date', validationRules.end_date)}
                error={errors && errors.end_date ? true : false}
                errorMsg={errors ? errors?.end_date?.message : null}
                name="end_date"
                label={'終了日'}
                color="primary"
                inputFormat={dateFormat}
                value={dateToValue && dateToValue !== ' ' ? dayjs(dateToValue) : ''}
                onChange={(newVal) => onDateChange(newVal, 'end_date')}
                minDate={dayjs(dateFromValue)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2.3} alignItems={'center'}>
              <InputMultiSelect
                ref={statusIdRef}
                name="status_id"
                label={'ステータス'}
                menuItems={statusList ?? []}
                enableEmpty
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1} pt={{ md: 2.8 }}>
              <LargeButton
                type="submit"
                id="SearchBtn-ID"
                bold="true"
                color="warning"
                startIcon={
                  <SearchIcon
                    size={'sm'}
                    disabled={props.isLoading ? true : false}
                    style={{ color: 'rgb(255 107 9 / 55%)' }}
                  />
                }
              >
                検索
              </LargeButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}>
        <TableList
          title={`Job List`}
          headCells={props.headerCells}
          data={formatData(props.data)}
          totalPage={props.totalPage}
          totalCount={props.totalCount}
          handlePageChange={props.handlePageChange}
          handleSelectedRow={handleSelectedRow}
          isLoading={props.isLoading}
          handleSort={props.handleSort}
          sort={props.sort}
          sortBy={props.sortBy}
          currentPage={props.currentPage}
          action={true}
          actionList={getActionList()}
          pageFrom={props.pageFrom}
          pageTo={props.pageTo}
        />
      </Grid>
    </Grid>
  )
}

JobListForm.displayName = 'Job List Form'

JobListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
  location: PropTypes.any,
}

export default JobListForm
