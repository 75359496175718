// named routes
export const routes = {
  applicationDetails: '/application/show/:id',
  projectList: '/project_list',
}

export default [
  {
    path: '/worker_list',
    component: 'views/operation-center/Worker/WorkerList',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/worker/create',
    component: 'views/operation-center/Worker/WorkerCreateUpdate',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/worker/update/:id',
    component: 'views/operation-center/Worker/WorkerCreateUpdate',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/worker/confirmation',
    component: 'views/operation-center/Worker/WorkerConfirm',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/worker/:id',
    component: 'views/operation-center/Worker/WorkerDetail',
    auth: true,
    mode: 'opc',
  },
  {
    path: routes.projectList,
    component: 'views/operation-center/ProjectList',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job_group_list',
    component: 'views/operation-center/JobGroup/JobGroupList',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job_group/create/',
    component: 'views/operation-center/JobGroup/JobGroupCreateUpdate',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job_group/confirmation',
    component: 'views/operation-center/JobGroup/JobGroupConfirm',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job_group/create/:id',
    component: 'views/operation-center/JobGroup/JobGroupCreateUpdate',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job_group/update/:id',
    component: 'views/operation-center/JobGroup/JobGroupCreateUpdate',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job_group/show/:id',
    component: 'views/operation-center/JobGroup/JobGroupShow',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job_list',
    component: 'views/operation-center/JobList',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job/show/:id',
    component: 'views/operation-center/Jobs/JobDetails',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job/create',
    component: 'views/operation-center/Jobs/JobCreateUpdate',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job/create/:id',
    component: 'views/operation-center/Jobs/JobCreateUpdate',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job/update/:id',
    component: 'views/operation-center/Jobs/JobCreateUpdate',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/job/confirmation',
    component: 'views/operation-center/Jobs/JobConfirm',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/result_list',
    component: 'views/operation-center/ResultList',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/system-usage-fee/list',
    component: 'views/operation-center/SystemUsageList',
    auth: true,
    mode: 'opc',
  },
  {
    path: routes.applicationDetails,
    component: 'views/operation-center/Application/ApplicationDetails',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/client-user/show/:id',
    component: 'views/operation-center/ClientUserDetail',
    auth: true,
    mode: 'opc',
  },
  { path: '/application/list', component: 'views/operation-center/ApplicationList', auth: true, mode: 'opc' },
  {
    path: '/client/create',
    component: 'views/operation-center/Client/ClientCreateUpdate',
    auth: true,
    mode: 'opc',
  },

  {
    path: '/client/list',
    component: 'views/operation-center/ClientList',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/client-user/list',
    component: 'views/operation-center/ClientUserList',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/client-user/create',
    component: 'views/operation-center/ClientUserCreateUpdate/ClientUserCreateUpdate',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/client-user/update/:id',
    component: 'views/operation-center/ClientUserCreateUpdate/ClientUserCreateUpdate',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/client-user/confirmation',
    component: 'views/operation-center/ClientUserCreateUpdate/ClientUserConfirm',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/client/update/:id',
    component: 'views/operation-center/Client/ClientCreateUpdate',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/client/confirmation',
    component: 'views/operation-center/Client/ClientConfirm',
    auth: true,
    mode: 'opc',
  },
  {
    path: '/client/show/:id',
    component: 'views/operation-center/ClientDetail',

    auth: true,
    mode: 'opc',
  },
]
