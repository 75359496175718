import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  TableList,
  Modal,
  LargeButton,
  Icon,
  WorkerExpenseForm,
  WorkerExpenseFormModal,
  WorkerExpenseCompletion,
  WorkerExpenseConfirmation,
  SubHeading,
} from 'components'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { removeWorkerExpense, submitWorkerExpense, readWorkerJob } from 'services/jobs'
import DownloadIcon from 'assets/svg/download.svg'
import { downloadFile } from 'services/common'
import { status } from 'utils/config/status'

const ExpenseReportTab = (props) => {
  const { data, navigateTo } = props
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [selectedRow, setSelectedRow] = useState({})
  const [action, setAction] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [expenseStatusContent, setExpenseStatusContent] = useState()
  const [expenseReportContent, setExpenseReportContent] = useState()
  const isLoading = useSelector((state) => state.loader)
  const { reportStatus } = useSelector((state) => state.jobs)
  const jobId = location.pathname.split('/')[3]
  const dispatch = useDispatch()
  const isAccepted = parseInt(data?.expense_status_id) === status.expense.accepted
  const [isRequesting, setIsRequesting] = useState(false)

  useEffect(() => {
    switch (reportStatus) {
      case 'SUBMIT':
      case 'REJECT': {
        setIsSubmit(true)
        break
      }
      case 'ACCEPT': {
        setIsEdit(false)
        break
      }
      default: {
        setIsEdit(true)
        break
      }
    }
  }, [reportStatus])
  const addDownloadButton = (item, index) => {
    const { file_name: fileName, file_url: fileUrl } = item
    return (
      <Grid container justifyContent="space-between">
        <Grid item>{fileName}</Grid>
        {fileUrl && (
          <Grid item>
            <Icon source={DownloadIcon} id={'tableDownload' + index} onClick={(e) => handleDownload(e, item)} />
          </Grid>
        )}
      </Grid>
    )
  }
  const reportExpenses = data?.expenses.map((item, index) => {
    return {
      ...item,
      updated_at: item?.updated_date + ' ' + item?.updated_time,
      file_name: isEdit ? item.file_name : addDownloadButton(item, index),
    }
  })

  const headerCells = [
    { id: 'item', label: '品目', align: 'center' },
    { id: 'expense_category_name', label: '項目', align: 'center' },
    { id: 'price_formatted', label: '金額', align: 'center' },
    { id: 'file_name', label: 'ファイル名', align: 'center' },
    { id: 'updated_at', label: '更新日', align: 'center' },
  ]

  const modalTitle = {
    completion: '経費報告',
    submit: '経費報告',
    edit: '経費報告',
    delete: '経費報告',
    confirm: '経費報告',
  }

  const handleSubmitExpenseReport = () => {
    setAction('submit')
    setModalType('submit')
    setIsOpenModal(true)
  }

  const handleDownload = (e, rowData) => {
    dispatch(downloadFile({ file_path: rowData?.file_url }, rowData?.file_name))
  }

  const handleEdit = (e, rowData) => {
    setAction('edit')
    setModalType('edit')
    setSelectedRow(rowData)
    setIsOpenModal(true)
  }

  const handleDelete = (e, rowData) => {
    setAction('delete')
    setModalType('delete')
    setSelectedRow(rowData)
    setIsOpenModal(true)
  }

  const handleModalClose = () => {
    setIsOpenModal(false)
  }

  const handleModalConfirm = () => {
    switch (action) {
      case 'submit':
        setIsRequesting(true)
        dispatch(submitWorkerExpense(jobId)).finally(() => {
          dispatch(readWorkerJob(jobId, false)).finally(() => {
            setIsRequesting(false)
            setModalType('confirm')
            setIsEdit(false)
          })
        })
        break
      case 'delete':
        setIsRequesting(true)
        dispatch(removeWorkerExpense(jobId, selectedRow.id)).finally(() => {
          setIsRequesting(false)
          handleModalClose()
        })
        break
      default:
        break
    }
  }
  const getModalContent = () => {
    switch (modalType) {
      case 'edit':
        return (
          <WorkerExpenseFormModal
            title={'PCリプレース XX支店 (作業名)'}
            data={selectedRow}
            navigateTo={navigateTo}
            onClose={handleModalClose}
          />
        )
      case 'submit':
        return isSubmit ? (
          <WorkerExpenseCompletion
            onClose={handleModalClose}
            jobId={jobId}
            reportStatus={isSubmit}
            setIsEdit={setIsEdit}
            setModalType={setModalType}
          />
        ) : (
          <WorkerExpenseConfirmation
            title="経費報告"
            data={selectedRow}
            onClose={handleModalClose}
            onConfirm={handleModalConfirm}
            action={action}
            isRequesting={isRequesting}
          />
        )
      case 'delete':
        return (
          <WorkerExpenseConfirmation
            title="経費報告"
            data={selectedRow}
            onClose={handleModalClose}
            onConfirm={handleModalConfirm}
            action={action}
          />
        )
      case 'confirm':
        return (
          <Grid container justifyContent="center">
            <Grid item xs={12} textAlign={'center'} pt={4}>
              <SubHeading>経費報告が完了しました。</SubHeading>
            </Grid>
            <Grid item xs={6} pt={6} textAlign="center">
              <LargeButton id="SubmitReportCloseBtn" onClick={handleModalClose}>
                OK
              </LargeButton>
            </Grid>
          </Grid>
        )
    }
  }
  useEffect(() => {
    if (!isAccepted) {
      setExpenseStatusContent(
        !isEdit && !isAccepted ? (
          <Grid item xs={12} sm={4} md={3} lg={2} textAlign="end">
            <LargeButton onClick={() => setIsEdit(true)}>{'変更する'}</LargeButton>
          </Grid>
        ) : (
          <WorkerExpenseForm data={data} navigateTo={navigateTo} />
        )
      )
      setExpenseReportContent(
        data?.has_clock_out_rpt && isEdit && (
          <LargeButton id="SubmitExpenseReportsBtn" onClick={handleSubmitExpenseReport}>
            {isSubmit ? '以上の内容で経費を変更したことを報告する' : '以上の内容で経費報告する'}
          </LargeButton>
        )
      )
    } else {
      setExpenseStatusContent()
      setExpenseReportContent()
    }
  }, [isEdit, reportExpenses.length])
  const editActions = isEdit && !isAccepted
  return (
    <Grid container>
      <Grid item xs={12}>
        <TableList
          tableName={!isEdit && '報告済み経費'}
          title={`Job Report List`}
          headCells={headerCells}
          data={reportExpenses}
          isLoading={isLoading}
          noSort
          noPaginate
          handleDownload={handleDownload}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          isReportsActions={editActions ? { edit: editActions, delete: editActions } : false}
          no_data_msg="レポートは提出されていません"
        />
      </Grid>
      <Grid container justifyContent="flex-end" pt={2}>
        <Grid item container xs={12} justifyContent="flex-end">
          {expenseStatusContent}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" pt={2}>
        <Grid item xs={12} sm={6} md={5} lg={4} textAlign="center">
          {expenseReportContent}
        </Grid>
      </Grid>
      <Modal
        open={isOpenModal}
        title={modalTitle[action]}
        onClose={handleModalClose}
        size={modalType == 'edit' ? 'lg' : 'md'}
      >
        {getModalContent()}
      </Modal>
    </Grid>
  )
}

ExpenseReportTab.propTypes = {
  data: PropTypes.object,
  navigateTo: PropTypes.func,
}

export default ExpenseReportTab
