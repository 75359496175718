import React from 'react'
import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Tag = styled(Chip, {
  shouldForwardProp: (prop) => prop,
})(({ borderRadius }) => ({
  opacity: 1,
  borderRadius: borderRadius ?? 4,
}))

export const SmallOutlinedTag = (props) => {
  return <Tag {...props} variant="outlined" size="small"></Tag>
}
