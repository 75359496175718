const ja = {
  translation: {
    // define translations below
    auth: {
      required: 'この項目は必須です。',
      email: 'メール形式が無効です。',
      password: {
        minLength: 'パスワードは8文字以上である必要があります。',
        confirm: 'パスワードの確認が一致しません。',
        strong: 'パスワードには、大文字1文字、特殊文字1文字、および少なくとも8文字が含まれている必要があります。',
      },
    },
    browserTitle: {
      siteTitle: 'ワークオンサイト！',
      checkEmaillbl: 'メールをご確認ください。',
      forgotPassLbl: 'パスワードをお忘れですか？',
      termsAndCondition: '利用規約 ',
      privacyPolicy: 'プライバシーポリシー ',
      forgotPass: 'パスワードをお忘れの方',
      opc: {
        //common
        loginTitle: 'ログイン ',
        sentEmailPassReset: 'メール送信完了',
        passwordSet: 'パスワード設定',

        //worker
        workerList: 'ワーカー一覧 ',
        workerRegister: 'ワーカー登録',
        workerEdit: 'ワーカー編集',

        //workerEditRegister: 'ワーカー登録/編集 ',
        // workerConfirmation: 'ワーカー登録/編集確認',
        workerRegConfirmation: 'ワーカー登録確認',
        workerEditConfirmation: 'ワーカー編集確認',
        workerRegConfirmationSuccess: 'ワーカー登録完了',
        workerEditConfirmationSuccess: 'ワーカー編集完了',
        //workerConfirmationSucces: 'ワーカー登録/編集完了',

        //workList
        workList: '作業一覧',

        //project
        projectList: '案件一覧',

        //job group
        jobList: '作業グループ一覧 ',
        jobGroupCreate: '作業グループ登録',
        jobGroupEdit: '作業グループ編集',

        jobGroupRegConfirmation: '作業グループ登録確認',
        jobGroupEditConfirmation: '作業グループ編集確認',

        jobGroupRegConfirmationSuccess: '作業グループ登録完了',
        jobGroupEditConfirmationSuccess: '作業グループ編集完了',
        // jobGroupConfirmation: '作業グループ登録/編集確認',
        // jobConfirmationSucces: '作業グループ登録/編集完了',

        //job
        jobCreate: '作業登録',
        jobEdit: '作業編集',
        jobReportDetails: '実績報告：{{str}}',
        jobExpenseDetails: '経費報告：{{str}}',

        //work
        workRegConfirmation: '作業登録確認',
        workEditConfirmation: '作業編集確認',

        workRegConfirmationSuccess: '作業登録完了',
        workEditConfirmationSuccess: '作業編集完了',

        // workConfirmation: '作業登録/編集確認',
        // workConfirmationSucces: '作業登録/編集完了',

        //achievement
        achievementList: '実績一覧',

        // system usage list
        systemUsageList: 'システム利用料一覧',
        // application
        applicantDetails: '応募詳細',

        //client-ser
        clientUserDetail: '{{pic}}',
        clientUserList: 'クライアント担当者一覧',
        clientUserCreateUpdate: 'クライアント{{registrationType}}',
        clientUserCreateConfirm: 'クライアント担当者登録確認',
        clientUserUpdateConfirm: 'クライアント担当者編集確認',
        clientUserCreateComplete: 'クライアント登録完了',
        clientUserUpdateComplete: 'クライアント編集完了',

        //client
        clientCreateConfirm: 'クライアント登録確認',
        clientUpdateConfirm: 'クライアント編集確認',
        clientCreateComplete: 'クライアント登録完了',
        clientUpdateComplete: 'クライアント編集完了',
        clientList: 'クライアント一覧 | ワークオンサイト！',
        clientDetail: '{{companyName}}',
        clientCreate: 'クライアント登録',
        clientUpdate: 'クライアント編集',
      },

      wkr: {
        loginTitle: 'ワーカーログイン ',
        sentEmailPassReset: 'パスワード設定',
        sentEmailForgotPass: 'メール送信完了',
        passwordSet: 'パスワード再設定 ',
        passwordSetting: 'パスワード登録 ',
        passwordSetConfirm: '会員登録完了',

        //sign-up
        signUpRegistration: '新規会員登録',
        signUpComplete: 'メール送信完了',

        //worker
        workerRegStep1: 'ワーカー基本情報入力 ',
        workerInputSkillsStep2: 'ワーカースキル入力',
        workerConfirmStep3: 'ワーカー登録確認',
        workerRegIsCompleted: 'ワーカー登録完了',
        workerInfoEdit: 'ワーカー情報変更',
        workerInforEditModal: 'ワーカー情報変更完了',
        workerMemberMyPage: '会員マイページ | ワークオンサイト！',

        //work
        workList: '作業一覧',

        //report
        reportTitle: '{{reportTite}}:{{jobTitle}}',

        //job
        jobReportDetails: '実績報告：{{str}}',
        jobExpenseDetails: '経費報告：{{str}} ',
        jobSearch: '作業検索',

        //invoices
        invoicesList: '請求書一覧',
        invoiceDownload: '請求書ダウンロード',

        //offer
        offerDetails: 'オファー詳細：{{offerName}}',
        offerConfirmation: 'オファー回答確認：{{offerName}}',
        offerConfirmationSuccess: 'オファー回答完了：{{offerName}}',
        offerList: 'オファー一覧',
      },

      client: {
        //work
        workEditConfirmationSuccess: '作業編集完了',
        workList: '作業一覧',

        //application
        applicationList: '応募一覧',
        applicationDetails: '応募詳細：{{wkrTitle}} : {{jobGroupTitle}}',

        //offer
        offerCreateTitle: 'オファーフォーム',
        offerConfirmation: 'オファー確認：{{offerName}}',
        offerConfirmed: 'オファー完了: {{offerName}}',

        //login
        loginTitle: 'クライアントログイン',

        //Password
        passwordSet: 'パスワード設定',
        passwordChange: 'パスワード変更',
        passwordChangeSuccess: 'パスワード変更完了',

        //Registration
        requestRegistration: '会員登録',
        registration: 'クライアント基本情報登録',
        registrationVerify: 'クライアント基本情報登録確認',
        registrationSuccess: 'クライアント基本情報登録完了',

        // System Usage
        systemUsageList: 'システム利用料一覧',

        // Payment List
        paymentList: '支払予定一覧',
      },
    },
  },
}

export default ja
