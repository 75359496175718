import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import sortDownIcon from 'assets/svg/caret-sort-down.svg'
import sortUPIcon from 'assets/svg/caret-sort-up.svg'
import { Icon } from 'components'

const StyledSortIndicator = styled(Box)(() => ({
  margin: '0px 20px 18px 2px',
  '& .sortIcon': {
    position: 'absolute',
  },
}))

Head.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headCells: PropTypes.array,
  isLoading: PropTypes.bool,
  action: PropTypes.bool,
  actionEditButton: PropTypes.bool,
  actionDeleteButton: PropTypes.bool,
  noSort: PropTypes.bool,
  isDownload: PropTypes.bool,
  tableName: PropTypes.any,
}

function Head(props) {
  const {
    tableName,
    order,
    orderBy,
    onRequestSort,
    headCells,
    isLoading,
    action,
    noSort,
    isDownload,
    actionEditButton,
    actionDeleteButton,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const SortIndicator = (props) => {
    return (
      <StyledSortIndicator {...props} id={`${props.cell.id}Sort-ID`}>
        {props.orderBy === props.cell.id && order === 'asc' && (
          <Icon source={sortUPIcon} width={20} height={20} className="sortIcon" />
        )}
        {props.orderBy === props.cell.id && order === 'desc' && (
          <Icon source={sortDownIcon} width={20} height={20} className="sortIcon" />
        )}
        {props.orderBy !== props.cell.id && (
          <>
            <Icon source={sortUPIcon} width={20} height={20} className="sortIcon" />
            <Icon source={sortDownIcon} width={20} height={20} className="sortIcon" />
          </>
        )}
      </StyledSortIndicator>
    )
  }

  SortIndicator.propTypes = {
    cell: PropTypes.any,
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
  }

  return (
    <TableHead className={'table__head'}>
      {tableName && (
        <TableRow>
          <TableCell align="left" colSpan={'100%'}>
            {tableName}
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        {headCells.map(
          (headCell) =>
            headCell.hide !== true && (
              <TableCell key={headCell.id} align={headCell.align || 'left'} className={`th-${headCell?.className}`}>
                {!noSort && !headCell.disableSort && (
                  <TableSortLabel
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={isLoading ? () => {} : createSortHandler(headCell.id)}
                    className="table__sort_label"
                    IconComponent={() => <SortIndicator orderBy={orderBy} order={order} cell={headCell} />}
                  >
                    {headCell.label}
                  </TableSortLabel>
                )}
                {(noSort || headCell.disableSort) && headCell.label}
              </TableCell>
            )
        )}
        {action && <TableCell align={'center'} className="ActionCell th-col-4" />}
        {isDownload && <TableCell align={'center'} className="ActionCell th-col-4" />}
        {actionEditButton && actionDeleteButton && <TableCell align={'center'} className="ActionCell th-col-4" />}
      </TableRow>
    </TableHead>
  )
}

export default Head
