import { Link as MUILink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import standards from 'theme/standards'

const Link = styled(MUILink, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ fontSize, bold, theme, color }) => ({
  opacity: 1,
  fontSize: fontSize,
  padding: 0,
  fontWeight: bold ? 'bold' : 'normal',
  lineHeight: 'unset',
  letterSpacing: 0,
  color: color ? `${theme.palette['text'][color]}` : `${theme.palette['text'].link}`,
  cursor: 'pointer',
  '&:hover': {
    cursor: 'pointer',
  },
}))

Link.defaultProps = {
  fontSize: standards.fontSize.default,
}

export default Link
