import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import InnerHTML from 'dangerously-set-html-content'
import { confirmJob, updateConfirmJob } from 'services/jobs'
import { setConfirming, setFormErrors } from 'store/jobs/actionCreators'
import { useHistory } from 'react-router-dom'
import SuccessIcon from 'assets/svg/success.svg'
//utils
//componets
import {
  Label,
  Heading,
  SubHeading,
  LargeButton,
  Icon,
  Link,
  ContentDisplay,
  LabelDisplay,
  OptionsDisplay,
} from 'components'

import PropTypes from 'prop-types'

const StyledJobConfirmForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& .LabelInfo': {
    fontSize: '12px',
    padding: '5px 15px 5px 15px',
    margin: '0px 2px 15px 2px',
    backgroundColor: 'oldlace',
    borderRadius: '5px',
  },
  '& .labelAlign': {
    textAlign: 'right',
    paddingRight: '10px',
    fontWeight: '200',
  },
  '& .OptionCheck': {
    paddingTop: 20,
    paddingBottom: 0,
  },
  '& .invoiceGenerated': {
    paddingTop: 0,
  },
}))

const StyledLabel = styled(Label)(() => {
  return {
    position: 'inherit',
    overflowWrap: 'break-word',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
    [`& .iconButton`]: {
      position: 'absolute',
      marginTop: -8,
    },
  }
})

const JobConfirmForm = (props) => {
  // @TODO: Backend integration to follow

  const dispatch = useDispatch()
  const history = useHistory()
  const formData = useSelector((state) => state.jobs.formData)
  const loader = useSelector((state) => state.loader)
  const isConfirming = useSelector((state) => state.jobs.is_confirming)
  const isUpdate = useSelector((state) => state.jobs.is_update)
  const [confirmed, setConfirmed] = useState(false)

  const handleConfirm = async () => {
    let goods_supplied = formData.current?.goods_supplied_chk
    let goods_sent_to_worker = formData.current?.goods_sent_to_worker_chk
    let goods_worker_bring = formData.current?.goods_worker_bring_chk
    let invoice_generated = formData.current?.invoice_generated
    let client_id = { id: formData.formatted?.client_user.client_id }
    if (!isUpdate) {
      invoice_generated = false
    }

    let newData = {
      ...formData.current,
      goods_supplied,
      goods_sent_to_worker,
      goods_worker_bring,
      invoice_generated,
      client_id,
    }
    if (isUpdate) {
      await dispatch(updateConfirmJob(newData, formData.formatted?.id))
        .then(() => {
          setConfirmed(true)
        })
        .catch((error) => {
          dispatch(setFormErrors(error.response.data.error))
          history.goBack()
        })
    } else {
      await dispatch(confirmJob(newData)).then(() => {
        setConfirmed(true)
      })
    }

    props.isConfirm(true)
  }

  useEffect(() => {
    if (!confirmed && (!isConfirming || !formData.current)) {
      history.push('/job/create')
    }
    return () => {
      dispatch(setConfirming(false))
    }
  }, [dispatch])

  return (
    <StyledJobConfirmForm container className={confirmed ? 'Confirmation' : ''}>
      {confirmed && (
        <Grid container justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} sm={10} mt={2}>
            <Icon source={SuccessIcon} />
          </Grid>
          <Grid item xs={12} sm={10} pt={1} pb={3} gap={4}>
            <SubHeading color="secondary">作業の編集が完了しました</SubHeading>
          </Grid>
          <Grid item xs={12} sm={10} pb={6}>
            <Link id="ConfirmedBackBtn" to={'/job/list'}>
              <u>作業一覧</u>
            </Link>
          </Grid>
        </Grid>
      )}
      {!confirmed && (
        <Grid container>
          <Grid item xs={12}>
            <Heading>{isUpdate ? '作業編集確認' : '作業登録確認'}</Heading>
          </Grid>
          <Grid item xs={12} pb={3} pt={1}>
            <SubHeading>{isUpdate ? '以下の内容で更新しますか？' : '以下の内容で登録しますか？'}</SubHeading>
          </Grid>
          <ContentDisplay label="案件:" data={formData.formatted?.project_name} />
          <ContentDisplay label="作業グループ:" data={formData.formatted?.job_group_name} />
          <ContentDisplay label="応募者表示名:" data={formData.formatted?.worker_name} />
          <ContentDisplay label="作業名:" data={formData.current?.name} />
          <ContentDisplay label="作業担当者:" data={formData.current?.client_user_id.name} />
          <ContentDisplay label="作業日:" data={formData.current?.date} />
          <ContentDisplay label="作業時間:" data={formData.current?.start_time + '~' + formData.current?.end_time} />
          <ContentDisplay label="休憩時間:" data={formData.current?.break_time} />
          <ContentDisplay
            label="作業場所:"
            data={
              (formData.current?.zip_code ? formData.current?.zip_code + ' ' : '') +
              (formData.current?.prefecture ? formData.current?.prefecture + ' ' : '') +
              (formData.current?.municipality ? formData.current?.municipality + ' ' : '') +
              (formData.current?.address_line_1 ? formData.current?.address_line_1 + ' ' : '') +
              (formData.current?.address_line_2 ? formData.current?.address_line_2 + ' ' : '')
            }
          />
          <ContentDisplay
            label="作業内容:"
            data={<InnerHTML className="InnerHtml" html={formData.current?.description} />}
          />
          <ContentDisplay label="作業費（税抜）:" data={`${formData.formatted?.total_fees}円`} />
          <ContentDisplay label="手当:" data={formData.current?.allowance} />
          <ContentDisplay label="交通費:" data={formData.current?.toll_fee} />
          <ContentDisplay label="高速代:" data={formData.current?.highway_fee} />
          <ContentDisplay label="駐車場代:" data={formData.current?.parking_fee} />
          <ContentDisplay label="宿泊費:" data={formData.current?.overnight_stay_fee} />
          <ContentDisplay label="その他経費:" data={formData.current?.other_fee} />
          <OptionsDisplay
            label="事前支給品:"
            textOption="その他"
            text={formData.current?.provided_to_worker_other}
            options={formData.current?.goods_supplied_chk}
          />
          <OptionsDisplay
            label="事前送付品:"
            textOption="作業に使用する部材"
            text={formData.current?.items_provided_description}
            options={formData.current?.goods_sent_to_worker_chk}
          />
          <OptionsDisplay
            label="ワーカー持参物:"
            text={formData.current?.worker_tool_notes}
            options={formData.current?.goods_worker_bring_chk}
          />
          <ContentDisplay label="服装:" data={formData.current?.attire} />
          <ContentDisplay label="エスカレーション先:" data={formData.current?.escalation_contact} />
          <Grid item container mt={2} spacing={2}>
            <Grid item xs={12} sm={5.75} md={5} lg={4}>
              <Label color="gray">{'キャンセル費:'}</Label>
            </Grid>
            <Grid item xs={12} sm={5.25} md={7}>
              <StyledLabel>
                <pre>
                  <LabelDisplay md={-17} type="info" className="LabelInfo">
                    {'・1週間前キャンセル、作業費20%支払い'} <br /> {'・1〜3日前キャンセル、作業費50%支払い'} <br />
                    {'・当日キャンセル、作業費100%支払い'}
                  </LabelDisplay>
                </pre>
              </StyledLabel>
            </Grid>
          </Grid>
          <Grid item container pt={0} spacing={2}>
            <Grid item xs={12} sm={5.75} md={5} lg={4}>
              <Label color="gray">{''}</Label>
            </Grid>
            <Grid item xs={12} sm={5.25} md={7}>
              <StyledLabel>
                <pre>{formData.current?.invoice_generated ? '✓ 上記キャンセル費を承諾する' : ''}</pre>
              </StyledLabel>
            </Grid>
          </Grid>
          <ContentDisplay label="お客様現地ご担当者様連絡先:" data={formData.current?.onsite_client_contact} />
          <ContentDisplay label="名乗り:" data={formData.current?.onsite_name} />
          <ContentDisplay label="当日同行者:" data={formData.current?.fellow} />
          <ContentDisplay label="その他連絡事項:" data={formData.current?.remarks} />
          <Grid item container mt={2} spacing={2}>
            <Grid item xs={12} sm={5.75} md={5} lg={4}>
              <Label color="gray">{'必要な連絡と、担当者以外に追加で必要な宛先:'}</Label>
            </Grid>
            <Grid item xs={12} sm={5.25} md={7}>
              <StyledLabel>
                <pre>
                  {formData.current?.is_previous_contact_enabled &&
                    '前日連絡 (' + formData.current?.one_day_before_recipients + ')'}
                </pre>
                <pre>
                  {formData.current?.is_departure_contact_enabled &&
                    '出発連絡 (' + formData.current?.departure_recipients + ')'}
                </pre>
                <pre>
                  {formData.current?.is_arrival_contact_enabled &&
                    '現着連絡 (' + formData.current?.arrival_recipients + ')'}
                </pre>
                <pre>{'入館連絡 (' + formData.current?.clock_in_recipients + ')'}</pre>
                <pre>{'退館連絡 (' + formData.current?.clock_out_recipients + ')'}</pre>
              </StyledLabel>
            </Grid>
          </Grid>
          <Grid item container xs={12} pt={6} justifyContent={{ xs: 'center', sm: 'end' }} gap={2}>
            <LargeButton id="ConfirmedBackBtn" color="gray" mode="btn1" onClick={history.goBack}>
              戻る
            </LargeButton>
            <LargeButton id="JobConfirmBtn" color="primary" onClick={handleConfirm}>
              {loader && <CircularProgress size="1.5rem" color="white" />}
              {!loader && '確定'}
            </LargeButton>
          </Grid>
        </Grid>
      )}
    </StyledJobConfirmForm>
  )
}

JobConfirmForm.displayName = 'Job Confirmation Form'

JobConfirmForm.propTypes = {
  isConfirm: PropTypes.func,
}

export default JobConfirmForm
