import React from 'react'
import { Grid, Stack, Box } from '@mui/material'
import PropTypes from 'prop-types'

import {
  Link,
  ContentDisplay,
  Label,
  CategoryDisplay,
  BackArrow,
  Heading,
  SubHeading,
  P,
  Banner,
  SmallOutlinedTag,
  Caption,
} from 'components'
import { styled } from '@mui/material/styles'
import { groupBy } from 'utils/helper'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

const StyledWorker = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& .WorkExperience-Text': {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
  },
  '& .Available-Work .MuiGrid-item': {
    paddingBottom: '0 !important',
  },
  '& .Worker-Qualifications .MuiGrid-item': {
    paddingLeft: 0,
  },
}))

const WorkerInfo = (props) => {
  const { worker, jobInfo } = props

  const skills = worker?.skills ?? []
  const certificates = skills.filter((skill) => skill.skill_category_type === 'Qualification')
  const canDos = skills.filter((skill) => skill.skill_category_type === 'Skills')

  const certificatesByCategory = groupBy(certificates, 'skill_category_name')
  const canDosByCategory = groupBy(canDos, 'skill_category_name')
  const beforeJobStart = jobInfo?.has_before_job_start_log

  const getWorkerName = () => {
    return (
      <React.Fragment>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>{beforeJobStart ? jobInfo?.worker_name : jobInfo?.worker_screen_name}</Grid>
          <Grid item>
            <Stack direction="row" spacing={1}>
              {!!worker?.verified_account && <SmallOutlinedTag label="本人確認済" color="success" />}
              {!!worker?.tekikaku_flag && <SmallOutlinedTag label="適格請求書発行事業者" color="primary" />}
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {!beforeJobStart && <Caption>(マッチングが成立すると開示されます)</Caption>}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const getWorkerPhone = () => {
    return (
      <React.Fragment>
        <Box display="inline-flex">
          <Label pr={2}>{beforeJobStart ? worker?.phone_number : worker?.phone_number.replace(/./g, '*')}</Label>
        </Box>
        {!beforeJobStart && <Caption>(マッチングが成立すると開示されます)</Caption>}
      </React.Fragment>
    )
  }

  return (
    <StyledWorker container pb={4}>
      {jobInfo && (
        <React.Fragment>
          <BackArrow label="作業詳細に戻る" path={'/job/show/' + jobInfo?.id} />
          <Grid item container>
            <Grid item xs={12} pt={2} pb={4}>
              <Heading>ワーカー詳細</Heading>
            </Grid>
            <Grid item pt={2} className="JobOutline">
              <Label pb={0.5}>
                作業:{' '}
                <Link to={'/job/show/' + jobInfo?.id} xs={{ textDecoration: 'underline' }}>
                  {jobInfo?.job_name}
                </Link>
              </Label>
              <Label pb={0.5}>
                作業グループ:{' '}
                <Link to={'/job-group/show/' + jobInfo?.job_group_id} xs={{ textDecoration: 'underline' }}>
                  {jobInfo?.job_group_name}
                </Link>
              </Label>
              <Label pb={0.5}>案件: {jobInfo?.project_alias}</Label>
            </Grid>
          </Grid>
          <Grid container py={3} spacing={4}>
            <Grid item xs={12} sm={5} md={4}>
              <Label pb={1}>ステータス</Label>
              <Banner label={jobInfo?.status_name} variant="outlined" color="success" bold="true" />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={12} py={2}>
        <SubHeading bold="true">ワーカー</SubHeading>
      </Grid>
      {worker && (
        <Grid item container>
          <ContentDisplay label="氏名:" data={getWorkerName()} />
          <ContentDisplay label="表示名:" data={jobInfo?.worker_screen_alias} />
          <ContentDisplay label="性別:" data={worker.gender} />
          <ContentDisplay label="生年月日:" data={`${worker.age}歳`} />
          <ContentDisplay label="住所:" data={`${worker?.prefecture} ${worker?.city}`} />
          <ContentDisplay label="メールアドレス:" data={worker.email_address} />
          <ContentDisplay label="電話番号:" data={getWorkerPhone()} />
          <ContentDisplay
            label="適格請求書発行事業者:"
            data={worker.tekikaku_flag === 1 ? <CheckBoxIcon color="primary" /> : <CheckBoxBlankIcon />}
          />
          {certificates.length !== 0 && (
            <Grid item pt={4} xs={12} textAlign="center">
              <SubHeading bold="true">所有資格</SubHeading>
            </Grid>
          )}
          {Object.entries(certificatesByCategory).map((items, i) => {
            return (
              <Grid item xs={12} key={i} pt={4} textAlign="center" className="Worker-Qualifications">
                <Label color="main" pb={2}>
                  {items[0]}:
                </Label>
                <CategoryDisplay items={items[1]} />
              </Grid>
            )
          })}
          {canDos.length !== 0 && (
            <Grid item pt={10} xs={12} textAlign="center">
              <SubHeading bold="true">対応可能作業</SubHeading>
            </Grid>
          )}
          {Object.entries(canDosByCategory).map((items, i) => {
            return (
              <Grid item xs={12} key={i} pt={4} textAlign="center" className="Available-Work">
                <CategoryDisplay items={items[1]} />
              </Grid>
            )
          })}
          <Grid item pt={10}>
            <Label bold="true">業務経験</Label>
          </Grid>
          <Grid item pt={1} xs={12} sm={12} md={12} lg={12}>
            <pre>
              <P className="WorkExperience-Text">{worker?.work_experience}</P>
            </pre>
          </Grid>
        </Grid>
      )}
    </StyledWorker>
  )
}

WorkerInfo.displayName = 'Worker Info'

WorkerInfo.propTypes = {
  worker: PropTypes.any,
  jobInfo: PropTypes.any,
}

export default WorkerInfo
