import React from 'react'
import { Typography as MUITypography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Typography = styled(MUITypography, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color, bold, display, theme, letterSpacing }) => ({
  opacity: 1,
  lineHeight: 'unset',
  letterSpacing: letterSpacing || 0,
  whiteSpace: 'unset',
  wordBreak: 'break-word',
  display: display ?? '',
  color: color ? `${theme.palette?.text?.[color]}` : '',
  fontWeight: bold ? 'bold' : '',

  '&.cursor-pointer': {
    cursor: 'pointer !important',
  },
}))

export const Heading = (props) => {
  return <Typography {...props} variant="title" component="label"></Typography>
}

export const SubHeading = (props) => {
  return <Typography {...props} variant="subtitle" component="label"></Typography>
}

export const Label = (props) => {
  return <Typography {...props} variant="p" component="label" display="block"></Typography>
}

export const Paragraph = (props) => {
  return <Typography {...props} variant="p" component="p"></Typography>
}

export const Caption = (props) => {
  return <Typography {...props} variant="caption" component="p"></Typography>
}
