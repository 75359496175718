import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { InputLabel, LargeButton, Heading, Icon, InputSelect, TableList, InputDatePicker, Link } from 'components'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as dayjs from 'dayjs'
import AddIcon from '@mui/icons-material/Add'
import searchIcon from 'assets/svg/search.svg'
import { useHistory } from 'react-router-dom'
//validation msg
import { getMessages } from 'validation'
import { setClientUserFormData, resetCurrentForm } from 'store/client-user/actionCreators'
import { useDispatch } from 'react-redux'
import { dateFormat, clientUsersAcctStatus } from 'utils/common'
import { formatNameID, formatNumbersOnly } from 'utils/helper'

const ClientUserListForm = (props) => {
  const [dateFromValue, setDateFromValue] = useState(null)
  const [dateToValue, setDateToValue] = useState(null)
  const [statusId, setStatusId] = useState(0)
  const rulesMsg = getMessages('ja')
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    dispatch(resetCurrentForm())
  }, [])

  //Click searach button
  const handleSearch = (data) => {
    if (parseInt(data?.account_status) === 0) {
      data['account_status'] = ''
    } else {
      data['account_status'] = data?.account_status === 1 ? '有効' : '無効'
    }
    props.handlePageSearch(data)
  }

  //Page
  const handleRegister = async () => {
    let response_data = {}
    response_data['current'] = null
    response_data['original'] = null
    dispatch(setClientUserFormData(response_data))
    history.push('/client-user/create')
  }

  const validationRules = {
    keyword: {
      validate: (value) => {
        return value.trim().length <= 255 || rulesMsg.max(255)
      },
    },
    keyword_client: {
      validate: (value) => {
        return value.trim().length <= 255 || rulesMsg.max(255)
      },
    },
    phone_number: {
      validate: (value) => {
        return value.trim().length <= 255 || rulesMsg.max(255)
      },
    },
    email: {
      validate: (value) => {
        return value.trim().length <= 255 || rulesMsg.max(255)
      },
    },
    last_login_from: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
    last_login_to: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        const startDateVal = getValues('last_login_from')
        if (!isValid) {
          return rulesMsg.invalid_date()
        } else if (dayjs(value).isBefore(startDateVal)) {
          return rulesMsg.is_date_before()
        } else {
          return true
        }
      },
    },
  }

  // Table Actions
  const actionList = [
    {
      name: '/client-user/show',
      color: 'view',
      label: '閲覧',
    },
    {
      name: '/client-user/update',
      color: 'edit',
      label: '編集',
    },
  ]

  const onDateChange = (newVal, type) => {
    if (type == 'last_login_date_from') {
      setDateFromValue(newVal)
      //if date from is set greater than date to, then adjust date to
      if (dayjs(newVal).diff(dayjs(dateToValue)) >= 1) {
        setDateToValue(newVal)
      }
    } else {
      setDateToValue(newVal)
    }
  }

  //Format table list data
  const formatData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        name: (
          <Link to={`/client-user/show/${item?.id}`}>
            <u>{item?.user?.name}</u>
          </Link>
        ),
        client_name: (
          <Link to={`/client/show/${item?.client?.id}`}>
            <u>{formatNameID(item?.client?.name, item?.client?.id)}</u>
          </Link>
        ),
        phone_number: formatNumbersOnly(item?.phone_number),
        email_address: item?.user?.email_address,
        last_login: item?.user?.last_login,
        account_status: item?.account_status ? '有効' : '無効',
      }
    })
  }

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Heading>{'クライアント担当者一覧'}</Heading>
        </Grid>
        <Grid item container justifyContent="flex-end" pt={{ xs: 2, sm: 0 }}>
          <Grid item xs={12} textAlign="end">
            <LargeButton startIcon={<AddIcon size={'sm'} color="#ffffff" />} onClick={handleRegister}>
              {'クライアント担当者新規登録'}
            </LargeButton>
          </Grid>
        </Grid>
        <Grid container pt={6}>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Grid item container spacing={2} xs={12} alignItems={'start'}>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <InputLabel
                  name="keyword"
                  ref={register('keyword', validationRules.keyword)}
                  error={errors && errors.keyword ? true : false}
                  errorMsg={errors ? errors?.keyword?.message : null}
                  label="クライアント担当者名・ID"
                  placeholder=""
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <InputLabel
                  name="keyword_client"
                  ref={register('keyword_client', validationRules.keyword_client)}
                  error={errors && errors.keyword_client ? true : false}
                  errorMsg={errors ? errors?.keyword_client?.message : null}
                  label="クライアント名・ID"
                  placeholder=""
                  type="text"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} alignItems={'start'}>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <InputLabel
                  name="phone_number"
                  ref={register('phone_number', validationRules.phone_number)}
                  error={errors && errors.phone_number ? true : false}
                  errorMsg={errors ? errors?.phone_number?.message : null}
                  label="電話番号"
                  placeholder=""
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <InputLabel
                  name="email"
                  ref={register('email', validationRules.email)}
                  error={errors && errors.email ? true : false}
                  errorMsg={errors ? errors?.email?.message : null}
                  label="メールアドレス"
                  placeholder=""
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={1.5}>
                <InputSelect
                  name="account_status"
                  ref={register('account_status')}
                  label="アカウント状態"
                  menuItems={clientUsersAcctStatus}
                  defaultValue={0}
                  value={statusId}
                  onChange={(value) => setStatusId(value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <InputDatePicker
                  id="clientUserList-last_login_from-ID"
                  ref={register('last_login_from', validationRules.last_login_from)}
                  error={errors && errors.last_login_from ? true : false}
                  errorMsg={errors ? errors?.last_login_from?.message : null}
                  name="last_login_from"
                  label={'最終ログイン日'}
                  color="primary"
                  inputFormat={dateFormat}
                  value={dayjs(dateFromValue)}
                  onChange={(newVal) => onDateChange(newVal, 'last_login_date_from')}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <InputDatePicker
                  id="clientUserList-last_login_to-ID"
                  ref={register('last_login_to', validationRules.last_login_to)}
                  error={errors && errors.last_login_to ? true : false}
                  errorMsg={errors ? errors?.last_login_to?.message : null}
                  name="last_login_to"
                  color="primary"
                  inputFormat={dateFormat}
                  value={dayjs(dateToValue)}
                  onChange={(newVal) => onDateChange(newVal, 'last_login_date_to')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2} mt={{ xs: 0, sm: 3.5 }}>
                <LargeButton
                  type="submit"
                  bold="true"
                  color="warning"
                  startIcon={<Icon size={'sm'} disabled={props.isLoading ? true : false} source={searchIcon} />}
                >
                  {'検索'}
                </LargeButton>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12} pt={6}>
            <TableList
              title={`Client List`}
              headCells={props.headerCells}
              data={formatData(props.data)}
              totalPage={props.totalPage}
              totalCount={props.totalCount}
              handlePageChange={props.handlePageChange}
              isLoading={props.isLoading}
              handleSort={props.handleSort}
              sort={props.sort}
              sortBy={props.sortBy}
              currentPage={props.currentPage}
              pageFrom={props.pageFrom}
              pageTo={props.pageTo}
              action={true}
              actionButton={true}
              actionList={actionList}
            />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

ClientUserListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

export default ClientUserListForm
