import React, { Fragment } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { Heading, LargeButton, Link, ContentDisplay } from 'components'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { formatNameID, formatNumbersOnly } from 'utils/helper'

const ClientUserDetailsForm = (props) => {
  const isLoading = useSelector((state) => state.loader)
  const { displayData } = props
  const history = useHistory()

  const handleUpdate = () => {
    history.push({
      pathname: `/client-user/update/${displayData?.id}`,
      state: { update: true, details: displayData },
    })
  }

  return (
    <Fragment>
      <Grid container>
        {isLoading && (
          <Grid xs={12} item textAlign="center" py={32}>
            <CircularProgress />
          </Grid>
        )}
        {!isLoading && (
          <Grid item xs={12}>
            <Grid item container xs={12} alignItems="center" mb={3}>
              <Grid item container xs={6} justifyContent="flex-start" alignItems="flex-start">
                <Heading>{'クライアント担当者詳細'}</Heading>
              </Grid>
              <Grid item container xs={6} direction="column" justifyContent="center" alignItems="flex-end">
                <Grid item mt={1}>
                  <LargeButton
                    id="editBtn"
                    color="primary"
                    mode="light"
                    width={220}
                    maxwidth="unset"
                    onClick={handleUpdate}
                  >
                    {'編集'}
                  </LargeButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-start" mb={2}>
              <ContentDisplay
                label="クライアント:"
                data={
                  <Link to={`/client/show/${displayData?.client?.id}`}>
                    <u>{formatNameID(displayData?.client?.name, displayData?.client?.id)}</u>
                  </Link>
                }
              />
              <ContentDisplay label="担当者名:" data={displayData?.user?.name} />
              <ContentDisplay label="担当者電話番号:" data={formatNumbersOnly(displayData?.phone_number)} />
              <ContentDisplay label="担当者メールアドレス:" data={displayData?.user?.email_address} />
              <ContentDisplay label="アカウント状態:" data={displayData?.account_status ? '有効' : '無効'} />
              <ContentDisplay label="登録日:" data={displayData?.create_date} />
              <ContentDisplay label="最終ログイン:" data={displayData?.user?.last_login} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}

ClientUserDetailsForm.propTypes = {
  displayData: PropTypes.any,
}

export default ClientUserDetailsForm
