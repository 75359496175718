import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { Heading, SubHeading, Label, LargeButton, SuccessRegistrationForm } from 'components'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { registerClientUser } from 'services/users'
import { setPending } from 'store/users/actionCreators'

const StyledForgotPasswordForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& .ForgotPassword-backLabel': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}))

function VerifyRegistrationForm() {
  const [success, setSuccess] = useState(false)
  const { userData, tokenData, isSuccessful } = useSelector((state) => state.users)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    !userData.token && history.push('/login')
  }, [])

  useEffect(() => {
    isSuccessful && setSuccess(true)
  }, [isSuccessful])

  const {
    full_name: name,
    phone_number: contact,
    email,
    company_name: company,
    prefecture,
    city,
    address_line_1: address1,
    address_line_2: address2,
    website,
    company_phone_number: companyContact,
    company_email: companyEmail,
  } = userData

  const handleVerifyRegistration = () => {
    dispatch(registerClientUser({ ...userData, user_id: tokenData.user_id }))
  }

  const handleReturnPage = () => {
    dispatch(setPending())
    history.goBack()
  }

  return !success ? (
    <StyledForgotPasswordForm container direction="column" rowSpacing={4}>
      <Grid item>
        <Heading bold="ture" variant="title">
          {'基本情報登録'}
        </Heading>
      </Grid>
      <Grid item>
        <Label>{'以下の情報を登録します。'}</Label>
      </Grid>
      <Grid item>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <SubHeading>{'担当者情報'}</SubHeading>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Label color="gray">{'担当者名'}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Label>{name}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Label color="gray">{'担当者電話番号'}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Label>{contact}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Label color="gray">{'担当者メールアドレス'}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Label>{email}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Label color="gray">{'パスワード'}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Label letterSpacing={5}>{'●   ●   ●   ●   ●   ●'}</Label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <SubHeading>{'法人情報'}</SubHeading>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Label color="gray">{'法人名'}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Label>{company}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Label color="gray">{'法人住所'}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Label>{`${prefecture} ${city} ${address1} ${address2}`}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Label color="gray">{'WEBサイト'}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Label>{website}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Label color="gray">{'法人代表電話番号'}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Label>{companyContact}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Label color="gray">{'法人メールアドレス'}</Label>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Label>{companyEmail}</Label>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={12} gap={2} justifyContent="end" mt={3}>
            <LargeButton type="button" color="gray" mode="btn1" width={'25%'} onClick={handleReturnPage}>
              {'戻る'}
            </LargeButton>
            <LargeButton type="button" width={'25%'} onClick={handleVerifyRegistration}>
              {'登録'}
            </LargeButton>
          </Grid>
        </Grid>
      </Grid>
    </StyledForgotPasswordForm>
  ) : (
    <SuccessRegistrationForm />
  )
}

export default VerifyRegistrationForm
