import React from 'react'
import * as types from './actionTypes'
import { formulateCurrentPage, formulatePageResult } from 'utils/helper'
import { Link } from 'components'
import { thisMonthStart, thisMonthEnd } from '../../utils/common'

const initialState = {
  list: [],
  is_confirming: false,
  is_update: false,
  is_modal_loading: false,
  cancellationFee: null,
  cancellationFeeFormatted: null,
  formData: {
    current: null,
    formatted: null,
    original: null,
  },
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 20,
    prevPageUrl: null,
    nextPageUrl: null,
    pageFrom: 0,
    pageTo: 0,
  },
  search: {
    filter_by1: 0,
    filter_txt1: '',
    filter_by2: 0,
    filter_txt2: '',
    start_date: thisMonthStart,
    end_date: thisMonthEnd,
    page: 1,
    limit: 20,
    sortBy: 'date',
    sort: 'desc',
    client_id: null,
  },
  filterOptions: {},
  error: {},
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SEARCH_RESULT: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      const auth = JSON.parse(localStorage.getItem('authUser'))
      const list = payload.data.map((item) => ({
        ...item,
        status_id: item.status_name,
        client_name: (
          <Link to={`/client/show/${item.client_id}`} sx={{ textDecoration: 'underline' }}>
            {item.client_name}
          </Link>
        ),
        job_name: (
          <Link to={`/job/show/${item.id}`} sx={{ textDecoration: 'underline' }}>
            {item.job_name}
          </Link>
        ),
        worker_name: (
          <Link
            to={auth?.client_user ? `/worker/show/${item?.id}` : `/worker/${item.worker_id}`}
            sx={{ textDecoration: 'underline' }}
          >
            {auth?.client_user
              ? item?.has_before_job_start_log === true
                ? item?.worker_name
                : item?.worker_screen_name
              : item?.worker_name}
          </Link>
        ),
      }))

      return {
        ...state,
        list: list,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pageSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
          client_id: auth?.client_user?.client_id,
        },
      }
    }
    case types.SET_SEARCH_CRITERIA: {
      const {
        filter_by1,
        filter_txt1,
        filter_by2,
        filter_txt2,
        page,
        sort,
        sortBy,
        keyword,
        start_date,
        end_date,
        status_id,
      } = payload
      let statusId = typeof status_id === 'object' ? status_id.join() : status_id
      return {
        ...state,
        search: {
          ...state.search,
          filter_by1,
          filter_txt1,
          filter_by2,
          filter_txt2,
          page,
          keyword,
          start_date,
          end_date,
          status_id: statusId,
          sort,
          sortBy,
        },
      }
    }
    case types.FETCHING_DATA_FAILED:
      return {
        ...state,
        error: payload,
      }
    case types.SET_RESULT_OPTIONS:
      return {
        ...state,
        filterOptions: payload,
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default reducer
