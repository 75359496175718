import palette from '../palette'
export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.white,
      border: `1px solid ${palette.lightGray.light}`,
      borderRadius: 7,
      height: '100%',
    },
    container: {
      margin: 0,
      height: '100%',
      borderRadius: 7,
    },
    toolbar: {
      backgroundColor: palette.white,
      height: 36,
      borderRadius: '7px 7px 0px 0px',
      boxShadow: `0px 2px 4px 0px ${palette.lightGray.light}`,
      marginBottom: 5,
      paddingLeft: 5,
      '& .MuiButtonBase-root': {
        height: 36,
        boxShadow: 'none',
        border: 'none',
        borderRight: `1px solid ${palette.lightGray.light}`,
        borderRadius: 0,
        backgroundColor: palette.white,
        color: palette.gray.main,
        fontSize: 18,
        alignItems: 'end',
        '&.MuiIconButton-colorPrimary': {
          backgroundColor: palette.lightGray.light,
        },
        '& .MuiSvgIcon-root': {
          fontSize: 20,
        },
        '&[aria-label="Bold"]::after': {
          content: '"太字"',
        },
        '&[aria-label="Underline"]::after': {
          content: '"下線"',
        },
      },
      '& #mui-rte-Bold-button::after': {
        content: '"太字"',
      },
      '& #mui-rte-Underline-button::after': {
        content: '"下線"',
      },
    },
    editor: {
      backgroundColor: palette.white,
      overflow: 'auto',
      height: 'calc(100% - 52px)',
      padding: 10,
    },
    placeHolder: {
      backgroundColor: palette.white,
      height: 'calc(100% - 52px)',
    },
  },
}
