import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Help as HelpIcon, DownloadForOffline as DownloadForOfflineIcon } from '@mui/icons-material'
import PropTypes from 'prop-types'
import palette from 'theme/palette'
import { downloadFile } from 'services/common'
import { Label, Tooltip, LargeButton, InputPopper } from 'components'

const StyledParagraph = styled(Label)(({ theme }) => {
  return {
    position: 'inherit',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
    [`& .iconButton`]: {
      position: 'absolute',
      marginTop: -8,
    },
    '& .InnerHtml': {
      display: 'grid',
      lineHeight: 'normal',
      '& p': {
        minHeight: `${theme.standards.fontSize.default}px !important`,
      },
      '& p, span, label': {
        backgroundColor: 'unset !important',
      },
    },
  }
})

const ContentDisplay = (props) => {
  const {
    label,
    data,
    isDownload,
    blockStyle,
    filePath,
    labelAlign,
    toolTip,
    applicationURL,
    inputPopperValue,
    popperModalContent,
  } = props
  const [openPopper, setOpenPopper] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()

  const onDownload = () => {
    dispatch(downloadFile({ file_path: filePath }, data))
  }

  return (
    <Grid item xs={12}>
      <Grid container mt={2} spacing={2}>
        <Grid
          item
          display="flex"
          textAlign={labelAlign || 'left'}
          xs={12}
          {...(!blockStyle && { sm: 5.75, md: 5, lg: 4 })}
        >
          <Label color="gray" sx={{ paddingRight: 1 }}>
            {label}
          </Label>
          {toolTip && (
            <Tooltip title={toolTip} width={885}>
              <HelpIcon fontSize="small" sx={{ color: palette.text.primary }} />
            </Tooltip>
          )}
        </Grid>
        <Grid
          item
          {...(!applicationURL && !blockStyle
            ? { xs: 12, sm: 5.25, md: 7 }
            : applicationURL
            ? { xs: 4, sm: 2.25, md: 2 }
            : { xs: 12 })}
        >
          <pre>
            <StyledParagraph>
              <div>
                {data}
                {isDownload && (
                  <IconButton className="iconButton" component="label" onClick={onDownload}>
                    <DownloadForOfflineIcon color="secondary" />
                  </IconButton>
                )}
              </div>
            </StyledParagraph>
          </pre>
        </Grid>
        {applicationURL && (
          <Grid item xs={8} sm={4} md={5} sx={{ justifyContent: 'start', marginTop: { xs: -1.25 } }}>
            <LargeButton
              id="jobGroupCreateBtn-ID"
              variant="outlined"
              onClick={({ currentTarget }) => {
                setAnchorEl(currentTarget)
                setOpenPopper(true)
              }}
              color="primary"
              mode="light"
            >
              {applicationURL}
            </LargeButton>
            <InputPopper
              open={openPopper}
              buttonLabel={'URLをコピー'}
              anchorEl={anchorEl}
              hasArrow={true}
              inputValue={inputPopperValue}
              clickAway={() => setOpenPopper(false)}
              modalContent={popperModalContent}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

ContentDisplay.displayName = 'Content Display'

ContentDisplay.propTypes = {
  label: PropTypes.string,
  data: PropTypes.any,
  isDownload: PropTypes.bool,
  blockStyle: PropTypes.bool,
  filePath: PropTypes.string,
  labelAlign: PropTypes.any,
  toolTip: PropTypes.string,
  applicationURL: PropTypes.string,
  inputPopperValue: PropTypes.string,
  popperModalContent: PropTypes.any,
}

export default ContentDisplay
