import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  InputDatePicker,
  InputLabel,
  Label,
  LargeButton,
  Heading,
  InputMultiCheckbox,
  InputRadioGroup,
  Input,
  Modal,
  InputTextArea,
  Checkbox,
  Caption,
} from 'components'
import { Grid, CircularProgress, FormHelperText } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setFormMode, setConfirming, setWorkersFormData, setFormErrors } from 'store/workers/actionCreators'
import { getPublicWorkerSkills, getAddress, downloadFile } from 'services/common'
import { readWorkerJobGroup } from 'services/jobGroups'
import * as dayjs from 'dayjs'
//utils
import { minBirthDate, dateFormat } from 'utils/common'
import { getWorker, saveWorkerInformation } from 'services/workers'
import * as eaw from 'eastasianwidth'
import * as AFHConvert from 'ascii-fullwidth-halfwidth-convert'
import { hideNotification } from 'store/notification/actionCreators'
import { hideLoader } from 'store/loader/actionCreators'
import { getMessages } from 'validation'

const StyledCreateWorkerInformationForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .SkillsWrapper_6 .CheckboxWrapper': {
    display: 'grid',
    gridTemplateRows: 'repeat(17, auto)',
    gridAutoFlow: 'column',
    gridAutoColumns: 'auto',
    gridTemplateColumns: '50%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  '& input.Mui-disabled': {
    backgroundColor: `${theme.palette.lightGray.dark}`,
    borderRadius: 5,
  },
  '& .DisplayNameWrapper .MuiBox-root': {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  '& .MuiButton-root.UploadBtn': {
    borderRadius: 5,
    '& .MuiFormControl-root': {
      display: 'none',
    },
  },
  '& input#InputUploadFile-ID': {
    display: 'none',
  },
  '& .WorkExperience-Wrapper': {
    '& .MuiBox-root:first-of-type': {
      padding: 0,
    },
    '& .InputLabel': {
      fontWeight: 'bold !important',
    },
    '& p.MuiFormHelperText-root.WorkExperience-Note': {
      color: `${theme.palette.text.primary} !important`,
      fontSize: 12,
      textAlign: 'end',
    },
  },
  '& .BankAccount-Wrapper': {
    '& .InputLabel': {
      paddingTop: 35,
    },
    '& .InputLabel-subLabel': {
      paddingBottom: `${theme.standards.padding.label.inputLabel}`,
    },
    '& .MuiBox-root': {
      padding: 0,
    },
  },
  '& #grid-full-name': {
    '& .css-v38ksm': {
      padding: 0,
    },
    paddingBottom: 25,
  },
  '& .downloadIcon': {
    cursor: 'pointer',
  },
}))

function UpdateRegistrationForm(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedSkills, setSelectedSkills] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)
  const [requestAddress, setRequestAddress] = useState(false)
  const [selectedGender, setSelectedGender] = useState('男性')
  const [cardUrlFilename, setCardUrlFilename] = useState('ファイルは選択されていません')
  const [isCurrentCard, setIsCurrentCard] = useState(true)
  const loader = useSelector((state) => state.loader)
  const [confirmed, setConfirmed] = useState(false)
  const [city, setCity] = useState('')
  const [prefecture, setPrefecture] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useForm()
  const workerSkills = useSelector((state) => state.common.workerSkillList)
  const currentUser = useSelector((state) => state.auth.user)
  const currentformData = useSelector((state) => state.workers.formData)
  const formErrors = useSelector((state) => state.workers.formErrors)
  const applicationData = useSelector((state) => state.jobGroups?.formData?.formatted)
  const forceRedirect = useSelector((state) => state.common.privateRedirect)
  let workerId = currentUser?.id
  let formData =
    currentformData?.current && Object.keys(currentformData?.current).length > 0
      ? currentformData?.current
      : currentformData?.original
  const isFromApplication = props.location.state?.prevPath?.includes('/worker-information')
  const rulesMsg = getMessages('ja')

  useEffect(() => {
    if (props?.location?.pathname.includes('update')) {
      setIsUpdate(true)
      dispatch(setFormMode(true))
    } else {
      setIsUpdate(false)
      dispatch(setFormMode(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (workerId) {
      dispatch(getWorker(currentUser.worker_id))
    }
  }, [currentUser])

  const onChangeGender = (e) => {
    setSelectedGender(e.target.value)
  }

  useEffect(() => {
    dispatch(getPublicWorkerSkills())
  }, [])

  let defaultInputProps = {
    required: true,
  }

  const handleChangePostal = () => {
    if (errors.postal_code) {
      trigger('postal_code')
    }
    clearErrors('prefecture')
    clearErrors('city')
    setPrefecture('')
    setCity('')
    setValue('prefecture', '')
    setValue('city', '')
  }

  const onDownloadCard = () => {
    dispatch(
      downloadFile({ file_path: currentformData?.original?.id_card_url }, currentformData?.original?.id_card_filename)
    )
  }

  const getInputTextAreaField = (label, subLabel, name, placeholder, height, required) => {
    let inputValue = formData ? formData[name] : null
    defaultInputProps['height'] = height
    defaultInputProps['required'] = required
    return (
      <InputTextArea
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        subLabel={subLabel}
        {...(inputValue && { value: inputValue })}
        {...defaultInputProps}
      />
    )
  }

  const handleGetAddress = async () => {
    const validPostal = await trigger('postal_code')
    let postal_code = getValues('postal_code')
    if (validPostal) {
      setRequestAddress(true)
      await getAddress(postal_code).then((response) => {
        setRequestAddress(false)
        if (response.data.results == null) {
          setError('postal_code', { type: 'custom', message: '該当する郵便番号が見つかりませんでした。' })
        } else {
          clearErrors('postal_code')
          clearErrors('prefecture')
          clearErrors('city')
          setPrefecture(response.data.results[0]?.address1)
          setCity(response.data.results[0]?.address2)
          setAddressLine1(response.data.results[0]?.address3)
        }
      })
    }
  }
  useEffect(() => {
    Object.keys(formErrors).map((key) => {
      setError(key, { type: 'custom', message: formErrors[key][0] }, { shouldFocus: true })
    })
  }, [formErrors])

  // handle Submit
  const handleSubmitWorkerForm = (data) => {
    if (eaw.eastAsianWidth(data?.full_name) !== 'F' || eaw.eastAsianWidth(data?.full_name) !== 'H') {
      const converter = new AFHConvert()
      if (eaw.eastAsianWidth(data?.full_name) === 'W') {
        data.full_name = converter.toFullWidth(data?.full_name)
      } else {
        data.full_name = converter.toHalfWidth(data?.full_name)
      }
    }

    let selectedSkills = []
    data.tekikaku_flag = data.tekikaku_flag ? 1 : 0
    data.receive_new_jobs_email = data.receive_new_jobs_email ? 1 : 0
    data.verified_account = 0
    data.account_status = 1

    workerSkills.map((type) =>
      type?.skills.map((skill) => {
        let skills = {
          skill_category_name: type.name,
          skill_category_type: type.type,
          skill_id: skill.id,
          skill_name: skill.name,
        }
        if (data?.skills.length > 0 && data?.skills.includes(skill.id.toString())) {
          selectedSkills.push(skills)
        }
      })
    )

    let newData = {
      ...data,
      userID: workerId,
      id_card_url: data.id_card_url.length > 0 ? data.id_card_url[0] : '',
      id_card_filename: data.id_card_url.length > 0 ? data.id_card_url[0].name : formData?.id_card_filename,
      selectedSkills: selectedSkills,
      skills: typeof data.skills === 'object' ? data.skills : formData.skills,
      account_status: formData?.account_status,
      verified_account: formData?.verified_account,
    }

    dispatch(setWorkersFormData({ current: newData }))
    dispatch(setConfirming(true))
    dispatch(setFormMode(isUpdate))
    dispatch(saveWorkerInformation(workerId, newData))
      .then(() => {
        if (forceRedirect) {
          if (applicationData?.id) {
            dispatch(readWorkerJobGroup(applicationData?.id))
          }
          history.push(forceRedirect)
        }
        setConfirmed(true)
        dispatch(setWorkersFormData({}))
        props.isSuccess(true)
        dispatch(setFormErrors({}))
      })
      .catch((e) => {
        dispatch(hideLoader())
        const { code, error } = e.response.data
        if (code === 422) {
          dispatch(hideNotification())
          dispatch(setFormErrors(error))
        }
        setConfirmed(false)
        props.isSuccess(false)
      })
  }

  //onClick upload ID Card
  const handleUploadCard = (event) => {
    if (event?.target?.files !== null && event.target?.files?.length > 0) {
      setCardUrlFilename(event.target.files[0].name)
    } else {
      setCardUrlFilename('ファイルは選択されていません')
    }
    setIsCurrentCard(false)
  }

  const getInputLabelField = (label, name, placeholder, disabled) => {
    let inputValue = formData ? formData[name] : null
    let required = defaultInputProps.required
    let requiredText = null
    if (name === 'company_name' || name === 'address_line_2') {
      required = false
    }

    if (name === 'prefecture') {
      inputValue = prefecture
    }

    if (name === 'city') {
      inputValue = city
    }

    if (name === 'address_line_1') {
      inputValue = addressLine1
    }

    if (name === 'display_name') {
      requiredText = (
        <React.Fragment>
          *
          <Caption color="error" pt={0.4}>
            {' '}
            本名が開示されるまで表示されます。変更不可。
          </Caption>
        </React.Fragment>
      )
    }
    return (
      <InputLabel
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        isDisabled={disabled || false}
        {...(inputValue && { value: inputValue })}
        {...(required && requiredText && { requiredText: requiredText })}
        {...defaultInputProps}
        required={required || false}
        onChange={name == 'postal_code' ? () => handleChangePostal() : () => handleInputChange(name)}
      />
    )
  }

  const fullNameSpaceValidation = (value) => {
    const converter = new AFHConvert()
    if (eaw.eastAsianWidth(value) === 'W') {
      value = converter.toHalfWidth(value)
    }

    return value.trim().split(' ').length < 2 || /\s{2,}/g.test(value.trim())
  }

  useEffect(() => {
    setCardUrlFilename(formData?.id_card_filename ? formData?.id_card_filename : 'ファイルは選択されていません')
    setSelectedSkills(formData?.skills.length > 0 ? formData?.skills.map((id) => parseInt(id)) : [])
    setSelectedGender(formData?.gender ? formData?.gender : '男性')
    setPrefecture(formData?.prefecture ?? '')
    setCity(formData?.city ?? '')
    setAddressLine1(formData?.address_line_1 ?? '')
  }, [formData])

  const getInputMultiCheckbox = (label, name, items, subLabel) => {
    return (
      <InputMultiCheckbox
        ref={register(name + '[]')}
        name={name + '[]'}
        label={label}
        subLabel={subLabel}
        items={items}
        selected={selectedSkills}
        labelProps={{
          bold: 'true',
        }}
      />
    )
  }

  const validationRules = {
    display_name: {
      required: {
        value: String,
        message: rulesMsg.required('表示名を'),
      },
      validate: (value) => {
        if (value.trim().length <= 0) {
          return rulesMsg.required('表示名を')
        } else if (value.trim().length > 30) {
          return rulesMsg.max(30)
        } else {
          return
        }
      },
    },
    full_name: {
      required: {
        value: String,
        message: rulesMsg.required('お名前を'),
      },
      validate: (value) => {
        if (value.trim().length <= 0) {
          return rulesMsg.required('お名前を')
        } else if (value.trim().length > 30) {
          return rulesMsg.max(30)
        } else if (fullNameSpaceValidation(value)) {
          return rulesMsg.name_space
        } else {
          return
        }
      },
    },
    email_address: {
      required: {
        value: String,
        message: rulesMsg.email_credential,
      },
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: rulesMsg.email_pattern,
      },
    },
    birthday: {
      required: {
        value: String,
        message: rulesMsg.required('生年月日を'),
      },
      validate: (value) => {
        const isValid = dayjs(value, dateFormat, true).isValid()
        const minAge = 18
        const currentDate = dayjs()
        const birthDate = dayjs(value)

        if (!isValid) {
          return rulesMsg.invalid_date()
        } else if (minAge > currentDate.diff(birthDate, 'year')) {
          return rulesMsg.legal_age_required
        } else {
          return true
        }
      },
    },
    postal_code: {
      required: {
        value: String,
        message: rulesMsg.required('郵便番号を'),
      },
      validate: (value) => {
        if (value.length > 7) {
          return rulesMsg.min_numbers(7)
        } else if (!/^[0-9]*$/.test(value)) {
          return rulesMsg.min_numbers(7)
        } else {
          return true
        }
      },
    },
    prefecture: {
      required: {
        value: String,
        message: rulesMsg.prefecture_required,
      },
    },
    city: {
      required: {
        value: String,
        message: rulesMsg.city_required,
      },
    },
    address_line_1: {
      required: {
        value: String,
        message: rulesMsg.address_line_1_input,
      },
      validate: (value) => {
        return value.length <= 255 || rulesMsg.max(255)
      },
    },
    address_line_2: {
      validate: (value) => {
        return value.length <= 255 || rulesMsg.max(255)
      },
    },
    phone_number: {
      required: {
        value: Number,
        message: rulesMsg.required('電話番号を'),
      },
      validate: (value) => {
        if (value.length < 10) {
          return rulesMsg.min_numbers('10桁か11')
        } else if (value.length > 11) {
          return rulesMsg.min_numbers('10桁か11')
        } else if (!/^[0-9]*$/.test(value)) {
          return rulesMsg.min_numbers('10桁か11')
        } else {
          return true
        }
      },
      pattern: {
        value: /^(0|[0-9]\d*)(\.\d+)?$/,
        message: rulesMsg.min_numbers('10桁か11'),
      },
    },
    company_name: {
      validate: (value) => {
        return value.length <= 255 || rulesMsg.max(255)
      },
    },
    bank_account: {
      validate: (value) => {
        return value.length <= 500 || rulesMsg.max(500)
      },
    },
    biller: {
      validate: (value) => {
        return value.length <= 500 || rulesMsg.max(500)
      },
    },
    work_experience: {
      required: {
        value: String,
        message: '業務経験を入力してください。',
      },
      validate: (value) => {
        return value.length <= 1000 || rulesMsg.max(1000)
      },
    },
    id_card_url: {
      validate: (value) => {
        if (value !== null && value.length > 0) {
          const filename = value[0].name
          const fileSize = value[0].size / 1024 / 1024 // in MB
          let allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.JPG|\.JPEG|\.png|\.PNG|\.gif|\.GIF)$/i

          if (process.env.REACT_APP_CLAMAV_TEST === 'true') {
            allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.JPG|\.JPEG|\.png|\.PNG|\.gif|\.GIF|\.james|\.JAMES)$/i
          }
          if (!allowedExtensions.exec(filename)) {
            return rulesMsg.file_types_available
          } else if (fileSize > 4) {
            return rulesMsg.file_size(4)
          }
        }
        return true
      },
    },
  }

  const handleInputChange = (name) => {
    if (errors[name]) {
      trigger(name)
    }
  }

  return (
    <StyledCreateWorkerInformationForm container justifyContent="center" alignItems="center">
      <form onSubmit={handleSubmit(handleSubmitWorkerForm)} className="form">
        <Grid container>
          <Grid item xs={12}>
            <Heading>ワーカー情報編集</Heading>
          </Grid>
          <Grid id={'grid-full-name'} container>
            <Grid item xs={12} md={12} alignItems="center" className="BankAccount-Wrapper">
              {getInputLabelField('お名前', 'full_name', '例: 佐々通 太郎')}
              <Caption fontSize={12}>姓と名の間にスペースを入れて下さい。</Caption>
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 4 }}>
            <Grid item xs={12} md={6}>
              {getInputLabelField('表示名', 'display_name', '例: 佐々通 太郎', true)}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputDatePicker
                id={'birthday-ID'}
                ref={register('birthday', validationRules.birthday)}
                error={errors && errors.birthday ? true : false}
                errorMsg={errors ? errors.birthday?.message : null}
                name="birthday"
                label="生年月日"
                placeholder="YYYY/MM/DD"
                inputFormat={dateFormat}
                maxDate={dayjs(minBirthDate)}
                defaultCalendarMonth={dayjs(minBirthDate)}
                disableHighlightToday
                required
                color="primary"
                {...(formData && formData['birthday'] && { value: formData['birthday'] })}
                {...defaultInputProps}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <InputRadioGroup
                label="性別"
                name="gender"
                id={'RadioGroup-gender-ID'}
                ref={register('gender')}
                value={selectedGender || '男性'}
                items={['男性', '女性', '無回答']}
                onChange={onChangeGender}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 4 }}>
            <Grid item xs={12} md={6}>
              {getInputLabelField('郵便番号', 'postal_code', '例: 9820011')}
            </Grid>
            <Grid item container xs={12} md={6} pb={{ xs: 3, md: 0 }} alignItems={'center'}>
              <LargeButton
                variant="outlined"
                color="green"
                mode="light"
                onClick={handleGetAddress}
                id="PostalCodeBtn-ID"
              >
                {requestAddress && <CircularProgress size="1.5rem" color="inherit" />}
                {!requestAddress && '郵便番号から住所を検索'}
              </LargeButton>
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 4 }}>
            <Grid item xs={12} md={6}>
              {getInputLabelField('都道府県', 'prefecture', '', true)}
            </Grid>
            <Grid item xs={12} md={6}>
              {getInputLabelField('市町村区', 'city', '', true)}
            </Grid>
          </Grid>
          <Grid container columnSpacing={{ xs: 0, md: 4 }}>
            <Grid item xs={12}>
              {getInputLabelField('町名番地', 'address_line_1', '例: 長町６−１３−２')}
            </Grid>
            <Grid item xs={12}>
              {getInputLabelField('以降の住所', 'address_line_2', '例: フリシャー仙台ビル１０４')}
            </Grid>
            <Grid item xs={12} md={6}>
              {getInputLabelField('電話番号', 'phone_number', '例: 09012345678')}
            </Grid>
            <Grid item xs={12} pb={2}>
              <Label>本人確認（任意）</Label>
            </Grid>
            <Grid item xs={12} md={3}>
              <LargeButton
                id="UploadIdCardBtn-ID"
                className="UploadBtn"
                color="lightGray"
                mode="dark"
                maxwidth="unset"
                component="label"
                variant="contained"
              >
                ファイル選択
                <Input
                  id="InputUploadFile-ID"
                  type="file"
                  name="id_card_url"
                  ref={register('id_card_url', validationRules.id_card_url)}
                  hidden
                  onChange={handleUploadCard}
                />
              </LargeButton>
            </Grid>
            <Grid item xs={12} md={9} display="inherit" alignItems="center" pl={{ xs: 0, md: 2 }}>
              <Label>{cardUrlFilename}</Label>
              &nbsp;
              {isUpdate && currentformData?.original?.id_card_url && isCurrentCard && (
                <DownloadForOfflineIcon color="secondary" onClick={onDownloadCard} className="downloadIcon" />
              )}
            </Grid>
            <Grid item xs={12} pt={1} pb={3}>
              {errors.id_card_url && (
                <FormHelperText id={'FormHelperText-id_card_url-ID'} error>
                  {errors.id_card_url.message}
                </FormHelperText>
              )}
              <Caption>
                例: 免許書、保険書、パスポートなど。個人認証済みというアイコンが表示されオファーされやすくなります。
              </Caption>
            </Grid>
            <Grid item container mb={2}>
              <Grid item container pt={2}>
                <Grid item pb={1}>
                  <Label>適格請求書発行事業者 (任意)</Label>
                </Grid>
                <Grid item container xs={12} alignItems={'center'}>
                  <Grid item>
                    <Checkbox
                      id={'tekikaku_flag-ID'}
                      ref={register('tekikaku_flag')}
                      name={'tekikaku_flag'}
                      checked={formData ? (formData?.tekikaku_flag == 1 ? true : false) : false}
                      label={''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container pt={2}>
                <Grid item pb={1}>
                  <Label>新着作業メールを受け取る (任意)</Label>
                </Grid>
                <Grid item container xs={12} alignItems={'center'}>
                  <Grid item>
                    <Checkbox
                      id={'receive_new_jobs_email-ID'}
                      ref={register('receive_new_jobs_email')}
                      name={'receive_new_jobs_email'}
                      checked={formData ? (formData?.receive_new_jobs_email == 1 ? true : false) : false}
                      label={''}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container columnSpacing={{ xs: 0, md: 4 }} mb={3}>
            <Grid item xs={12} mb={3}>
              <Heading>スキル</Heading>
            </Grid>
            <Grid item xs={12}>
              <Label>所有資格</Label>
            </Grid>
            <Grid item xs={12}>
              <Label>取得済みの資格にチェックを入れてください</Label>
            </Grid>
          </Grid>

          <Grid id="skill-container" container>
            {workerSkills &&
              workerSkills.map((value, index) => (
                <Grid
                  item
                  xs={12}
                  md={workerSkills.length - 1 === index ? 12 : 6}
                  key={index}
                  className={'SkillsWrapper_' + index}
                >
                  {getInputMultiCheckbox(
                    value?.name,
                    'skills',
                    value?.skills,
                    workerSkills.length - 1 === index ? '手順書があれば可能な作業にチェックをいれてください' : ''
                  )}
                </Grid>
              ))}
          </Grid>
          <Grid item xs={12} className="WorkExperience-Wrapper">
            {getInputTextAreaField('業務経験', '', 'work_experience', '', 227, true)}
            <FormHelperText className="WorkExperience-Note">最大1000文字まで入力ください</FormHelperText>
          </Grid>
          <Grid container pt={2} display="flex" justifyContent="end">
            <Grid item container xs={12} pt={6} justifyContent={{ xs: 'center', sm: 'end' }} gap={2}>
              <LargeButton id="ConfirmedBackBtn" color="gray" mode="btn1" onClick={history.goBack}>
                戻る
              </LargeButton>
              <LargeButton id="WorkerSubmitBtn" type="submit" color="primary">
                {loader && <CircularProgress size="1.5rem" color="white" />}
                {!loader ? (!isFromApplication ? '保存' : '保存して応募画面に戻る') : ''}
              </LargeButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Modal
        open={confirmed}
        title={'ワーカー情報'}
        onClose={() => {
          history.push('/my-page')
        }}
        size="sm"
      >
        <Grid container display="flex" justifyContent="center" textAlign="center">
          <Grid item py={3} pb={6}>
            <Label>ワーカー情報変更が完了しました。</Label>
          </Grid>
          <LargeButton id="ModalConfirmBtn" onClick={() => history.push('/my-page')}>
            OK
          </LargeButton>
        </Grid>
      </Modal>
    </StyledCreateWorkerInformationForm>
  )
}

UpdateRegistrationForm.propTypes = {
  match: PropTypes.any,
  location: PropTypes.object,
  isSuccess: PropTypes.func,
}

export default UpdateRegistrationForm
