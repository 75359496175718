export const GET_PREFECTURES_LIST_REQUEST = 'GET_PREFECTURES_LIST_REQUEST'
export const GET_PREFECTURES_LIST_SUCCESS = 'GET_PREFECTURES_LIST_SUCCESS'
export const GET_PREFECTURES_LIST_FAILURE = 'GET_PREFECTURES_LIST_FAILURE'

export const GET_ALL_CLIENT_LIST_REQUEST = 'GET_ALL_CLIENT_LIST_REQUEST'
export const GET_ALL_CLIENT_LIST_SUCCESS = 'GET_ALL_CLIENT_LIST_SUCCESS'
export const GET_ALL_CLIENT_LIST_FAILURE = 'GET_ALL_CLIENT_LIST_FAILURE'

export const GET_ALL_STATUS_LIST_REQUEST = 'GET_ALL_STATUS_LIST_REQUEST'
export const GET_ALL_STATUS_LIST_SUCCESS = 'GET_ALL_STATUS_LIST_SUCCESS'
export const GET_ALL_STATUS_LIST_FAILURE = 'GET_ALL_STATUS_LIST_FAILURE'

export const GET_ALL_JOB_FILTER_OPTIONS_LIST_REQUEST = 'GET_ALL_JOB_FILTER_OPTIONS_LIST_REQUEST'
export const GET_ALL_JOB_FILTER_OPTIONS_LIST_SUCCESS = 'GET_ALL_JOB_FILTER_OPTIONS_LIST_SUCCESS'
export const GET_ALL_JOB_FILTER_OPTIONS_LIST_FAILURE = 'GET_ALL_JOB_FILTER_OPTIONS_LIST_FAILURE'

export const GET_ALL_WORKER_SKILLS_REQUEST = 'GET_ALL_WORKER_SKILLS_REQUEST'
export const GET_ALL_WORKER_SKILLS_SUCCESS = 'GET_ALL_WORKER_SKILLS_SUCCESS'
export const GET_ALL_WORKER_SKILLS_FAILURE = 'GET_ALL_WORKER_SKILLS_FAILURE'

export const GET_ALL_EXEPENSE_CATEGORIES_REQUEST = 'GET_ALL_EXEPENSE_CATEGORIES_REQUEST'
export const GET_ALL_EXEPENSE_CATEGORIES_SUCCESS = 'GET_ALL_EXEPENSE_CATEGORIES_SUCCESS'
export const GET_ALL_EXEPENSE_CATEGORIES_FAILURE = 'GET_ALL_EXEPENSE_CATEGORIES_FAILURE'

export const SET_DOWNLOAD_FILE_FAILURE = 'SET_DOWNLOAD_FILE_FAILURE'
export const SET_REDIRECT_ROUTE = 'SET_REDIRECT_ROUTE'
export const SET_PRIVATE_REDIRECT_ROUTE = 'SET_PRIVATE_REDIRECT_ROUTE'

export const RESET = 'RESET'

export const SET_OFFER_DROPDOWN_DATA = 'SET_OFFER_DROPDOWN_DATA'
export const ADD_LOCATION = 'ADD_LOCATION'

export const SET_404_PAGE = 'SET_404_PAGE'
