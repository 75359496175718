import React from 'react'
import { Divider, Grid, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getMessages } from 'validation'
import { signInUser } from 'services/auth'
import { authFailSignIn } from 'store/auth/actionCreators'
import { Label, InputLabel, LargeButton, Heading, Link, DividerLabel } from 'components'

const LoginForm = () => {
  const dispatch = useDispatch()
  const rulesMsg = getMessages('ja')
  const auth = useSelector((state) => state.auth)
  const isLoading = useSelector((state) => state.loader)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const mode = localStorage.getItem('mode')
  const notOPC = ['wkr', 'client'].includes(mode)

  // handle login
  const handleSignIn = (data) => {
    dispatch(signInUser(data))
  }

  //form validation
  const validationRules = {
    username: {
      required: {
        value: String,
        message: rulesMsg.email_credential,
      },
      pattern: {
        value: /^[A-Z0-9._%+"-]+@([\w-]+\.)+[\w-]{1,63}$/i, // Regex Email Validation
        message: rulesMsg.email_pattern,
      },
    },
    password: {
      required: {
        value: String,
        message: rulesMsg.password_credential,
      },
    },
  }

  //api login auth errors
  let login_error = null
  let email_error = null
  if (auth.signInFailed && auth.error) {
    if (auth.error.error == 'invalid_password' || auth.error.error == 'invalid_user_credentials') {
      login_error = rulesMsg.invalid_credentials
    }
    if (auth.error.error == 'invalid_account') {
      email_error = rulesMsg.invalid_account
    }
  }
  const handleInputChange = () => {
    //reset auth error on input change
    dispatch(authFailSignIn({}))
  }

  //labels
  const usernameLabel = { opc: 'メールアドレス' }
  const passwordLabel = { opc: 'パスワード' }

  //required
  const isRequired = {
    opc: true,
  }

  return (
    <Grid container py={'35px'} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={11} md={11} lg={11} sx={{ px: { xs: 2 } }}>
        <Heading>{mode === 'client' ? 'クライアントログイン' : 'ログイン'}</Heading>
      </Grid>
      <Grid item xs={12} sm={10} md={8} lg={8} sx={{ px: { xs: 2 }, pt: { xs: 1, sm: 4 } }} pb={6}>
        <form onSubmit={handleSubmit(handleSignIn)} className="form">
          <InputLabel
            className={notOPC ? 'NoLabel' : ''}
            id="LoginUsernameInput"
            name="username"
            ref={register('username', validationRules.username)}
            error={(errors && errors.username ? true : false) || login_error || email_error}
            errorMsg={(errors ? errors?.username?.message : null) || login_error || email_error}
            label={usernameLabel[mode] ?? ''}
            type="text"
            placeholder={'メールアドレスを入力してください'}
            required={isRequired[mode] ?? false}
            onChange={(e) => handleInputChange(e)}
          />
          <InputLabel
            className={notOPC ? 'NoLabel NoPad' : ''}
            id="LoginPasswordInput"
            name="password"
            ref={register('password', validationRules.password)}
            error={(errors && errors.password ? true : false) || (login_error ? true : false)}
            errorMsg={(errors ? errors?.password?.message : null) || (login_error ? login_error : null)}
            label={passwordLabel[mode] ?? ''}
            type="password"
            placeholder={'パスワードを入力してください'}
            required={isRequired[mode] ?? false}
            onChange={(e) => handleInputChange(e)}
          />
          <Grid item xs={12} pt={notOPC ? 2 : 0}>
            <LargeButton id="LoginBtn" type="submit" color="primary" maxwidth="inherit">
              {isLoading ? <CircularProgress size={24} color="white" /> : 'ログイン'}
            </LargeButton>
          </Grid>
          {notOPC && (
            <Link id="ForgotPasswordLink" to="/forgot_password">
              {'パスワードを忘れた場合はこちら'}
            </Link>
          )}
        </form>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ borderBottomWidth: 1, borderColor: 'rgb(179 178 178)' }} />
      </Grid>
      {notOPC ? (
        <Grid item xs={12} sm={10} md={8} lg={8} sx={{ px: { xs: 2 } }}>
          <DividerLabel>
            <Label>{'アカウントをお持ちでない場合'}</Label>
          </DividerLabel>
          <Link id="RegisterLink" to="/register">
            <LargeButton id="ResetBtn" type="button" color="success" mode="light" variant="outlined" maxwidth="inherit">
              {'会員登録する'}
            </LargeButton>
          </Link>
        </Grid>
      ) : (
        <Grid item xs={12} textAlign="center">
          <Label pt={4}>
            {'パスワードを忘れた場合は'}
            <Link id="ForgotPasswordLink" to="/forgot_password">
              {'こちら'}
            </Link>
          </Label>
        </Grid>
      )}
    </Grid>
  )
}

export default LoginForm
