import React from 'react'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword } from 'services/auth'
import ChangePasswordFormContent from './ChangePasswordFormContent'
import ChangePasswordFormSuccess from './ChangePasswordFormSuccess'
import { Heading } from 'components'

function ChangePasswordForm() {
  const loading = useSelector((state) => state.loader)
  const { isPasswordUpdated: success } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const handleUpdatePassword = (data) => {
    dispatch(changePassword(data))
  }

  const content = (success) => {
    return !success ? (
      <Grid container justifyContent="center" alignItems="center">
        <ChangePasswordFormContent handleUpdate={handleUpdatePassword} loading={loading} />
      </Grid>
    ) : (
      <Grid container justifyContent="center" alignItems="center">
        <ChangePasswordFormSuccess />
      </Grid>
    )
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} pb={3}>
        <Heading>{!success && 'パスワード変更'}</Heading>
      </Grid>
      {content(success)}
    </Grid>
  )
}

ChangePasswordForm.propTypes = {}

export default ChangePasswordForm
