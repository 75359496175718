import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'
import { styled } from '@mui/material/styles'
import { Grid, FormHelperText } from '@mui/material'
import { Paragraph } from 'components/atoms/Typography/Typography'
import { dateFormat, thisMonthEnd, thisMonthStart, thisDay } from 'utils/common'
import { InputDatePicker, P, Label, LargeButton, DatepickerControl, Input } from 'components'
//validation msg
import { getMessages } from 'validation'

const StyledDateList = styled(Grid, { shouldForwardProp: (prop) => prop })(({ theme }) => ({
  background: '#F6F6F680',
  opacity: 1,
  '& .JobDatesCount': {
    display: 'none',
  },
  '&.hasError': {
    border: `1px solid ${theme.palette['error'].main}`,
  },
  '& .JobOfferDates': {
    maxHeight: 300,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: `${theme.palette['lightGray'].light}`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${theme.palette['lightGray'].main}`,
      borderRadius: 2,
    },
  },
}))

const OfferDateRange = React.forwardRef((props, ref) => {
  const rulesMsg = getMessages('ja')
  const { errors, register, validationRules, clearErrors, setError, setValue, trigger, unregister } = ref
  const [dateFromValue, setDateFromValue] = useState(thisMonthStart)
  const [dateToValue, setDateToValue] = useState(thisMonthEnd)
  const [dates, setDates] = useState({})
  const { label, labelProps, required, defaultDates } = props
  const { start_date, end_date } = props.range
  const filteredDatesCount =
    Object.values(dates)?.filter((val) => (!isEmpty(val) || val !== '') && dayjs(val, dateFormat, true).isValid())
      ?.length || 0
  const {
    register: registerDate,
    trigger: triggerDate,
    formState: { errors: errorDate },
    clearErrors: clearDateErrors,
    getValues: getDateValues,
  } = useForm()

  useEffect(() => {
    setDateFromValue(dayjs(start_date).isBefore(dayjs(thisDay)) ? thisDay : dayjs(start_date))
    setDateToValue(dayjs(end_date).isBefore(dayjs(thisDay)) ? thisDay : dayjs(end_date))
  }, [start_date, end_date])

  useEffect(() => {
    if (defaultDates?.length > 0) {
      let datesObject = {}
      defaultDates.map((val, index) => {
        datesObject[index] = dayjs(val).format(dateFormat)
      })
      setDates(datesObject)
      const validDateCounts =
        Object.values(datesObject)?.filter(
          (val) => (!isEmpty(val) || val !== '') && dayjs(val, dateFormat, true).isValid()
        )?.length || 0
      setValue('job_dates_count', validDateCounts || 0)
      trigger('job_dates_count')
    }
  }, [defaultDates])

  const onDateChange = (newVal, type) => {
    if (type == 'start_date') {
      setDateFromValue(newVal)
      if (dayjs(newVal).diff(dayjs(dateToValue)) >= 1) {
        setDateToValue(newVal)
      }
    } else {
      setDateToValue(newVal)
      if (dayjs(dateFromValue).diff(dayjs(newVal)) >= 1) {
        setDateFromValue(newVal)
      }
    }
    clearDateErrors('start_date')
    clearDateErrors('end_date')
  }

  const labelDisplay = (
    <P {...labelProps} className="InputLabel" display="flex">
      {!label ? <>&nbsp;</> : label}
      {required && label != null && (
        <Label color="error" className="RequiredText">
          *
        </Label>
      )}
    </P>
  )

  const handleCreateDateRange = async () => {
    const validStartDate = await triggerDate('start_date')
    const validEndDate = await triggerDate('end_date')
    const startDate = dayjs(getDateValues('start_date'))
    const endDate = dayjs(getDateValues('end_date'))
    const range = endDate.diff(startDate, 'day')
    let index = 0
    let newIndex = Object.keys(dates).pop() || 0
    let datesObject = { ...dates }
    if (validStartDate && validEndDate) {
      if (getDateValues('start_date') && getDateValues('end_date')) {
        do {
          const newDateValue = startDate.add(index, 'day').format(dateFormat)
          newIndex = Object.values(datesObject)?.length > 0 ? parseInt(newIndex) + 1 : parseInt(newIndex)
          if (Object.values(dates).includes(newDateValue)) {
            setError(
              `job_dates[${newIndex}]`,
              { type: 'custom', message: rulesMsg.offer_unique_dates },
              { shouldFocus: true }
            )
          }
          datesObject[newIndex] = newDateValue
          index += 1
        } while (index <= range)
      }
    }
    setDates(datesObject)
    const validDateCounts =
      Object.values(datesObject)?.filter(
        (val) => (!isEmpty(val) || val !== '') && dayjs(val, dateFormat, true).isValid()
      )?.length || 0
    setValue('job_dates_count', validDateCounts || 0)
    trigger('job_dates_count')
  }

  const handleDeleteDate = (item) => {
    clearErrors(`job_dates[${item}]`)
    unregister(`job_dates[${item}]`)
    delete dates[item]
    setDates({ ...dates })
    const validDateCounts =
      Object.values(dates)?.filter((val) => (!isEmpty(val) || val !== '') && dayjs(val, dateFormat, true).isValid())
        ?.length || 0
    setValue('job_dates_count', validDateCounts || 0)
    trigger('job_dates_count')
  }

  const handleAddDate = () => {
    let dateId = parseInt(Object?.keys(dates)?.pop() ? Object.keys(dates).pop() : 0) + 1
    dates[dateId] = ''
    setDates(dates)
    const validDateCounts =
      Object.values(dates)?.filter((val) => (!isEmpty(val) || val !== '') && dayjs(val, dateFormat, true).isValid())
        ?.length || 0
    setValue('job_dates_count', validDateCounts || 0)
    trigger('job_dates_count')
  }

  const onJobDateChange = (newVal, key) => {
    const newDateValue = dayjs(newVal).format(dateFormat)
    trigger(`job_dates[${key}]`)
    dates[key] = newDateValue
    const validDateCounts =
      Object.values(dates)?.filter((val) => (!isEmpty(val) || val !== '') && dayjs(val, dateFormat, true).isValid())
        ?.length || 0
    setValue('job_dates_count', validDateCounts || 0)
    trigger('job_dates_count')
  }

  const addDateButtonContent = (
    <Grid item container spacing={1} justifyContent="space-between" mt={2}>
      <Grid item xs={12} sm={10} md={10.9} lg={11} justifyContent={{ xs: 'center', sm: 'end' }} display="inline-flex">
        <LargeButton variant="outlined" color="primary" mode="light" minwidth="fit-content" onClick={handleAddDate}>
          {'追加'}
        </LargeButton>
      </Grid>
    </Grid>
  )

  const mainValidationRules = {
    start_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        if (!isValid) {
          return rulesMsg.invalid_date()
        } else if (dayjs(value).isBefore(dayjs(thisDay))) {
          return rulesMsg.after_today()
        } else {
          return true
        }
      },
    },
    end_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        const startDateVal = getDateValues('start_date')
        const range = dayjs(value).diff(startDateVal, 'day') + 1
        if (!isValid) {
          return rulesMsg.invalid_date()
        } else if (dayjs(value).isBefore(dayjs(thisDay))) {
          return rulesMsg.after_today()
        } else if (dayjs(value).isBefore(startDateVal)) {
          return rulesMsg.is_date_before()
        } else if (range > 31) {
          return rulesMsg.date_range()
        } else {
          return true
        }
      },
    },
  }

  const datesContent = (
    <StyledDateList
      py={2}
      className={errors && (errors?.job_dates?.length > 0 || errors?.job_dates_count) && 'hasError'}
    >
      <Grid container direction="column">
        <Grid item xs={12} sm={12} md={12} lg={12} ml={2} pb={1} px={2}>
          <Input
            className="JobDatesCount"
            name="job_dates_count"
            ref={register('job_dates_count', validationRules.job_dates_count)}
            disabled
            value={filteredDatesCount}
          />
          <Paragraph>{'最大31日分まで追加可能'}</Paragraph>
        </Grid>
        <Grid item container xs={12} rowSpacing={1} className="JobOfferDates" px={2}>
          {Object.keys(dates).map((key) => {
            return (
              <DatepickerControl
                key={key}
                dataID={key}
                name={`job_dates[${key}]`}
                ref={register(`job_dates[${key}]`, validationRules.job_dates)}
                error={errors && errors?.job_dates?.[key] ? true : false}
                errorMsg={errors ? errors?.job_dates?.[key]?.message : null}
                color={'primary'}
                inputFormat={dateFormat}
                value={dayjs(dates[key])}
                onChange={(newVal) => onJobDateChange(newVal, key)}
                onRemove={() => handleDeleteDate(key)}
                placeholder="例: 2022/03/25"
              />
            )
          })}
        </Grid>
        {Object.keys(dates).length < 31 && addDateButtonContent}
      </Grid>
    </StyledDateList>
  )

  return (
    <React.Fragment>
      {labelDisplay}
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={4.5} md={5.2} lg={5.3} xl={5.4}>
          <InputDatePicker
            id="start_date-ID"
            ref={registerDate('start_date', mainValidationRules.start_date)}
            error={errorDate && errorDate?.start_date ? true : false}
            errorMsg={errorDate ? errorDate?.start_date?.message : null}
            name="start_date"
            label={'from'}
            color="primary"
            inputFormat={dateFormat}
            value={dayjs(dateFromValue)}
            onChange={(newVal) => onDateChange(newVal, 'start_date')}
            placeholder="例: 2022/03/25"
          />
        </Grid>
        <Grid item xs={12} sm={4.5} md={5.2} lg={5.3} xl={5.4}>
          <InputDatePicker
            id="jobList-end_date-ID"
            ref={registerDate('end_date', mainValidationRules.end_date)}
            error={errorDate && errorDate?.end_date ? true : false}
            errorMsg={errorDate ? errorDate?.end_date?.message : null}
            name="end_date"
            label={'to'}
            color="primary"
            inputFormat={dateFormat}
            value={dayjs(dateToValue)}
            onChange={(newVal) => onDateChange(newVal, 'end_date')}
            placeholder="例: 2022/03/25"
          />
        </Grid>
        <Grid item xs={12} sm={2.5} md={1.6} lg={1.4} xl={1.2} mt={{ xs: 0, sm: 3.5 }}>
          <LargeButton
            id="generateDateBtn-ID"
            variant="outlined"
            color="primary"
            mode="light"
            minwidth="fit-content"
            maxwidth="unset"
            onClick={handleCreateDateRange}
          >
            {'自動追加'}
          </LargeButton>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={8} md={7} lg={6}>
        {datesContent}
        <Grid item xs={12}>
          {errors && errors?.job_dates_count && (
            <FormHelperText error={true}>{errors?.job_dates_count?.message}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
})

OfferDateRange.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  labelProps: PropTypes.object,
  errors: PropTypes.object,
  validationRules: PropTypes.object,
  range: PropTypes.object,
  defaultDates: PropTypes.any,
}

export default OfferDateRange
