import React, { useState, useEffect, Fragment } from 'react'
import { Grid } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import InnerHTML from 'dangerously-set-html-content'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { readJobGroup } from 'services/jobGroups'
//componets
import { Label, Heading, LargeButton, Banner, ContentDisplay, MinMaxDisplay, BackArrow, Modal } from 'components'

import isEmpty from 'lodash/isEmpty'
import { status } from 'utils/config/status'
import { toolTipTitle } from 'utils/config/staticContents'
import { changeSearchCriteria } from 'services/application'
import { changeSearchClientCriteria } from 'services/jobs'
import { JobGroupCancel, JobGroupCancelSuccess } from 'components/molecules/Modal'

const JobGroupDetails = (props) => {
  const dispatch = useDispatch()

  const [jobGroupId, setJobGroupId] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [jobGroupCanceledStatus, setJobGroupCanceledStatus] = useState('')

  const formData = useSelector((state) => state.jobGroups.formData)
  const { search: searchParameters } = useSelector((state) => state.applications)

  const displayData = formData?.formatted
  const isClient = localStorage.getItem('mode').includes('client')
  const { state } = props.location

  const validateOngoingJobStatus = () => {
    if (displayData && 'jobs' in displayData) {
      return !!displayData.jobs?.filter(
        ({ status_id }) => status_id >= status.job.offer && status_id <= status.job.completed
      ).length
    }
    return false
  }

  const hasOngoingJob = validateOngoingJobStatus()
  const isJobGroupCanceled = displayData?.status_name.includes('キャンセル')

  useEffect(() => {
    let jobGroupId = props?.match?.params?.id
    setJobGroupId(jobGroupId > 0 && jobGroupId)
    if (jobGroupId) {
      dispatch(readJobGroup(jobGroupId))
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(displayData)) props.handleData(displayData)
    return
  }, [displayData])

  const handleSearchApplication = (name) => {
    dispatch(changeSearchCriteria({ ...searchParameters, job_group: name }))
  }

  const handleSearchJob = (id) => {
    dispatch(
      changeSearchClientCriteria({
        ...searchParameters,
        job_group_name: `${`"${id}"`}`,
      })
    )
  }

  const handleSetJobGroupCancelStatus = (canceledStatus) => {
    setJobGroupCanceledStatus(canceledStatus)
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }

  const formActionContent = isClient ? (
    <Grid container direction={'column'} spacing={2}>
      <Grid item xs={6} sm={6} md={5} lg={3} textAlign="end">
        <Link to={`/job-group/create/${jobGroupId}`}>
          <LargeButton id="AddJobGroupBtn" color="primary" mode="light">
            {'コピー'}
          </LargeButton>
        </Link>
      </Grid>
      <Grid item xs={6} sm={6} md={5} lg={3} textAlign="end" sx={{ marginTop: { xs: 0, sm: -6, md: -4, lg: 0.5 } }}>
        <Link to={`/job-group/update/${formData?.original?.id}`}>
          <LargeButton id="AddJobGroupBtn" color="primary" mode="light">
            {'編集'}
          </LargeButton>
        </Link>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction={'column'} spacing={2}>
      <Grid item xs={6} sm={6} md={5} lg={3} textAlign="end">
        <Link to={`/job/create?job_group=${displayData?.id}`}>
          <LargeButton id="AddJobGroupBtn" color="primary">
            {'作業新規登録'}
          </LargeButton>
        </Link>
      </Grid>
      <Grid item xs={6} sm={6} md={5} lg={3} textAlign="end" sx={{ marginTop: { xs: 0, sm: -3, md: -1.5, lg: -1 } }}>
        <Link to={`/job_group/create/${jobGroupId}`}>
          <LargeButton id="AddJobGroupBtn" color="primary">
            {'コピー'}
          </LargeButton>
        </Link>
      </Grid>
    </Grid>
  )

  const modalContent = jobGroupCanceledStatus.includes('success') ? (
    <JobGroupCancelSuccess onClose={handleModalClose} jobGroupData={displayData} />
  ) : (
    <JobGroupCancel
      onClose={handleModalClose}
      jobGroupData={displayData}
      jobGroupCanceled={handleSetJobGroupCancelStatus}
    />
  )

  const popperModalContent = (
    <Grid item pt={2.5}>
      {isClient ? '応募URLをコピーしました。' : 'URLをコピーしました。'}
    </Grid>
  )

  return (
    <Fragment>
      <Grid>
        {(state?.fromJobGroupList || !isClient) && (
          <BackArrow
            label={'作業グループ一覧に戻る'}
            path={`/${isClient ? 'job-group/' : 'job_group_'}list`}
            margin="30px 5px 0px 22px"
          />
        )}
        <Grid container pt={5}>
          <Grid item xs={12}>
            <Heading>{displayData?.alias}</Heading>
          </Grid>
          <Grid item xs={12} sm={6} md={4} py={isClient ? 4.5 : 3}>
            {isClient && (
              <Grid container mb={2}>
                <Grid item xs={6} sm={6} md={6}>
                  <Link
                    to={`/application/list`}
                    style={{ textDecoration: 'underline' }}
                    onClick={() => {
                      handleSearchApplication(displayData?.name)
                    }}
                  >
                    {'応募一覧'}
                  </Link>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Link
                    to={`/job/list`}
                    style={{ textDecoration: 'underline' }}
                    onClick={() => {
                      handleSearchJob(displayData?.id, displayData?.start_date, displayData?.end_date)
                    }}
                  >
                    {'作業一覧'}
                  </Link>
                </Grid>
              </Grid>
            )}
            <Label color="gray" pb={1}>
              ステータス
            </Label>
            <Banner label={displayData?.status_name} variant="outlined" color="success" bold="true" />
          </Grid>
          <Grid item xs={12} sm={6} md={8} display="inline-flex" justifyContent={{ xs: 'center', sm: 'end' }}>
            {formActionContent}
          </Grid>
          {!isClient && (
            <Grid item xs={12} sm={6} md={8} sx={{ marginTop: { xs: 2 } }}>
              <Link to={`/${isClient ? 'job-group' : 'job_group'}/update/${formData?.original?.id}`}>
                <LargeButton id="AddJobGroupBtn" color="primary">
                  {'編集'}
                </LargeButton>
              </Link>
            </Grid>
          )}
          {!isClient && <ContentDisplay label="クライアント:" data={displayData?.client_name} />}
          <ContentDisplay label="案件名:" data={displayData?.project_name} />
          <ContentDisplay label="作業グループ担当者:" data={displayData?.client_user_name} />
          <ContentDisplay
            label="公開設定:"
            data={
              <>
                {displayData?.visibility}
                {displayData?.close_for_application ? <Label>✓ 募集を締め切る</Label> : ''}
              </>
            }
            toolTip={isClient ? toolTipTitle : ''}
            applicationURL={isClient ? '応募URLをコピー' : ''}
            inputPopperValue={isClient ? `https://${process.env.REACT_APP_DOMAIN}/job-group/show/${jobGroupId}` : ''}
            popperModalContent={isClient && popperModalContent}
          />
          <ContentDisplay
            label="募集を締め切る:"
            data={formData.formatted?.close_for_application ? 'はい' : 'いいえ'}
          />
          <ContentDisplay label={isClient ? '作業グループ名:' : 'ジョブグループ名'} data={displayData?.name} />
          <ContentDisplay label={isClient ? '作業グループ開始日:' : '作業開始日:'} data={displayData?.start_date} />
          <ContentDisplay label={isClient ? '作業グループ終了日:' : '作業終了日:'} data={displayData?.end_date} />
          <ContentDisplay label="作業実施都道府県:" data={displayData?.prefecture} />
          <MinMaxDisplay
            label="基本作業費(1日あたり):"
            min_data={displayData?.min_fee}
            max_data={displayData?.max_fee}
          />
          <ContentDisplay label="募集人数:" data={displayData?.no_of_pos} />
          <ContentDisplay label="応募問い合わせ用メールアドレス:" data={displayData?.email_address} />
          <ContentDisplay
            label="作業内容:"
            data={<InnerHTML className="InnerHtml" html={displayData?.task_details} />}
          />
          <ContentDisplay label="作業費:" data={<InnerHTML className="InnerHtml" html={displayData?.fee_details} />} />
          <ContentDisplay
            label="その他手当:"
            data={<InnerHTML className="InnerHtml" html={displayData?.allowance_details} />}
          />
          {!isClient && (
            <ContentDisplay
              label="登録日:"
              data={<InnerHTML className="InnerHtml" html={displayData?.registered_date} />}
            />
          )}
          {!hasOngoingJob && !isJobGroupCanceled && (
            <Grid
              item
              container
              pt={8}
              pb={2}
              justifyContent="center"
              display={{ xs: 'block', sm: 'inhiret' }}
              spacing={2}
            >
              <Grid item>
                <LargeButton
                  id="JobGroupCancelBtn"
                  color="gray"
                  onClick={() => {
                    setIsOpen(true)
                  }}
                >
                  キャンセル
                </LargeButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Modal
        open={isOpen}
        title={jobGroupCanceledStatus.includes('success') ? '作業グループキャンセル' : '作業グループキャンセル'}
        onClose={handleModalClose}
        size={'md'}
      >
        {modalContent}
      </Modal>
    </Fragment>
  )
}

JobGroupDetails.displayName = 'Job Group Details'

JobGroupDetails.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
  handleData: PropTypes.func,
}

export default JobGroupDetails
