import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Label, Input, Caption } from 'components'

const StyledInputTextArea = styled(Box)(({ theme }) => ({
  padding: `${theme.standards.padding.input.default}`,
  '& .InputLabel': {
    paddingBottom: `${theme.standards.padding.label.inputLabel}`,
    fontSize: `${theme.standards.fontSize['input_label']}px !important`,
  },
  '& .RequiredText': {
    display: 'inline-flex',
  },
}))

const InputTextArea = React.forwardRef((props, ref) => {
  const excludedProps = ['label', 'labelProps', 'required', 'sideLabel', 'subLabel']
  let defaultProps = omit(props, excludedProps)
  const { label, labelProps, sideLabel } = props

  const labelDisplay = (
    <React.Fragment>
      <Label {...labelProps} className="InputLabel">
        {label}
        {props.required && (
          <Label color="error" className="RequiredText">
            *
          </Label>
        )}
      </Label>
      {props?.subLabel && <Caption className="InputLabel-subLabel">{props?.subLabel}</Caption>}
    </React.Fragment>
  )
  const topLabelDisplay = (
    <Box>
      {labelDisplay}
      <Input {...defaultProps} multiline ref={ref} />
    </Box>
  )
  const sideLabelDisplay = (
    <Box alignItems="center">
      <Box display="inline-grid" width={{ xs: 1, sm: 5 / 12, md: 4 / 12 }}>
        {labelDisplay}
      </Box>
      <Box display="inline-grid" width={{ xs: 1, sm: 7 / 12, md: 8 / 12 }}>
        <Input {...defaultProps} multiline ref={ref} />
      </Box>
    </Box>
  )

  return (
    <StyledInputTextArea className="InputTextArea">
      {sideLabel ? sideLabelDisplay : topLabelDisplay}
    </StyledInputTextArea>
  )
})

InputTextArea.displayName = 'Input Text Area'

InputTextArea.defaultProps = {
  color: 'secondary',
}

InputTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
  sideLabel: PropTypes.bool,
  required: PropTypes.bool,
  subLabel: PropTypes.string,
}

export default InputTextArea
