export const headerItems = (mode) => {
  let menuItems = []
  let profileItems = []

  switch (mode) {
    case 'opc': {
      profileItems = [
        {
          name: 'クライアント一覧',
          link: '/client/list',
        },
        {
          name: 'クライアント担当者一覧',
          link: '/client-user/list',
        },
        {
          name: 'ワーカー一覧',
          link: '/worker_list',
        },
        {
          name: '案件一覧',
          link: '/project_list',
        },
        {
          name: '作業グループ一覧',
          link: '/job_group_list',
        },
        {
          name: '作業一覧',
          link: '/job_list',
        },
        {
          name: '応募一覧',
          link: '/application/list',
        },
        {
          name: '実績一覧',
          link: '/result_list',
        },
        {
          name: 'システム利用料一覧',
          link: '/system-usage-fee/list',
        },
      ]
      break
    }
    case 'wkr': {
      menuItems = [
        {
          name: 'マイページ',
          link: '/my-page',
        },
        {
          name: '作業検索',
          link: '/job/search',
        },
        {
          name: '作業オファー',
          link: '/offer/list',
        },
        {
          name: 'MY作業一覧',
          link: '/job/list',
        },
        {
          name: '請求書',
          link: '/invoice_list',
        },
        {
          name: 'ワーカー登録',
          link: '/worker-information',
          type: '会員',
        },
      ]
      profileItems = [
        {
          name: 'ワーカー情報編集',
          link: '/worker/update',
          type: 'ワーカー',
        },
        {
          name: 'パスワード変更',
          link: '/password/change',
        },
      ]
      break
    }
    case 'client': {
      menuItems = [
        {
          name: '案件一覧',
          link: '/project/list',
        },
        {
          name: '作業グループ一覧',
          link: '/job-group/list',
        },
        {
          name: '作業一覧',
          link: '/job/list',
        },
        {
          name: '応募一覧',
          link: '/application/list',
        },
        {
          name: '支払予定一覧',
          link: '/payment/list',
        },
        {
          name: 'システム利用料',
          link: '/system-usage-fee/list',
        },
      ]
      profileItems = [
        {
          name: 'パスワード変更',
          link: '/password/change',
        },
      ]
      break
    }
    default: {
      break
    }
  }

  return { menuItems, profileItems }
}
