import { colors } from '@mui/material'

const white = '#FFFFFF'
const black = '#2A2A2A'
const green = '#7CA202'

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#FF6B09',
    main: '#ff6b09',
    light: '#FFB340',
  },
  gray: {
    contrastText: white,
    dark: '#444444',
    main: '#7E7E7E',
    light: '#999999',
    btn1: '#B1B1B1',
  },
  lightGray: {
    contrastText: black,
    dark: '#D9D9D9',
    main: '#999999',
    light: '#CECECE',
  },
  green: {
    contrastText: green,
    dark: '#7CA202',
    main: '#FFFFFF',
    light: '#7CA202',
  },
  success: {
    contrastText: white,
    dark: '#02D27F',
    main: '#0DAB57',
    light: '#7CA202',
    multiSelectMenu: {
      main: '#32A05F',
      light: '#32A05F1F',
    },
  },
  warning: {
    contrastText: black,
    dark: '#32A05F',
    main: '#ffe1ac94',
    light: '#fffb7ed4',
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400'],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: '#e53935 !important',
    light: '#FFD2D2',
  },
  text: {
    primary: '#2a2a2a',
    secondary: '#FF6B09',
    gray: '#2A2A2A99',
    gray2: '#8A8A8A',
    light_gray: '#00000066',
    error: '#FF0000',
    link: '#0044FF',
    light_blue: '#004BE2',
    main: '#7E7E7E',
    white: '#FFFFFF',
  },
  background: {
    default: '#F4F6F8',
    primary: '#FEF3DD',
    gray: '#ebebeb',
    paper: white,
    light_gray: '#F2F2F2',
  },
  view: {
    main: '#D2C402',
  },
  edit: {
    main: '#7CA202',
  },
  pageWrapper: {
    opc: '#FDFD96',
    wkr: '#F4FCD7',
    client: '#FFE1AC',
  },
  navigator: {
    default: '#000000A6',
    selected: '#D62907',
    indicator: '#B5B5B6',
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  notice: '#fef3dd',
  disabled: '#E9E9E9',
}
