import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Tabs, Tab } from '@mui/material'
import { matchPath, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { filterMenuItems } from 'utils/helper'
import { resetReducers } from 'store/common/actionCreators'

const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  marginRight: 40,
  color: `${theme.palette.black}`,
  '& .MuiTab-root': {
    color: `${theme.palette.navigator['default']}`,
    '&.Mui-selected': {
      color: `${theme.palette.navigator['selected']}`,
    },
  },
  '& .MuiTabs-flexContainer': {
    marginTop: 14,
  },
  '& .MuiTabs-scroller': {
    height: 75,
  },
  '& .MuiTabs-indicator': {
    backgroundColor: `${theme.palette.navigator['indicator']}`,
  },
}))

const NavTabs = (props) => {
  const { menuItems, isMobile } = props
  const [currentTab, setCurrentTab] = useState(false)
  const [isActivated, setIsActivated] = useState(false)
  const [isSelected, setIsSelected] = useState()
  const [links, setLinks] = useState([])
  const authUser = useSelector((state) => state.auth?.user)
  const user = JSON.parse(localStorage.getItem('authUser'))
  const dispatch = useDispatch()

  useEffect(() => {
    setLinks(!isMobile ? filterMenuItems(localStorage.getItem('mode'), menuItems, user) : [])
  }, [authUser])

  useEffect(() => {
    let t = setTimeout(() => {
      setIsActivated(true)
    }, 800)
    return () => clearTimeout(t)
  }, [])

  useEffect(() => {
    useRouteMatch(links)
  }, [links])

  const useRouteMatch = (links) => {
    const patterns = links.map(({ link }) => link)
    const pathname = location.pathname
    let possibleMatch

    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i]
      possibleMatch = matchPath(pattern, pathname)

      if (possibleMatch !== null) {
        return setCurrentTab(possibleMatch?.path)
      }
    }
    return setCurrentTab(false)
  }

  const handleHoverEffect = (value) => {
    setIsSelected(value)
  }

  const handleLeaveEffect = () => {
    setIsSelected(null)
  }

  const handleReset = () => {
    dispatch(resetReducers())
  }

  return (
    <StyledTabs value={isActivated ? isSelected || currentTab : false} variant="scrollable" allowScrollButtonsMobile>
      {links.map(({ name, link }, index) => (
        <Tab
          key={index}
          label={name}
          onMouseEnter={handleHoverEffect.bind(this, link)}
          onMouseLeave={handleLeaveEffect.bind(this)}
          value={link}
          to={link}
          component={Link}
          onClick={handleReset}
        />
      ))}
    </StyledTabs>
  )
}

NavTabs.propTypes = {
  menuItems: PropTypes.array,
  isMobile: PropTypes.bool,
}

export default NavTabs
