import React from 'react'
import { Grid, CircularProgress } from '@mui/material'
import successIcon from 'assets/svg/success.svg'
import { useDispatch, useSelector } from 'react-redux'
import { SubHeading, Icon, LargeButton } from 'components'
import { signInUser } from 'services/auth'

function SuccessRegistrationForm() {
  const { userData } = useSelector((state) => state.users)
  const loader = useSelector((state) => state.loader)
  const dispatch = useDispatch()

  const handleSignIn = () => {
    dispatch(signInUser({ username: userData.email, password: userData.password }))
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" rowSpacing={4} py={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Icon source={successIcon} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} mt={-2}>
        <SubHeading color="secondary" bold="ture" variant="title">
          {'ご登録ありがとうございました。'}
        </SubHeading>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <LargeButton color="primary" mode="light" onClick={handleSignIn}>
          {loader ? <CircularProgress size={24} color="white" /> : '案件を登録する'}
        </LargeButton>
      </Grid>
    </Grid>
  )
}

export default SuccessRegistrationForm
