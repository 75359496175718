import MuiButton from './MuiButton'
import MuiIconButton from './MuiIconButton'
import MuiPaper from './MuiPaper'
import MuiTableCell from './MuiTableCell'
import MuiTableHead from './MuiTableHead'
import MuiTypography from './MuiTypography'
import MuiTableRow from './MuiTableRow'
import MuiMenuItem from './MuiMenuItem'
import MUIRichTextEditor from './MUIRichTextEditor'
import MuiAutocomplete from './MuiAutocomplete'

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiTableRow,
  MuiMenuItem,
  MUIRichTextEditor,
  MuiAutocomplete,
}
