import * as types from './actionTypes'

export function OPCActionSearchApplication(applicationList) {
  return {
    type: types.OPC_SEARCH_APPLICATION,
    payload: applicationList,
  }
}
export function actionSearchApplication(applicationList) {
  return {
    type: types.SEARCH_APPLICATION,
    payload: applicationList,
  }
}

export function OPCActionSetSearchCriteria(searchParameters) {
  return {
    type: types.OPC_SET_SEARCH_CRITERIA,
    payload: searchParameters,
  }
}
export function actionSetSearchCriteria(searchParameters) {
  return {
    type: types.SET_SEARCH_CRITERIA,
    payload: searchParameters,
  }
}

export function fetchingDataFail(error) {
  return {
    type: types.FETCHING_DATA_FAILED,
    payload: error,
  }
}

export function setApplicationFormData(formData) {
  return {
    type: types.SET_APPLICATION_FORM_DATA,
    payload: formData,
  }
}

export function resetCurrentForm() {
  return {
    type: types.RESET_OPC_SEARCH_PARAMS,
  }
}
