import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { InputLabel, LargeButton, Heading, Icon, InputSelect, TableList, Link } from 'components'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setClientUserFormData, resetCurrentForm } from 'store/client-user/actionCreators'

import AddIcon from '@mui/icons-material/Add'
import searchIcon from 'assets/svg/search.svg'

//validation msg
import { getMessages } from 'validation'

const ClientListForm = (props) => {
  const [selectedRow, setSelectedRow] = useState({})
  const prefectureslist = useSelector((state) => state.common.prefectureslist)
  const rulesMsg = getMessages('ja')
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  //Get All Active Clients
  const handleSearch = (data) => {
    props.handlePageSearch(data)
  }

  const handleRegister = () => {
    const clientCreatePage = '/client/create'
    dispatch(resetCurrentForm())
    history.push(clientCreatePage)
  }

  const validationRules = {
    keyword: {
      validate: (value) => {
        return value.trim().length <= 255 || rulesMsg.max(255)
      },
    },
    project: {
      validate: (value) => {
        return value.trim().length <= 255 || rulesMsg.max(255)
      },
    },
    phone_number: {
      validate: (value) => {
        return value.trim().length <= 255 || rulesMsg.max(255)
      },
    },
    email: {
      validate: (value) => {
        return value.trim().length <= 255 || rulesMsg.max(255)
      },
    },
  }

  const handleClientDetails = () => {
    const clientDetailsPage = '/client/show/' + selectedRow?.id
    history.push(clientDetailsPage)
  }

  const handleEdit = () => {
    const clientUpdatePage = '/client/update/' + selectedRow?.id
    dispatch(resetCurrentForm())
    history.push(clientUpdatePage)
  }

  const handleAddPic = () => {
    const clientUserCreatePage = '/client-user/create'
    dispatch(resetCurrentForm())
    const newData = {
      ...selectedRow,
      client: {
        id: selectedRow?.id,
        name: selectedRow?.name?.props?.children?.props?.children,
      },
      name: '',
      phone_number: '',
      email: '',
    }
    dispatch(setClientUserFormData({ original: newData }))
    history.push(clientUserCreatePage)
  }

  const actionList = [
    {
      handler: handleClientDetails,
      name: '閲覧',
    },
    {
      handler: handleEdit,
      name: '編集',
    },
    {
      handler: handleAddPic,
      name: '担当者登録',
    },
  ]

  const handleSelectedRow = (rowData) => {
    setSelectedRow(rowData)
  }

  //Format table list data
  const formatData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        name: (
          <Link to={`/client/show/${item?.id}`}>
            <u>{item?.name}</u>
          </Link>
        ),
        prefecture: item?.prefecture?.name,
      }
    })
  }

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Heading>{'クライアント一覧'}</Heading>
        </Grid>
        <Grid item container justifyContent="flex-end" pt={{ xs: 2, sm: 0 }}>
          <Grid item xs={12} sm={5} md={4} lg={3} textAlign="end">
            <LargeButton startIcon={<AddIcon size={'sm'} color="#ffffff" />} onClick={handleRegister}>
              {'クライアント新規登録'}
            </LargeButton>
          </Grid>
        </Grid>
        <Grid container pt={6}>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Grid container item xs={12} alignItems="start">
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <InputLabel
                  name="keyword"
                  ref={register('keyword', validationRules.keyword)}
                  error={errors && errors.keyword ? true : false}
                  errorMsg={errors ? errors?.keyword?.message : null}
                  label="クライアント名・ID"
                  placeholder="例: 佐々通 太郎"
                  type="text"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} alignItems="start">
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <InputSelect
                  name="prefecture"
                  ref={register('prefecture')}
                  label="都道府県"
                  menuItems={[{ id: '0', name: 'すべて' }, ...prefectureslist]}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <InputLabel
                  name="phone_number"
                  ref={register('phone_number', validationRules.phone_number)}
                  error={errors && errors.phone_number ? true : false}
                  errorMsg={errors ? errors?.phone_number?.message : null}
                  label="電話番号"
                  placeholder=""
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <InputLabel
                  name="email"
                  ref={register('email', validationRules.email)}
                  error={errors && errors.email ? true : false}
                  errorMsg={errors ? errors?.email?.message : null}
                  label="メールアドレス"
                  placeholder=""
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2} mt={3.5}>
                <LargeButton
                  type="submit"
                  bold="true"
                  color="warning"
                  startIcon={
                    <Icon
                      size={'sm'}
                      // disabled={props.isLoading ? true : false}
                      source={searchIcon}
                    />
                  }
                >
                  {'検索'}
                </LargeButton>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12} pt={6}>
            <TableList
              title={`Client List`}
              headCells={props.headerCells}
              data={formatData(props.data)}
              totalPage={props.totalPage}
              totalCount={props.totalCount}
              handlePageChange={props.handlePageChange}
              isLoading={props.isLoading}
              handleSort={props.handleSort}
              handleSelectedRow={handleSelectedRow}
              sort={props.sort}
              sortBy={props.sortBy}
              currentPage={props.currentPage}
              action={true}
              actionList={actionList}
              pageFrom={props.pageFrom}
              pageTo={props.pageTo}
            />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

ClientListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

export default ClientListForm
