import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
//components
import { Label } from '../Typography'
const StyledIconLabel = styled(Label, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const IconLabel = (props) => {
  return (
    <StyledIconLabel {...props}>
      {props.icon}&nbsp;{props.label}
    </StyledIconLabel>
  )
}

IconLabel.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
}

export default IconLabel
