import React, { useState } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import _without from 'lodash/without'
import { styled } from '@mui/material/styles'
import { Box, Stack, Chip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

//componets
import { Label, MultiSelect } from 'components'
const StyledInputMultiSelect = styled(Box)(({ color, mode, theme }) => ({
  padding: `${theme.standards.padding.input.default}`,
  '& .MultiSelect-RenderInputBox': {
    overflowX: 'auto',
    height: 36,
    alignItems: 'center',
    padding: '2px 0px 2px 0px',
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: `${theme.palette['lightGray'].light}`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${theme.palette['lightGray'].main}`,
      borderRadius: 2,
    },
    '& .MultiSelect-Chips': {
      borderRadius: 4,
      fontSize: 12,
      height: 20,
      backgroundColor: `${theme.palette[color]?.multiSelectMenu?.[mode]}`,
      color: `${theme.palette[color]?.multiSelectMenu?.main}`,
    },
    '& .MultiSelect-ChipCloseBtn': {
      color: `${theme.palette[color]?.multiSelectMenu?.main}`,
    },
  },
  '& .InputLabel': {
    paddingBottom: `${theme.standards.padding.label.inputLabel}`,
  },
}))

const InputMultiSelect = React.forwardRef((props, ref) => {
  const excludedProps = ['label', 'type', 'onChange', 'labelProps', 'required', 'mode']
  let defaultProps = omit(props, excludedProps)

  const { label, menuItems, placehoder, labelProps, color, mode } = props
  const [selected, setSelected] = useState(props.defaultValue ? props.defaultValue : [0])

  //on delete doropdown selected
  const handleDelete = (event, sel_id) => {
    const emptyVal = props.enableEmpty ? [] : [0]
    setSelected((current) => {
      let newSelectedVal = _without(current, sel_id)
      let newValue = !newSelectedVal[newSelectedVal.length - 1] ? emptyVal : newSelectedVal
      ref.onChange({ target: { name: props.name, value: newValue } })
      return newValue
    })
  }

  //on chnage dropdpown
  const handleChange = (event) => {
    const value = event.target.value
    if (!value[value.length - 1] || value[value.length - 1] === 0) {
      //set all selected
      props.enableEmpty ? setSelected([]) : setSelected([0])
    } else {
      //set mltiple selected
      setSelected(_without(value, 0))
    }

    //call parent component onchange
    if (props.onChange) {
      props.onChange(value)
    }
  }

  //get render value for multiselect
  const getRenderValue = (selected, menuItems) => {
    ref?.onChange({ target: { name: defaultProps.name, value: selected } })
    return (
      <Box display="flex" flexWrap="wrap" gap={0.2} className="MultiSelect-RenderInputBox">
        {selected.map((sel_id, index) => (
          <Stack key={index} direction="row">
            {sel_id !== 0 ? (
              <Chip
                id={'selected_' + sel_id}
                className="MultiSelect-Chips"
                size="small"
                label={menuItems.find((item) => item.id === sel_id)?.name}
                deleteIcon={
                  <CloseIcon
                    id={'selected_closeBtn' + sel_id}
                    className="MultiSelect-ChipCloseBtn"
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                onDelete={(e) => handleDelete(e, sel_id)}
              />
            ) : (
              !props.enableEmpty && <Label>すべて</Label>
            )}
          </Stack>
        ))}
      </Box>
    )
  }

  return (
    <StyledInputMultiSelect color={color} mode={mode}>
      <Box display="flex">
        <Label {...labelProps} className="InputLabel">
          {label}
        </Label>
        {props.required && <Label>*</Label>}
      </Box>
      <MultiSelect
        {...defaultProps}
        ref={ref}
        value={selected}
        onChange={(e) => handleChange(e)}
        menuItems={menuItems}
        placehoder={placehoder}
        renderValue={(selected) => getRenderValue(selected, menuItems)}
      />
    </StyledInputMultiSelect>
  )
})

InputMultiSelect.displayName = 'Input Multi Select'

InputMultiSelect.defaultProps = {
  color: 'success',
  mode: 'light',
}

InputMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placehoder: PropTypes.string,
  menuItems: PropTypes.array,
  labelProps: PropTypes.object,
  height: PropTypes.number,
  required: PropTypes.bool,
  defaultValue: PropTypes.array,
  color: PropTypes.string,
  mode: PropTypes.string,
  name: PropTypes.string,
  enableEmpty: PropTypes.bool,
}

export default InputMultiSelect
