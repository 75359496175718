import React, { useState } from 'react'
import { Grid, FormHelperText } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { createExpense, readJob } from 'services/jobs'
import { hideNotification } from 'store/notification/actionCreators'
import { Input, Select, Label, LargeButton } from 'components'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

const StyledExpenseForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  backgroundColor: `${theme.palette['background'].paper}`,
  height: 'auto',
  outline: 'none',
  borderRadius: `${theme.standards.border.box}`,
  opacity: 1,
  '& .MuiButton-root.UploadBtn': {
    borderRadius: 5,
    '& .MuiFormControl-root': {
      display: 'none',
    },
  },
}))

const ExpenseForm = (props) => {
  const { data } = props
  const dispatch = useDispatch()
  const [hasFileLabel, setHasFileLabel] = useState('選択されてません')
  const [categoryChange, setCategoryChange] = useState(0)
  const [fileUrl, setFileUrl] = useState('')
  const [fileError, setFileError] = useState(null)
  const { expenseCategoryList } = useSelector((state) => state.common)
  const [item, setItem] = useState('')
  const [price, setPrice] = useState('')
  const [uploading, setUploading] = useState(false)
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
    trigger,
    reset,
    getValues,
    setError,
  } = useForm()

  const handleCreateExpense = (formData) => {
    dispatch(createExpense({ ...formData, file_url: fileUrl }, data.id))
      .then(() => {
        dispatch(readJob(data.id, false))
        reset()
        setUploading(false)
        setCategoryChange(0)
        setValue('expense_category_id', 0)
        setHasFileLabel('選択されてません')
        setItem('')
        setPrice('')
        setFileUrl('')
        unregister('file_url')
      })
      .catch((e) => {
        const { code, error } = e.response.data
        const allFields = Object.keys(getValues()) ?? []
        const allErrors = error ? Object.keys(error) : []
        const hasFieldError = allFields.some((item) => allErrors.includes(item))
        if (code === 422 && hasFieldError) {
          dispatch(hideNotification())
          allErrors.map((key) => {
            if (key == 'file_url') {
              setValue('file_url', '')
              setFileError(error[key][0] || null)
            }
            setError(key, { type: 'custom', message: error[key][0] }, { shouldFocus: true })
          })
        }
        setUploading(false)
      })
    setUploading(true)
  }

  const validationRules = {
    item: {
      required: {
        value: String,
        message: '品目を入力してください。',
      },
      validate: (value) => {
        if (value.trim().length <= 0) {
          return '品目を入力してください。'
        } else if (value.trim().length > 255) {
          return '255文字以内で入力してください。'
        } else {
          return
        }
      },
    },
    price: {
      required: {
        value: Number,
        message: '金額を入力してください。',
      },
      pattern: {
        value: /^[0-9,]*$/, // Regex numbers and commas Validation
        message: '1〜1,000,000の数値を入力してください。',
      },
      validate: (value) => {
        const regex = /^\d{1,3}(,\d{3})*(\.\d+)?$/
        if (value && regex?.test(value)) {
          value = value.replaceAll(',', '')
        }

        if (!(value >= 1 && value <= 1000000)) {
          return '1〜1,000,000の数値を入力してください。'
        }
        return
      },
    },
    expense_category_id: {
      validate: (value) => {
        if (value == undefined || value == 0) {
          return '項目を選択してください。'
        }
      },
    },
    file_url: {
      validate: (value) => {
        if (value !== null && value.length > 0) {
          const filename = value[0].name
          const fileSize = value[0].size / 1024 / 1024 // in MB
          let allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.JPG|\.JPEG|\.png|\.PNG|\.gif|\.GIF)$/i

          if (process.env.REACT_APP_CLAMAV_TEST === 'true') {
            allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.JPG|\.JPEG|\.png|\.PNG|\.gif|\.GIF|\.james|\.JAMES)$/i
          }
          if (!allowedExtensions.exec(filename)) {
            return 'JPG, JPEG, GIF, PNG, PDFいずれかのファイルを選択してください。'
          } else if (fileSize > 4) {
            return 'ファイルサイズは4MB以下にしてください。'
          }
        }
        return true
      },
    },
  }

  const handleUploadFile = (e) => {
    setFileError(null)
    if (e?.target?.files !== null && e.target?.files?.length > 0) {
      setFileUrl(e.target.files[0])
      setHasFileLabel(e.target.files[0].name)
    } else {
      setFileUrl('')
      setHasFileLabel('選択されてません')
    }
  }

  const handleCategoryChange = (id) => {
    setValue('expense_category_id', id)
    setCategoryChange(id)
    if (isSubmitted) {
      trigger('expense_category_id')
    }
  }

  return (
    <StyledExpenseForm item container xs={12}>
      <form onSubmit={handleSubmit(handleCreateExpense)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2.5}>
            <Input
              id="ExpenseItemName"
              name="item"
              ref={register('item', validationRules.item)}
              error={errors && errors.item ? true : false}
              errorMsg={errors ? errors?.item?.message : null}
              type="text"
              placeholder={'例: 高速代○○インター〜○○インター'}
              required={true}
              value={item || ''}
              onChange={(e) => setItem(e?.target?.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Select
              id="ExpenseCategory"
              name="expense_category_id"
              ref={register('expense_category_id', validationRules.expense_category_id)}
              error={errors && errors.expense_category_id ? true : false}
              errormsg={errors ? errors?.expense_category_id?.message : null}
              menuItems={[{ id: '0', name: '' }, ...expenseCategoryList]}
              value={categoryChange || 0}
              onChange={handleCategoryChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} mt={{ xs: 3, sm: 0 }}>
            <Input
              id="ExpensePrice"
              name="price"
              ref={register('price', validationRules.price)}
              error={errors && errors.price ? true : false}
              errorMsg={errors ? errors?.price?.message : null}
              type="text"
              placeholder={'例: 2,100'}
              required={true}
              className="NoLabel"
              label=""
              value={price || ''}
              onChange={(e) => setPrice(e?.target?.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={4} lg={1.75}>
            <LargeButton id="uploadBtn" className="UploadBtn" color="lightGray" mode="dark" component="label">
              {'ファイルを選択'}
              {!uploading && (
                <Input
                  id="ExpenseFile"
                  name="file_url"
                  ref={register('file_url', validationRules.file_url)}
                  type="file"
                  hidden
                  onChange={handleUploadFile}
                />
              )}
            </LargeButton>

            {(errors?.file_url || fileError) && (
              <FormHelperText id={'FormHelperText-file_url-ID'} error>
                {errors?.file_url?.message || fileError}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={3} md={4} lg={1.75}>
            <Label id="fileUploadLabel" pt={1}>
              {hasFileLabel}
            </Label>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2} textAlign={{ sm: 'end' }}>
            <LargeButton id="createExpenseBtn" type="submit" color="success" mode="light" variant="outlined">
              {'登録する'}
            </LargeButton>
          </Grid>
        </Grid>
      </form>
    </StyledExpenseForm>
  )
}

ExpenseForm.propTypes = {
  data: PropTypes.object,
  navigateTo: PropTypes.func,
}

export default ExpenseForm
