import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { getAddress } from 'services/common'
import { useDispatch, useSelector } from 'react-redux'
import { setFormMode, setClientFormData, setConfirming } from 'store/client/actionCreators'
import { useParams, useHistory } from 'react-router-dom'
//componets
import { LargeButton, Heading, InputLabel, InputTextArea, Caption } from 'components'
import { confirmClient, getClient } from 'services/client'
import { getMessages } from 'validation'

const StyledClientCreateUpdateForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& .ProjectList-form': {
    width: '100%',
  },
  '& .inputLabel-Wrapper': {
    '& .MuiBox-root': {
      padding: 0,
    },
  },
  '& .MuiGrid-root.postal-Wrapper': {
    margin: 0,
  },
  '& .download': {
    cursor: 'pointer',
  },
  '& .AddressWrapper .MuiTypography-root': {
    display: 'none',
  },
}))

const ClientCreateUpdateForm = () => {
  const history = useHistory()
  const rulesMsg = getMessages('ja')
  const [formData, setFormData] = useState(null)
  let defaultInputProps = {
    required: true,
  }

  const dispatch = useDispatch()
  const { id } = useParams()

  const [prefecture, setPrefecture] = useState('')
  const [city, setCity] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [requestAddress, setRequestAddress] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  //form data
  const currentformData = useSelector((state) => state.client.formData)

  let defaultFormData =
    currentformData.current && Object.keys(currentformData.current).length > 0
      ? currentformData.current
      : currentformData.original

  //state
  const [isUpdate, setIsUpdate] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors,
  } = useForm()

  //dispatch hooks
  useEffect(() => {
    if (location.pathname.includes('update')) {
      setIsUpdate(true)
      dispatch(setFormMode(true))
    } else {
      setIsUpdate(false)
      dispatch(setFormMode(false))
    }
    if (id && !defaultFormData) {
      dispatch(getClient(id))
    }
  }, [dispatch])

  useEffect(() => {
    if (defaultFormData) {
      setFormData({
        ...defaultFormData,
        name: defaultFormData?.name ?? '',
        postal_code: defaultFormData?.postal_code ?? '',
        prefecture: defaultFormData?.prefecture
          ? typeof defaultFormData?.prefecture === 'string'
            ? defaultFormData?.prefecture
            : defaultFormData?.prefecture?.name
          : '',
        address_line_1: defaultFormData?.address_line_1 ?? '',
        address_line_2: defaultFormData?.address_line_2 ?? '',
        website: defaultFormData?.website ?? '',
        phone_number: defaultFormData?.phone_number ?? '',
        email: defaultFormData?.email ?? '',
        internal_memo: defaultFormData?.internal_memo ?? '',
      })
    }
  }, [dispatch, defaultFormData])

  //formData hooks
  useEffect(() => {
    setValue('name', formData?.name ?? '')
    setValue('postal_code', formData?.postal_code ?? '')
    setValue('prefecture', formData?.prefecture ?? '')
    setValue('city', formData?.city ?? '')
    setValue('address_line_1', formData?.address_line_1 ?? '')
    setValue('address_line_2', formData?.address_line_2 ?? '')
    setValue('website', formData?.website ?? '')
    setValue('phone_number', formData?.phone_number ?? '')
    setValue('email', formData?.email ?? '')
    setValue('internal_memo', formData?.internal_memo ?? '')
    setPrefecture(formData?.prefecture ?? '')
    setAddressLine1(formData?.address_line_1 ?? '')
  }, [formData, defaultFormData])

  const handleInputChange = (name) => {
    if (errors[name]) {
      trigger(name)
    }
  }

  const getInputLabelField = (label, name, placeholder, disabled) => {
    let inputValue = null
    if (name === 'prefecture') {
      inputValue = prefecture
    }
    if (name === 'city') {
      inputValue = city
    }
    if (name === 'address_line_1') {
      inputValue = addressLine1
    }

    if (name === 'name') {
      inputValue = getValues('name')
    }

    if (name === 'postal_code') {
      inputValue = getValues('postal_code')
    }

    if (name === 'address_line_2') {
      inputValue = getValues('address_line_2')
    }

    if (name === 'website') {
      inputValue = getValues('website')
    }

    if (name === 'phone_number') {
      inputValue = getValues('phone_number')
    }

    if (name === 'email') {
      inputValue = getValues('email')
    }

    if (name === 'internal_memo') {
      inputValue = getValues('internal_memo')
    }

    let required = defaultInputProps.required
    if (name === 'address_line_1' || name === 'address_line_2' || name === 'prefecture' || name === 'city') {
      required = false
    }

    return (
      <InputLabel
        id={name + '-ID'}
        className={''}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        isDisabled={disabled || false}
        {...(inputValue && { value: inputValue })}
        {...defaultInputProps}
        required={required || false}
        onChange={name == 'postal_code' ? () => handleChangePostal() : () => handleInputChange(name)}
      />
    )
  }

  const getInputTextAreaField = (label, subLabel, name, placeholder, height, required) => {
    let inputValue = formData ? formData[name] : null
    defaultInputProps['height'] = height
    defaultInputProps['required'] = required
    return (
      <InputTextArea
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        subLabel={subLabel}
        {...(inputValue && { value: inputValue })}
        {...defaultInputProps}
      />
    )
  }

  // handle Submit
  const handleSubmitClientForm = (data) => {
    let newData = {
      ...data,
      id: isUpdate ? id : null,
      name: data?.name.trim(),
      postal_code: data?.postal_code.trim(),
      prefecture: data?.prefecture.trim(),
      city: data?.city.trim(),
      address_line_1: data?.address_line_1.trim(),
      address_line_2: data?.address_line_2.trim(),
      website: data?.website.trim(),
      phone_number: data?.phone_number.trim(),
      email: data?.email.trim(),
      internal_memo: data?.internal_memo.trim(),
    }
    dispatch(confirmClient(newData))
      .then(() => {
        dispatch(setFormMode(isUpdate))
        dispatch(setClientFormData({ current: newData }))
        dispatch(setConfirming(true))
        history.push('/client/confirmation')
      })
      .finally(() => {
        setIsSubmit(false)
      })
  }

  // onClick get address by postal code
  const handleGetAddress = async () => {
    const validPostal = await trigger('postal_code')
    let postalCode = getValues('postal_code')
    if (validPostal) {
      setRequestAddress(true)
      await getAddress(postalCode).then((response) => {
        setRequestAddress(false)
        if (response.data.results == null) {
          setError('postal_code', { type: 'custom', message: '該当する郵便番号が見つかりませんでした。' })
        } else {
          clearErrors('postal_code')
          clearErrors('prefecture')
          clearErrors('city')
          setPrefecture(response.data.results[0]?.address1)
          setCity(response.data.results[0]?.address2)
          setAddressLine1(response.data.results[0]?.address3)
        }
      })
    }
  }

  //onChange postal input value
  const handleChangePostal = () => {
    if (errors.postal_code) {
      trigger('postal_code')
    }
    clearErrors('prefecture')
    clearErrors('city')
    setPrefecture('')
    setCity('')
  }

  //form validation
  const validationRules = {
    name: {
      required: {
        value: String,
        message: rulesMsg.required('法人名を'),
      },
      validate: (value) => {
        const trimmedVal = value.trim()
        if (trimmedVal.length > 255) {
          return rulesMsg.max(255)
        } else if (trimmedVal.length === 0) {
          return rulesMsg.required('法人名を')
        } else {
          return true
        }
      },
    },
    postal_code: {
      required: {
        value: String,
        message: rulesMsg.required('郵便番号を'),
      },
      validate: (value) => {
        const trimmedVal = value.trim()
        if (trimmedVal.length > 7) {
          return rulesMsg.postal_length(7)
        } else if (trimmedVal.length === 0) {
          return rulesMsg.required('郵便番号を')
        } else if (!/^[0-9]*$/.test(trimmedVal)) {
          return rulesMsg.postal_invalid
        } else {
          return true
        }
      },
    },
    prefecture: {
      required: {
        value: String,
        message: rulesMsg.required('都道府県を'),
      },
      validate: (value) => {
        const trimmedVal = value.trim()
        return trimmedVal.length > 0 || rulesMsg.required('都道府県を')
      },
    },
    city: {
      required: {
        value: String,
        message: rulesMsg.required('市町村区を'),
      },
      validate: (value) => {
        const trimmedVal = value.trim()
        return trimmedVal.length > 0 || rulesMsg.required('市町村区を')
      },
    },
    address_line_1: {
      required: {
        value: String,
        message: rulesMsg.required('町名番地を'),
      },
      validate: (value) => {
        const trimmedVal = value.trim()
        if (trimmedVal.length > 255) {
          return rulesMsg.max(255)
        } else if (trimmedVal.length === 0) {
          return rulesMsg.required('町名番地を')
        } else {
          return true
        }
      },
    },
    address_line_2: {
      validate: (value) => {
        const trimmedVal = value.trim()
        return trimmedVal.length <= 255 || rulesMsg.max(255)
      },
    },
    website: {
      required: {
        value: String,
        message: rulesMsg.required('WEBサイトを'),
      },
      validate: (value) => {
        const trimmedVal = value.trim()
        if (trimmedVal.length > 255) {
          return rulesMsg.max(255)
        } else if (trimmedVal.length === 0) {
          return rulesMsg.required('WEBサイトを')
        } else {
          return true
        }
      },
    },
    phone_number: {
      required: {
        value: Number,
        message: rulesMsg.required('電話番号を'),
      },
      validate: (value) => {
        const trimmedVal = value.trim()
        if (trimmedVal.length < 10) {
          return rulesMsg.contact_length
        } else if (trimmedVal.length > 11) {
          return rulesMsg.contact_length
        } else if (trimmedVal.length === 0) {
          return rulesMsg.required('電話番号を')
        } else if (!/^[0-9]*$/.test(trimmedVal)) {
          return rulesMsg.contact_length
        } else {
          return true
        }
      },
      pattern: {
        value: /^(0|[0-9]\d*)(\.\d+)?$/,
        message: rulesMsg.contact_length,
      },
    },
    email: {
      required: {
        value: String,
        message: rulesMsg.required('メールアドレスを'),
      },
      validate: (value) => {
        const trimmedVal = value.trim()
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedVal)) {
          if (/[ァ-ン]|[ｧ-ﾝﾞﾟ]|[０-９]|[Ａ-ｚ]|[ァ-ヶ]/.test(trimmedVal)) {
            return rulesMsg.half_width
          } else if (trimmedVal.length > 300) {
            return rulesMsg.max(300)
          } else if (trimmedVal.length === 0) {
            return rulesMsg.required('メールアドレスを')
          } else {
            return rulesMsg.email_pattern
          }
        } else if (/[ァ-ン]|[ｧ-ﾝﾞﾟ]|[０-９]|[Ａ-ｚ]|[ァ-ヶ]/.test(trimmedVal)) {
          return rulesMsg.half_width
        } else if (trimmedVal.length > 300) {
          return rulesMsg.max(300)
        } else if (trimmedVal.length === 0) {
          return rulesMsg.required('メールアドレスを')
        } else {
          return true
        }
      },
    },
  }

  //formData hooks
  useEffect(() => {
    setPrefecture(formData?.prefecture ?? '')
    setCity(formData?.city ?? '')
    setAddressLine1(formData?.address_line_1 ?? '')
  }, [formData])

  return (
    <StyledClientCreateUpdateForm container>
      <form onSubmit={handleSubmit(handleSubmitClientForm)} className="form">
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Heading>{isUpdate ? 'クライアント編集' : 'クライアント登録'}</Heading>
          </Grid>
        </Grid>
        <Grid container mb={2}>
          <Grid item xs={12}>
            {getInputLabelField('法人名', 'name', '例: ○○株式会社')}
          </Grid>
        </Grid>

        <Grid container columnSpacing={{ xs: 0, md: 4 }} spacing={{ xs: 0, md: 0 }} mb={3} className="webUrl-Wrapper">
          <Grid item container spacing={{ xs: 0, md: 4 }} alignItems="start">
            <Grid item xs={12} md={6}>
              {getInputLabelField('法人住所', 'postal_code', '例: 9820011')}
            </Grid>
            <Grid item container xs={12} md={6} alignItems={'center'} mt={{ xs: 0, md: 3.5 }} pb={{ xs: 3.6, md: 0 }}>
              <LargeButton variant="outlined" color="green" mode="light" onClick={handleGetAddress}>
                {requestAddress && <CircularProgress size="1.5rem" color="inherit" />}
                {!requestAddress && '郵便番号から住所を入力'}
              </LargeButton>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} className="AddressWrapper">
            {getInputLabelField(null, 'prefecture', '都道府県', true)}
          </Grid>
          <Grid item xs={12} md={6} className="AddressWrapper">
            {getInputLabelField(null, 'city', '市町村区', true)}
          </Grid>
          <Grid item xs={12} className="AddressWrapper">
            {getInputLabelField(null, 'address_line_1', '例: 6丁目')}
          </Grid>
          <Grid item xs={12} className="AddressWrapper">
            {getInputLabelField(null, 'address_line_2', '例: 13-2 フリシャー仙台ビル 104')}
          </Grid>
        </Grid>

        <Grid container mb={4}>
          <Grid item xs={12} className="inputLabel-Wrapper">
            {getInputLabelField('WEBサイト', 'website', '例: https://www.sasatsu.co.jp/')}
            <Caption>{'SNSページ（Facebook,Twitterなど）でも可'}</Caption>
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12} className="inputLabel-Wrapper">
            {getInputLabelField('法人代表電話番号', 'phone_number', '例: 0223979860')}
            <Caption>{'担当者電話番号と重複可'}</Caption>
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12} className="inputLabel-Wrapper">
            {getInputLabelField('法人メールアドレス', 'email', '例: taro@example.com')}
            <Caption>{'担当者メールアドレスと重複可'}</Caption>
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12}>
            {getInputTextAreaField('内部メモ', '', 'internal_memo', '', 173)}
          </Grid>
        </Grid>
        <Grid container pt={4} justifyContent={{ xs: 'center', sm: 'end' }}>
          <LargeButton id="WorkerSubmitBtn" type="submit" color="primary">
            {isSubmit && <CircularProgress size="1.5rem" color="white" />}
            {!isSubmit && '確認'}
          </LargeButton>
        </Grid>
      </form>
    </StyledClientCreateUpdateForm>
  )
}

export default ClientCreateUpdateForm
