import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { Label } from 'components'

const FooterLinks = (props) => {
  return (
    <Grid container sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
      {props.footerLinks.map((item, index) => (
        <Grid
          item
          key={index}
          xs={12}
          md={6}
          lg={4}
          sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}
        >
          <Link to={item.link} target={item.target}>
            <Label color="gray2" className="Footer-Label">
              {item.name}
            </Label>
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}

FooterLinks.propTypes = {
  footerLinks: PropTypes.array,
}

export default FooterLinks
