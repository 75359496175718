import React, { Fragment } from 'react'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Heading, SubHeading, Label, P } from 'components'
import { sectionDetails } from '../../../../utils/config/staticContents'

const StyledContent = styled('ol', {
  shouldForwardProp: (prop) => !['isSubcontent'].includes(prop),
})(({ isSubcontent }) => ({
  marginLeft: 25,
  ['li']: {
    listStyleType: 'none',
  },
  ['li::marker']: {
    content: isSubcontent ? `"(" counter(list-item) ") "` : `counter(list-item)". "`,
  },
}))

const StyledSubContent = styled('ol', {
  shouldForwardProp: (prop) => !['isSubcontent'].includes(prop),
})(({ isSubcontent }) => ({
  fontSize: 14,
  marginLeft: 25,
  ['li']: {
    listStyleType: 'none',
  },
  ['li::marker']: {
    content: isSubcontent ? `counter(list-item, upper-alpha) ") "` : `"(" counter(list-item) ") "`,
  },
}))

function TermsConditionContent() {
  return (
    <Fragment>
      {sectionDetails.map(({ id, chapter, info, title, isSubcontent, contentDetails }) => (
        <Fragment key={`${id}-${chapter}`}>
          {chapter && (
            <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
              <Heading id={chapter}>{chapter}</Heading>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
            <SubHeading id={id} color="secondary" bold="true">
              {title}
            </SubHeading>
          </Grid>
          {info && (
            <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
              <Label fontSize={14}>{info}</Label>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
            <StyledContent isSubcontent={isSubcontent}>
              {contentDetails.map(({ content, subContent }) => (
                <li key={`${content}-${id}`}>
                  <P id={id}>{content}</P>
                  {subContent && (
                    <StyledSubContent isSubcontent={isSubcontent}>
                      {subContent.map((item) => (
                        <li key={`${item}-${id}`}>
                          <P>{item}</P>
                        </li>
                      ))}
                    </StyledSubContent>
                  )}
                </li>
              ))}
            </StyledContent>
          </Grid>
        </Fragment>
      ))}
    </Fragment>
  )
}

export default TermsConditionContent
