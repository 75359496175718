import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import ForgotPasswordSuccessIcon from 'assets/svg/ForgotPasswordSuccessIcon.svg'
import EmailPasswordResetIcon from 'assets/svg/EmailPasswordResetIcon.svg'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Label, LargeButton, Icon, SubHeading, Link } from 'components'

const StyledForgotPasswordForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& .ForgotPassword-backLabel': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}))

function ForgotPasswordSuccess(props) {
  const { emailAddress } = props
  const mode = localStorage.getItem('mode')
  const isWorker = mode === 'wkr'
  const isClient = mode === 'client'

  const setConfirmButton = (mode) => {
    switch (mode) {
      case 'opc':
        return (
          <Link to="/login">
            <LargeButton id="ForgotPasswordConfirmBtn" width={280}>
              {'ログイン画面に戻る'}
            </LargeButton>
          </Link>
        )
      case 'wkr':
        return (
          <Link to="/login">
            <Label fontSize={18} color="light_gray" pt={2} className="ForgotPassword-backLabel">
              <KeyboardBackspaceIcon fontSize="small" />
              {'ログインにもどる'}
            </Label>
          </Link>
        )
      default:
        return
    }
  }

  const clientPasswordSuccessContent = (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label>{`${emailAddress} へ承認用メールを送信しました。`}</Label>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Label>{'メールをご確認いただき、記載されたURLをクリックして承認をお願いいたします。 '}</Label>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} display={'inline-flex'} justifyContent={'center'}>
        <Label>
          {`メールが届かない場合、`}
          <Link to="/register">{'こちら'}</Link>
          {`から再度メールアドレスを登録ください。`}
        </Label>
      </Grid>
    </Grid>
  )

  return (
    <StyledForgotPasswordForm
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      py={2}
    >
      <Grid item>
        <Icon source={isWorker ? EmailPasswordResetIcon : ForgotPasswordSuccessIcon} />
        <SubHeading color="secondary" id={'ForgotPassword-Title'} display="block" bold="true" pt={2}>
          {isClient ? 'メールアドレス登録および承認をお願いします' : 'メールをご確認ください。'}
        </SubHeading>
        <Grid pt={2} pb={2}>
          {isClient ? (
            clientPasswordSuccessContent
          ) : (
            <Label>{'入力されたメールアドレスに再設定リンクをお送りしました。'}</Label>
          )}
        </Grid>
      </Grid>
      <Grid item>{setConfirmButton(mode)}</Grid>
    </StyledForgotPasswordForm>
  )
}

ForgotPasswordSuccess.propTypes = {
  mode: PropTypes.string,
  emailAddress: PropTypes.string,
}

export default ForgotPasswordSuccess
