export const SEARCH_WORKERS = 'SEARCH_WORKERS'
export const SET_SEARCH_CRITERIA = 'SET_WORKER_SEARCH_CRITERIA'

export const GET_WORKER_DETAILS_SUCCESS = 'GET_WORKER_DETAILS_SUCCESS'
export const GET_WORKER_DETAILS_FAILED = 'GET_WORKER_DETAILS_FAILED'

export const REGISTER_WORKER_EMAIL_SUCCESS = 'REGISTER_WORKER_EMAIL_SUCCESS'
export const REGISTER_WORKER_EMAIL_FAILED = 'REGISTER_WORKER_EMAIL_FAILED'

export const SET_WORKERS_FORM_DATA = 'SET_WORKERS_FORM_DATA'
export const SET_CONFIRMING_DATA = 'SET_CONFIRMING_DATA'
export const SET_FORM_MODE = 'SET_FORM_MODE'
export const RESET_CURRENT_FORM = 'RESET_CURRENT_FORM'
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS'

export const GET_WORKER_SKILLS_SUCCESS = 'GET_WORKER_SKILLS_SUCCESS'
export const GET_WORKER_SKILLS_FAILED = 'GET_WORKER_SKILLS_FAILED'

export const SAVE_WORKER_INFORMATION_SUCCESS = 'SAVE_WORKER_INFORMATION_SUCCESS'
export const SAVE_WORKER_INFORMATION_FAILED = 'SAVE_WORKER_INFORMATION_FAILED'

export const GET_WORKER_JOB_LIST_REQUEST = 'GET_WORKER_JOB_LIST_REQUEST'
export const GET_WORKER_JOB_LIST_SUCCESS = 'GET_WORKER_JOB_LIST_SUCCESS'
export const GET_WORKER_JOB_LIST_FAILURE = 'GET_WORKER_JOB_LIST_FAILURE'
