import React, { useState, useEffect } from 'react'
import logoIcon from 'assets/svg/logo.svg'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { signOutUser } from 'services/auth'
import { headerItems } from 'utils/config/headerItems'
import { AppBar as MUIAppBar, Box, Toolbar, Container } from '@mui/material'
import { Icon, NavTabs, NavigatorMenu as MobileMenu, ProfileMenu } from 'components'
import standards from 'theme/standards'
import palette from 'theme/palette'
import { useHistory } from 'react-router-dom'
import { thisDay } from 'utils/common'
import { changePasswordRequest } from 'store/auth/actionCreators'
import { resetReducers } from 'store/common/actionCreators'

const StyledHeader = styled(MUIAppBar)(({ theme }) => ({
  backgroundColor: `${theme.palette.background.paper}`,
  color: palette.navigator.default,
  height: standards.header.height,
  opacity: 1,
  boxShadow: 'none',
  position: 'unset',
  justifyContent: 'center',
  paddingRight: 'unset !important',
  '& #logo:hover': {
    cursor: 'pointer',
  },
}))

const getWindowSize = () => {
  const { innerWidth } = window
  return { innerWidth }
}

const PageHeader = () => {
  const auth = useSelector((state) => state.auth)
  const mode = localStorage.getItem('mode')
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const { menuItems, profileItems } = headerItems(mode)
  const { isAuthenticated, user } = auth
  const pathName = location.pathname
  const dispatch = useDispatch()
  const history = useHistory()
  const minimumTabletScreenSize = 900
  const isMobile = windowSize.innerWidth <= minimumTabletScreenSize

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const handleLogoClick = (mode) => {
    switch (mode) {
      case 'opc':
        history.push('/login')
        break
      case 'wkr':
        if (!['/register', '/password-settings'].includes(location.pathname)) {
          if (isAuthenticated) {
            history.push(user?.member_type.includes('ワーカー') ? '/my-page' : '/job/search')
          } else {
            window.location.replace('/home')
          }
        }
        break
      case 'client':
        isAuthenticated ? history.push({ pathname: '/job/list', search: `?date=${thisDay}` }) : history.push('/login')
        break
      default:
        history.push('/login')
        break
    }
  }

  const logo = (
    <Icon id="logo" source={logoIcon} height={40} onClick={() => handleLogoClick(localStorage.getItem('mode'))} />
  )

  const handleOpenNavMenu = ({ currentTarget }) => {
    setAnchorElNav(currentTarget)
  }

  const handleOpenUserMenu = ({ currentTarget }) => {
    setAnchorElUser(currentTarget)
    setMenuOpen((prevOpen) => !prevOpen)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
    dispatch(resetReducers())
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
    setMenuOpen((prevOpen) => !prevOpen)
  }

  const handleCloseProfileMenu = () => {
    setAnchorElUser(null)
    setMenuOpen(false)
    dispatch(changePasswordRequest())
  }

  const handleSignOut = () => {
    dispatch(signOutUser())
  }

  const showContent = ({ isAuthenticated }, pathName) => {
    const hideMenuOnPaths = ['/terms_of_use', '/privacy_policy']
    return isAuthenticated && !hideMenuOnPaths.includes(pathName)
  }

  const mobileMenuContent = (
    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      {showContent(auth, pathName) && menuItems.length > 0 && (
        <MobileMenu
          onNavOpen={handleOpenNavMenu}
          onNavClose={handleCloseNavMenu}
          menuItems={menuItems}
          navAnchor={anchorElNav}
        />
      )}
    </Box>
  )

  return (
    <StyledHeader>
      <Container
        maxWidth="xl"
        sx={mode === 'opc' ? { textAlign: { xs: '-webkit-center', md: 'inherit' } } : { textAlign: 'inherit' }}
      >
        <Toolbar
          disableGutters
          sx={mode === 'opc' ? { writingMode: { xs: 'tb', md: 'inherit' } } : { textAlign: 'inherit' }}
        >
          <Box sx={{ flexGrow: 0.25 }} />
          <Box
            id="desktopLogo"
            sx={
              isAuthenticated
                ? { display: { xs: 'none', md: 'flex' } }
                : { marginLeft: -10, display: { xs: 'none', md: 'flex' } }
            }
          >
            {logo}
          </Box>
          {mode !== 'opc' && mobileMenuContent}
          <Box id="mobileLogo" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            {logo}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'end' } }}>
            {!isMobile && showContent(auth, pathName) && <NavTabs menuItems={menuItems} isMobile={isMobile} />}
          </Box>
          <Box sx={{ flexGrow: 0.25 }}>
            {showContent(auth, pathName) && (
              <ProfileMenu
                onSignOut={handleSignOut}
                onUserOpen={handleOpenUserMenu}
                onUserClose={handleCloseUserMenu}
                onNavClose={handleCloseProfileMenu}
                profileItems={profileItems}
                userAnchor={anchorElUser}
                menuOpen={menuOpen}
              />
            )}
          </Box>
        </Toolbar>
      </Container>
    </StyledHeader>
  )
}
export default PageHeader
