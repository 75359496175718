import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import PropTypes from 'prop-types'
import standards from 'theme/standards'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

function Page({ title, children, ...rest }) {
  const { t } = useTranslation()

  return (
    <div {...rest}>
      <HelmetProvider>
        <Helmet>
          <title>
            {title} | {t('browserTitle.siteTitle')}
          </title>
        </Helmet>
      </HelmetProvider>
      <Grid item container p={standards.padding.page.default} px={1} justifyContent="center" alignItems="center">
        {children}
      </Grid>
    </div>
  )
}

Page.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Page
