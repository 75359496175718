import React, { Fragment, useState } from 'react'
import { Grid, Paper, ClickAwayListener, OutlinedInput } from '@mui/material'
import { LargeButton, Popper, Modal } from 'components'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

const StyledPaper = styled(Paper)(() => {
  return {
    maxHeight: 'fit-content',
    maxWidth: 'inherit',
    padding: 10,
    background: 'white',
    boxShadow: '4px 3px 8px 1px #969696',
  }
})

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => {
  return {
    padding: '0px -2px',
    borderColor: `${theme.standards.border.color}`,
    '& fieldset': {
      border: '1px solid',
    },
  }
})

const StyledArrow = styled('div')(() => ({
  '&#arrow, &#arrow::before': {
    zIndex: -1,
    position: 'absolute',
    width: 15,
    height: 15,
    background: 'inherit',
    boxShadow: '4px 3px 8px 1px #969696',
  },
  '&#arrow': {
    visibility: 'hidden',
  },
  '&#arrow::before': {
    visibility: 'visible',
    content: '""',
    transform: 'rotate(45deg)',
  },
}))

function InputPopper(props) {
  const { open, buttonLabel, anchorEl, hasArrow, clickAway, inputValue, modalContent } = props
  const [arrowRef, setArrowRef] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleCopyURL = () => {
    navigator.clipboard.writeText(inputValue)
    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <Fragment>
      <Popper open={open} anchorEl={anchorEl} arrowRef={arrowRef} hasArrow={hasArrow}>
        <ClickAwayListener onClickAway={() => clickAway()}>
          <StyledPaper>
            <Grid container direction="column" alignItems="flex-start" spacing={2}>
              <Grid item>
                <StyledOutlinedInput value={inputValue} size="small" />
              </Grid>
              <Grid item sx={{ alignSelf: 'end' }}>
                <LargeButton mt={-10} borderRadius={5} onClick={handleCopyURL} color="primary" mode="light">
                  {buttonLabel}
                </LargeButton>
              </Grid>
            </Grid>
          </StyledPaper>
        </ClickAwayListener>
        {hasArrow ? <StyledArrow id="arrow" data-popper-arrow ref={setArrowRef} /> : null}
      </Popper>
      <Modal open={isOpen} size={'sm'} onClose={handleCloseModal}>
        {modalContent}
      </Modal>
    </Fragment>
  )
}

InputPopper.propTypes = {
  open: PropTypes.bool,
  buttonLabel: PropTypes.string,
  hasArrow: PropTypes.bool,
  anchorEl: PropTypes.object,
  clickAway: PropTypes.func,
  inputValue: PropTypes.string,
  modalContent: PropTypes.any,
}

export default InputPopper
