import React, { useEffect, useState } from 'react'
import { Grid, Box, Stack, Divider, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { readWorkerJob } from 'services/jobs'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined'
import GoogleIcon from 'assets/svg/google.svg'
import { getAllExpenseCategories } from 'services/common'
//utils
import { formatWorkerJobDetails, getJobGoogleCalendarDate } from 'utils/helper'
import { tabs, tabIndex } from 'utils/common'
import { status } from 'utils/config/status'

//componets
import {
  Icon,
  Label,
  Heading,
  Select,
  LargeButton,
  Banner,
  Tabs,
  TabTableDisplay,
  WorkerExpenseReportTab,
  WorkerReportFileTab,
  JobOutline,
  Modal,
  WorkerJobCompletion,
  WorkerJobProcessConfirmation,
  BackArrow,
} from 'components'
import { actionSetReportStatus } from 'store/jobs/actionCreators'

//import isEmpty from 'lodash/isEmpty'

const StyledJobDetails = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .DateTimeBanner  .MuiChip-label': {
    height: 'inherit',
    width: '100%',
  },
  '& .GooglCalendarBtn': {
    width: 'fit-content',
    padding: '5px 30px',
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      minWidth: '230px !important',
      marginLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
    },
  },
  '& pre.ReportDescriptionWrapper': {
    whiteSpace: 'pre-wrap',
  },
  '& .MuiButton-root.UploadBtn': {
    '& .MuiFormControl-root': {
      display: 'none !important',
    },
  },
  '& .TabExpense': {
    '& .TabPannelContainer': {
      border: 'none',
    },
    '& .MuiTableContainer-root ': {
      borderTop: 0,
    },
    '& #tabpanel-2 ': {
      border: 0,
    },
  },
}))

const JobDetails = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const reports = Number(params.get('reports'))
  const formData = useSelector((state) => state.jobs.formData)
  const isLoading = useSelector((state) => state.loader)
  const displayData = formData?.formatted
  const [isOpen, setIsOpen] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [action, setAction] = useState('')
  //set tab state
  const [tabContent, setTabContent] = useState()
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    let jobId = props?.match?.params?.id
    if (jobId) {
      dispatch(readWorkerJob(jobId))
    }
    setActiveTab(tabIndex.details)
    dispatch(getAllExpenseCategories())
  }, [])

  useEffect(() => {
    if (reports) {
      setActiveTab(reports)
    }
  }, [reports])

  useEffect(() => {
    if (activeTab === tabIndex.details) {
      setTabContent(JobDetailsTab)
    } else if (activeTab === tabIndex.reports) {
      setTabContent(ReportsFileTab)
    } else {
      setTabContent(ExpenseReportTab)
    }
  }, [activeTab])

  useEffect(() => {
    if (displayData) {
      dispatch(actionSetReportStatus(displayData))
      props.handleData(displayData, activeTab)
    }
  }, [displayData])

  const jobMenuList = [
    { id: 1, name: '前日連絡', disabled: !displayData?.is_previous_contact_enabled },
    { id: 2, name: '出発連絡', disabled: !displayData?.is_departure_contact_enabled },
    { id: 3, name: '現着連絡', disabled: !displayData?.is_arrival_contact_enabled },
    { id: 4, name: '入館連絡', disabled: !displayData?.is_clockin_contact_enabled },
    { id: 5, name: '退館連絡', disabled: !displayData?.is_clockout_contact_enabled },
  ]
  let jobDetailsData = formatWorkerJobDetails(displayData)
  jobDetailsData[0].data = (
    <Box display={{ xs: 'block', md: 'inline-flex' }} alignItems="center">
      {jobDetailsData[0].data}
      <LargeButton
        maxwidth={{ xs: 'fit-content !important', md: 'inherit' }}
        target="_blank"
        component="a"
        href={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${
          displayData?.name
        }&dates=${getJobGoogleCalendarDate(
          displayData?.date,
          displayData?.start_time,
          displayData?.end_time
        )}&location=${displayData?.zip_code ?? ''} ${displayData?.prefecture ?? ''} ${
          displayData?.municipality ?? ''
        } ${displayData?.address_line_1 ?? ''} ${displayData?.address_line_2 ?? ''} `}
        className="GooglCalendarBtn"
        color="success"
        mode="light"
        variant="outlined"
        startIcon={<Icon source={GoogleIcon} />}
      >
        カレンダーに登録する
      </LargeButton>
    </Box>
  )

  jobDetailsData[4].data = displayData?.salary_format + '（税抜き）'

  const onTabChange = (tab) => {
    setActiveTab(tab)
    switch (tab) {
      case tabIndex.reports:
        setTabContent(ReportsFileTab)
        props.handleData(displayData, 1)
        break
      case tabIndex.expense:
        setTabContent(ExpenseReportTab)
        props.handleData(displayData, 2)
        break
      default:
        setTabContent(JobDetailsTab)
        props.handleData([displayData, 0])
        break
    }
  }

  //Tab Contents
  const JobDetailsTab = <TabTableDisplay items={jobDetailsData} />
  const ReportsFileTab = <WorkerReportFileTab data={displayData} />
  const ExpenseReportTab = <WorkerExpenseReportTab data={displayData} navigateTo={onTabChange} />

  useEffect(() => {
    if (activeTab === tabIndex.details) {
      setTabContent(JobDetailsTab)
    } else if (activeTab === tabIndex.reports) {
      setTabContent(ReportsFileTab)
    } else {
      setTabContent(ExpenseReportTab)
    }
  }, [displayData])

  const handleChangeMenuList = (value) => {
    return history.push(`/report/${displayData.id}/${value}`)
  }

  const getDateTimeComponent = (date, time) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem color="gray" sx={{ borderColor: '#f7f0f0a1' }} />}
        height="inherit"
      >
        <Box width={7 / 12} alignItems="center" display="inline-flex">
          <CalendarMonthOutlinedIcon />
          <Label>&nbsp;{date}</Label>
        </Box>
        <Box width={5 / 12} alignItems="center" display="inline-flex" p={1}>
          <ScheduleOutlinedIcon />
          <Label>&nbsp;{time}</Label>
        </Box>
      </Stack>
    )
  }

  const handleResign = () => {
    setAction('resign')
    setIsOpen(true)
    setIsComplete(false)
  }

  const handleCloseCancelModal = () => {
    setIsComplete(false)
    setIsOpen(false)
  }

  const handleCompletion = (completed) => {
    setIsComplete(completed)
    setIsOpen(true)
  }

  const modalContent = isComplete ? (
    <WorkerJobCompletion data={action === 'resign' ? '作業を辞退しました。' : ''} onClose={handleCloseCancelModal} />
  ) : (
    <WorkerJobProcessConfirmation
      data={displayData}
      onClose={handleCloseCancelModal}
      completed={handleCompletion}
      action={action}
    />
  )

  return (
    <StyledJobDetails item container xs={12}>
      {isLoading && (
        <Grid xs={12} item textAlign="center" py={32}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <React.Fragment>
          <BackArrow label="一覧に戻る" path="/job/list" />
          <Grid item xs={12} pt={1}>
            <Heading>作業詳細</Heading>
          </Grid>

          <Grid container py={3} direction="row-reverse">
            <Grid item xs={12} sm={12} md={2}>
              {displayData?.status_id !== status.job.declined && displayData?.status_id !== status.job.canceled && (
                <Select
                  name="jobDetailsMenuList"
                  placeholder="各種報告"
                  menuItems={jobMenuList}
                  value={-1}
                  onChange={handleChangeMenuList}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={10}>
              <JobOutline />
            </Grid>
          </Grid>

          <Grid container py={3} spacing={4}>
            <Grid item xs={12} sm={5} md={4}>
              <Label pb={1}>ステータス</Label>
              <Banner label={displayData?.status_name} variant="outlined" color="success" bold="true" />
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <Label pb={1}>ステータス更新日</Label>
              <Banner
                label={getDateTimeComponent(displayData?.status_updated_date, displayData?.status_updated_time)}
                variant="outlined"
                color="gray"
                bold="true"
                mode="dark"
                className="DateTimeBanner"
              />
            </Grid>
            <Grid item xs={12} className={activeTab === tabIndex.expense ? 'TabExpense' : ''}>
              <Tabs tabs={tabs} value={activeTab} onChange={onTabChange}>
                {tabContent}
              </Tabs>
            </Grid>
            {activeTab === tabIndex.details && parseInt(displayData?.status_id) === status.job.before_job_start && (
              <Grid item container xs={12} gap={2} justifyContent="center">
                <Grid item xs={12} sm={3} lg={2}>
                  <LargeButton id="JobResignBtn" color="gray" mode="btn1" onClick={handleResign}>
                    辞退
                  </LargeButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
      <Modal open={isOpen} title={action === 'resign' ? '辞退' : ''} onClose={handleCloseCancelModal} size="sm">
        {modalContent}
      </Modal>
    </StyledJobDetails>
  )
}

JobDetails.displayName = 'Job Details'

JobDetails.propTypes = {
  match: PropTypes.any,
  handleData: PropTypes.func,
}

export default JobDetails
