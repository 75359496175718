import * as types from './actionTypes'
import { formulateCurrentPage, formulatePageResult } from '../../utils/helper'
const initialState = {
  list: [],
  is_update: false,
  isConfirming: false,
  formErrors: {},
  verifiedItems: {
    responseStatus: 0,
  },
  formData: {
    current: null,
    formatted: null,
    original: null,
  },
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 20,
    prevPageUrl: null,
    nextPageUrl: null,
    pageFrom: 0,
    pageTo: 0,
  },
  search: {
    keyword: '',
    prefecture: '',
    phone_number: '',
    email: '',
    page: 1,
    limit: 20,
    sortBy: 'id',
    sort: 'desc',
  },
  error: {},
  reportStatus: false,
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.CREATE_CLIENT: {
      const responseValue = payload.response.code === 200 ? payload.response.code : 0
      return {
        ...state,
        verifiedItems: {
          responseStatus: responseValue,
        },
      }
    }
    case types.UPDATE_CLIENT: {
      const responseValue = payload.response.code === 200 ? payload.response.code : 0
      return {
        ...state,
        isUpdate: true,
        verifiedItems: {
          responseStatus: responseValue,
        },
      }
    }
    case types.SEARCH_CLIENT: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pageSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }

    case types.SET_SEARCH_CRITERIA: {
      const { keyword, prefecture, phone_number, email, page, sort, sortBy } = payload
      return {
        ...state,
        search: {
          ...state.search,
          keyword,
          prefecture,
          phone_number,
          email,
          page,
          sort,
          sortBy,
        },
      }
    }
    case types.FETCHING_DATA_FAILED:
      return {
        ...state,
        error: payload,
      }
    case types.SET_CLIENT_FORM_DATA:
      return {
        ...state,
        formData: {
          current: {
            ...state.formData?.current,
            ...payload?.current,
          },
          formatted: {
            ...state.formData?.formatted,
            ...payload?.formatted,
          },
          original: {
            ...state.formData?.original,
            ...payload?.original,
          },
        },
      }
    case types.RESET_CURRENT_FORM:
      return {
        ...state,
        formData: {
          ...initialState.formData,
        },
        formErrors: {},
      }
    case types.SET_CONFIRMING_DATA:
      return {
        ...state,
        isConfirming: payload,
      }
    case types.SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: {
          ...payload,
        },
      }
    case types.SET_FORM_MODE:
      return {
        ...state,
        is_update: payload,
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default reducer
