import React from 'react'
import { Box } from '@mui/material'
import { SubHeading, Label } from 'components'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'

const StyledJobOutline = styled(Box)(() => ({
  '& .MuiTypography-title': {
    padding: '0px 0px 14px 0px',
    display: 'block',
  },
}))

function JobOutline() {
  const {
    job_name,
    job_name_with_ID,
    job_group_name,
    client_name,
    job_date_time,
    client_contact_name,
    client_contact_email,
    client_contact_phone_number,
  } = useSelector((state) => state.jobs.jobOutline)

  return (
    <StyledJobOutline>
      <SubHeading color="secondary" bold="true">
        {job_name ?? ''}
      </SubHeading>
      <Label bold="true" pb={0.5}>
        {`作業名: `}
        <Box component="span" fontWeight="normal">
          {job_name_with_ID ?? ''}
        </Box>
      </Label>
      <Label bold="true" pb={0.5}>
        {`作業グループ名: `}
        <Box component="span" fontWeight="normal">
          {job_group_name ?? ''}
        </Box>
      </Label>
      <Label bold="true" pb={0.5}>
        {`クライアント: `}
        <Box component="span" fontWeight="normal">
          {client_name ?? ''}
        </Box>
      </Label>
      <Label pb={0.5}>{job_date_time ?? ''}</Label>
      <Label bold="true" pb={0.5}>
        {`担当: `}
        <Box component="span" fontWeight="normal">
          {client_contact_name ?? ''}
        </Box>
      </Label>
      <Label>{`${client_contact_email ?? ''} ${client_contact_phone_number ?? ''}`}</Label>
    </StyledJobOutline>
  )
}

export default JobOutline
