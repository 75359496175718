import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { LargeButton, InputSelect, TableList, Heading, Icon, InputLabel } from 'components'
import { styled } from '@mui/material/styles'
import * as dayjs from 'dayjs'
import searchIcon from 'assets/svg/search.svg'
//utils
import { formatDropdownInvoiceMonths, getMonths } from 'utils/helper'
import { thisDay, systemInfo } from 'utils/common'
import { getMessages } from 'validation'

const StyledSystemUsageListForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& .MuiBox-root.InputSelect': {
    padding: 0,
  },
  '& tbody tr td:last-of-type': {
    minWidth: '300px !important',
  },
  '& .InvoiceDownloadedBtn': {
    paddingLeft: 4,
  },
}))

const SystemUsageListForm = (props) => {
  const isLoading = useSelector((state) => state.loader)
  //invoice months
  const [invoiceMonthSelectData, setInvoiceMonthSelectData] = useState([])
  const [invoiceValue, setInvoiceValue] = useState('')
  const rulesMsg = getMessages('ja')
  const startMonth = systemInfo.release_date
  const now = thisDay
  const months = getMonths(startMonth, now)
  const invoiceMonthList = months
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  // handle click search btn
  const handleSearch = (data) => {
    data.invoiceMonth = invoiceValue
    props.handlePageSearch(data)
  }

  const validationRules = {
    client: {
      validate: (value) => {
        return value.trim().length <= 255 || rulesMsg.max(255)
      },
    },
  }

  //set default search invoice month
  useEffect(() => {
    const formattedInvoiceData = formatDropdownInvoiceMonths(invoiceMonthList)
    if (formattedInvoiceData.length > 0) {
      setInvoiceMonthSelectData(formatDropdownInvoiceMonths(invoiceMonthList))
    }
  }, [])

  //handle change invoice date
  const onChangeInvoiceDate = (value) => {
    setInvoiceValue(value > 0 ? dayjs(value).format('YYYYMM') : '')
  }

  return (
    <StyledSystemUsageListForm container>
      <Grid item container>
        <Grid item xs={12} sm={12} md={6}>
          <Heading>システム利用料請求一覧</Heading>
        </Grid>
      </Grid>
      <Grid item container pt={6}>
        <form onSubmit={handleSubmit(handleSearch)} className="InvoiceList-form">
          <Grid item container spacing={2} alignItems={'start'}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <InputSelect
                name="invoiceMonth"
                ref={register('invoiceMonth')}
                label="請求月"
                menuItems={[{ id: '0', name: 'すべて' }, ...invoiceMonthSelectData]}
                onChange={onChangeInvoiceDate}
                defaultValue={0}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <InputLabel
                name="client"
                id="client-name-ID"
                ref={register('client', validationRules.client)}
                error={!!(errors && errors?.client)}
                errorMsg={errors ? errors?.client?.message : null}
                label="クライアント名・ID"
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={3}
              lg={2}
              xl={2.4}
              direction={{ xs: 'row', sm: 'column' }}
              justifyContent="center"
              display={{ xs: 'block', sm: 'inline-flex' }}
              mt={3.5}
            >
              <LargeButton
                id="SearchInvoiceBtn-ID"
                type="submit"
                color="warning"
                disabled={isLoading}
                startIcon={<Icon size={'sm'} source={searchIcon} />}
              >
                検索
              </LargeButton>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} pt={3}>
          <TableList
            title={`Invoice List`}
            headCells={props.headerCells}
            data={props.data}
            totalPage={props.totalPage}
            totalCount={props.totalCount}
            handlePageChange={props.handlePageChange}
            isLoading={props.isLoading}
            handleSort={props.handleSort}
            sort={props.sort}
            sortBy={props.sortBy}
            currentPage={props.currentPage}
            pageFrom={props.pageFrom}
            pageTo={props.pageTo}
          />
        </Grid>
      </Grid>
    </StyledSystemUsageListForm>
  )
}

SystemUsageListForm.displayName = 'System Usage List Form'

SystemUsageListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

export default SystemUsageListForm
