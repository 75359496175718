import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useForm } from 'react-hook-form'

//componets
import { LargeButton, Heading, InputLabel, InputAutoComplete, Checkbox, Label, Caption } from 'components'
//validation msg
import { getMessages } from 'validation'
import { useHistory, useParams, useLocation } from 'react-router-dom'
//store
import {
  setFormMode,
  setFormErrors,
  setClientUserFormData,
  resetCurrentForm,
  setConfirming,
} from 'store/client-user/actionCreators'
import { hideNotification } from 'store/notification/actionCreators'
import { getClientUser, confirmClientUser } from 'services/client-user'
import { useSelector, useDispatch } from 'react-redux'
//utils
import { getAllActiveClientList } from 'services/common'
import { formatDropdownData } from 'utils/helper'

const StyledClientCreateUpdateForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .ProjectList-form': {
    width: '100%',
  },
  '& input.Mui-disabled': {
    backgroundColor: `${theme.palette.lightGray.dark}`,
    borderRadius: 5,
  },
  '& .Pic-Wrapper': {
    '& .MuiBox-root': {
      paddingBottom: 0,
    },
  },
  '& .MuiGrid-root.postal-Wrapper': {
    margin: 0,
  },
  '& .download': {
    cursor: 'pointer',
  },
}))

const ClientUserCreateUpdateForm = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const rulesMsg = getMessages('ja')
  const history = useHistory()
  const { id } = useParams()
  const [accVerified, setAccVerified] = useState(1)
  const [isSubmit, setIsSubmit] = useState(false)
  const [formData, setFormData] = useState(null)
  const [selectedClientValue, setSelectedClientValue] = useState()

  //update state
  const [isUpdate, setIsUpdate] = useState(false)

  //form data
  const formErrors = useSelector((state) => state.clientUsers.formErrors)
  const currentformData = useSelector((state) => state.clientUsers.formData)
  let defaultFormData =
    currentformData.current && Object.keys(currentformData.current).length > 0
      ? currentformData.current
      : currentformData.original
  const clientList = useSelector((state) => state.common.clientList)
  const formattedClientData = formatDropdownData(clientList, 'name')

  let defaultInputProps = {
    required: true,
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm()

  const getInputLabelField = (label, name, placeholder, disabled) => {
    let inputValue = formData ? formData[name] : null
    const required = true

    return (
      <InputLabel
        id={name + '-ID'}
        className={name === 'display_name' ? 'NoPad' : ''}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        isDisabled={disabled || false}
        {...(inputValue && { value: inputValue })}
        {...defaultInputProps}
        required={required || false}
      />
    )
  }

  //get All Active Client List
  useEffect(() => {
    dispatch(getAllActiveClientList())
  }, [])

  //formErrors hooks
  useEffect(() => {
    Object.keys(formErrors).map((key) => {
      setError(key, { type: 'custom', message: formErrors[key][0] }, { shouldFocus: true })
    })
  }, [formErrors])

  // handle Submit
  const handleSubmitClientUserForm = (data) => {
    setIsSubmit(false)
    let newData = {
      ...data,
      id: isUpdate ? id : null,
      client_id: data?.client?.id,
    }

    dispatch(confirmClientUser(newData, isUpdate ? id : null))
      .then(() => {
        dispatch(setFormMode(isUpdate))
        dispatch(setClientUserFormData({ current: newData }))
        dispatch(setConfirming(true))
        dispatch(setFormErrors(null))
        history.push('/client-user/confirmation')
      })
      .catch((e) => {
        const { code, error } = e.response.data
        const allFields = Object.keys(newData)
        const allErrors = error ? Object.keys(error) : []
        const hasFieldError = allFields.some((item) => allErrors.includes(item))
        if (code === 422 && hasFieldError) {
          dispatch(hideNotification())
          dispatch(setFormErrors(error))
        }
      })
      .finally(() => {
        setIsSubmit(false)
      })
  }

  //set form data base on action type
  useEffect(() => {
    if (location?.pathname.includes('update')) {
      setIsUpdate(true)
      dispatch(setFormMode(true))
    } else {
      setIsUpdate(false)
      dispatch(setFormMode(false))
    }
    if (id) {
      dispatch(getClientUser(id))
    }
  }, [dispatch])

  //set form data state
  useEffect(() => {
    setFormData({
      ...defaultFormData,
      name: defaultFormData?.name ? defaultFormData?.name : defaultFormData?.user?.name,
      email: defaultFormData?.email ? defaultFormData?.email : defaultFormData?.user?.email_address,
    })
  }, [dispatch, defaultFormData])

  //set selected value
  useEffect(() => {
    if (formData) {
      setSelectedClientValue(formData?.client || null)
      if (isUpdate) {
        setAccVerified(formData?.account_status ? 1 : 0)
      }
    }
  }, [formData])

  const handleSetClient = (value) => {
    setSelectedClientValue(value)
  }

  const handleBackButton = () => {
    dispatch(resetCurrentForm())
    history.goBack()
  }

  const onChangeAccVerified = () => {
    setAccVerified(!accVerified)
  }

  //form validation
  const validationRules = {
    client: {
      required: {
        value: Object,
        message: rulesMsg.required_select('クライアントを'),
      },
    },
    name: {
      required: {
        value: String,
        message: rulesMsg.required('担当者名を'),
      },
      validate: (value) => {
        if (value.trim().length > 30) {
          return rulesMsg.max(30)
        } else {
          return
        }
      },
    },
    phone_number: {
      required: {
        value: Number,
        message: rulesMsg.required('担当者電話番号を'),
      },
      validate: (value) => {
        if (value.length < 10) {
          return rulesMsg.max_digits([10, 11])
        } else if (value.length > 11) {
          return rulesMsg.max_digits([10, 11])
        } else if (!/^[0-9]*$/.test(value)) {
          return rulesMsg.max_digits([10, 11])
        } else {
          return true
        }
      },
      pattern: {
        value: /^(0|[0-9]\d*)(\.\d+)?$/,
        message: rulesMsg.contact_length,
      },
    },
    email: {
      required: {
        value: String,
        message: rulesMsg.email_credential,
      },
      validate: (value) => {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return rulesMsg.email_pattern
        } else if (/[ァ-ン]|[ｧ-ﾝﾞﾟ]|[０-９]|[Ａ-ｚ]|[ァ-ヶ]/.test(value)) {
          return rulesMsg.half_width
        } else if (value.length > 300) {
          return rulesMsg.max(300)
        } else {
          return true
        }
      },
    },
  }

  return (
    <StyledClientCreateUpdateForm container>
      <form onSubmit={handleSubmit(handleSubmitClientUserForm)} className="form">
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Heading>{isUpdate ? 'クライアント担当者編集' : 'クライアント担当者登録'}</Heading>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <InputAutoComplete
              name="client"
              ref={register('client', validationRules.client)}
              error={errors && errors?.client ? true : false}
              errorMsg={errors?.client?.message}
              id="client"
              placeholder="クライアント"
              label="クライアント"
              options={formattedClientData}
              defaultinput={selectedClientValue}
              itemSelect={(data) => handleSetClient(data)}
              {...defaultInputProps}
            />
          </Grid>
          <Grid item xs={12} className="Pic-Wrapper" mb={3}>
            {getInputLabelField('担当者名', 'name', '例: 佐々通　太郎')}
            <Caption color="primary">姓と名の間にスペースを入れてください</Caption>
          </Grid>
          <Grid item xs={12}>
            {getInputLabelField('担当者電話番号', 'phone_number', '例: 09012345678')}
          </Grid>
          <Grid item xs={12}>
            {getInputLabelField('担当者メールアドレス', 'email', 'taro@example.com')}
          </Grid>
          <Grid item container justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              <Label>アカウント状態</Label>
            </Grid>

            <Grid item>
              <Checkbox
                id={'acc_verified-ID'}
                ref={register('account_status')}
                name={'account_status'}
                onChange={onChangeAccVerified}
                checked={accVerified === 1 ? true : false}
                disabled={!isUpdate ? true : false}
                label={''}
                pl={3}
              />
            </Grid>
            <Grid item>
              <Label>有効</Label>
            </Grid>
          </Grid>
        </Grid>
        <Grid container pt={4} justifyContent="flex-end" alignItems="flex-end">
          <Grid item mr={2}>
            <LargeButton id="WorkerSubmitBtn" color="gray" mode="btn1" onClick={handleBackButton}>
              {'戻る'}
            </LargeButton>
          </Grid>
          <Grid item>
            <LargeButton id="WorkerSubmitBtn" type="submit" color="primary">
              {isSubmit && <CircularProgress size="1.5rem" color="white" />}
              {!isSubmit && '確認'}
            </LargeButton>
          </Grid>
        </Grid>
      </form>
    </StyledClientCreateUpdateForm>
  )
}

export default ClientUserCreateUpdateForm
