import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import { formatJobGroupForm, formatJobGroupDisplay } from 'utils/helper'
import {
  actionSearchClientJobGroups,
  actionSearchJobGroups,
  actionSetSearchCriteria,
  setJobGroupFormData,
} from 'store/job-groups/actionCreators'
import { actionSearchWorkJobGroups, resetSearch } from 'store/job-seeker/actionCreators'

export function searchJobGroups(params) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('op-center/job-groups', { params })
      .then((response) => {
        dispatch(actionSearchJobGroups(response.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function searchClientJobGroups(jobGroupQuery) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('client/job-groups', {
      params: {
        ...jobGroupQuery,
      },
    })
      .then((response) => {
        dispatch(actionSearchClientJobGroups(response.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function searchWorkJobGroups(searchParams) {
  return (dispatch) => {
    dispatch(showLoader())
    parseInt(searchParams?.page) <= 1 && dispatch(resetSearch())
    const newParams = {
      ...searchParams,
      prefecture: searchParams?.prefecture > 0 ? searchParams?.prefecture : '',
    }
    return Http.get('op-center/job-groups', {
      params: newParams,
    })
      .then((response) => {
        dispatch(actionSearchWorkJobGroups(response.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function readWorkerJobGroup(jobGroupId) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('worker/job-groups/' + jobGroupId)
      .then((response) => {
        let response_data = {}
        response_data['formatted'] = response.data.data
        dispatch(setJobGroupFormData(response_data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function applyJobGroups(id, workerId) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('worker/job-groups/apply', { job_group_id: id, worker_id: workerId })
      .then((response) => {
        let response_data = {}
        response_data['formatted'] = {}
        response_data['original'] = response.data.data
        dispatch(setJobGroupFormData(response_data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function changeSearchCriteria(searchData, page, limit, sort, sortBy) {
  return (dispatch) => {
    dispatch(actionSetSearchCriteria(searchData, page, limit, sort, sortBy))
  }
}

export function createJobGroup(data, currentData) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('op-center/job-groups/confirm', data)
      .then((response) => {
        let response_data = response.data.data
        response_data['current'] = currentData
        dispatch(setJobGroupFormData(response_data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function confirmJobGroup(data) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('op-center/job-groups', data)
      .then((response) => {
        let response_data = {}
        response_data['current'] = {}
        response_data['original'] = formatJobGroupForm(response.data.data)
        dispatch(setJobGroupFormData(response_data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function updateConfirmJobGroup(data, id) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.put(`op-center/job-groups/${id}`, data)
      .then((response) => {
        let response_data = {}
        response_data['current'] = {}
        response_data['original'] = formatJobGroupForm(response.data.data)
        dispatch(setJobGroupFormData(response_data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function readJobGroup(jobGroupId) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('op-center/job-groups/' + jobGroupId)
      .then((response) => {
        let response_data = {}
        response_data['original'] = formatJobGroupForm(response.data.data)
        response_data['formatted'] = formatJobGroupDisplay(response.data.data)
        dispatch(setJobGroupFormData(response_data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function getProject(project_id) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('op-center/projects/' + project_id).finally(() => {
      dispatch(hideLoader())
    })
  }
}

export function cancelJobGroup(jobGroupId) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.put(`op-center/job-groups/cancel/${jobGroupId}`).finally(() => {
      dispatch(hideLoader())
    })
  }
}
