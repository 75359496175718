import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Link, useHistory } from 'react-router-dom'
import Layout from 'layouts/main'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useSelector, useDispatch } from 'react-redux'
import { changeSearchCriteria as searchJobs } from 'services/jobs'
import { member_type } from 'utils/config/status'
import * as dayjs from 'dayjs'

const PREFIX = 'NotFound'

const classes = {
  contentBox: `${PREFIX}-contentBox`,
  heading: `${PREFIX}-heading`,
  link: `${PREFIX}-link`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.contentBox}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    flexDirection: 'column',
    fontFamily: 'Roboto',
  },

  [`& .${classes.heading}`]: {
    fontSize: '10em',
  },

  [`& .${classes.link}`]: {
    fontWeight: '700',
    paddingTop: theme.spacing(5),
  },
}))

const NotFound = () => {
  const auth = useSelector((state) => state.auth)
  const authMember = useSelector((state) => state.auth.user)
  const mode = localStorage.getItem('mode')
  const dispatch = useDispatch()
  const searchParameters = useSelector((state) => state.jobs.search)
  const now = dayjs().format('YYYY/MM/DD')
  const [clickStarted, setClickStarted] = useState(false)
  const history = useHistory()
  const jobHandlerLink = () => {
    dispatch(searchJobs({ ...searchParameters, start_date: now, end_date: now }))
  }

  useEffect(() => {
    if (clickStarted) {
      if (auth.isAuthenticated) {
        switch (mode) {
          case 'wkr':
            if (authMember?.member_type?.length > 0) {
              authMember?.member_type === member_type.worker ? history.push('/my-page') : history.push('/job/search')
            }
            break
          case 'client':
            jobHandlerLink()
            history.push('/job/list')
            break
          case 'opc':
            history.push('/project_list')
            break
          default:
            history.push('/')
            break
        }
      } else {
        window.location.replace('/home')
      }
    }
  }, [clickStarted])

  return (
    <Root>
      <Layout>
        <div className={classes.contentBox}>
          <h1 className={classes.heading}>404</h1>
          <p>ページがみつかりませんでした。</p>
          <Link
            to={'#'}
            className={classes.link}
            onClick={() => {
              setClickStarted(true)
            }}
          >
            &#62;&#62; ホームに戻る
          </Link>
        </div>

        <HelmetProvider>
          <Helmet>
            <title>404 Not Found - {process.env.REACT_APP_SITE_TITLE}</title>
          </Helmet>
        </HelmetProvider>
      </Layout>
    </Root>
  )
}

export default NotFound
