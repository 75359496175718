import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Label, P, LargeButton } from 'components'
import { useDispatch } from 'react-redux'
import { readJobGroup } from 'services/jobGroups'

const StyledForm = styled('form')(() => ({
  display: 'contents',
}))

function JobGroupCancelSuccess(props) {
  const { onClose, jobGroupData } = props
  const isClient = localStorage.getItem('mode').includes('client')
  const dispatch = useDispatch()

  const handleUpdateDetails = () => {
    dispatch(readJobGroup(jobGroupData?.id))
    onClose()
  }

  const clientDetail = (
    <Fragment>
      <Grid item xs={5.25} textAlign="end">
        <Label color="gray">{'クライアント:'}</Label>
      </Grid>
      <Grid item xs={6.75} pl={1}>
        <P>{jobGroupData?.client_alias}</P>
      </Grid>
    </Fragment>
  )

  return (
    <Grid container rowSpacing={6} justifyContent="center">
      <Grid item pb={5} xs={12} md={12} lg={12} textAlign="center">
        <Label>{'作業グループをキャンセルしました。'}</Label>
      </Grid>
      <StyledForm className="JobGroupCancelSuccess">
        {!isClient && clientDetail}
        <Grid item xs={5.25} textAlign="end">
          <Label color="gray">{'作業グループ名:'}</Label>
        </Grid>
        <Grid item xs={6.75} pl={1}>
          <P>{jobGroupData?.alias}</P>
        </Grid>
      </StyledForm>
      <Grid textAlign="center" item xs={7} sm={6} md={5}>
        <LargeButton id="jobGroupModalClose" onClick={handleUpdateDetails} variant="contained">
          {'はい'}
        </LargeButton>
      </Grid>
    </Grid>
  )
}

JobGroupCancelSuccess.propTypes = {
  onClose: PropTypes.func,
  jobGroupData: PropTypes.object,
}

export default JobGroupCancelSuccess
