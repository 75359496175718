import * as types from './actionTypes'
import { formulateCurrentPage, formulatePageResult } from '../../utils/helper'
import { OPCApplicationListDefaultStatusId, OPCApplicationListJobGroupDefaultStatusId } from 'utils/config/status'
import { status, applicationListDefaultStatusId } from 'utils/config/status'

const initialState = {
  list: [],
  formData: {
    current: null,
    formatted: null,
    original: null,
  },
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 20,
    prevPageUrl: null,
    nextPageUrl: null,
    pageFrom: 0,
    pageTo: 0,
  },
  OPCSearch: {
    mode: 'opc',
    worker: '',
    job_group: '',
    project: '',
    job_group_status: OPCApplicationListJobGroupDefaultStatusId ?? [],
    client: '',
    application_status: OPCApplicationListDefaultStatusId ?? [],
    start_date: '',
    end_date: '',
    page: 1,
    limit: 20,
    sortBy: 'id',
    sort: 'desc',
  },
  search: {
    mode: 'client',
    worker: '',
    job_group: '',
    project: '',
    job_group_status: applicationListDefaultStatusId.join() ?? '',
    application_status: Object.values(status.application).join() ?? '',
    start_date: '',
    end_date: '',
    page: 1,
    limit: 20,
    sortBy: 'id',
    sort: 'desc',
  },
  error: {},
  reportStatus: false,
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.OPC_SEARCH_APPLICATION: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pageSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        OPCSearch: {
          ...state.OPCSearch,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }

    case types.SEARCH_APPLICATION: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pageSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }

    case types.OPC_SET_SEARCH_CRITERIA: {
      const {
        mode,
        worker,
        project,
        job_group,
        job_group_status,
        client,
        application_status,
        start_date,
        end_date,
        sortBy,
        sort,
        page,
        limit,
      } = payload
      return {
        ...state,
        OPCSearch: {
          ...state.OPCSearch,
          mode,
          worker,
          project,
          job_group,
          job_group_status,
          client,
          application_status,
          start_date,
          end_date,
          sortBy,
          sort,
          page,
          limit,
        },
      }
    }
    case types.SET_SEARCH_CRITERIA: {
      const { application_status, job_group_status } = payload
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
          application_status: typeof application_status === 'object' ? application_status.join() : application_status,
          job_group_status: typeof job_group_status === 'object' ? job_group_status.join() : job_group_status,
        },
      }
    }
    case types.FETCHING_DATA_FAILED:
      return {
        ...state,
        error: payload,
      }
    case types.SET_APPLICATION_FORM_DATA:
      return {
        ...state,
        formData: {
          current: {
            ...state.formData?.current,
            ...payload?.current,
          },
          formatted: {
            ...state.formData?.formatted,
            ...payload?.formatted,
          },
          original: {
            ...state.formData?.original,
            ...payload?.original,
          },
        },
      }
    case types.RESET_OPC_SEARCH_PARAMS: {
      return {
        ...state,
        OPCSearch: {
          ...initialState.OPCSearch,
        },
      }
    }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default reducer
