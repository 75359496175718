import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Label, P, LargeButton } from 'components'
import { useDispatch } from 'react-redux'
import { cancelJobGroup } from 'services/jobGroups'

const StyledForm = styled('form')(() => ({
  display: 'contents',
}))

function JobGroupCancel(props) {
  const { onClose, jobGroupData, jobGroupCanceled } = props
  const dispatch = useDispatch()
  const isClient = localStorage.getItem('mode').includes('client')

  const handleCancelJobGroup = () => {
    dispatch(cancelJobGroup(jobGroupData?.id))
    jobGroupCanceled('success')
  }

  const clientDetails = (
    <Fragment>
      <Grid item xs={5.25} textAlign="end">
        <Label color="gray">{'クライアント:'}</Label>
      </Grid>
      <Grid item xs={6.75} pl={1}>
        <P>{`${jobGroupData?.client_alias}`}</P>
      </Grid>
    </Fragment>
  )

  return (
    <Grid container rowSpacing={6} justifyContent="center">
      <Grid item pb={5} xs={12} md={12} lg={12} textAlign="center">
        <Label>{'この作業グループをキャンセルしますか?'}</Label>
      </Grid>
      <StyledForm className="JobGroupCancel">
        {!isClient && clientDetails}
        <Grid item xs={5.25} textAlign="end">
          <Label color="gray">{'作業グループ名:'}</Label>
        </Grid>
        <Grid item xs={6.75} pl={1}>
          <P>{`${jobGroupData?.alias}`}</P>
        </Grid>
        <Grid item xs={5.25} textAlign="end">
          <Label color="gray">{'作業グループ開始日:'}</Label>
        </Grid>
        <Grid item xs={6.75} pl={1}>
          <P>{jobGroupData?.start_date}</P>
        </Grid>
        <Grid item xs={5.25} textAlign="end">
          <Label color="gray">{'作業グループ終了日:'}</Label>
        </Grid>
        <Grid item xs={6.75} pl={1}>
          <P>{jobGroupData?.end_date}</P>
        </Grid>
      </StyledForm>
      <Grid item container justifyContent="center" gap={2}>
        <LargeButton id="jobGroupModalClose" onClick={() => onClose()} color="gray" variant="contained">
          {'いいえ'}
        </LargeButton>
        <LargeButton id="jobGroupModalCancel" onClick={handleCancelJobGroup} variant="contained">
          {'はい'}
        </LargeButton>
      </Grid>
    </Grid>
  )
}

JobGroupCancel.propTypes = {
  onClose: PropTypes.func,
  jobGroupCanceled: PropTypes.func,
  jobGroupData: PropTypes.object,
}

export default JobGroupCancel
