import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import omit from 'lodash/omit'
import { styled } from '@mui/material/styles'
//componets
import { Label, Select } from 'components'

const StyledInputSelect = styled(Box)(({ theme }) => ({
  padding: `${theme.standards.padding.input.default}`,
  '& .InputLabel': {
    paddingBottom: `${theme.standards.padding.label.inputLabel}`,
  },
  '& .RequiredText': {
    display: 'inline-flex',
  },
}))

const InputSelect = React.forwardRef((props, ref) => {
  const excludedProps = ['label', 'type', 'labelProps', 'required', 'sideLabel']
  let defaultProps = omit(props, excludedProps)
  const { label, menuItems, labelProps, sideLabel } = props

  const labelDisplay = (
    <Label {...labelProps} className="InputLabel">
      {label}
      {props.required && (
        <Label color="error" className="RequiredText">
          *
        </Label>
      )}
    </Label>
  )

  const topLabelDisplay = (
    <Box>
      {labelDisplay}
      <Select {...defaultProps} ref={ref} menuItems={menuItems} />
    </Box>
  )
  const sideLabelDisplay = (
    <Box>
      <Box display="inline-grid" width={{ xs: 1, sm: 5 / 12, md: 4 / 12 }}>
        {labelDisplay}
      </Box>
      <Box display="inline-grid" width={{ xs: 1, sm: 7 / 12, md: 8 / 12 }}>
        <Select {...defaultProps} ref={ref} menuItems={menuItems} />
      </Box>
    </Box>
  )

  return <StyledInputSelect className="InputSelect">{sideLabel ? sideLabelDisplay : topLabelDisplay}</StyledInputSelect>
})

InputSelect.displayName = 'Input Select'

InputSelect.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  menuItems: PropTypes.array,
  labelProps: PropTypes.object,
  sideLabel: PropTypes.bool,
}

export default InputSelect
