import React, { useEffect } from 'react'
import { Router as ReactRouter, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { opCenterRoutes, clientRoutes, workerRoutes, commonRoutes } from './routes'
import { useDispatch } from 'react-redux'

import Public from './Public'
import Private from './Private'
import NotFound from '../views/NotFound'
import { actionAddLocation } from 'store/common/actionCreators'

const history = createBrowserHistory()
function Router() {
  const dispatch = useDispatch()
  let userRoutes = []

  switch (window.location.hostname) {
    case process.env.REACT_APP_CLIENT_DOMAIN: {
      userRoutes = clientRoutes
      localStorage.setItem('mode', 'client')
      break
    }
    case process.env.REACT_APP_DOMAIN: {
      userRoutes = workerRoutes
      localStorage.setItem('mode', 'wkr')
      break
    }
    default: {
      userRoutes = opCenterRoutes
      localStorage.setItem('mode', 'opc')
      break
    }
  }

  useEffect(() => {
    dispatch(actionAddLocation(history.location))
    return history.listen((location) => {
      dispatch(actionAddLocation(location))
    })
  }, [])

  return (
    <ReactRouter history={history}>
      <Switch>
        {[...userRoutes, ...commonRoutes].map((route, i) => {
          if (route.auth) {
            return <Private exact key={i} {...route} mode={localStorage.getItem('mode')} />
          }
          return <Public exact key={i} {...route} mode={localStorage.getItem('mode')} />
        })}
        <Route component={NotFound} />
      </Switch>
    </ReactRouter>
  )
}

export default Router
