import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import TextField, { textFieldClasses } from '@mui/material/TextField'
import omit from 'lodash/omit'
import { styled } from '@mui/material/styles'
import standards from 'theme/standards'

const StyledAutoComplete = styled(Autocomplete, {
  shouldForwardProp: (prop) => !['isInit', 'isHideClearBtn'].includes(prop),
})(({ width, height, fontSize, disabled, theme }) => ({
  padding: 0,
  width: width,
  height: height,
  display: 'inline-flex',
  [`& .${textFieldClasses.root}`]: {
    padding: 0,
  },
  [`& .${autocompleteClasses.inputRoot}`]: {
    padding: 0,
  },
  '& input': {
    width: '100% !important',
    height: height,
    boxSizing: 'border-box',
    fontSize: fontSize,
    borderColor: '#D9D9D9 !important',
    '&.Mui-disabled': {
      background: `${disabled ? theme.palette.disabled : ''}`,
    },
  },
  '& fieldset': {
    border: '1px solid ',
    borderRadius: 5,
    borderColor: '#D9D9D9 !important',
  },
  '&:hover fieldset': {
    borderColor: '#D9D9D9 !important',
  },
  '& .Mui-error': {
    marginLeft: 0,
    '& fieldset': {
      borderColor: `${theme.palette['error'].main}`,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette['error'].main}`,
    },
  },
}))

const AutoComplete = React.forwardRef((props, ref) => {
  const [item, setItem] = useState(null)
  const excludedProps = ['onChange', 'errorMsg', 'required', 'error', 'itemSelect']
  let defaultProps = omit(props, excludedProps)
  const { name, placeholder, itemSelect, errorMsg, error, defaultinput, options, isInit, isHideClearBtn } = props

  const handleItem = (selectedItem) => {
    itemSelect(selectedItem)
    setItem(selectedItem)
  }

  isInit
    ? useEffect(() => {
        setItem(Object.keys(defaultinput).length > 0 ? defaultinput : {})
      }, [])
    : useEffect(() => {
        setItem(Object.keys(defaultinput ? defaultinput : {}).length > 0 ? defaultinput : null)
      }, [options, defaultinput])

  return (
    <StyledAutoComplete
      {...defaultProps}
      id={defaultProps?.id || `${name}-modal`}
      noOptionsText={' '}
      isOptionEqualToValue={(option, value) => option?.id === value?.id || {}}
      getOptionLabel={(option) => option.name || ''}
      value={item}
      disableClearable={isHideClearBtn}
      onChange={(e, item) => {
        itemSelect ? handleItem(item) : setItem(item)
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )
      }}
      renderInput={(params) => {
        ref.onChange({ target: { name: name, value: item } })
        return <TextField {...params} {...ref} placeholder={placeholder} helperText={errorMsg || ''} error={error} />
      }}
    />
  )
})

AutoComplete.displayName = 'AutoComplete'

AutoComplete.defaultProps = {
  height: standards.inputHeight.md,
  width: '100% !important',
  fontSize: standards.fontSize.default,
}

AutoComplete.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  itemSelect: PropTypes.func,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  defaultinput: PropTypes.any,
  options: PropTypes.array,
  isInit: PropTypes.bool,
  isHideClearBtn: PropTypes.bool,
}

export default AutoComplete
