import * as types from './actionTypes'
import { formulateCurrentPage, formulatePageResult } from '../../utils/helper'

const initialState = {
  invoiceMonths: [],
  list: [],
  formData: {
    current: null,
    formatted: null,
    original: null,
  },
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 20,
    prevPageUrl: null,
    nextPageUrl: null,
    pageFrom: 0,
    pageTo: 0,
  },
  search: {
    invoiceMonth: '',
    limit: 20,
    page: 1,
    sort: 'asc',
    sortBy: 'client_name',
  },
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.GET_WORKER_MONTHS:
      return {
        ...state,
        invoiceMonths: payload.data,
      }
    case types.SET_DEFAULT_INVOICE_MONTH:
      return {
        ...state,
        search: {
          ...state.search,
          invoiceMonth: payload,
        },
      }

    case types.SEARCH_INVOICE: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )
      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }
    case types.SET_SEARCH_CRITERIA:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      }
    case types.SET_INVOICE_FORM_DATA:
      return {
        ...state,
        formData: {
          original: {
            ...state.formData?.original,
            ...payload?.original,
          },
        },
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default reducer
