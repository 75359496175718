import React, { useState, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import palette from 'theme/palette'
import { Grid, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getAddress } from 'services/common'
import { emailAddressValidation, fullNameSpaceValidation } from 'utils/helper'
import { Heading, SubHeading, InputLabel, LargeButton } from 'components'
import { confirmClientUser, validateToken } from 'services/users'
import { actionRegisterUser } from 'store/users/actionCreators'
import { useHistory } from 'react-router-dom'
import { getMessages } from 'validation'

const StyledClientRegistrationForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& p.MuiFormHelperText-root': {
    color: palette.text.light_gray,
  },
}))

function ClientRegistrationForm() {
  const [prefecture, setPrefecture] = useState('')
  const [city, setCity] = useState('')
  const [street, setStreet] = useState('')
  const [addressError, setAddressError] = useState()
  const [loader, setLoader] = useState(false)
  const { tokenData, userData, confirmed, error } = useSelector((state) => state.users)
  const url = new URL(location.href)
  const token = url.searchParams.get('token')
  const history = useHistory()
  const dispatch = useDispatch()
  const rulesMsg = getMessages('ja')
  const serverError = error ? error[0]?.data?.error : {}

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    getValues,
    watch,
    clearErrors,
    setError,
    setFocus,
  } = useForm()

  useEffect(() => {
    if (token) {
      const data = { validationType: 'activation', token: token }
      dispatch(validateToken(data))
    } else {
      history.push('/login')
    }
  }, [])

  useEffect(() => {
    confirmed && history.push('/register/verify')
  }, [confirmed])

  useEffect(() => {
    error &&
      Object.keys(serverError).forEach((error) => {
        setError(error, { type: 'serverError', message: serverError[error][0] })
      })
  }, [error])

  useEffect(() => {
    error && setFocus(Object.keys(serverError)[0], { shouldSelect: true })
  }, [setFocus, error])

  let setPassword = watch('password')

  const validationRules = {
    full_name: {
      required: {
        value: String,
        message: rulesMsg.name_invalid,
      },
      maxLength: {
        value: 30,
        message: rulesMsg.max(30),
      },
      validate: (value) => {
        if (fullNameSpaceValidation(value)) return rulesMsg.name_space
        return
      },
    },
    phone_number: {
      required: {
        value: String,
        message: rulesMsg.contact_input,
      },
      minLength: {
        value: 10,
        message: rulesMsg.contact_length,
      },
      maxLength: {
        value: 11,
        message: rulesMsg.contact_length,
      },
      pattern: {
        value: /^[0-9]*$/,
        message: rulesMsg.contact_length,
      },
    },
    password: {
      required: {
        value: String,
        message: rulesMsg.password_credential,
      },
      minLength: {
        value: 8,
        message: rulesMsg.password_pattern,
      },
      pattern: {
        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        message: rulesMsg.password_pattern,
      },
    },
    password_confirmation: {
      validate: (value) => {
        return value === setPassword || rulesMsg.password_mismatch
      },
    },
    postal_code: {
      required: {
        value: String,
        message: rulesMsg.postal_input,
      },
      minLength: {
        value: 7,
        message: rulesMsg.postal_length(7),
      },
      maxLength: {
        value: 7,
        message: rulesMsg.postal_length(7),
      },
      validate: () => {
        if (addressError === 'fetchError') {
          return rulesMsg.postal_invalid
        }
        return
      },
    },
    prefecture: {
      required: {
        value: String,
        message: rulesMsg.prefecture_input,
      },
    },
    city: {
      required: {
        value: String,
        message: rulesMsg.city_input,
      },
    },
    address_line_1: {
      required: {
        value: String,
        message: rulesMsg.address_line_1_input,
      },
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    address_line_2: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    website: {
      required: {
        value: String,
        message: rulesMsg.website_input,
      },
      maxLength: {
        value: 500,
        message: rulesMsg.max(500),
      },
    },
    company_phone_number: {
      required: {
        value: String,
        message: rulesMsg.contact_input,
      },
      minLength: {
        value: 10,
        message: rulesMsg.contact_length,
      },
      maxLength: {
        value: 11,
        message: rulesMsg.contact_length,
      },
      pattern: {
        value: /^[0-9]*$/,
        message: rulesMsg.contact_length,
      },
    },
    company_email: {
      required: {
        value: String,
        message: rulesMsg.email_credential,
      },
      validate: (value) => {
        return emailAddressValidation(value)
      },
      maxLength: {
        value: 300,
        message: rulesMsg.max(300),
      },
    },
  }

  const handleRegister = (data) => {
    dispatch(confirmClientUser({ ...data, token: token }))
    dispatch(actionRegisterUser({ ...data, token: token }))
  }

  const inputLabelCreator = (
    name,
    label = '',
    placeholder,
    value,
    isRequired = false,
    isDisabled = false,
    caption = '',
    type = 'text'
  ) => {
    return (
      <Fragment>
        <InputLabel
          id={`${name}-id`}
          name={name}
          ref={register(name, validationRules[name])}
          error={errors && errors[name] ? true : false}
          errorMsg={(errors[name] && errors[name]?.message) ?? null}
          label={label}
          placeholder={placeholder}
          type={type}
          required={isRequired}
          isDisabled={isDisabled}
          value={value}
          onChange={name == 'postal_code' ? () => handleChangePostal() : () => handleInputChange(name)}
          caption={caption}
        />
      </Fragment>
    )
  }

  const handleChangePostal = () => {
    if (errors.postal_code) {
      trigger('postal_code')
    }
    clearErrors('prefecture')
    clearErrors('city')
    setAddressError('')
    setPrefecture('')
    setStreet('')
    setCity('')
  }

  const handleInputChange = (name) => {
    errors[name] && trigger(name)
  }

  const handleGetAddress = async () => {
    const validPostal = await trigger('postal_code')
    let postalCode = getValues('postal_code')

    if (validPostal) {
      setLoader(true)
      const { data } = await getAddress(postalCode)
      const { results } = data

      if (results) {
        setPrefecture(results[0]?.address1)
        setCity(results[0]?.address2)
        setStreet(results[0]?.address3)
        clearErrors('address_line_1')
        clearErrors('address_line_2')
      } else {
        setAddressError('fetchError')
        trigger('postal_code')
      }
    }
    setLoader(false)
  }

  return (
    <form onSubmit={handleSubmit(handleRegister)}>
      <StyledClientRegistrationForm container direction="column" rowSpacing={2}>
        <Grid item>
          <Heading bold="true" variant="title">
            {'基本情報登録'}
          </Heading>
        </Grid>
        <Grid item>
          <SubHeading bold="true">{'担当者情報'}</SubHeading>
        </Grid>
        <Grid item>
          <Grid container columnSpacing={{ xs: 0, md: 4 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {inputLabelCreator(
                'full_name',
                '担当者名',
                '例: 佐々通 太郎',
                userData?.full_name,
                true,
                false,
                '姓と名の間にスペースを入れてください'
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  {inputLabelCreator('phone_number', '担当者電話番号', '例: 09012345678', userData?.phone_number, true)}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  {inputLabelCreator(
                    'email',
                    '担当者メールアドレス',
                    'taro@example.com',
                    tokenData?.email_address,
                    false,
                    true,
                    '',
                    'text'
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {inputLabelCreator(
                'password',
                'パスワード',
                '●   ●   ●   ●   ●   ●',
                userData?.password,
                true,
                false,
                '数字、大文字の英字、小文字の英字をすべて含めた8文字以上を入力してください。',
                'password'
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {inputLabelCreator(
                'password_confirmation',
                'パスワード(確認)',
                '●   ●   ●   ●   ●   ●',
                userData?.password_confirmation,
                true,
                false,
                '',
                'password'
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <SubHeading>{'法人情報'}</SubHeading>
        </Grid>
        <Grid item mt={2}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {inputLabelCreator(
                'company_name',
                '法人名',
                '〇〇株式会社',
                tokenData?.company_name,
                false,
                true,
                '',
                'text'
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              {inputLabelCreator('postal_code', '法人住所', '例: 9820011', userData?.postal_code, true)}
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} sx={{ marginTop: { xs: -2, sm: -2, md: 3.5 } }}>
              <LargeButton variant="outlined" color={'primary'} mode={'light'} onClick={handleGetAddress}>
                {loader ? <CircularProgress size={14} color="white" /> : '郵便番号から住所を入力'}
              </LargeButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ marginTop: { xs: -2, sm: -2, md: -4 } }}>
              {inputLabelCreator(
                'prefecture',
                '',
                '都道府県',
                prefecture || userData?.prefecture,
                false,
                true,
                '',
                'text'
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ marginTop: { xs: -5, sm: -4 } }}>
              {inputLabelCreator('city', '', '市町村区', city || userData?.city, false, true, '', 'text')}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: { xs: -5, sm: -4 } }}>
              {inputLabelCreator(
                'address_line_1',
                '',
                '例: ６丁目',
                street || userData?.address_line_1,
                false,
                false,
                '',
                'text'
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: { xs: -5, sm: -4 } }}>
              {inputLabelCreator('address_line_2', '', '例: １３−２ フリシャー仙台ビル 104', userData?.address_line_2)}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {inputLabelCreator(
                'website',
                'WEBサイト',
                '例: https://www.sasatsu.co.jp/',
                userData?.website,
                true,
                false,
                'SNSページ（Facebook, Twitterなど）でも可'
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {inputLabelCreator(
                'company_phone_number',
                '法人代表電話番号',
                '例: 0223979860',
                userData?.company_phone_number,
                true,
                false,
                '担当者電話番号と重複可'
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {inputLabelCreator(
                'company_email',
                '法人メールアドレス',
                '例: taro@example.com',
                userData?.company_email,
                true,
                false,
                '担当者メールアドレスと重複可'
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} textAlign="end">
              <LargeButton type="submit" color="primary" mode="light" width={'30%'}>
                {'確認'}
              </LargeButton>
            </Grid>
          </Grid>
        </Grid>
      </StyledClientRegistrationForm>
    </form>
  )
}

export default ClientRegistrationForm
