import * as types from './actionTypes'
import pick from 'lodash/pick'
import { formulateCurrentPage, formulatePageResult } from '../../utils/helper'

const initialState = {
  list: [],
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 20,
    prevPageUrl: null,
    nextPageUrl: null,
    pageFrom: 0,
    pageTo: 0,
  },
  search: {
    date: '',
    keyword: '',
    limit: 20,
    page: 1,
    sort: 'desc',
    sortBy: 'date',
  },
  mode: localStorage.getItem('mode'),
  error: {},
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SEARCH_SYSTEM_USAGE: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )
      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
      }
    }
    case types.SET_SEARCH_CRITERIA:
      return {
        ...state,
        search: {
          ...state.search,
          ...pick(payload, Object.keys(state.search)),
        },
      }
    case types.RESET_CURRENT_FORM:
      return {
        ...state,
        search: {
          ...initialState.search,
          ...(payload.mode === 'opc' ? { keyword: '' } : {}),
        },
        error: {},
        mode: payload.mode,
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default reducer
