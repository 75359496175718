import React, { useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useMediaQuery, Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getUser } from 'services/auth'
import { hideNotification } from 'store/notification/actionCreators'
import { PageHeader, PageFooter, Modal } from 'components'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Grid, Typography } from '@mui/material'
import { createClientWorkerGtag, removeScript, scriptId } from 'utils/gtag'
const PREFIX = 'Main'

const classes = {
  root: `${PREFIX}-root`,
  shiftContent: `${PREFIX}-shiftContent`,
  content: `${PREFIX}-content`,
}

const Root = styled('div')(({ mode, theme }) => ({
  [`&.${classes.root}`]: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {},
  },
  [`& .${classes.content}`]: {
    minHeight: `calc(100% - (${theme.standards.header.height}px + ${theme.standards.footer.height}px))`,
    backgroundColor: `${theme.palette.pageWrapper[mode]} !important`,
  },
}))

const Main = (props) => {
  const { children, mode } = props
  const auth = useSelector((state) => state.auth)
  const notification = useSelector((state) => state.notification)
  const dispatch = useDispatch()
  const site = localStorage.getItem('mode')
  //add gtag for worker and client
  useEffect(() => {
    if (site === 'wkr' || site === 'client') {
      createClientWorkerGtag()
    } else {
      removeScript(scriptId)
    }
    return () => {
      removeScript(scriptId)
    }
  }, [])

  //force page to load on top
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, '500')
  }, [location])
  useEffect(() => {
    if (auth.isAuthenticated && !auth.user) {
      dispatch(getUser())
    }
  }, [dispatch, auth.user])
  const handleCloseModal = () => {
    dispatch(hideNotification())
  }
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })
  return (
    <Root
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
      mode={mode || localStorage.getItem('userType')}
    >
      <PageHeader />
      <main
        className={clsx({
          [classes.content]: true,
        })}
      >
        {children}
        <Modal
          open={notification.show}
          title={
            <Grid item container px={1} alignItems="center">
              <ErrorOutlineIcon color="error" fontSize="large" />{' '}
              <Typography variant="h1" color="error" sx={{ ml: 2 }}>
                システムエラー
              </Typography>
            </Grid>
          }
          onClose={handleCloseModal}
        >
          <Box sx={{ fontSize: 12 }}>{notification.message}</Box>
        </Modal>
      </main>
      <PageFooter />
    </Root>
  )
}

Main.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.string,
}

export default Main
