import React, { Fragment, useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import searchIcon from 'assets/svg/search.svg'
import AddIcon from '@mui/icons-material/Add'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { resetCurrentForm } from 'store/job-groups/actionCreators'
import { getAllClientUsers } from 'services/projects'
import { changeSearchCriteria as jobGroupSearchCriteria } from 'services/jobGroups'
import { filterProjectStatus, getStatusByType } from 'utils/helper'
import { useHistory } from 'react-router-dom'
import { actionClearModalValues, actionClearProjectDetails } from 'store/projects/actionCreators'
import { getMessages } from 'validation'
import { status } from 'utils/config/status'

//components
import {
  LargeButton,
  InputLabel,
  InputSelect,
  TableList,
  Heading,
  Modal,
  ProjectModalForm,
  ProjectModalVerify,
  Icon,
  ProjectAbortModal,
  Link,
} from 'components'

const ProjectListForm = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalAction, setModalAction] = useState('')
  const [selectedRow, setSelectedRow] = useState({})
  const [projectStatus, setProjectStatus] = useState([])
  const [statusId, setStatusId] = useState(0)

  const { statusList } = useSelector((state) => state.common)
  const { search } = useSelector((state) => state.jobGroups)
  const { projectDetails } = useSelector((state) => state.projects)

  const dispatch = useDispatch()
  const history = useHistory()
  const rulesMsg = getMessages('ja')
  const isClient = localStorage.getItem('mode').includes('client')
  const { props: projectProps } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const hasErrors = errors.project || errors.client
  const hasProjectStatusList = projectStatus && projectStatus.length > 0

  useEffect(() => {
    isClient && projectProps.location?.state?.fromRegistration && handleRegister()
  }, [])

  useEffect(() => {
    statusList && setProjectStatus(filterProjectStatus(getStatusByType(statusList, 'Project')))
  }, [statusList])

  useEffect(() => {
    setStatusId(status.project.open)
  }, [projectStatus])

  //Get All Active Clients
  const handleSearch = (data) => {
    props.handlePageSearch(data)
  }

  const validationRules = {
    client: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
    project: {
      maxLength: {
        value: 255,
        message: rulesMsg.max(255),
      },
    },
  }

  //Actions
  //Modal Actions
  const handleCloseProjectModal = () => {
    dispatch(actionClearProjectDetails())
    dispatch(actionClearModalValues())
    setModalAction('')
    setIsOpen(false)
  }

  //Page
  const handleRegister = () => {
    setModalAction('register')
    setIsOpen(true)
    setSelectedRow({})
  }

  //Table Actions
  const handleEdit = () => {
    setModalAction('edit')
    dispatch(getAllClientUsers(selectedRow?.client.id))
    setIsOpen(true)
  }

  const handleAddJobGroup = () => {
    dispatch(resetCurrentForm())
    history.push({
      pathname: `/${isClient ? 'job-group' : 'job_group'}/create/`,
      state: { projectId: selectedRow?.id },
    })
  }

  const handleOpenJobGroup = () => {
    const clientName = selectedRow.client.name
    const projectId = selectedRow.id

    dispatch(
      jobGroupSearchCriteria({
        ...search,
        client_name: clientName,
        project_name: `"${projectId}"`,
        start_date: '',
        end_date: '',
        status_id: '',
      })
    )
    history.push(`/${isClient ? 'job-group/' : 'job_group_'}list`)
  }

  const handleCopy = () => {
    setModalAction('copy')
    dispatch(getAllClientUsers(selectedRow?.client.id))
    setIsOpen(true)
  }

  const handleSelectedRow = (rowData) => {
    setSelectedRow(rowData)
  }

  //Format table list data
  const formatData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        client_name: (
          <Link to={`/client/show/${item?.client?.id}`}>
            <u>{item?.client_name}</u>
          </Link>
        ),
        pic_name: (
          <Link to={`/client-user/show/${item?.client_user?.id}`}>
            <u>{item?.pic_name}</u>
          </Link>
        ),
      }
    })
  }

  const actionList = [
    {
      handler: handleEdit,
      name: '編集',
    },
    {
      handler: handleAddJobGroup,
      name: '作業グループ追加',
    },
    {
      handler: handleOpenJobGroup,
      name: '作業グループ一覧',
    },
    {
      handler: handleCopy,
      name: 'コピー',
    },
  ]

  const modalContent = () => {
    if (Object.keys(projectDetails).length > 0) {
      return (
        <ProjectModalVerify
          action={modalAction}
          title={modalAction === 'edit' ? '案件の編集が完了しました。' : '案件の登録が完了しました。'}
          onClose={handleCloseProjectModal}
          projectDetails={projectDetails}
        />
      )
    }

    if (modalAction.includes('abort')) {
      return <ProjectAbortModal selectedRow={selectedRow} onClose={handleCloseProjectModal} />
    }

    return (
      <ProjectModalForm
        action={modalAction}
        selectedRow={selectedRow}
        onClose={handleCloseProjectModal}
        statusList={projectStatus}
      />
    )
  }

  return (
    <Fragment>
      <Grid container>
        <Grid container>
          <Grid item xs={12} sm={isClient ? 6 : 12} md={isClient ? 8 : 12} pt={0.5}>
            <Heading>{'案件一覧'}</Heading>
          </Grid>
          <Grid item xs={12} sm={isClient ? 6 : 12} md={isClient ? 4 : 12} textAlign="end" mt={{ xs: 3, sm: 0 }}>
            <LargeButton startIcon={<AddIcon size={'sm'} color="#ffffff" />} onClick={handleRegister}>
              {'案件新規登録'}
            </LargeButton>
          </Grid>
        </Grid>
        <Grid container pt={4}>
          <form onSubmit={handleSubmit(handleSearch)} className="ProjectList-form">
            <Grid container spacing={2} alignItems={'start'}>
              {!isClient && (
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <InputLabel
                    name="client"
                    ref={register('client', validationRules.client)}
                    error={errors && errors.client ? true : false}
                    errorMsg={errors ? errors?.client?.message : null}
                    label="クライアント名"
                    placeholder="クライアント名"
                    type="text"
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={isClient ? 5 : 3} lg={isClient ? 5 : 3} xl={isClient ? 5 : 3}>
                <InputLabel
                  className="Login-InputLabel"
                  name="project"
                  ref={register('project', validationRules.project)}
                  error={errors && errors.project ? true : false}
                  errorMsg={errors ? errors?.project?.message : null}
                  label={`${isClient ? '案件名・ID' : '案件名'}`}
                  placeholder={`${!isClient ? '案件名' : ''}`}
                  type="text"
                />
              </Grid>
              {hasProjectStatusList && (
                <Grid item xs={12} sm={6} md={isClient ? 3 : 2} lg={isClient ? 3 : 2} xl={isClient ? 3 : 2}>
                  <InputSelect
                    name="status"
                    ref={register('status')}
                    label="ステータス"
                    menuItems={hasProjectStatusList && [...projectStatus, { id: 0, name: 'すべて' }]}
                    value={statusId}
                    onChange={(value) => setStatusId(value)}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2} sx={{ alignSelf: 'center' }} mt={hasErrors && -3}>
                <LargeButton
                  type="submit"
                  bold="true"
                  color="warning"
                  startIcon={<Icon size={'sm'} disabled={props.isLoading ? true : false} source={searchIcon} />}
                >
                  {'検索'}
                </LargeButton>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12} mt={hasErrors ? -1 : 2}>
            <TableList
              title={`Project List`}
              headCells={props.headerCells}
              data={isClient ? props.data : formatData(props.data)}
              totalPage={props.totalPage}
              totalCount={props.totalCount}
              handlePageChange={props.handlePageChange}
              isLoading={props.isLoading}
              handleSort={props.handleSort}
              handleSelectedRow={handleSelectedRow}
              sort={props.sort}
              sortBy={props.sortBy}
              currentPage={props.currentPage}
              action={true}
              actionList={actionList}
              pageFrom={props.pageFrom}
              pageTo={props.pageTo}
            />
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={isOpen}
        title={modalAction == 'edit' ? '案件編集' : '案件登録'}
        onClose={handleCloseProjectModal}
        size={'md'}
      >
        {modalContent()}
      </Modal>
    </Fragment>
  )
}

ProjectListForm.displayName = 'Project List Form'

ProjectListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
  location: PropTypes.any,
  props: PropTypes.object,
}

export default ProjectListForm
