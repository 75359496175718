import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal as MUIModal, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
//components
import { SubHeading, Container } from 'components'
import standards from 'theme/standards'

const StyledModal = styled(MUIModal)(({ size, theme }) => ({
  '& .Modal-container': {
    height: '100%',
  },
  '& .Modal-wrapper': {
    [theme.breakpoints.down('sm')]: {
      marginRight: '25px',
      marginLeft: '25px',
    },
    backgroundColor: `${theme.palette.background.paper}`,
    outline: 'none',
    borderRadius: '7px',
    minHeight: '200px',
    '&:focus': {
      outline: 'none',
    },
    maxWidth: `${theme.standards.container[size + '_modal']}` ?? '',
  },
  '& .Modal-header': {
    backgroundColor: `${theme.palette.background.gray}`,
    height: `${standards.modal.header.height}px`,
    opacity: 1,
    borderRadius: '7px 7px 0px 0px',
  },
  '& .Modal-CloseBtn': {
    color: `${theme.palette.black}`,
    cursor: 'pointer',
  },
  '& .Modal-Body': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: standards.padding.modal.body,
  },
}))

const Modal = (props) => {
  const [modalOpen, setModalOpen] = useState(false)
  useEffect(() => {
    setModalOpen(props.open)
  }, [props])

  const handleClose = () => {
    setModalOpen(false)
  }

  return (
    <StyledModal open={modalOpen} onClose={props.onClose ? props.onClose : handleClose} size={props.size}>
      <Grid container display="flex" justifyContent="center" alignItems="center" className="Modal-container">
        <Container item className={'Modal-wrapper NoPadding'} xs={12}>
          <Grid container className="Modal-header" p={3}>
            <Grid item xs={10}>
              <SubHeading>{props.title}</SubHeading>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <CloseIcon className="Modal-CloseBtn" onClick={props.onClose ? props.onClose : handleClose} />
            </Grid>
          </Grid>
          <Grid container className="Modal-Body">
            {props.children}
          </Grid>
        </Container>
      </Grid>
    </StyledModal>
  )
}

Modal.defaultProps = {
  size: 'md',
}

Modal.propTypes = {
  title: PropTypes.any,
  children: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  size: PropTypes.string,
}

export default Modal
