import React from 'react'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
//components
import { FooterLinks, Label } from 'components'
import standards from 'theme/standards'

const StyledPageFooter = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  backgroundColor: `${theme.palette.background.paper}`,
  height: standards.footer.height,
  opacity: 1,
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  '& .Footer-Label': {
    cursor: 'pointer',
  },
}))

const PageFooter = () => {
  const footerLinks = [
    {
      name: '利用規約',
      link: '/terms_of_use',
      target: '_blank',
    },
    {
      name: 'プライバシーポリシー',
      link: '/privacy_policy',
      target: '_blank',
    },
  ]
  return (
    <StyledPageFooter container>
      <Grid item xs={12} md={1} sm={1} />
      <Grid item xs={12} md={6} sm={6} sx={{ display: { xs: 'flex', sm: 'block' }, justifyContent: 'center' }}>
        <Label color="gray2">{` ©  ${new Date().getFullYear()} 佐々通オンサイト`}</Label>
      </Grid>
      <Grid item xs={12} md={4} sm={4}>
        <FooterLinks footerLinks={footerLinks}></FooterLinks>
      </Grid>
    </StyledPageFooter>
  )
}

export default PageFooter
