import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Label, TableList, LargeButton, Input, Modal, InputTextArea, SubHeading } from 'components'
import { Grid, FormHelperText } from '@mui/material'
import {
  downloadActualReport,
  saveReportDescription,
  uploadActualReport,
  deleteActualReport,
  submitPerformanceReport,
  readWorkerJob,
} from 'services/jobs'
import { useDispatch } from 'react-redux'
import { downloadFile } from 'utils/common'
import { status } from 'utils/config/status'
import { getMessages } from 'validation'

const ReportFileTab = (props) => {
  const rulesMsg = getMessages('ja')
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm()

  const {
    register: registerSubmitReport,
    handleSubmit: handleSubmitReportStatus,
    reset,
    formState: { errors: reportErrors },
  } = useForm()

  const { data } = props
  const [update, setUpdate] = useState(false)
  const [isRequesting, setRequesting] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [modalMode, setModalMode] = useState('delete')
  const [selectedRow, setSelectedRow] = useState(null)
  const [confirmed, setConfirmed] = useState(false)
  const [fileError, setFileError] = useState('')
  const reportData = data?.report_files.map((item) => {
    return {
      ...item,
      updated_at: item?.updated_date + ' ' + item?.updated_time,
    }
  })

  const headerCells = [
    { id: 'filename', label: 'ファイル名', minWidth: 400 },
    { id: 'updated_at', label: 'アップロード日時', align: 'center' },
  ]

  //form validation
  const validationRules = {
    actual_report_draft: {
      required: {
        value: String,
        message: rulesMsg.required('実績報告を'),
      },
      validate: (value) => {
        if (!value.trim().length > 0) {
          return rulesMsg.required('実績報告を')
        } else if (value.trim().length > 1000) {
          return rulesMsg.max(1000)
        } else {
          return
        }
      },
    },
    comment: {
      required: {
        value: String,
        message: rulesMsg.required('修正コメントを'),
      },
      validate: (value) => {
        if (!value.trim().length > 0) {
          return rulesMsg.required('修正コメントを')
        } else if (value.trim().length > 512) {
          return rulesMsg.max(512)
        } else {
          return
        }
      },
    },
  }

  useEffect(() => {
    const reportNotSubmitted = parseInt(data?.actual_report_status_id) === status.actual_report.not_submitted
    setUpdate(reportNotSubmitted)
  }, [data?.status_id, data?.actual_report_status_id])

  //handle download report file
  const handleDownload = (e, rowData) => {
    downloadActualReport(data.id, rowData?.id).then((response) => {
      downloadFile(rowData?.filename, response.data)
    })
  }
  //handle on click update report btn
  const handleUpdateReport = () => {
    setUpdate(true)
  }
  //handle save report description
  const handleSubmitReportDescription = (formData) => {
    setRequesting(true)
    saveReportDescription(data?.id, formData?.actual_report_draft)
      .then(() => {
        setRequesting(false)
        dispatch(readWorkerJob(data?.id, false))
      })
      .catch(() => {
        setRequesting(false)
      })
  }
  // handle upload report file
  const handleUploadReport = (e) => {
    setFileError('')
    let files = e?.target?.files
    if (files && files.length > 0) {
      const fileSize = files[0].size / 1024 / 1024 // in MB
      if (fileSize > 4) {
        setFileError('ファイルサイズは4MB以下にしてください。')
      } else {
        setRequesting(true)
        uploadActualReport(data?.id, e?.target?.files[0])
          .then(() => {
            setRequesting(false)
            setUpdate(true)
            dispatch(readWorkerJob(data?.id, false))
          })
          .catch((e) => {
            const { code, error } = e.response.data
            if (code === 422) {
              setFileError(error['id_card_url'][0])
            }
            setRequesting(false)
          })
      }
    }
  }
  //handle on delete show confirmation modal
  const handleDelete = (e, rowData) => {
    setSelectedRow(rowData)
    setModalMode('delete')
    setOpenModal(true)
  }
  //handle Delete Confirmation Modal close
  const handleModalClose = () => {
    setOpenModal(false)
    setConfirmed(false)
  }
  //Remove Report File
  const handleRemoveReport = (removeReportId) => {
    if (removeReportId) {
      setRequesting(true)
      deleteActualReport(data?.id, removeReportId)
        .then(() => {
          setRequesting(false)
          setUpdate(true)
          dispatch(readWorkerJob(data?.id, false))
          setOpenModal(false)
        })
        .catch(() => {
          setRequesting(false)
        })
    }
  }
  //handle click submit/update report
  const handleSubmitReport = () => {
    resetCommentForm()
    setFileError('')
    setModalMode('report')
    setOpenModal(true)
  }
  //handle reset form
  const resetCommentForm = () => {
    reset(
      {},
      {
        keepErrors: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      }
    )
  }
  //handle confirm submit/update report
  const handleConfirmSubmitReport = (formData) => {
    formData['actual_report_draft'] = getValues('actual_report_draft')
    setRequesting(true)
    dispatch(submitPerformanceReport(data?.id, formData))
      .then(() => {
        resetCommentForm()
        setRequesting(false)
        setConfirmed(true)
        setUpdate(false)
      })
      .catch(() => {
        resetCommentForm()
        setRequesting(false)
      })
  }
  //get Delete Modal content
  const getDeleteModalContent = () => {
    return (
      <Grid container justifyContent="center">
        <Grid item pt={4} xs={12} textAlign="center">
          <Label fontSize={18}>削除してもよろしいでしょうか？</Label>
        </Grid>
        <Grid item container xs={12} justifyContent="center" textAlign="center" gap={2} pt={6}>
          <LargeButton id="CancelRemoveBtn" color="gray" mode="btn1" onClick={handleModalClose} disabled={isRequesting}>
            キャンセル
          </LargeButton>
          <LargeButton
            id="ConfirmRemoveBtn"
            type="submit"
            disabled={isRequesting}
            onClick={() => handleRemoveReport(selectedRow?.id)}
          >
            はい
          </LargeButton>
        </Grid>
      </Grid>
    )
  }
  const isFirstSubmit = parseInt(data?.actual_report_status_id) === status.actual_report.not_submitted
  //get Submit Report Modal Content
  const getSubmitReportModalContent = () => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} textAlign={(!confirmed && isFirstSubmit) || confirmed ? 'center' : 'left'} pt={4}>
          {!confirmed && (
            <Label>{isFirstSubmit ? 'この内容で実績報告しますか？' : 'この内容で実績の変更を報告しますか？'} </Label>
          )}
          {confirmed && <SubHeading>実績報告が完了しました。</SubHeading>}
        </Grid>
        {!confirmed && !isFirstSubmit && (
          <Grid item xs={12} pt={4}>
            <InputTextArea
              name="comment"
              ref={registerSubmitReport('comment', validationRules.comment)}
              error={reportErrors && reportErrors.comment ? true : false}
              errorMsg={reportErrors ? reportErrors?.comment?.message : null}
              label="修正コメント"
              required
              borderRadius={0}
              height={100}
              borderColor="#707070"
            />
          </Grid>
        )}
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          textAlign="center"
          gap={2}
          pt={!isFirstSubmit && !confirmed ? 1 : 6}
        >
          {!confirmed && (
            <React.Fragment>
              <Grid item xs={5} sm={4}>
                <LargeButton
                  id="CancelSubmitBtn"
                  color="gray"
                  mode="btn1"
                  onClick={handleModalClose}
                  disabled={isRequesting}
                >
                  キャンセル
                </LargeButton>
              </Grid>
              <Grid item xs={5} sm={4}>
                <LargeButton
                  id="ConfirmSubmitReportBtn"
                  type="submit"
                  disabled={isRequesting}
                  onClick={handleSubmitReportStatus(handleConfirmSubmitReport)}
                >
                  はい
                </LargeButton>
              </Grid>
            </React.Fragment>
          )}
          {confirmed && (
            <Grid item xs={6} sm={6} textAlign="center">
              <LargeButton id="SubmitReportCloseBtn" onClick={handleModalClose}>
                OK
              </LargeButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container p={4}>
      <Label bold="true" pb={1}>
        実績報告
      </Label>
      <Grid item pb={2} xs={12}>
        <pre className="ReportDescriptionWrapper">{!update && data?.actual_report_draft}</pre>
        {update && (
          <Input
            multiline
            name="actual_report_draft"
            ref={register('actual_report_draft', validationRules.actual_report_draft)}
            error={errors && errors.actual_report_draft ? true : false}
            errorMsg={errors ? errors?.actual_report_draft?.message : null}
            borderRadius={7}
            height={184}
            defaultValue={data?.actual_report_draft}
            borderColor="#B2B0B0"
          />
        )}
      </Grid>
      {update && !data?.actual_report_description && (
        <Grid item container xs={12} justifyContent="center" pb={3}>
          <LargeButton
            id="UpdateReportTextBtn"
            onClick={handleSubmit(handleSubmitReportDescription)}
            variant="outlined"
            color="success"
            mode="light"
            disabled={isRequesting}
          >
            下書き保存する
          </LargeButton>
        </Grid>
      )}
      <Grid item xs={12}>
        <TableList
          title={`Job Report List`}
          headCells={headerCells}
          data={reportData}
          handleDownload={handleDownload}
          handleDelete={handleDelete}
          noSort
          noPaginate
          isReportsActions={{ download: true, delete: update }}
          no_data_msg="レポートは提出されていません"
        />
        {fileError && (
          <FormHelperText id={'FormHelperText-id_card_url-ID'} error>
            {fileError}
          </FormHelperText>
        )}
      </Grid>
      <Grid item container justifyContent="end" gap={3} pt={2}>
        {!update && parseInt(data?.actual_report_status_id) !== status.actual_report.accepted && (
          <LargeButton id="UpdateReportsBtn" onClick={handleUpdateReport}>
            変更する
          </LargeButton>
        )}
        {update && (
          <Grid item xs={12} sm={6} textAlign="end">
            <LargeButton
              id="UploadReportsBtn"
              className="UploadBtn"
              variant="outlined"
              color="success"
              mode="light"
              component="label"
              disabled={isRequesting}
            >
              ファイルを追加する
              <input id="InputUploadFile-ID" type="file" name="id_card_url" hidden onChange={handleUploadReport} />
            </LargeButton>
          </Grid>
        )}
      </Grid>
      <Grid item container justifyContent="center" gap={3} pt={8}>
        {update && data?.has_clock_out_rpt && (
          <LargeButton
            id="SubmitReportsBtn"
            onClick={handleSubmit(handleSubmitReport)}
            disabled={errors && errors.actual_report_draft}
          >
            {parseInt(data?.actual_report_status_id) === status.actual_report.not_submitted
              ? '以上の内容で実績報告する'
              : '以上の内容で実績を変更したことを報告する'}
          </LargeButton>
        )}
      </Grid>
      <Modal
        open={openModal}
        title={isFirstSubmit || confirmed ? '実績報告' : '実績報告の変更'}
        onClose={handleModalClose}
        size={confirmed ? 'sm' : 'md'}
      >
        {modalMode === 'delete' ? getDeleteModalContent() : getSubmitReportModalContent()}
      </Modal>
    </Grid>
  )
}

ReportFileTab.propTypes = {
  data: PropTypes.object,
}

export default ReportFileTab
