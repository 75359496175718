import React, { useEffect, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  InputDatePicker,
  InputLabel,
  Label,
  LargeButton,
  Heading,
  SubHeading,
  P,
  InputMultiCheckbox,
  InputRadioGroup,
  Input,
  InputTextArea,
  ContentDisplay,
  CategoryDisplay,
  Icon,
  Stepper,
  Checkbox,
  Caption,
} from 'components'
import { Grid, CircularProgress, FormHelperText } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { useDispatch, useSelector } from 'react-redux'
import { setFormMode, setConfirming, setWorkersFormData, setFormErrors } from 'store/workers/actionCreators'
import { getPublicWorkerSkills, getAddress } from 'services/common'
import * as dayjs from 'dayjs'
//utils
import { minBirthDate, dateFormat } from 'utils/common'
import { saveWorkerInformation } from 'services/workers'
import { groupBy } from 'utils/helper'
import SuccessIcon from 'assets/svg/success.svg'
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckIcon from '@mui/icons-material/Check'
import { hideLoader } from 'store/loader/actionCreators'
import { hideNotification } from 'store/notification/actionCreators'
import * as eaw from 'eastasianwidth'
import * as AFHConvert from 'ascii-fullwidth-halfwidth-convert'
import { getUser } from 'services/auth'

const StyledCreateWorkerInformationForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .SkillsWrapper_6 .CheckboxWrapper': {
    display: 'grid',
    gridTemplateRows: 'repeat(17, auto)',
    gridAutoFlow: 'column',
    gridAutoColumns: 'auto',
    gridTemplateColumns: '50%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  '& input.Mui-disabled': {
    backgroundColor: `${theme.palette.lightGray.dark}`,
    borderRadius: 5,
  },
  '& .DisplayNameWrapper .MuiBox-root': {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  '& .MuiButton-root.UploadBtn': {
    borderRadius: 5,
    '& .MuiFormControl-root': {
      display: 'none',
    },
  },
  '& input#InputUploadFile-ID': {
    display: 'none',
  },
  '& .WorkExperience-Wrapper': {
    '& .MuiBox-root:first-of-type': {
      padding: 0,
    },
    '& .InputLabel': {
      fontWeight: 'bold !important',
    },
    '& p.MuiFormHelperText-root.WorkExperience-Note': {
      color: `${theme.palette.text.primary} !important`,
      fontSize: 12,
      textAlign: 'end',
    },
  },
  '& .BankAccount-Wrapper': {
    '& .InputLabel': {
      padding: 0,
    },
    '& .InputLabel-subLabel': {
      paddingBottom: `${theme.standards.padding.label.inputLabel}`,
    },
    '& .MuiBox-root': {
      padding: 0,
    },
  },
  '& #grid-full-name': {
    '& .css-v38ksm': {
      padding: 0,
    },
    paddingBottom: 25,
  },
  '& .notice-label': {
    display: 'flex',
  },
  '& .WorkExperience-Text': {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
  },
  '& .downloadIcon': {
    cursor: 'pointer',
  },
}))

function CreateRegistrationForm(props) {
  const [step, setStep] = useState(0)
  const dispatch = useDispatch()
  const history = useHistory()
  const workerUpdated = localStorage.getItem('workerUpdated')
  const [selectedSkills, setSelectedSkills] = useState([])
  const currentformData = useSelector((state) => state.workers.formData)
  const forceRedirect = useSelector((state) => state.common.privateRedirect)
  const [isUpdate, setIsUpdate] = useState(false)
  const [requestAddress, setRequestAddress] = useState(false)
  const [selectedGender, setSelectedGender] = useState('男性')
  const [cardUrlFilename, setCardUrlFilename] = useState('ファイルは選択されていません')
  const [isCurrentCard, setIsCurrentCard] = useState(true)
  const loader = useSelector((state) => state.loader)
  const authUser = JSON.parse(localStorage.getItem('authUser'))
  const [title, setTitle] = useState('基本情報入力')
  const [buttonLabel, setButtonLabel] = useState('次へ')
  const [confirmed, setConfirmed] = useState(workerUpdated || false)
  const [addressLine1, setAddressLine1] = useState('')
  const [profile, setProfile] = useState()
  const workerBasicInfo = 0
  const workerSkillStep = 1
  const workerConfirmStep = 2
  let workerId = authUser?.id
  let checkededSkills = []
  let newData = {}
  let formData =
    currentformData?.current && Object.keys(currentformData?.current).length > 0
      ? currentformData?.current
      : currentformData?.original
  const certificates = formData?.selectedSkills?.filter((skill) => skill.skill_category_type === 'Qualification')
  const canDos = formData?.selectedSkills?.filter((skill) => skill.skill_category_type === 'Skills')
  const certificatesByCategory =
    certificates && certificates.length > 0 ? groupBy(certificates, 'skill_category_name') : []
  const canDosByCategory = canDos && canDos.length > 0 ? groupBy(canDos, 'skill_category_name') : []
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useForm()

  const {
    register: registerForm2,
    unregister: unregisterForm2,
    handleSubmit: handleSubmitForm2,
    setValue: setForm2Value,
    getValues: getValuesForm2,
    setError: setErrorForm2,
    formState: { errors: errorsForm2 },
  } = useForm()

  const workerSkills = useSelector((state) => state.common.workerSkillList)
  const formErrors = useSelector((state) => state.workers.formErrors)

  //Reset local storage workerUpdated
  useEffect(() => {
    return () => {
      const currentPath = history.location.pathname
      if (!currentPath.includes('/worker-information')) {
        localStorage.removeItem('workerUpdated')
      }
    }
  })

  useEffect(() => {
    if (props?.location?.pathname.includes('update')) {
      setIsUpdate(true)
      dispatch(setFormMode(true))
    } else {
      setIsUpdate(false)
      dispatch(setFormMode(false))
    }
  }, [dispatch])

  useEffect(() => {
    setAddressLine1(formData?.address_line_1 ?? '')
  }, [formData])

  useEffect(() => {
    if (step === workerBasicInfo) {
      setTitle('基本情報入力')
      setButtonLabel('次へ')
    } else if (step === workerSkillStep) {
      setTitle('スキル')
      setButtonLabel('確認')
    } else {
      setTitle('基本情報')
      setButtonLabel('登録')
    }
    window.scrollTo(0, 0)
  }, [step])

  const onChangeGender = (e) => {
    setSelectedGender(e.target.value)
  }
  useEffect(() => {
    dispatch(getPublicWorkerSkills())
  }, [])

  useEffect(() => {
    setForm2Value('skills', selectedSkills.map(String))
  }, [dispatch, selectedSkills, formData, step])

  let defaultInputProps = {
    required: true,
  }

  const handleChangePostal = () => {
    if (errors.postal_code) {
      trigger('postal_code')
    }
    clearErrors('prefecture')
    clearErrors('city')
    setValue('prefecture', '')
    setValue('city', '')
  }

  const onDownloadCard = () => {
    const link = document.createElement('a')
    link.download = currentformData?.original?.id_card_url
    link.href = currentformData?.original.id_card_url
    link.click()
  }

  const redirectRoute = () => {
    if (forceRedirect) {
      history.push(forceRedirect)
    } else {
      history.push('/job/list')
    }
  }

  const getInputTextAreaField = (label, subLabel, name, placeholder, height, required) => {
    let inputValue = formData ? formData[name] : null
    defaultInputProps['height'] = height
    defaultInputProps['required'] = required
    const formError = step === 0 ? errors : errorsForm2

    return (
      <InputTextArea
        id={name + '-ID'}
        ref={step === 0 ? register(name, validationRules[name]) : registerForm2(name, validationRules[name])}
        error={formError && formError[name] ? true : false}
        errorMsg={formError ? formError[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        subLabel={subLabel}
        {...(inputValue && { value: inputValue })}
        {...defaultInputProps}
      />
    )
  }

  const handleGetAddress = async () => {
    const validPostal = await trigger('postal_code')
    let postal_code = getValues('postal_code')
    if (validPostal) {
      setRequestAddress(true)
      await getAddress(postal_code).then((response) => {
        setRequestAddress(false)
        if (response.data.results == null) {
          setError('postal_code', { type: 'custom', message: '該当する郵便番号が見つかりませんでした。' })
        } else {
          clearErrors('postal_code')
          clearErrors('prefecture')
          clearErrors('city')
          setValue('prefecture', response.data.results[0]?.address1)
          setValue('city', response.data.results[0]?.address2)
          setAddressLine1(response.data.results[0]?.address3)
        }
      })
    }
  }

  // handle Submit
  const handleSubmitWorkerForm = (data) => {
    setStep(step + 1)
    data.tekikaku_flag = data.tekikaku_flag ? 1 : 0
    data.receive_new_jobs_email = data.receive_new_jobs_email ? 1 : 0
    data.verified_account = 0
    data.account_status = 1

    let cardUrl = ''
    if (!data.id_card_url && typeof formData?.id_card_url === 'string') {
      //if blob type then remain
      cardUrl = formData?.id_card_url.search('blob:') !== -1 ? formData?.id_card_url : null
    }
    if (data.id_card_url[0] && data.id_card_url.length > 0) {
      //if file type then convert blob
      setProfile(data.id_card_url[0])
      cardUrl = URL.createObjectURL(data.id_card_url[0])
    }

    newData = {
      ...data,
      userID: workerId,
      id_card_url: cardUrl,
      id_card_filename: data.id_card_url.length > 0 ? data.id_card_url[0].name : formData?.id_card_filename,
    }

    dispatch(setWorkersFormData({ current: newData }))
    setValue('id_card_url', '')
    props.handleSteps(step + 1, false)
  }

  const handleSubmitWorkerForm1 = async () => {
    let newFormData = {
      ...formData,
      id_card_url: profile ?? '',
    }
    dispatch(setConfirming(true))
    dispatch(saveWorkerInformation(workerId, newFormData))
      .then((response) => {
        localStorage.setItem('workerId', response.data?.data?.worker_id)
        localStorage.setItem('workerUpdated', true)
        setConfirmed(true)
        props.handleSteps(step, true)
        dispatch(getUser())
        dispatch(setFormErrors({}))
      })
      .catch((e) => {
        dispatch(hideLoader())
        const { code, error } = e.response.data
        const allFields = formData ? Object.keys(formData) : []
        const allErrors = error ? Object.keys(error) : []
        const hasFieldError = allFields.some((item) => allErrors.includes(item))

        const allStepOneField = Object.keys(getValues())
        const allStepTwoField = Object.keys(getValuesForm2())
        const hasFieldErrorForm1 = allStepOneField.some((item) => allErrors.includes(item))
        const hasFieldErrorForm2 = allStepTwoField.some((item) => allErrors.includes(item))

        if (hasFieldErrorForm1) {
          setStep(workerBasicInfo)
        } else if (!hasFieldErrorForm1 && hasFieldErrorForm2) {
          setStep(workerSkillStep)
        }
        if (code === 422 && hasFieldError) {
          dispatch(hideNotification())
          dispatch(setFormErrors(error))
        }
      })
  }

  const setWorkerSkillsForm = (data) => {
    workerSkills.map((type) =>
      type?.skills.map((skill) => {
        let skills = {
          skill_category_name: type.name,
          skill_category_type: type.type,
          skill_id: skill.id,
          skill_name: skill.name,
        }
        if (data?.skills.length > 0 && data?.skills.includes(skill.id.toString())) {
          checkededSkills.push(skills)
        }
      })
    )

    newData = {
      ...data,
      selectedSkills: checkededSkills,
      skills: data.skills ? data.skills : [],
    }

    dispatch(setWorkersFormData({ current: newData }))
    props.handleSteps(step + 1, false)
  }

  const handleSubmitWorkSkills = (data) => {
    setStep(step + 1)
    setWorkerSkillsForm(data)
  }

  const goBackFirstoPage = () => {
    setStep(step - 1)
    props.handleSteps(step - 1, false)
    setWorkerSkillsForm(getValuesForm2())
    unregisterForm2('work_experience')
  }

  //onClick upload ID Card
  const handleUploadCard = (event) => {
    if (event?.target?.files !== null && event.target?.files?.length > 0) {
      setProfile(event.target.files[0])
      setCardUrlFilename(event.target.files[0].name)
    } else {
      setCardUrlFilename('ファイルは選択されていません')
    }
    setIsCurrentCard(false)
  }

  const getInputLabelField = (label, name, placeholder, disabled) => {
    let inputValue = formData ? formData[name] : null
    let required = defaultInputProps.required
    if (name === 'company_name' || name === 'address_line_2') {
      required = false
    }

    if (name === 'city' || name === 'prefecture') {
      inputValue = getValues(name)
    }

    if (name === 'address_line_1') {
      inputValue = addressLine1
    }

    return (
      <InputLabel
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        isDisabled={disabled || false}
        {...(inputValue && { value: inputValue })}
        {...defaultInputProps}
        required={required || false}
        onChange={name == 'postal_code' ? () => handleChangePostal() : () => handleInputChange(name)}
      />
    )
  }

  useEffect(() => {
    setCardUrlFilename(formData?.id_card_filename ? formData?.id_card_filename : 'ファイルは選択されていません')
    setSelectedSkills(formData?.skills?.length > 0 ? formData?.skills.map((id) => parseInt(id)) : [])
    setSelectedGender(formData?.gender ? formData?.gender : '男性')
  }, [formData])

  const getInputMultiCheckbox = (label, name, items, subLabel) => {
    return (
      <InputMultiCheckbox
        ref={registerForm2(name + '[]')}
        name={name + '[]'}
        label={label}
        subLabel={subLabel}
        items={items}
        selected={selectedSkills}
        labelProps={{
          bold: 'true',
        }}
      />
    )
  }

  const validationRules = {
    display_name: {
      required: {
        value: String,
        message: '表示名を入力してください。',
      },
      validate: (value) => {
        if (value.trim().length <= 0) {
          return '表示名を入力してください。'
        } else if (value.trim().length > 30) {
          return '30文字以内で入力してください。'
        } else {
          return
        }
      },
    },
    full_name: {
      required: {
        value: String,
        message: 'お名前を入力してください。',
      },
      validate: (value) => {
        if (value.trim().length <= 0) {
          return 'お名前を入力してください。'
        } else if (value.trim().length > 30) {
          return '30文字以内で入力してください。'
        } else if (fullNameSpaceValidation(value)) {
          return '姓と名の間にスペースを入力してください。'
        } else {
          return
        }
      },
    },
    email_address: {
      required: {
        value: String,
        message: 'メールアドレスを入力してください。',
      },
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'メールアドレスの書式が正しくありません。',
      },
    },
    birthday: {
      required: {
        value: String,
        message: '生年月日を入力してください。',
      },
      validate: (value) => {
        const isValid = dayjs(value, dateFormat, true).isValid()
        const minAge = 18
        const currentDate = dayjs()
        const birthDate = dayjs(value)

        if (!isValid) {
          return 'YYYY/MM/DDの形式で入力してください。'
        } else if (minAge > currentDate.diff(birthDate, 'year')) {
          return '18歳以上の方のみご登録可能です。'
        } else {
          return true
        }
      },
    },
    postal_code: {
      required: {
        value: String,
        message: '郵便番号を入力してください。',
      },
      validate: (value) => {
        if (value.length > 7) {
          return '7桁の半角数字を入力してください。'
        } else if (!/^[0-9]*$/.test(value)) {
          return '7桁の半角数字を入力してください。'
        } else {
          return true
        }
      },
    },
    prefecture: {
      required: {
        value: String,
        message: '都道府県は必須項目です。',
      },
    },
    city: {
      required: {
        value: String,
        message: '市町村区は必須項目です。',
      },
    },
    address_line_1: {
      required: {
        value: String,
        message: '町名番地を入力してください。',
      },
      validate: (value) => {
        if (value.trim().length <= 0) {
          return '町名番地を入力してください。'
        } else {
          return value.length <= 255 || '255文字以内で入力してください。'
        }
      },
    },
    address_line_2: {
      validate: (value) => {
        return value.length <= 255 || '255文字以内で入力してください。'
      },
    },
    phone_number: {
      required: {
        value: Number,
        message: '電話番号を入力してください。',
      },
      validate: (value) => {
        if (value.trim().length <= 0) {
          return '電話番号を入力してください。'
        } else if (value.length < 10) {
          return '10桁か11桁の半角数字を入力してください。'
        } else if (value.length > 11) {
          return '10桁か11桁の半角数字を入力してください。'
        } else if (!/^[0-9]*$/.test(value)) {
          return '10桁か11桁の半角数字を入力してください。'
        } else {
          return true
        }
      },
      pattern: {
        value: /^(0|[0-9]\d*)(\.\d+)?$/,
        message: '10桁か11桁の半角数字を入力してください。',
      },
    },
    company_name: {
      validate: (value) => {
        return value.length <= 255 || '255文字以内で入力してください。'
      },
    },
    bank_account: {
      validate: (value) => {
        return value.length <= 500 || '500文字以内で入力してください。'
      },
    },
    biller: {
      validate: (value) => {
        return value.length <= 500 || '500文字以内で入力してください。'
      },
    },
    work_experience: {
      required: {
        value: String,
        message: '業務経験を入力してください。',
      },
      validate: (value) => {
        if (value.trim().length <= 0) {
          return '業務経験を入力してください。'
        } else {
          return value.length <= 1000 || '1000文字以内で入力してください。'
        }
      },
    },
    id_card_url: {
      validate: (value) => {
        if (value !== null && value.length > 0) {
          const filename = value[0].name
          const fileSize = value[0].size / 1024 / 1024 // in MB
          let allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.JPG|\.JPEG|\.png|\.PNG|\.gif|\.GIF)$/i

          if (process.env.REACT_APP_CLAMAV_TEST === 'true') {
            allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.JPG|\.JPEG|\.png|\.PNG|\.gif|\.GIF|\.james|\.JAMES)$/i
          }

          if (!allowedExtensions.exec(filename)) {
            return 'JPEG, JPG, PNG, GIF, PDFいずれかのファイルを選択してください。'
          } else if (fileSize > 4) {
            return 'ファイルサイズは4MB以下にしてください。'
          }
        }
        return true
      },
    },
  }

  const fullNameSpaceValidation = (value) => {
    const converter = new AFHConvert()
    if (eaw.eastAsianWidth(value) === 'W') {
      value = converter.toHalfWidth(value)
    }

    return value.trim().split(' ').length < 2 || /\s{2,}/g.test(value.trim())
  }

  useEffect(() => {
    const allStepOneField = Object.keys(getValues())
    const allErrors = formErrors ? Object.keys(formErrors) : []
    const hasFieldErrorForm1 = allStepOneField.some((item) => allErrors.includes(item))

    Object.keys(formErrors).map((key) => {
      if (hasFieldErrorForm1) {
        setError(key, { type: 'custom', message: formErrors[key][0] }, { shouldFocus: true })
      } else {
        setErrorForm2(key, { type: 'custom', message: formErrors[key][0] }, { shouldFocus: true })
      }
    })
  }, [formErrors])

  const handleInputChange = (name) => {
    if (errors[name]) {
      trigger(name)
    }
  }

  const steps = ['基本情報入力', 'スキル入力', '確認']

  //Redirect to my-page: info already confirmed
  if (authUser?.member_type === 'ワーカー' && !workerUpdated) {
    return <Redirect to={'/my-page'} />
  }

  return (
    <StyledCreateWorkerInformationForm container py={'35px'} justifyContent="center" alignItems="center">
      {confirmed ? (
        <Grid container justifyContent="center" alignItems="center" textAlign="center" className="ConfirmationMd">
          <Grid item xs={12} sm={10} pt={4}>
            <Icon source={SuccessIcon} />
          </Grid>
          <Grid item xs={12} pb={3}>
            <Heading>ご登録ありがとうございます。</Heading>
          </Grid>
          <Grid item xs={12} pb={3}>
            <SubHeading>ワーカーを募集している作業を検索してみましょう!</SubHeading>
          </Grid>
          <Grid item xs={12} sm={10} pb={2}>
            <LargeButton id="CreatNewWorkerBtn" onClick={() => redirectRoute()}>
              作業一覧
            </LargeButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container px={{ sm: 0, md: 7 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stepper activeStep={step} steps={steps} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} pt={{ xs: 4, sm: 4 }}>
            <Grid container justifyContent="space-between">
              <Heading>{title}</Heading>
              {workerBasicInfo === step && (
                <Grid item>
                  <Label className="notice-label InputLabel">
                    <Label color="error" className="RequiredText">
                      *
                    </Label>
                    は必須項目です
                  </Label>
                </Grid>
              )}
              {workerSkillStep === step && (
                <Grid item xs={12} pt={4}>
                  <SubHeading bold="true">所有資格</SubHeading>
                  <Label pt={1}>取得済みの資格にチェックを入れてください</Label>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} pt={{ xs: 2, sm: 4 }}>
            <form onSubmit={handleSubmit(handleSubmitWorkerForm)} className="form">
              {step === 0 && (
                <>
                  <Grid id={'grid-full-name'} container>
                    <Grid item xs={12} md={12} alignItems="center" className="BankAccount-Wrapper">
                      {getInputLabelField('お名前', 'full_name', '例: 佐々通 太郎')}
                      <Caption>姓と名の間にスペースを入れて下さい。</Caption>
                    </Grid>
                    <Grid item xs={12}></Grid>
                  </Grid>
                  <Grid container spacing={{ xs: 0, md: 4 }}>
                    <Grid item xs={12} md={6}>
                      {getInputLabelField('表示名', 'display_name', '例：タロウ')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputDatePicker
                        id={'birthday-ID'}
                        ref={register('birthday', validationRules.birthday)}
                        error={errors && errors.birthday ? true : false}
                        errorMsg={errors ? errors.birthday?.message : null}
                        name="birthday"
                        label="生年月日"
                        placeholder="YYYY/MM/DD"
                        inputFormat={dateFormat}
                        maxDate={dayjs(minBirthDate)}
                        defaultCalendarMonth={dayjs(minBirthDate)}
                        disableHighlightToday
                        required
                        color="primary"
                        {...(formData && formData['birthday'] && { value: formData['birthday'] })}
                        {...defaultInputProps}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <InputRadioGroup
                        label="性別"
                        name="gender"
                        id={'RadioGroup-gender-ID'}
                        ref={register('gender')}
                        value={selectedGender || '男性'}
                        items={['男性', '女性', '無回答']}
                        onChange={onChangeGender}
                        size={32}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={{ xs: 0, md: 4 }}>
                    <Grid item xs={12} md={6}>
                      {getInputLabelField('郵便番号', 'postal_code', '例: 9820011')}
                    </Grid>
                    <Grid item container xs={12} md={6} pb={{ xs: 3, md: 0 }} alignItems={'center'}>
                      <LargeButton
                        variant="outlined"
                        color="green"
                        mode="light"
                        onClick={handleGetAddress}
                        id="PostalCodeBtn-ID"
                      >
                        {requestAddress && <CircularProgress size="1.5rem" color="inherit" />}
                        {!requestAddress && '郵便番号から住所を検索'}
                      </LargeButton>
                    </Grid>
                  </Grid>
                  <Grid container spacing={{ xs: 0, md: 4 }}>
                    <Grid item xs={12} md={6}>
                      {getInputLabelField('都道府県', 'prefecture', '', true)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {getInputLabelField('市町村区', 'city', '', true)}
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={{ xs: 0, md: 4 }}>
                    <Grid item xs={12}>
                      {getInputLabelField('町名番地', 'address_line_1', '例: 長町６−１３−２')}
                    </Grid>
                    <Grid item xs={12}>
                      {getInputLabelField('以降の住所', 'address_line_2', '例: フリシャー仙台ビル１０４')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {getInputLabelField('電話番号', 'phone_number', '例: 09012345678')}
                    </Grid>
                    <Grid item xs={12} pb={2}>
                      <Label>本人確認（任意）</Label>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <LargeButton
                        id="UploadIdCardBtn-ID"
                        className="UploadBtn"
                        color="lightGray"
                        mode="dark"
                        component="label"
                        maxwidth="unset"
                      >
                        ファイル選択
                        <Input
                          id="InputUploadFile-ID"
                          type="file"
                          name="id_card_url"
                          ref={register('id_card_url', validationRules.id_card_url)}
                          hidden
                          onChange={handleUploadCard}
                        />
                      </LargeButton>
                    </Grid>
                    <Grid item xs={12} md={9} display="inherit" alignItems="center" pl={{ xs: 0, md: 2 }}>
                      <Label>{cardUrlFilename}</Label>
                      &nbsp;
                      {isUpdate && currentformData?.original?.id_card_url && isCurrentCard && (
                        <DownloadForOfflineIcon color="secondary" onClick={onDownloadCard} className="downloadIcon" />
                      )}
                    </Grid>
                    <Grid item xs={12} pt={1} pb={3}>
                      {errors.id_card_url && (
                        <FormHelperText id={'FormHelperText-id_card_url-ID'} error>
                          {errors.id_card_url.message}
                        </FormHelperText>
                      )}
                      <Caption>
                        例:
                        免許書、保険書、パスポートなど。個人認証済みというアイコンが表示されオファーされやすくなります。
                      </Caption>
                    </Grid>
                    <Grid item container mb={2}>
                      <Grid item container pt={2}>
                        <Grid item pb={1}>
                          <Label>適格請求書発行事業者 (任意)</Label>
                        </Grid>
                        <Grid item container xs={12} alignItems={'center'}>
                          <Grid item>
                            <Checkbox
                              id={'tekikaku_flag-ID'}
                              ref={register('tekikaku_flag')}
                              name={'tekikaku_flag'}
                              checked={formData ? (formData?.tekikaku_flag == 1 ? true : false) : false}
                              label={''}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container pt={2}>
                        <Grid item pb={1}>
                          <Label>新着作業メールを受け取る (任意)</Label>
                        </Grid>
                        <Grid item container xs={12} alignItems={'center'}>
                          <Grid item>
                            <Checkbox
                              id={'receive_new_jobs_email-ID'}
                              ref={register('receive_new_jobs_email')}
                              name={'receive_new_jobs_email'}
                              checked={formData ? (formData?.receive_new_jobs_email == 1 ? true : false) : false}
                              label={''}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container pt={2} display="flex" justifyContent="end">
                    <LargeButton id="WorkerSubmitBtn" type="submit" color="primary">
                      {loader && <CircularProgress size="1.5rem" color="white" />}
                      {!loader && buttonLabel}
                    </LargeButton>
                  </Grid>
                </>
              )}
            </form>
            <form onSubmit={handleSubmitForm2(handleSubmitWorkSkills)}>
              <Grid container>
                {step === workerSkillStep &&
                  workerSkills &&
                  workerSkills.map((value, index) => (
                    <Grid
                      item
                      xs={12}
                      md={workerSkills.length - 1 === index ? 12 : 6}
                      key={index}
                      className={'SkillsWrapper_' + index}
                    >
                      {getInputMultiCheckbox(
                        value?.name,
                        'skills',
                        value?.skills,
                        workerSkills.length - 1 === index ? '手順書があれば可能な作業にチェックをいれてください' : ''
                      )}
                    </Grid>
                  ))}
              </Grid>
              {step === workerSkillStep && (
                <>
                  <Grid item xs={12} className="WorkExperience-Wrapper">
                    {getInputTextAreaField(
                      '業務経験',
                      '',
                      'work_experience',
                      '例:\nIT業界経験は通算で12年。\n以前の職場ではシステム運用部に所属し、ヘルプデスク業務従事。\n社員からのOS、Officeソフトについての問い合わせ対応や、PC導入時の設定作業、ハード故障等トラブル対応を行う。\n転職後は取引先から依頼を受け、新規導入PCのキッティング（およそ300台）を経験。\nまた同職場では、自社サイトの更新作業や、全国の月例所長WEB会議のセッティングなども担当した。\nその後個人事業主として独立し、某メーカーの訪問サポートを担う企業から業務委託を受け、\nNASの保守対応や個人宅インターネット導入時訪問サポートに従事。\n並行して、スポットではあるが法人向けITサポート対応（主にPCリプレース）も別企業より受託している。',
                      227,
                      true
                    )}
                    <FormHelperText className="WorkExperience-Note">最大1000文字まで入力ください</FormHelperText>
                  </Grid>
                  <Grid container pt={2} display="flex" justifyContent="end" gap={2}>
                    <LargeButton id="ConfirmBackBtn" color="gray" mode="btn1" onClick={() => goBackFirstoPage()}>
                      戻る
                    </LargeButton>
                    <LargeButton id="WorkerSubmitBtn" type="submit" color="primary" width={150}>
                      {loader && <CircularProgress size="1.5rem" color="white" />}
                      {!loader && buttonLabel}
                    </LargeButton>
                  </Grid>
                </>
              )}
            </form>
            {step === workerConfirmStep && (
              <Grid container>
                <ContentDisplay label="お名前:" data={formData.full_name ? formData.full_name.trim() : ''} />
                <ContentDisplay label="表示名:" data={formData.display_name ? formData.display_name.trim() : ''} />
                <ContentDisplay label="性別:" data={formData?.gender} />
                <ContentDisplay label="生年月日:" data={formData?.birthday} />
                <ContentDisplay label="郵便番号:" data={formData?.postal_code} />
                <ContentDisplay label="都道府県:" data={formData?.prefecture} />
                <ContentDisplay label="市町村区:" data={formData?.city} />
                <ContentDisplay
                  label="町名番地:"
                  data={formData.address_line_1 ? formData.address_line_1.trim() : ''}
                />
                <ContentDisplay
                  label="以降の住所:"
                  data={formData.address_line_2 ? formData.address_line_2.trim() : ''}
                />
                <ContentDisplay label="電話番号:" data={formData?.phone_number} />
                <ContentDisplay label="本人確認（任意）:" data={formData?.id_card_filename} />
                <ContentDisplay
                  label="適格請求書発行事業者（任意):"
                  data={formData?.tekikaku_flag === 1 ? <CheckIcon /> : <CheckBoxBlankIcon />}
                />
                <ContentDisplay
                  label="新着作業メールを受け取る:"
                  data={formData?.receive_new_jobs_email === 1 ? <CheckIcon /> : <CheckBoxBlankIcon />}
                />
                {certificates && certificates.length !== 0 && (
                  <Grid item pt={10} xs={12} textAlign="center">
                    <SubHeading bold="true">所有資格</SubHeading>
                  </Grid>
                )}
                {Object.entries(certificatesByCategory).map((items, i) => {
                  return (
                    <Grid item xs={12} key={i} pt={4} textAlign="center">
                      <Label color="main" pb={2}>
                        {items[0]}:
                      </Label>
                      <CategoryDisplay items={items[1]} />
                    </Grid>
                  )
                })}
                {canDos && canDos.length !== 0 && (
                  <Grid item pt={10} xs={12} textAlign="center">
                    <SubHeading bold="true">対応作業</SubHeading>
                  </Grid>
                )}
                {Object.entries(canDosByCategory).map((items, i) => {
                  return (
                    <Grid item xs={12} key={i} pt={4} textAlign="center">
                      <CategoryDisplay items={items[1]} />
                    </Grid>
                  )
                })}
                <Grid item xs={12} pt={3}>
                  <SubHeading bold="true" color="secondary">
                    業務経験
                  </SubHeading>
                </Grid>
                <Grid item xs={12} gap={2}>
                  <pre>
                    <P className="WorkExperience-Text">
                      {formData.work_experience ? formData.work_experience.trim() : ''}
                    </P>
                  </pre>
                </Grid>
              </Grid>
            )}
            <Grid container pt={2} display="flex" justifyContent="end" gap={2}>
              {step === workerConfirmStep && (
                <>
                  <LargeButton
                    id="ConfirmBackBtn"
                    color="gray"
                    mode="btn1"
                    onClick={() => {
                      setStep(step - 1), props.handleSteps(step - 1, false)
                    }}
                  >
                    戻る
                  </LargeButton>
                  <LargeButton id="WorkerSubmitBtn" color="primary" onClick={handleSubmitWorkerForm1}>
                    {loader && <CircularProgress size="1.5rem" color="white" />}
                    {!loader && buttonLabel}
                  </LargeButton>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </StyledCreateWorkerInformationForm>
  )
}

CreateRegistrationForm.propTypes = {
  match: PropTypes.any,
  location: PropTypes.object,
  handleSteps: PropTypes.func,
}

export default CreateRegistrationForm
