import * as types from './actionTypes'
import { formulateCurrentPage, formulatePageResult } from '../../utils/helper'
import { thisMonthStart, thisMonthEnd } from '../../utils/common'
import { status } from 'utils/config/status'

const { under_edition, can_apply, close_application, completed } = status.job_group
const defaultJobGroupStatus = [under_edition, can_apply, close_application, completed]
const isClient = localStorage.getItem('mode') === 'client'

const initialState = {
  list: [],
  is_confirming: false,
  is_update: false,
  formData: {
    current: null,
    formatted: null,
    original: null,
  },
  selectedProject: null,
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 20,
    prevPageUrl: null,
    nextPageUrl: null,
    pageFrom: 0,
    pageTo: 0,
  },
  search: {
    project_id: '',
    name: '',
    project_name: '',
    client_name: '',
    prefecture: '',
    start_date: thisMonthStart,
    end_date: thisMonthEnd,
    status_id: defaultJobGroupStatus.toString(),
    limit: 20,
    page: 1,
    sort: 'desc',
    sortBy: isClient ? 'id' : 'registered_date',
  },
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SEARCH_JOB_GROUPS: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )
      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }
    case types.SEARCH_CLIENT_JOB_GROUPS: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )

      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }
    case types.SET_SEARCH_CRITERIA:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload.searchData,
        },
      }
    case types.SET_JOB_GROUP_FORM_DATA:
      return {
        ...state,
        formData: {
          ...payload,
          original: {
            ...state.formData?.original,
            ...payload?.original,
          },
        },
      }
    case types.SET_CONFIRMING_DATA:
      return {
        ...state,
        is_confirming: payload,
      }
    case types.SET_FORM_MODE:
      return {
        ...state,
        is_update: payload,
      }
    case types.RESET_CURRENT_FORM:
      return {
        ...state,
        formData: {
          ...initialState.formData,
        },
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default reducer
