import Http from 'utils/Http'
import * as types from './actionTypes'

const initialState = {
  isAuthenticated: false,
  signInFailed: false,
  isPending: false,
  token: null,
  error: {},
  user: null,
  memberType: '',
  registrationStatus: '',
  isPasswordUpdated: false,
  isUpdateFailed: false,
  passwordData: {},
}

function reducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case types.AUTH_SIGNIN:
      setToken(payload)

      return {
        ...state,
        isAuthenticated: true,
        isPending: false,
        signInFailed: false,
      }
    case types.AUTH_SIGNIN_PENDING:
      return { ...state, isPending: true, error: {}, signInFailed: false }
    case types.AUTH_SIGNIN_FAILED:
      return {
        ...state,
        isPending: false,
        error: payload,
        signInFailed: true,
      }
    // The following case clauses are wrapped into blocks using brackets to avoid eslint no-case-declarations
    case types.AUTH_CHECK: {
      const accessToken = localStorage.getItem('accessToken')
      const currentState = {
        ...state,
        isAuthenticated: !!accessToken,
      }

      if (currentState.isAuthenticated) {
        Http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      }

      return currentState
    }
    case types.AUTH_SET_USER:
      localStorage.setItem('authUser', JSON.stringify(payload))
      return {
        ...state,
        user: payload,
      }
    case types.AUTH_SIGNOUT:
      // clear tokens
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('workerId')
      localStorage.removeItem('authUser')
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      }
    case types.MEMBER_TYPE:
      return {
        ...state,
        memberType: payload,
      }

    case types.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        isPending: false,
        signInFailed: false,
      }
    case types.UPDATE_PASSWORD_SUCCESS:
      setToken(payload)
      return {
        ...state,
        token: payload,
        isAuthenticated: true,
        isPending: false,
        signInFailed: true,
      }
    case types.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isPending: false,
        signInFailed: true,
        error: payload.error,
      }
    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isPasswordUpdated: initialState.isPasswordUpdated,
        isUpdateFailed: initialState.isUpdateFailed,
        error: initialState.error,
      }
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordUpdated: true,
        isUpdateFailed: false,
      }
    case types.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isPasswordUpdated: false,
        isUpdateFailed: true,
      }
    case types.COMMON_ERROR:
      return {
        ...state,
        error: payload,
      }
    case types.SET_PASSWORD_DATA:
      return {
        ...state,
        passwordData: payload,
      }
    default:
      return state
  }
}

/**
 * Stores the access_token in local storage
 * and updates the HTTP header
 *
 * @param payload
 */
function setToken({ access_token, refresh_token }) {
  localStorage.setItem('accessToken', access_token)
  localStorage.setItem('refreshToken', refresh_token)

  Http.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
}

export default reducer
