import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import InnerHTML from 'dangerously-set-html-content'
import { confirmJobGroup, updateConfirmJobGroup } from 'services/jobGroups'
import { setConfirming, setFormMode, setJobGroupFormData } from 'store/job-groups/actionCreators'
import { useHistory } from 'react-router-dom'
import SuccessIcon from 'assets/svg/success.svg'
//utils
//componets
import { Label, Heading, SubHeading, LargeButton, Icon, Link, ContentDisplay, MinMaxDisplay } from 'components'

import PropTypes from 'prop-types'

const JobGroupConfirmForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const formData = useSelector((state) => state.jobGroups.formData)
  const loader = useSelector((state) => state.loader)
  const is_confirming = useSelector((state) => state.jobGroups.is_confirming)
  const { user } = useSelector((state) => state.auth)
  const isUpdate = useSelector((state) => state.jobGroups.is_update)
  const [confirmed, setConfirmed] = useState(false)
  const isClient = localStorage.getItem('mode').includes('client')

  const handleConfirm = async () => {
    if (isUpdate) {
      await dispatch(updateConfirmJobGroup(formData.original, formData.original.id)).then(() => {
        setConfirmed(true)
      })
    } else {
      await dispatch(confirmJobGroup(formData.original)).then(() => {
        setConfirmed(true)
      })
    }

    props.isConfirm(true)
  }

  useEffect(() => {
    if (!confirmed && (!is_confirming || !formData.formatted)) {
      history.push(`/${isClient ? 'job-group' : 'job_group'}/create`)
    }
    return () => {
      dispatch(setConfirming(false))
    }
  }, [dispatch])

  const handleReturn = () => {
    dispatch(setFormMode(true))
    isClient &&
      dispatch(
        setJobGroupFormData({
          ...formData,
          current: {
            client_id: { id: user?.client_user?.client_id, name: user?.client_user?.client_name },
            ...formData.current,
          },
        })
      )
    history.push({
      pathname: `/${isClient ? 'job-group' : 'job_group'}/create/`,
      state: { projectId: null, isReturned: true },
    })
  }

  return (
    <Grid container className={confirmed ? 'Confirmation' : ''}>
      {confirmed && (
        <Grid container justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} sm={10} pt={4}>
            <Icon source={SuccessIcon} />
          </Grid>
          <Grid item xs={12} sm={10} pt={3} pb={3} gap={4}>
            <SubHeading color="secondary">
              {!isUpdate ? '作業グループの登録が完了しました。' : '作業グループの編集が完了しました。'}
            </SubHeading>
          </Grid>
          {!isUpdate && (
            <Grid item xs={12} sm={isClient ? 11 : 10} pb={'16px'}>
              <Link to={`/${isClient ? 'job-group' : 'job_group'}/create/${formData?.original.id}`}>
                <LargeButton id="CopyJobGroupBtn" color="primary" mode="light" maxwidth="unset" fontSize={13}>
                  {!isClient
                    ? 'この作業グループをコピーして別の作業グループを作成'
                    : 'この作業グループをコピーして別の作業グループを登録する'}
                </LargeButton>
              </Link>
            </Grid>
          )}
          {!isClient && !isUpdate && (
            <Grid item xs={12} sm={10} pb={'28px'}>
              <Link to={'/job/create?job_group=' + formData?.original.id}>
                <LargeButton id="CopyJobBtn" color="primary" mode="light" maxwidth="unset">
                  {'この作業グループに作業を登録'}
                </LargeButton>
              </Link>
            </Grid>
          )}
          <Grid item xs={12} sm={10} pb={6}>
            <Link id="ConfirmedBackBtn" to={isClient ? '/job-group/list' : '/job_group_list'}>
              <u>{'作業グループ一覧'}</u>
            </Link>
          </Grid>
        </Grid>
      )}
      {!confirmed && (
        <Grid container>
          <Grid item xs={12}>
            <Heading>{isUpdate ? '作業グループ編集確認' : '作業グループ登録確認'}</Heading>
          </Grid>
          <Grid item xs={12} pb={3} pt={isClient ? 5 : 1}>
            <SubHeading>{isUpdate ? '以下の内容で更新しますか？' : '以下の内容で登録しますか？'}</SubHeading>
          </Grid>
          {!isClient && <ContentDisplay label="クライアント:" data={formData.current?.client_id?.name} />}
          <ContentDisplay label="案件名:" data={formData.formatted?.project_name} />
          <ContentDisplay label="作業グループ担当者:" data={formData.formatted?.client_user_name} />
          <ContentDisplay
            label="公開設定:"
            data={
              <>
                {formData.formatted?.visibility}
                {!isClient && isUpdate && formData.formatted?.close_for_application && (
                  <Label>{'✓ 募集を締め切る'}</Label>
                )}
              </>
            }
          />
          {isClient && (
            <ContentDisplay
              label="募集を締め切る:"
              data={formData.formatted?.close_for_application ? 'はい' : 'いいえ'}
            />
          )}
          <ContentDisplay label="作業グループ名:" data={formData.formatted?.name} />
          <ContentDisplay label="作業グループ開始日:" data={formData.formatted?.start_date} />
          <ContentDisplay label="作業グループ終了日:" data={formData.formatted?.end_date} />
          <ContentDisplay label="作業実施都道府県:" data={formData.formatted?.prefecture} />
          <MinMaxDisplay
            label="基本作業費(1日あたり):"
            min_data={formData.formatted?.min_fee}
            max_data={formData.formatted?.max_fee}
          />
          <ContentDisplay label="募集人数:" data={formData.formatted?.no_of_pos} />
          <ContentDisplay label="応募問い合わせ用メールアドレス:" data={formData.formatted?.email} />
          <ContentDisplay
            label="作業内容"
            data={<InnerHTML className="InnerHtml" html={formData.formatted?.task_details} />}
            blockStyle
          />
          <ContentDisplay
            label="作業費"
            data={<InnerHTML className="InnerHtml" html={formData.formatted?.fee_details} />}
            blockStyle
          />
          <ContentDisplay
            label="その他手当"
            data={<InnerHTML className="InnerHtml" html={formData.formatted?.allowance_details} />}
            blockStyle
          />
          <Grid item container xs={12} pt={6} justifyContent={{ xs: 'center', md: 'end' }} gap={2}>
            <LargeButton id="ConfirmedBackBtn" color="gray" mode="btn1" onClick={handleReturn}>
              {'戻る'}
            </LargeButton>
            <LargeButton id="JobGroupConfirmBtn" color="primary" onClick={handleConfirm}>
              {loader && <CircularProgress size="1.5rem" color="white" />}
              {!loader && '確定'}
            </LargeButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

JobGroupConfirmForm.displayName = 'Job Group Confirmation Form'

JobGroupConfirmForm.propTypes = {
  history: PropTypes.object,
  isConfirm: PropTypes.func,
}

export default JobGroupConfirmForm
