import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'

const StyledIcon = styled('img', {
  shouldForwardProp: (prop) => prop,
})(({ width, height }) => ({
  width: width,
  height: height,
  '& .cursor-pointer': {
    cursor: 'pointer',
  },
}))

const Icon = React.forwardRef((props, ref) => (
  <StyledIcon
    id={props.id}
    height={props.height}
    width={props.width}
    className={clsx(props.className)}
    src={props.source}
    onClick={props.onClick ? (e) => props.onClick(e) : () => {}}
    ref={ref}
  />
))

Icon.displayName = 'Icon'

Icon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  source: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
}

export default Icon
