import Http from 'utils/Http'
import * as dayjs from 'dayjs'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  actionSearchResults,
  fetchingDataFail,
  actionSetSearchCriteria,
  actionSetResultOptions,
} from 'store/results/actionCreators'

const FileDownload = require('js-file-download')

export function searchResult(searchParameters) {
  const {
    filter_by1,
    filter_txt1,
    filter_by2,
    filter_txt2,
    start_date,
    end_date,
    limit,
    page,
    sort,
    sortBy,
    client_id,
  } = searchParameters
  return async (dispatch) => {
    dispatch(showLoader())
    return await Http.get('op-center/tabulations', {
      params: {
        filter_by1,
        filter_by2,
        filter_txt1,
        filter_txt2,
        start_date,
        end_date,
        sortBy,
        sort,
        page,
        limit,
        client_id,
      },
    })
      .then((response) => {
        dispatch(actionSearchResults(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function resultsOptions() {
  return async (dispatch) => {
    dispatch(showLoader())
    return await Http.get('op-center/tabulations/options')
      .then((response) => {
        dispatch(actionSetResultOptions(response.data))
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function downloadCSV(searchParameters) {
  const {
    filter_by1,
    filter_txt1,
    filter_by2,
    filter_txt2,
    start_date,
    end_date,
    limit,
    page,
    sort,
    sortBy,
    client_id,
  } = searchParameters
  return async (dispatch) => {
    return await Http.get(
      'op-center/tabulations/download',
      {
        params: {
          filter_by1,
          filter_by2,
          filter_txt1,
          filter_txt2,
          start_date,
          end_date,
          sortBy,
          sort,
          page,
          limit,
          client_id,
        },
      },
      {
        responseType: 'blob',
      }
    )
      .then((response) => {
        const currentDate = dayjs().format('YYYY-MM-DD').split('-')
        const blobData = new Blob(['\ufeff', response.data], { type: 'text/csv;charset=utf-8' })
        FileDownload(blobData, `tabulation_${currentDate[0]}_${currentDate[1]}_${currentDate[2]}.csv`)
      })
      .catch((error) => {
        dispatch(fetchingDataFail(error))
      })
  }
}

export function changeSearchCriteria(searchParameters) {
  return (dispatch) => {
    dispatch(actionSetSearchCriteria(searchParameters))
  }
}
