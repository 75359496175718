export const AUTH_SIGNIN = 'AUTH_SIGNIN'
export const AUTH_SIGNIN_PENDING = 'AUTH_SIGNIN_PENDING'
export const AUTH_SIGNIN_FAILED = 'AUTH_SIGNIN_FAILED'

export const AUTH_CHECK = 'AUTH_CHECK'
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT'
export const AUTH_SET_USER = 'AUTH_SET_USER'

export const MEMBER_TYPE = 'MEMBER_TYPE'

// worker
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const COMMON_ERROR = 'COMMON_ERROR'

// client > profile > change password
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export const SET_PASSWORD_DATA = 'SET_PASSWORD_DATA'
