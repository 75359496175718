import * as types from './actionTypes'

const initialState = {
  list: [],
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 10,
    prevPageUrl: null,
    nextPageUrl: null,
  },
  search: {
    keyword: '',
    limit: 10,
    page: 1,
    sort: 'asc',
    sortBy: 'last_name',
  },
  modalValues: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    avatar: null,
    status: {
      id: 0,
      name: '',
    },
    password: '',
  },
  userData: {
    full_name: '',
    phone_number: '',
    email: '',
    password: '',
    passwordConfirm: '',
    company_name: '',
    postal_code: '',
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    address_line_4: '',
    website: '',
    company_phone_number: '',
    company_email: '',
    token: '',
  },
  tokenData: {
    user_id: '',
    email_address: '',
  },
  confirmed: false,
  isSuccessful: false,
  isFailed: false,
  error: null,
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SET_MODAL_VALUES:
      return {
        ...state,
        modalValues: {
          ...state.modalValues,
          id: payload.id ?? state.modalValues.id,
          firstName: payload.first_name ?? state.modalValues.firstName,
          lastName: payload.last_name ?? state.modalValues.lastName,
          email: payload.email ?? state.modalValues.email,
          avatar: payload.avatar ?? state.modalValues.avatar,
          status: payload.status ?? state.modalValues.status,
          password: payload.password ?? state.modalValues.password,
        },
      }
    case types.CREATE_USER:
    case types.UPDATE_USER:
    case types.DELETE_USER:
      return state
    case types.CLEAR_MODAL_VALUES:
      return {
        ...state,
        modalValues: {
          ...initialState.modalValues,
        },
      }
    // The following case clauses are wrapped into blocks using brackets to avoid eslint no-case-declarations
    case types.SEARCH_USER: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)

      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: currentPage,
          lastPage: payload.meta.lastPage,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: currentPage,
        },
      }
    }
    case types.SET_SEARCH_CRITERIA:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      }
    case types.SET_USER_DATA:
      return {
        ...state,
        userData: payload,
      }
    case types.SET_INITIAL_STATE:
      return {
        ...initialState,
      }
    case types.SET_TOKEN_RESPONSE:
      return {
        ...state,
        tokenData: payload,
      }
    case types.SET_ERROR: {
      return {
        ...state,
        error: payload,
      }
    }
    case types.PENDING: {
      return {
        ...state,
        confirmed: initialState.confirmed,
        isSuccessful: initialState.isSuccessful,
        isFailed: initialState.isFailed,
        error: initialState.error,
      }
    }
    case types.CONFIRMED: {
      return {
        ...state,
        confirmed: true,
        isFailed: false,
      }
    }
    case types.SUCCESSFUL: {
      return {
        ...state,
        isSuccessful: true,
        isFailed: false,
      }
    }
    case types.FAILED: {
      return {
        ...state,
        error: [payload],
        isSuccessful: false,
        isFailed: true,
      }
    }
    default:
      return state
  }
}

function formulateCurrentPage(currentPage, totalCount, pageSize) {
  let totalPages = Math.ceil(totalCount / pageSize)
  let newCurrentPage = currentPage

  if (totalPages === 0) {
    // There are no entries, set current page to 1.
    newCurrentPage = 1
  } else if (totalPages < currentPage) {
    // The current page is out of bounds, return last page instead
    newCurrentPage = totalPages
  }

  return newCurrentPage
}

export default reducer
