import * as types from './actionTypes'
import { formulateCurrentPage, formulatePageResult } from '../../utils/helper'

const initialState = {
  list: [],
  pageLoaded: [],
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 20,
    prevPageUrl: null,
    nextPageUrl: null,
    pageFrom: 0,
    pageTo: 0,
  },
  search: {
    mode: localStorage.getItem('mode'),
    keyword: '',
    prefecture: '',
    start_date: '',
    end_date: '',
    min_fee: '',
    max_fee: '',
    is_include: 0,
    limit: 18,
    page: 1,
    sort: 'desc',
    sortBy: 'registered_date',
    date_preset: '',
  },
  is_advance_search: false,
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SEARCH_WORK_JOB_GROUPS: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )
      return {
        ...state,
        pageLoaded: state.pageLoaded.includes(parseInt(currentPage))
          ? [...state.pageLoaded]
          : [...state.pageLoaded, parseInt(currentPage)],
        list:
          parseInt(currentPage) > 1
            ? !state.pageLoaded.includes(parseInt(currentPage))
              ? [...state.list, ...payload.data]
              : state.list
            : payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }
    case types.SEARCH_RESET: {
      return {
        ...state,
        list: [],
      }
    }
    case types.RESET_PAGE_LOADED: {
      return {
        ...state,
        pageLoaded: [],
      }
    }
    case types.SET_SEARCH_CRITERIA:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload.searchData,
        },
      }
    case types.RESET_CURRENT_FORM:
      return {
        ...state,
        formData: {
          ...initialState.formData,
        },
      }
    case types.SET_ADVANCE_SEARCH:
      return {
        ...state,
        is_advance_search: payload,
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default reducer
