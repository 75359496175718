import * as types from './actionTypes'
import { formulateCurrentPage, formulatePageResult } from '../../utils/helper'
import { status } from 'utils/config/status'

const initialState = {
  list: [],
  listMeta: {
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    pageSize: 20,
    prevPageUrl: null,
    nextPageUrl: null,
    pageFrom: 0,
    pageTo: 0,
  },
  search: {
    project: '',
    client: '',
    status: status.project.open,
    limit: 20,
    page: 1,
    sort: 'desc',
    sortBy: 'id',
  },
  status: [
    { id: 5, name: 'オープン' },
    { id: 6, name: 'クローズ' },
  ],
  modalValues: {
    id: 0,
    project: null,
    clients: [],
    client_id: 0,
    client_users: [],
    client_user_id: 0,
    status: {},
    jobgroup: false,
    copy_jg: false,
  },
  projectDetails: {},
  projectStatus: {
    canAbort: null,
  },
  error: {},
}

function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case types.SEARCH_PROJECTS: {
      const currentPage = formulateCurrentPage(payload.meta.currentPage, payload.meta.total, payload.meta.perPage)
      const { pageFrom, pageTo } = formulatePageResult(
        payload.meta.currentPage,
        payload.meta.total,
        payload.meta.perPage
      )
      return {
        ...state,
        list: payload.data,
        listMeta: {
          totalCount: payload.meta.total,
          currentPage: parseInt(currentPage),
          lastPage: payload.meta.total > 0 ? payload.meta.lastPage : 0,
          pagSize: payload.meta.perPage,
          prevPageUrl: payload.meta.previousPageUrl,
          nextPageUrl: payload.meta.nextPageUrl,
          pageFrom: pageFrom,
          pageTo: pageTo,
        },
        search: {
          ...state.search,
          limit: payload.meta.perPage,
          page: parseInt(currentPage),
        },
      }
    }
    case types.SET_SEARCH_CRITERIA:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      }
    case types.CREATE_PROJECT:
    case types.UPDATE_PROJECT: {
      return {
        ...state,
        projectDetails: payload.response.data,
      }
    }
    case types.GET_ACTIVE_CLIENTS:
      return {
        ...state,
        modalValues: {
          ...state.modalValues,
          clients: payload.data.map((item) => ({ id: item.id, name: item.name })),
        },
      }
    case types.GET_ALL_CLIENT_USERS:
      return {
        ...state,
        modalValues: {
          ...state.modalValues,
          client_users: payload.data.client_user.map((item) => ({ id: item.id, name: item.name })),
        },
      }
    case types.MODAL_VALUES: {
      return {
        ...state,
        modalValues: {
          ...state.modalValues,
          id: payload.id,
          project: payload.name,
          client_id: payload.client_id,
          client_user_id: payload.client_user_id,
          status: payload.status,
          status_id: payload.status_id,
          copy_jg: payload.copy_jg,
        },
      }
    }
    case types.CLEAR_MODAL_VALUES:
      return {
        ...state,
        modalValues: {
          ...initialState.modalValues,
          clients: state.modalValues.clients,
          client_users: state.modalValues.client_users,
        },
        projectStatus: {
          ...initialState.projectStatus,
        },
        error: {
          ...initialState.error,
        },
      }
    case types.FETCHING_DATA_FAILED:
      return {
        ...state,
        error: payload,
      }
    case types.PROJECT_STATUS:
      return {
        ...state,
        projectStatus: {
          canAbort: payload,
        },
      }
    case types.CLEAR_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: {
          ...initialState.projectDetails,
        },
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default reducer
