import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Table, { tableClasses } from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer, { tableContainerClasses } from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Toolbar from './Toolbar'
import Head from './Head'
import CircularProgress from '@mui/material/CircularProgress'
import { Popper, Paper, MenuList, MenuItem, Grid, ClickAwayListener } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Pagination, { paginationClasses } from '@mui/material/Pagination'
import { Label } from 'components'
import { styled } from '@mui/material/styles'
import { Button, Link, Icon } from 'components/atoms'
import RemoveIcon from 'assets/svg/delete.svg'
import DownloadIcon from 'assets/svg/download.svg'
import EditIcon from 'assets/svg/table-edit-action.svg'
import standards from 'theme/standards'
import { getMessages } from 'validation'

TableList.propTypes = {
  title: PropTypes.string,
  headCells: PropTypes.array,
  data: PropTypes.array,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  sort: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalCount: PropTypes.number,
  isLoading: PropTypes.bool,
  handlePageChange: PropTypes.func,
  handleSort: PropTypes.func,
  handleSelectedRow: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  action: PropTypes.bool,
  actionButton: PropTypes.bool,
  actionList: PropTypes.any,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
  noSort: PropTypes.bool,
  noPaginate: PropTypes.bool,
  isReportsActions: PropTypes.any,
  handleDownload: PropTypes.func,
  no_data_msg: PropTypes.string,
  tableName: PropTypes.any,
}

TableList.defaultProps = {
  pageSize: 10,
  currentPage: 1,
  sort: 'asc',
}

const StyledTable = styled('div')(({ theme }) => ({
  display: 'grid',
  [`& .${paginationClasses.root}`]: {
    '& .MuiPaginationItem-previousNext': {
      backgroundColor: '#f0f0f0',
      '&:hover': {
        backgroundColor: `${theme.palette['primary'].main}`,
      },
    },
    '& .MuiPaginationItem-page': {
      '&:hover': {
        backgroundColor: `${theme.palette['primary'].main}`,
      },
      '&.Mui-selected': {
        backgroundColor: 'unset',
        color: `${theme.palette['primary'].main}`,
      },
    },
  },
  [`& .${tableContainerClasses.root}`]: {
    borderRadius: '7px 7px 0px 0px',
    opacity: 1,
    border: '1px solid #efefef',
    [`& .${tableClasses.root}`]: {
      '& thead': {
        backgroundColor: `${theme.palette['gray'].dark}`,
        borderRadius: '7px 7px 0px 0px',
        opacity: 1,
        fontSize: standards.fontSize.default,
      },
      '& th': {
        color: `${theme.palette.white}`,
        fontSize: standards.fontSize.default,
        wordBreak: 'keep-all',
        '& .MuiTableSortLabel-root': {
          color: `${theme.palette.white}`,
          '&:hover': {
            color: `${theme.palette.white}`,
          },
          '&:after': {
            color: `${theme.palette.white}`,
          },
        },
        '& .MuiTableSortLabel-icon': {
          fill: `${theme.palette.white}`,
          opacity: 'unset',
        },
        '&.ActionCell': {
          maxWidth: 50,
        },
        '&.th-col-1': {
          minWidth: 200,
        },
        '&.th-col-2': {
          minWidth: 150,
        },
        '&.th-col-3': {
          minWidth: 100,
        },
        '&.th-col-4': {
          width: 50,
          minWidth: 'fit-content',
          wordBreak: 'keep-all !important',
          whiteSpace: 'nowrap !important',
        },
      },
      '& td': {
        border: '1px solid #efefef',
        padding: '6px 10px 6px 10px',
        fontSize: standards.fontSize.default,
        color: `${theme.palette.black}`,
        opacity: 1,
        '&.reportActions div': {
          minWidth: 'max-content',
        },
        '&.td-col-4': {
          wordBreak: 'keep-all !important',
          width: 'fit-content',
          minWidth: 'fit-content',
          maxWidth: 'fit-content',
          whiteSpace: 'nowrap !important',
        },
      },
      '& tr': {
        height: 42,
      },
    },
  },
  '& .table-cell-action': {
    cursor: 'pointer',
    '& .actionButton': {
      wordBreak: 'keep-all',
      padding: theme.standards.padding.button,
      minWidth: 'max-content',
    },
  },
}))

function TableList(props) {
  const {
    tableName,
    action,
    headCells,
    data,
    pageSize,
    currentPage,
    totalPage,
    handlePageChange,
    totalCount,
    isLoading,
    sort,
    handleSort,
    handleSelectedRow,
    actionList,
    actionButton,
    pageFrom,
    pageTo,
    noSort,
    noPaginate,
    isReportsActions,
    handleDownload,
    handleEdit,
    handleDelete,
    no_data_msg,
  } = props
  const sortBy = props.sortBy || 'id'
  // Selected rows
  const [selected, setSelected] = useState([])
  // Minus 1 for the current page because pagination component is zero based
  const page = currentPage

  useEffect(() => {
    setTableColumnWidth()

    window.addEventListener('resize', setTableColumnWidth)
    return () => {
      window.removeEventListener('resize', setTableColumnWidth)
    }
  })

  function setTableColumnWidth() {
    let tableBody = document.getElementById('table-id').offsetWidth
    if (tableBody < 900) {
      tableBody = 900
    }
    let col1 = document.querySelectorAll('.th-col-1')
    let col2 = document.querySelectorAll('.th-col-2')
    let col3 = document.querySelectorAll('.th-col-3')
    let col4 = document.querySelectorAll('.th-col-4')
    let initialSpace = 0
    const fullPct = 100
    const triquarterPct = 75
    const halfPct = 50
    col4.forEach(function (col4) {
      initialSpace += col4.offsetWidth
    })

    //get total space left from all column 4 attrinutes
    let availableSpace = tableBody - initialSpace
    let availableColSpace = availableSpace / (col1.length + col2.length + col3.length)

    //col1 initial width
    const col1TotalWidth = availableColSpace
    const col2TotalWidth = (triquarterPct / fullPct) * availableColSpace
    const col3TotalWidth = (halfPct / fullPct) * availableColSpace

    //base width
    const baseWidth = col1TotalWidth * col1.length + col2TotalWidth * col2.length + col3TotalWidth * col3.length

    //get ratio percent base on initial width
    const col1Ratio = (col1TotalWidth / baseWidth) * fullPct
    const col2Ratio = (col2TotalWidth / baseWidth) * fullPct
    const col3Ratio = (col3TotalWidth / baseWidth) * fullPct

    //set computed ratio width
    const col1Width = (col1Ratio / fullPct) * availableSpace
    const col2Width = (col2Ratio / fullPct) * availableSpace
    const col3Width = (col3Ratio / fullPct) * availableSpace

    //set ratio width
    setColWidth(col1, col1Width)
    setColWidth(col2, col2Width)
    setColWidth(col3, col3Width)
  }

  const setColWidth = (cols, availableColSpace) => {
    if (cols?.length > 0) {
      cols.forEach(function (col) {
        //col.style.width = availableColSpace + 'px'
        col.style.width = availableColSpace + 'px'
        col.style.maxWidth = availableColSpace + 'px'
      })
    }
  }

  const handleRequestSort = (event, property) => {
    setSelected([])
    const isAsc = sortBy === property && sort === 'asc'
    handleSort(isAsc ? 'desc' : 'asc', property)
  }
  const handleChangePage = (event, newPage) => {
    setSelected([])
    handlePageChange(newPage)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let selection = data.map((n) => n.id)
      setSelected(selection)
      return
    }

    setSelected([])
  }
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [currentOpenPopper, setcurrentOpenPopper] = useState(null)
  const handleActionOpen = (event, row) => {
    handleSelectedRow(row)
    if (currentOpenPopper && currentOpenPopper == row.id) {
      setAnchorEl(null)
      setcurrentOpenPopper(null)
      return
    }
    setAnchorEl(event.currentTarget)
    setcurrentOpenPopper(row.id)
  }

  const handlePopperClose = () => {
    setAnchorEl(null)
  }
  const actionOpen = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const mode = localStorage.getItem('mode')
  const rulesMsg = getMessages('ja')
  const tableErrorMessage = mode === 'opc' || mode === 'client' ? rulesMsg.opc_client_empty_table : rulesMsg.empty_table

  return (
    <StyledTable>
      {!noPaginate && (
        <Toolbar
          selected={selected}
          isLoading={isLoading}
          totalCount={totalCount}
          pageFrom={pageFrom}
          pageTo={pageTo}
        />
      )}
      {action && (
        <Popper id={id} open={actionOpen} anchorEl={anchorEl} placement={'top-start'}>
          <ClickAwayListener onClickAway={handlePopperClose}>
            <Paper>
              <MenuList onClick={handlePopperClose}>
                {actionList.map((item, index) => (
                  <MenuItem id={'menuItem' + index + '-ID'} key={index} onClick={item.handler}>
                    <Label className="cursor-pointer">{item.name}</Label>
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
      <TableContainer className="table__root" id="table-id">
        <Table aria-labelledby="tableTitle" size="medium" aria-label="table list">
          <Head
            tableName={tableName}
            numSelected={selected.length}
            order={sort}
            orderBy={sortBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={totalCount}
            headCells={headCells}
            isLoading={isLoading}
            action={action || (isReportsActions ? true : false) || false}
            noSort={noSort || false}
          />
          <TableBody id="table-body-id">
            {!isLoading &&
              data.map((row, index) => {
                const labelId = `table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    className="results"
                  >
                    {createCells(headCells, row, labelId)}
                    {action && (
                      <TableCell align={'center'} className="table-cell-action">
                        {actionButton && !row?.hideAction && (
                          <Grid container spacing={1} display="flex" justifyContent="center">
                            {actionList.map((item, index) => (
                              <Grid item key={index}>
                                <Link to={`${item.name}/${row.id}`}>
                                  <Button
                                    className="actionButton"
                                    variant={'outlined'}
                                    color={item.color}
                                    {...(item?.mode && { mode: item?.mode })}
                                    height={item?.height ? item?.height : 30}
                                    width={item?.width ? item?.width : 47}
                                    fontSize={12}
                                    padding={0}
                                  >
                                    {item.label}
                                  </Button>
                                </Link>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                        {!actionButton && <MoreHorizIcon id={id} onClick={(e) => handleActionOpen(e, row)} />}
                      </TableCell>
                    )}
                    {isReportsActions && (
                      <TableCell align={'center'} className="reportActions">
                        <Grid container spacing={1} display="flex" justifyContent="center">
                          {isReportsActions?.download && (row.filename || row.file_name) && (
                            <Grid item>
                              <Icon
                                source={DownloadIcon}
                                id={'tableDownload' + index}
                                onClick={handleDownload ? (e) => handleDownload(e, row) : () => {}}
                                className="table-cell-action"
                              />
                              &nbsp;
                            </Grid>
                          )}
                          {isReportsActions?.edit && (
                            <Grid item>
                              <Icon
                                className="table-cell-action"
                                source={EditIcon}
                                id={'tableEdit' + index}
                                onClick={handleEdit ? (e) => handleEdit(e, row) : () => {}}
                              />
                              &nbsp;
                            </Grid>
                          )}
                          {isReportsActions?.delete && (
                            <Grid item>
                              <Icon
                                className="table-cell-action"
                                source={RemoveIcon}
                                id={'tableDelete' + index}
                                onClick={handleDelete ? (e) => handleDelete(e, row) : () => {}}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            {isLoading && (
              <TableRow style={{ height: 42 * pageSize }} align={'center'}>
                <TableCell align={'center'} colSpan={headCells.length + 1}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {data.length < 1 && !isLoading && (
              <TableRow style={{ height: 42 * pageSize }} align={'center'}>
                <TableCell align={'center'} colSpan={headCells.length + 1}>
                  {no_data_msg ? no_data_msg : tableErrorMessage}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!noPaginate && (
        <Grid pt={2} display="flex" justifyContent="flex-end" alignItems="right">
          {!isLoading && (
            <Pagination
              size="large"
              count={totalPage}
              page={page}
              siblingCount={0}
              onChange={isLoading ? () => {} : handleChangePage}
            />
          )}
        </Grid>
      )}
    </StyledTable>
  )
}

function createCells(headCells, rowData) {
  let cells = []
  // Iterate thru the headCells, this will be the basis for the display of data
  for (let i = 0; i < headCells.length; i++) {
    const headCell = headCells[i]

    // Process only shown columns
    if (headCell.hide !== true) {
      // Default display value is base from the column ID
      let value = rowData[headCell.id]

      // Value is an object, we need to get the specific value for display
      if (headCell.display !== undefined && typeof value === 'object') {
        // Iterate to all given indeces and value to be the value of the last index
        for (let ii = 0; ii < headCell.display.length; ii++) {
          value = value[headCell.display[ii]]
        }
      }

      cells.push(
        <TableCell
          key={`${headCell.id}-${rowData.id}`}
          align={headCell.colAlign || 'left'}
          className={`td-${headCell?.className}`}
        >
          {value}
        </TableCell>
      )
    }
  }

  return cells
}

export default TableList
