import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { Icon, Heading, SubHeading, LargeButton } from 'components'
import ForgotPaswordIcon from 'assets/svg/ForgotPaswordIcon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { signOutUser } from 'services/auth'
function ChangePasswordFormSuccess() {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const role = auth?.user?.role.name
  const [btnColor, setBtnColor] = useState('yellow')
  const [btnMode, setBtnMode] = useState('light')
  const [subHeaderColor, setubHeaderColor] = useState('primary')

  const handleSignOut = () => {
    dispatch(signOutUser())
  }

  useEffect(() => {
    if (role === 'Worker') {
      setBtnColor('primary')
      setBtnMode('main')
      setubHeaderColor('secondary')
    }
  }, [role])

  return (
    <Grid container py={'35px'} rowSpacing={2} justifyContent="center" alignItems="center" textAlign="center">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Icon source={ForgotPaswordIcon} />
      </Grid>
      {role !== 'Worker' && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Heading>{'パスワード変更完了'}</Heading>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SubHeading color={subHeaderColor}>{'パスワード変更が完了しました。'}</SubHeading>
      </Grid>
      <Grid item xs={12}>
        <LargeButton
          id="ChangePasswordConfirmBtn"
          color={btnColor}
          mode={btnMode}
          mt={20}
          onClick={() => handleSignOut()}
        >
          {'ログインに戻る'}
        </LargeButton>
      </Grid>
    </Grid>
  )
}

ChangePasswordFormSuccess.propTypes = {}

export default ChangePasswordFormSuccess
