import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

const StyledBorder = styled(Grid, { shouldForwardProp: (prop) => prop })(({ color }) => ({
  borderBottom: `1px solid ${color}`,
}))

const StyledContent = styled('span', { shouldForwardProp: (prop) => prop })(({ fontSize, pt, pb, pr, pl }) => ({
  paddingTop: pt,
  paddingBottom: pb,
  paddingRight: pr,
  paddingLeft: pl,
  fontSize: fontSize ?? '',
}))

function DividerLabel(props) {
  const { color, children } = props
  return (
    <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'}>
      <StyledBorder item color={color} xs={1} sm={2.2} />
      <Grid item xs={'auto'}>
        <StyledContent {...props}>{children}</StyledContent>
      </Grid>
      <StyledBorder item color={color} xs={1} sm={2.2} />
    </Grid>
  )
}

DividerLabel.defaultProps = {
  pt: 0,
  pb: 0,
  pr: 35,
  pl: 35,
  fontSize: 20,
  color: 'black',
}

DividerLabel.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
}

export default DividerLabel
