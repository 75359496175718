import React, { useState, useEffect } from 'react'
import { Grid, Paper } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import InnerHTML from 'dangerously-set-html-content'
import SuccessApplicationIcon from 'assets/svg/success_application.png'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { styled } from '@mui/material/styles'
import { setConfirming } from 'store/job-groups/actionCreators'
import { setPrivateRedirectRoute } from 'store/common/actionCreators'
//services
import { applyJobGroups } from 'services/jobGroups'
//componets
import {
  Heading,
  LargeButton,
  ContentDisplay,
  SubHeading,
  SmallBanner,
  Link,
  P,
  CardGray,
  CategoryDisplay,
  Icon,
  Caption,
} from 'components'
//utils
import { settings } from 'utils/config/status'
import { formatAddress } from 'utils/helper'

const StyledCategoryDisplay = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .MuiGrid-root.CategoryWrapper': {
    display: 'grid',
    gridTemplateRows: 'repeat(10, auto)',
    gridAutoFlow: 'column',
    gridAutoColumns: 'auto',
    gridTemplateColumns: '33%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    '& .MuiGrid-item': {
      maxWidth: '100% !important',
      textAlign: 'left',
    },
  },
}))

const StyledWorkExperience = styled('pre')(() => ({
  whiteSpace: 'pre-wrap !important',
}))

const JobGroupApplicationConfirm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const formData = useSelector((state) => state.jobGroups.formData)
  const isConfirming = useSelector((state) => state.jobGroups.is_confirming)
  const isLoading = useSelector((state) => state.loader)
  const displayData =
    formData?.formatted && Object.keys(formData?.formatted).length > 0 ? formData?.formatted : formData?.original
  const skills = displayData?.worker?.skills ?? []
  const certificates = skills.filter((skill) => skill.skill_category_type === 'Qualification')
  const canDos = skills.filter((skill) => skill.skill_category_type === 'Skills')
  const [confirmed, setConfirmed] = useState(false)
  const [confirmationTitle, setConfirmationTitle] = useState('')

  useEffect(() => {
    if (!confirmed && (!isConfirming || !displayData)) {
      history.push('/job/search')
    }
    return () => {
      dispatch(setConfirming(false))
    }
  }, [dispatch])

  //reset private redirect route
  useEffect(() => {
    dispatch(setPrivateRedirectRoute(null))
  }, [])

  //set Page Title
  useEffect(() => {
    if (!isEmpty(displayData)) props.handleData(displayData)
    return
  }, [dispatch, displayData, isConfirming])

  //set Confirmation Title
  useEffect(() => {
    if (displayData?.title) {
      setConfirmationTitle(displayData?.title)
    }
  }, [displayData?.title])

  //confirm application
  const submitApplication = async () => {
    await dispatch(applyJobGroups(displayData?.id, displayData?.worker?.id)).then(() => {
      dispatch(setConfirming(false))
      setConfirmed(true)
    })
  }

  //handle update worker info
  const updateWorkerInfo = () => {
    dispatch(setPrivateRedirectRoute(props?.location?.pathname ?? null))
  }

  //get title with banner UI
  const getTitleWithBanner = () => {
    return (
      <React.Fragment>
        {displayData?.title + '  '}
        {displayData?.visibility == settings.limited_public && (
          <SmallBanner label={displayData?.visibility} color="warning" width="fit-content" />
        )}
      </React.Fragment>
    )
  }

  //get category UI
  const getCategory = (title, data) => {
    return (
      <Grid item container my={3}>
        <Grid item xs={12} textAlign="center">
          <SubHeading bold="true" color="secondary">
            {title}
          </SubHeading>
        </Grid>
        <StyledCategoryDisplay item xs={12} textAlign="left">
          <CategoryDisplay items={data} bullets={true} />
        </StyledCategoryDisplay>
      </Grid>
    )
  }

  return (
    <Grid className={confirmed ? 'Confirmation' : ''}>
      {confirmed && (
        <Grid container justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} sm={10} pt={4}>
            <Icon source={SuccessApplicationIcon} />
          </Grid>
          <Grid item xs={12} sm={10} pt={3} pb={3} gap={4}>
            <SubHeading color="secondary">応募完了</SubHeading>
          </Grid>
          <Grid item xs={12} pb={2} textAlign="center">
            <P>{confirmationTitle} への応募が完了しました。</P>
            <P>クライアントからのオファー連絡をお待ち下さい。</P>
          </Grid>
        </Grid>
      )}
      {!confirmed && (
        <Grid container>
          <Grid item xs={12}>
            <Heading>応募確認</Heading>
          </Grid>
          <Grid item xs={12} mt={3}>
            <SubHeading pr={1}>以下の内容で応募しますか？</SubHeading>
          </Grid>
          <Grid item container xs={12} px={4}>
            <ContentDisplay
              label="氏名:"
              data={
                <>
                  {displayData?.worker?.name}
                  <Caption>※マッチングが成立するとクライアントに開示されます。</Caption>
                </>
              }
            />

            <ContentDisplay label="表示名:" data={displayData?.worker?.display_name} />
            <ContentDisplay label="性別:" data={displayData?.worker?.gender} />
            <ContentDisplay label="年齢:" data={displayData?.worker?.age + '歳'} />
            <ContentDisplay label="住所:" data={formatAddress(displayData?.worker)} />
            <ContentDisplay label="メールアドレス:" data={displayData?.worker?.email} />
            <ContentDisplay
              label="電話番号:"
              data={
                <>
                  {displayData?.worker?.phone_number}
                  <Caption>※マッチングが成立するとクライアントに開示されます。</Caption>
                </>
              }
            />
          </Grid>
          <Grid item container xs={12} pt={6} className="CategoryWrapper">
            {certificates.length !== 0 && getCategory('所有資格', certificates)}
            {canDos.length !== 0 && getCategory('対応可能作業', canDos)}
            <Grid item container mt={3}>
              <Grid item xs={12} textAlign="center">
                <SubHeading bold="true" color="secondary">
                  業務経験
                </SubHeading>
              </Grid>
              <Grid item xs={12}>
                <Paper variant="outlined" p={2}>
                  <Grid container px={5} py={2}>
                    <StyledWorkExperience>
                      <P>{displayData?.worker?.experience}</P>
                    </StyledWorkExperience>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="end" mt={1} mb={4}>
            <Link id="WorkerInfoLink-ID" to="/worker/update" onClick={() => updateWorkerInfo()}>
              <u>ワーカー情報を変更する</u>
            </Link>
          </Grid>
          <CardGray>
            <Grid container justifyContent="space-between" px={3} py={4}>
              <ContentDisplay label="応募作業:" data={getTitleWithBanner()} />
              <ContentDisplay label="クライアント:" data={displayData?.client_name} />
              <ContentDisplay label="作業日:" data={displayData?.date_range} />
              <ContentDisplay label="作業実施都道府県:" data={displayData?.prefecture} />
              <ContentDisplay label="基本作業費（税抜き）:" data={displayData?.fee_range} />
              <ContentDisplay label="募集人数:" data={displayData?.no_of_position} />
              <ContentDisplay
                label="作業内容:"
                data={<InnerHTML className="InnerHtml" html={displayData?.details} />}
              />
              <ContentDisplay label="作業費:" data={<InnerHTML className="InnerHtml" html={displayData?.fees} />} />
              <ContentDisplay
                label="その他手当:"
                data={<InnerHTML className="InnerHtml" html={displayData?.allowances} />}
              />
            </Grid>
          </CardGray>
          <Grid
            item
            container
            my={2}
            spacing={2}
            justifyContent={{ xs: 'center', sm: 'end' }}
            display={{ xs: 'block', sm: 'inhiret' }}
          >
            <Grid item>
              <LargeButton
                id="JobGroupBackBtn-ID"
                color="gray"
                mode="btn1"
                onClick={() => history.push(`/job-group/show/${displayData?.id}`)}
              >
                戻る
              </LargeButton>
            </Grid>
            <Grid item>
              <LargeButton
                id="JobGroupConfirmApplyBtn-ID"
                color="primary"
                onClick={() => {
                  submitApplication(displayData?.id)
                }}
                disabled={isLoading}
              >
                応募する
              </LargeButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

JobGroupApplicationConfirm.displayName = 'Job Group Application Confirm'

JobGroupApplicationConfirm.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
  handleData: PropTypes.func,
}

export default JobGroupApplicationConfirm
