import { createTheme } from '@mui/material'
import palette from './palette'
import typography from './typography'
import standards from './standards'
import components from './overrides'

const theme = createTheme({
  palette,
  typography,
  standards,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1920,
    },
  },
  components,
})

export default theme
