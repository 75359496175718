import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { matchPath } from 'react-router'

export function useMatchLastLocation(path) {
  const location = useSelector((state) => state.common.locations[1])

  return !!useMemo(() => location && matchPath(location.pathname, { path }), [location, path])
}

export function useControlledMultiSelectFormField({ register, watch, name }) {
  const ref = register(name)
  ref.value = watch(name)
  const oldOnChange = ref.onChange
  ref.onChange = (e) => {
    if (e instanceof PointerEvent) {
      oldOnChange(e)
    }
  }

  return ref
}

export function useControlledInputFormField({ register, watch, name, rules }) {
  const ref = register(name, rules || [])
  ref.value = watch(name)
  const oldOnChange = ref.onChange
  ref.onChange = (e) => {
    if (e.type == 'change') {
      oldOnChange(e)
    }
  }

  return ref
}
