export const SEARCH_PROJECTS = 'SEARCH_PROJECTS'
export const SET_SEARCH_CRITERIA = 'SET_SEARCH_PROJECTS'
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const GET_ACTIVE_CLIENTS = 'GET_ALL_CLIENTS'
export const GET_ALL_CLIENT_USERS = 'GET_ALL_CLIENT_USERS'
export const MODAL_VALUES = 'MODAL_VALUES'
export const CLEAR_MODAL_VALUES = 'CLEAR_MODAL_VALUES'
export const FETCHING_DATA_FAILED = 'FETCHING_DATA_FAILED'
export const PROJECT_STATUS = 'PROJECT_STATUS'
export const CLEAR_PROJECT_DETAILS = 'CLEAR_PROJECT_DETAILS'
