import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  actionSearchWorkers,
  actionSetSearchCriteria,
  actionGetWorkerDetailsSuccess,
  actionGetWorkerDetailsFailed,
  setWorkersFormData,
  resetCurrentForm,
  actionRegisterWorkerEmailSuccess,
  actionRegisterWorkerEmailFailed,
  getWorkerJobListRequest,
  getWorkerJobListSuccess,
  getWorkerJobListFailure,
} from 'store/workers/actionCreators'

export function searchWorkers(name, screenName, id, emailAddress, phoneNumber, memberType, page, limit, sort, sortBy) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('op-center/workers', {
      params: {
        name,
        screen_name: screenName,
        id,
        email_address: emailAddress,
        phone_number: phoneNumber,
        member_type: memberType,
        page,
        limit,
        sort,
        sortBy,
      },
    })
      .then((response) => {
        dispatch(actionSearchWorkers(response.data))
      })
      .catch((error) => {
        console.log(error)
        // TODO Handle error throw a snackbar, alert, toast, or something
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

const SUCCESS = 200

export function changeSearchCriteria(
  name,
  screenName,
  id,
  emailAddress,
  phoneNumber,
  memberType,
  page,
  limit,
  sort,
  sortBy
) {
  return (dispatch) => {
    dispatch(
      actionSetSearchCriteria(name, screenName, id, emailAddress, phoneNumber, memberType, page, limit, sort, sortBy)
    )
  }
}

export function getWorker(id) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get(`op-center/workers/${id}`)
      .then((response) => {
        const fileNameRegex = /^.*[\\/]/
        let formData = []
        formData['original'] = {
          ...response.data.data,
          skills: response.data.data?.skills.map((skill) => skill.skill_id),
          full_name: response.data.data?.name,
          id_card_filename: response.data.data?.id_card_url?.replace(fileNameRegex, '') ?? '',
        }
        dispatch(setWorkersFormData(formData))
        dispatch(actionGetWorkerDetailsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(actionGetWorkerDetailsFailed(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function confirmWorkerData(data, id) {
  let formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    if (key == 'skills') {
      data.skills.map((val) => {
        formData.append(`${key}[]`, val)
      })
    } else {
      formData.append(key, value)
    }
  }
  let url = id == null ? 'op-center/workers/confirm' : 'op-center/workers/confirmUpdate/' + id
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }).finally(() => {
      dispatch(hideLoader())
    })
  }
}

export function confirmCreateUpdateWorker(data, id) {
  let formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    if (key == 'skills') {
      data.skills.map((val) => {
        formData.append(`${key}[]`, val)
      })
    } else {
      formData.append(key, value)
    }
  }
  let url = id == null ? 'op-center/workers/' : 'op-center/workers/' + id
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(() => {
        dispatch(resetCurrentForm())
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function registerWorkerEmail(email) {
  return () => {
    return Http.post(`register/worker`, email)
  }
}

export function updateWorkerPassword(data) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post(`update/password`, data)
      .then((response) => {
        dispatch(actionRegisterWorkerEmailSuccess(response.data))
      })
      .catch((error) => {
        dispatch(actionRegisterWorkerEmailFailed(error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function saveWorkerInformation(userId, data) {
  let formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    if (key == 'skills') {
      data.skills.map((val) => {
        formData.append(`${key}[]`, val)
      })
    } else {
      formData.append(key, value)
    }
  }

  return (dispatch) => {
    dispatch(showLoader())
    return Http.post(`worker/save-information/${userId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}

export function getWorkerJobList(id) {
  return (dispatch) => {
    dispatch(showLoader())
    dispatch(getWorkerJobListRequest())
    return Http.get(`worker/${id}/jobs`)
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(getWorkerJobListSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(getWorkerJobListFailure(error.response.data.error))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}
