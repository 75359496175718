import { status } from 'utils/config/status'
import * as types from './actionTypes'

export function actionSearchJob(jobList) {
  return {
    type: types.SEARCH_JOB,
    payload: jobList,
  }
}

export function actionSearchOffer(offerList) {
  return {
    type: types.SEARCH_OFFER,
    payload: offerList,
  }
}

export function actionSetOfferSearchCriteria(searchParameters) {
  return {
    type: types.SET_SEARCH_OFFER_CRITERIA,
    payload: searchParameters,
  }
}

export function actionSetSearchCriteria(searchParameters) {
  return {
    type: types.SET_JOB_SEARCH_CRITERIA,
    payload: searchParameters,
  }
}

export function actionSetJobStatusDefault(jobStatus) {
  return {
    type: types.SET_JOB_STATUS_DEFAULT,
    payload: jobStatus,
  }
}

export function setJobFormData(formData) {
  return {
    type: types.SET_JOB_FORM_DATA,
    payload: formData,
  }
}

export function getWorkerJobList(formData) {
  return {
    type: types.GET_WORKER_JOB_LIST,
    payload: formData,
  }
}

export function setConfirming(confirming) {
  return {
    type: types.SET_CONFIRMING_DATA,
    payload: confirming,
  }
}

export function setFormErrors(errors) {
  return {
    type: types.SET_FORM_ERRORS,
    payload: errors,
  }
}

export function setFormMode(isUpdate) {
  return {
    type: types.SET_FORM_MODE,
    payload: isUpdate,
  }
}

export function actionGetJobDetailsSuccess(data) {
  return {
    type: types.GET_JOB_DETAILS_SUCCESS,
    payload: data,
  }
}

export function actionGetJobDetailsFailed(error) {
  return {
    type: types.GET_JOB_DETAILS_FAILED,
    payload: { error },
  }
}
export function fetchingDataFail(error) {
  return {
    type: types.FETCHING_DATA_FAILED,
    payload: error,
  }
}

export function setCancellationFee(data) {
  return {
    type: types.SET_CANCELLATION_FEE,
    payload: data,
  }
}

export function showModalLoader() {
  return {
    type: types.SHOW_MODAL_LOADER,
  }
}

export function hideModalLoader() {
  return {
    type: types.HIDE_MODAL_LOADER,
  }
}

export function resetCurrentForm() {
  return {
    type: types.RESET_CURRENT_FORM,
  }
}
export function setJobOutlineDetails(data) {
  return {
    type: types.SET_JOB_OUTLINE_DETAILS,
    payload: data,
  }
}

export function populateJobOutline(data) {
  return {
    type: types.POPULATE_JOB_OUTLINE,
    payload: data,
  }
}

export function actionCheckClockout(response) {
  return {
    type: types.CHECK_CLOCKOUT,
    payload: response?.data,
  }
}

export function actionSetReportStatus(data) {
  const { expense_status_id: expenseStatus, status_id: reportsStatus } = data
  const {
    wait_work_report_submit: pendingReport,
    wait_work_report_approve: pendingReportApproval,
    wait_expense_report_submit: pendingExpenseReport,
    wait_expense_report_approve: pendingExpenseApproval,
  } = status.job
  const { need_revision, accepted } = status.expense
  let reportStatus = ''

  if ([pendingReport, pendingReportApproval, pendingExpenseReport, pendingExpenseApproval].includes(reportsStatus)) {
    if ([pendingReport, pendingExpenseReport, pendingExpenseApproval].includes(expenseStatus)) {
      reportStatus = 'SUBMIT'
    } else if (expenseStatus === need_revision) {
      reportStatus = 'REJECT'
    } else if (expenseStatus === accepted) {
      reportStatus = 'ACCEPT'
    }
  }

  return {
    type: types.SET_REPORT_STATUS,
    payload: reportStatus,
  }
}

export function setOfferFormData(data) {
  return {
    type: types.SET_ACCEPT_REJECT_OFFER,
    payload: data,
  }
}

export function setOfferAction(mode) {
  return {
    type: types.SET_OFFER_ACTION,
    payload: mode,
  }
}

export function actionSearchClientJob(jobList) {
  return {
    type: types.SEARCH_CLIENT_JOB,
    payload: jobList,
  }
}

export function actionSetSearchClientCriteria(searchParameters) {
  return {
    type: types.SET_SEARCH_CLIENT_CRITERIA,
    payload: searchParameters,
  }
}

export function resetCurrentClientSearchForm() {
  return {
    type: types.RESET_CLIENT_SEARCH_PARAMS,
  }
}
