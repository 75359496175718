import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Heading, ScrollToTop, LargeButton, PrivacyPolicyContent } from 'components'

const StyledPrivacyPolicy = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

function PrivacyPolicy(props) {
  return (
    <Fragment>
      <StyledPrivacyPolicy container id={'Top-Page-Privacy-Policy'}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Heading>プライバシーポリシー</Heading>
        </Grid>
        <Grid item xs={12} mt={5}>
          <Divider sx={{ borderBottomWidth: 1, borderColor: 'gray' }} />
        </Grid>
        <PrivacyPolicyContent />
      </StyledPrivacyPolicy>
      <ScrollToTop {...props} topId={'#Top-Page-Privacy-Policy'} threshold={300}>
        <LargeButton maxwidth={180}>
          <KeyboardArrowUpIcon />
          {'トップへ戻る'}
        </LargeButton>
      </ScrollToTop>
    </Fragment>
  )
}

PrivacyPolicy.propTypes = {
  isSubcontent: PropTypes.bool,
}

export default PrivacyPolicy
