import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, CircularProgress } from '@mui/material'
import { Label, LargeButton, P } from 'components'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { abortProject } from 'services/projects'

const StyledForm = styled('form')(() => ({
  display: 'contents',
}))

function ProjectAbortModal(props) {
  const { onClose, selectedRow } = props
  const [isLoading, setIsLoading] = useState(false)
  const { error, projectStatus } = useSelector((state) => state.projects)
  const { canAbort } = projectStatus
  const { client, pic_name: picName, name } = selectedRow
  const isClient = localStorage.getItem('mode').includes('client')
  const hasError = Object.keys(error).length > 0
  const dispatch = useDispatch()

  useEffect(() => {
    if (canAbort !== null) Object.keys(canAbort).length > 0 && onClose()
  }, [canAbort, error])

  const handleAbortProject = () => {
    setIsLoading(true)
    dispatch(abortProject(selectedRow?.id)).then(() => {
      !error && setIsLoading(false)
    })
  }
  const modalMessage = !hasError ? (
    <Fragment>
      <Label>{'中断するとこの案件は参照できなくなります。'}</Label>
      <Label>{'中断すると元に戻せないため案件は再度作り直しになります。'}</Label>
    </Fragment>
  ) : (
    <Fragment>
      <Label color="error">{'関連する作業があるため中断できません。'}</Label>
      <Label color="error">{'先に作業グループの中断を行ってください。'}</Label>
    </Fragment>
  )

  const modalButtons = !hasError ? (
    <Grid container justifyContent="center" gap={2}>
      <Grid item xs={5} sm={4}>
        <LargeButton color={'gray'} onClick={onClose}>
          {'いいえ'}
        </LargeButton>
      </Grid>
      <Grid item xs={5} sm={4}>
        <LargeButton onClick={handleAbortProject}>
          {isLoading ? <CircularProgress size="14px" color="white" /> : 'はい'}
        </LargeButton>
      </Grid>
    </Grid>
  ) : (
    <Grid container justifyContent="center" gap={2}>
      <Grid item xs={5} sm={4}>
        <LargeButton color={'gray'} onClick={onClose}>
          {'閉じる'}
        </LargeButton>
      </Grid>
    </Grid>
  )

  return (
    <Grid container rowSpacing={6} justifyContent="center">
      {!hasError && (
        <Grid item pb={5} xs={12} md={12} lg={12} textAlign="center">
          <Label>{'この案件を中断しますか？'}</Label>
        </Grid>
      )}
      <StyledForm className="PojectListVerify-form">
        {!isClient && (
          <Fragment>
            <Grid item xs={5.25} textAlign="end" mt={hasError && 5}>
              <Label color="gray">{'クライアント:'}</Label>
            </Grid>
            <Grid item xs={6.75} pl={1} mt={hasError && 5}>
              <P>{client.name}</P>
            </Grid>
          </Fragment>
        )}
        <Grid item xs={5.25} textAlign="end">
          <Label color="gray">{'担当者:'}</Label>
        </Grid>
        <Grid item xs={6.75} pl={1}>
          <P>{picName}</P>
        </Grid>
        <Grid item xs={5.25} textAlign="end">
          <Label color="gray">{'案件名:'}</Label>
        </Grid>
        <Grid item xs={6.75} pl={1}>
          <P>{name}</P>
        </Grid>
      </StyledForm>
      <Grid item pb={5} xs={12} md={12} lg={12} textAlign="center">
        {modalMessage}
      </Grid>
      {modalButtons}
    </Grid>
  )
}

ProjectAbortModal.propTypes = {
  onClose: PropTypes.func,
  selectedRow: PropTypes.object,
}

export default ProjectAbortModal
