export default [
  {
    path: '/job/list',
    component: 'views/worker/Job/JobList',
    auth: true,
  },
  {
    path: '/job/show/:id',
    component: 'views/worker/Job/JobDetails',
    auth: true,
  },
  {
    path: '/worker/update',
    component: 'views/worker/WorkerBasicInformation/UpdateWorkerBasicInformation',
    auth: true,
  },
  {
    path: '/invoice_list',
    component: 'views/worker/Invoice/InvoiceList',
    auth: true,
  },
  {
    path: '/invoice/show/:workerId',
    component: 'views/worker/Invoice/InvoiceDetails',
    auth: true,
  },
  {
    path: '/register',
    component: 'views/worker/WorkerRegistration',
    mode: 'wkr',
  },
  {
    path: '/report/:jobId/:dropdownId',
    component: 'views/worker/Report/WorkerReport',
    auth: true,
  },
  {
    path: '/password-settings',
    component: 'views/update-password',
  },
  {
    path: '/worker-information',
    component: 'views/worker/WorkerBasicInformation/WorkerBasicInformation',
    auth: true,
  },
  {
    path: '/job/search',
    component: 'views/worker/JobSearch',
    auth: true,
  },
  {
    path: '/job-group/show/:id',
    component: 'views/worker/JobGroup/JobGroupDetails',
    auth: true,
  },
  {
    path: '/job-group/application/confirm',
    component: 'views/worker/JobGroup/JobGroupApplicationConfirm',
    auth: true,
  },
  {
    path: '/my-page',
    component: 'views/worker/WorkerMemberMyPageInformation',
    auth: true,
  },
  {
    path: '/password/change',
    component: 'views/common/ChangePassword',
    auth: true,
    mode: 'worker',
  },

  { path: '/offer/list', component: 'views/worker/Offer/OfferList', auth: true },
  {
    path: '/offer/show/:id',
    component: 'views/worker/Offer/OfferDetails',
    auth: true,
  },
  {
    path: '/offer/confirmation',
    component: 'views/worker/Offer/OfferConfirmation',
    auth: true,
  },
]
