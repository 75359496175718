import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

//components
import { Label, DatePicker } from 'components'

const StyledInputDatePicker = styled(Box)(({ theme }) => ({
  padding: `${theme.standards.padding.input.default}`,
  '& .InputLabel': {
    paddingBottom: `${theme.standards.padding.label.inputLabel}`,
  },
  '& .RequiredText': {
    display: 'inline-flex',
  },
}))

const InputDatePicker = React.forwardRef((props, ref) => {
  const excludedProps = ['label', 'labelProps', 'required']
  let defaultProps = omit(props, excludedProps)
  const { label, labelProps } = props

  return (
    <StyledInputDatePicker>
      <Label {...labelProps} className="InputLabel">
        {!props.label ? <>&nbsp;</> : label}
        {props.required && (
          <Label color="error" className="RequiredText">
            *
          </Label>
        )}
      </Label>
      <DatePicker {...defaultProps} ref={ref} />
    </StyledInputDatePicker>
  )
})

InputDatePicker.displayName = 'Input Date Picker'

InputDatePicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  labelProps: PropTypes.object,
  defaultValue: PropTypes.any,
  required: PropTypes.bool,
}

export default InputDatePicker
