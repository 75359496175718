import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { LargeButton } from 'components'

function JobCompletion(props) {
  const { data, onClose } = props

  return (
    <Grid container rowSpacing={6} justifyContent="center">
      <Grid item xs={12} md={12} lg={12}>
        <Grid container columnSpacing={1} justifyContent="center">
          {data}
        </Grid>
      </Grid>
      <Grid textAlign="center" item xs={6}>
        <LargeButton width={120} onClick={onClose} variant="contained">
          OK
        </LargeButton>
      </Grid>
    </Grid>
  )
}

JobCompletion.propTypes = {
  title: PropTypes.string,
  data: PropTypes.string,
  onClose: PropTypes.func,
}

export default JobCompletion
