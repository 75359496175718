import React from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
//store
import { useSelector } from 'react-redux'
//components
import { JobGroupCard, Select, LargeButton } from 'components'

const StyledJobSearchResults = styled(Grid)(({ theme }) => ({
  '& .JobSearchResults-Top-Btn': {
    '& .MuiSvgIcon-root': {
      marginTop: '-20px',
    },
    display: 'inline !important',
    minWidth: 'unset',
    maxWidth: 'unset',
    borderRadius: '50%',
    padding: 0,
    width: 63,
    height: 63,
    lineHeight: 0,
    paddingTop: -10,
    float: 'right',
    clear: 'both',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      width: '63px !important',
      float: 'unset',
    },
  },
}))

const JobSearchResults = (props) => {
  const history = useHistory()
  const results = useSelector((state) => state.jobSeeker)
  const isLoading = useSelector((state) => state.loader)
  const listMeta = useSelector((state) => state.jobSeeker.listMeta)
  const canLoadMore = parseInt(listMeta?.currentPage) === parseInt(listMeta?.lastPage) ? false : true
  const sortBy = [
    {
      id: 0,
      name: '新着順',
      sortBy: 'registered_date',
      sort: 'desc',
    },
    {
      id: 1,
      name: '更新順',
      sortBy: 'updated_date',
      sort: 'desc',
    },
    {
      id: 2,
      name: '作業費高い順',
      sortBy: 'max_fee',
      sort: 'desc',
    },
    {
      id: 3,
      name: '作業日近い順 ',
      sortBy: 'start_date',
      sort: 'asc',
    },
    {
      id: 4,
      name: '作業日遠い順',
      sortBy: 'end_date',
      sort: 'desc',
    },
  ]
  //open job search
  const openJob = (id) => {
    history.push('/job-group/show/' + id)
  }
  //scroll to top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  //load more items
  const loadMoreItems = () => {
    props.handlePageChange(results.listMeta.currentPage + 1)
  }

  const handleSort = (id) => {
    props.handleSort(sortBy[id]?.sort, sortBy[id]?.sortBy)
  }
  return (
    <StyledJobSearchResults container id="JobResultsWrapper">
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item xs={6} alignItems="center" display="inline-flex">
          検索結果：{results.listMeta.totalCount}件
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Select
            id="jobResults-sortDropdown-ID"
            name="jobResults-sortBy"
            menuItems={sortBy}
            defaultValue={0}
            onChange={handleSort}
          />
        </Grid>
      </Grid>
      {!isLoading && results.list.length <= 0 && (
        <Grid item xs={12} textAlign="center">
          該当する作業はありません
        </Grid>
      )}
      <Grid item container spacing={2} pt={4} justifyContent={isLoading && 'center'}>
        {results.list.length > 0 &&
          results.list.map((data, index) => {
            return (
              <Grid
                className="ResultCard"
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                key={index}
                onClick={() => {
                  openJob(data.id)
                }}
              >
                <JobGroupCard data={data} />
              </Grid>
            )
          })}
        {isLoading && (
          <Grid xs={12} item textAlign="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
      {!isLoading && results.list.length > 0 && (
        <Grid item container xs={12} pt={8} justifyContent="center">
          <Grid item>
            {canLoadMore && (
              <LargeButton
                id="LoadMoreBtn-ID"
                onClick={() => {
                  loadMoreItems()
                }}
              >
                もっと見る
              </LargeButton>
            )}
          </Grid>
          <Grid item xs={12} justifyContent="center" textAlign="center" mt={{ xs: 0, sm: -6 }}>
            <LargeButton
              fontSize={8}
              color="lightGray"
              mode="light"
              className="JobSearchResults-Top-Btn"
              id="JobSearchResults-Top-ID"
              onClick={() => {
                scrollToTop()
              }}
            >
              <ExpandLessIcon fontSize="large" /> <br /> ページ先頭へ
            </LargeButton>
          </Grid>
        </Grid>
      )}
    </StyledJobSearchResults>
  )
}

JobSearchResults.propTypes = {
  handlePageChange: PropTypes.func,
  handleSort: PropTypes.func,
}

export default JobSearchResults
