import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid } from '@mui/material'
import { Label, LargeButton, Alert, Checkbox } from 'components'
import { Info } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { cancelJob, resignJob, readJob } from 'services/jobs'
import { showNotification } from 'store/notification/actionCreators'

function JobProcessConfirmation(props) {
  const { data, onClose, completed, action } = props
  const dispatch = useDispatch()
  const cancellationFee = useSelector((state) => state.jobs.cancellationFee)
  const cancellationFeeFormatted = useSelector((state) => state.jobs.cancellationFeeFormatted)
  const isLoading = useSelector((state) => state.jobs.is_modal_loading)
  const [isChecked, setIsChecked] = useState(false)
  const jobDate = `${data.date} ${data.start_time}〜${data.end_time}`
  const isCancelJob = action === 'cancel'
  const isResignJob = action === 'resign'
  const title = isCancelJob ? 'この作業をキャンセルしますか？' : 'この作業を辞退しますか？'

  const handleClick = () => {
    setIsChecked(!isChecked)
  }

  const handleConfirm = () => {
    if (isCancelJob) {
      dispatch(cancelJob(data.id, cancellationFee))
        .then(() => {
          dispatch(readJob(data.id, false))
          onClose()
          completed(true)
        })
        .catch((error) => {
          if (error?.response.data && error?.response.data.code === 500) {
            dispatch(showNotification(error?.response.data.error, false))
          }
        })
    } else {
      dispatch(resignJob(data.id))
        .then(() => {
          dispatch(readJob(data.id, false))
          onClose()
          completed(true)
        })
        .catch((error) => {
          if (error?.response.data && error?.response.data.code === 500) {
            dispatch(showNotification(error?.response.data.error, false))
          }
        })
    }
  }

  return (
    <div>
      {isLoading && (
        <Grid xs={12} item textAlign="center">
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12} lg={12} textAlign="center" pb={2}>
            <Label>{title}</Label>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container columnSpacing={1} justifyContent="center">
              <Grid item xs={4} textAlign="end">
                <Label color="gray">クライアント名:</Label>
              </Grid>
              <Grid item xs={6} pl={1}>
                <Label>{data?.client_name}</Label>
              </Grid>
              <Grid item xs={4} textAlign="end">
                <Label color="gray">作業名:</Label>
              </Grid>
              <Grid item xs={6} pl={1}>
                <Label>{data?.job_name}</Label>
              </Grid>
              <Grid item xs={4} textAlign="end">
                <Label color="gray">作業日:</Label>
              </Grid>
              <Grid item xs={6} pl={1}>
                <Label>{jobDate}</Label>
              </Grid>
              <Grid item xs={4} textAlign="end">
                <Label color="gray">ワーカー:</Label>
              </Grid>
              <Grid item xs={6} pl={1}>
                <Label>{data?.worker_name}</Label>
              </Grid>
            </Grid>
            {isCancelJob && (
              <Grid container columnSpacing={1} justifyContent="center" mt={cancellationFee === 0 ? 2 : 0}>
                {cancellationFee > 0 && (
                  <Grid item xs="auto" pb={3} pt={3}>
                    <Alert icon={<Info />} severity="info">
                      キャンセル料が {cancellationFeeFormatted}発生します。
                    </Alert>
                  </Grid>
                )}
                <Grid item xs="auto">
                  <Checkbox
                    id="AcceptFeeBtn-ID"
                    onChange={handleClick}
                    label="事前にワーカーに周知している"
                    checked={isChecked}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            textAlign="center"
            justifyContent="center"
            gap={2}
            pt={isChecked || isResignJob ? 6 : 2}
            pb={isChecked || isResignJob ? 2 : 0}
          >
            <Grid item xs={4}>
              <LargeButton color={'gray'} onClick={onClose} variant="contained" className="cancel">
                いいえ
              </LargeButton>
            </Grid>
            <Grid item xs={4}>
              {isChecked || isResignJob ? (
                <LargeButton id="submitBtn-ID" type="submit" variant="contained" onClick={handleConfirm}>
                  はい
                </LargeButton>
              ) : (
                <LargeButton id="submitBtn-ID" color={'gray'} variant="contained" disabled>
                  はい
                </LargeButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

JobProcessConfirmation.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  completed: PropTypes.func,
  action: PropTypes.string,
}

export default JobProcessConfirmation
