import React from 'react'
import { Grid, Box, Stack, Divider, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined'
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { groupBy, formatAddress } from 'utils/helper'

//components
import {
  Label,
  Heading,
  SubHeading,
  Banner,
  BackArrow,
  Link,
  ContentDisplay,
  CategoryDisplay,
  P,
  SmallOutlinedTag,
  Caption,
} from 'components'

const StyledApplicationDetails = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .ApplicantOutline label': {
    [theme.breakpoints.down('sm')]: {
      display: 'inline-grid',
    },
  },
  '& .ApplicantOutline .MuiGrid-item': {
    paddingTop: '0px !important',
  },
  '& .DateTimeBanner  .MuiChip-label': {
    height: 'inherit',
    width: '100%',
  },
  '& .WorkExperience-Text': {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
  },
  '& .Available-Work .MuiGrid-item': {
    paddingBottom: '0 !important',
  },
}))

//get applicant name display
const getWorkerDisplayName = (data) => {
  return (
    <React.Fragment>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item>
          <Link to={`/worker/${data?.worker?.id}`} sx={{ textDecoration: 'underline' }}>
            {data?.worker?.name}
          </Link>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1}>
            {!!data?.worker?.verified_user && (
              <SmallOutlinedTag label="本人確認済" variant="outlined" color="success" />
            )}
            {!!data?.worker?.tekikaku_flag && (
              <SmallOutlinedTag label="適格請求書発行事業者" variant="outlined" color="primary" />
            )}
          </Stack>
        </Grid>
      </Grid>
      <Caption>(マッチングが成立したら開示されます。）</Caption>
    </React.Fragment>
  )
}

//get phone number display
const getPhoneNumberDisplay = (phone_number) => {
  return (
    <React.Fragment>
      {phone_number}
      <Caption>(マッチングが成立したら開示されます。）</Caption>
    </React.Fragment>
  )
}

const getDateTimeComponent = (date, time) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      divider={<Divider orientation="vertical" flexItem color="gray" sx={{ borderColor: '#f7f0f0a1' }} />}
      height="inherit"
    >
      <Box width={7 / 12} alignItems="center" display="inline-flex">
        <CalendarMonthOutlinedIcon />
        <Label>&nbsp;{date}</Label>
      </Box>
      <Box width={5 / 12} alignItems="center" display="inline-flex" p={1}>
        <ScheduleOutlinedIcon />
        <Label>&nbsp;{time}</Label>
      </Box>
    </Stack>
  )
}

const ApplicationDetails = (props) => {
  const isLoading = useSelector((state) => state.loader)
  const displayData = props?.displayData

  const skills = displayData?.data?.worker?.skills ?? []
  const certificates = skills.filter((skill) => skill.skill_category_type === 'Qualification')
  const canDos = skills.filter((skill) => skill.skill_category_type === 'Skills')
  const certificatesByCategory = groupBy(certificates, 'skill_category_name')
  const canDosByCategory = groupBy(canDos, 'skill_category_name')

  return (
    <StyledApplicationDetails item xs={12}>
      {isLoading && (
        <Grid xs={12} item textAlign="center" py={32}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <React.Fragment>
          <BackArrow label="応募一覧に戻る" path="/application/list" />
          <Grid item xs={12} pt={5} pb={4} py={3}>
            <Heading>応募者詳細</Heading>
          </Grid>
          <Grid item xs={12} pb={4}>
            <SubHeading bold="true">応募先</SubHeading>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} className="ApplicantOutline">
              <Label pb={0.5}>
                応募ID: <span>{displayData?.data?.id}</span>
              </Label>
              <Label pb={0.5}>
                作業グループ:&nbsp;
                <Link to={'/job_group/show/' + displayData?.data?.project_id} sx={{ textDecoration: 'underline' }}>
                  {displayData?.data?.job_group_name_formatted}
                </Link>
              </Label>
              <Label pb={0.5}>
                案件: <span>{displayData?.data?.project_name}</span>
              </Label>
              <Label pb={0.5}>
                クライアント:&nbsp;
                <Link to={'/client/show/' + displayData?.data?.client?.id} sx={{ textDecoration: 'underline' }}>
                  {displayData?.data?.client?.name}
                </Link>
              </Label>
              <Label pb={0.5}>
                応募日: <span>{displayData?.data?.application_date}</span>
              </Label>
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <Label pb={1}>応募ステータス</Label>
              <Banner label={displayData?.data?.application_status} variant="outlined" color="success" bold="true" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Label pb={1}>ステータス更新日</Label>
              <Banner
                label={getDateTimeComponent(
                  displayData?.data?.status_updated_date,
                  displayData?.data?.status_updated_time
                )}
                variant="outlined"
                color="gray"
                bold="true"
                mode="dark"
                className="DateTimeBanner"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} pt={2}>
            <SubHeading bold="true">応募者</SubHeading>
          </Grid>
          {displayData && (
            <Grid item container>
              <ContentDisplay label="応募者名:" data={getWorkerDisplayName(displayData?.data)} />
              <ContentDisplay label="表示名:" data={displayData?.data?.screen_name} />
              <ContentDisplay label="性別:" data={displayData?.data?.worker?.gender} />
              <ContentDisplay label="年齢:" data={`${displayData?.data?.worker?.age}歳`} />
              <ContentDisplay label="住所:" data={formatAddress(displayData?.data?.worker)} />
              <ContentDisplay label="メールアドレス:" data={displayData?.data?.worker?.email} />
              <ContentDisplay label="電話番号:" data={getPhoneNumberDisplay(displayData?.data?.worker?.phone_number)} />
              <ContentDisplay
                label="適格請求書発行事業者:"
                data={
                  displayData?.data?.worker?.tekikaku_flag ? <CheckBoxIcon color="primary" /> : <CheckBoxBlankIcon />
                }
              />
              {certificates.length !== 0 && (
                <Grid item pt={10} xs={12} textAlign="center">
                  <SubHeading bold="true">所有資格</SubHeading>
                </Grid>
              )}
              {Object.entries(certificatesByCategory).map((items, i) => {
                return (
                  <Grid item xs={12} key={i} pt={4} textAlign="center">
                    <Label color="main" pb={2}>
                      {items[0]}:
                    </Label>
                    <CategoryDisplay items={items[1]} />
                  </Grid>
                )
              })}
              {canDos.length !== 0 && (
                <Grid item pt={10} xs={12} textAlign="center">
                  <SubHeading bold="true">対応可能作業</SubHeading>
                </Grid>
              )}
              {Object.entries(canDosByCategory).map((items, i) => {
                return (
                  <Grid item xs={12} key={i} pt={4} textAlign="center" className="Available-Work">
                    <CategoryDisplay items={items[1]} />
                  </Grid>
                )
              })}
              <Grid item pt={10}>
                <SubHeading bold="true">業務経験</SubHeading>
              </Grid>
              <Grid item pt={1} xs={12} sm={12} md={12} lg={12}>
                <pre>
                  <P className="WorkExperience-Text">{displayData?.data?.worker?.work_experience}</P>
                </pre>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      )}
    </StyledApplicationDetails>
  )
}

ApplicationDetails.displayName = 'Application Details From'

ApplicationDetails.propTypes = {
  match: PropTypes.any,
  handleData: PropTypes.func,
  displayData: PropTypes.object,
}

export default ApplicationDetails
