/**
 * Formats file size.
 *
 * @param {Number|String} size
 */
export const formatFileSize = (size) => {
  const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const threshold = 1024
  size = Number(size) * threshold
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold))
  return `${(size / Math.pow(threshold, i)).toFixed(2) * 1} ${units[i]}`
}

export const messages = {
  after: (field, [target]) => `${field}は${target}の後でなければなりません`,
  alpha_dash: (field) => `${field}は英数字とハイフン、アンダースコアのみ使用できます`,
  alpha_num: (field) => `${field}は英数字のみ使用できます`,
  alpha_spaces: (field) => `${field}はアルファベットと空白のみ使用できます`,
  alpha: (field) => `${field}はアルファベットのみ使用できます`,
  before: (field, [target]) => `${field}は${target}よりも前でなければなりません`,
  between: ([min, max]) => `${min}〜${max}の数字を入力してください。`,
  confirmed: (field) => `${field}が一致しません`,
  credit_card: (field) => `${field}が正しくありません`,
  date_between: (field, [min, max]) => `${field}は${min}から${max}の間でなければなりません`,
  date_format: (field, [format]) => `${field}は${format}形式でなけれななりません`,
  decimal: (field, [decimals = '*'] = []) =>
    `${field}は整数及び小数点以下${decimals === '*' ? '' : decimals}桁までの数字にしてください`,
  digits: (field, [length]) => `${field}は${length}桁の数字でなければなりません`,
  dimensions: (field, [width, height]) => `${field}は幅${width}px、高さ${height}px以内でなければなりません`,
  email: (field) => `${field}は有効なメールアドレスではありません`,
  ext: (field) => `${field}は有効なファイル形式ではありません`,
  image: (field) => `${field}は有効な画像形式ではありません`,
  in: (field) => `${field}は有効な値ではありません`,
  ip: (field) => `${field}は有効なIPアドレスではありません`,
  max: (length) => `${length}文字以内で入力してください。`,
  max_value: (field, [max]) => `${field}は${max}以下でなければなりません`,
  mimes: (field) => `${field}は有効なファイル形式ではありません`,
  min: (field, [length]) => `${field}は${length}文字以上でなければなりません`,
  min_value: (field, [min]) => `${field}は${min}以上でなければなりません`,
  not_in: (field) => `${field}は不正な値です`,
  numeric: (field) => `${field}は数字のみ使用できます`,
  regex: (field) => `${field}が正しくありません`,
  required: (field) => `${field}入力してください。`,
  size: (field, [size]) => `${field}は${formatFileSize(size)}以内でなければなりません`,
  url: (field) => `${field}が正しくありません`,
  invalid_data_given: () => '必須項目を入力してください。',
  invalid_date: () => 'YYYY/MM/DDの形式で入力してください。',
  is_date_before: () => '終了日は開始日と同じか、後の日付を指定してください。',
  is_equal_greater: () => '最低額以上の値を入力してください。',
  max_digits: ([min, max]) => `${min}桁か${max}桁の半角数字を入力してください。`,
  after_today: () => '本日以降の日を入力してください。',
  time_format: () => 'HH:MMの形式で入力してください。',
  min_numbers: (length) => `${length}桁の半角数字を入力してください。`,
  basic_required: () => 'は必須項目です。',
  invalid_email: () => 'メールアドレスの書式が正しくありません。',
  date_range: () => '一度に追加できる作業日は最大31日分です。',
  required_select: (field) => `${field}選択してください。`,
  email_credential: 'メールアドレスを入力してください。',
  email_pattern: 'メールアドレスの書式が正しくありません。',
  jobGroup_email_pattern: '応募問い合わせ用メールアドレスの書式が正しくありません。',
  email_exists: 'このメールアドレスはすでに使用されています。別のメールアドレスを入力してください。',
  password_credential: 'パスワードを入力してください。',
  password_pattern: '数字、大文字の英字、小文字の英字をすべて含めた8文字以上を入力してください。',
  password_mismatch: 'パスワードが一致しません。',
  password_invalid: '現在のパスワードが違います。',
  token_expired: '期限切れのパスワードリセットトークン',
  invalid_credentials: 'メールアドレスまたはパスワードが違います。',
  invalid_account: 'ログインできません。詳しくはお問い合わせください。',
  invalid_input: '入力された内容が正しくありません。',
  name_input: '法人名を入力してください。',
  name_invalid: 'お名前を入力してください。',
  name_space: '姓と名の間にスペースを入力してください。',
  name_exists: 'この法人名はすでに登録されています。',
  half_width: '半角英数記号で入力してください。',
  contact_input: '電話番号を入力してください。',
  contact_length: '10桁か11桁の半角数字を入力してください。',
  postal_input: '郵便番号を入力してください。',
  postal_length: (length) => `${length}桁の半角数字を入力してください。`,
  postal_invalid: '該当する郵便番号が見つかりませんでした。',
  prefecture_input: '都道府県を入力してください。',
  city_input: '市町村区を入力してください。',
  prefecture_required: '都道府県は必須項目です。',
  city_required: '市町村区は必須項目です。',
  address_line_1_input: '町名番地を入力してください。',
  website_input: 'WEBサイトを入力してください。',
  phone_number_exists: 'この電話番号はすでに使用されています。別の電話番号を入力してください。',
  client_input: 'クライアントを選択してください。',
  project_input: '案件を選択してください。',
  client_jobGroup_input: '作業グループ担当者を選択してください。',
  setting_input: '公開設定を選択してください。',
  jobGroup_name_input: '作業グループ名を入力してください。',
  start_date_input: '作業開始日を入力してください。',
  dates_mismatch: '開始日は終了日以前である必要があります',
  date_invalid: '本日以降の日を入力してください。',
  end_date_invalid: '終了日は開始日と同じか、後の日付を指定してください。',
  end_date_input: '作業終了日を入力してください。',
  client_end_date_input: '作業グループ終了日を入力してください。',
  client_start_date_input: '作業グループ開始日を入力してください。',
  client_jobGroup_date_error: '作業グループ開始日以降の日付を入力してください。',
  jobGroup_date_error: '終了日は開始日と同じか、後の日付を指定してください',
  location_input: '作業実施都道府県を選択してください。',
  min_salary_input: '基本作業費(1日あたり)の最低額を入力してください。',
  salary_length_error: '1〜1,000,000の数字を入力してください。',
  max_salary_invalid: '基本作業費(1日あたり)の最高額を入力してください。',
  max_salary_less_error: '基本作業費(1日あたり)の最低額以上の値を入力してください。',
  worker_input: '募集人数を入力してください。',
  worker_capacity_error: '1〜100の数字を入力してください。',
  details_input: '作業内容を入力してください。',
  fees_input: '作業費を入力してください。',
  allowances_input: 'その他手当を入力してください。',
  jobGroup_email_input: '応募問い合わせ用メールアドレスを入力してください。',
  project_name_input: '案件名を入力してください。',
  contact_person_input: '担当者を選択してください。',
  project_status_error: '進行中の作業が完了してからクローズしてください。',
  job_time:
    '作業開始時刻以降の時刻を入力してください。深夜作業で日をまたぐ場合は24:00にプラスする形で入力してください。例: 28:00',
  is_24hours: '作業開始時間から24時間以内の時刻を入力してください。',
  cancellation_required: '上記キャンセル費を承諾する、にチェックを入れてください。',
  empty_table: '検索条件に一致する結果が見つかりません。',
  opc_client_empty_table: '検索結果が見つかりません',
  legal_age_required: '18歳以上の方のみご登録可能です。',
  file_types_available: 'JPEG, JPG, PNG, GIF, PDFいずれかのファイルを選択してください。',
  file_size: (length) => `ファイルサイズは${length}以下にしてください。`,
  offer_unique_dates: 'すでに存在するオファーの日付です。ほかの日付を入力してください。',
}
