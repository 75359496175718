import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Link, Label } from 'components'

function ReturnLabel(props) {
  const { icon, children, path, color, fontSize } = props
  return (
    <Link to={path}>
      <Grid container justifyContent="flex-start">
        <Grid item xs={0.25}>
          <Label fontSize={fontSize} color={color}>
            {icon}
          </Label>
        </Grid>
        <Grid item>
          <Label fontSize={fontSize} color={color}>
            {children}
          </Label>
        </Grid>
      </Grid>
    </Link>
  )
}

ReturnLabel.defaultProps = {
  fontSize: 18,
  color: 'black',
}

ReturnLabel.propTypes = {
  icon: PropTypes.any,
  children: PropTypes.any,
  path: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.number,
}

export default ReturnLabel
