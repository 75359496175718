import palette from '../palette'
export default {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        backgroundColor: palette.background.gray,
        '&:hover': {
          backgroundColor: palette.background.gray,
        },
        '&:focus': {
          backgroundColor: palette.background.gray,
        },
      },
      '&#menuSelectItem-Placeholder-ID': {
        display: 'none',
      },
    },
  },
}
