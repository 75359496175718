import React, { useEffect } from 'react'
import { Grid, Chip, CircularProgress } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import InnerHTML from 'dangerously-set-html-content'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp'
import { setConfirming } from 'store/job-groups/actionCreators'
import { setPrivateRedirectRoute } from 'store/common/actionCreators'
//services
import { readWorkerJobGroup } from 'services/jobGroups'
//componets
import {
  Label,
  Heading,
  LargeButton,
  Banner,
  ContentDisplay,
  SubHeading,
  SmallBanner,
  IconLabel,
  Link,
} from 'components'
//common
import { settings, member_type, status } from 'utils/config/status'

const JobGroupDetailsForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const formData = useSelector((state) => state.jobGroups.formData)
  const isLoading = useSelector((state) => state.loader)
  const displayData = formData?.formatted

  //reset private redirect route
  useEffect(() => {
    dispatch(setPrivateRedirectRoute(null))
  }, [])

  //api call get details
  useEffect(() => {
    let id = props?.match?.params?.id
    if (id && id > 0) {
      dispatch(readWorkerJobGroup(id))
    }
  }, [])

  //change title
  useEffect(() => {
    if (!isEmpty(displayData)) props.handleData(displayData)
    return
  }, [displayData])

  //handle confirm application
  const confirmApplication = () => {
    dispatch(setConfirming(true))
    history.push('/job-group/application/confirm')
  }

  //handle update worker info
  const updateWorkerInfo = () => {
    dispatch(setPrivateRedirectRoute(props?.location?.pathname ?? null))
  }
  return (
    <Grid item container xs={12}>
      {isLoading && (
        <Grid xs={12} item textAlign="center" py={32}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && displayData && (
        <Grid container>
          <Grid item xs={12}>
            <Heading>募集要項</Heading>
          </Grid>
          <Grid item xs={12} mt={2} display="inline-flex" alignItems="end">
            <SubHeading pr={1}>{displayData?.title}</SubHeading>
            {displayData?.visibility == settings.limited_public && (
              <SmallBanner label={displayData?.visibility} color="warning" width="fit-content" />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} py={4} id="bannerWrapper-ID">
            <Label color="gray" pb={1}>
              ステータス
            </Label>
            <Banner label={displayData?.status} variant="outlined" color="error" bold="true" />
          </Grid>
          <ContentDisplay
            label="クライアント:"
            data={
              displayData?.member_type === member_type.worker ? (
                displayData?.client_name
              ) : (
                <Chip label="クライアント名はワーカー登録後に表示されます" />
              )
            }
          />
          <ContentDisplay label="作業開始日:" data={displayData?.start_date} />
          <ContentDisplay label="作業終了日:" data={displayData?.end_date} />
          <ContentDisplay label="作業実施都道府県:" data={displayData?.prefecture} />
          <ContentDisplay label="基本作業費（税抜き）:" data={displayData?.fee_range} />
          <ContentDisplay label="募集人数:" data={displayData?.no_of_position} />
          <ContentDisplay label="作業内容:" data={<InnerHTML className="InnerHtml" html={displayData?.details} />} />
          <ContentDisplay label="作業費:" data={<InnerHTML className="InnerHtml" html={displayData?.fees} />} />
          <ContentDisplay
            label="その他手当:"
            data={<InnerHTML className="InnerHtml" html={displayData?.allowances} />}
          />
          <ContentDisplay
            label="問い合わせ先:"
            data={
              displayData?.member_type == member_type.worker ? (
                displayData?.email_address
              ) : (
                <Chip label="クライアント問い合わせ先はワーカー登録後に表示されます" />
              )
            }
          />
          {displayData?.member_type == member_type.member && displayData?.status == status.name.job_group.can_apply && (
            <Grid item container justifyContent={{ xs: 'start', sm: 'end' }} pt={8}>
              <Grid item>
                <IconLabel
                  label="ワーカー登録後に応募することができます。"
                  icon={<WarningAmberSharpIcon color="error" fontSize="small" />}
                  color="error"
                  fontSize={10}
                />
                <Label color="error" pl={2.8} fontSize={10}>
                  ワーカー登録は
                  <Link fontSize={10} id="RegisterLink" to="/worker-information" onClick={() => updateWorkerInfo()}>
                    こちら
                  </Link>
                </Label>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            container
            mt={1}
            mb={2}
            spacing={2}
            justifyContent={{ xs: 'center', sm: 'end' }}
            display={{ xs: 'block', sm: 'inhiret' }}
          >
            <Grid item>
              <Link to="/job/search">
                <LargeButton id="JobGroupBackBtn-ID" color="gray" mode="btn1">
                  戻る
                </LargeButton>
              </Link>
            </Grid>
            {displayData?.status == status.name.job_group.can_apply && (
              <Grid item>
                <LargeButton
                  id="JobGroupApplyBtn-ID"
                  color="primary"
                  disabled={displayData?.member_type == member_type.member ? true : false}
                  onClick={() => {
                    confirmApplication()
                  }}
                >
                  応募する
                </LargeButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

JobGroupDetailsForm.displayName = 'Job Group Details'

JobGroupDetailsForm.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
  handleData: PropTypes.func,
}

export default JobGroupDetailsForm
