import React, { useState, useEffect } from 'react'
import { Grid, Box } from '@mui/material'
import PropTypes from 'prop-types'
import searchIcon from 'assets/svg/search.svg'
import AddIcon from '@mui/icons-material/Add'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import * as dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { resetCurrentForm, setJobGroupFormData } from 'store/job-groups/actionCreators'
import { status } from 'utils/config/status'

//utils
import {
  datePresets,
  thisDay,
  thisMonthStart,
  thisMonthEnd,
  prevMonthStart,
  prevMonthEnd,
  nextMonthStart,
  nextMonthEnd,
  dateFormat,
} from 'utils/common'
import { getStatusByType } from 'utils/helper'
//componets
import {
  LargeButton,
  InputLabel,
  InputSelect,
  InputMultiSelect,
  TableList,
  Heading,
  InputDatePicker,
  Icon,
  Link,
} from 'components'
import { getMessages } from 'validation'
import { useMatchLastLocation } from 'utils/hooks'
import { routes } from 'router/routes/opCenterRoutes'

const JobGroup = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isFromProjectList = useMatchLastLocation(routes.projectList)
  const prefectureslist = useSelector((state) => state.common.prefectureslist)
  const allStatus = useSelector((state) => state.common.statusList)
  const { formData, search } = useSelector((state) => state.jobGroups)

  const jobGroupStatus = getStatusByType(allStatus, 'Job Group')
  const isClient = localStorage.getItem('mode') === 'client'

  const [datePresetValue, setdatePresetValue] = useState(isFromProjectList ? 5 : 1)
  const [dateFromValue, setDateFromValue] = useState(thisMonthStart)
  const [dateToValue, setDateToValue] = useState(thisMonthEnd)
  const [selectedRow, setSelectedRow] = useState({})
  const [dateFromChanged, setDateFromChanged] = useState(false)
  const [dateToChanged, setDateToChanged] = useState(false)

  const { under_edition, can_apply, close_application, completed } = status.job_group
  const defaultJobGroupStatus = [under_edition, can_apply, close_application, completed]
  const rulesMsg = getMessages('ja')
  jobGroupStatus.splice(-1) //removes abort('中断') status on list

  //useform
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm()
  const handleSearch = (data) => {
    props.handlePageSearch({
      ...data,
      prefecture: data.prefecture.toString() !== '0' ? data.prefecture : '',
    })
  }

  useEffect(() => {
    dispatch(resetCurrentForm())
  }, [dispatch])

  //validation rules for search form
  const validationRules = {
    name: {
      validate: (value) => {
        return value.length <= 255 || rulesMsg.max(255)
      },
    },
    project_name: {
      validate: (value) => {
        return value.length <= 255 || rulesMsg.max(255)
      },
    },
    client_name: {
      validate: (value) => {
        return value.length <= 255 || rulesMsg.max(255)
      },
    },
    start_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        return isValid || rulesMsg.invalid_date()
      },
    },
    end_date: {
      validate: (value) => {
        const isValid = value.length > 0 ? dayjs(value, dateFormat, true).isValid() : true
        const startDateVal = getValues('start_date')
        if (!isValid) {
          return rulesMsg.invalid_date()
        } else if (dayjs(value).isBefore(startDateVal)) {
          return rulesMsg.is_date_before()
        } else {
          return true
        }
      },
    },
  }

  //date Preset onchange
  const handleDatePreset = (preset) => {
    switch (preset) {
      case 2: //prev month
        setDateFromValue(prevMonthStart)
        setDateToValue(prevMonthEnd)
        break
      case 3: //next month
        setDateFromValue(nextMonthStart)
        setDateToValue(nextMonthEnd)
        break
      case 4: //this day
        setDateFromValue(thisDay)
        setDateToValue(thisDay)
        break
      case 1: //this month
        setDateFromValue(thisMonthStart)
        setDateToValue(thisMonthEnd)
        break
      default:
        break
    }

    setdatePresetValue(preset)
  }

  //on date change
  const onDateChange = (newVal, type) => {
    setdatePresetValue(5)
    if (type == 'start_date') {
      setDateFromValue(newVal)
      setDateFromChanged(true)
      //if date from is set greater than date to, then adjust date to
      if (dayjs(newVal).diff(dayjs(dateToValue)) >= 1) {
        setDateToValue(newVal)
      }
    } else {
      setDateToValue(newVal)
      setDateToChanged(true)
    }
  }

  const handleSelectedRow = (rowData) => {
    setSelectedRow(rowData)
  }

  //Table Actions
  const handleEdit = () => {
    history.push(`/${isClient ? 'job-group' : 'job_group'}/update/${selectedRow.id}`)
  }
  const handleShowDetals = () => {
    history.push(`/${isClient ? 'job-group' : 'job_group'}/show/${selectedRow.id}`)
  }
  const handleAddJob = () => {
    history.push(`/job/create?job_group=${selectedRow.id}`)
  }
  const handleCopy = () => {
    dispatch(setJobGroupFormData({ ...formData, current: null }))
    history.push(`/${isClient ? 'job-group' : 'job_group'}/create/${selectedRow.id}`)
  }

  const actionList = [
    {
      handler: handleShowDetals,
      name: '閲覧',
    },
    {
      handler: handleEdit,
      name: '編集',
    },
    {
      handler: handleAddJob,
      name: '作業追加',
    },
    {
      handler: handleCopy,
      name: 'コピー',
    },
  ]

  const handleCreate = () => {
    dispatch(resetCurrentForm())
    history.push(`/${isClient ? 'job-group' : 'job_group'}/create`)
  }

  const formatListData = (list) => {
    return list.map((item) => ({
      ...item,
      name: <Link to={`/job_group/show/${item.id}`}>{item.name}</Link>,
      client_name: <Link to={`/client/show/${item.client_id}`}>{item.client_name}</Link>,
    }))
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Heading>作業グループ一覧</Heading>
      </Grid>
      <Grid item container justifyContent="end" pt={{ xs: 2, sm: 0 }}>
        <Grid item xs={12} sm={5} md={4} lg={3} textAlign="end">
          <LargeButton
            id="jobGroupCreateBtn-ID"
            startIcon={<AddIcon size={'sm'} style={{ color: '#ffffff' }} />}
            onClick={handleCreate}
          >
            作業グループ新規登録
          </LargeButton>
        </Grid>
      </Grid>
      <Box component="form" onSubmit={handleSubmit(handleSearch)} className="form" pt={6}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <InputLabel
              ref={register('name', validationRules.name)}
              id="jobGroup-name-ID"
              error={errors && errors.name ? true : false}
              errorMsg={errors ? errors?.name?.message : null}
              name="name"
              label={'作業グループ名'}
              value={search?.name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
            <InputLabel
              id="jobGroup-project_name-ID"
              ref={register('project_name', validationRules.project_name)}
              error={errors && errors.project_name ? true : false}
              errorMsg={errors ? errors?.project_name?.message : null}
              name="project_name"
              label={'案件名'}
              value={search?.project_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
            <InputLabel
              id="jobGroup-client_name-ID"
              ref={register('client_name', validationRules.client_name)}
              error={errors && errors.client_name ? true : false}
              errorMsg={errors ? errors?.client_name?.message : null}
              name="client_name"
              label={'クライアント名'}
              value={search?.client_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
            <InputSelect
              id="jobGroup-prefecture-ID"
              ref={register('prefecture')}
              name="prefecture"
              label={'都道府県'}
              menuItems={[{ id: '0', name: 'すべて' }, ...prefectureslist]}
              defaultValue={0}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={1} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={4} lg={1.6}>
            <InputSelect
              id="jobGroup-date_preset-ID"
              name="date_preset"
              label={'作業開始日'}
              menuItems={datePresets}
              value={datePresetValue}
              onChange={(e) => handleDatePreset(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InputDatePicker
              id="jobGroup-start_date-ID"
              ref={register('start_date', validationRules.start_date)}
              error={errors && errors.start_date ? true : false}
              errorMsg={errors ? errors?.start_date?.message : null}
              name="start_date"
              label={'開始日'}
              color="primary"
              inputFormat={dateFormat}
              value={dayjs(dateFromChanged ? dateFromValue : isFromProjectList ? search.start_date : dateFromValue)}
              onChange={(newVal) => onDateChange(newVal, 'start_date')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InputDatePicker
              id="jobGroup-end_date-ID"
              ref={register('end_date', validationRules.end_date)}
              error={errors && errors.end_date ? true : false}
              errorMsg={errors ? errors?.end_date?.message : null}
              name="end_date"
              label={'終了日'}
              color="primary"
              inputFormat={dateFormat}
              value={dayjs(dateToChanged ? dateToValue : isFromProjectList ? search.end_date : dateToValue)}
              onChange={(newVal) => onDateChange(newVal, 'end_date')}
              minDate={dayjs(dateFromValue)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2.7}>
            <InputMultiSelect
              id="jobGroup-status-ID"
              ref={register('status_id')}
              name="status_id"
              label={'ステータス'}
              menuItems={jobGroupStatus}
              defaultValue={isFromProjectList ? [] : defaultJobGroupStatus}
              enableEmpty
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={1} sx={{ marginTop: { md: 3.5 } }}>
            <LargeButton
              id="jobGroup-submitBtn-ID"
              type="submit"
              bold="true"
              color="warning"
              startIcon={<Icon size={'sm'} disabled={props.isLoading ? true : false} source={searchIcon} />}
            >
              検索
            </LargeButton>
          </Grid>
        </Grid>
      </Box>
      <Box width={1}>
        <Grid item xs={12} pt={6}>
          <TableList
            title={`Project List`}
            headCells={props.headerCells}
            data={formatListData(props.data)}
            totalPage={props.totalPage}
            totalCount={props.totalCount}
            handlePageChange={props.handlePageChange}
            isLoading={props.isLoading}
            handleSort={props.handleSort}
            handleSelectedRow={handleSelectedRow}
            sort={props.sort}
            sortBy={props.sortBy}
            currentPage={props.currentPage}
            action={true}
            actionList={actionList}
            pageFrom={props.pageFrom}
            pageTo={props.pageTo}
          />
        </Grid>
      </Box>
    </Grid>
  )
}

JobGroup.displayName = 'Project List Form'

JobGroup.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

export default JobGroup
