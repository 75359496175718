export const scriptId = 'gtag-script-id'
export const wkrLoginScriptId = 'wkr-login-gtag-script-id'
export const clientLoginScriptId = 'client-login-gtag-script-id'

export function createClientWorkerGtag() {
  const scriptText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-WPSKFWK');`
  const url = `https://www.googletagmanager.com/ns.html?id=GTM-WPSKFW`

  noScriptForGTM(scriptId, url)
  createScript(scriptId, scriptText, null, false)

  return false
}

export function createWorkerLoginGtag() {
  const scriptText = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-YCX7V94QE0');`
  const url = `https://www.googletagmanager.com/gtag/js?id=G-YCX7V94QE0`

  createScript(wkrLoginScriptId, null, url, true)
  createScript(wkrLoginScriptId, scriptText, null, false)

  return false
}

export function createClientLoginGtag() {
  const scriptText = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-PB106NXK13');`
  const url = `https://www.googletagmanager.com/gtag/js?id=G-PB106NXK13`

  createScript(clientLoginScriptId, null, url, true)
  createScript(clientLoginScriptId, scriptText, null, false)

  return false
}

export function createScript(id, scriptText, src, async) {
  var script = document.createElement('script')

  if (id) {
    script.setAttribute('id', id)
  }

  if (src) {
    script.setAttribute('src', src)
  }

  if (async) {
    script.async = true
  }

  if (scriptText) {
    script.appendChild(document.createTextNode(scriptText))
  }

  try {
    document.head.appendChild(script)
  } catch (e) {
    script.text = scriptText
    document.head.appendChild(script)
  }
}

export function noScriptForGTM(id, url) {
  var script = document.createElement('noscript')
  script.setAttribute('id', id)
  var x = document.createElement('iframe')
  x.setAttribute('src', url)
  x.setAttribute('width', '0')
  x.setAttribute('height', '0')
  x.setAttribute('style', 'display:none;visibility:hidden')
  script.appendChild(x)

  var body = document.getElementsByTagName('body')[0]
  body.parentNode.insertBefore(script, body.nextSibling)
}

export function removeScript(id) {
  var elements = document.querySelectorAll(`#${id}`)
  elements.forEach((element) => {
    if (element) {
      element.parentNode.removeChild(element)
    }
  })
}
