import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { resetPassword, validateTokenService, updatePassword } from 'services/auth'
import { hideNotification } from 'store/notification/actionCreators'
import PropTypes from 'prop-types'
import { Redirect, useHistory } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import ForgotPasswordSuccessIcon from 'assets/svg/ForgotPasswordSuccessIcon.svg'
import { getMessages } from 'validation'

//components
import { InputLabel, LargeButton, Typography, Icon, Link, SubHeading, Heading, P } from 'components'

const StyledResetPasswordForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& #ResetPasswordInput-helper-text:not(.Mui-error)': {
    fontSize: 10,
  },
}))

const ResetPasswordForm = (props) => {
  const dispatch = useDispatch()
  const rulesMsg = getMessages('ja')
  const history = useHistory()
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [invalidTokenError, setInvalidTokenError] = useState(null)
  const mode = localStorage.getItem('mode')
  const isWorkerUpdatePassword = props.location.pathname === '/password-settings'
  const validationType = isWorkerUpdatePassword ? 'activation' : 'reset-password'
  const authToken = useSelector((state) => state.auth.token)
  const authUser = useSelector((state) => state.auth.user)
  const token = queryString.parse(props.location.search).token
  const invalidPasswordMsg = rulesMsg.password_pattern

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    getValues,
    watch,
  } = useForm()

  useEffect(() => {
    if (!authToken?.user_id) {
      validateToken(token, validationType)
      setValue('token', token)
    }

    if (authUser?.name && !success) {
      if (mode === 'wkr') {
        if (authUser?.member_type?.length > 0) {
          authUser?.member_type === 'ワーカー'
            ? window.location.replace('/job/list')
            : window.location.replace('/worker-information')
        }
      }
    }

    if (!token) {
      history.push('/reset/error')
    }
  }, [authUser])

  useEffect(() => {
    if (authToken?.user_id) {
      props.isSuccess('success')
    }
  }, [authToken?.user_id])

  const handleReset = (data) => {
    setLoading(true)
    let usedService = isWorkerUpdatePassword ? updatePassword(data) : resetPassword(data)

    dispatch(usedService)
      .then(() => {
        setSuccess(true)
        props.isSuccess('success')
      })
      .catch((e) => {
        const { code, error } = e.response.data
        // handle API validation error
        validateErrorCode(code, error)
      })
      .finally(() => setLoading(false))
  }

  const validateToken = (token, validationType) => {
    setLoading(true)
    dispatch(validateTokenService(token, validationType))
      .catch((e) => {
        const { code } = e.response.data
        code === 500 && dispatch(hideNotification()) && history.push('/reset/error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const validateErrorCode = (code, error) => {
    switch (code) {
      case 500:
        return error && dispatch(hideNotification()) && setInvalidTokenError(rulesMsg.token_expired)
      case 422:
        if (error) {
          dispatch(hideNotification())
          setError('password', { type: 'custom', message: invalidPasswordMsg }, { shouldFocus: true })
          setError('password_confirmation', { type: 'custom', message: invalidPasswordMsg }, { shouldFocus: true })
        }
    }
  }

  const validationRules = {
    password: {
      required: {
        value: String,
        message: rulesMsg.password_credential,
      },
      minLength: {
        value: 8,
        message: invalidPasswordMsg,
      },
      pattern: {
        // 8 Characters, Should include number, uppercase, lowercase.
        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        message: invalidPasswordMsg,
      },
    },
    password_confirmation: {
      // custom validation rule
      required: {
        value: String,
        message: rulesMsg.password_credential,
      },
      minLength: {
        value: 8,
        message: invalidPasswordMsg,
      },
      pattern: {
        // 8 Characters, Should include number, uppercase, lowercase.
        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        message: invalidPasswordMsg,
      },
      validate: (value) => {
        return value === getValues('password') || rulesMsg.password_mismatch
      },
    },
  }

  if (!isWorkerUpdatePassword && success) {
    return <Redirect to="/login" />
  }

  if (authToken?.user_id) {
    return (
      <StyledResetPasswordForm container justifyContent="center" alignItems="center" textAlign="center">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} pt={{ xs: 1, sm: 4 }}>
            <Icon source={ForgotPasswordSuccessIcon}></Icon>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: { xs: 2 }, pt: { xs: 1, sm: 4 } }}>
            <Heading>{'パスワード設定完了'}</Heading>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: { xs: 2 }, pt: { xs: 1, sm: 4 } }}>
            <SubHeading>{'ご登録ありがとうございました。'}</SubHeading>
          </Grid>
          <Grid item xs={12} pt={{ xs: 1, sm: 4 }}>
            <LargeButton id="CreatNewWorkerBtn" maxwidth="inherit">
              <Link id="ConfirmedBackBtn" color={'white'} to={`worker-information`}>
                {'ワーカー登録へ進む'}
              </Link>
            </LargeButton>
          </Grid>
        </Grid>
      </StyledResetPasswordForm>
    )
  }

  return (
    <StyledResetPasswordForm container justifyContent="center" alignItems="center">
      <Grid item xs={12} pb={3}>
        <Heading>{'パスワード設定'}</Heading>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <P>{isWorkerUpdatePassword && 'メールアドレスのご確認ありがとうございました。'}</P>
      </Grid>
      <Grid item xs={12} sm={10} sx={{ px: { xs: 2 }, pt: { xs: 1, sm: 3 } }}>
        <form onSubmit={handleSubmit(handleReset)}>
          <InputLabel
            id="ResetPasswordInput"
            name="password"
            ref={register('password', validationRules.password)}
            error={errors && errors.password ? true : false}
            errorMsg={errors.password ? errors.password.message : rulesMsg.password_pattern}
            label="新規パスワード"
            type="password"
            placeholder="●   ●   ●   ●   ●   ●"
            required={mode !== 'wkr'}
            iconState={!watch('password')}
          />
          <InputLabel
            id="ResetPasswordConfirmationInput"
            name="password_confirmation"
            ref={register('password_confirmation', validationRules.password_confirmation)}
            error={errors && errors.password_confirmation ? true : false}
            errorMsg={errors ? errors?.password_confirmation?.message : null}
            label="新規パスワード (確認)"
            type="password"
            placeholder="●   ●   ●   ●   ●   ●"
            required={mode !== 'wkr'}
            iconState={!watch('password_confirmation')}
          />
          {invalidTokenError && (
            <Typography color="error" pb={1} justifyContent="center" alignItems="center">
              {invalidTokenError}
            </Typography>
          )}
          <Grid item xs={12} mt={1}>
            <LargeButton
              type="submit"
              className="ResetPassword-SubmitBtn"
              id="ResetPasswordBtn"
              maxwidth="inherit"
              disabled={loading}
            >
              {loading && <CircularProgress size="1.5rem" color="white" />}
              {!loading && '登録'}
            </LargeButton>
          </Grid>
        </form>
      </Grid>
    </StyledResetPasswordForm>
  )
}

ResetPasswordForm.propTypes = {
  location: PropTypes.object,
  isSuccess: PropTypes.func,
}

export default ResetPasswordForm
