import React, { useEffect, useState } from 'react'
import { Grid, Box, Stack, Divider, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { getCancellationFee, readJob } from 'services/jobs'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined'
//utils
import { formatJobDetails, getCurrentDate } from 'utils/helper'
import { tabs, tabIndex } from 'utils/common'
import { status } from 'utils/config/status'

//components
import {
  Label,
  Heading,
  SubHeading,
  Link,
  LargeButton,
  Banner,
  Tabs,
  TabTableDisplay,
  ReportFileTab,
  ExpenseReportTab as ExpensesTab,
  Modal,
  JobCompletion,
  JobProcessConfirmation,
  BackArrow,
} from 'components'

import isEmpty from 'lodash/isEmpty'

const StyledJobDetails = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .JobOutline label': {
    [theme.breakpoints.down('sm')]: {
      display: 'inline-grid',
    },
  },
  '& .DateTimeBanner  .MuiChip-label': {
    height: 'inherit',
    width: '100%',
  },
  '& pre.ReportDescriptionWrapper': {
    whiteSpace: 'pre-wrap',
  },
}))

const JobDetails = (props) => {
  const dispatch = useDispatch()
  const formData = useSelector((state) => state.jobs.formData)
  const isLoading = useSelector((state) => state.loader)
  const displayData = formData?.formatted
  const beforeJobStart = parseInt(displayData?.status_id) === status.job.before_job_start
  const working = parseInt(displayData?.status_id) === status.job.working
  const [isOpen, setIsOpen] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [action, setAction] = useState('')

  const jobDetailsData = formatJobDetails(displayData)

  //Tab Contents
  const JobDetailsTab = <TabTableDisplay items={jobDetailsData} />
  const ReportsFileTab = <ReportFileTab data={displayData} />
  const ExpenseReportTab = <ExpensesTab data={displayData} />

  //set tab state
  const [tabContent, setTabContent] = useState(JobDetailsTab)
  const [activeTab, setActiveTab] = useState(tabIndex.details)

  useEffect(() => {
    let jobId = props?.match?.params?.id
    if (jobId) {
      dispatch(readJob(jobId))
    }
  }, [dispatch])

  useEffect(() => {
    if (activeTab === tabIndex.details) {
      setTabContent(JobDetailsTab)
    } else if (activeTab === tabIndex.reports) {
      setTabContent(ReportsFileTab)
    } else {
      setTabContent(ExpenseReportTab)
    }
    if (!isEmpty(displayData)) {
      props.handleData(displayData, 0)
    }
  }, [displayData])

  const onTabChange = (tab) => {
    setActiveTab(tab)
    switch (tab) {
      case tabIndex.reports:
        setTabContent(ReportsFileTab)
        props.handleData(displayData, 1)
        break
      case tabIndex.expense:
        setTabContent(ExpenseReportTab)
        props.handleData(displayData, 2)
        break
      default:
        setTabContent(JobDetailsTab)
        props.handleData([displayData, 0])
        break
    }
  }

  const getDateTimeComponent = (date, time) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem color="gray" sx={{ borderColor: '#f7f0f0a1' }} />}
        height="inherit"
      >
        <Box width={7 / 12} alignItems="center" display="inline-flex">
          <CalendarMonthOutlinedIcon />
          <Label>&nbsp;{date}</Label>
        </Box>
        <Box width={5 / 12} alignItems="center" display="inline-flex" p={1}>
          <ScheduleOutlinedIcon />
          <Label>&nbsp;{time}</Label>
        </Box>
      </Stack>
    )
  }

  const handleCancel = () => {
    setAction('cancel')
    setIsOpen(true)
    setIsComplete(false)
    dispatch(getCancellationFee(displayData.id, getCurrentDate()))
  }

  const handleResign = () => {
    setAction('resign')
    setIsOpen(true)
    setIsComplete(false)
  }

  const handleCloseCancelModal = () => {
    setIsComplete(false)
    setIsOpen(false)
  }

  const handleCompletion = (completed) => {
    setIsComplete(completed)
    setIsOpen(true)
  }

  const modalTitle = isComplete ? '辞退' : '作業辞退'

  const modalContent = isComplete ? (
    <JobCompletion
      data={action === 'cancel' ? '作業をキャンセルしました。' : '作業を辞退しました。'}
      onClose={handleCloseCancelModal}
    />
  ) : (
    <JobProcessConfirmation
      data={displayData}
      onClose={handleCloseCancelModal}
      completed={handleCompletion}
      action={action}
    />
  )

  return (
    <StyledJobDetails item xs={12}>
      {isLoading && (
        <Grid xs={12} item textAlign="center" py={32}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <React.Fragment>
          <BackArrow label="作業一覧に戻る" path="/job_list" />
          <Grid item xs={12} pt={5} pb={4} py={3}>
            <Heading>作業詳細</Heading>
          </Grid>
          <Grid container py={3} direction="row-reverse">
            <Grid item container xs={12} sm={6} md={6} justifyContent={{ xs: 'center', sm: 'end' }}>
              <Grid item xs={12} md={4}>
                <Grid item xs={12} pb={1} textAlign="end">
                  <Link to={'/job/update/' + displayData?.id}>
                    <LargeButton id="JobUpdateBtn" color="primary" mode="light">
                      編集
                    </LargeButton>
                  </Link>
                </Grid>
                <Grid item xs={12} textAlign="end">
                  <Link to={'/job/create/' + displayData?.id}>
                    <LargeButton id="JobUpdateBtn" color="primary" mode="light">
                      コピー
                    </LargeButton>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="JobOutline">
              <SubHeading color="secondary" bold="true">
                {displayData?.name}
              </SubHeading>
              <Label pb={0.5}>
                {displayData?.date} {displayData?.start_time}~{displayData?.end_time}
              </Label>
              <Label pb={0.5}>
                クライアント: <span>{displayData?.client.name}</span>
              </Label>
              <Label pb={0.5}>
                案件: <span>{displayData?.project_alias}</span>
              </Label>
              <Label pb={0.5}>
                作業グループ:
                <Link to={'/job_group/show/' + displayData?.job_group_id}>{displayData?.job_group_alias}</Link>
              </Label>
              <Label pb={0.5}>
                作業担当者: <span>{displayData?.pic_alias}</span>
              </Label>
              <Label pb={0.5}>
                ワーカー: <Link to={'/worker/' + displayData?.worker_id}>{displayData?.worker_alias}</Link>
              </Label>
            </Grid>
          </Grid>
          <Grid container py={3} spacing={4}>
            <Grid item xs={12} sm={5} md={4}>
              <Label pb={1}>ステータス</Label>
              <Banner label={displayData?.status_name} variant="outlined" color="success" bold="true" />
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <Label pb={1}>ステータス更新日</Label>
              <Banner
                label={getDateTimeComponent(displayData?.status_updated_date, displayData?.status_updated_time)}
                variant="outlined"
                color="gray"
                bold="true"
                mode="dark"
                className="DateTimeBanner"
              />
            </Grid>
            <Grid item xs={12}>
              <Tabs tabs={tabs} onChange={onTabChange}>
                {tabContent}
              </Tabs>
            </Grid>
            {activeTab === tabIndex.details && (
              <Grid item container xs={12} gap={2} justifyContent="center" textAlign="center">
                <LargeButton
                  id="JobCancelBtn"
                  color="gray"
                  mode="btn1"
                  onClick={handleCancel}
                  disabled={!(beforeJobStart || working)}
                >
                  作業キャンセル
                </LargeButton>
                <LargeButton
                  id="JobResignBtn"
                  color="gray"
                  mode="btn1"
                  onClick={handleResign}
                  disabled={!beforeJobStart}
                >
                  作業辞退
                </LargeButton>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
      <Modal
        open={isOpen}
        title={action === 'cancel' ? '作業キャンセル' : modalTitle}
        onClose={handleCloseCancelModal}
        size={isComplete ? 'sm' : 'md'}
      >
        {modalContent}
      </Modal>
    </StyledJobDetails>
  )
}

JobDetails.displayName = 'Job Details'

JobDetails.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
  handleData: PropTypes.func,
}

export default JobDetails
