import * as types from './actionTypes'

export function actionSearchProject(projectList) {
  return {
    type: types.SEARCH_PROJECTS,
    payload: projectList,
  }
}

export function actionSetSearchCriteria(project, client, status, page, limit, sort, sortBy) {
  return {
    type: types.SET_SEARCH_CRITERIA,
    payload: { project, client, status, page, limit, sort, sortBy },
  }
}

export function actionCreateProject(response) {
  return {
    type: types.CREATE_PROJECT,
    payload: { response },
  }
}

export function actionUpdateProject(response) {
  return {
    type: types.UPDATE_PROJECT,
    payload: { response },
  }
}

export function actionGetAllClients(clientList) {
  return {
    type: types.GET_ACTIVE_CLIENTS,
    payload: clientList,
  }
}

export function actionGetAllClientUsers(clientUserList) {
  return {
    type: types.GET_ALL_CLIENT_USERS,
    payload: clientUserList,
  }
}

export function actionModalValues(formData) {
  return {
    type: types.MODAL_VALUES,
    payload: { ...formData },
  }
}

export function actionClearModalValues() {
  return {
    type: types.CLEAR_MODAL_VALUES,
  }
}

export function fetchingDataFail(error) {
  return {
    type: types.FETCHING_DATA_FAILED,
    payload: error,
  }
}

export function actionValidateProjectStatus(status) {
  return {
    type: types.PROJECT_STATUS,
    payload: status,
  }
}

export function actionClearProjectDetails() {
  return {
    type: types.CLEAR_PROJECT_DETAILS,
  }
}
