export const SEARCH_JOB = 'SEARCH_JOB'
export const SET_JOB_SEARCH_CRITERIA = 'SET_JOB_SEARCH_CRITERIA'
export const SET_JOB_FORM_DATA = 'SET_JOB_FORM_DATA'
export const SET_CONFIRMING_DATA = 'SET_CONFIRMING_DATA'
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS'
export const SET_FORM_MODE = 'SET_FORM_MODE'
export const GET_JOB_DETAILS_SUCCESS = 'GET_JOB_DETAILS_SUCCESS'
export const GET_JOB_DETAILS_FAILED = 'GET_JOB_DETAILS_FAILED'
export const RESET_CURRENT_FORM = 'RESET_CURRENT_FORM'
export const GET_WORKER_JOB_LIST = 'GET_WORKER_JOB_LIST'
export const FETCHING_DATA_FAILED = 'FETCHING_DATA_FAILED'
export const SET_CANCELLATION_FEE = 'SET_CANCELLATION_FEE'
export const SHOW_MODAL_LOADER = 'SHOW_MODAL_LOADER'
export const HIDE_MODAL_LOADER = 'HIDE_MODAL_LOADER'
export const SET_JOB_OUTLINE_DETAILS = 'SET_JOB_OUTLINE_DETAILS'
export const POPULATE_JOB_OUTLINE = 'POPULATE_JOB_OUTLINE'
export const CHECK_CLOCKOUT = 'CHECK_CLOCKOUT'
export const SET_REPORT_STATUS = 'SET_REPORT_STATUS'
export const SEARCH_OFFER = 'SEARCH_OFFER'
export const SET_SEARCH_OFFER_CRITERIA = 'SET_SEARCH_OFFER_CRITERIA'
export const SET_ACCEPT_REJECT_OFFER = 'SET_ACCEPT_REJECT_OFFER'
export const SET_OFFER_ACTION = 'SET_OFFER_ACTION'
export const SEARCH_CLIENT_JOB = 'SEARCH_CLIENT_JOB'
export const SET_SEARCH_CLIENT_CRITERIA = 'SET_SEARCH_CLIENT_CRITERIA'
export const SET_JOB_STATUS_DEFAULT = 'SET_JOB_STATUS_DEFAULT'
export const RESET_CLIENT_SEARCH_PARAMS = 'RESET_CLIENT_SEARCH_PARAMS'
