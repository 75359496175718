import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid } from '@mui/material'
import { InputLabel, LargeButton } from 'components'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { getMessages } from 'validation'

function ChangePasswordFormContent(props) {
  const rulesMsg = getMessages('ja')
  const { error: serverError, isPasswordUpdated } = useSelector((state) => state.auth)
  const { handleUpdate, loading } = props
  const [triggered, setTriggered] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    clearErrors,
    setError,
  } = useForm()
  let updatedPassword = watch('password')

  useEffect(() => {
    Object.keys(serverError).length &&
      Object.keys(serverError).forEach((error) => {
        setError(error, { type: 'serverError', message: serverError[error][0] }, { shouldFocus: true })
      })
  }, [serverError])

  const rules = {
    required: {
      value: String,
      message: rulesMsg.password_credential,
    },
    minLength: {
      value: 8,
      message: rulesMsg.password_pattern,
    },
    pattern: {
      // 8 Characters, 1 Uppercase, 1 Special Character
      value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      message: rulesMsg.password_pattern,
    },
  }

  const validationRules = {
    password: rules,
    password_confirmation: {
      validate: (value) => {
        return value === updatedPassword || rulesMsg.password_mismatch
      },
    },
  }

  const handleRules = () => {
    clearErrors('old_password')
    triggered && trigger('old_password')
  }

  const InputLabelCreator = (id, name, label, isNew) => {
    const errorMsg = (errors, name) => {
      const isNewErrorMessage = errors[name] ? errors[name].message : rulesMsg.password_pattern
      const defaultErrorMessage = errors ? errors[name]?.message : null
      return isNew ? isNewErrorMessage : defaultErrorMessage
    }

    return (
      <InputLabel
        id={`${id}-ID`}
        name={name}
        ref={register(name, validationRules[name])}
        error={!!(errors && errors[name]) || isPasswordUpdated}
        errorMsg={errorMsg(errors, name)}
        label={label}
        type="password"
        autoComplete="off"
        placeholder="●   ●   ●   ●   ●   ●"
        required={true}
        onChange={name.includes('old_password') ? handleRules : (name) => errors[name] && trigger(name)}
      />
    )
  }

  return (
    <Grid item xs={12} sm={10} sx={{ px: { xs: 2 }, pt: { xs: 1, sm: 3 } }}>
      <form onSubmit={handleSubmit(handleUpdate)}>
        {InputLabelCreator('CurrentPasswordInput', 'old_password', '現在のパスワード', false)}
        {InputLabelCreator('NewPasswordInput', 'password', '新規パスワード', true)}
        {InputLabelCreator('PasswordConfirmationInput', 'password_confirmation', '新規パスワード (確認)', false)}
        <LargeButton
          type="submit"
          className="ChangePassword-SubmitBtn"
          id="ChangePasswordBtn"
          maxwidth="inherit"
          onClick={() => {
            setTriggered(true)
          }}
        >
          {loading ? <CircularProgress size="1.5rem" color="white" /> : '変更'}
        </LargeButton>
      </form>
    </Grid>
  )
}

ChangePasswordFormContent.propTypes = {
  handleUpdate: PropTypes.func,
  invalidTokenError: PropTypes.string,
  loading: PropTypes.bool,
}

export default ChangePasswordFormContent
