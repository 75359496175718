import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { actionSetDefaultInvoiceMonth } from 'store/invoice/actionCreators'
import { LargeButton, InputSelect, TableList, Heading, Icon } from 'components'
import { styled } from '@mui/material/styles'
import * as dayjs from 'dayjs'
import searchIcon from 'assets/svg/search.svg'
//utils
import { formatDropdownInvoiceMonths } from 'utils/helper'
const StyledInvoiceListForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& .MuiBox-root.InputSelect': {
    padding: 0,
  },
  '& tbody tr td:last-of-type': {
    width: '150px !important',
  },
  '& .InvoiceDownloadedBtn': {
    paddingLeft: 4,
  },
}))

const InvoiceListForm = (props) => {
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.loader)
  //invoice months
  const invoiceMonthList = useSelector((state) => state.invoice.invoiceMonths)
  const [invoiceMonthSelectData, setInvoiceMonthSelectData] = useState([])
  const [invoiceValue, setInvoiceValue] = useState('')
  const { register, handleSubmit } = useForm()
  // handle click search btn
  const handleSearch = (data) => {
    data.invoiceMonth = invoiceValue
    props.handlePageSearch(data)
  }

  //set default search invoice month
  useEffect(() => {
    const formattedInvoiceData = formatDropdownInvoiceMonths(invoiceMonthList)
    if (formattedInvoiceData.length > 0) {
      const lastInvoiceMonth = dayjs(invoiceMonthList[invoiceMonthList.length - 1]?.value)
      setInvoiceMonthSelectData(formatDropdownInvoiceMonths(invoiceMonthList))
      setInvoiceValue(lastInvoiceMonth.format('YYYYMM'))
      dispatch(actionSetDefaultInvoiceMonth(lastInvoiceMonth.format('YYYY/MM/DD')))
    }
  }, [invoiceMonthList])

  //handle change invoice date
  const onChangeInvoiceDate = (value) => {
    setInvoiceValue(dayjs(value).format('YYYYMM'))
  }

  return (
    <StyledInvoiceListForm container>
      <Grid item xs={12} sm={4} md={4} lg={3}>
        <Heading>請求書一覧</Heading>
      </Grid>
      <Grid item container pt={6}>
        <form onSubmit={handleSubmit(handleSearch)} className="InvoiceList-form">
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <InputSelect
                name="invoiceMonth"
                ref={register('invoiceMonth')}
                label="請求締め月"
                menuItems={invoiceMonthSelectData}
                value={invoiceValue}
                onChange={onChangeInvoiceDate}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={3}
              lg={2}
              xl={2.4}
              direction={{ xs: 'row', sm: 'column' }}
              justifyContent="flex-end"
              display={{ xs: 'block', sm: 'inline-flex' }}
            >
              <LargeButton
                id="SearchInvoiceBtn-ID"
                type="submit"
                disabled={isLoading}
                startIcon={<Icon size={'sm'} source={searchIcon} />}
              >
                検索
              </LargeButton>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} pt={3}>
          <TableList
            title={`Invoice List`}
            headCells={props.headerCells}
            data={props.data}
            totalPage={props.totalPage}
            totalCount={props.totalCount}
            handlePageChange={props.handlePageChange}
            isLoading={props.isLoading}
            handleSort={props.handleSort}
            sort={props.sort}
            sortBy={props.sortBy}
            currentPage={props.currentPage}
            pageFrom={props.pageFrom}
            pageTo={props.pageTo}
          />
        </Grid>
      </Grid>
    </StyledInvoiceListForm>
  )
}

InvoiceListForm.displayName = 'Invoice List Form'

InvoiceListForm.propTypes = {
  headerCells: PropTypes.array,
  data: PropTypes.array,
  totalPage: PropTypes.number,
  totalCount: PropTypes.number,
  handlePageChange: PropTypes.func,
  handlePageSearch: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  sort: PropTypes.string,
  sortBy: PropTypes.string,
  currentPage: PropTypes.number,
  pageFrom: PropTypes.number,
  pageTo: PropTypes.number,
}

export default InvoiceListForm
